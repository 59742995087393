import React from "react";

export const CommentIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.83333 6.08333H9M4.83333 9H11.5M7.06979 14H12.5C13.9001 14 14.6002 14 15.135 13.7275C15.6054 13.4878 15.9878 13.1054 16.2275 12.635C16.5 12.1002 16.5 11.4001 16.5 10V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V15.9463C1.5 16.3903 1.5 16.6123 1.59102 16.7263C1.67019 16.8255 1.79022 16.8832 1.91712 16.8831C2.06302 16.8829 2.23639 16.7442 2.58313 16.4668L4.57101 14.8765C4.9771 14.5517 5.18014 14.3892 5.40624 14.2737C5.60683 14.1712 5.82036 14.0963 6.04101 14.051C6.28972 14 6.54975 14 7.06979 14Z"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
