import React from "react";
import { theme } from "../../shared/theme";
import { useStore } from "../../lib/storeData";

interface Props {
  size?: number;
}

export const FilledStar = ({ size = 16 }: Props) => {
  const { storeReviewSettings } = useStore();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.0004 12.5548L75.2291 14.4779C76.1765 15.0422 76.7394 14.6324 76.4856 13.5585L75.6324 9.94957L78.4727 7.52141C79.3165 6.8001 79.1017 6.14011 78.0048 6.04828L74.2484 5.73378L72.7867 2.33024C72.3525 1.31919 71.6492 1.31742 71.2142 2.33024L69.7524 5.73378L65.9961 6.04828C64.8939 6.14056 64.6829 6.79883 65.5282 7.52141L68.3684 9.94957L67.5153 13.5585C67.2619 14.6305 67.8289 15.0395 68.7717 14.4779L72.0004 12.5548Z"
        fill="#D0D5DD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.0004 12.5548L59.2291 14.4779C60.1765 15.0422 60.7394 14.6324 60.4856 13.5585L59.6324 9.94957L62.4727 7.52141C63.3165 6.8001 63.1017 6.14011 62.0048 6.04828L58.2484 5.73378L56.7867 2.33024C56.3525 1.31919 55.6492 1.31742 55.2142 2.33024L53.7524 5.73378L49.9961 6.04828C48.8939 6.14056 48.6829 6.79883 49.5282 7.52141L52.3684 9.94957L51.5153 13.5585C51.2619 14.6305 51.8289 15.0395 52.7717 14.4779L56.0004 12.5548Z"
        fill="#D0D5DD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.0004 12.5548L43.2291 14.4779C44.1765 15.0422 44.7394 14.6324 44.4856 13.5585L43.6324 9.94957L46.4727 7.52141C47.3165 6.8001 47.1017 6.14011 46.0048 6.04828L42.2484 5.73378L40.7867 2.33024C40.3525 1.31919 39.6492 1.31742 39.2142 2.33024L37.7524 5.73378L33.9961 6.04828C32.8939 6.14056 32.6829 6.79883 33.5282 7.52141L36.3684 9.94957L35.5153 13.5585C35.2619 14.6305 35.8289 15.0395 36.7717 14.4779L40.0004 12.5548Z"
        fill={storeReviewSettings?.starsColor || theme.colors.primary}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.0004 12.5548L27.2291 14.4779C28.1765 15.0422 28.7394 14.6324 28.4856 13.5585L27.6324 9.94957L30.4727 7.52141C31.3165 6.8001 31.1017 6.14011 30.0048 6.04828L26.2484 5.73378L24.7867 2.33024C24.3525 1.31919 23.6492 1.31742 23.2142 2.33024L21.7524 5.73378L17.9961 6.04828C16.8939 6.14056 16.6829 6.79883 17.5282 7.52141L20.3684 9.94957L19.5153 13.5585C19.2619 14.6305 19.8289 15.0395 20.7717 14.4779L24.0004 12.5548Z"
        fill={storeReviewSettings?.starsColor || theme.colors.primary}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00044 12.5548L11.2291 14.4779C12.1765 15.0422 12.7394 14.6324 12.4856 13.5585L11.6324 9.94957L14.4727 7.52141C15.3165 6.8001 15.1017 6.14011 14.0048 6.04828L10.2484 5.73378L8.78668 2.33024C8.35245 1.31919 7.64919 1.31742 7.2142 2.33024L5.75244 5.73378L1.99607 6.04828C0.893911 6.14056 0.682938 6.79883 1.52815 7.52141L4.36844 9.94957L3.51531 13.5585C3.26188 14.6305 3.82894 15.0395 4.77173 14.4779L8.00044 12.5548Z"
        fill={storeReviewSettings?.starsColor || theme.colors.primary}
      />
    </svg>
  );
};
