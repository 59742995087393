import Flex from "../../../shared/globals/UiElements/Flex";
import { FormattedMessage } from "react-intl";
import { CouponIcon } from "../../../assets/Icons";
import { DefaultTextPrice } from "../../../components/Price";
import { H4 } from "../../../shared/globals";
import styled from "styled-components";
import { Badge } from "../../../shared/globals/UiElements/Badge";
import { DiscountBreakdownProps, TemplateElement } from "../../types";

const DiscountsBreakdown: TemplateElement<DiscountBreakdownProps> = ({
  totalDiscounts,
  aggregateDiscount,
  applyDefaultTemplateStyles,
}) => (
  <Flex column fullWidth justifyContent="space-between" alignItems="flex-start">
    <Flex fullWidth justifyContent="space-between">
      <StyledH4 isDefaultStyles={!!applyDefaultTemplateStyles}>
        <FormattedMessage defaultMessage="Discount" />
      </StyledH4>
      <Flex column alignItems="flex-end" spacing="s">
        <DiscountedPrice fontWeight={600}>
          -
          <DefaultTextPrice money={aggregateDiscount} />
        </DiscountedPrice>
      </Flex>
    </Flex>
    <Flex column fullWidth justifyContent="space-between" spacing="s">
      {totalDiscounts &&
        Array.from(totalDiscounts.keys()).map((discount) => {
          if (totalDiscounts.get(discount)!.amount > 0)
            return (
              <Flex
                spacing="s"
                fullWidth
                justifyContent="space-between"
                alignItems="center"
              >
                <Badge
                  type={applyDefaultTemplateStyles ? "white" : "ink"}
                  label={
                    <Flex alignItems="center" spacing="xs">
                      <CouponIcon />
                      {discount}
                    </Flex>
                  }
                />
                <DiscountedPrice>
                  -
                  <DefaultTextPrice money={totalDiscounts.get(discount)} />
                </DiscountedPrice>
              </Flex>
            );
          return null;
        })}
    </Flex>
  </Flex>
);

export default DiscountsBreakdown;

// To match with the default template styles on Checkout pages
const StyledH4 = styled(H4)<{ isDefaultStyles: boolean }>`
  color: ${({ theme, isDefaultStyles }) =>
    isDefaultStyles ? theme.text.inkLight : theme.text.secondary};
  font-weight: ${({ isDefaultStyles }) => (isDefaultStyles ? 600 : 400)};
`;

const DiscountedPrice = styled(H4)`
  color: ${({ theme }) => theme.success.medium};
  * {
    color: ${({ theme }) => theme.success.medium};
  }
`;
