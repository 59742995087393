import { FC } from "react";
import { useIntl } from "react-intl";
import { SectionHeadingProps } from "../../types";
import { getLocaleInfo } from "../../../lib/i18n/locales-data";

const SectionHeading: FC<SectionHeadingProps> = ({ section }) => {
  const { locale } = useIntl();
  const activeLocale = getLocaleInfo(locale).code;
  const translations = section?.translations?.find(
    (translation) => translation.locale === activeLocale
  );

  return section?.showSectionHeader ? (
    <p className="text-[36px] max-md:text-[24px] font-semibold text-text-default mb-xl max-md:mb-2xl max-md:text-center">
      {translations?.header || section?.header}
    </p>
  ) : null;
};

export default SectionHeading;
