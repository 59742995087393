import { Card } from "../../../shared/globals/UiElements/Card";
import { Section } from "../../../shared/layout";
import type { RegistrationPageProps, TemplateElement } from "../../types";
import { StoreTemplate } from "../../TemplateLoader";

const Registration: TemplateElement<RegistrationPageProps> = () => {
  const Template = StoreTemplate.get();
  return (
    <div className="py-12">
      <Section>
        <Card paddingSize="2xl" className="mx-auto md:max-w-[500px]">
          {/* @ts-expect-error: should expect RegistrationFormProps to be required but handled in its renderer */}
          <Template.elements.RegistrationForm />
        </Card>
      </Section>
    </div>
  );
};

export default Registration;
