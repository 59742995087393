import * as React from "react";
import styled from "styled-components";
import { NextRouter, useRouter } from "next/router";
import { FormattedMessage } from "react-intl";
import Select, { ControlProps, StylesConfig, components } from "react-select";
import Filter from "./Filter";
import { FilterIcon, EmptyCartIcon, SortIcon } from "../../../../assets/Icons";
import { PrimaryButton } from "../../../../components/Button";
import { EmptyState } from "../../../../components/UtilityComponents/EmptyState";
import { SortOrder } from "../../../../generated/graphql";
import { FlexCol, FlexRow, P } from "../../../../shared/globals";
import LoadingSpinner from "../../../../shared/globals/UiElements/LoadingSpinner";
import { rtl, themeColor } from "../../../../shared/styles-utils";
import { StoreTemplate } from "../../../TemplateLoader";
import FilterTags from "../../../default/components/Filter/components/FilterTags";
import { sortOptions } from "../../../default/components/Filter/consts";
import { FilterAndSortProps } from "../../../types";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { ZIndex } from "../../../../shared/globals/types";

const MobileFilterSort = ({
  products,
  itemPerPage,
  productsTotalCount,
  offset,
  loading,
}: FilterAndSortProps) => {
  const Template = StoreTemplate.get();
  const router = useRouter();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleChange = (selectedOption: {
    value: { sortBy: string; sortOrder: SortOrder };
  }) => {
    updateQuerySorts(router, {
      ...selectedOption.value,
    });
  };

  const sort = {
    sortBy: router.query.sortBy,
    sortOrder: router.query.sortOrder,
  };

  return (
    <>
      <Filter
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        productsTotalCount={productsTotalCount}
      />
      <FlexCol alignItems="start">
        {router.query.query && (
          <SearchText>
            <FormattedMessage defaultMessage="Search Results For" />{" "}
            {'"' + router.query.query + '"'}{" "}
            <span style={{ opacity: 0.4 }}>
              <FormattedMessage
                defaultMessage="[{resultsCount} Results]"
                values={{ resultsCount: productsTotalCount }}
              />
            </span>
          </SearchText>
        )}
        <SortActionWrapper fullWidth>
          <FilterBtn onClick={() => setIsOpen(true)}>
            <FilterIcon />
            <MobileText>
              <FormattedMessage defaultMessage="Filter by" />
            </MobileText>
          </FilterBtn>

          <Select
            instanceId="sort-products"
            options={sortOptions}
            onChange={handleChange}
            styles={customStyles}
            defaultValue={sortOptions.find(
              (option) => option.key === `${sort.sortBy}-${sort.sortOrder}`
            )}
            isSearchable={false}
            placeholder={<FormattedMessage defaultMessage="Sort by" />}
            components={{
              Control,
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
          />
        </SortActionWrapper>
        <FilterTagsWrapper>
          <FilterTags />
        </FilterTagsWrapper>
      </FlexCol>
      {loading ? (
        <LoadingSpinner />
      ) : products?.length === 0 ? (
        <EmptyState
          title={
            router.query.filters ||
            router.query.minPrice ||
            router.query.maxPrice ? (
              <FormattedMessage defaultMessage="There are no products match your search criteria" />
            ) : (
              <FormattedMessage defaultMessage="There are no products added yet" />
            )
          }
          image={<EmptyCartIcon />}
        />
      ) : (
        <div style={{ paddingInline: 10 }}>
          <Template.elements.ProductsList products={products} />
          <Pagination
            offset={offset!}
            itemPerPage={itemPerPage!}
            totalCount={productsTotalCount!}
          />
        </div>
      )}
    </>
  );
};

export default MobileFilterSort;

// Functions

function updateQuerySorts(
  router: NextRouter,
  newFilters: { sortBy: string; sortOrder: SortOrder }
) {
  router.push({
    pathname: router.asPath.split("?")[0],
    query: {
      ...router.query,
      ...newFilters,
    },
  });
}

const Control = (props: ControlProps) => {
  const { children, ...restProps } = props;
  return (
    components.Control && (
      <components.Control {...restProps}>
        <SortIcon />
        {children}
      </components.Control>
    )
  );
};

const secondaryColor = "#525252"; // theme.colors.secondary
const customStyles: StylesConfig = {
  container: (provided) => ({
    ...provided,
    width: "50%",
  }),
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    justifyContent: "center",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontWeight: 600,
    fontSize: 14,
    padding: 0,
    flex: "unset",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: secondaryColor,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: secondaryColor,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "4px",
    boxShadow: "0px 4px 10px 0px rgba(182, 182, 182, 0.25)",
    backgroundColor: "white",
    zIndex: ZIndex["DROPDOWN"],
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "1.43",
    color: secondaryColor,
    backgroundColor: "white",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#F5F5F5",
    },
  }),
};

// Styles

const SearchText = styled(P)`
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  padding: 20px;
  word-break: break-word;
`;

const SortActionWrapper = styled(FlexRow)`
  justify-content: space-around;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
`;

const MobileText = styled(P)`
  font-weight: 600;
  margin-inline-start: 4px;
`;

const FilterBtn = styled(PrimaryButton)`
  background-color: ${themeColor("backgroundReverse")};
  width: 50%;
  border: none;
  ${rtl("border-left", "border-right")}: 1px solid #d8d8d8;
  border-radius: 0;
  padding: 10px 12px;

  &:hover {
    box-shadow: unset;
  }
  &:focus {
    outline: none;
  }
`;

const FilterTagsWrapper = styled.div`
  padding: 20px;
  > div {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    > * {
      color: ${({ theme }) => theme.text.default};
    }
  }
`;
