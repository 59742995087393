import React from "react";
import { useLocalStorage } from "../../hooks/useStorage";
import { useStore } from "../../lib/storeData";
import { getInitialState } from "./checkoutUtils";
import { Context } from "./context";
import { reducer } from "./reducer/reducer";
import type { CartState } from "./types";

export const CartProvider = ({ children }: { children: React.ReactNode }) => {
  const { currency } = useStore();
  const initialState = getInitialState(currency);
  const [defaultState, updateStorage] = useLocalStorage<CartState>(
    "new-cart-state",
    initialState
  );

  const value = React.useReducer(reducer(initialState), defaultState!);

  const items = value?.[0]?.items;
  const checkout = value?.[0]?.checkout;
  const buyNowItem = value?.[0]?.buyNowItem;
  const itemsTotal = value?.[0]?.itemsTotal;

  React.useEffect(() => {
    updateStorage({
      items,
      checkout,
      buyNowItem,
      itemsTotal,
    });
  }, [items, checkout, updateStorage, buyNowItem, itemsTotal]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
