import React from "react";
import styled from "styled-components";
import { StoreTemplate } from "../../TemplateLoader";
import type { TemplateElement, ProductsListProps } from "../../types";

const ProductsList: TemplateElement<ProductsListProps> = ({ products }) => {
  const Template = StoreTemplate.get();

  return (
    <StyledContainer>
      {products?.map((product) => (
        <Template.elements.ProductCard
          key={product?.id}
          product={product!}
          isProductList
        />
      ))}
    </StyledContainer>
  );
};

export default ProductsList;

/**
 *
 * Styles
 *
 */

const StyledContainer = styled.div`
  padding: 40px 0;
  display: grid;
  /* Only using 1f breaks for small screens for long contents because 1fr = minmax(auto,1fr)
   * and item size can't be smaller than auto which is the grid item size.
   * Using minmax(0, 1fr) makes the grid element resizes min to 0and max to 1fr and all
   * items resize proportionally
   */
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 16px;
  justify-content: center;
  margin: 0;
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding-bottom: 50px;
  }
`;
