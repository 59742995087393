import { FormattedMessage } from "react-intl";
import { Delete, ShoppingCart } from "../../../../assets/Icons";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { useStore } from "../../../../lib/storeData";
import { BuyNowButtonDisplay } from "../../../../generated/graphql";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import { H4, Overlay } from "../../../../shared/globals";
import { Price } from "../../../../components/Price";
import styled from "styled-components";
import { QuantityInput } from "../QuantityInput";
import { useState } from "react";
import MotionElementWrapper from "../../../../shared/globals/MotionElementWrapper";
import { useAnimation } from "framer-motion";
import { themeColor } from "../../../../shared/styles-utils";
import { css } from "styled-components";
import { SimpleProductProps } from "../../../types";
import Button from "../Button/Button";
import BuyNowButton from "../BuyNowButton/BuyNowButton";
import { theme } from "../../../../../tailwind.config";
import { useCartData } from "../../../../lib/cartData/useCartData";

interface ProductBottomSheetProps extends SimpleProductProps {
  disableButton: () => boolean | undefined | null;
}

const ProductBottomSheet = (props: ProductBottomSheetProps) => {
  const { appearance } = useStore();
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const controls = useAnimation();
  const {
    cart: { isLoading },
  } = useCartData();
  const title = props.product?.title;
  const options = props.product?.options;
  const image = props.product?.images?.[0];
  const price = props.selectedVariant?.price;
  const max = props.availableQuantity?.max;
  const min = props.availableQuantity?.min;

  const selectedOptions = props.selectedVariant?.selectedOptions!;

  const toggleBottomSheet = () => {
    if (bottomSheetOpen) {
      setBottomSheetOpen(false);
      controls.start("hidden");
    } else {
      controls.start("visible");
      setBottomSheetOpen(true);
    }
  };

  const handleClickOutside = () => {
    if (bottomSheetOpen) {
      setBottomSheetOpen(false);
      controls.start("hidden");
    }
  };
  function onDragEnd(_, info) {
    const shouldClose = info.velocity.y > 20;

    if (shouldClose) {
      toggleBottomSheet();
      controls.start("hidden");
    } else {
      controls.start("visible");
    }
  }

  const isBuyNowButtonVisible =
    appearance?.productDisplay?.buyNowButton?.display &&
    appearance?.productDisplay?.buyNowButton?.display !==
      BuyNowButtonDisplay.None;

  return (
    <div className="block md:hidden">
      <MotionElementWrapper
        key="animation-on-state"
        initial="hidden"
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: "100%" },
        }}
        transition={{ duration: 0.1 }}
        animate={bottomSheetOpen ? "visible" : "hidden"}
        className="fixed top-0 left-0 w-full h-screen z-OVERLAY"
        onClick={handleClickOutside}
      >
        <Overlay />
      </MotionElementWrapper>

      <MotionElementWrapper
        className="fixed bottom-0 left-0 bg-white w-full px-2xl py-2xl rounded-t-3xl z-OVERLAY"
        drag="y"
        onDragEnd={onDragEnd}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { y: 0 },
          hidden: { y: "100%" },
        }}
        transition={{
          type: "spring",
          damping: 30,
          stiffness: 300,
        }}
        dragConstraints={{ top: 0 }} // Set limits for dragging
      >
        <Flex column spacing="l" fullWidth>
          <div className="w-[80px] h-[5px] absolute top-sm bg-gray-300 rounded-full left-1/2 -translate-x-1/2"></div>

          <Flex fullWidth justifyContent="space-between">
            <Flex className="flex-1">
              <ItemImg imgDetails={image} size={60} />
              <Flex column justifyContent="center" spacing="none">
                <H4>{title}</H4>
                <Flex spacing="xs">
                  {selectedOptions.map((option, idx) => (
                    <div key={idx} className="text-sm text-gray-500">
                      {option.value.name}
                      {options!.length - 1 === idx ? "" : " / "}
                    </div>
                  ))}
                </Flex>

                <H4>
                  <Price money={price} />
                </H4>
              </Flex>
            </Flex>
            <Flex fullHeight onClick={toggleBottomSheet}>
              <Delete small className="text-gray-500" />
            </Flex>
          </Flex>

          <Flex column spacing="l">
            {options?.map((option, optionIndex) => (
              <Flex key={option?.name! + optionIndex} spacing="m" column>
                <H4>{option?.name}</H4>

                <Flex flexWrap="wrap" spacing="xs">
                  {option?.values?.map((value) => (
                    <StyledOptionBox
                      key={value.id}
                      isSelected={
                        props.selectedVariant?.selectedOptions?.[optionIndex]
                          ?.value?.id === value.id
                      }
                      isAvailable={
                        !!props.isValueAvailableForOtherSelectedValues?.(
                          props.selectedVariant,
                          optionIndex,
                          props.product,
                          value
                        )
                      }
                      onClick={props.handleSelect?.(value, optionIndex)}
                      className="px-sm py-xs rounded-full text-sm cursor-pointer"
                    >
                      {value.name}
                    </StyledOptionBox>
                  ))}
                </Flex>
              </Flex>
            ))}
          </Flex>

          <Flex>
            <QuantityInput
              value={props.quantity!}
              onChange={(value) => props.setQuantity?.(value)}
              max={max}
              size="large"
              disabled={props.isCartLoading}
            />
            <Button
              suffixIcon={<ShoppingCart />}
              fullWidth
              onClick={() => props.handleAddToCart!(() => toggleBottomSheet())}
              isLoading={props.isCartLoading}
              isDisabled={
                props.isCartLoading || props.quantity! < min! || isLoading
              }
            >
              <FormattedMessage defaultMessage="Add to cart" />
            </Button>
          </Flex>
        </Flex>
      </MotionElementWrapper>

      <Flex
        fullWidth
        height="60px"
        spacing="none"
        className="fixed bottom-0 left-0 z-DROPDOWN"
      >
        <Button
          suffixIcon={<ShoppingCart />}
          fullWidth
          onClick={() =>
            options?.length ? toggleBottomSheet() : props.handleAddToCart!()
          }
          isDisabled={props.disableButton() || props.isCartLoading || isLoading}
          borderRadius={0}
          isLoading={props.isCartLoading}
          loadingSpinnerColor={theme.extend.colors.gray[500]}
          type={isBuyNowButtonVisible ? "secondary" : "primary"}
        >
          <FormattedMessage defaultMessage="Add to cart" />
        </Button>
        {isBuyNowButtonVisible && (
          <BuyNowButton
            quantity={props.quantity}
            isDisabled={
              props.disableButton() ||
              props.quantity! < props.availableQuantity?.min! ||
              isLoading
            }
            product={props.product!}
            borderRadius={0}
          />
        )}
      </Flex>
    </div>
  );
};

export { ProductBottomSheet };

const StyledOptionBox = styled(Flex)<{
  isSelected;
  isAvailable;
}>`
  padding: 10px 12px;
  border: solid 1px ${({ theme }) => theme.bg.inactive};
  color: ${({ theme }) => theme.text.default};

  background-color: ${themeColor("white")};
  ${({ isSelected }) =>
    isSelected
      ? css`
          color: ${themeColor("primary")};
          border: solid 1px ${themeColor("primary")};
          background-color: ${themeColor("white")};
          outline: none;
        `
      : ""}
  font-weight:600;
  ${({ isAvailable }) =>
    !isAvailable &&
    css`
      opacity: 0.4;
      background-color: ${({ theme }) => theme.bg.reverse};
    `}
`;
