import { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, Form } from "react-final-form";
import { RequiredSpan } from ".././Contact/styled";
import { PrimaryButton } from "../../../../components/Button";
import { Lock, Mail } from "../../../../assets/Icons";
import { Link } from "../../../../lib/i18n";
import InputPhone, {
  PhoneInput,
} from "../../../../shared/globals/UiElements/InputPhone";
import LoginSwitcher from "../../components/Authentication/LoginSwitcher";
import {
  LoginFormProps,
  LoginFormValues,
  LoginMethod,
  TemplateElement,
} from "../../../types";
import Flex from "../../../../shared/globals/UiElements/Flex";
import InputWithIcon from "../../../../shared/globals/UiElements/InputWithIcon";
import { useRouter } from "next/router";

const LoginForm: TemplateElement<LoginFormProps> = ({
  activeTab,
  setActiveTab,
  onSubmit,
  isError,
  setIsError,
  focusOnError,
}) => {
  const intl = useIntl();
  const { query } = useRouter();
  return (
    <Form<LoginFormValues>
      onSubmit={onSubmit}
      validate={(values) => validate(values, activeTab)}
      decorators={[focusOnError]}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Flex column spacing="xl">
            <Flex
              column
              alignItems="center"
              justifyContent="center"
              spacing="s"
            >
              <h1 className="m-0 text-gray-900 font-semibold">
                <FormattedMessage defaultMessage="Sign-in to your account" />
              </h1>
              <h3 className="text-gray-600 text-base font-normal m-0">
                <FormattedMessage defaultMessage="Welcome back! Please enter your details." />
              </h3>
            </Flex>
            <LoginSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
            {activeTab === LoginMethod.EMAIL && (
              <label className="text-gray-600 text-sm font-medium">
                <FormattedMessage defaultMessage="Email" />
                <Field name="email">
                  {({ input, meta: { error, touched } }) => (
                    <div className="mt-1.5">
                      <InputWithIcon
                        {...input}
                        type="email"
                        autoComplete="email"
                        prefix={<Mail />}
                        className={
                          (error && touched) || isError ? "invalid" : ""
                        }
                        onChange={(event) => {
                          setIsError(false);
                          input.onChange(event);
                        }}
                        placeholder={intl.formatMessage({
                          defaultMessage: "Enter your email",
                        })}
                      />
                      {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                    </div>
                  )}
                </Field>
              </label>
            )}
            {activeTab === LoginMethod.PHONE && (
              <label className="text-gray-600 text-sm font-medium">
                <FormattedMessage defaultMessage="Phone no." />
                <Field<PhoneInput> name="phone">
                  {(fieldProps) => (
                    <InputPhone
                      {...fieldProps}
                      className={isError ? "invalid" : ""}
                      onChange={(event: PhoneInput) => {
                        setIsError(false);
                        fieldProps.input.onChange(event);
                      }}
                    />
                  )}
                </Field>
              </label>
            )}
            <Flex column alignItems="flex-end">
              <label className="w-full text-gray-600 text-sm font-medium">
                <FormattedMessage defaultMessage="Password" />
                <Field name="password">
                  {({ input, meta: { error, touched } }) => (
                    <div className="mt-1.5">
                      <InputWithIcon
                        {...input}
                        type="password"
                        autoComplete="current-password"
                        prefix={<Lock />}
                        className={
                          (error && touched) || isError ? "invalid" : ""
                        }
                        onChange={(event) => {
                          setIsError(false);
                          input.onChange(event);
                        }}
                        placeholder="••••••••"
                      />
                      {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                      {isError && (
                        <RequiredSpan>
                          <FormattedMessage defaultMessage="Invalid credentials" />
                        </RequiredSpan>
                      )}
                    </div>
                  )}
                </Field>
              </label>
              <PrimaryButton compact reversed>
                <Link href="/reset-password">
                  <FormattedMessage defaultMessage="Forgot password?" />
                </Link>
              </PrimaryButton>
            </Flex>

            <PrimaryButton
              type="submit"
              fullWidth
              isLoading={submitting}
              rounded
            >
              <FormattedMessage defaultMessage="Login" />
            </PrimaryButton>

            <Flex justifyContent="center" spacing="xs">
              <div className="text-gray-600 text-sm">
                <FormattedMessage defaultMessage="Don't Have an Account?" />
              </div>
              <PrimaryButton compact reversed>
                <Link href="/registration" query={{ ...query }}>
                  <FormattedMessage defaultMessage="Create Account" />
                </Link>
              </PrimaryButton>
            </Flex>
          </Flex>
        </form>
      )}
    />
  );
};

export default LoginForm;

/**
 *
 * Functions
 *
 */

const validate = (values: LoginFormValues, activeTab: LoginMethod) => {
  const errors: Partial<{ [key in keyof LoginFormValues]: ReactElement }> = {};

  if (
    activeTab === LoginMethod.EMAIL &&
    (!values?.email?.trim() ||
      !values?.email?.match(
        /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
      ))
  ) {
    errors.email = (
      <FormattedMessage defaultMessage="Please enter a valid email address" />
    );
  }

  if (activeTab === LoginMethod.PHONE && !values?.phone?.isValid) {
    errors.phone = (
      <FormattedMessage defaultMessage="Please enter a valid phone number" />
    );
  }

  if (!values?.password) {
    errors.password = (
      <FormattedMessage defaultMessage="Please enter your password" />
    );
  }

  return errors;
};
