import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, Form } from "react-final-form";
import createDecorator from "final-form-focus";
import Asterisk from "../../../../shared/globals/UiElements/Asterisk";
import { useStore } from "../../../../lib/storeData";
import { PrimaryButton } from "../../../../components/Button";
import {
  CheckCircle,
  EnvelopeOutlined,
  MarkerOutlined,
  PhoneOutlined,
} from "../../../../assets/Icons";
import { H4, Label } from "../../../../shared/globals";
import { Card } from "../../../../shared/globals/UiElements/Card";
import { RequiredSpan, StyledH3, FeedbackMessageContainer } from "./styled";
import { SocialLinks } from "../../../../components/UtilityComponents/SocialLinks";
import { useSubmitStoreContactFormMutation } from "../../../../generated/graphql";
import { PhoneInput } from "../../../../shared/globals/UiElements/InputPhone";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { Input, TextArea } from "../../components/Input";
import InputPhone from "../../components/Input/inputPhone";
import { fireSubmitContactFormEvent } from "./utils";

export type ContactFormValues = {
  name: string;
  email: string;
  phone: PhoneInput;
  subjectTitle: string;
  subjectBody: string;
};

const SuccessMessage = () => (
  <FeedbackMessageContainer isSuccess={true}>
    <CheckCircle />
    <H4 fontWeight={600}>
      <FormattedMessage defaultMessage="We have received your message and will reach you as soon as possible." />
    </H4>
  </FeedbackMessageContainer>
);

const FailureMessage = () => (
  <FeedbackMessageContainer isSuccess={false}>
    <H4 fontWeight={600}>
      <FormattedMessage defaultMessage="Something went wrong, please try again." />
    </H4>
  </FeedbackMessageContainer>
);

const focusOnError = createDecorator<ContactFormValues>();

const Contact = () => {
  const intl = useIntl();
  const { storeId, socialMedia, contactInfo, location } = useStore();
  const [isSuccess, setIsSuccess] = React.useState<boolean | undefined>(
    undefined
  );
  // const { isMobile } = useBreakPoints();

  const [SubmitStoreContactForm] = useSubmitStoreContactFormMutation();

  const email = contactInfo?.email;
  const phone = contactInfo?.phone;
  const address = location?.address;

  const handleSubmitContact = async (input: ContactFormValues) => {
    try {
      const { data } = await SubmitStoreContactForm({
        variables: {
          input: {
            storeId,
            name: input.name,
            fromEmail: input.email,
            phone: input?.phone?.value!,
            messageSubject: input.subjectTitle,
            messageBody: input.subjectBody,
          },
        },
      });

      if (data?.submitStoreContactForm?.statusCode! < 400) {
        handleFeedback(true);
        fireSubmitContactFormEvent(input);
      } else {
        handleFeedback(false);
      }
    } catch (error) {
      handleFeedback(false);
    }
  };

  const handleFeedback = (value: boolean) => {
    setIsSuccess(value);
    setTimeout(() => setIsSuccess(undefined), 5000);
  };

  const isContactInfoExist = !!(email || phone || address);
  const socialLinkContent = socialMedia?.length ? (
    <>
      <hr className="w-full my-xl border-[#C4CDD5]" />
      <Flex column>
        <h2 className="text-center text-sm font-normal w-full">
          <FormattedMessage defaultMessage="You can also follow & contact us through" />
        </h2>
        <SocialLinks
          className="!w-full"
          justifyContent="center"
          socialSettings={socialMedia}
        />
      </Flex>
    </>
  ) : null;

  return (
    <div className="py-2xl w-full">
      <Flex
        justifyContent={isContactInfoExist ? "space-between " : "center"}
        spacing="xxxl"
        rotateOn="TABLET"
      >
        <Card
          className={`justify-start items-start ${
            isContactInfoExist ? "flex-[2]" : "md:max-w-[500px] w-full"
          }`}
          paddingSize="xl"
        >
          <Flex column>
            <h4 className={"text-gray-600 text-center"}>
              <FormattedMessage defaultMessage="You can send your questions by filling out the form below & We will get back to you as soon as possible." />
            </h4>
            <Flex>
              <Form<ContactFormValues>
                onSubmit={handleSubmitContact}
                validate={validate}
                initialValues={{}}
                decorators={[focusOnError]}
                render={({ handleSubmit, submitting }) => {
                  return (
                    <form className="w-full" onSubmit={handleSubmit}>
                      <Label>
                        <Flex spacing="xxs">
                          <FormattedMessage defaultMessage="Name" />
                          <Asterisk />
                        </Flex>
                        <Field name="name">
                          {({ input, meta: { error, touched } }) => (
                            <>
                              <Input
                                {...input}
                                className={error && touched ? "invalid" : ""}
                                placeholder={intl.formatMessage({
                                  defaultMessage: "Enter your name",
                                })}
                              />
                              {error && touched && (
                                <RequiredSpan>{error}</RequiredSpan>
                              )}
                            </>
                          )}
                        </Field>
                      </Label>
                      <Label>
                        <Flex spacing="xxs">
                          <FormattedMessage defaultMessage="Email" />
                          <Asterisk />
                        </Flex>
                        <Field name="email">
                          {({ input, meta: { error, touched } }) => (
                            <>
                              <Input
                                {...input}
                                className={error && touched ? "invalid" : ""}
                                type="email"
                                placeholder={intl.formatMessage({
                                  defaultMessage: "Enter your email",
                                })}
                              />
                              {error && touched && (
                                <RequiredSpan>{error}</RequiredSpan>
                              )}
                            </>
                          )}
                        </Field>
                      </Label>
                      <Label>
                        <Flex spacing="xxs">
                          <FormattedMessage defaultMessage="Phone number" />
                          <Asterisk />
                        </Flex>
                        <Field<PhoneInput> name="phone">
                          {(fieldProps) => (
                            <InputPhone
                              {...fieldProps}
                              dataTest="type-primary-phone"
                              className=""
                            />
                          )}
                        </Field>
                      </Label>
                      <Label>
                        <Flex spacing="xxs">
                          <FormattedMessage defaultMessage="Subject" />
                          <Asterisk />
                        </Flex>
                        <Field name="subjectTitle">
                          {({ input, meta: { error, touched } }) => (
                            <>
                              <Input
                                {...input}
                                className={error && touched ? "invalid" : ""}
                                placeholder={intl.formatMessage({
                                  defaultMessage: "Enter your subject",
                                })}
                              />
                              {error && touched && (
                                <RequiredSpan>{error}</RequiredSpan>
                              )}
                            </>
                          )}
                        </Field>
                      </Label>
                      <Label>
                        <Flex spacing="xxs">
                          <FormattedMessage defaultMessage="Subject Body" />
                          <Asterisk />
                        </Flex>
                        <Field name="subjectBody">
                          {({ input, meta: { error, touched } }) => (
                            <>
                              <TextArea
                                {...input}
                                className={error && touched ? "invalid" : ""}
                                placeholder={intl.formatMessage({
                                  defaultMessage: "Write your message...",
                                })}
                              />
                              {error && touched && (
                                <RequiredSpan>{error}</RequiredSpan>
                              )}
                            </>
                          )}
                        </Field>
                      </Label>
                      <PrimaryButton
                        type="submit"
                        className="!mt-6 !rounded-[3.125rem]"
                        fullWidth
                        isLoading={submitting}
                      >
                        <FormattedMessage defaultMessage="Send" />
                      </PrimaryButton>
                      {isSuccess === true ? (
                        <SuccessMessage />
                      ) : isSuccess === false ? (
                        <FailureMessage />
                      ) : null}
                    </form>
                  );
                }}
              />
            </Flex>
          </Flex>
          {!isContactInfoExist && socialLinkContent}
        </Card>

        {isContactInfoExist && (
          <Card className="w-full flex-1 h-fit" paddingSize="xl">
            <Flex column spacing="xxl">
              {email && (
                <Flex>
                  <div className="min-w-5">
                    <EnvelopeOutlined />
                  </div>
                  <Flex column alignItems="flex-start" spacing="xs">
                    <p className="text-gray-600 text-xs m-none">
                      <FormattedMessage defaultMessage="Email" />
                    </p>
                    <StyledH3>{email}</StyledH3>
                  </Flex>
                </Flex>
              )}
              {phone && (
                <Flex>
                  <div className="min-w-5">
                    <PhoneOutlined />
                  </div>
                  <Flex column alignItems="flex-start" spacing="xs">
                    <p className="text-gray-600 text-xs m-none">
                      <FormattedMessage defaultMessage="Phone" />
                    </p>
                    <StyledH3>{phone}</StyledH3>
                  </Flex>
                </Flex>
              )}
              {address && (
                <Flex>
                  <div className="min-w-5">
                    <MarkerOutlined />
                  </div>
                  <Flex column alignItems="flex-start" spacing="xs">
                    <p className="text-gray-600 text-xs m-none">
                      <FormattedMessage defaultMessage="Address" />
                    </p>
                    <StyledH3>{address}</StyledH3>
                  </Flex>
                </Flex>
              )}
            </Flex>
            {socialLinkContent}
          </Card>
        )}
      </Flex>
    </div>
  );
};

const validate = (values: ContactFormValues) => {
  const errors: Record<string, unknown> = {};

  if (!values.name?.trim()) {
    errors.name = <FormattedMessage defaultMessage="Enter your full name" />;
  }

  if (
    !values?.email ||
    !values?.email?.match(
      /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    )
  ) {
    errors.email = (
      <FormattedMessage defaultMessage="Enter a valid email address" />
    );
  }

  if (!values.phone?.isValid) {
    errors.phone = (
      <FormattedMessage defaultMessage="Enter a valid phone number" />
    );
  }

  if (!values.subjectTitle?.trim()) {
    errors.subjectTitle = (
      <FormattedMessage defaultMessage="Enter subject title" />
    );
  }

  if (!values.subjectBody?.trim()) {
    errors.subjectBody = (
      <FormattedMessage defaultMessage="Enter subject content" />
    );
  }
  return errors;
};

export default Contact;
