import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Price } from "../../../components/Price";
import { Link } from "../../../lib/i18n";
import { EmptyState } from "../../../components/UtilityComponents/EmptyState";
import { FlexRow, Overlay } from "../../../shared/globals";
import {
  Delete,
  ShoppingBag,
  RightArrow,
  LeftArrow,
  EmptyCartIcon,
} from "../../../assets/Icons";
import {
  Container,
  CartHeader,
  FullWidth,
  StyledH3,
  ItemsContainer,
  CheckoutSection,
  MarginBottom,
  StyledSubTotal,
  CheckoutButton,
  StyledH3WithMargin,
  CloseButton,
  MobileClose,
} from "../components/SideCart/styled";
import SideCartSingleItem from "../components/SideCart/SideCartSingleItem";
import { PrimaryButton } from "../../../components/Button";
import { SideCartProps } from "../../types";
import { useCartData } from "../../../lib/cartData/useCartData";
import { isCartDirty } from "../../../shared/utils/error-utils";

const SideCart: FC<SideCartProps> = () => {
  const {
    cart: { id: cartId, isLoading, receipt, items, cartErrors, isSideCartOpen },
    setSideCartState,
  } = useCartData();

  const canNotProceedToCheckout = isCartDirty(cartErrors);

  return (
    <>
      {isSideCartOpen && <Overlay onClick={() => setSideCartState(false)} />}
      <Container isOpen={!!isSideCartOpen}>
        <MobileClose
          data-test="button-close-cart"
          onClick={() => setSideCartState(false)}
        >
          <LeftArrow />
        </MobileClose>
        <CartHeader>
          <FlexRow>
            <ShoppingBag />
            <StyledH3WithMargin>
              <FormattedMessage defaultMessage="Shopping Cart" />
            </StyledH3WithMargin>
          </FlexRow>
          <CloseButton
            data-test="button-close-cart"
            onClick={() => setSideCartState(false)}
          >
            <Delete />
          </CloseButton>
        </CartHeader>
        {!items || items?.length === 0 ? (
          <EmptyState
            title={<FormattedMessage defaultMessage="Your cart is empty" />}
            description={
              <FormattedMessage defaultMessage="Looks like you haven’t added anything to your cart yet" />
            }
            image={<EmptyCartIcon />}
          />
        ) : (
          <>
            <ItemsContainer data-test="cart-items-list">
              {items?.map((item) => (
                <SideCartSingleItem
                  key={item?.id}
                  item={item}
                  errors={cartErrors}
                />
              ))}
            </ItemsContainer>
            <CheckoutSection>
              <MarginBottom>
                <FullWidth>
                  <StyledH3>
                    <FormattedMessage defaultMessage="Subtotal" />
                  </StyledH3>
                  <StyledSubTotal data-test="text-sidecart-subtotal">
                    {isLoading ? (
                      <FormattedMessage defaultMessage="Calculating" />
                    ) : (
                      <Price money={receipt?.total} />
                    )}
                  </StyledSubTotal>
                </FullWidth>
              </MarginBottom>
              <MarginBottom>
                <Link
                  href={`/checkout/${cartId}`}
                  disabled={isLoading || canNotProceedToCheckout}
                >
                  <CheckoutButton
                    data-test="button-checkout"
                    fullWidth
                    suffixIcon={<RightArrow />}
                    onClick={() => {
                      setSideCartState(false);
                    }}
                    loadOnRouteChange
                    to={`/checkout/${cartId}`}
                    disabled={isLoading || canNotProceedToCheckout}
                  >
                    <FormattedMessage defaultMessage="Checkout" />
                  </CheckoutButton>
                </Link>
              </MarginBottom>
              <MarginBottom>
                <Link href="/cart">
                  <PrimaryButton
                    data-test="button-view-cart"
                    fullWidth
                    onClick={() => {
                      setSideCartState(false);
                    }}
                    loadOnRouteChange
                    to="cart"
                    outlined
                  >
                    <FormattedMessage defaultMessage="View Cart" />
                  </PrimaryButton>
                </Link>
              </MarginBottom>
            </CheckoutSection>
          </>
        )}
      </Container>
    </>
  );
};

export default SideCart;
