import styled, { css } from "styled-components";
import { themeColor } from "../../../../shared/styles-utils";
import { CSSProperties } from "react";

export const Input = styled.input<{
  width?: CSSProperties["width"];
  noMarginTop?: boolean;
}>`
  flex: 1 0 auto;
  background: ${themeColor("white")};
  width: ${({ width }) => width || "100%"};
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  outline: none;
  padding: 0.625rem 0.875rem;
  margin-top: ${({ noMarginTop }) => (noMarginTop ? "0" : "0.375rem")};
  font-weight: 600;
  &.invalid,
  &.invalid:focus {
    border: 1px solid #d21c1c;
  }
  ${(props) =>
    props.type === "checkbox" &&
    css`
      flex: initial;
      width: initial;
      margin: 0 0.5rem 0.125rem 0;
    `} &::placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
    font-weight: 400;
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
    font-weight: 400;
  }
  &:-moz-placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
    font-weight: 400;
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
    font-weight: 400;
  }
  &:focus {
    border: 1px solid ${themeColor("primary")};
  }
  /* Chrome, Safari, Edge, Opera, Firefox */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  -moz-appearance: textfield;
`;
export const TextArea = styled.textarea`
  flex: 1 0 auto;
  width: 100%;
  min-height: 180px;
  background: ${themeColor("white")};
  font-weight: 600;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.bg.inactive};
  border-radius: 0.25rem;
  outline: none;
  padding: 0.75rem 0.875rem;
  margin-top: 0.438rem;
  box-shadow: none;
  resize: vertical;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &.invalid {
    border: 1px solid #d21c1c;
  }
  &::placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
    font-weight: 400;
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
    font-weight: 400;
  }
  &:-moz-placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
    font-weight: 400;
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
    font-weight: 400;
  }
  &:focus {
    border: 1px solid ${themeColor("primary")};
  }
`;
