import type { ModifiedTemplate } from "../default";
import Logo from "./elements/Logo";
import SectionCardWrapper from "./elements/SectionCardWrapper";
import GridGallerySection from "./elements/GridGallerySection";
import CollectionCard from "./elements/CollectionCard";

const farahOmarTemplate: ModifiedTemplate = {
  elements: {
    // SHARED
    Logo,
    SectionCardWrapper,
    GridGallerySection,
    CollectionCard,
  },
};

export default farahOmarTemplate;
