import { Link } from "../../../../lib/i18n";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CouponIcon } from "../../../../assets/Icons";
import { DefaultTextPrice } from "../../../../components/Price";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { getTranslatedDiscountTitle } from "../../../../shared/utils/getTranslatedDiscountTitle";
import { ItemInfo, SelectedOptions, Title } from "./styled";
import { getLocaleInfo } from "../../../../lib/i18n/locales-data";
import { Badge } from "../../../../shared/globals/UiElements/Badge";
import {
  CartCustomItem,
  CartFragment,
  CartSimpleItem,
  ProductType,
} from "../../../../generated/graphql";
import { FlexCol } from "../../../../shared/globals";
import CartSummaryPanel from "../../../default/components/customComponents/CartSummaryPanel";

interface CartItemInfoProps {
  item: CartFragment["items"][0];
  isCustomDiscountApplied: boolean;
  isAutomaticDiscountApplied: boolean;
  discountedSimpleItemsCount: number;
  isAllItemsDiscounted: boolean;
}

export const CartItemInfo = ({
  item,
  isCustomDiscountApplied,
  isAutomaticDiscountApplied,
  discountedSimpleItemsCount,
  isAllItemsDiscounted,
}: CartItemInfoProps) => {
  const { locale } = useIntl();
  const activeLocale = getLocaleInfo(locale).code;
  const typedSimpleItem = item as CartSimpleItem;
  const typedCustomItem = item as CartCustomItem;
  const isItemSimple = item?.product?.type === ProductType.Simple;
  return (
    <ItemInfo alignItems="flex-start">
      <FlexCol alignItems="flex-start" spacing="xxs">
        {isCustomDiscountApplied && (
          <Badge
            type="ink"
            label={
              <Flex alignItems="center" spacing="xs">
                <CouponIcon />
                {typedCustomItem?.discount?.info?.percentage ? (
                  <FormattedMessage
                    defaultMessage="SAVE {percent}%"
                    values={{
                      percent: typedCustomItem?.discount?.info?.percentage,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="SAVE {fixed}"
                    values={{
                      fixed: (
                        <DefaultTextPrice
                          money={typedCustomItem?.discount?.info?.fixed}
                        />
                      ),
                    }}
                  />
                )}
              </Flex>
            }
          />
        )}
        {isAutomaticDiscountApplied && (
          <Flex flexWrap="wrap" spacing="s">
            {typedSimpleItem?.discounts?.map((discount, idx) => (
              <Badge
                key={idx}
                type="ink"
                label={
                  <Flex alignItems="center" spacing="xs">
                    <CouponIcon />
                    {getTranslatedDiscountTitle({
                      defaultTitle: discount?.data?.title,
                      currentLocale: activeLocale,
                      translations: discount?.data?.translations,
                    })}
                  </Flex>
                }
              />
            ))}
          </Flex>
        )}

        <Title data-test="text-item-title">
          <Link
            title={item?.product?.title}
            href={`/product/all/${item.product?.handle}`}
          >
            {item?.product?.title}
          </Link>
        </Title>

        {!isItemSimple && (
          <CartSummaryPanel
            customItem={typedCustomItem}
            isUpdating={true}
            isCustomDiscountApplied={isCustomDiscountApplied}
            customItemDiscount={typedCustomItem.discount?.info}
          />
        )}
      </FlexCol>

      <SelectedOptions>
        {typedSimpleItem?.variant?.sku && (
          <FormattedMessage
            defaultMessage="SKU: {sku}"
            values={{ sku: typedSimpleItem?.variant?.sku }}
          />
        )}
        <div>
          {isAutomaticDiscountApplied && !isAllItemsDiscounted && (
            <span>{discountedSimpleItemsCount}x </span>
          )}
          {(typedSimpleItem?.variant?.selectedOptions || [])
            ?.map((option) => option.value.name)
            .join(" / ")}
        </div>
      </SelectedOptions>
    </ItemInfo>
  );
};
