import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";
import styled from "styled-components";
import { Delete } from "../../../../assets/Icons";
import {
  useGetFiltersQuery,
  ProductAttributeValue,
  ProductCollection,
  ProductOptionValue,
} from "../../../../generated/graphql";
import { useEscapeAndStopScrollingEffect } from "../../../../hooks/useEscapeAndStopScrollingEffect";
import { useStore } from "../../../../lib/storeData";
import { Overlay, H3, FlexCol, FlexRow } from "../../../../shared/globals";
import { themeColor, rtl } from "../../../../shared/styles-utils";
import useBreakPoints from "../../../../shared/utils/useBreakPoints";
import { DefaultFilters } from "../../../default/components/Filter/consts";
import { ApplyFilterSection } from "./components/ApplyFilterSection";
import FiltersSection from "./components/FiltersSection";
import { ZIndex } from "../../../../shared/globals/types";
import { getAvailableFilters } from "../../../../shared/utils/filterHelperFunctions";

interface FilterProps {
  isOpen?: boolean;
  onClose?: () => void;
  loading?: boolean;
  productsTotalCount?: number;
}

const Filter = ({
  isOpen,
  onClose,
  loading,
  productsTotalCount,
}: FilterProps) => {
  const router = useRouter();
  const { storeId } = useStore();
  const intl = useIntl();
  const { isTablet } = useBreakPoints();

  const [selectedFilters, setSelectedFilters] = useState<Map<string, boolean>>(
    new Map()
  );

  const { data, error } = useGetFiltersQuery({
    variables: {
      storeId,
    },
  });

  const selectedFiltersIds = useMemo(
    () =>
      typeof router.query.filters === "string"
        ? [router.query.filters]
        : router.query.filters || [],
    [router.query.filters]
  );

  // update selectedFilters state
  useEffect(() => {
    if (selectedFiltersIds.length) {
      selectedFiltersIds.forEach((id) => {
        const collectionId = id.startsWith("c");
        const valueId = id.split("|")[1];

        if (collectionId) {
          setSelectedFilters((prevState) => prevState.set("c", true));
        }

        if (valueId) {
          setSelectedFilters((prevState) => prevState.set(valueId, true));
        }
      });
    }

    if (router.query.minPrice || router.query.maxPrice) {
      setSelectedFilters((prevState) =>
        prevState.set(DefaultFilters.Price, true)
      );
    }
  }, [router.query.maxPrice, router.query.minPrice, selectedFiltersIds]);

  useEscapeAndStopScrollingEffect({ isOpen: isOpen!, onClose });

  const { filtersDictionary } = getAvailableFilters({
    attributesProductsDistribution: data?.getApplicableFilters
      ?.attributeValues! as ProductAttributeValue[],
    collectionsProductsDistribution: data?.getApplicableFilters
      ?.collections as ProductCollection[],
    optionsProductsDistribution: data?.getApplicableFilters
      ?.optionValues as ProductOptionValue[],
    intl,
  });

  return (
    <>
      {isOpen && <Overlay onClick={onClose} />}
      <Container isOpen={isOpen!} alignItems="start">
        <StyledFlexRow fullWidth justifyContent="space-between">
          <H3 fontWeight={700}>
            <FormattedMessage defaultMessage="Filter by" />
          </H3>
          <CloseIcon onClick={onClose}>
            <Delete />
          </CloseIcon>
        </StyledFlexRow>
        <FiltersSection
          availableFilters={filtersDictionary}
          selectedFiltersIds={selectedFiltersIds}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          loading={loading!}
          error={error}
        />
        {isTablet && (
          <ApplyFilterSection
            onClose={onClose!}
            totalCount={productsTotalCount || 0}
            loading={loading!}
          />
        )}
      </Container>
    </>
  );
};

export default Filter;

// Styles

const Container = styled(FlexCol)<{ isOpen: boolean }>`
  min-width: 250px;
  width: 250px;
  margin-inline-end: 40px;
  background-color: ${themeColor("white")};

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    ${rtl("right: 0;", "left: 0;")}
    height: 100%;
    width: 80vw;
    max-width: 340px;
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.2);
    z-index: ${ZIndex["OVERLAY"]};
    margin-inline-end: unset;
    transition: all 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    transform: ${({ isOpen }) =>
      isOpen ? "translateX(0)" : rtl("translateX(120%)", "translateX(-120%)")};
  }
`;

const StyledFlexRow = styled(FlexRow)`
  @media (max-width: 768px) {
    padding: 20px;
    box-shadow: 0px 4px 4px 0px rgba(226, 226, 226, 0.5);
    z-index: ${ZIndex["DEFAULT"]};
  }
`;

const CloseIcon = styled.span`
  opacity: 0.4;
  @media (min-width: 768px) {
    display: none;
  }
`;
