import {
  CartItemInput,
  CartItemType,
} from "../../../contexts/CartContext/types";
import {
  CustomItem,
  GetOrderByIdQuery,
  OrderItemDiscountInfoFragment,
  OrderVariantSnapshotFragment,
  SelectedVariant,
  SourceType,
} from "../../../generated/graphql";
import { subtractMoney } from "../../../shared/utils/money";
import type { CartBuildData } from "../../../templates/types";

export const transformOrderItems = (order: GetOrderByIdQuery["order"]) => {
  const itemsToFit: CartItemType[] = (order?.items || [])?.map?.((item) => {
    if (item?.__typename === "SimpleItem") {
      const price = {
        currencyCode: item?.subtotal?.currencyCode!,
        amount: item?.subtotal?.amount! / (item?.quantity || 1),
      };

      return {
        collectionIds: (item?.productSnapshot?.collectionIds as string[]) || [],
        buildData: null,
        customDiscount: null,
        productId: item?.productSnapshot?.id!,
        variantId: item?.variantSnapshot?.id!,
        title: item?.title! || item?.productSnapshot?.title!,
        sku: item?.variantSnapshot?.sku,
        selectedOptions: item?.selectedOptions,
        quantity: item?.quantity || 1,
        availableQuantity: {
          type: "infinity",
          value: Number.POSITIVE_INFINITY,
        },
        source:
          item?.productSnapshot?.integrationProvider?.provider ||
          SourceType.Manual,
        price,
        img: item?.variantSnapshot?.image || item?.productSnapshot?.images?.[0],
        subtotal: item?.subtotal!,
        total: subtractMoney(item?.subtotal!, item?.discount?.total),
        handle: item?.productSnapshot?.handle!,
        collectionNames: [],

        discount: item?.discount,
        discounts: item?.discounts || [],
      } as CartItemType;
    }

    // IN CASE OF CUSTOM ITEM
    const buildData = prepareCartBuildState(item as CustomItem);

    const customDiscount = item?.discount?.perItem?.amount
      ? {
          title: "custom-product-discount",
          percentage: item?.discount?.info?.percentage,
          fixed: item?.discount?.info?.fixed,
          quantity: item?.quantity,
          itemDiscount: item?.discount?.perItem,
          totalDiscount: item?.discount?.total,
        }
      : null;

    return {
      collectionIds: (item?.productSnapshot?.collectionIds as string[]) || [],
      productId: item?.productSnapshot?.id!,
      variantId: `build-${item?.productSnapshot?.id}`,
      title: item?.productSnapshot?.title!,
      sku: "",
      selectedOptions: [],
      quantity: item?.quantity || 1,
      availableQuantity: { type: "infinity", value: Number.POSITIVE_INFINITY },
      source:
        item?.productSnapshot?.integrationProvider?.provider ||
        SourceType.Manual,
      img: item?.productSnapshot?.images?.[0],
      buildData,
      price: item?.price!,
      customDiscount,
      subtotal: item?.subtotal!,
      total: subtractMoney(item?.subtotal!, item?.discount?.total),
      handle: item?.productSnapshot?.handle!,
      collectionNames: [],
    } as CartItemInput;
  });

  return itemsToFit;
};

function getDiscount(
  discount: OrderItemDiscountInfoFragment | undefined | null
) {
  if (discount?.info?.fixed?.amount || discount?.info?.percentage) {
    return {
      fixed: discount?.info?.fixed,
      percent: discount?.info?.percentage,
    };
  }
  return undefined;
}

function getImage(
  selectedVariant: OrderVariantSnapshotFragment | undefined | null
) {
  return (
    selectedVariant?.image?.src ||
    selectedVariant?.productImages?.[0]?.src ||
    ""
  );
}

function getSelectedOptions(selectedVariant: SelectedVariant) {
  return selectedVariant.selectedOptions.map((option) => ({
    option: { id: option?.name, name: option?.name },
    value: { id: option?.value, name: option?.value },
  }));
}

function prepareCartBuildState(item: CustomItem): CartBuildData | null {
  if (item?.__typename !== "CustomItem") {
    return null;
  }

  const buildCategories = item?.categories?.map((category) => {
    const selectedVariantsForEachCategory = category?.selectedVariants?.map(
      (selectedVariant) => ({
        imageSrc: getImage(selectedVariant.variantSnapshot),
        maxQuantity:
          selectedVariant.variantSnapshot?.quantity || Number.POSITIVE_INFINITY,
        price: selectedVariant.price!,
        productId: selectedVariant.variantSnapshot?.productId!,
        quantity: selectedVariant.quantity,
        selectedOptions: getSelectedOptions(selectedVariant),
        title: selectedVariant.variantSnapshot?.productTitle!,
        id: selectedVariant.variantSnapshot?.id!,
        sku: selectedVariant.variantSnapshot?.sku,
      })
    );
    return {
      selectedVariants: selectedVariantsForEachCategory,
      id: category.category.id,
      name: category.category.name,
      imageSrc: category.category.image?.src || "",
      type: category.category.categoryType,
    };
  });

  return {
    id: item.id,
    buildId: null,
    handle: item.productSnapshot?.handle!,
    title: item.title!,
    img: item.productSnapshot?.images?.[0],
    discount: getDiscount(item?.discount),
    categories: buildCategories,
    total: subtractMoney(item?.subtotal!, item?.discount?.total),
    subtotal: item?.subtotal!,
  };
}
