import * as React from "react";
import styled from "styled-components";
import { AddIcon, HyphenIcon } from "../../../../assets/Icons";
import { themeColor } from "../../../../shared/styles-utils";
import { NavigationLink } from "../../../default/components/NavBar/NavigationLink";
import type {
  MenuProps,
  MenuWithChildrenType,
  MenuItemProps,
} from "../../../default/components/NavBar/menu/types";
import { FlexRow } from "../../../../shared/globals";
import HTMLRender from "../../../../shared/globals/UiElements/HTMLRender";

function checkIsActive(activePath: string[], pageId: string): boolean {
  return pageId === null ? true : activePath.indexOf(pageId) !== -1;
}

const MobileMenu = ({ items }: { items: MenuWithChildrenType }) => {
  const [activePath, setActivePath] = React.useState<string[]>([]);

  const handleOpenSubMenu = React.useCallback<MenuProps["onOpenSubMenu"]>(
    (parentId, level) => {
      const newPath = [...activePath];
      newPath[level - 1] = parentId!;
      setActivePath(newPath);
    },
    [activePath]
  );

  const handleCloseSubMenu = React.useCallback<(level: number) => void>(
    (level) => {
      setActivePath(activePath.splice(0, level - 1));
    },
    [activePath]
  );

  return (
    <Menu
      parentId={null}
      activePath={activePath}
      onCloseSubMenu={handleCloseSubMenu}
      onOpenSubMenu={handleOpenSubMenu}
      menu={items}
      level={1}
    />
  );
};

const Menu = ({
  activePath,
  parentId,
  onOpenSubMenu,
  onCloseSubMenu,
  menu,
  level,
}: MenuProps) => {
  const isOpen = checkIsActive(activePath, parentId!);

  if (!menu) return null;

  return (
    <StyledMenu key={parentId! + level} level={level} isOpen={isOpen}>
      {menu.map((item) => (
        <MenuItem
          activePath={activePath}
          onOpenSubMenu={onOpenSubMenu}
          onCloseSubMenu={onCloseSubMenu}
          isActive={checkIsActive(activePath, item.id)}
          key={item.id}
          level={level}
          item={item}
        />
      ))}
    </StyledMenu>
  );
};

const MenuItem = ({
  item,
  isActive,
  level,
  activePath,
  onOpenSubMenu,
  onCloseSubMenu,
}: MenuItemProps) => {
  const handleOpenSubMenu = React.useCallback(() => {
    if (isActive) {
      if (onCloseSubMenu) onCloseSubMenu(level);
    } else {
      onOpenSubMenu(item.id, level);
    }
  }, [isActive, item.id, level, onCloseSubMenu, onOpenSubMenu]);

  return (
    <StyledMenuItem level={level}>
      <StyledMenuItemContent level={level} isActive={isActive} spacing="xs">
        {item.type === "Folder" ? (
          <div onClick={handleOpenSubMenu}>
            <NavigationLink {...item.link} type={item?.link?.type!}>
              <HTMLRender html={item?.icon} />
              {item.title}
            </NavigationLink>
          </div>
        ) : (
          <NavigationLink {...item.link} type={item?.link?.type!}>
            <HTMLRender html={item?.icon} />
            {item.title}
          </NavigationLink>
        )}
        {!!item?.children?.length && (
          <span onClick={handleOpenSubMenu}>
            {isActive ? (
              <HyphenIcon width={17} height={17} />
            ) : (
              <AddIcon width={17} height={17} />
            )}
          </span>
        )}
      </StyledMenuItemContent>
      {!!item?.children?.length && (
        <Menu
          parentId={item.id}
          activePath={activePath}
          menu={item.children!}
          level={level + 1}
          onCloseSubMenu={onCloseSubMenu}
          onOpenSubMenu={onOpenSubMenu}
        />
      )}
    </StyledMenuItem>
  );
};

export default MobileMenu;

/**
 *
 *
 * Styles
 *
 *
 */

const StyledMenu = styled.ul<{ isOpen: boolean; level: number }>`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  padding-inline-start: ${({ level }) => (level === 1 ? 0 : "16px")};
  width: 100%;
  margin: 0;
  gap: ${({ level }) => level === 1 && "5px"};
`;

const StyledMenuItem = styled.li<{
  level: number;
}>`
  display: flex;
  flex-direction: column;
  font-size: ${({ level }) => 16 - (level - 1) * 2}px;
  cursor: pointer;
`;

const StyledMenuItemContent = styled(FlexRow)<{
  isActive: boolean;
  level: number;
}>`
  background-color: ${({ level }) =>
    level === 1 ? themeColor("white") : "transparent"};
  font-weight: ${({ level }) => 700 - level * 100};
  padding: 16px;
  > a,
  > div {
    flex: 1;
  }
`;
