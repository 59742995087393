import {
  CartCustomItem,
  CartFragment,
  CartSimpleItem,
  ProductType,
} from "../../generated/graphql";

export function createItemEvent(
  item: CartFragment["items"][0],
  customerId: string | undefined
) {
  const isItemSimple = item?.product?.type === ProductType.Simple;

  const typedSimpleItem = item as CartSimpleItem;
  const typedCustomItem = item as CartCustomItem;

  if (isItemSimple) {
    return createSimpleItemEvent(typedSimpleItem, customerId);
  } else {
    return createCustomItemEvent(typedCustomItem, customerId);
  }
}

export function createSimpleItemEvent(
  simpleItem: CartSimpleItem,
  customerId: string | undefined
) {
  const selectedOptions = simpleItem.variant.selectedOptions
    .map((option) => option.value.name)
    .join(" / ");
  return {
    variant: selectedOptions,
    content_type: "product",
    content_ids: [simpleItem.variant.id],
    content_name: selectedOptions
      ? `${simpleItem?.product?.title} - ${selectedOptions}`
      : simpleItem?.product?.title,
    num_items: simpleItem.quantity || 1,
    value: simpleItem.price.amount,
    currency: simpleItem.price.currencyCode,
    sku: simpleItem.variant.sku || "",
    imgSrc:
      simpleItem.variant?.image?.src ||
      simpleItem.product?.images[0]?.src ||
      "",
    collectionNames: simpleItem.product?.collections?.nodes?.map(
      (collection) => collection?.title
    ),
    handle: simpleItem.product.handle,
    customerId: customerId || "",
  };
}

export function createCustomItemEvent(
  customItem: CartCustomItem,
  customerId: string | undefined
) {
  return {
    variant: "",
    content_type: "product",
    content_ids: [customItem.product.id],
    content_name: customItem?.product?.title,
    num_items: customItem.quantity || 1,
    value: customItem.price.amount,
    currency: customItem.price.currencyCode,
    sku: "",
    imgSrc: customItem.product?.images[0]?.src || "",
    collectionNames: customItem.product?.collections?.nodes?.map(
      (collection) => collection?.title
    ),
    handle: customItem.product.handle,
    customerId: customerId || "",
  };
}
