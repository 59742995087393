import {
  ApolloClient,
  ApolloQueryResult,
  NormalizedCacheObject,
} from "@apollo/client";
import {
  CartDocument,
  CartQuery,
  CartQueryVariables,
} from "../../generated/graphql";

export * from "./CartContext";

const isServer = typeof window === "undefined";
// @ts-ignore
const windowCartData = !isServer && window.__NEXT_DATA__.cartData;

export async function getCartData({
  apolloClient,
  storeId,
  sessionId,
  cartId,
}: {
  apolloClient?: ApolloClient<NormalizedCacheObject>;
  storeId: string;
  sessionId: string;
  cartId?: string;
}): Promise<any> {
  if (windowCartData) {
    return windowCartData;
  }

  if (!apolloClient) {
    throw new Error("getCartData: apolloClient is required");
  }
  const query: ApolloQueryResult<CartQuery> = await apolloClient?.query<
    CartQuery,
    CartQueryVariables
  >({
    query: CartDocument,
    errorPolicy: "ignore",
    variables: { storeId, cartId },
    context: {
      headers: { sessionId },
    },
    fetchPolicy: "no-cache",
  });

  const { data, error } = query;
  if (error) {
    throw error;
  }

  const cartData = data?.customerQueries?.cart;
  return cartData;
}
