import React from "react";
import { FormattedMessage } from "react-intl";
import { C500V2 } from "../../../../assets/Icons";
import { SvgWrapper } from "./PageNotFound";
import { PrimaryButton } from "../../../../components/Button";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { P } from "../../../../shared/globals";
import { EmptyState } from "../../../../components/UtilityComponents/EmptyState";

function UnknownError() {
  return (
    <Flex height="100vh" column justifyContent="center" alignItems="center">
      <EmptyState
        descriptionFullWidth
        image={
          <SvgWrapper svgWidth="400px">
            <C500V2 />
          </SvgWrapper>
        }
        title={<FormattedMessage defaultMessage="Something went wrong!" />}
        description={
          <P
            style={{
              opacity: "0.5",
            }}
          >
            <FormattedMessage defaultMessage="Sorry we’re unable to find the page you’re looking for." />
          </P>
        }
        button={
          <PrimaryButton
            href="/shop"
            style={{
              minWidth: "300px",
            }}
          >
            <FormattedMessage defaultMessage="Go back to Shop" />
          </PrimaryButton>
        }
      />
    </Flex>
  );
}

export default UnknownError;
