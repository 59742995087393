import styled from "styled-components";
import { FlexCol, FlexRow, H2, H3, H4 } from "../../../../shared/globals";
import { rtl, themeColor } from "../../../../shared/styles-utils";
import {
  StyledForm,
  RequiredSpan,
} from "../../../../components/Checkout/Information/styled";

export const FeedbackMessageContainer = styled(FlexRow)<{ isSuccess: boolean }>`
  background: ${({ isSuccess }) => (isSuccess ? " #f0fdfa" : "#FFEDF6")};
  padding: 10px 20px;
  border: 1px solid #ccfbf1;
  border-radius: 6px;
  margin: 0 20px;

  > h4 {
    color: ${({ isSuccess }) => (isSuccess ? " #115e59" : "#d21c1c")};
  }

  > svg {
    ${rtl("margin-left", "margin-right")}: 10px;
  }
`;

export const Container = styled(FlexCol)`
  padding: 30px 0;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const StyledH4 = styled(H4)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  width: 100%;
  margin-bottom: 30px;
  max-width: 400px;
`;

export const StyledSection = styled(FlexCol)<{
  isColumn: boolean;
}>`
  @media (min-width: 1024px) {
    flex: 1 1 50%;
    flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
  }
`;

export const ContactForm = styled(FlexCol)`
  width: 100%;
  @media (min-width: 1024px) {
    flex: 1 1 50%;
    ${rtl("margin-left", "margin-right")}: 17.5px;
  }
`;

export const ContactInfo = styled(FlexCol)`
  width: 100%;
  margin-top: 1.5rem;
  @media (min-width: 1024px) {
    width: calc(50% - 17.5px);
    ${rtl("margin-right", "margin-left")}: 17.5px;
    margin-top: 2.4rem;
  }
`;

export const AddressBook = styled(FlexCol)`
  width: 100%;
`;

export const InfoHolder = styled(FlexRow)`
  align-items: flex-start;
  margin-bottom: 59px;

  &:nth-child(3) {
    margin-bottom: 0;
  }
`;

export const Icon = styled(FlexCol)`
  min-width: 20px;
`;

export const Info = styled(FlexCol)`
  ${rtl("margin-right", "margin-left")}: 10px;
  h3 {
    margin: 5px 0 0;
  }
`;

export const StyledH3 = styled(H3)`
  color: ${themeColor("primary")};
`;

export const HorizontalLine = styled.hr`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.bg.wash}1a;
  margin: 30px 0 0;
`;

export const TextFollow = styled(H2)`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  margin: 20px 0 18px;
`;

export { StyledForm, RequiredSpan };
