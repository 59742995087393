import React from "react";
import {
  CartFragment,
  CartStatusEnum,
  CartStepEnum,
  CurrencyCode,
} from "../../generated/graphql";
import { reducer } from "./reducer/reducer";
import { Context } from "./context";
import { ShippingDestination } from "../../components/UtilityComponents/ShippingDestinationSelect";
import { PhoneInput } from "../../shared/globals/UiElements/InputPhone";

export interface ShippingInformationProps {
  address: string;
  apartment: string;
  shippingDestination: ShippingDestination;
  postalCode: string;
  notes: string;
  secondPhone: PhoneInput;
}
export type CartContextType = CartFragment & {
  isSideCartOpen?: boolean;
  isCartPaymentPopUpOpen?: boolean;
  isCartEmptyPopUpOpen?: boolean;
  isLoading?: boolean;
  recoveryCart?: CartFragment | null;
  shippingInformation?: ShippingInformationProps;
};

export const defaultValue: CartContextType = {
  contactInfo: {},
  cartStep: CartStepEnum.Shopping,
  lastStep: CartStepEnum.Shopping,
  id: "",
  items: [],
  createdAt: "",
  recoveryId: "",
  receipt: {
    automaticDiscount: {
      amount: 0,
      currencyCode: CurrencyCode.Egp,
    },
    subtotal: {
      amount: 0,
      currencyCode: CurrencyCode.Egp,
    },
    total: {
      amount: 0,
      currencyCode: CurrencyCode.Egp,
    },
    tax: {
      amount: 0,
      currencyCode: CurrencyCode.Egp,
    },
    discount: {
      amount: 0,
      currencyCode: CurrencyCode.Egp,
    },
    shipping: {
      amount: 0,
      currencyCode: CurrencyCode.Egp,
    },
  },
  sessionId: "",
  status: CartStatusEnum.Active,
  updatedAt: "",
  cartErrors: [],
  customer: null,
  isReserved: false,
  reservedAt: "",
  shippingDetails: null,
  isSideCartOpen: false,
  isCartPaymentPopUpOpen: false,
  isCartEmptyPopUpOpen: false,
  isLoading: false,
  recoveryCart: null,
  shippingInformation: {
    address: "",
    apartment: "",
    notes: "",
    postalCode: "",
    secondPhone: {
      isValid: false,
      value: "",
    },
    shippingDestination: {},
  },
};

const CartProvider: React.FC<{
  children?: React.ReactNode;
  initialCartData: CartFragment;
}> = ({ initialCartData, children }) => {
  const cartContextWithAdditionalData = {
    ...initialCartData,
    isSideCartOpen: false,
    isCartPaymentPopUpOpen: false,
    isCartEmptyPopUpOpen: false,
    isLoading: false,
    recoveryCart: null,
    shippingInformation: {
      address: "",
      apartment: "",
      notes: "",
      postalCode: "",
      secondPhone: {
        isValid: false,
        value: "",
      },
      shippingDestination: {},
    },
  };

  const value = React.useReducer(
    reducer(defaultValue),
    cartContextWithAdditionalData
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { CartProvider };
