import styled from "styled-components";

export const StyledForm = styled.form`
  width: 100%;
`;

export const ContactSection = styled.div`
  width: 100%;
`;

export const RelativeDiv = styled.div`
  position: relative;
`;

export const RequiredSpan = styled.span`
  position: absolute;
  bottom: -18px;
  left: 0;
  color: #d21c1c;
  font-size: 12px;
  font-weight: 400;
`;
