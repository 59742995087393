export const EditIcon = ({ ink }: { ink?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M15.5002 8.33326L12.1669 4.99993M2.5835 17.9166L5.4038 17.6032C5.74838 17.5649 5.92067 17.5458 6.0817 17.4937C6.22457 17.4474 6.36054 17.3821 6.4859 17.2994C6.62721 17.2062 6.74978 17.0836 6.99493 16.8385L18.0002 5.83326C18.9207 4.91279 18.9207 3.4204 18.0002 2.49993C17.0797 1.57945 15.5873 1.57945 14.6669 2.49992L3.6616 13.5052C3.41645 13.7503 3.29388 13.8729 3.20069 14.0142C3.11802 14.1396 3.05268 14.2755 3.00642 14.4184C2.95429 14.5794 2.93515 14.7517 2.89686 15.0963L2.5835 17.9166Z"
        stroke={ink ? ink : "#667085"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
