import HeadingSection from "../components/customComponents/HeadingSection";
import SummaryPanel from "../components/customComponents/SummaryPanel";
import SummarySection from "../components/customComponents/SummarySection";
import { FlexRow } from "../../../shared/globals";
import { Card } from "../../../shared/globals/UiElements/Card";
import type {
  BuildCustomState,
  CustomProductInfoProps,
  TemplateElement,
} from "../../types";
import { StoreTemplate } from "../../TemplateLoader";
import { CustomProductDiscount } from "../../../generated/graphql";

const DesktopCustomProduct: TemplateElement<CustomProductInfoProps> = ({
  product,
  buildState,
  isSummary,
  activeCategoryId,
  buildTotal,
  buildSubtotal,
  isDiscount,
  isAlertOpen,
  exceededVariantId,
  setExceededVariantId,
  setIsAlertOpen,
  handleAddToCart,
  prepareCartBuildState,
  setActiveCategoryId,
  setBuildState,
  setIsSummary,
  isLoading,
  isUpdating,
}) => {
  const Template = StoreTemplate.get();
  const activeCategory = buildState?.find(
    (category) => category?.id === activeCategoryId
  );
  return (
    <>
      <HeadingSection product={product} />
      <FlexRow alignItems="flex-start">
        <Card paddingSize="none" className="flex-shrink-0 basis-3/4 mb-14 me-5">
          <FlexRow
            flexGrow={1}
            alignItems="unset"
            style={{ position: "relative" }}
          >
            <Template.elements.CategoriesSection
              buildState={buildState!}
              activeCategory={activeCategory!}
              setActiveCategoryId={setActiveCategoryId!}
            />
            <Template.elements.VariantsSection
              buildState={buildState!}
              setBuildState={setBuildState!}
              activeCategoryId={activeCategoryId!}
              exceededVariantId={exceededVariantId}
              setExceededVariantId={setExceededVariantId}
              setActiveCategoryId={setActiveCategoryId!}
              activeCategory={activeCategory!}
              productId={product.id}
            />
          </FlexRow>
        </Card>
        <SummarySection
          product={product}
          isAlertOpen={isAlertOpen}
          setIsAlertOpen={setIsAlertOpen}
          setIsSummary={setIsSummary!}
          buildTotal={buildTotal!}
          buildSubtotal={buildSubtotal!}
          isDiscount={isDiscount}
          onAddToCart={handleAddToCart!}
          buildState={buildState!}
          loading={isLoading}
          isUpdating={isUpdating}
        />
      </FlexRow>
      <SummaryPanel
        buildState={
          prepareCartBuildState?.(buildState!, product)! as BuildCustomState
        }
        inProductPage
        isOpen={!!isSummary}
        onClose={() => setIsSummary?.(false)}
        onAddToCart={handleAddToCart}
        customItemDiscount={product.discount! as CustomProductDiscount}
        isCustomDiscountApplied={isDiscount}
        isUpdating={isUpdating}
        isLoading={isLoading}
      />
    </>
  );
};

export default DesktopCustomProduct;
