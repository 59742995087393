import { useState } from "react";
import CartItemDesktop from "../components/Cart/CartItemDesktop";
import CartItemMobile from "../components/Cart/CartItemMobile";
import {
  CartCustomItem,
  CartSimpleItem,
  DiscountSource,
  ProductType,
} from "../../../generated/graphql";
import { useStore } from "../../../lib/storeData";
import useBreakPoints from "../../../shared/utils/useBreakPoints";
import { CartItemProps } from "../../types";
import { useCartData } from "../../../lib/cartData/useCartData";
import { generateItemRelatedErrors } from "../../../shared/utils/error-utils";

const CartItem = ({ item, errors }: CartItemProps) => {
  const {
    updateSimpleItem: { updateSimpleItem },
    removeSimpleItem: { removeSimpleItem },
    removeCustomItem: { removeCustomItem },
    updateCustomItem: { updateCustomItem },
  } = useCartData();
  const { isTablet } = useBreakPoints();
  const { currency: currencyCode } = useStore();

  const [value, setValue] = useState(item.quantity);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const isItemSimple = item?.product?.type === ProductType.Simple;

  const itemRelatedErrors = generateItemRelatedErrors(
    item,
    currencyCode,
    errors
  );

  const typedSimpleItem = item as CartSimpleItem;
  const typedCustomItem = item as CartCustomItem;

  const isCustomDiscountApplied =
    typedCustomItem?.discount?.discountSource ===
      DiscountSource.CustomDiscount &&
    (!!typedCustomItem?.discount?.info?.fixed?.amount ||
      !!typedCustomItem?.discount?.info?.percentage);
  const isAutomaticDiscountApplied = !!typedSimpleItem?.discounts?.length;

  const discountedSimpleItemsCount =
    (isAutomaticDiscountApplied && typedSimpleItem?.discount?.quantity) || 0;

  const nonDiscountedSimpleItemsCount = isAutomaticDiscountApplied
    ? typedSimpleItem?.quantity - discountedSimpleItemsCount
    : 0;

  const isAllItemsDiscounted = isAutomaticDiscountApplied
    ? nonDiscountedSimpleItemsCount === 0
    : false;

  const handleQuantityChange = async (value: number) => {
    if (isItemSimple)
      return await updateSimpleItem({
        item: {
          quantity: value,
          variantId: typedSimpleItem?.variant?.id,
        },
      });

    return await updateCustomItem({
      item: {
        quantity: value,
        productId: typedCustomItem?.product?.id,
      },
    });
  };

  const handleRemoveCartItem = async () => {
    if (isItemSimple) {
      await removeSimpleItem(typedSimpleItem?.variant?.id);
    } else {
      await removeCustomItem({
        item: {
          productId: typedCustomItem?.product?.id,
        },
      });
    }
  };

  return (
    <>
      {isTablet ? (
        <CartItemMobile
          item={item}
          isCustomDiscountApplied={isCustomDiscountApplied}
          isAutomaticDiscountApplied={isAutomaticDiscountApplied}
          discountedSimpleItemsCount={discountedSimpleItemsCount}
          nonDiscountedSimpleItemsCount={nonDiscountedSimpleItemsCount}
          isAllItemsDiscounted={isAllItemsDiscounted}
          itemErrors={itemRelatedErrors}
          value={value}
          setValue={setValue}
          isAlertOpen={isAlertOpen}
          setIsAlertOpen={setIsAlertOpen}
          onQuantityChange={handleQuantityChange}
          onRemoveCartItem={handleRemoveCartItem}
        />
      ) : (
        <CartItemDesktop
          item={item}
          isCustomDiscountApplied={isCustomDiscountApplied}
          isAutomaticDiscountApplied={isAutomaticDiscountApplied}
          discountedSimpleItemsCount={discountedSimpleItemsCount}
          nonDiscountedSimpleItemsCount={nonDiscountedSimpleItemsCount}
          isAllItemsDiscounted={isAllItemsDiscounted}
          itemErrors={itemRelatedErrors}
          value={value}
          setValue={setValue}
          isAlertOpen={isAlertOpen}
          setIsAlertOpen={setIsAlertOpen}
          onQuantityChange={handleQuantityChange}
          onRemoveCartItem={handleRemoveCartItem}
        />
      )}
    </>
  );
};
export default CartItem;
