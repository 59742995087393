const Mail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M2.1665 5.8335L8.9706 10.5964C9.52158 10.982 9.79707 11.1749 10.0967 11.2496C10.3614 11.3156 10.6383 11.3156 10.903 11.2496C11.2026 11.1749 11.4781 10.982 12.0291 10.5964L18.8332 5.8335M6.1665 16.6668H14.8332C16.2333 16.6668 16.9334 16.6668 17.4681 16.3943C17.9386 16.1547 18.321 15.7722 18.5607 15.3018C18.8332 14.767 18.8332 14.067 18.8332 12.6668V7.3335C18.8332 5.93336 18.8332 5.2333 18.5607 4.69852C18.321 4.22811 17.9386 3.84566 17.4681 3.60598C16.9334 3.3335 16.2333 3.3335 14.8332 3.3335H6.1665C4.76637 3.3335 4.06631 3.3335 3.53153 3.60598C3.06112 3.84566 2.67867 4.22811 2.43899 4.69852C2.1665 5.2333 2.1665 5.93336 2.1665 7.3335V12.6668C2.1665 14.067 2.1665 14.767 2.43899 15.3018C2.67867 15.7722 3.06112 16.1547 3.53153 16.3943C4.06631 16.6668 4.76637 16.6668 6.1665 16.6668Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { Mail };
