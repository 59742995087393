import React from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import styled from "styled-components";
import {
  UserOrderInfoFragment,
  OrderPaymentStatusEnum,
  FulfillStatusEnum,
} from "../../../../../generated/graphql";
import { Link } from "../../../../../lib/i18n";
import { H5, P, Spinner } from "../../../../../shared/globals";
import { DefaultTextPrice } from "../../../../../components/Price";
import { LongTailRightArrow } from "../../../../../assets/Icons";
import { rtl } from "../../../../../shared/styles-utils";

interface OrderHistoryPanelProps {
  orders: (UserOrderInfoFragment | null)[] | null;
  loading: boolean;
}

const formattedOrderStatus = {
  [OrderPaymentStatusEnum.Paid]: <FormattedMessage defaultMessage="Paid" />,
  [OrderPaymentStatusEnum.Pending]: (
    <FormattedMessage defaultMessage="Pending" />
  ),
  [OrderPaymentStatusEnum.Refunded]: (
    <FormattedMessage defaultMessage="Refunded" />
  ),
  unknown: <FormattedMessage defaultMessage="Unknown" />,
};

const formattedFulfillmentOrderStatus = {
  [FulfillStatusEnum.Fulfilled]: (
    <FormattedMessage defaultMessage="Fulfilled" />
  ),
  [FulfillStatusEnum.PartiallyFulfilled]: (
    <FormattedMessage defaultMessage="Partially Fulfilled" />
  ),
  [FulfillStatusEnum.Unfulfilled]: (
    <FormattedMessage defaultMessage="Unfulfilled" />
  ),
  unknown: <FormattedMessage defaultMessage="Unknown" />,
};

const OrderHistoryPanel = ({ orders, loading }: OrderHistoryPanelProps) => {
  if (loading) {
    return <Spinner size={64} inline />;
  }

  return (
    <>
      <GridContainerHeader>
        <GridCell>
          <FormattedMessage defaultMessage="Order" />
        </GridCell>
        <GridCell>
          <FormattedMessage defaultMessage="Date" />
        </GridCell>
        <GridCell>
          <FormattedMessage defaultMessage="Payment" />
        </GridCell>
        <GridCell>
          <FormattedMessage defaultMessage="Fulfillment" />
        </GridCell>
        <GridCell>
          <FormattedMessage defaultMessage="Total" />
        </GridCell>
      </GridContainerHeader>
      {orders?.map((order, index) => (
        <Link key={order?.id} href={`/me/my-orders/${order?.id}`}>
          <GridContainerBody isLastOrderInPage={index === orders.length - 1}>
            <GridCell id="order-serial">
              #
              <FormattedNumber
                value={+order?.orderSerial!}
                minimumIntegerDigits={4}
                notation={+order?.orderSerial! < 1000 ? "compact" : "standard"}
              />
            </GridCell>
            <GridCell>
              <FormattedDate
                value={order?.createdAt}
                day="2-digit"
                month="long"
                year="numeric"
              />
            </GridCell>
            <GridCell id="payment-status">
              <MobileIdentifier>
                <FormattedMessage defaultMessage="Payment Status" /> {": "}
              </MobileIdentifier>
              {formattedOrderStatus[order?.paymentStatus ?? "unknown"] ??
                order?.paymentStatus}
            </GridCell>
            <GridCell id="fulfillment-status">
              <MobileIdentifier>
                <FormattedMessage defaultMessage="Fulfillment Status" />
                {": "}
              </MobileIdentifier>
              {formattedFulfillmentOrderStatus[
                order?.fulfillmentStatus ?? "unknown"
              ] ?? order?.fulfillmentStatus}
            </GridCell>
            <PriceCell money={order?.totalPrice} />

            <LongTailRightArrow />
          </GridContainerBody>
        </Link>
      ))}
    </>
  );
};

export default OrderHistoryPanel;

const GridContainerBody = styled.div<{
  isLastOrderInPage?: boolean;
}>`
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  padding: 20px;
  cursor: pointer;
  position: relative;

  svg {
    display: none;
  }

  &:not(:last-child) {
    border-bottom: solid 1px #dfe4e8;
  }

  &:hover {
    background-color: ${({ theme }) => theme.text.default}10;
  }

  @media (max-width: 768px) {
    align-items: center;
    grid-template-columns: 100%;
    gap: 10px;
    border-top: 1px solid ${({ theme }) => theme.special.border};
    border-bottom: ${({ isLastOrderInPage }) =>
      isLastOrderInPage ? "1px solid #dfe4e8" : "none"};
    svg {
      display: inline;
      position: absolute;
      top: 50%;
      ${({ theme }) => (theme.isRTL ? "left" : "right")}: 20px;

      transform: translateY(-50%) ${rtl("rotate(180deg)", "rotate(0deg)")};
    }
  }
`;

const GridContainerHeader = styled(GridContainerBody)`
  text-transform: capitalize;
  background-color: ${({ theme }) => theme.special.border};
  & > h5 {
    color: ${({ theme }) => theme.text.default};
    font-weight: 600;
  }
  &:hover {
    background-color: ${({ theme }) => theme.special.border};
  }
  cursor: unset;
  @media (max-width: 768px) {
    display: none;
  }
`;

const GridCell = styled(H5)`
  min-width: 100px;
  font-weight: 500;
  max-width: 20%;
  font-size: 14px;

  @media (max-width: 768px) {
    &#order-serial {
      font-weight: 600;
      color: ${({ theme }) => theme.text.default};
    }
    &#payment-status {
      color: ${({ theme }) => theme.bg.gray};
    }
    &#fulfillment-status {
      color: ${({ theme }) => theme.bg.gray};
    }
    min-width: 100%;
  }
`;

const MobileIdentifier = styled(P)`
  display: none;
  @media (max-width: 768px) {
    display: inline;
    font-weight: 500;
    color: ${({ theme }) => theme.text.default};
  }
`;

const PriceCell = styled(DefaultTextPrice)`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 700;
  }
`;
