import * as React from "react";
import { FormattedMessage } from "react-intl";
import { CheckoutCart } from "../CheckoutCart";
import { CircleTracker } from "../../ProgressTracker";
import { Price } from "../../Price";
import { DownArrow } from "../../../assets/Icons";
import { FlexRow } from "../../../shared/globals";
import { Container, Summary, StyledH3, RotateArrowSpan, Total } from "./styled";
import { useCartData } from "../../../lib/cartData/useCartData";

const MobileSubHeader = ({ orderDetails, cartConfirmationData }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { cart } = useCartData();

  const cartData =
    cart.id == "" && cartConfirmationData
      ? cartConfirmationData
      : cart.recoveryCart ?? cart;

  return (
    <Container>
      <CircleTracker />
      <Summary
        justifyContent="space-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <FlexRow>
          <StyledH3>
            <FormattedMessage defaultMessage="Show order summary" />
          </StyledH3>
          <RotateArrowSpan isOpen={isOpen}>
            <DownArrow />
          </RotateArrowSpan>
        </FlexRow>
        <Total>
          <Price
            money={
              !orderDetails
                ? cartData?.receipt?.total
                : orderDetails?.order?.receipt?.total
            }
          />
        </Total>
      </Summary>
      <CheckoutCart
        orderDetails={orderDetails}
        isOpen={isOpen}
        isMobile={true}
      />
    </Container>
  );
};

export default MobileSubHeader;
