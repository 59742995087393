import React from "react";

export const ReplyIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.08926 1.92259C6.41469 1.59715 6.41469 1.06951 6.08926 0.744078C5.76382 0.418641 5.23618 0.418641 4.91074 0.744078L0.744078 4.91074C0.418641 5.23618 0.418641 5.76382 0.744078 6.08926L4.91074 10.2559C5.23618 10.5814 5.76382 10.5814 6.08926 10.2559C6.41469 9.93049 6.41469 9.40285 6.08926 9.07741L3.34518 6.33333H6.66667C8.08055 6.33333 9.09084 6.33398 9.8828 6.39869C10.665 6.4626 11.1606 6.58482 11.5583 6.78747C12.3423 7.18694 12.9797 7.82436 13.3792 8.60837C13.5818 9.0061 13.7041 9.50164 13.768 10.2839C13.8327 11.0758 13.8333 12.0861 13.8333 13.5V14.6667C13.8333 15.1269 14.2064 15.5 14.6667 15.5C15.1269 15.5 15.5 15.1269 15.5 14.6667V13.463C15.5 12.0942 15.5 11.0159 15.4291 10.1481C15.3568 9.26286 15.2065 8.52356 14.8642 7.85172C14.3049 6.75411 13.4126 5.86172 12.3149 5.30246C11.6431 4.96014 10.9038 4.80989 10.0185 4.73756C9.15076 4.66666 8.07246 4.66666 6.70368 4.66667L3.34518 4.66667L6.08926 1.92259Z"
        fill="#667085"
      />
    </svg>
  );
};
