import React, { FC } from "react";
import { SectionCardWrapperProps } from "../../types";
import { SectionBackgroundTypeEnum } from "../../../generated/graphql";
import WhileInViewAnimation from "../../../shared/globals/WhileInViewAnimation";
import Flex from "../../../shared/globals/UiElements/Flex";

const SectionCardWrapper: FC<SectionCardWrapperProps> = ({
  children,
  background,
  isProduct,
  isSlider,
  removeBorderRadiusDesktop = false,
  removeBorderRadiusMobile = false,
}) => {
  return (
    <WhileInViewAnimation
      isDisabled={isSlider}
      style={{
        backgroundColor:
          background?.type === SectionBackgroundTypeEnum.Color
            ? background?.color
            : isProduct
            ? "white"
            : "transparent",
        border:
          !isProduct && background?.type === SectionBackgroundTypeEnum.Color
            ? `2px solid ${background?.color}`
            : "none",
      }}
      className={`relative box-border overflow-hidden rounded-lg 
        ${
          (background?.type === SectionBackgroundTypeEnum.Color || isProduct) &&
          "shadow-sm hover:shadow-lg transition-shadow duration-500 ease-in-out"
        } 
        ${
          removeBorderRadiusMobile ? "max-sm:rounded-none" : "max-sm:rounded-lg"
        } 
        ${removeBorderRadiusDesktop ? "rounded-none" : "rounded-lg"}
        ${isProduct && "cursor-pointer"}
      `}
    >
      <Flex fullHeight className="bg-inherit">
        {children}
      </Flex>
    </WhileInViewAnimation>
  );
};

export default SectionCardWrapper;
