import type { RegisterType, User } from "../hooks/useAuth";
import { AnalyticsActions } from "../../analytics-accounts/utils";
import { setApolloAccessToken } from "../../apollo";
import { isBrowser } from "../../isBrowser";
import { CookieSerializeOptions } from "cookie";
import { createCookie, eraseCookie } from "../../../shared/utils/cookie";
import { AuthState } from "../../apollo/client-graphql";

const cookieOptions: CookieSerializeOptions = {
  path: "/",
  maxAge: 60 * 60 * 24 * 365,
  sameSite: "strict",
  // httpOnly: true,
  // secure: true,
};

const AnalyticsEventName = {
  login: "Login",
  signup: "Sign_Up",
} as const;

const fireAnalyticsEvent = (type: RegisterType, user: User) => {
  if (!isBrowser) return;
  AnalyticsActions.fireEvent({
    name: AnalyticsEventName[type],
    parameters: {
      event_category: "User",
      content_name: user.name,
      ...user,
      subscription_id: user.id,
      user_id: user.id,
      password: undefined,
      method: "Email",
    },
  });
};
export const logout = (): void => {
  setApolloAccessToken(undefined);
  // AnalyticsActions.setUserId(undefined); keep tracking the user
  eraseCookie("auth-token", { sameSite: "strict" });
};

export const registerUser = (
  type: RegisterType,
  user: User,
  token: string
): void => {
  if (!user?.id) return logout();
  AnalyticsActions.setUserId(user.id);
  fireAnalyticsEvent(type, user);
  const authState: AuthState = {
    email: user?.email!,
    id: user?.id,
    name: user?.name!,
    phone: user?.phone!,
    token,
  };
  setApolloAccessToken(authState);
  createCookie("auth-token", token!, cookieOptions);
};

export async function tryLogin(token?: string): Promise<AuthState | undefined> {
  try {
    if (!token) throw Error();
    const results = await fetch(process.env.GRAPHQL_URI!, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ query: `{ me { id name email phone } }` }),
    });
    const { data } = await results.json();
    if (data?.me) {
      AnalyticsActions.setUserId(data?.me?.id);
      fireAnalyticsEvent("login", data?.me);
      return { ...data?.me, token };
    }
  } catch (error) {}

  logout();

  if (typeof window !== "undefined") {
    window.location.reload();
  }
  return undefined;
}
