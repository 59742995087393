import * as React from "react";
import Modal from "../../../../../shared/globals/UiElements/Modal";
import { Form, Field } from "react-final-form";
import { FormattedMessage } from "react-intl";
import createDecorator from "final-form-focus";
import Asterisk from "../../../../../shared/globals/UiElements/Asterisk";
import { H2, Label, Input, FlexRow } from "../../../../../shared/globals";
import { ButtonsContainer, SaveButton, CancelButton } from "./styled";
import InputPhone, {
  PhoneInput,
} from "../../../../../shared/globals/UiElements/InputPhone";
import type { FormValues } from "./types";
import { useStore } from "../../../../../lib/storeData";
import {
  CheckoutFieldOptions,
  CustomerIdentifier,
} from "../../../../../generated/graphql";
import { CheckoutFields } from "../../../../../contexts/CartContext/types";
import {
  PhoneField,
  RequiredSpan,
} from "../../../../../components/Checkout/Information/styled";

import { validate } from "../../../../../components/Checkout/Information/validate";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import ShippingDestinationSelect, {
  ShippingDestination,
} from "../../../../../components/UtilityComponents/ShippingDestinationSelect";

const focusOnError = createDecorator();

interface AddressModalProps {
  isOpen: boolean;
  defaultValues?: FormValues;
  mode: "ADD" | "EDIT";
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
}

const AddressModal: React.FC<AddressModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  mode,
  defaultValues,
}) => {
  const { customCheckoutSetting } = useStore();
  const ignoredFields = () => {
    return customCheckoutSetting?.secondaryPhone ===
      CheckoutFieldOptions.Mandatory
      ? [
          CheckoutFields.Phone,
          CheckoutFields.Name,
          CheckoutFields.Email,
          CheckoutFields.Notes,
        ]
      : [
          CheckoutFields.Phone,
          CheckoutFields.Name,
          CheckoutFields.Email,
          CheckoutFields.Notes,
          CheckoutFields.SecondPhone,
        ];
  };
  return (
    <Modal show={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        validate={(values) =>
          validate(values, customCheckoutSetting, ignoredFields())
        }
        decorators={[focusOnError]}
        render={({ handleSubmit, submitting, errors }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header>
              <H2>
                {mode === "ADD" ? (
                  <FormattedMessage defaultMessage="Add New Address" />
                ) : (
                  <FormattedMessage defaultMessage="Edit this address" />
                )}
              </H2>
            </Modal.Header>
            <Modal.Body>
              <Flex column>
                <div className="w-full">
                  <Label className="!m-none">
                    <FlexRow>
                      <FormattedMessage defaultMessage="Address" />
                      <Asterisk />
                    </FlexRow>
                  </Label>
                  <Field name="address" defaultValue={defaultValues?.address}>
                    {({ input, meta: { error, touched } }) => (
                      <>
                        <Input
                          {...input}
                          className={error && touched ? "invalid" : ""}
                          data-test="type-address"
                        />
                        {error && touched && (
                          <RequiredSpan>{error}</RequiredSpan>
                        )}
                      </>
                    )}
                  </Field>
                </div>
                <div>
                  <Label className="!mt-none">
                    <FlexRow>
                      <FormattedMessage defaultMessage="Apartment, suite, Unit etc." />
                      <Asterisk />
                    </FlexRow>
                  </Label>
                  <Field
                    style={{ position: "relative" }}
                    name="apartment"
                    defaultValue={defaultValues?.apartment}
                  >
                    {({ input, meta: { error, touched } }) => (
                      <>
                        <Input
                          {...input}
                          className={error && touched ? "invalid" : ""}
                          data-test="type-apartment"
                        />
                        {error && touched && (
                          <RequiredSpan>{error}</RequiredSpan>
                        )}
                      </>
                    )}
                  </Field>
                </div>
                <Flex column>
                  <Field<ShippingDestination>
                    name="shippingDestination"
                    defaultValue={defaultValues?.shippingDestination}
                  >
                    {({ input, meta: { error } }) => (
                      <ShippingDestinationSelect
                        {...input}
                        startQuery
                        error={error}
                      />
                    )}
                  </Field>

                  {customCheckoutSetting?.postalCode !==
                    CheckoutFieldOptions.Inactive && (
                    <Flex spacing="none" className="flex-1" column>
                      <Label className="!m-none">
                        <FlexRow>
                          <FormattedMessage defaultMessage="Postal Code" />
                          {customCheckoutSetting?.postalCode ===
                            CheckoutFieldOptions.Mandatory && <Asterisk />}
                        </FlexRow>
                      </Label>
                      <Field
                        name="postalCode"
                        defaultValue={defaultValues?.postalCode}
                      >
                        {({ input, meta: { error, touched } }) => (
                          <>
                            <Input
                              {...input}
                              data-test="type-postalcode"
                              type="text"
                              min="0"
                            />
                            {error && touched && (
                              <RequiredSpan>{error}</RequiredSpan>
                            )}
                          </>
                        )}
                      </Field>
                    </Flex>
                  )}
                </Flex>
                {customCheckoutSetting?.identifier !==
                  CustomerIdentifier.Email &&
                  customCheckoutSetting?.secondaryPhone !==
                    CheckoutFieldOptions.Inactive && (
                    <PhoneField fullWidth>
                      <Label className="!m-none">
                        <FlexRow>
                          <FormattedMessage defaultMessage="Secondary Phone no." />
                          {customCheckoutSetting?.secondaryPhone ===
                            CheckoutFieldOptions.Mandatory && <Asterisk />}
                        </FlexRow>
                      </Label>
                      <Field<PhoneInput>
                        name="secondPhone"
                        defaultValue={defaultValues?.secondPhone}
                      >
                        {(fieldProps) => <InputPhone {...fieldProps} />}
                      </Field>
                    </PhoneField>
                  )}
              </Flex>
            </Modal.Body>
            <Modal.Footer>
              <ButtonsContainer>
                <CancelButton type="button" onClick={onClose}>
                  <FormattedMessage defaultMessage="Cancel" />
                </CancelButton>
                <SaveButton
                  data-test="button-save-changes"
                  type="submit"
                  isLoading={submitting}
                  disabled={!!Object.keys(errors || {}).length}
                >
                  <FormattedMessage defaultMessage="Save Changes" />
                </SaveButton>
              </ButtonsContainer>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

export default AddressModal;
