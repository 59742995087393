import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, Form } from "react-final-form";
import { BoxImportant, PercentCard, Delete } from "../../../assets/Icons";
import { PrimaryButton } from "../../../components/Button";
import { FlexCol, FlexRow, Input, Spinner } from "../../../shared/globals";
import Alert from "../../../shared/globals/UiElements/Alert";
import { rtl } from "../../../shared/styles-utils";
import { HeavyH4 } from "../components/Cart/styled";
import { CouponProps, FormValues, TemplateElement } from "../../types";
import { useCartData } from "../../../lib/cartData/useCartData";
import Flex from "../../../shared/globals/UiElements/Flex";
import { PriceBase } from "../../../components/Price/Price";
import { useStore } from "../../../lib/storeData";
import { CheckoutServiceErrorCodes } from "../../../generated/graphql";

const Coupon: TemplateElement<CouponProps> = ({
  loading,
  error,
  removePromoCodeLoading,
  onSubmit,
  onRemovePromoCode,
  minimumPromoCodeLoading,
}) => {
  const intl = useIntl();
  const { cart } = useCartData();
  const couponData = cart.recoveryCart != null ? cart.recoveryCart : cart;
  const { currency } = useStore();
  const isInvalid = !loading && error;

  return (
    <>
      <FlexCol alignItems="flex-start">
        <HeavyH4 style={{ fontWeight: 500 }}>
          <FormattedMessage defaultMessage="Do you have a coupon code?" />
        </HeavyH4>
        <Form<FormValues>
          onSubmit={onSubmit!}
          initialValues={{
            coupon: couponData?.promoCode?.code || "",
          }}
          render={({ handleSubmit, values }) => {
            return (
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <FullWidth style={{ marginTop: 10 }} alignItems="stretch">
                  <Field name="coupon">
                    {({ input }) => (
                      <InputWithIcon>
                        <StyledInput
                          {...input}
                          className={isInvalid ? "invalid" : ""}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Enter coupon code",
                          })}
                          disabled={cart?.isLoading}
                        />
                        {isInvalid && (
                          <IconHolder>
                            <BoxImportant />
                          </IconHolder>
                        )}
                      </InputWithIcon>
                    )}
                  </Field>
                  <ApplyCouponButton
                    type="submit"
                    className={
                      !values.coupon ||
                      values?.coupon === couponData?.promoCode?.code
                        ? "dimmed "
                        : undefined
                    }
                    isLoading={loading || minimumPromoCodeLoading}
                  >
                    <FormattedMessage defaultMessage="Apply" />
                  </ApplyCouponButton>
                </FullWidth>
              </form>
            );
          }}
        />
        {couponData?.promoCode?.percentageOff! > 0 &&
          error == undefined &&
          (!cart?.isLoading ? (
            <CouponTag
              prefixIcon={<PercentCard />}
              suffixIcon={
                <span style={{ cursor: "pointer" }} onClick={onRemovePromoCode}>
                  <Delete small />
                </span>
              }
            >
              {couponData?.promoCode?.code}
            </CouponTag>
          ) : (
            <Flex spacing="s" className="mt-2" alignItems="center">
              <Spinner inline={true} size={20} />
              <div className="text-sm text-gray-600">
                <FormattedMessage defaultMessage="Processing" />
              </div>
            </Flex>
          ))}
        {couponData?.promoCode?.fixedAmount! > 0 &&
          error == undefined &&
          (!cart?.isLoading ? (
            <CouponTag
              prefixIcon={<PercentCard />}
              suffixIcon={
                <span style={{ cursor: "pointer" }} onClick={onRemovePromoCode}>
                  <Delete small />
                </span>
              }
            >
              {couponData?.promoCode?.code}
            </CouponTag>
          ) : (
            <Flex spacing="s" className="mt-2" alignItems="center">
              <Spinner inline={true} size={20} />
              <div className="text-sm text-gray-600">
                <FormattedMessage defaultMessage="Processing" />
              </div>
            </Flex>
          ))}
        {isInvalid &&
          (error && !removePromoCodeLoading ? (
            <Alert
              message={
                error.message == CheckoutServiceErrorCodes.PromoCodeNotValid ? (
                  <FormattedMessage defaultMessage="Sorry, this coupon code is invalid" />
                ) : (
                  <FormattedMessage
                    defaultMessage="The order subtotal must be more than {minimum} to apply the coupon"
                    values={{
                      minimum: (
                        <PriceBase
                          money={{
                            amount: error?.minimumAmount,
                            currencyCode: currency,
                          }}
                        />
                      ),
                    }}
                  />
                )
              }
              closeAction={() => {
                onRemovePromoCode!();
              }}
            />
          ) : (
            <Flex spacing="s" className="mt-2" alignItems="center">
              <Spinner inline={true} size={20} />
              <div className="text-sm text-gray-600">
                <FormattedMessage defaultMessage="Processing" />
              </div>
            </Flex>
          ))}
      </FlexCol>
    </>
  );
};

export default Coupon;

const FullWidth = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
`;

const InputWithIcon = styled(FullWidth)`
  position: relative;
`;

const StyledInput = styled(Input)`
  width: 50%;
  ${rtl("margin-left", "margin-right")}: 10px;
  ${rtl("padding-left", "padding-right")}: 30px;
  margin-bottom: 0px;
  margin-top: 0;
`;

const ApplyCouponButton = styled(PrimaryButton)`
  padding: 8px 17px;
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

const CouponTag = styled(ApplyCouponButton)`
  margin-top: 10px;
  padding: 8px 4px !important;
  color: #42ab44;
  background-color: #42ab441a;
  border: none;
  cursor: unset;
`;

const IconHolder = styled.span`
  position: absolute;
  ${rtl("left", "right")}: 20px;
  color: ${({ theme }) => theme.danger.default};
`;
