import { CSSProperties } from "styled-components";

interface Props {
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
}

const ProductPlaceHolder = ({ width = "100%", height = "100%" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 350 350"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="350" height="350" fill="#F2F4F7" />
      <g clip-path="url(#clip0_3974_21652)">
        <path
          d="M186 195C188.209 195 190 193.209 190 191C190 188.791 188.209 187 186 187C183.791 187 182 188.791 182 191C182 193.209 183.791 195 186 195Z"
          fill="#D0D5DD"
        />
        <path
          d="M164 195C166.209 195 168 193.209 168 191C168 188.791 166.209 187 164 187C161.791 187 160 188.791 160 191C160 193.209 161.791 195 164 195Z"
          fill="#D0D5DD"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M162 183H188C189 183 189.8 182.4 190 181.4L194 165.4C194.2 164.2 193.2 163 192 163H168V167H189.4L186.4 179H164V157C164 155.8 163.2 155 162 155H154V159H160V181C160 182.2 160.8 183 162 183Z"
          fill="#667085"
        />
      </g>
      <defs>
        <clipPath id="clip0_3974_21652">
          <rect
            width="41"
            height="40"
            fill="white"
            transform="translate(154 155)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { ProductPlaceHolder };
