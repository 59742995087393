import React from "react";
import styled from "styled-components";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import {
  BadgeContainer,
  Badge as OldBadge,
} from "../../../../../components/Badge/Badge";
import { PrimaryButton } from "../../../../../components/Button";
import {
  BuyNowButtonDisplay,
  ProductType,
} from "../../../../../generated/graphql";
import { Link } from "../../../../../lib/i18n";
import { FlexCol, H3, Spinner } from "../../../../../shared/globals";
import { themeColor } from "../../../../../shared/styles-utils";
import type { ProductCardProps } from "../../../../types";
import Alert from "../../../../../shared/globals/UiElements/Alert";
import { useStore } from "../../../../../lib/storeData";
import BuyNowButton from "../../../../../components/BuyNowButton/BuyNowButton";
import ProductCardReviews from "../../../../default/components/ReviewsAndRatings/ProductCardReviews";
import { CouponIcon, ShoppingBasket } from "../../../../../assets/Icons";
import ProductCardPrice, { getSalePercentage } from "../../ProductCardPrice";
import ItemImg from "../../../../../shared/globals/UiElements/ItemImg";
import { Badge } from "../../../../../shared/globals/UiElements/Badge";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import { getTranslatedDiscountTitle } from "../../../../../shared/utils/getTranslatedDiscountTitle";
import { getLocaleInfo } from "../../../../../lib/i18n/locales-data";

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  collection,
  isAlertOpen,
  setIsAlertOpen,
  handleAddToCart,
  isDiscount,
  discountedCustomProductPrice,
  availableQuantity,
  isSlider,
  isCartUpdating,
  isAddingSimpleItem,
  appliedAutomaticDiscounts,
}) => {
  const { areReviewsActivated, appearance, storeReviewSettings } = useStore();
  const { locale } = useIntl();
  const activeLocale = getLocaleInfo(locale).code;
  const productTitle = product?.title?.substring(0, 63);
  const firstVariant = product?.variants?.nodes[0];
  const customProduct = product.type === ProductType.Custom;
  const productHasOptions =
    !customProduct && !!firstVariant?.selectedOptions?.length;
  const isButNowButtonEnabled = // typo
    !!appearance?.productDisplay?.buyNowButton?.display &&
    appearance?.productDisplay?.buyNowButton?.display !==
      BuyNowButtonDisplay.None;
  const [isHovered, setIsHovered] = React.useState(false);
  const showProductReviews =
    areReviewsActivated &&
    (storeReviewSettings?.showOverAllRating ||
      storeReviewSettings?.showReviewsCount ||
      storeReviewSettings?.showStars);

  return (
    <ProductCardContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-test="product-card"
    >
      <StyledLink
        href={`/product/${collection || "all"}/${product.handle}`}
        fullWidth
      >
        <BadgeContainer>
          <StyledBadge
            outOfStock={!product.isInStock}
            onSale={
              !!firstVariant?.compareAtPrice &&
              firstVariant?.price?.amount !== 0
            }
            customInStock={customProduct && !!product.isInStock}
            dotBackground="#fff"
            saleMessage={
              <>
                <FormattedNumber
                  value={getSalePercentage(
                    customProduct
                      ? product?.initialPrice?.amount!
                      : firstVariant?.compareAtPrice?.amount!,
                    customProduct
                      ? discountedCustomProductPrice!
                      : firstVariant?.price?.amount!
                  )}
                  minimumFractionDigits={1}
                  style="percent"
                />{" "}
                <FormattedMessage defaultMessage=" off" />
              </>
            }
          />
          {isDiscount && (
            <StyledBadge
              customItemDiscount={product?.discount ?? undefined}
              saleMessage={
                <>
                  <FormattedNumber
                    value={product?.discount?.percent! / 100}
                    minimumFractionDigits={1}
                    style="percent"
                  />{" "}
                  <FormattedMessage defaultMessage=" off" />
                </>
              }
            />
          )}
          {!!appliedAutomaticDiscounts?.length && (
            <Flex flexWrap="wrap" spacing="xs">
              {appliedAutomaticDiscounts.map((discount) => (
                <Badge
                  key={discount?.id}
                  type="ink"
                  label={
                    <Flex alignItems="center" spacing="xs">
                      <CouponIcon />
                      {getTranslatedDiscountTitle({
                        defaultTitle: discount?.title,
                        currentLocale: activeLocale,
                        translations: discount?.translations,
                      })}
                    </Flex>
                  }
                />
              ))}
            </Flex>
          )}
        </BadgeContainer>

        <ItemImg
          imgDetails={product.images?.[0]}
          onlyImg
          innerSize="85%"
          maxHeight="300px"
          isSquare={!isSlider}
        />
      </StyledLink>
      <InfoWrapper>
        <Link href={`/product/${collection || "all"}/${product.handle}`}>
          <Title data-test="text-product-title">{productTitle}</Title>
          {showProductReviews && (
            <ProductCardReviews
              averageRating={product?.reviewsStatistics?.average}
              totalReviews={product?.reviewsStatistics?.total}
            />
          )}
        </Link>
        <div>
          <PriceAndAddToCartWrapper>
            <ProductCardPrice
              product={product}
              isDiscount={isDiscount}
              discountedCustomProductPrice={discountedCustomProductPrice}
            />
            {!productHasOptions &&
              !customProduct &&
              product.isInStock &&
              (isAddingSimpleItem ? (
                <AddToCartButton
                  data-test="button-add-product-to-cart"
                  suffixIcon={<Spinner inline={true} color="colors.white" />}
                  size="medium"
                  style={{ cursor: "not-allowed" }}
                  disabled
                />
              ) : (
                <AddToCartButton
                  data-test="button-add-product-to-cart"
                  suffixIcon={<ShoppingBasket />}
                  size="medium"
                  onClick={handleAddToCart}
                  disabled={isCartUpdating}
                />
              ))}
          </PriceAndAddToCartWrapper>
          {customProduct ? (
            <ActionButton>
              <Link
                fullWidth
                href={`/product/${collection || "all"}/${product.handle}`}
              >
                <FormattedMessage defaultMessage="Build your product" />
              </Link>
            </ActionButton>
          ) : productHasOptions ? (
            <ActionButton>
              <Link
                fullWidth
                href={`/product/${collection || "all"}/${product.handle}`}
              >
                <FormattedMessage defaultMessage="Select options" />
              </Link>
            </ActionButton>
          ) : isButNowButtonEnabled ? (
            <BuyNowButton
              isDisabled={
                !product.isInStock ||
                productHasOptions ||
                customProduct ||
                firstVariant?.minPerCart! > 1
              }
              isHovered={isHovered}
              isAlwaysVisible={false}
              isCard
              product={product}
            />
          ) : null}
        </div>

        {isAlertOpen && (
          <Alert
            message={
              availableQuantity?.type === "stock" ? (
                <FormattedMessage defaultMessage="Quantity exceeded" />
              ) : (
                <FormattedMessage
                  defaultMessage="You can only add up to {max} of this product to cart"
                  values={{ max: availableQuantity?.max }}
                />
              )
            }
            closeAction={() => setIsAlertOpen!(false)}
          />
        )}
      </InfoWrapper>
    </ProductCardContainer>
  );
};

export default ProductCard;

/**
 *
 * Styles
 *
 */

const ProductCardContainer = styled(FlexCol)`
  position: relative;
  height: 100%;
`;

const StyledLink = styled(Link)`
  display: flex;
`;

const StyledBadge = styled(OldBadge)`
  background-color: #cc0905;
  color: white;
  white-space: nowrap;
  svg {
    fill: white;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1 auto;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
`;

const Title = styled(H3)`
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

const PriceAndAddToCartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const AddToCartButton = styled(PrimaryButton)`
  padding: 0;
  height: 35px;
  width: 35px;
  background-color: ${themeColor("primary")};
  border-radius: 50%;
  border: none;
  margin-bottom: 12px;
`;

const ActionButton = styled(PrimaryButton)`
  width: 100%;
  color: ${themeColor("primary")};
  border: 1px solid ${themeColor("primary")};
  background-color: ${themeColor("white")};
  padding: 5px;
  min-height: 40px;

  @media (min-width: 768px) {
    padding: 6px 0;
  }
`;
