import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  useListCustomerAddressesQuery,
  useMakeDefaultAddressMutation,
  ListCustomerAddressesDocument,
} from "../../../../generated/graphql";
import { useAuth } from "../../../../lib/Authentication";
import { H3, Spinner } from "../../../../shared/globals";
import { Card } from "../../../../shared/globals/UiElements/Card";
import LoadingSpinner from "../../../../shared/globals/UiElements/LoadingSpinner";
import AddressesEmptyState from "../../components/CustomerProfile/my-addresses/AddressesEmptyState";
import AddressesPanel from "../../components/CustomerProfile/my-addresses/AddressesPanel";
import AddAddress from "../../components/CustomerProfile/my-addresses/AddAddress";
import { useStore } from "../../../../lib/storeData";
import Flex from "../../../../shared/globals/UiElements/Flex";

const UserAddresses = () => {
  const { user: loggedInUser } = useAuth();
  const { storeId } = useStore();
  const { data, loading } = useListCustomerAddressesQuery({
    variables: { customerId: loggedInUser?.id!, storeId },
  });
  const [makeDefaultAddress, { loading: settingDefault }] =
    useMakeDefaultAddressMutation({
      refetchQueries: [
        {
          query: ListCustomerAddressesDocument,
          variables: { customerId: loggedInUser?.id, storeId },
        },
      ],
    });

  const setDefaultAddress = async (userId: string, addressId: string) => {
    await makeDefaultAddress({
      variables: { customerId: userId, addressId: addressId, storeId },
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Flex column spacing="xl">
      <Flex justifyContent="space-between">
        <H3 fontWeight="bold">
          <FormattedMessage defaultMessage="My Addresses" />
        </H3>
        {data?.customerAddresses?.length && <AddAddress buttonOutlined />}
      </Flex>
      {data?.customerAddresses?.length ? (
        data?.customerAddresses?.map((address) => (
          <AddressesPanel
            key={address?.id}
            userName={loggedInUser?.name!}
            address={address}
            isDefault={address.isDefault}
            isValid={true}
            SetDefaultButton={
              <Flex alignItems="center">
                <DefaultAddressButton
                  onClick={() =>
                    setDefaultAddress(loggedInUser?.id!, address.id)
                  }
                >
                  <FormattedMessage defaultMessage="Set as default address" />
                </DefaultAddressButton>
                {settingDefault && <Spinner inline size={16} />}
              </Flex>
            }
            DefaultBadge={
              <DefaultAddress>
                <FormattedMessage defaultMessage="✓ Default Address" />
              </DefaultAddress>
            }
          />
        ))
      ) : (
        <Card paddingSize="3xl">
          <AddressesEmptyState />
        </Card>
      )}
    </Flex>
  );
};

export default UserAddresses;

const DefaultAddress = styled.span`
  display: flex;
  width: fit-content;
  font-weight: 600;
  padding: 10px 12px;
  background: #fff5e9;
  color: #c36b00;
  border-radius: 4px;
  letter-spacing: -0.084px;
`;

const DefaultAddressButton = styled.button`
  color: #c36b00;
  background-color: white;
  font-weight: 600;
  font-size: 14px;
  border: none;
  padding: 0px;
  cursor: pointer;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #c36b00;
  &:hover {
    text-decoration: underline;
  }
`;
