import React from "react";
import { Photo } from "../../../../../shared/globals/UiElements/Photo";
import { Link } from "../../../../../lib/i18n";
import { CollectionCardProps } from "../../../../types";
import MotionElementWrapper from "../../../../../shared/globals/MotionElementWrapper";
import { FormattedMessage, FormattedNumber } from "react-intl";
import SectionCardTitle from "./SectionCardTitle";

const CollectionCard: React.FC<CollectionCardProps> = ({
  collection,
  hideTitle,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <Link fullHeight fullWidth href={`/product/${collection?.handle}`}>
      <div
        className="w-full h-full"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <MotionElementWrapper
          fullWidth
          fullHeight
          animate={isHovered ? { scale: 1.1 } : { scale: 1 }}
          duration={0.5}
        >
          <Photo
            src={collection?.image?.src || "/default-placeholder-image.png"}
            alt={collection?.title!}
            objectFit="cover"
          />
        </MotionElementWrapper>
      </div>
      {!hideTitle && (
        <SectionCardTitle
          isHovered={isHovered}
          setIsHovered={setIsHovered}
          title={collection?.title!}
          secondLine={
            <span className="text-xs text-gray-800">
              <FormattedNumber
                value={collection?.productsCount}
                style="decimal"
              />{" "}
              <FormattedMessage defaultMessage="Products" />
            </span>
          }
        />
      )}
    </Link>
  );
};

export default CollectionCard;
