import React, { useEffect } from "react";
import {
  AnalyticalAccount,
  IntegrationScriptControls,
} from "../../generated/graphql";
import { createScript } from "./snippets";
import { AnalyticsActions } from "./utils";
import { useRouter } from "../i18n";
import { useStore } from "../storeData";
import { useAuth } from "../Authentication";

function mapAnalyticsAccount(item) {
  return {
    type: "analytics",
    name: item?.name,
    isActive: item?.isActive,
    id: item?.id,
  };
}

function mapIntegrationsScriptControls(item: IntegrationScriptControls) {
  return {
    type: "store",
    name: item?.provider,
    isActive: true,
    scriptControls: item,
  };
}

interface AnalyticsAccountsProps {
  analyticalAccounts: (AnalyticalAccount | null)[] | null | undefined;
  storeIntegrations: (IntegrationScriptControls | null)[] | null | undefined;
}

const AnalyticsAccounts: React.FC<AnalyticsAccountsProps> = ({
  analyticalAccounts,
  storeIntegrations,
}) => {
  const router = useRouter();
  const { user: customer } = useAuth();
  const { contactInfo, locale } = useStore();

  const analytics = (analyticalAccounts || [])?.map(mapAnalyticsAccount);

  const mappedStoreIntegrations = (storeIntegrations || [])?.map(
    mapIntegrationsScriptControls
  );
  const accounts = [...analytics, ...mappedStoreIntegrations];

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      AnalyticsActions.trackRouteChange(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  if (!accounts || accounts?.findIndex((account) => account?.isActive) === -1) {
    return null;
  }

  AnalyticsActions.setAccountsMap(accounts);
  const scripts: JSX.Element[] = accounts
    .filter((account) => account?.isActive)
    .map((account) =>
      createScript(account, contactInfo?.email, customer, locale)
    );

  return <>{[...scripts]}</>;
};

export default AnalyticsAccounts;
