import { GetOrderByCartIdQuery } from "../../../../generated/graphql";
import { AnalyticsActions } from "../../../../lib/analytics-accounts/utils";

export function firePurchaseEvent(
  orderDetails: GetOrderByCartIdQuery["orderByCart"],
  storeName: string
) {
  AnalyticsActions.fireEvent({
    name: "Purchase",
    parameters: {
      event_category: "Purchase",
      content_type: "product_group",
      content_ids: orderDetails?.items.map((item) =>
        item?.__typename === "SimpleItem"
          ? item.variantSnapshot?.id
          : item?.productSnapshot?.id
      ),
      num_items: orderDetails?.items
        ?.map((item) => item?.quantity)
        .reduce((a, b) => (a || 0) + (b || 0)),
      value: orderDetails?.receipt?.total?.amount,
      orderSerial: orderDetails?.orderSerial,
      currency: orderDetails?.receipt?.total?.currencyCode,
      transaction_id: orderDetails?.id,
      affiliation: storeName,
      subtotal: orderDetails?.receipt?.subtotal?.amount,
      coupon: orderDetails?.promoCodeSnapshot?.code || "No Coupon",
      shipping: orderDetails?.shippingRateCost?.amount,
      shippingName: orderDetails?.shippingRateName,
      customer: JSON.stringify(
        {
          name: orderDetails?.customer?.name,
          country: orderDetails?.shippingAddress?.areaSnapshot?.countryName,
          email: orderDetails?.customer?.email,
        },
        null,
        2
      ),
      contents: orderDetails?.items?.map((item) => {
        return JSON.stringify(
          {
            id: item?.id,
            quantity: item?.quantity,
            label: item?.title,
            variant:
              item?.__typename === "SimpleItem"
                ? item?.selectedOptions
                    .map((variant) => variant.name)
                    .join(" - ")
                : [],
            value: item?.subtotal?.amount,
            currency: item?.subtotal?.currencyCode,
          },
          null,
          2
        );
      }),
    },
  });
}

export function fireConfirmationEvent() {
  AnalyticsActions.fireEvent({
    name: "Checkout_Confirmation",
    parameters: {
      event_category: "Checkout",
    },
  });
}

export function firePaymentEvent(
  orderDetails: GetOrderByCartIdQuery["orderByCart"]
) {
  AnalyticsActions.fireEvent({
    name: "Checkout_Payment",
    parameters: {
      event_category: "Checkout",
      content_type: "Payment",
      value: orderDetails?.receipt?.total?.amount,
      event_label: orderDetails?.paymentMethod,
    },
  });
}

export function fireUseCouponEvent(
  orderDetails: GetOrderByCartIdQuery["orderByCart"]
) {
  AnalyticsActions.fireEvent({
    name: "Use_Coupon",
    parameters: {
      event_category: "Purchase",
      content_type: "Coupon",
      event_label: orderDetails?.promoCodeSnapshot?.code,
      percentage: orderDetails?.promoCodeSnapshot?.percentageOff,
      currency: orderDetails?.receipt?.subtotal?.currencyCode,
      value: orderDetails?.receipt?.discount?.amount,
    },
  });
}

export function fireWaslaEvent(
  amount: number,
  storeId: string,
  orderSerial: string
) {
  // this is the storeId for Arab hardware store
  if (storeId !== "Store_ckuqkzb7701u001jf724jhmgm") return;

  // @ts-ignore
  if (typeof EF !== "undefined") {
    // @ts-ignore
    EF?.conversion({
      aid: 22,
      amount,
      adv4: orderSerial,
    });
  }
}
