const Pinterest = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0199 1.25H9.98007C5.15859 1.25 1.25 5.15859 1.25 9.98007V10.0199C1.25 14.8414 5.15859 18.75 9.98007 18.75H10.0199C14.8414 18.75 18.75 14.8414 18.75 10.0199V9.98007C18.75 5.15859 14.8414 1.25 10.0199 1.25Z"
        fill="#1D2939"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.20156 12.3926C9.19113 12.4287 9.18133 12.4594 9.17336 12.4901C8.71046 14.3036 8.65896 14.7064 8.18318 15.5489C7.95633 15.9492 7.70067 16.3287 7.41802 16.6917C7.38614 16.7328 7.3561 16.7855 7.29233 16.7726C7.22244 16.7579 7.21692 16.6948 7.20956 16.639C7.13354 16.0878 7.09123 15.5354 7.10963 14.9787C7.13354 14.254 7.22305 14.005 8.15743 10.0762C8.17092 10.0162 8.15559 9.9665 8.13597 9.91254C7.91219 9.30924 7.86804 8.69674 8.06363 8.07628C8.48667 6.73602 10.006 6.63363 10.2714 7.73906C10.4351 8.42268 10.0023 9.31783 9.66997 10.6409C9.39469 11.7323 10.6798 12.5084 11.7778 11.7114C12.7907 10.9769 13.1837 9.21605 13.1089 7.96775C12.9618 5.47914 10.2328 4.94145 8.502 5.74278C6.51736 6.66061 6.06612 9.1204 6.96248 10.2448C7.07591 10.3877 7.28988 10.4754 7.25248 10.6201C7.19485 10.8451 7.14396 11.0719 7.08142 11.2957C7.03483 11.4625 6.76935 11.5232 6.60075 11.4545C6.27028 11.3209 5.99499 11.1106 5.77182 10.8334C5.01095 9.89108 4.7933 8.02723 5.79941 6.44909C6.91404 4.70111 8.98758 3.99358 10.8809 4.20817C13.142 4.46506 14.5712 6.0101 14.8385 7.76298C14.9605 8.56125 14.8728 10.5299 13.7521 11.9217C12.4627 13.5207 10.3726 13.6268 9.40817 12.6452C9.33399 12.5698 9.27452 12.4821 9.20156 12.392V12.3926Z"
        fill="white"
      />
    </svg>
  );
};

export { Pinterest };
