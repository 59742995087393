import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { StyledH3 } from "../styled";
import ClearAllFilters from "./ClearAllFilters";
import { PrimaryButton } from "../../../../../components/Button";

interface ApplyFilterSectionProps {
  onClose: () => void;
  totalCount: number;
  loading: boolean;
}

const getItemsFound = (itemsCount: number) => (
  <FormattedMessage
    defaultMessage="{count, plural, =0 {no items} one {1 item} two {# items} few {# items} many {# items} other {# items}} found"
    values={{ count: itemsCount }}
  />
);

export const ApplyFilterSection = ({
  onClose,
  totalCount,
  loading,
}: ApplyFilterSectionProps) => {
  return (
    <ApplyFilterContainer>
      <TotalContainer>
        <StyledH3>
          <FormattedMessage defaultMessage="Items" />
        </StyledH3>
        {!loading && <SubTitle>{getItemsFound(totalCount)} </SubTitle>}
      </TotalContainer>
      <ButtonsContainer>
        <PrimaryButton
          style={{
            flexGrow: 1,
          }}
          onClick={onClose}
        >
          <FormattedMessage defaultMessage="Done" />
        </PrimaryButton>
        <ClearAllFilters loading={loading} />
      </ButtonsContainer>
    </ApplyFilterContainer>
  );
};

const ApplyFilterContainer = styled.div`
  width: 100%;
  height: 180px;
  flex-shrink: 0;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  @media (min-width: 768px) {
    height: 130px;
  }
  flex-direction: column;
  padding: 20px;
`;
const TotalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;
const SubTitle = styled.p`
  font-size: 12px;
  margin: 0;
`;
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }
  justify-content: stretch;
  align-items: stretch;
  margin-top: 20px;
  gap: 10px;
`;
