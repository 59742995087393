import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  useListCustomerAddressesQuery,
  useMakeDefaultAddressMutation,
  ListCustomerAddressesDocument,
} from "../../../../generated/graphql";
import { useAuth } from "../../../../lib/Authentication";
import { H3, FlexRow, Spinner } from "../../../../shared/globals";
import { Card } from "../../../../shared/globals/UiElements/Card";
import LoadingSpinner from "../../../../shared/globals/UiElements/LoadingSpinner";
import AddressesEmptyState from "../../components/CustomerProfile/my-addresses/AddressesEmptyState";
import AddressesPanel from "../../components/CustomerProfile/my-addresses/AddressesPanel";
import AddAddress from "../../components/CustomerProfile/my-addresses/AddAddress";
import { useStore } from "../../../../lib/storeData";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { Badge } from "../../../../shared/globals/UiElements/Badge";

const UserAddresses = () => {
  const { user: loggedInUser } = useAuth();
  const { storeId } = useStore();
  const { data, loading } = useListCustomerAddressesQuery({
    variables: { customerId: loggedInUser?.id!, storeId },
  });
  const [makeDefaultAddress, { loading: settingDefault }] =
    useMakeDefaultAddressMutation({
      refetchQueries: [
        {
          query: ListCustomerAddressesDocument,
          variables: { customerId: loggedInUser?.id, storeId },
        },
      ],
    });

  const setDefaultAddress = async (userId: string, addressId: string) => {
    await makeDefaultAddress({
      variables: { customerId: userId, addressId: addressId, storeId },
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Flex column spacing="l">
      <Flex justifyContent="space-between">
        <H3 fontWeight="bold" fontSize="18px" className="!text-gray-800">
          <FormattedMessage defaultMessage="My Addresses" />
        </H3>
        {data?.customerAddresses?.length && <AddAddress />}
      </Flex>
      {data?.customerAddresses?.length ? (
        <>
          {data?.customerAddresses?.map((address) => (
            <AddressesPanel
              key={address?.id}
              userName={loggedInUser?.name!}
              address={address}
              isDefault={address.isDefault}
              isValid={true}
              SetDefaultButton={
                <FlexRow alignItems="center" spacing="m">
                  <DefaultAddressButton
                    onClick={() =>
                      setDefaultAddress(loggedInUser?.id!, address.id)
                    }
                    className="!text-[#EF6820]"
                  >
                    <FormattedMessage defaultMessage="Set as default address" />
                  </DefaultAddressButton>
                  {settingDefault && <Spinner inline size={16} />}
                </FlexRow>
              }
              DefaultBadge={
                <Badge
                  label={<FormattedMessage defaultMessage="Default Address" />}
                  className={
                    "!bg-[#ef68201a] h-fit  !py-4xs !px-xs !border-none !text-[#EF6820] !rounded-full !text-xs"
                  }
                />
              }
            />
          ))}
        </>
      ) : (
        <Card paddingSize="3xl">
          <AddressesEmptyState />
        </Card>
      )}
    </Flex>
  );
};

export default UserAddresses;

const DefaultAddressButton = styled.button`
  color: #fe9931;
  background-color: white;
  font-weight: 600;
  font-size: 14px;
  border: none;
  padding: 0px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
