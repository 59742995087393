import React from "react";
import styled from "styled-components";
import { FlexRow } from "../../../shared/globals";
import { Section } from "../../../shared/layout";
import { LeftSmallArrow, RightSmallArrow } from "../../../assets/Icons";
import { HeroBtnLink } from "../../../components/Hero/HeroBtnLink";
import type { HeroProps } from "../../types";
import { Photo } from "../../../shared/globals/UiElements/Photo";
import {
  HeroBox,
  HeroContainer,
  Heading,
  ButtonsWrapper,
  HeroBtn,
  SecondButton,
  NavigationContainer,
  NavigationCircle,
  ButtonHolder,
  Text,
} from "../../../components/Hero";
import { useStore } from "../../../lib/storeData";

const Hero: React.FC<HeroProps> = ({
  heroSettings,
  activeSlide,
  isButton1Exist,
  isButton2Exist,
  image,
  handleNavigationButtons,
  setActiveSlide,
  hasOverlay,
}) => {
  const { appearance } = useStore();

  const firstMenuItemId = appearance?.menus?.[0]?.items?.[0]?.id;
  let isFirstMenuItemHasChildren = false;

  appearance?.menus?.[0]?.items?.forEach((item) => {
    if (item?.parentId === firstMenuItemId) {
      isFirstMenuItemHasChildren = true;
      return;
    }
  });

  return (
    <StyledSection>
      <StyledHeroBox
        align={activeSlide?.align!}
        isFirstItemHasChildren={isFirstMenuItemHasChildren}
      >
        {image && (
          <Photo
            src={image || ""}
            alt={activeSlide?.headingText!}
            objectFit="cover"
            priority
            absolute
          />
        )}
        <HeroContainer hasOverlay={hasOverlay!}>
          <StyledHeading headingColor={activeSlide?.subHeadingColor!}>
            {activeSlide?.headingText}
          </StyledHeading>
          <StyledText
            subHeadingColor={activeSlide?.subHeadingColor!}
            subHeadingSize={String(activeSlide?.subHeadingSize!)}
            align={activeSlide?.align!}
          >
            {activeSlide?.subHeadingText}
          </StyledText>
          <ButtonsWrapper align={activeSlide?.align!}>
            {!isButton1Exist ? null : (
              <HeroBtnLink {...activeSlide?.buttons?.[0]?.link}>
                <HeroBtn>{activeSlide?.buttons?.[0]?.text}</HeroBtn>
              </HeroBtnLink>
            )}
            {!isButton2Exist ? null : (
              <HeroBtnLink {...activeSlide?.buttons?.[1]?.link}>
                <SecondButton>{activeSlide?.buttons?.[1]?.text}</SecondButton>
              </HeroBtnLink>
            )}
          </ButtonsWrapper>
        </HeroContainer>
        {heroSettings?.length! > 1 && (
          <NavigationContainer>
            <FlexRow>
              {heroSettings?.map((hero, i) => {
                return (
                  <NavigationCircle
                    key={hero?.id}
                    className={hero?.id === activeSlide?.id ? "active" : ""}
                    onClick={() => setActiveSlide?.(heroSettings[i])}
                  />
                );
              })}
            </FlexRow>
            <FlexRow>
              <ButtonHolder
                prefixIcon={<LeftSmallArrow />}
                reversed
                onClick={() => handleNavigationButtons?.("previous")}
              />
              <ButtonHolder
                suffixIcon={<RightSmallArrow />}
                reversed
                onClick={() => handleNavigationButtons?.("next")}
              />
            </FlexRow>
          </NavigationContainer>
        )}
      </StyledHeroBox>
    </StyledSection>
  );
};

export default Hero;

/**
 *
 * Styles
 *
 */

const StyledSection = styled(Section)`
  && {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
`;

const StyledHeroBox = styled(HeroBox)<{
  isFirstItemHasChildren?: boolean;
}>`
  border-radius: 4px;

  @media (min-width: 768px) {
    aspect-ratio: 16/9;
    width:
     // 235px sub-menu-width + 32px margin
      ${({ isFirstItemHasChildren }) =>
      isFirstItemHasChildren ? "calc(100% - 267px)" : "100%"};
  }
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  padding: 0 40px;

  @media (min-width: 768px) {
    padding: 0 10px;
  }
`;

const StyledText = styled(Text)`
  padding: 0 20px;
`;
