import { ServerResponse } from "http";
const IS_TECHNICAL_MAINTENANCE = false;

export function checkMaintenancePageCondition(
  isEnabled: boolean | undefined,
  pathname: string,
  res: ServerResponse | undefined,
  isMaintenanceTest: boolean
) {
  if (
    (isEnabled || (IS_TECHNICAL_MAINTENANCE && !isMaintenanceTest)) &&
    pathname !== "/maintenance" &&
    res instanceof ServerResponse
  ) {
    res.writeHead(302, { location: "/maintenance" });
    res.end();
    return;
  }

  if (
    !(isEnabled || (IS_TECHNICAL_MAINTENANCE && !isMaintenanceTest)) &&
    pathname === "/maintenance" &&
    res instanceof ServerResponse
  ) {
    res.writeHead(301, { location: "/" });
    res.end();
    return;
  }
}
