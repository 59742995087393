import React from "react";
import type { SearchPageProps } from "../../types";
import { StoreTemplate } from "../../TemplateLoader";
import SearchEmptyState from "../components/Filter/SearchEmptyState";

const Search: React.FC<SearchPageProps> = ({
  products,
  itemPerPage,
  productsTotalCount,
  offset,
  loading,
}) => {
  const Template = StoreTemplate.get();
  if (products?.length === 0) {
    return <SearchEmptyState />;
  }
  return (
    <Template.elements.FilterAndSort
      products={products}
      itemPerPage={itemPerPage}
      productsTotalCount={productsTotalCount}
      offset={offset}
      loading={loading}
    />
  );
};

export default Search;
