import styled from "styled-components";
import { FlexCol, FlexRow, H2 } from "../../../../shared/globals";
import { rtl } from "../../../../shared/styles-utils";
import {
  StyledForm,
  RequiredSpan,
} from "../../../../components/Checkout/Information/styled";

export const FeedbackMessageContainer = styled(FlexRow)<{ isSuccess: boolean }>`
  background: ${({ isSuccess }) => (isSuccess ? " #f0fdfa" : "#FFEDF6")};
  padding: 10px 20px;
  border: 1px solid #ccfbf1;
  border-radius: 6px;
  margin: 0 20px;

  > svg {
    ${rtl("margin-left", "margin-right")}: 10px;
  }
`;

export const ContactUsSubHeader = styled.div`
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-weight: 400;
  letter-spacing: -0.2px;
  max-width: 500px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledSection = styled(FlexCol)<{
  isColumn: boolean;
}>`
  gap: 30px;
  max-width: unset;
  align-items: stretch;

  @media (min-width: 1024px) {
    flex: 1 1 50%;
    flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
    max-width: 80%;
  }
`;

export const ContactInfoWrapper = styled(FlexCol)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 42px;
`;

export const ContactInfoHeader = styled.span`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-transform: uppercase;
`;

export const ContactInfoValue = styled.span`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.1px;
  word-break: break-word;
`;

export const ContactForm = styled(FlexCol)`
  width: 100%;
  @media (min-width: 1024px) {
    flex: 1 1 50%;
  }
`;

export const SocialLinksContainer = styled(FlexCol)`
  width: 100%;
  gap: 20px;
  margin-top: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  svg {
    color: #fff;
  }
`;

export const ContactInfo = styled(FlexCol)`
  flex: 1 1 50%;
  margin-top: 1.5rem;
  background-color: black;
  color: white;
  justify-content: center;
  align-items: start;
  border-radius: 10px;
  padding: 20px 40px;
  @media (min-width: 1024px) {
    margin-top: 2.4rem;
  }
  svg {
    min-width: 24px;
    min-height: 24px;
  }
`;

export const AddressBook = styled(FlexCol)`
  width: 100%;
`;

export const InfoHolder = styled(FlexRow)`
  align-items: flex-start;
  margin-bottom: 59px;

  &:nth-child(3) {
    margin-bottom: 0;
  }
`;

export const Info = styled(FlexCol)`
  ${rtl("margin-right", "margin-left")}: 10px;
  h3 {
    margin: 5px 0 0;
  }
`;

export const HorizontalLine = styled.hr`
  width: 100%;
  border: 1px solid white;
  margin: 30px 0 0;
`;

export const TextFollow = styled(H2)`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: white;
`;

export { StyledForm, RequiredSpan };
