import React from "react";

export const ShippingAddressIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22 13.9961H18.5C18.2239 13.9961 18 13.7722 18 13.4961V9.99609H21.2"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5566 18.4393C18.9856 18.8683 19.114 19.5135 18.8819 20.074C18.6497 20.6345 18.1028 21 17.4961 21C16.8894 21.0001 16.3424 20.6346 16.1102 20.0741C15.878 19.5136 16.0063 18.8684 16.4353 18.4394L16.4354 18.4393C17.0212 17.8536 17.9708 17.8536 18.5566 18.4393V18.4393"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.55662 18.4393C7.98564 18.8683 8.11401 19.5135 7.88186 20.074C7.64972 20.6345 7.10279 21 6.49609 21C5.8894 21.0001 5.34243 20.6346 5.11023 20.0741C4.87803 19.5136 5.00633 18.8684 5.4353 18.4394V18.4394L5.4356 18.4391C6.02125 17.8535 6.97078 17.8535 7.55643 18.4391L7.5566 18.4393V18.4393"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.0812 19H7.91016"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.9104 19H21C21.5523 19 22 18.5522 22 18V12.3812C22 12.1269 21.9515 11.875 21.857 11.6388L20.5023 8.25296C20.1985 7.49371 19.4632 6.99586 18.6454 6.99585H15"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.08159 19H3C2.44772 19 2 18.5523 2 18V12"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 14.9961H14C14.5523 14.9961 15 14.5484 15 13.9961V5.99609C15 5.44381 14.5523 4.99609 14 4.99609H12"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 5.5C2 7.13852 3.788 8.88882 4.808 9.75436C5.21082 10.0819 5.78818 10.0819 6.191 9.75436C7.212 8.88882 9 7.13852 9 5.5"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.501 5.24001C5.64446 5.24056 5.76037 5.35721 5.76 5.50067C5.75963 5.64413 5.64313 5.76019 5.49967 5.76C5.35621 5.75982 5.24 5.64347 5.24 5.50001C5.23963 5.43076 5.26704 5.36426 5.3161 5.3154C5.36516 5.26653 5.43176 5.23937 5.501 5.24001"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 5.5V5.5C2 3.567 3.567 2 5.5 2V2C7.433 2 9 3.567 9 5.5V5.5"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
