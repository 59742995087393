import { IntlShape } from "react-intl";
import {
  Money,
  DiscountAppliedOnType,
  DiscountStatus,
  CurrencyCode,
  AutomaticDiscountFragment,
} from "../../generated/graphql";
import {
  CartItemType,
  CartState,
  Checkout,
  CheckoutReceipt,
  CheckoutStatusEnum,
  CheckoutStepInfo,
} from "./types";
import {
  addMoney,
  multiplyMoneyByNumber,
  subtractMoney,
} from "../../shared/utils/money";

export function getInitialState(currency: CurrencyCode): CartState {
  const ZeroMoney: Money = { amount: 0, currencyCode: currency };
  return {
    isSideCart: false,
    isCartUpdating: false,
    items: [],
    buyNowItem: null,
    itemsTotal: ZeroMoney,
    checkout: {
      status: CheckoutStatusEnum.Active,
      coupon: { code: "", percentageOff: 0 },
      receipt: {
        automaticDiscount: ZeroMoney,
        couponDiscount: ZeroMoney,
        shipping: ZeroMoney,
        shippingDiscount: ZeroMoney,
        subtotal: ZeroMoney,
        tax: ZeroMoney,
        total: ZeroMoney,
      },
    },
  };
}

export function findShippingDiscount(
  discountsArray: (AutomaticDiscountFragment | null)[],
  subtotal: Money | null
) {
  let maxDiscount: AutomaticDiscountFragment | null = null;

  // Filter the discounts for shipping discounts that are active and applicable to the subtotal amount
  const appliedShippingDiscount = discountsArray?.filter(
    (discount) =>
      discount?.appliedOn === DiscountAppliedOnType.Shipping &&
      discount?.status === DiscountStatus.Active &&
      subtotal?.amount &&
      discount?.customerBuys?.value?.amount &&
      subtotal?.amount >= discount?.customerBuys?.value?.amount
  );

  // Find the discount with the highest percentage and maxDiscount === null for the first iteration
  appliedShippingDiscount?.forEach((discount) => {
    if (
      discount?.percentage !== undefined &&
      (maxDiscount === null || discount.percentage! > maxDiscount.percentage!)
    ) {
      maxDiscount = discount;
    }
  });
  return maxDiscount;
}

export function calculateReceipt(
  itemsTotal: Money,
  checkout: Checkout | undefined,
  discountAmount: number,
  shippingDiscount: AutomaticDiscountFragment | null,
  totalTax: Money
): CheckoutReceipt {
  const currencyCode = itemsTotal?.currencyCode;

  const automaticDiscount: Money = {
    amount: discountAmount,
    currencyCode,
  };

  const subtotal = addMoney(itemsTotal, automaticDiscount);

  const couponPercentage = checkout?.coupon?.percentageOff;
  const couponFixedOffAmount = checkout?.coupon?.fixedAmountOff;

  const couponDiscount: Money = {
    amount: couponFixedOffAmount
      ? couponFixedOffAmount
      : couponPercentage
      ? multiplyMoneyByNumber(itemsTotal, couponPercentage)?.amount / 100
      : 0,
    currencyCode,
  };
  const subtotalWithCouponDiscount = subtractMoney(itemsTotal, couponDiscount);

  const subtotalWithTax = addMoney(subtotalWithCouponDiscount, totalTax);

  const shippingPercentageAmount =
    ((shippingDiscount?.percentage || 0) / 100) *
    (checkout?.shippingInfo?.cost?.amount || 0);

  const shippingDiscountAmount = shippingDiscount?.percentage
    ? shippingPercentageAmount
    : shippingDiscount?.amount?.amount || 0;

  const shippingCost: Money = {
    amount:
      (checkout?.shippingInfo?.cost?.amount || 0) - shippingDiscountAmount,
    currencyCode,
  };

  const total = addMoney(subtotalWithTax, shippingCost);

  return {
    subtotal,
    automaticDiscount,
    couponDiscount,
    tax: totalTax,
    shipping: shippingCost,
    shippingDiscount: { amount: shippingDiscountAmount, currencyCode },
    total,
  };
}

export const getStepsMessages = (intl: IntlShape, route: string) => {
  const stepsMessages = [
    intl.formatMessage({ defaultMessage: "Cart" }),
    intl.formatMessage({ defaultMessage: "Information" }),
    intl.formatMessage({ defaultMessage: "Delivery" }),
    intl.formatMessage({ defaultMessage: "Payment" }),
  ];

  if (route.includes("invalid-order")) {
    const deliveryIndex = stepsMessages.findIndex(
      (step) => step === "Delivery"
    );

    stepsMessages.splice(
      deliveryIndex + 1,
      0,
      intl.formatMessage({ defaultMessage: "Invalid Order" })
    );
  }

  return stepsMessages as CheckoutStepInfo["name"][];
};

export function getActiveStepInfo(pathname: string): CheckoutStepInfo {
  const isInvalidOrder = pathname?.includes("invalid-order");
  const discriminator = isInvalidOrder ? 4 : 3;

  if (pathname?.includes("confirmation")) {
    return {
      name: "Confirmation",
      number: discriminator + 2,
    };
  }

  if (pathname?.includes("payment")) {
    return {
      name: "Payment",
      number: discriminator + 1,
    };
  }

  if (pathname?.includes("invalid-order")) {
    return {
      name: "Invalid Order",
      number: discriminator,
    };
  }

  if (pathname?.includes("delivery")) {
    return {
      name: "Delivery",
      number: 3,
    };
  }

  if (pathname?.includes("cart")) {
    return {
      name: "Cart",
      number: 1,
    };
  }

  // should be last one as all paths includes checkout
  if (pathname?.includes("checkout")) {
    return {
      name: "Information",
      number: 2,
    };
  }

  return {
    name: "Other",
    number: -1,
  };
}

export function getDiscountsData(items: CartItemType[]) {
  let isAutomaticDiscount = false;
  let isCustomDiscount = false;
  const discountAmount = items?.reduce((acc, curr) => {
    const automaticDiscount = curr?.discount?.total?.amount;
    const customDiscount =
      curr?.quantity * (curr?.customDiscount?.itemDiscount?.amount || 0);

    if (automaticDiscount) isAutomaticDiscount = true;
    if (customDiscount) isCustomDiscount = true;

    return acc + (automaticDiscount || customDiscount || 0);
  }, 0);

  return { discountAmount, isCustomDiscount, isAutomaticDiscount };
}

export const roundNumber = (num: number, decimals = 4) => {
  const temp = 10 ** decimals;
  return Math.round((num + Number.EPSILON) * temp) / temp;
};
