import React, { useEffect, useState } from "react";
import { ApolloError } from "@apollo/client";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";
import { getLocaleInfo } from "../../../../../lib/i18n/locales-data";
import Flex from "../../../../../shared/globals/UiElements/Flex";

interface FilterByCategoryProps {
  availableFilters: {
    propertyName: string;
    propertyId: string;
    valueName: string;
    valueId: string;
    count?: number;
  }[];
  loading: boolean;
  error: ApolloError | undefined;
  selectedFilterIds: string[];
}

export const AvailableFilterChoices = ({
  availableFilters: availableFiltersProp,
  loading,
  error,
  selectedFilterIds,
}: FilterByCategoryProps) => {
  const router = useRouter();
  const { locale } = useIntl();
  const activeLocale = getLocaleInfo(locale).code;
  const [availableFilters, setAvailableFilters] =
    useState(availableFiltersProp);

  useEffect(() => {
    if (availableFiltersProp?.length) {
      setAvailableFilters(availableFiltersProp);
    }
  }, [availableFiltersProp]);

  if (error && !availableFilters) {
    return null;
  }

  availableFilters?.sort((a, b) => {
    const fa = a.valueName,
      fb = b.valueName;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  return (
    <Flex column spacing="none" className="mx-2xl">
      {availableFilters?.map((filter, i) => (
        <Flex alignItems="center" spacing="s" className="py-xs" key={i}>
          <input
            type="checkbox"
            checked={
              selectedFilterIds?.includes(filter.valueId) ||
              selectedFilterIds?.includes(
                filter?.valueId! + "|" + filter?.propertyId!
              )
            }
            id={filter?.valueId!}
            disabled={loading}
            onChange={(event) => {
              let filters = selectedFilterIds;
              if (event.target.checked) {
                if (filter.valueId.startsWith("c:"))
                  filters.push(filter?.valueId!);
                else filters.push(filter?.valueId! + "|" + filter?.propertyId!);
              } else {
                if (filter.valueId.startsWith("c:"))
                  filters = filters.filter((item) => item !== filter?.valueId!);
                else
                  filters = filters.filter(
                    (item) =>
                      item !== filter?.valueId! + "|" + filter.propertyId
                  );
                if (router.route.includes("collection")) {
                  router.push(`/${activeLocale}/shop`);
                  return;
                }
              }
              if (router.query.collection) {
                filters.push(
                  availableFilters?.find(
                    (item) => item.valueName === router.query.collection
                  )?.valueId!
                );
              }

              router.push(
                {
                  pathname: router.pathname.includes("collection")
                    ? `/${activeLocale}/shop`
                    : router.asPath?.split("?")[0],
                  query: {
                    ...router.query,
                    page: 1,
                    filters,
                  },
                },
                undefined,
                { scroll: false }
              );
            }}
            className="disabled:cursor-not-allowed disabled:opacity-50 cursor-pointer"
          />

          <label
            htmlFor={filter.valueId!}
            className="text-gray-700 cursor-pointer"
          >
            {filter?.valueName}
          </label>
        </Flex>
      ))}
    </Flex>
  );
};
