import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Group, ShoppingCart } from "../../../../assets/Icons";
import { useStore } from "../../../../lib/storeData";
import { FlexCol, FlexRow, H3, H4, H5 } from "../../../../shared/globals";
import { Card } from "../../../../shared/globals/UiElements/Card";
import { Price } from "../../../../components/Price/Price";
import type {
  BuildStateCategory,
  CustomProductInfoProps,
} from "../../../types";
import Alert from "../../../../shared/globals/UiElements/Alert";
import { Badge } from "../../../../components/Badge/Badge";
import { DiscountPrice } from "../../../../components/Price";
import { BuyNowButtonDisplay } from "../../../../generated/graphql";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import BuyNowButton from "../BuyNowButton/BuyNowButton";
import Button from "../Button/Button";

interface SummarySectionProps {
  product: CustomProductInfoProps["product"];
  buildTotal: number;
  buildSubtotal: number;
  isDiscount?: boolean;
  isAlertOpen?: boolean;
  buildState: BuildStateCategory[] | undefined;
  setIsAlertOpen?: (value: React.SetStateAction<boolean>) => void;
  setIsSummary: React.Dispatch<React.SetStateAction<boolean>>;
  onAddToCart: () => void;
  loading?: boolean;
  isUpdating?: boolean;
}

const SummarySection = ({
  product,
  buildTotal,
  buildSubtotal,
  isDiscount,
  isAlertOpen,
  buildState,
  setIsAlertOpen,
  setIsSummary,
  onAddToCart,
  loading,
  isUpdating,
}: SummarySectionProps) => {
  const { currency: currencyCode, appearance } = useStore();

  return (
    <Card
      paddingSize="none"
      className="px-2 flex-1 sticky top-5 [&>*]:py-2 [&>*:not(:last-child)]:border-0 [&>*:not(:last-child)]:border-b [&>*:not(:last-child)]:border-solid [&>*:not(:last-child)]:border-gray-200"
    >
      <StyledH4>
        <FormattedMessage defaultMessage="My Selections" />
      </StyledH4>
      <FlexRow alignItems="flex-start" spacing="m">
        {product.images?.[0]?.src && (
          <ItemImg imgDetails={product.images?.[0]} onlyImg size={90} />
        )}
        <FlexCol alignItems="flex-start">
          <Badge
            customInStock={product.isInStock}
            outOfStock={!product.isInStock}
          />
          {isDiscount && <Badge customItemDiscount={product?.discount} />}
          <H3 style={{ marginTop: 6 }} fontWeight={600}>
            {product.title}
          </H3>
        </FlexCol>
      </FlexRow>
      <div>
        <SummaryButton
          fullWidth
          prefixIcon={<Group />}
          onClick={() => setIsSummary(true)}
          type="secondary"
        >
          <FormattedMessage defaultMessage="Configuration Summary" />
        </SummaryButton>
      </div>
      <SummaryPrice alignItems="flex-start">
        <SummaryPriceText>
          <FormattedMessage defaultMessage="Price including Taxes" />
        </SummaryPriceText>
        <FlexCol>
          {isDiscount && (
            <StyledDiscountPrice
              fontSize="14px"
              money={{
                amount: buildSubtotal,
                currencyCode,
              }}
            />
          )}
          <SummaryPriceTotal money={{ amount: buildTotal, currencyCode }} />
        </FlexCol>

        <Button
          fullWidth
          prefixIcon={<ShoppingCart />}
          onClick={onAddToCart}
          isLoading={loading}
          isDisabled={!product.isInStock || loading}
        >
          {isUpdating ? (
            <FormattedMessage defaultMessage="Update item" />
          ) : (
            <FormattedMessage defaultMessage="Add to cart" />
          )}
        </Button>
        {appearance?.productDisplay?.buyNowButton?.display !==
          BuyNowButtonDisplay.None && (
          <BuyNowButton
            isDisabled={!product.isInStock || loading}
            product={product}
            buildState={buildState}
          />
        )}
        {isAlertOpen && (
          <Alert
            message={<FormattedMessage defaultMessage="Quantity exceeded" />}
            closeAction={() => setIsAlertOpen!(false)}
          />
        )}
      </SummaryPrice>
    </Card>
  );
};

export default SummarySection;

/**
 *
 *
 * Styles
 *
 *
 */

const StyledH4 = styled(H4)`
  padding: 10px;
  margin: 0px -10px;
`;

const SummaryButton = styled(Button)`
  color: ${({ theme }) => theme.text.default};
  border: 1px solid #e5e9eb;
  font-size: 12px;
  cursor: pointer;
`;

const SummaryPrice = styled(FlexCol)`
  & > * {
    margin-top: 10px;
  }
`;

const SummaryPriceText = styled(H5)`
  color: #a3a3a3;
  font-weight: 400;
`;

const SummaryPriceTotal = styled(Price)`
  align-self: flex-start;
  font-size: 24px;
  font-weight: 500;
`;

const StyledDiscountPrice = styled(DiscountPrice)`
  align-self: flex-start;
  padding: 0;
`;
