import * as React from "react";
import styled from "styled-components";
import { Link } from "../../../lib/i18n";
import { useStore } from "../../../lib/storeData";
import { themeColor } from "../../../shared/styles-utils";
import ItemImg from "../../../shared/globals/UiElements/ItemImg";
import { LogoSizes } from "../../../shared/globals/types";
import { LogoSize } from "../../../generated/graphql";

export interface LogoProps {}

const Logo: React.FC<LogoProps> = () => {
  const { logo, name } = useStore();

  const content = logo?.image?.src ? (
    <LogoWrapper>
      <ItemImg
        imgDetails={logo?.image}
        onlyImg
        size={LogoSizes[logo?.size || LogoSize.Medium]}
        isSquare={false}
      />
    </LogoWrapper>
  ) : (
    <span>{name}</span>
  );
  return (
    <Link href="/" passHref>
      <StyledSpan data-test="store-logo">{content}</StyledSpan>
    </Link>
  );
};

export default Logo;

/**
 *
 * Styles
 *
 */

const LogoWrapper = styled.div`
  > div {
    height: unset;
    ::after {
      padding-bottom: 0;
    }
  }
`;

const StyledSpan = styled.span`
  display: flex;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.6;
  text-decoration: none;
  color: ${themeColor("primary")};
`;
