import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import HeadingSection from "../components/customComponents/HeadingSection";
import { ShoppingCart } from "../../../assets/Icons";
import { PriceBase } from "../../../components/Price/Price";
import { useStore } from "../../../lib/storeData";
import { FlexCol, FlexRow, H2, H4, H5 } from "../../../shared/globals";
import { Card } from "../../../shared/globals/UiElements/Card";
import { rtl } from "../../../shared/styles-utils";
import type { CustomProductInfoProps, TemplateElement } from "../../types";
import Alert from "../../../shared/globals/UiElements/Alert";
import { Badge } from "../../../components/Badge/Badge";
import { DiscountPrice } from "../../../components/Price";
import { StoreTemplate } from "../../TemplateLoader";
import { BuyNowButtonDisplay } from "../../../generated/graphql";
import { ZIndex } from "../../../shared/globals/types";
import BuyNowButton from "../components/BuyNowButton/BuyNowButton";
import Button from "../components/Button/Button";

const MobileCustomProduct: TemplateElement<CustomProductInfoProps> = ({
  product,
  buildState,
  activeCategoryId,
  buildTotal,
  buildSubtotal,
  isDiscount,
  isAlertOpen,
  exceededVariantId,
  setExceededVariantId,
  setIsAlertOpen,
  handleAddToCart,
  setActiveCategoryId,
  setBuildState,
  isLoading,
  isUpdating,
}) => {
  const { currency: currencyCode, appearance } = useStore();
  const Template = StoreTemplate.get();

  const activeCategory = buildState?.find(
    (category) => category?.id === activeCategoryId
  );

  return (
    <>
      <HeadingSection product={product} />
      <Card paddingSize="none">
        <Template.elements.CategoriesSection
          buildState={buildState!}
          activeCategory={activeCategory!}
          exceededVariantId={exceededVariantId}
          setExceededVariantId={setExceededVariantId}
          setActiveCategoryId={setActiveCategoryId!}
          setBuildState={setBuildState}
          activeCategoryId={activeCategoryId}
          productId={product.id}
        />
      </Card>
      <Card
        paddingSize="lg"
        className={`fixed left-0 bottom-[0px] z-${[ZIndex["TOOLTIP"]]}`}
      >
        <FlexRow alignItems="flex-start" justifyContent="space-between">
          <FlexColMargin alignItems="flex-start">
            <FlexRow>
              <Badge
                customInStock={product.isInStock}
                outOfStock={!product.isInStock}
              />
              {isDiscount && <Badge customItemDiscount={product?.discount} />}
            </FlexRow>
            <H4 style={{ margin: "4px 0" }}>{product.title}</H4>
            <SummaryPriceText>
              <FormattedMessage defaultMessage="Price including Taxes" />
            </SummaryPriceText>
            <H2 fontWeight={500}>
              <PriceBase
                money={{
                  amount: buildTotal!,
                  currencyCode,
                }}
              />
              {isDiscount && (
                <StyledDiscountPrice
                  fontSize="16px"
                  money={{
                    amount: buildSubtotal!,
                    currencyCode,
                  }}
                />
              )}
            </H2>
          </FlexColMargin>
          <FlexCol alignItems="flex-start" spacing="xs">
            <Button
              fullWidth
              prefixIcon={<ShoppingCart />}
              onClick={handleAddToCart}
              isLoading={isLoading}
              isDisabled={!product.isInStock || isLoading}
            >
              {isLoading ? (
                <FormattedMessage defaultMessage="Loading" />
              ) : isUpdating ? (
                <FormattedMessage defaultMessage="Update item" />
              ) : (
                <FormattedMessage defaultMessage="Add to cart" />
              )}
            </Button>
            {appearance?.productDisplay?.buyNowButton?.display !==
              BuyNowButtonDisplay.None && (
              <BuyNowButton
                isDisabled={!product.isInStock || isLoading}
                product={product}
                buildState={buildState!}
              />
            )}
            {isAlertOpen && (
              <Alert
                message={
                  <FormattedMessage defaultMessage="Quantity exceeded" />
                }
                closeAction={() => setIsAlertOpen!(false)}
              />
            )}
          </FlexCol>
        </FlexRow>
      </Card>
    </>
  );
};

export default MobileCustomProduct;

/**
 *
 *
 * Styles
 *
 *
 */

const FlexColMargin = styled(FlexCol)`
  max-width: 200px;
  ${rtl("margin-left", "margin-right")}: 6px;
`;

const SummaryPriceText = styled(H5)`
  margin: 4px 0;
  color: #a3a3a3;
`;

const StyledDiscountPrice = styled(DiscountPrice)`
  padding: ${rtl("0 6px 0 0", "0 0 0 6px")};
`;
