const LogoutIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 10.0002H13.4167"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.16602 6.66679V5.27831C7.166 4.46357 7.75502 3.76823 8.55868 3.63429L15.2253 2.52318C15.7086 2.44263 16.2028 2.5788 16.5767 2.89548C16.9505 3.21215 17.166 3.67727 17.166 4.16719V15.833C17.166 16.3229 16.9505 16.7881 16.5766 17.1047C16.2028 17.4214 15.7086 17.5576 15.2253 17.477L8.55868 16.3659C7.75504 16.232 7.16602 15.5367 7.16602 14.7219V13.3335"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.916 7.5L13.416 10L10.916 12.5"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { LogoutIcon };
