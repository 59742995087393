import React, { FC } from "react";
import styled from "styled-components";
import { PageHeaderProps } from "../../types";
import { H1 } from "../../../shared/globals";

const PageHeader: FC<PageHeaderProps> = ({ pageTitle }) => {
  return (
    <H1>
      <StyledHeader>{pageTitle}</StyledHeader>
    </H1>
  );
};

const StyledHeader = styled.div`
  width: 100%;
  min-height: 100px;
  background-color: ${({ theme }) => theme.bg.wash}1a;
  text-transform: uppercase;
  display: flex;
  text-align: center;
  align-items: center;
  place-content: center;
`;

export default PageHeader;
