import React from "react";

export const MailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3736 12.718L19.8796 9.663C20.5806 9.189 20.9996 8.398 20.9996 7.552V7.552C20.9996 6.142 19.8576 5 18.4486 5H5.56565C4.15665 5 3.01465 6.142 3.01465 7.551V7.551C3.01465 8.397 3.43365 9.188 4.13465 9.663L8.64065 12.718C10.6736 14.096 13.3406 14.096 15.3736 12.718V12.718Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 7.55078V16.9998C3 18.6568 4.343 19.9998 6 19.9998H18C19.657 19.9998 21 18.6568 21 16.9998V7.55178"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
