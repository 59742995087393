import React, { FC } from "react";
import styled from "styled-components";
import { FlexCol } from "../../../shared/globals";
import { SectionCardWrapperProps } from "../../types";
import { SectionBackgroundTypeEnum } from "../../../generated/graphql";

const SectionCardWrapper: FC<SectionCardWrapperProps> = ({
  children,
  isCollection,
  isSlider,
  background,
}) => {
  return (
    <CardWrapper
      isCollection={isCollection}
      background={background}
      applyPadding={background?.type === SectionBackgroundTypeEnum.Color}
      isSlider={isSlider}
    >
      {children}
    </CardWrapper>
  );
};

export default SectionCardWrapper;

const CardWrapper = styled(FlexCol)<{
  isCollection?: boolean;
  background?: {
    color?: string;
    type: SectionBackgroundTypeEnum;
  };
  isStretchToFullWidth?: boolean;
  applyPadding?: boolean;
  isSlider?: boolean;
}>`
  height: ${({ isSlider }) => (isSlider ? "100%" : "auto")};
  background-color: ${({ background }) =>
    background?.type === SectionBackgroundTypeEnum.Color
      ? background?.color
      : "transparent"};
  padding: ${({ applyPadding }) => (applyPadding ? "10px" : "0")};
`;
