import React from "react";

export const OutlineLogoutIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4082_53944)">
        <rect x="2" y="1" width="36" height="36" rx="18" fill="white" />
        <rect
          x="2.5"
          y="1.5"
          width="35"
          height="35"
          rx="17.5"
          stroke="#D0D5DD"
        />
        <path
          d="M12.5 18.9997H22.9167"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.6665 15.6663V14.2778C16.6665 13.4631 17.2555 12.7677 18.0592 12.6338L24.7258 11.5227C25.2091 11.4421 25.7033 11.5783 26.0771 11.895C26.451 12.2117 26.6665 12.6768 26.6665 13.1667V24.8325C26.6665 25.3225 26.4509 25.7876 26.0771 26.1042C25.7033 26.4209 25.2091 26.5571 24.7258 26.4765L18.0592 25.3654C17.2555 25.2315 16.6665 24.5362 16.6665 23.7214V22.333"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.4165 16.5L22.9165 19L20.4165 21.5"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4082_53944"
          x="0"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4082_53944"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4082_53944"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
