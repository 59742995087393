import React from "react";
import { FormattedMessage } from "react-intl";
import { EmptyCartIcon } from "../../../assets/Icons";
import { Pagination } from "../../../components/Pagination/Pagination";
import { EmptyState } from "../../../components/UtilityComponents/EmptyState";
import { Section } from "../../../shared/layout";
import { StoreTemplate } from "../../TemplateLoader";
import type { SearchPageProps } from "../../types";
import LoadingSpinner from "../../../shared/globals/UiElements/LoadingSpinner";

const Search: React.FC<SearchPageProps> = ({
  products,
  itemPerPage,
  productsTotalCount,
  offset,
  loading,
}) => {
  const Template = StoreTemplate.get();
  return (
    <>
      <Template.elements.PageHeader
        pageTitle={<FormattedMessage defaultMessage="Search Results" />}
      />

      {products?.length > 0 && <Template.elements.FilterAndSort />}

      <Section background="backgroundReverse">
        {loading ? (
          <LoadingSpinner />
        ) : products?.length === 0 ? (
          <EmptyState
            title={
              <FormattedMessage defaultMessage="There are no products match your search criteria" />
            }
            image={<EmptyCartIcon />}
          />
        ) : (
          <>
            <Template.elements.ProductsList products={products} />
            <Pagination
              offset={offset}
              itemPerPage={itemPerPage}
              totalCount={productsTotalCount}
              rounded
            />
          </>
        )}
      </Section>
    </>
  );
};

export default Search;
