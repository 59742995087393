import React, { CSSProperties, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Search } from "../../assets/Icons";
import useBreakPoints from "../../shared/utils/useBreakPoints";
import Lightbox, { SlideImage } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {
  HoverToZoom,
  HoverToZoomBadge,
  HoverToZoomContainer,
  lightBoxStyles,
} from "./styled";
import { useStore } from "../../lib/storeData";
import { ProductImageZoomBehavior } from "../../generated/graphql";
interface HoverToZoomProps {
  src: string;
  images: SlideImage[];
  zoomScale?: number;
  hideBadge?: boolean;
  badgeBottomPosition?: CSSProperties["bottom"];
  objectFit?: CSSProperties["objectFit"];
}

const HoverToZoomClickToLightBox = ({
  src,
  images,
  zoomScale = 2,
  hideBadge,
  badgeBottomPosition = "5px",
  objectFit = "contain",
}: HoverToZoomProps) => {
  const [mouseX, setMouseX] = React.useState<null | number>();
  const [mouseY, setMouseY] = React.useState<null | number>();
  const [isHovered, setIsHovered] = React.useState(false);
  const [showLightBox, setShowLightBox] = React.useState(false);
  const { isTablet } = useBreakPoints();
  const { appearance } = useStore();

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (
      showLightBox ||
      appearance?.productDisplay?.productImageZoomBehavior ===
        ProductImageZoomBehavior.None ||
      isTablet
    )
      return;
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();

    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.screenY - top) / height) * 100;

    setMouseX(x);
    setMouseY(y);
    setIsHovered(true);
  };

  useEffect(() => {
    // disable scrolling when lightbox is open
    if (showLightBox) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showLightBox]);

  return (
    <HoverToZoomContainer
      isZoomEnabled={
        appearance?.productDisplay?.productImageZoomBehavior ===
        ProductImageZoomBehavior.OnHover
      }
      onClick={() => {
        if (
          appearance?.productDisplay?.productImageZoomBehavior ===
          ProductImageZoomBehavior.None
        )
          return;
        setIsHovered(false);
        setShowLightBox(true);
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={() => setIsHovered(false)}
    >
      <HoverToZoom
        src={src}
        isHovered={isHovered}
        x={mouseX}
        y={mouseY}
        zoomScale={zoomScale}
        objectFit={objectFit}
      />
      {!hideBadge &&
      !isHovered &&
      appearance?.productDisplay?.productImageZoomBehavior ===
        ProductImageZoomBehavior.OnHover ? (
        <HoverToZoomBadge badgeBottomPosition={badgeBottomPosition}>
          <Search />
          {isTablet ? (
            <FormattedMessage defaultMessage="Tab to enlarge" />
          ) : (
            <FormattedMessage defaultMessage="Hover to zoom & click to enlarge" />
          )}
        </HoverToZoomBadge>
      ) : null}
      {appearance?.productDisplay?.productImageZoomBehavior ===
        ProductImageZoomBehavior.OnHover && (
        <Lightbox
          open={showLightBox}
          plugins={[Zoom, Thumbnails]}
          carousel={{
            preload: 3,
            finite: true,
          }}
          index={
            images.indexOf(images?.find((image) => image.src === src)!) || 0
          }
          styles={lightBoxStyles}
          animation={{
            zoom: 400,
            navigation: 400,
          }}
          zoom={{
            maxZoomPixelRatio: 3,
            scrollToZoom: true,
          }}
          close={() => setShowLightBox(false)}
          slides={images}
        />
      )}
    </HoverToZoomContainer>
  );
};

export default HoverToZoomClickToLightBox;
