import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { ShoppingBag } from "../../../../assets/Icons";
import { DefaultTextPrice } from "../../../../components/Price";
import { FlexRow, FlexCol, H3, H5 } from "../../../../shared/globals";
import { Link } from "../../../../lib/i18n";
import { PrimaryButton } from "../../../../components/Button";
import { StoreTemplate } from "../../../TemplateLoader";
import { themeColor } from "../../../../shared/styles-utils";
import LoadingSpinner from "../../../../shared/globals/UiElements/LoadingSpinner";
import { isCartDirty } from "../../../../shared/utils/error-utils";
import { useCartData } from "../../../../lib/cartData/useCartData";
import { CartCardSummary } from "../CartCardSummary/CartCardSummary";

const CartCard = () => {
  const Template = StoreTemplate.get();
  const {
    cart: { receipt, shippingRate, isLoading, cartErrors, id },
  } = useCartData();
  const isFreeShippingApplied = receipt?.shipping?.amount === 0;

  const canNotProceedToCheckout = isCartDirty(cartErrors);

  return (
    <Container fullWidth>
      <CartHeader fullWidth spacing="xs">
        <ShoppingBag />
        <H3 fontWeight={600}>
          <FormattedMessage defaultMessage="Cart Totals" />
        </H3>
      </CartHeader>

      <Template.elements.Coupon />
      <CartCardSummary />
      <FlexCol fullWidth spacing="l">
        <FlexRow
          fullWidth
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <H3 fontWeight={600}>
            <FormattedMessage defaultMessage="Total" />
          </H3>

          <FlexCol alignItems="flex-end">
            {isLoading ? (
              <FlexRow alignItems="center" spacing="m">
                <H3 fontSize="18px" fontWeight={700}>
                  <FormattedMessage defaultMessage="Calculating..." />
                </H3>

                <LoadingSpinner
                  minHeight="fit-content"
                  position="center"
                  size="small"
                />
              </FlexRow>
            ) : (
              <H3 fontSize="18px" fontWeight={700}>
                <DefaultTextPrice money={receipt?.total} />
              </H3>
            )}
            {shippingRate ? (
              shippingRate?.cost?.amount && !isFreeShippingApplied ? (
                <H5 color="secondary">
                  <FormattedMessage defaultMessage="+ Shipping" />
                </H5>
              ) : (
                <H5 color="primary">
                  <FormattedMessage defaultMessage="Free Shipping" />
                </H5>
              )
            ) : (
              <H5 color="secondary">
                <FormattedMessage defaultMessage="To be calculated" />
              </H5>
            )}
          </FlexCol>
        </FlexRow>

        <Link
          href={`/checkout/${id}`}
          fullWidth
          disabled={isLoading || canNotProceedToCheckout}
        >
          <CheckoutButton
            data-test="button-checkout"
            fullWidth
            loadOnRouteChange
            to={`/checkout/${id}`}
            disabled={isLoading || canNotProceedToCheckout}
          >
            <FormattedMessage defaultMessage="Checkout" />
          </CheckoutButton>
        </Link>

        <Link href="/shop">
          <ShoppingButton data-test="button-shop" reversed>
            <FormattedMessage defaultMessage="Continue Shopping" />
          </ShoppingButton>
        </Link>
      </FlexCol>
    </Container>
  );
};

export default CartCard;

const Container = styled(FlexCol)`
  max-width: 350px;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.bg.wash}1a;
  > * {
    padding: 16px;
    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.bg.wash}1a;
    }
  }
`;

const CartHeader = styled(FlexRow)`
  background-color: ${({ theme }) => theme.bg.reverse};
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`;

const CheckoutButton = styled(PrimaryButton)`
  padding-block: 12px;
  background-color: ${themeColor("primary")};
  border-color: ${themeColor("primary")};
`;

const ShoppingButton = styled(PrimaryButton)`
  padding: 0;
  color: ${themeColor("primary")};
  margin: auto;
`;
