import { CartCustomItem, CartSimpleItem } from "../../generated/graphql";
import { CartContextType } from "./CartContext";

export const getCartItem = (cart: CartContextType, productId: string) => {
  if (productId?.startsWith("ProductVariant_")) {
    return cart?.items?.find(
      (item) => (item as CartSimpleItem)?.variant?.id === productId
    );
  }
  return cart?.items?.find(
    (item) => (item as CartCustomItem).product.id === productId
  );
};

export const getSelectedVariants = (item: CartCustomItem) => {
  const categoryId = item?.categories[0].category.id;
  return item?.categories[0].selectedVariants.map((variant) => ({
    categoryId,
    variantId: variant.variant?.id,
    quantity: variant.quantity,
  }));
};
