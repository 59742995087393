import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useRouter } from "../../../lib/i18n";
import { DefaultTextPrice, DiscountPrice, Price } from "../../Price";
import {
  CouponIcon,
  HelpIcon,
  ShoppingBag,
  ShoppingCartPromotionIcon,
} from "../../../assets/Icons";
import { FlexRow, FlexCol, H3, P } from "../../../shared/globals";
import {
  Container,
  BorderBottom,
  FullWidth,
  StyledH3WithMargin,
  StyledButton,
  MarginBottom,
  ImgDiv,
  QuantitySpan,
  LightH4,
  HeavyH4,
  StyledH2,
  PriceWrapper,
  SelectedOptions,
  LightH4ForDiscount,
  HideOnMobile,
  StyledShippingCost,
  StyledShippingDiscount,
  StyledShippingDiscountBadge,
} from "./styled";
import { subtractMoney } from "../../../shared/utils/money";
import ItemImg from "../../../shared/globals/UiElements/ItemImg";
import {
  StyledNonDiscountedVariants,
  StyledNumber,
} from "../../../templates/default/components/Cart/styled";
import { StoreTemplate } from "../../../templates/TemplateLoader";
import Flex from "../../../shared/globals/UiElements/Flex";
import { Badge } from "../../../shared/globals/UiElements/Badge";
import { getLocaleInfo } from "../../../lib/i18n/locales-data";
import { getTranslatedDiscountTitle } from "../../../shared/utils/getTranslatedDiscountTitle";
import Tooltip from "../../../shared/globals/UiElements/Tooltip";
import { transformOrderItems } from "../utils/transformOrderItems";
import {
  CartCustomItem,
  CartSimpleItem,
  DiscountAppliedOnType,
  GetOrderByIdQuery,
} from "../../../generated/graphql";
import { useCartData } from "../../../lib/cartData/useCartData";
import CartSummaryPanel from "../../../templates/modern/components/customComponents/CartSummaryPanel";
import { CartContextType } from "../../../lib/cartData";

interface CheckoutCartProps {
  className?: string;
  isOpen?: boolean;
  isMobile?: boolean;
  orderDetails?: GetOrderByIdQuery | undefined;
  cartConfirmationData?: CartContextType;
}

export const CheckoutCart = ({
  className,
  isOpen,
  isMobile,
  orderDetails,
  cartConfirmationData,
}: CheckoutCartProps) => {
  const Template = StoreTemplate.get();
  const { cart } = useCartData();

  const cartData =
    cart.id == "" && cartConfirmationData
      ? cartConfirmationData
      : cart.recoveryCart ?? cart;
  const sharedData = {
    items: !orderDetails ? cartData?.items : orderDetails.order?.items,
    receipt: !orderDetails ? cartData?.receipt : orderDetails?.order?.receipt,
    customer: !orderDetails
      ? cartData?.customer
      : orderDetails?.order?.customer,
    shippingAddress: !orderDetails
      ? cartData?.shippingDetails
      : orderDetails?.order?.shippingAddress,
    shippingRateCost: !orderDetails
      ? cartData?.shippingRate?.cost
      : orderDetails?.order?.receipt?.shipping,
    shippingRateName: !orderDetails
      ? cartData?.shippingRate?.details.name
      : orderDetails?.order?.shippingRateName,

    paymentMethod: !orderDetails
      ? cartData?.paymentIntent?.paymentMethod
      : orderDetails?.order?.paymentMethod,
    shippingDiscount: !orderDetails
      ? cartData?.shippingDiscount
      : orderDetails?.order?.discounts?.find(
          (discount) => discount?.appliedOn === DiscountAppliedOnType.Shipping
        ),
    promoCode: !orderDetails
      ? cartData?.promoCode
      : orderDetails?.order?.promoCodeSnapshot,
    automaticDiscount: !orderDetails
      ? cartData?.receipt?.automaticDiscount
      : orderDetails?.order?.receipt?.automaticDiscount,
    total: !orderDetails
      ? cartData?.receipt?.total
      : orderDetails?.order?.receipt?.total,
    subtotal: !orderDetails
      ? cartData?.receipt?.subtotal
      : orderDetails?.order?.receipt?.subtotal,
    shipping: !orderDetails
      ? cartData?.receipt?.shipping
      : orderDetails?.order?.receipt?.shipping,
    tax: !orderDetails
      ? cartData?.receipt?.tax
      : orderDetails?.order?.receipt?.tax,
    discounts: orderDetails !== undefined ? orderDetails?.order?.discounts : [],
  };

  const cartItems = sharedData.items;

  const areDiscountsApplied = !!sharedData.receipt?.automaticDiscount?.amount;

  const isCouponDiscountApplied =
    !!sharedData?.promoCode?.percentageOff ||
    !!sharedData?.promoCode?.fixedAmount;

  const handleShippingDiscount = () => {
    if (!orderDetails) {
      return cartData?.shippingDiscount
        ? cartData?.receipt?.subtotal?.amount >=
            cartData?.shippingDiscount?.customerBuys?.value?.amount!
        : false;
    } else {
      return !!sharedData?.discounts?.find(
        (discount) => discount?.appliedOn === DiscountAppliedOnType.Shipping
      );
    }
  };
  const isShippingDiscountApplies = handleShippingDiscount();

  const isFreeShippingApplied = sharedData?.receipt?.shipping?.amount === 0;

  const { route } = useRouter();
  const excludedPages =
    route?.includes("confirmation") || route?.includes("payment");
  const { locale } = useIntl();
  const intl = useIntl();
  const activeLocale = getLocaleInfo(locale).code;

  const cartContent = (
    <>
      <Template.elements.CartPopup />
      <Template.elements.CartEmptyPopup />
      <BorderBottom>
        <FlexRow justifyContent="space-between" style={{ width: "auto" }}>
          <FlexRow>
            <ShoppingBag />
            <StyledH3WithMargin>
              <FormattedMessage defaultMessage="Your Order" />
            </StyledH3WithMargin>
          </FlexRow>
          <Link href="/cart">
            <StyledButton>
              <FormattedMessage defaultMessage="View Cart" />
            </StyledButton>
          </Link>
        </FlexRow>
      </BorderBottom>
      {!excludedPages && (
        <BorderBottom>
          <HideOnMobile>
            <Template.elements.Coupon />
          </HideOnMobile>
        </BorderBottom>
      )}
      <BorderBottom>
        {cartItems?.map((item, index) => {
          const isItemSimple =
            item.__typename == "SimpleItem" ||
            item.__typename == "CartSimpleItem";
          const typedSimpleItem = item as CartSimpleItem;
          const typedCustomItem = item as CartCustomItem;

          const isCustomDiscountApplied =
            !isItemSimple &&
            (!!typedCustomItem?.discount?.info?.fixed?.amount ||
              !!typedCustomItem?.discount?.info?.percentage);

          const isAutomaticDiscountApplied =
            !!typedSimpleItem?.discounts?.length;

          const discountedSimpleItemsCount =
            (isAutomaticDiscountApplied &&
              typedSimpleItem?.discount?.quantity) ||
            0;

          const nonDiscountedSimpleItemsCount = isAutomaticDiscountApplied
            ? typedSimpleItem?.quantity - discountedSimpleItemsCount
            : 0;

          const isAllItemsDiscounted = isAutomaticDiscountApplied
            ? nonDiscountedSimpleItemsCount === 0
            : false;

          return (
            <MarginBottom key={index}>
              <FlexRow alignItems="start" spacing="m">
                <ImgDiv>
                  <ItemImg
                    imgDetails={
                      !orderDetails
                        ? typedSimpleItem?.variant?.image ||
                          typedCustomItem?.product?.images[0]
                        : item?.variantSnapshot?.image ||
                          item?.productSnapshot?.images[0]
                    }
                  />

                  <QuantitySpan>{item.quantity}</QuantitySpan>
                </ImgDiv>
                <FlexCol alignItems="flex-start">
                  {isCustomDiscountApplied && (
                    <Badge
                      type="white"
                      label={
                        <Flex alignItems="center" spacing="xs">
                          <CouponIcon />
                          {typedCustomItem?.discount?.info?.percentage ? (
                            <FormattedMessage
                              defaultMessage="SAVE {percent}%"
                              values={{
                                percent:
                                  typedCustomItem.discount?.info?.percentage,
                              }}
                            />
                          ) : (
                            <FormattedMessage
                              defaultMessage="SAVE {fixed}"
                              values={{
                                fixed: (
                                  <DefaultTextPrice
                                    money={
                                      typedCustomItem.discount?.info?.fixed
                                    }
                                  />
                                ),
                              }}
                            />
                          )}
                        </Flex>
                      }
                    />
                  )}

                  {isAutomaticDiscountApplied && (
                    <Flex flexWrap="wrap" spacing="s">
                      {typedSimpleItem?.discounts?.map((discount, idx) => (
                        <Badge
                          key={idx}
                          type="white"
                          label={
                            <Flex alignItems="center" spacing="xs">
                              <CouponIcon />
                              {getTranslatedDiscountTitle({
                                defaultTitle: discount?.data?.title,
                                currentLocale: activeLocale,
                                translations: discount?.data?.translations,
                              })}
                            </Flex>
                          }
                        />
                      ))}
                    </Flex>
                  )}
                  <H3>
                    {!orderDetails
                      ? item?.product.title
                      : item?.productSnapshot?.title}
                  </H3>
                  {!isItemSimple && (
                    <CartSummaryPanel
                      hideButtons
                      customItem={typedCustomItem}
                      customItemDiscount={typedCustomItem.discount?.info}
                      isCustomDiscountApplied={isCustomDiscountApplied}
                    />
                  )}
                  {!!typedSimpleItem?.variant?.sku && (
                    <P fontSize="12px" style={{ fontStyle: "italic" }}>
                      <FormattedMessage
                        defaultMessage="SKU: {sku}"
                        values={{ sku: typedSimpleItem?.variant?.sku }}
                      />
                    </P>
                  )}
                  <SelectedOptions>
                    {isAutomaticDiscountApplied && !isAllItemsDiscounted && (
                      <span>{discountedSimpleItemsCount}x </span>
                    )}
                    {!!typedSimpleItem?.variant?.selectedOptions.length && (
                      <span className="text-gray-500 text-xs ">
                        {(typedSimpleItem?.variant?.selectedOptions || [])
                          ?.map((option) => option.value.name)
                          .join(" / ")}
                      </span>
                    )}
                    {isAutomaticDiscountApplied && !isAllItemsDiscounted && (
                      <PriceWrapper
                        style={{ padding: "0 6px", display: "inline-block" }}
                      >
                        <DefaultTextPrice
                          money={subtractMoney(
                            typedSimpleItem.discount?.total!,
                            typedSimpleItem.price
                          )}
                        />
                      </PriceWrapper>
                    )}
                  </SelectedOptions>
                  {isAutomaticDiscountApplied && !isAllItemsDiscounted && (
                    <StyledNonDiscountedVariants isMobile>
                      <FlexRow>
                        <StyledNumber>
                          {nonDiscountedSimpleItemsCount}x
                        </StyledNumber>
                        <SelectedOptions style={{ padding: "0" }}>
                          {!!typedSimpleItem?.variant?.selectedOptions
                            .length && (
                            <span className="text-gray-500 text-xs ">
                              {(typedSimpleItem?.variant?.selectedOptions || [])
                                ?.map((option) => option.value.name)
                                .join(" / ")}
                            </span>
                          )}
                        </SelectedOptions>
                      </FlexRow>
                      <PriceWrapper style={{ padding: "0 6px" }}>
                        <DefaultTextPrice
                          money={
                            typedSimpleItem.discount
                              ? subtractMoney(
                                  typedSimpleItem.subtotal,
                                  typedSimpleItem.discount?.total!
                                )
                              : typedSimpleItem.subtotal
                          }
                        />
                      </PriceWrapper>
                    </StyledNonDiscountedVariants>
                  )}
                </FlexCol>
              </FlexRow>
              <PriceWrapper>
                <DefaultTextPrice
                  money={
                    item?.totalPrice ||
                    subtractMoney(item?.subtotal, item?.discount?.total || 0)
                  }
                />
                {(isCustomDiscountApplied ||
                  isAutomaticDiscountApplied ||
                  item.discount) && (
                  <DiscountPrice
                    style={{ fontWeight: 500 }}
                    money={item.subtotal}
                  />
                )}
              </PriceWrapper>
            </MarginBottom>
          );
        })}
      </BorderBottom>
      <BorderBottom>
        <Flex column>
          <FullWidth>
            <HeavyH4>
              <FormattedMessage defaultMessage="Subtotal" />
            </HeavyH4>
            <LightH4>
              <DefaultTextPrice money={sharedData?.receipt?.subtotal} />
            </LightH4>
          </FullWidth>
          {areDiscountsApplied ? (
            <Template.elements.DiscountsBreakdown
              orderItems={
                orderDetails && transformOrderItems(orderDetails?.order)
              }
            />
          ) : (
            <Template.elements.DiscountsBreakdown applyDefaultTemplateStyles />
          )}
          {isCouponDiscountApplied && (
            <FullWidth>
              <HeavyH4>
                <FormattedMessage defaultMessage="Coupon" />
              </HeavyH4>
              {sharedData?.promoCode?.percentageOff ? (
                <LightH4ForDiscount>
                  -<DefaultTextPrice money={sharedData?.receipt?.discount} /> (-
                  {sharedData?.promoCode?.percentageOff}%)
                </LightH4ForDiscount>
              ) : (
                <LightH4ForDiscount>
                  -<DefaultTextPrice money={sharedData?.receipt?.discount} />
                </LightH4ForDiscount>
              )}
            </FullWidth>
          )}
          {!!sharedData?.receipt?.tax?.amount && (
            <FullWidth>
              <FlexRow spacing="xs">
                <HeavyH4>
                  <FormattedMessage defaultMessage="Estimated Taxes" />
                </HeavyH4>
                <Tooltip
                  icon={<HelpIcon />}
                  tooltipMessage={intl.formatMessage({
                    defaultMessage:
                      "The final tax and total will be confirmed by email or text after you place your order.",
                  })}
                />
              </FlexRow>
              <LightH4>
                + <DefaultTextPrice money={sharedData?.receipt?.tax} />
              </LightH4>
            </FullWidth>
          )}
          {/* Shipping rate details */}
          <FullWidth>
            <HeavyH4>
              <FormattedMessage defaultMessage="Shipping" />
            </HeavyH4>
            {sharedData?.shippingRateCost ? (
              sharedData?.shippingRateCost?.amount ? (
                <StyledShippingCost
                  isShippingDiscount={isShippingDiscountApplies}
                >
                  {sharedData?.shippingRateName} (
                  <DefaultTextPrice money={sharedData?.shippingRateCost} />)
                </StyledShippingCost>
              ) : (
                <LightH4ForDiscount>
                  <FormattedMessage defaultMessage="Free Shipping" />
                </LightH4ForDiscount>
              )
            ) : (
              <LightH4>
                <FormattedMessage defaultMessage="To be calculated" />
              </LightH4>
            )}
          </FullWidth>
          {/* Shipping and shipping discount details */}
          {isShippingDiscountApplies &&
            !!sharedData?.shippingRateCost?.amount && (
              <StyledShippingDiscount>
                <LightH4ForDiscount>
                  {isFreeShippingApplied ? (
                    <FormattedMessage defaultMessage="Free Shipping" />
                  ) : (
                    <LightH4ForDiscount>
                      +
                      <DefaultTextPrice money={sharedData?.receipt?.shipping} />
                      (-{cartData?.shippingDiscount?.percentage}%)
                    </LightH4ForDiscount>
                  )}
                </LightH4ForDiscount>
                <StyledShippingDiscountBadge>
                  <ShoppingCartPromotionIcon />
                  {cartData?.shippingDiscount?.title}
                </StyledShippingDiscountBadge>
              </StyledShippingDiscount>
            )}
        </Flex>
      </BorderBottom>
      <BorderBottom>
        <FullWidth style={{ alignItems: "flex-start" }}>
          <HeavyH4>
            <FormattedMessage defaultMessage="TOTAL" />
          </HeavyH4>
          <FlexCol alignItems="flex-end">
            <StyledH2>
              <Price money={sharedData?.receipt?.total} />
            </StyledH2>
          </FlexCol>
        </FullWidth>
      </BorderBottom>
    </>
  );

  return (
    <Container className={className} alignItems="flex-start">
      {!isMobile ? cartContent : isOpen ? cartContent : null}
      {isMobile && !excludedPages && <Template.elements.Coupon />}
    </Container>
  );
};

export const LargeScreensCheckoutCart = styled(CheckoutCart)`
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;
