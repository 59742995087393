import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { PrimaryButton } from "../../../../../components/Button";
import AddressModal from "./AddressModal";
import { AddIcon } from "../../../../../assets/Icons";
import {
  useAddCustomerAddressMutation,
  ListCustomerAddressesDocument,
} from "../../../../../generated/graphql";
import { useAuth } from "../../../../../lib/Authentication";
import type { FormValues } from "./types";
import { useStore } from "../../../../../lib/storeData";

interface AddAddressProps {
  fullWidth?: boolean;
  buttonOutlined?: boolean;
}

export const AddAddress: React.FC<AddAddressProps> = ({
  fullWidth,
  buttonOutlined,
}) => {
  const [active, setActive] = React.useState(false);
  const { user: loggedInUser } = useAuth();
  const { storeId } = useStore();

  const [addCustomerAddress] = useAddCustomerAddressMutation({
    refetchQueries: [
      {
        query: ListCustomerAddressesDocument,
        variables: { customerId: loggedInUser?.id, storeId },
      },
    ],
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      await addCustomerAddress({
        variables: {
          customerId: loggedInUser?.id!,
          input: {
            addressLine1: values?.address,
            addressLine2: values?.apartment,
            secondPhone: values?.secondPhone?.value,
            countryId: values?.shippingDestination?.country?.value,
            stateId: values?.shippingDestination?.state?.value,
            cityId: values?.shippingDestination?.city?.value,
            regionId: values?.shippingDestination?.region?.value,
            postalCode: values?.postalCode,
          },
        },
      });

      setActive(false);
    } catch (e) {}
  };

  return (
    <>
      <AddAddressButton
        prefixIcon={buttonOutlined && <AddIcon />}
        onClick={() => setActive(true)}
        fullWidth={fullWidth}
        outlined={buttonOutlined}
      >
        <FormattedMessage defaultMessage="Add new address" />
      </AddAddressButton>
      <AddressModal
        mode="ADD"
        isOpen={active}
        onClose={() => setActive(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

const AddAddressButton = styled(PrimaryButton)<{
  fullWidth?: boolean;
  outlined?: boolean;
}>`
  padding: ${(props) => (props.outlined ? "0" : "10px 0")};
  width: ${(props) => (props.outlined ? "auto" : "80%")};
  border: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.2px;
  color: black;

  svg {
    width: 20px;
    height: 20px;
  }

  :hover {
    box-shadow: none;
  }
`;

export default AddAddress;
