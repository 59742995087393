import styled from "styled-components";
import { PrimaryButton } from "../../../../../components/Button";
import { rtl } from "../../../../../shared/styles-utils";
import { FlexRow } from "../../../../../shared/globals";

export const RequiredSpan = styled.span`
  font-size: 12px;
  color: #d21c1c;
  font-weight: 400;
  height: 0;
  margin-top: 4px;
`;

export const ButtonsContainer = styled(FlexRow)`
  gap: 12px;
  justify-content: flex-end;
  align-items: stretch;
  margin-top: 20px;
`;

export const SaveButton = styled(PrimaryButton)`
  margin-bottom: 40px;
  padding: 8px 12px;
  width: 100%;
  letter-spacing: -0.084px;
  @media (min-width: 768px) {
    margin-bottom: 0;
    width: auto;
    flex: 0 0 auto;
  }
  div {
    ${rtl("padding-right", "padding-left")}: 8px;
    display: flex;
    transform: scaleX(${rtl("-1", "1")});
  }
`;

export const CancelButton = styled.button`
  padding: 10px 20px;
  border: 1px solid #dde2e4;
  background-color: white;
  border-radius: 6px;
  cursor: pointer;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;
