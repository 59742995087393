import type { ModifiedTemplate } from "../default";
import InfoBar from "./elements/InfoBar";
import DesktopMenu from "./elements/DesktopMenu";
import ProductCard from "./elements/ProductCard";
import Hero from "./elements/Hero";
import ProductDetails from "./elements/ProductDetails";
import SectionCardWrapper from "./elements/SectionCardWrapper";
import SectionHeading from "./elements/SectionHeading";
import GridGallerySection from "./elements/GridGallerySection";
import ProductsList from "./elements/ProductsList";
import LoginForm from "./elements/Authentication/LoginForm";
import RegistrationForm from "./elements/Authentication/RegistrationForm";
import ResetPasswordForm from "./elements/Authentication/ResetPasswordForm";
import ForgotPasswordForm from "./elements/Authentication/ForgotPasswordForm";
import CollectionCard from "./elements/CollectionCard";
import BannerCard from "./elements/BannerCard";
import NavBar from "./elements/NavBar";
import AttributeValueCard from "./elements/AttributeValueCard";
import ProductGallery from "./elements/ProductGallery";
import SimpleProduct from "./elements/SimpleProduct";
import RatingAndReviews from "./elements/RatingAndReviews";
import FilterAndSort from "./elements/FilterAndSort";
import CartTable from "./elements/CartTable";
import CartItem from "./elements/CartItem";
import SideCart from "./elements/SideCart";
import Coupon from "./elements/Coupon";
import ContactElement from "./elements/Contact";
import ProfileLayout from "./elements/CustomerProfile/ProfileLayout";
import UserAccountElement from "./elements/CustomerProfile/UserAccount";
import UserAddressesElement from "./elements/CustomerProfile/UserAddresses";
import UserOrdersElement from "./elements/CustomerProfile/OrderHistory";
import UserOrderElement from "./elements/CustomerProfile/OrderDetails";
import UserReviewsElement from "./elements/CustomerProfile/UserReviews";
import Cart from "./pages/Cart";

const arabHWTemplate: ModifiedTemplate = {
  pages: {
    Cart,
  },
  elements: {
    // SHARED
    InfoBar,
    DesktopMenu,
    ProductCard,
    Hero,
    ProductsList,
    RatingAndReviews,
    Coupon,
    // HOME
    SectionHeading,
    SectionCardWrapper,
    GridGallerySection,
    CollectionCard,
    AttributeValueCard,
    BannerCard,
    // Navbar
    NavBar,
    // PRODUCT
    SimpleProduct,
    ProductGallery,
    ProductDetails,
    // AUTHENTICATION
    LoginForm,
    RegistrationForm,
    ResetPasswordForm,
    ForgotPasswordForm,
    FilterAndSort,
    // Cart
    CartTable,
    CartItem,
    SideCart,
    // Contact
    ContactElement,
    // CUSTOMER PROFILE
    ProfileLayout,
    UserAccountElement,
    UserAddressesElement,
    UserOrderElement,
    UserOrdersElement,
    UserReviewsElement,
  },
};

export default arabHWTemplate;
