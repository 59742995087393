import React from "react";
import { Section } from "../../../shared/layout";
import { StoreTemplate } from "../../TemplateLoader";

const UserReviewsPage = () => {
  const Template = StoreTemplate.get();

  return (
    <Section background={"backgroundReverse"}>
      <Template.elements.ProfileLayout>
        <Template.elements.UserReviewsElement />
      </Template.elements.ProfileLayout>
    </Section>
  );
};

export default UserReviewsPage;
