import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { H2, P } from "../../shared/globals";
import { Link } from "../../lib/i18n";
import { TemplateElement } from "../../templates/types";
import { StoreTemplate } from "../../templates/TemplateLoader";
import Flex from "../../shared/globals/UiElements/Flex";
import { AlsoLikeProps } from "./AlsoLikeProps";
import { useStore } from "../../lib/storeData";

const AlsoLike: TemplateElement<AlsoLikeProps> = ({
  products,
  isRandom,
  numberOfProducts,
  showSeeMore,
}) => {
  const Template = StoreTemplate.get();
  const { appearance } = useStore();
  return (
    <Flex column spacing="none">
      <Flex
        fullWidth
        justifyContent="space-between"
        className="border-b border-b-gray-200 pb-sm"
      >
        <H2 fontWeight="600">
          {isRandom ? (
            <FormattedMessage defaultMessage="You May Also Like" />
          ) : (
            <FormattedMessage defaultMessage="Similar products" />
          )}
        </H2>
        {showSeeMore && (
          <SeeProducts>
            <Link href="/shop">
              <span className="text-gray-600">
                <FormattedMessage defaultMessage="See all products" />
              </span>
            </Link>
          </SeeProducts>
        )}
      </Flex>
      <Template.elements.ProductsList
        products={products?.slice(0, numberOfProducts)}
        showTwoProductsPerRowOn={
          appearance?.template?.handle === "black-and-white"
            ? "TABLET"
            : undefined
        }
      />
    </Flex>
  );
};

export default AlsoLike;

/**
 *
 * Styles
 *
 */

const SeeProducts = styled(P)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  a {
    text-decoration: none;
  }
`;
