import { FC } from "react";
import type { CartTableProps } from "../templates/types";
import type { RendererComponent } from "./types";
import { StoreTemplate } from "../templates/TemplateLoader";
import { useCartData } from "../lib/cartData/useCartData";

interface CartTableRendererProps extends RendererComponent<CartTableProps> {}

const CartTableRenderer: FC<CartTableRendererProps> = ({ Component }) => {
  const Template = StoreTemplate.get();
  const { cart } = useCartData();

  const cartData = cart.recoveryCart ?? cart;

  const { items, cartErrors } = cartData;

  const renderCartItems = () =>
    items?.map((item) => (
      <Template.elements.CartItem
        key={item.id}
        item={item}
        errors={cartErrors}
      />
    ));

  return <Component renderCartItems={renderCartItems} />;
};

export default CartTableRenderer;
