import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import { Input } from "../../Input";

export const FilterByPrice = () => {
  const router = useRouter();
  const [minValue, setMinValue] = React.useState(
    (router.query.minPrice as string) || ""
  );
  const [maxValue, setMaxValue] = React.useState(
    (router.query.maxPrice as string) || ""
  );
  const intl = useIntl();
  useEffect(() => {
    setMinValue((router.query.minPrice as string) || "");
    setMaxValue((router.query.maxPrice as string) || "");
  }, [router.query.minPrice, router.query.maxPrice]);

  return (
    <Flex column className="mb-xl">
      <Flex alignItems="center" className="mx-2xl">
        <Flex>
          <Input
            value={minValue}
            type="number"
            onChange={(e) => setMinValue(e.target.value)}
            min="0"
            placeholder={intl.formatMessage({ defaultMessage: "Min" })}
            onBlur={() => {
              if (
                (!router.query.minPrice && minValue === "") ||
                parseFloat(minValue) >= parseFloat(maxValue)
              )
                return;
              router.push(
                {
                  pathname: router.asPath?.split("?")[0],
                  query: {
                    ...router.query,
                    page: 1,
                    minPrice: minValue,
                  },
                },
                undefined,
                { scroll: false }
              );
            }}
            width="40%"
            noMarginTop
          />
        </Flex>
        <div className="bg-gray-500 w-sm h-[1px] shrink-0" />
        <Flex>
          <Input
            value={maxValue}
            type="number"
            onChange={(e) => setMaxValue(e.target.value)}
            placeholder={intl.formatMessage({ defaultMessage: "Max" })}
            width="40%"
            noMarginTop
            onBlur={() => {
              if (
                (!router.query.maxPrice && maxValue === "") ||
                parseFloat(minValue) >= parseFloat(maxValue)
              )
                return;
              router.push(
                {
                  pathname: router.asPath?.split("?")[0],
                  query: {
                    ...router.query,
                    page: 1,
                    maxPrice: maxValue,
                  },
                },
                undefined,
                { scroll: false }
              );
            }}
          />
        </Flex>
      </Flex>
      {parseFloat(minValue) >= parseFloat(maxValue) && (
        <StyledErrorMessage>
          <FormattedMessage defaultMessage="Please make sure that min price is less than the max price" />
        </StyledErrorMessage>
      )}
    </Flex>
  );
};

const StyledErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;
