import React from "react";
import { Section } from "../../../shared/layout";
import type { UserAddressesPageProps } from "../../types";
import { StoreTemplate } from "../../TemplateLoader";

const UserAddressesPage: React.FC<UserAddressesPageProps> = () => {
  const Template = StoreTemplate.get();

  return (
    <Section background={"backgroundReverse"}>
      <Template.elements.ProfileLayout>
        <Template.elements.UserAddressesElement />
      </Template.elements.ProfileLayout>
    </Section>
  );
};

export default UserAddressesPage;
