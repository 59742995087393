import { CSSProperties } from "react";
import styled from "styled-components";
import { SlotStyles } from "yet-another-react-lightbox";

export const lightBoxStyles: SlotStyles = {
  root: {
    "--yarl__color_backdrop": "transparent",
  },
  container: {
    backgroundColor: "RGB(0,0,0,0.5)",
  },
  thumbnail: {
    backgroundColor: "transparent",
  },
  thumbnailsContainer: {
    backgroundColor: "RGB(0,0,0,0.5)",
  },
};

export const HoverToZoomContainer = styled.div<{
  isZoomEnabled: boolean;
}>`
  width: 100%;
  height: 100%;
  cursor: ${({ isZoomEnabled }) => (isZoomEnabled ? "zoom-in" : "auto")};
`;

export const HoverToZoom = styled.div<{
  src: string;
  isHovered: boolean;
  x: number | null | undefined;
  y: number | null | undefined;
  zoomScale: number;
  objectFit?: CSSProperties["objectFit"];
}>`
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-size: ${({ objectFit }) => objectFit ?? "contain"};
  background-repeat: no-repeat;
  background-position: center;
  transform-origin: ${({ x, y }) => `${x ?? 0}% ${y ?? 0}%`};
  transition: transform 0.2s ease-out;
  transform: scale(
    ${({ isHovered, zoomScale }) => (isHovered ? zoomScale : 1)}
  );
`;

export const HoverToZoomBadge = styled.div<{
  badgeBottomPosition: CSSProperties["bottom"];
}>`
  position: absolute;
  bottom: ${({ badgeBottomPosition }) => badgeBottomPosition};
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 5px;
  background: #fff;
  padding: 5px 10px;
  border-radius: 20px;
  color: #444;
  border: 1px solid #444;
  font-size: 14px;
  align-items: center;
  font-weight: 600;
  svg {
    width: 15px;
  }
  white-space: nowrap;
`;
