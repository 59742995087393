import React from "react";
import { BreadcrumbHeader } from "../../../components/Breadcrumb";
import type { CustomProductInfoProps } from "../../types";
import { ProductType } from "../../../generated/graphql";
import { Section } from "../../../shared/layout";
import { ProductPageProps } from "../../types";
import { StoreTemplate } from "../../TemplateLoader";
import Flex from "../../../shared/globals/UiElements/Flex";
import { ArrowRight } from "../../../assets/Icons";
import { useRouter } from "next/router";
import { rtl } from "../../../shared/styles-utils";
import styled from "styled-components";
import { theme } from "../../../../tailwind.config";

const ProductPage: React.FC<ProductPageProps> = ({
  breadcrumbItems,
  product,
}) => {
  const Template = StoreTemplate.get();
  const router = useRouter();

  return (
    <>
      <Section background="backgroundReverse">
        <Flex alignItems="center" spacing="s">
          <BackIcon onClick={() => router.back()}>
            <ArrowRight />
          </BackIcon>
          <BreadcrumbHeader breadcrumbs={breadcrumbItems} />
        </Flex>
      </Section>
      <Section background="backgroundReverse">
        {product?.type === ProductType.Simple ? (
          <Template.elements.SimpleProduct product={product} />
        ) : (
          <Template.elements.CustomProduct
            product={
              {
                ...product,
              } as CustomProductInfoProps["product"]
            }
          />
        )}
      </Section>
    </>
  );
};

export default ProductPage;

const BackIcon = styled.div`
  cursor: pointer;
  padding: ${theme.extend.spacing.xs};
  transform: ${rtl("rotate(0deg)", "rotate(180deg)")};
`;
