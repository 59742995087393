import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Information } from "../../../../../assets/Icons";
import {
  AddressInfoFragment,
  CheckoutFieldOptions,
  CustomerIdentifier,
} from "../../../../../generated/graphql";
import { useStore } from "../../../../../lib/storeData";
import { FlexRow, H5 } from "../../../../../shared/globals";
import { Card } from "../../../../../shared/globals/UiElements/Card";
import DeleteAddress from "./DeleteAddress";
import { EditAddress } from "./EditAddress";
import Flex from "../../../../../shared/globals/UiElements/Flex";

interface AddressesPanelProps {
  address: AddressInfoFragment;
  userName: string;
  isDefault?: boolean;
  SetDefaultButton?: React.ReactNode;
  DefaultBadge?: React.ReactNode;
  CheckButton?: React.ReactNode;
  onSelectAddress?: React.MouseEventHandler<HTMLDivElement>;
  isValid: boolean;
}
const AddressesPanel: React.FC<AddressesPanelProps> = ({
  address,
  userName,
  isDefault,
  SetDefaultButton,
  DefaultBadge,
  CheckButton,
  onSelectAddress,
  isValid,
}) => {
  const { customCheckoutSetting } = useStore();
  return (
    <>
      {!isValid && <InvalidAddressFlag />}
      <Card
        boxShadow="sm"
        paddingSize="md"
        className={`border !rounded-xl ${
          !isValid ? "border-danger-alt" : "border-gray-200"
        } `}
      >
        <Flex
          fullWidth
          justifyContent="space-between"
          alignItems="start"
          column
        >
          {CheckButton ? CheckButton : null}
          <AddressDetails
            onClick={onSelectAddress}
            isClickable={!!onSelectAddress}
            className="w-full gap-3xs"
          >
            <Flex justifyContent="space-between" fullWidth alignItems="center">
              <Flex alignItems="center">
                <H5 fontSize="16px" fontWeight="500" className="!text-gray-700">
                  {userName}
                </H5>
                {isDefault && DefaultBadge ? DefaultBadge : null}
              </Flex>
              <Flex spacing="s" className="max-md:!hidden">
                <DeleteAddress addressId={address?.id} />
                <EditAddress addressDetails={address} />
              </Flex>
            </Flex>
            <Flex
              className="text-gray-500 text-base"
              flexWrap="wrap"
              spacing="none"
            >
              <span>{address?.country?.name},</span>
              <span>{address?.state?.name},</span>
              {address?.city && <span>{address?.city?.name},</span>}
              <span>{address?.addressLine1},</span>
              <span>{address?.addressLine2},</span>
              {address?.postalCode &&
              customCheckoutSetting?.postalCode !==
                CheckoutFieldOptions.Inactive ? (
                <span>{address?.postalCode}</span>
              ) : null}
            </Flex>
            <Flex
              spacing="xs"
              className="text-gray-500 text-base"
              alignItems="center"
            >
              {customCheckoutSetting?.identifier !== CustomerIdentifier.Email}
              {customCheckoutSetting?.identifier !== CustomerIdentifier.Email &&
                address?.secondPhone}
            </Flex>
            <Flex
              justifyContent={
                !isDefault && SetDefaultButton ? "space-between" : "flex-end"
              }
              className={"max-md:!mt-sm"}
            >
              {!isDefault && SetDefaultButton ? SetDefaultButton : null}
              <Flex spacing="s" className="max-md:!flex !hidden">
                <DeleteAddress addressId={address?.id} />
                <EditAddress addressDetails={address} />
              </Flex>
            </Flex>
          </AddressDetails>
        </Flex>
      </Card>
    </>
  );
};

const InvalidAddressFlag = () => {
  return (
    <InvalidAddressFlagContainer>
      <FlexRow justifyContent="start" alignItems="center" spacing="s">
        <Information />
        <FlexRow spacing="xs">
          <StyledErrorText isBolded>
            <FormattedMessage defaultMessage="Missing shipping address information," />
          </StyledErrorText>
          <StyledErrorText>
            <FormattedMessage defaultMessage="Please edit the address" />
          </StyledErrorText>
        </FlexRow>
      </FlexRow>
    </InvalidAddressFlagContainer>
  );
};

const AddressDetails = styled.div<{ isClickable }>`
  display: flex;
  flex-direction: column;

  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "auto")};

  span {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 1;
  }
`;

const InvalidAddressFlagContainer = styled.div`
  background-color: ${({ theme }) => theme.warn.wash};
  color: ${({ theme }) => theme.danger.default};
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
  border: solid 1px ${({ theme }) => theme.danger.alt};
  border-bottom: none;
`;

const StyledErrorText = styled.span<{ isBolded?: boolean }>`
  font-weight: ${({ isBolded }) => (isBolded ? "600" : "normal")};
  font-size: 14px;
`;
export default AddressesPanel;
