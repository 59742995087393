export const PercentDashedIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3117 2.62083C10.4447 2.45972 9.55538 2.45972 8.68835 2.62083"
        stroke="#42AB44"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0984 7.4775C17.4026 8.30488 17.5592 9.17928 17.5609 10.0608"
        stroke="#42AB44"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.79 5.2025C15.222 4.52852 14.541 3.95861 13.7775 3.51833"
        stroke="#42AB44"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.22246 3.51833C5.45908 3.95883 4.77811 4.52871 4.20996 5.2025"
        stroke="#42AB44"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.4392 10.0608C2.44074 9.17927 2.59729 8.30484 2.9017 7.4775"
        stroke="#42AB44"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.22253 16.6033C6.98537 17.0449 7.8201 17.3484 8.68837 17.5"
        stroke="#42AB44"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.90161 12.6442C3.20175 13.473 3.64453 14.2429 4.20994 14.9192"
        stroke="#42AB44"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3116 17.5C12.1799 17.3483 13.0146 17.0447 13.7775 16.6033"
        stroke="#42AB44"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.79 14.9192C16.3554 14.2429 16.7982 13.473 17.0984 12.6442"
        stroke="#42AB44"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.15234 11.8467L11.8458 8.15328"
        stroke="#42AB44"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0834 12.0003C12.0832 12.0463 12.0459 12.0834 11.9999 12.0833C11.9539 12.0832 11.9167 12.046 11.9167 12C11.9167 11.954 11.9539 11.9168 11.9999 11.9167C12.0459 11.9166 12.0832 11.9537 12.0834 11.9997V12.0003"
        stroke="#42AB44"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.91671 7.99969C7.91688 7.95373 7.95423 7.91658 8.00019 7.91667C8.04616 7.91675 8.08337 7.95404 8.08337 8C8.08337 8.04596 8.04616 8.08325 8.00019 8.08333C7.95423 8.08342 7.91688 8.04627 7.91671 8.00031V7.99969"
        stroke="#42AB44"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
