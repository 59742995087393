import { ContactFormValues } from ".";
import { AnalyticsActions } from "../../../../lib/analytics-accounts/utils";

export function fireSubmitContactFormEvent(
  contactFormValues: ContactFormValues
) {
  AnalyticsActions.fireEvent({
    name: "Submit_Contact_Form",
    parameters: {
      event_category: "Form",
      content_type: "Contact_Form",
      event_label: "Message",
      ...contactFormValues,
      phone: contactFormValues?.phone?.value,
    },
  });
}
