export const SortIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 12.5L13.7493 14.9993L16.2487 12.5"
        stroke="#1F1F1F"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.7489 5.00391V15.0013"
        stroke="#1F1F1F"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.75065 7.50325L6.2513 5.00391L3.75195 7.50325"
        stroke="#1F1F1F"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.25081 15.0013V5.00391"
        stroke="#1F1F1F"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
