import { FC } from "react";
import useBreakPoints from "../../../shared/utils/useBreakPoints";
import { FilterAndSortProps } from "../../types";
import DesktopFilterSort from "../components/Filter/DesktopFilterSort";
import MobileFilterSort from "../components/Filter/MobileFilterSort";

const FilterAndSort: FC<FilterAndSortProps> = ({
  products,
  itemPerPage,
  productsTotalCount,
  offset,
}) => {
  const { isTablet } = useBreakPoints();

  return isTablet ? (
    <MobileFilterSort />
  ) : (
    <DesktopFilterSort
      products={products}
      itemPerPage={itemPerPage}
      productsTotalCount={productsTotalCount}
      offset={offset}
    />
  );
};

export default FilterAndSort;
