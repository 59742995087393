import styled from "styled-components";
import { FlexCol, FlexRow, H5, H2, H4, H3 } from "../../shared/globals";
import { themeColor, rtl } from "../../shared/styles-utils";

const StepsHolder = styled.div`
  display: flex;
  padding: 10px 0;
`;

const StepBar = styled.div<{ done: boolean }>`
  position: absolute;
  top: 0;
  ${rtl("right", "left")}: 50%;
  transform: translate(0%, -50%);
  width: 100%;
  height: 2px;
  background-color: ${({ done }) => (done ? themeColor("primary") : "#d8d8d8")};
`;

const StepMarker = styled(FlexCol)<{ done: boolean; active: boolean }>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 700;
  background-color: ${({ active, done }) =>
    done ? themeColor("primary") : active ? themeColor("white") : "#989898"};
  color: ${({ active }) => (active ? themeColor("primary") : "#fff")};
  border: solid 1px
    ${({ done, active }) =>
      done || active ? themeColor("primary") : "#989898"};
`;

const StepLabel = styled(H5)<{ active: boolean }>`
  margin-top: 16px;
  color: ${themeColor("primary")};
  color: ${({ active }) => (active ? themeColor("primary") : "#989898")};
`;

const StyledStepItem = styled(FlexCol)`
  position: relative;
  width: 130px;
  height: 100%;
  margin-top: 10px;

  :last-of-type ${StepBar} {
    display: none;
  }
`;

const Stepper = styled(FlexRow)`
  display: flex;
  background-color: ${({ theme }) => theme.bg.wash}1a;
  height: 70px;
  width: 100%;
  padding: 10px 20px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const StyledH2 = styled(H2)`
  color: ${({ theme }) => theme.text.default};
`;
const StyledH4 = styled(H4)`
  color: ${({ theme }) => theme.text.default}80;
`;
const StyledH3 = styled(H3)`
  margin: 0 10px 0 8px;
  &:nth-child(1) {
    margin: 0;
    color: ${themeColor("primary")};
  }
`;

export {
  StepBar,
  StepLabel,
  StepMarker,
  StepsHolder,
  StyledStepItem,
  Stepper,
  StyledH2,
  StyledH4,
  StyledH3,
};
