import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import {
  BurgerIcon,
  CustomerIcon,
  Home,
  MobileShop,
  ShoppingBag,
} from "../../../assets/Icons";
import { Link, useRouter } from "../../../lib/i18n";
import { themeColor } from "../../../shared/styles-utils";
import { useStore } from "../../../lib/storeData";
import Flex from "../../../shared/globals/UiElements/Flex";
import { useAuth } from "../../../lib/Authentication";
import { SocialLinkType } from "../../../generated/graphql";
import { Breakpoints } from "../../../shared/globals/types";
import { useCartData } from "../../../lib/cartData/useCartData";
import { MenuWithChildrenType } from "../components/NavBar/menu/types";
import { ReactElement, useState } from "react";
import { StoreTemplate } from "../../TemplateLoader";
import { useEscapeAndStopScrollingEffect } from "../../../hooks/useEscapeAndStopScrollingEffect";
import useRouteChange from "../../../hooks/useRouteChange";

const FixedNav = ({
  socialSettings,
  menus,
}: {
  socialSettings: (SocialLinkType | null)[];
  menus: MenuWithChildrenType;
}) => {
  const { setSideCartState } = useCartData();
  const { appearance } = useStore();
  const { pathname } = useRouter();
  const { isLoggedIn } = useAuth();

  const accountPath = isLoggedIn ? "/me/my-account" : "/login";
  const storePrimaryColor = appearance?.colors?.primary!;

  return (
    <MobileFixedNav justifyContent="space-between" alignItems="center">
      <Link href="/">
        <NavItem>
          <Home color={pathname === "/" ? storePrimaryColor : undefined} />
          <FormattedMessage defaultMessage="Home" />
        </NavItem>
      </Link>
      <Link href="/shop">
        <NavItem column>
          <MobileShop
            color={pathname === "/shop" ? storePrimaryColor : undefined}
          />
          <FormattedMessage defaultMessage="Shop" />
        </NavItem>
      </Link>
      <div onClick={() => setSideCartState(true)}>
        <NavItem>
          <ShoppingBag
            color={pathname === "/cart" ? storePrimaryColor : undefined}
          />
          <FormattedMessage defaultMessage="Cart" />
        </NavItem>
      </div>
      <Link
        href={accountPath}
        disabled={pathname === accountPath || pathname === "/registration"}
      >
        <NavItem column>
          <CustomerIcon
            color={pathname === accountPath ? storePrimaryColor : undefined}
          />
          <FormattedMessage defaultMessage="Account" />
        </NavItem>
      </Link>
      <NavItem>
        <BurgerMenu menus={menus} socialSettings={socialSettings} />
        <FormattedMessage defaultMessage="Menu" />
      </NavItem>
    </MobileFixedNav>
  );
};
export default FixedNav;

const BurgerMenu = ({
  socialSettings,
  menus,
  icon = <BurgerIcon />,
}: {
  socialSettings: (SocialLinkType | null)[];
  menus: MenuWithChildrenType;
  icon?: ReactElement;
}) => {
  const Template = StoreTemplate.get();
  const [isOpen, setIsOpen] = useState(false);
  useRouteChange(() => setIsOpen(false));
  useEscapeAndStopScrollingEffect({ isOpen, setIsOpen });
  return (
    <>
      <span onClick={() => setIsOpen(true)}>{icon}</span>
      <Template.elements.MobileNavigation
        menus={menus}
        socialSettings={socialSettings}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

const MobileFixedNav = styled(Flex)`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 7px 20px;
  box-shadow: 0 -1px 5px 0 rgba(190, 190, 190, 0.3);
  background-color: ${themeColor("white")};
  @media (min-width: ${Breakpoints.SMALLSCREEN}) {
    display: none;
  }
`;

const NavItem = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  color: ${themeColor("secondary")};
  font-size: 12px;
  font-weight: 500;
  gap: 4px;
  svg {
    width: 24px;
    height: 24px;
  }
`;
