import styled, { css } from "styled-components";
import { FlexCol, FlexRow, H3 } from "../../../shared/globals";
import { themeColor } from "../../../shared/styles-utils";

export const Container = styled(FlexCol)`
  width: 100%;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const Summary = styled(FlexRow)`
  padding: 15px 20px;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;

export const StyledH3 = styled(H3)`
  margin: 0 10px 0 8px;
  &:nth-child(1) {
    margin: 0;
  }
`;

export const RotateArrowSpan = styled.span<{ isOpen?: boolean }>`
  height: 24px;
  color: ${themeColor("primary")};
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: scaleY(-1);
    `}
`;

export const Total = styled(StyledH3)`
  margin: 0;
  color: ${themeColor("primary")};
`;
