import createDecorator from "final-form-focus";
import React, { useMemo, useRef, useState } from "react";
import { Form, Field } from "react-final-form";
import { FormattedMessage } from "react-intl";
import { LeftArrow, RightArrow } from "../../../assets/Icons";
import { H2, Label, FlexRow, TextArea, Input } from "../../../shared/globals";
import Asterisk from "../../../shared/globals/UiElements/Asterisk";
import { Link } from "../../../lib/i18n";
import InputPhone, {
  PhoneInput,
} from "../../../shared/globals/UiElements/InputPhone";
import {
  StyledForm,
  ContactSection,
  PhoneField,
  RequiredSpan,
  CheckboxLabel,
  CheckboxInput,
  Checkmark,
  ShippingSection,
  RouteSection,
  GoBackButton,
  ContinueButton,
} from "./styled";
import ShippingDestinationSelect, {
  ShippingDestination,
} from "../../UtilityComponents/ShippingDestinationSelect";
import {
  CartStepEnum,
  CheckoutFieldOptions,
  CustomerIdentifier,
  useShippingDestinationsQuery,
  useShippingRatesLazyQuery,
} from "../../../generated/graphql";
import { useStore } from "../../../lib/storeData";
import { validate } from "./validate";
import { useCartData } from "../../../lib/cartData/useCartData";
import Flex from "../../../shared/globals/UiElements/Flex";
import { useRouter } from "../../../lib/i18n";
import {
  fireBeginCheckoutEvent,
  fireCheckoutInformationEvent,
  getShippingDestinations,
  isShippingDestinationAvailable,
} from "./utils";
import { isEqual } from "lodash";
import { CheckoutInformation } from "../../../contexts/CartContext/types";

const focusOnError = createDecorator();
const GuestInformation = () => {
  const { storeId, customCheckoutSetting } = useStore();
  const [checkSubmitting, setCheckSubmitting] = useState(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const [queryShippingRates] = useShippingRatesLazyQuery({ ssr: false });
  const {
    updateContactInfo: { updateContactInfo },
    setCartShippingDetails: {
      setCartShippingDetails,
      loading: CartShippingDetailsLoading,
    },
    setShippingInformation,
    cart,
  } = useCartData();

  const router = useRouter();
  const {
    query: { cartId },
  } = useRouter();

  const queryCartId = cartId as string;

  const cartIdPrefix = queryCartId.split("_")[0];

  const cartData = cart.recoveryCart ?? cart;

  const [currentNameValue, setCurrentNameValue] = useState(
    cartData?.contactInfo?.name || ""
  );
  const [currentEmailValue, setCurrentEmailValue] = useState(
    cartData?.contactInfo?.email || ""
  );
  const [currentPhoneValue, setCurrentPhoneValue] = useState({
    value: cartData?.contactInfo?.phone || "",
    isValid: true,
  });

  const handleShippingRates = async (input: ShippingDestination) => {
    if (input?.isValid) {
      await queryShippingRates({
        variables: {
          storeId,
          input: {
            countryId: input?.country?.value,
            stateId: input?.state?.value,
            cityId: input?.city?.value,
            regionId: input?.region?.value,
          },
        },
      });
    }
  };

  const { data } = useShippingDestinationsQuery({
    variables: { storeId },
  });

  const shippingDestinationsMap = useMemo(
    () => getShippingDestinations(data),
    [data]
  );

  const shippingDestinationAvailable = isShippingDestinationAvailable(
    shippingDestinationsMap,
    cartData?.shippingDetails?.area
  );

  const updateValidation = async (values, errors) => {
    if (customCheckoutSetting?.identifier === CustomerIdentifier.Phone) {
      if (
        errors.name === undefined &&
        errors.phone === undefined &&
        (values?.phone.value !== cartData?.contactInfo?.email ||
          values?.name !== cartData?.contactInfo?.name)
      ) {
        await updateContactInfo({
          info: {
            phone: values?.phone?.value,
            name: values?.name,
          },
        });
      }
    } else if (customCheckoutSetting?.identifier === CustomerIdentifier.Email) {
      if (
        errors.name === undefined &&
        errors.email === undefined &&
        (values?.email !== cartData?.contactInfo?.email ||
          values?.name !== cartData?.contactInfo?.name)
      ) {
        await updateContactInfo({
          info: {
            email: values?.email,
            name: values.name,
          },
        });
      }
    } else {
      if (
        errors.name === undefined &&
        errors.email === undefined &&
        errors.phone === undefined &&
        (values?.phone.value !== cartData?.contactInfo?.phone ||
          values?.email !== cartData?.contactInfo?.email ||
          values?.name !== cartData?.contactInfo?.name)
      ) {
        await updateContactInfo({
          info: {
            phone: values?.phone?.value,
            name: values.name,
            email: values.email,
          },
        });
      }
    }
  };
  const setShippingDetails = async (values) => {
    const variables = {
      addressLine1: values?.address,
      addressLine2: values?.apartment,
      area: {
        cityId: values?.shippingDestination?.city?.value,
        countryId: values?.shippingDestination?.country?.value,
        regionId: values?.shippingDestination?.region?.value,
        stateId: values?.shippingDestination?.state?.value,
      },
      notes: values?.notes,
      postalCode: values?.postalCode === undefined ? null : values?.postalCode,
      secondPhone: values?.secondPhone?.value,
    };
    const preShippingDetails = {
      addressLine1: cartData?.shippingDetails?.addressLine1,
      addressLine2: cartData?.shippingDetails?.addressLine2,
      area: {
        cityId: cartData?.shippingDetails?.area?.cityId,
        countryId: cartData?.shippingDetails?.area?.countryId,
        regionId: cartData?.shippingDetails?.area?.regionId,
        stateId: cartData?.shippingDetails?.area?.stateId,
      },
      notes: cartData?.shippingDetails?.notes,
      postalCode: cartData?.shippingDetails?.postalCode,
      secondPhone: cartData?.shippingDetails?.secondPhone,
    };
    if (!isEqual(variables, preShippingDetails)) {
      await setCartShippingDetails({
        shippingDetails: variables,
      });
    }
  };
  const shippingDestinationByCart =
    cart.shippingInformation?.shippingDestination;
  const initialValuesOfCart = {
    address:
      cartData?.shippingDetails?.addressLine1 ||
      cart.shippingInformation?.address,
    apartment:
      cartData?.shippingDetails?.addressLine2 ||
      cart.shippingInformation?.apartment,
    isSubscribed: false,
    name: currentNameValue ?? cartData?.contactInfo?.name,
    email: currentEmailValue ?? cartData?.contactInfo?.email,
    notes: cartData?.shippingDetails?.notes || cart.shippingInformation?.notes,
    postalCode:
      cartData?.shippingDetails?.postalCode ||
      cart.shippingInformation?.postalCode,
    phone: {
      value: currentPhoneValue.value
        ? currentPhoneValue.value
        : cartData?.contactInfo?.phone,
      isValid: currentPhoneValue.isValid,
    },
    secondPhone: {
      value:
        cartData?.shippingDetails?.secondPhone ||
        cart?.shippingInformation?.secondPhone?.value,
      isValid: cart?.shippingInformation?.secondPhone?.isValid || true,
    },
    shippingDestination: {
      country: {
        label:
          cartData?.shippingDetails?.area?.country?.name ||
          shippingDestinationByCart?.country?.label,
        value:
          cartData?.shippingDetails?.area?.countryId ||
          shippingDestinationByCart?.country?.value,
      },
      state: {
        label:
          cartData?.shippingDetails?.area?.state?.name ||
          shippingDestinationByCart?.state?.label,
        value:
          cartData?.shippingDetails?.area?.stateId ||
          shippingDestinationByCart?.state?.value,
      },
      city: {
        label:
          cartData?.shippingDetails?.area?.city?.name ||
          shippingDestinationByCart?.city?.label,
        value:
          cartData?.shippingDetails?.area?.cityId ||
          shippingDestinationByCart?.city?.value,
      },
      region: {
        label:
          cartData?.shippingDetails?.area?.region?.name ||
          shippingDestinationByCart?.region?.label,
        value:
          cartData?.shippingDetails?.area?.regionId ||
          shippingDestinationByCart?.region?.value,
      },
      isValid:
        shippingDestinationAvailable || shippingDestinationByCart?.isValid,
    },
  };
  const areEqual = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };
  const handleSubmit = (values: CheckoutInformation) => {
    if (
      cartData.lastStep === CartStepEnum.Shopping ||
      cartData.lastStep === CartStepEnum.Information
    ) {
      fireBeginCheckoutEvent(cartData);
      fireCheckoutInformationEvent(values);
    }
    setShippingDetails(values);
    router.push(`/checkout/delivery/${cartId}`);
  };
  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values, customCheckoutSetting)}
      initialValues={{
        ...initialValuesOfCart,
      }}
      initialValuesEqual={areEqual}
      decorators={[focusOnError]}
      render={({ handleSubmit, values, errors }) => (
        <StyledForm onSubmit={handleSubmit}>
          <ContactSection>
            <H2>
              <FormattedMessage defaultMessage="Contact information" />
            </H2>
            <Label>
              <FlexRow>
                <FormattedMessage defaultMessage="Name" />
                <Asterisk />
              </FlexRow>
              <Field
                name="name"
                formatOnBlur
                format={(value) => {
                  if (checkSubmitting) return value;
                  updateValidation(values, errors);
                  return value;
                }}
              >
                {({ input, meta: { error, touched } }) => (
                  <>
                    <Input
                      {...input}
                      onChange={(e) => {
                        setCheckSubmitting(false);
                        setCurrentNameValue(e.target.value);
                        input.onChange(e);
                      }}
                      className={error && touched ? "invalid" : ""}
                      data-test="type-name"
                      ref={nameRef}
                    />
                    {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                  </>
                )}
              </Field>
            </Label>
            {customCheckoutSetting?.identifier !== CustomerIdentifier.Phone && (
              <>
                <Label>
                  <FlexRow>
                    <FormattedMessage defaultMessage="Email" />
                    <Asterisk />
                  </FlexRow>
                  <Field
                    name="email"
                    type="email"
                    formatOnBlur
                    format={(value) => {
                      if (checkSubmitting) return value;
                      updateValidation(values, errors);
                      return value;
                    }}
                  >
                    {({ input, meta: { error, touched } }) => (
                      <>
                        <Input
                          type="email"
                          data-test="type-email"
                          {...input}
                          onChange={(e) => {
                            setCheckSubmitting(false);
                            setCurrentEmailValue(e.target.value);
                            input.onChange(e);
                          }}
                          className={error && touched ? "invalid" : ""}
                          ref={emailRef}
                        />
                        {error && touched && (
                          <RequiredSpan style={{ height: "auto" }}>
                            {error}
                          </RequiredSpan>
                        )}
                      </>
                    )}
                  </Field>
                </Label>
                <Field<boolean> name="isSubscribed">
                  {({ input }) => (
                    <CheckboxLabel>
                      <CheckboxInput
                        type="checkbox"
                        checked={input.value || false}
                        onChange={(value) => input?.onChange(value)}
                      />
                      <Checkmark />
                      <FormattedMessage defaultMessage="Keep me up to date on news and exclusive offers" />
                    </CheckboxLabel>
                  )}
                </Field>
              </>
            )}
            {customCheckoutSetting?.identifier !== CustomerIdentifier.Email && (
              <Flex>
                <PhoneField fullWidth>
                  <Label>
                    <FlexRow>
                      <FormattedMessage defaultMessage="Phone no." />
                      <Asterisk />
                    </FlexRow>
                  </Label>
                  <Field<PhoneInput>
                    fullWidth
                    name="phone"
                    formatOnBlur
                    format={(value) => {
                      if (checkSubmitting) return value;
                      updateValidation(values, errors);
                      return value;
                    }}
                  >
                    {(fieldProps) => (
                      <InputPhone
                        setPhoneValue={setCurrentPhoneValue}
                        {...fieldProps}
                        onChange={(value) => {
                          setCheckSubmitting(false);
                          fieldProps.input.onChange(value);
                        }}
                        dataTest="type-primary-phone"
                        ref={phoneRef}
                      />
                    )}
                  </Field>
                </PhoneField>
                {customCheckoutSetting?.secondaryPhone !==
                  CheckoutFieldOptions.Inactive && (
                  <PhoneField fullWidth>
                    <Label>
                      <FlexRow>
                        <FormattedMessage defaultMessage="Secondary Phone no." />
                        {customCheckoutSetting?.secondaryPhone ===
                          CheckoutFieldOptions.Mandatory && <Asterisk />}
                      </FlexRow>
                    </Label>
                    <Field
                      formatOnBlur
                      format={(value) => {
                        if (checkSubmitting) return value;
                        setShippingInformation({
                          ...cart.shippingInformation!,
                          secondPhone: value,
                        });
                        return value;
                      }}
                      name="secondPhone"
                    >
                      {(fieldProps) => <InputPhone {...fieldProps} />}
                    </Field>
                  </PhoneField>
                )}
              </Flex>
            )}
          </ContactSection>
          <ShippingSection>
            <H2>
              <FormattedMessage defaultMessage="Shipping information" />
            </H2>
            <Label>
              <FlexRow>
                <FormattedMessage defaultMessage="Address" />
                <Asterisk />
              </FlexRow>
              <Field
                name="address"
                formatOnBlur
                format={(value) => {
                  if (checkSubmitting) return value;
                  setShippingInformation({
                    ...cart.shippingInformation!,
                    address: value,
                  });
                  return value;
                }}
              >
                {({ input, meta: { error, touched } }) => (
                  <>
                    <Input
                      {...input}
                      className={error && touched ? "invalid" : ""}
                      data-test="type-address"
                    />
                    {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                  </>
                )}
              </Field>
            </Label>
            <Label>
              <FlexRow>
                <FormattedMessage defaultMessage="Apartment, suite, Unit etc." />
                <Asterisk />
              </FlexRow>
              <Field
                formatOnBlur
                format={(value) => {
                  if (checkSubmitting) return value;
                  setShippingInformation({
                    ...cart.shippingInformation!,
                    apartment: value,
                  });
                  return value;
                }}
                name="apartment"
              >
                {({ input, meta: { error, touched } }) => (
                  <>
                    <Input
                      {...input}
                      className={error && touched ? "invalid" : ""}
                      data-test="type-apartment"
                    />
                    {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                  </>
                )}
              </Field>
            </Label>
            <Label>
              <Field<ShippingDestination> name="shippingDestination">
                {({ input, meta: { error } }) => (
                  <ShippingDestinationSelect
                    {...input}
                    onChange={(value) => {
                      input?.onChange(value);
                      handleShippingRates(value);
                      setShippingInformation({
                        ...cart.shippingInformation!,
                        shippingDestination: value,
                      });
                    }}
                    startQuery
                    error={error}
                  />
                )}
              </Field>
            </Label>
            {customCheckoutSetting?.postalCode !==
              CheckoutFieldOptions.Inactive && (
              <>
                <Label>
                  <FlexRow>
                    <FormattedMessage defaultMessage="Postal Code" />
                    {customCheckoutSetting?.postalCode ===
                      CheckoutFieldOptions.Mandatory && <Asterisk />}
                  </FlexRow>
                </Label>
                <Field
                  formatOnBlur
                  format={(value) => {
                    if (checkSubmitting) return value;
                    setShippingInformation({
                      ...cart.shippingInformation!,
                      postalCode: value,
                    });
                    return value;
                  }}
                  name="postalCode"
                >
                  {({ input, meta: { error, touched } }) => (
                    <>
                      <Input
                        {...input}
                        className={error && touched ? "invalid" : ""}
                        data-test="type-postalcode"
                        type="text"
                      />
                      {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                    </>
                  )}
                </Field>
              </>
            )}
            {customCheckoutSetting?.notesToSeller !==
              CheckoutFieldOptions.Inactive && (
              <>
                <Label>
                  <FlexRow>
                    <FormattedMessage defaultMessage="Notes to seller" />
                    {customCheckoutSetting?.notesToSeller ===
                      CheckoutFieldOptions.Mandatory && <Asterisk />}
                  </FlexRow>
                  <Field
                    formatOnBlur
                    format={(value) => {
                      if (checkSubmitting) return value;
                      setShippingInformation({
                        ...cart.shippingInformation!,
                        notes: value,
                      });
                      return value;
                    }}
                    name="notes"
                  >
                    {({ input, meta: { error, touched } }) => (
                      <>
                        <TextArea
                          {...input}
                          data-test="type-notes"
                          className={error && touched ? "invalid" : ""}
                        />
                        {error && touched && (
                          <RequiredSpan>{error}</RequiredSpan>
                        )}
                      </>
                    )}
                  </Field>
                </Label>
              </>
            )}
          </ShippingSection>
          <RouteSection isRecoveryCart={cartIdPrefix === "recoverycart"}>
            {cartIdPrefix !== "recoverycart" && (
              <Link href="/cart">
                <GoBackButton type="button" data-test="button-go-back">
                  <div>
                    <LeftArrow />
                  </div>
                  <FormattedMessage defaultMessage="Return to cart" />
                </GoBackButton>
              </Link>
            )}
            <ContinueButton
              data-test="button-delivery"
              type="submit"
              suffixIcon={<RightArrow />}
              loadOnRouteChange
              isLoading={CartShippingDetailsLoading}
              onClick={() => {
                if (nameRef.current) nameRef.current.blur();
                if (emailRef.current) emailRef.current.blur();
                if (phoneRef.current) phoneRef.current.blur();
                setCheckSubmitting(true);
              }}
              disabled={!cartData?.items?.length}
            >
              <FormattedMessage defaultMessage="Continue to Delivery " />
            </ContinueButton>
          </RouteSection>
        </StyledForm>
      )}
    />
  );
};
export default GuestInformation;
