import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { H3 } from "../../../../shared/globals";
import ReviewsEmptyState from "../../components/CustomerProfile/my-reviews/ReviewsEmptyState";
import { useListCustomerReviewsQuery } from "../../../../generated/graphql";
import { useAuth } from "../../../../lib/Authentication";
import ReviewsList from "../../components/CustomerProfile/my-reviews/ReviewsList";
import LoadingSpinner from "../../../../shared/globals/UiElements/LoadingSpinner";

const UserReviews = () => {
  const { user } = useAuth();
  const { data, loading } = useListCustomerReviewsQuery({
    variables: {
      input: {
        customerId: user?.id!,
      },
    },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <MarginTop>
      <H3 fontWeight="bold">
        <FormattedMessage defaultMessage="My ratings & reviews" />
      </H3>
      {data?.listCustomerReviews?.length ? (
        <ReviewsList reviews={data?.listCustomerReviews} />
      ) : (
        <ReviewsEmptyState />
      )}
    </MarginTop>
  );
};

export default UserReviews;

const MarginTop = styled.div`
  margin-top: 25px;
  width: 100%;
`;
