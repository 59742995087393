import Modal from "../../../../shared/globals/UiElements/Modal";
import { StoreTemplate } from "../../../TemplateLoader";

interface LoginPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const LoginPopup = ({ isOpen, onClose }: LoginPopupProps) => {
  const Template = StoreTemplate.get();
  return (
    <Modal modalWidth="small" show={isOpen} onClose={onClose}>
      <Modal.Body>
        {/* @ts-expect-error: should expect LoginFormProps to be required but handled in its renderer */}
        <Template.elements.LoginForm />
      </Modal.Body>
    </Modal>
  );
};

export default LoginPopup;
