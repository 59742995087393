import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import {
  DeleteButton,
  StyledDiscount,
  StyledNonDiscountedVariants,
  StyledNumber,
} from "./styled";
import { TrashIcon } from "../../../../assets/Icons";
import { DefaultTextPrice } from "../../../../components/Price";
import { QuantityInput } from "../QuantityInput";
import {
  CartCustomItem,
  CartSimpleItem,
  CheckoutServiceErrorCodes as ErrorCodes,
  ProductType,
} from "../../../../generated/graphql";
import { FlexCol, FlexRow, H4, P } from "../../../../shared/globals";
import Alert from "../../../../shared/globals/UiElements/Alert";
import { subtractMoney } from "../../../../shared/utils/money";
import { CartItemChildrenProps } from "../../../default/components/Cart/types";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import { useCartData } from "../../../../lib/cartData/useCartData";
import { PriceProps } from "../../../../components/Price/Price";
import { CartItemInfo } from "./CartItemInfo";
import { isProductNotAvailableError } from "../../../../shared/utils/error-utils";

const CartItemDesktop = ({
  item,
  isCustomDiscountApplied,
  isAutomaticDiscountApplied,
  discountedSimpleItemsCount,
  nonDiscountedSimpleItemsCount,
  isAllItemsDiscounted,
  itemErrors,
  value,
  setValue,
  isAlertOpen,
  setIsAlertOpen,
  onQuantityChange,
  onRemoveCartItem,
}: CartItemChildrenProps) => {
  const {
    cart: { isLoading },
  } = useCartData();

  const isProductNotAvailableErrorFound =
    isProductNotAvailableError(itemErrors);

  const isItemSimple = item?.product?.type === ProductType.Simple;

  const typedSimpleItem = item as CartSimpleItem;
  const typedCustomItem = item as CartCustomItem;

  const totalPricePerSimpleItem: PriceProps["money"] = subtractMoney(
    typedSimpleItem.price!,
    typedSimpleItem.discount?.perItem
  );

  const totalPricePerCustomItem: PriceProps["money"] = subtractMoney(
    typedCustomItem.price!,
    typedCustomItem.discount?.perItem
  );

  return (
    <>
      <tr>
        <td colSpan={5} style={{ padding: 0 }}>
          <HR />
        </td>
      </tr>

      <tr>
        <StyledTD>
          <FlexRow spacing="m">
            <ItemImg
              imgDetails={
                typedSimpleItem?.variant?.image ||
                typedCustomItem?.product?.images[0]
              }
            />
            <CartItemInfo
              item={item}
              isCustomDiscountApplied={isCustomDiscountApplied}
              isAutomaticDiscountApplied={isAutomaticDiscountApplied}
              discountedSimpleItemsCount={discountedSimpleItemsCount}
              isAllItemsDiscounted={isAllItemsDiscounted}
            />
          </FlexRow>

          {isAutomaticDiscountApplied && !isAllItemsDiscounted && (
            <StyledNonDiscountedVariants spacing="xs">
              <FlexRow spacing="xs">
                <StyledNumber>{nonDiscountedSimpleItemsCount}x</StyledNumber>
                <H4>
                  {(typedSimpleItem?.variant?.selectedOptions || [])
                    ?.map((option) => option.value.name)
                    .join(" / ")}
                </H4>
              </FlexRow>

              <PriceWrapper isNotActive={isProductNotAvailableErrorFound}>
                <DefaultTextPrice
                  money={
                    isItemSimple
                      ? totalPricePerSimpleItem
                      : totalPricePerCustomItem
                  }
                />
              </PriceWrapper>
            </StyledNonDiscountedVariants>
          )}
        </StyledTD>

        <StyledTD>
          <PriceWrapper
            spacing="xs"
            isNotActive={isProductNotAvailableErrorFound}
          >
            {(isAutomaticDiscountApplied || isCustomDiscountApplied) && (
              <StyledDiscount money={item.price} />
            )}
            <DefaultTextPrice
              money={
                isItemSimple ? totalPricePerSimpleItem : totalPricePerCustomItem
              }
            />
          </PriceWrapper>
        </StyledTD>

        <StyledTD style={{ position: "relative" }}>
          <QuantityInput
            max={
              typedSimpleItem.variant?.maxPerCart || Number.POSITIVE_INFINITY
            }
            value={item.quantity}
            onChange={(value) => {
              setValue(value);
              onQuantityChange(value);
            }}
            disabled={isProductNotAvailableErrorFound}
            loading={isLoading}
          />

          {isAlertOpen && (
            <Alert
              message={
                value >
                (typedSimpleItem?.variant?.maxPerCart ||
                  Number?.POSITIVE_INFINITY) ? (
                  <FormattedMessage defaultMessage="Quantity exceeded" />
                ) : (
                  <FormattedMessage
                    defaultMessage="Minimum quantity per order {min}. You can add up to {max} per order"
                    values={{
                      min:
                        typedSimpleItem?.variant?.minPerCart ||
                        Number.POSITIVE_INFINITY,
                      max:
                        typedSimpleItem?.variant?.maxPerCart ||
                        Number.POSITIVE_INFINITY,
                    }}
                  />
                )
              }
              style={{ position: "absolute" }}
              closeAction={() => setIsAlertOpen(false)}
            />
          )}
        </StyledTD>

        <StyledTD>
          <PriceWrapper
            spacing="xs"
            isNotActive={isProductNotAvailableErrorFound}
          >
            {isLoading ? (
              <P fontWeight="600" fontSize="14px">
                <FormattedMessage defaultMessage="Calculating" />
              </P>
            ) : (
              <>
                {(isAutomaticDiscountApplied || isCustomDiscountApplied) && (
                  <StyledDiscount money={item?.subtotal} />
                )}
                <DefaultTextPrice money={item.totalPrice} />
              </>
            )}
          </PriceWrapper>
        </StyledTD>

        <StyledTD>
          <DeleteButton
            data-test="button-remove-item"
            type="button"
            onClick={() =>
              onRemoveCartItem(
                typedSimpleItem?.variant?.id || typedCustomItem?.product?.id
              )
            }
            disabled={isLoading}
          >
            <TrashIcon />
          </DeleteButton>
        </StyledTD>
      </tr>

      {itemErrors?.map((itemError, i) => (
        <tr key={i}>
          <StyledErrorTD colSpan={5}>
            <Alert
              type={
                itemError.code === ErrorCodes.PriceChanged ? "warn" : undefined
              }
              message={itemError.message}
            />
          </StyledErrorTD>
        </tr>
      ))}
    </>
  );
};

export default CartItemDesktop;

// Styles

const StyledTD = styled.td`
  text-align: start;
  padding: 20px 6px;
  :first-of-type {
    padding-inline-start: 20px;
  }
  :last-of-type {
    padding-inline-end: 20px;
    text-align: end;
  }
`;

const StyledErrorTD = styled.td`
  padding: 0 20px 20px;
  column-span: 5;
  > div {
    margin-top: 0;
  }
`;

const PriceWrapper = styled(FlexCol)<{ isNotActive: boolean }>`
  align-items: flex-start;
  opacity: ${({ isNotActive }) => isNotActive && 0.5};
  span {
    font-size: 14px;
    font-weight: 600;
  }
`;

const HR = styled.div`
  width: 100%;
  border-bottom: solid 1px ${({ theme }) => theme.bg.wash}1a;
`;
