const Valu = () => (
  <svg
    width="44"
    height="14"
    viewBox="0 0 44 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2259_23919)">
      <path
        d="M0.00292969 3.48324C0.0263964 3.47108 0.0247964 3.44702 0.0311964 3.42621C0.137596 3.08351 0.399196 2.87297 0.74213 2.85324C1.07653 2.83378 1.36773 3.02 1.50506 3.35297C1.74453 3.93243 1.97866 4.51405 2.21466 5.09513C2.9456 6.89621 3.67626 8.69729 4.4072 10.4984C4.424 10.5397 4.44266 10.5803 4.46906 10.6411C4.4952 10.5813 4.516 10.5368 4.53466 10.4911C5.49493 8.12567 6.4552 5.76027 7.41546 3.39513C7.61546 2.90216 8.11946 2.70675 8.54693 2.9554C8.89226 3.15648 9.02746 3.56783 8.86906 3.96081C8.5328 4.79594 8.19226 5.62919 7.8536 6.46324C6.996 8.5754 6.13866 10.6878 5.28133 12.8C5.2544 12.8662 5.22933 12.9332 5.20266 12.9997C5.07386 13.3208 4.7888 13.5211 4.46426 13.5189C4.1448 13.5168 3.86426 13.3173 3.7368 13.0022C3.3032 11.9313 2.86986 10.8603 2.43546 9.78946C1.656 7.86783 0.875996 5.94648 0.0959964 4.02513C0.0661297 3.95189 0.0341297 3.87946 0.00292969 3.80675C0.00292969 3.69892 0.00292969 3.59081 0.00292969 3.48297V3.48324Z"
        fill="#01A79D"
      />
      <path
        d="M42.6637 1.21594C42.9008 1.08756 43.1123 0.918916 43.3355 0.768916C43.5664 0.61378 43.7216 0.646483 43.86 0.885132C43.904 0.960807 43.9515 1.03432 43.9973 1.10865V1.29756C43.9424 1.41189 43.8475 1.47594 43.736 1.52783C43.4979 1.63865 43.2632 1.75756 43.0101 1.88189C43.2619 2.00594 43.4933 2.12513 43.7296 2.23432C43.8411 2.28594 43.9328 2.35297 43.9976 2.45783V2.64675C43.9435 2.73865 43.8907 2.83108 43.8349 2.92189C43.7243 3.10216 43.5547 3.14189 43.38 3.02459C43.1691 2.88297 42.9613 2.73702 42.7515 2.59405C42.7272 2.57756 42.7024 2.54405 42.6699 2.56378C42.6381 2.58297 42.6552 2.62081 42.6573 2.65C42.6733 2.88756 42.6872 3.1254 42.7085 3.36243C42.7325 3.62892 42.6203 3.75892 42.3549 3.76054C41.8941 3.76351 41.8427 3.71135 41.8661 3.24216C41.8776 3.01054 41.8987 2.77946 41.9173 2.52189C41.6821 2.68297 41.4667 2.83027 41.2512 2.9781C40.9853 3.16054 40.8443 3.12702 40.6824 2.84297C40.6624 2.80783 40.6413 2.77351 40.6219 2.73838C40.5107 2.53621 40.5595 2.37162 40.7629 2.27C40.9819 2.16108 41.2019 2.05486 41.4211 1.94648C41.4597 1.92729 41.5051 1.91783 41.5352 1.87405C41.2827 1.75054 41.0309 1.6281 40.7803 1.50405C40.5517 1.39108 40.5067 1.23702 40.6333 1.00973C40.8605 0.602429 40.9512 0.580807 41.3336 0.84324C41.5205 0.971618 41.7072 1.1 41.9144 1.24243C41.904 1.06919 41.8957 0.916483 41.8851 0.76378C41.8752 0.620267 41.8613 0.477294 41.8517 0.33378C41.8392 0.14351 41.964 0.00783445 42.1552 0.00269931C42.2261 0.00080742 42.2973 0.00215877 42.3685 0.00242904C42.6139 0.00378039 42.7301 0.137564 42.7096 0.388645C42.6891 0.639456 42.6728 0.890537 42.6547 1.14135C42.6528 1.16783 42.6467 1.1954 42.6661 1.21865C42.6611 1.22054 42.656 1.22216 42.6509 1.22405C42.6533 1.22486 42.6571 1.22675 42.6579 1.22594C42.6605 1.22324 42.6621 1.21919 42.6643 1.21594H42.6637Z"
        fill="#F16624"
      />
      <path
        d="M39.4339 4.85432C39.4373 5.51973 39.4427 6.18432 39.3355 6.84486C39.0392 8.67054 38.2197 10.2135 36.8792 11.4665C35.6952 12.573 34.2973 13.25 32.7027 13.4849C28.5861 14.0905 24.8309 11.3949 23.8947 7.44783C23.7501 6.83811 23.68 6.21892 23.6805 5.59162C23.6811 4.94865 23.68 4.30567 23.6805 3.6627C23.6824 1.86 24.8869 0.394861 26.6384 0.0645912C28.5403 -0.294328 30.4539 1.0854 30.7483 3.02513C30.7888 3.29297 30.7995 3.56135 30.7987 3.83135C30.7971 4.40702 30.7976 4.98243 30.7984 5.5581C30.7989 6.02702 31.1227 6.37216 31.5589 6.37081C31.9944 6.36946 32.3165 6.02405 32.3173 5.55324C32.3187 4.91027 32.316 4.26729 32.3179 3.62432C32.3232 1.85 33.5571 0.369726 35.2853 0.0626993C37.2176 -0.280544 39.1008 1.09729 39.3885 3.06351C39.4672 3.60054 39.42 4.14081 39.4333 4.67946C39.4347 4.73783 39.4333 4.79648 39.4333 4.85486L39.4339 4.85432ZM25.2357 4.65973C25.2357 5.00324 25.2344 5.26865 25.236 5.53405C25.2392 6.03865 25.2829 6.53919 25.3973 7.03135C26.2035 10.5013 29.5893 12.6289 33.0187 11.8208C35.7901 11.1678 37.8192 8.63432 37.8685 5.75567C37.8808 5.03189 37.8715 4.30783 37.8717 3.58378C37.8717 3.46216 37.8597 3.34189 37.8381 3.22189C37.6496 2.17865 36.6131 1.44 35.5789 1.61351C34.5765 1.78162 33.8797 2.6027 33.8723 3.63216C33.8675 4.26621 33.872 4.90027 33.8707 5.53432C33.8707 5.65108 33.8731 5.76919 33.8571 5.88432C33.6693 7.24783 32.4808 8.13 31.1357 7.90702C30.1312 7.74054 29.3019 6.81189 29.2512 5.7827C29.2165 5.07729 29.2435 4.37108 29.2379 3.6654C29.2363 3.47135 29.2211 3.28 29.1723 3.09162C28.9261 2.14027 28.0147 1.50243 27.036 1.59783C26.0843 1.69054 25.3125 2.49216 25.2483 3.45675C25.2197 3.88351 25.2448 4.31081 25.236 4.65946L25.2357 4.65973Z"
        fill="#F16523"
      />
      <path
        d="M18.2213 11.9565C18.0013 12.16 17.8059 12.3349 17.5944 12.4905C14.4725 14.7903 10.008 13.0019 9.2864 9.16189C8.74186 6.26405 10.6323 3.44405 13.4875 2.89486C16.3861 2.33729 19.1832 4.28216 19.7011 7.21675C19.7549 7.52243 19.7851 7.83135 19.7853 8.14189C19.7867 9.67054 19.7853 11.1989 19.7837 12.7276C19.7832 13.1468 19.5368 13.4732 19.1701 13.5478C18.6616 13.6513 18.2323 13.2895 18.2221 12.7451C18.2176 12.4946 18.2213 12.2435 18.2213 11.9568V11.9565ZM18.216 8.16621C18.2589 6.2481 16.7096 4.40324 14.5213 4.37729C12.4563 4.3527 10.7629 6.04838 10.7504 8.13946C10.7379 10.2146 12.4035 11.9151 14.4616 11.9335C16.5925 11.9527 18.2467 10.1624 18.2163 8.16621H18.216Z"
        fill="#01A79D"
      />
      <path
        d="M22.3715 1.00081C22.3731 1.24351 22.376 1.48621 22.3763 1.72892C22.3771 3.06892 22.3568 4.40919 22.3821 5.74838C22.4312 8.35297 23.3381 10.6189 25.076 12.5422C25.1971 12.6762 25.3139 12.8095 25.3579 12.9913C25.4435 13.3457 25.2909 13.7013 24.9792 13.8754C24.6616 14.0527 24.2877 13.9935 24.0291 13.7184C23.5133 13.1697 23.0523 12.577 22.6496 11.9389C21.7507 10.5146 21.1827 8.96459 20.94 7.29324C20.8565 6.71892 20.8213 6.14054 20.8221 5.55973C20.8245 4.07135 20.8229 2.58324 20.8232 1.09486C20.8232 1.06351 20.8264 1.03216 20.8283 1.00081C20.8853 1.00189 20.8819 1.05378 20.8963 1.08729C21.032 1.40135 21.2835 1.5627 21.6067 1.56054C21.9237 1.5581 22.1717 1.39756 22.3043 1.0881C22.3187 1.05459 22.3149 1.00243 22.3717 1.00081H22.3715Z"
        fill="#01A79D"
      />
      <path
        d="M22.3715 1.00081C22.3147 1.00243 22.3184 1.05459 22.304 1.0881C22.1712 1.39756 21.9235 1.5581 21.6064 1.56054C21.2832 1.56297 21.0317 1.40162 20.896 1.08729C20.8813 1.05351 20.8848 1.00162 20.828 1.00081C20.828 0.982699 20.828 0.964862 20.828 0.946753C20.8315 0.944321 20.8381 0.940537 20.8376 0.939456C20.8355 0.932699 20.8315 0.926483 20.8283 0.920267C20.7667 0.374862 21.16 0.0270236 21.5531 0.00351012C22.0187 -0.024598 22.4315 0.379726 22.3715 0.89351C22.3683 0.895943 22.3619 0.899726 22.3624 0.901078C22.3645 0.907564 22.3685 0.913781 22.3717 0.919997C22.3717 0.947024 22.3717 0.974321 22.3717 1.00135L22.3715 1.00081Z"
        fill="#0FA69C"
      />
      <path
        d="M20.8283 0.919997C20.8315 0.926483 20.8355 0.932429 20.8376 0.939186C20.8381 0.940537 20.8315 0.944051 20.828 0.946483C20.828 0.937564 20.828 0.928645 20.828 0.919726L20.8283 0.919997Z"
        fill="#01A79D"
      />
      <path
        d="M22.3715 0.919456C22.3683 0.91324 22.3643 0.907294 22.3621 0.900537C22.3616 0.899186 22.368 0.895672 22.3712 0.89297C22.3712 0.901618 22.3712 0.910537 22.3715 0.919186V0.919456Z"
        fill="#01A79D"
      />
      <path
        d="M42.6637 1.21594C42.6616 1.21946 42.66 1.22324 42.6573 1.22594C42.6565 1.22675 42.6528 1.22486 42.6504 1.22405C42.6555 1.22216 42.6605 1.22054 42.6656 1.21865L42.6637 1.21567V1.21594Z"
        fill="#FEFEFE"
      />
      <path
        d="M36.656 5.49946C36.6819 8.00162 35.044 9.91919 33.0355 10.5368C30.1589 11.4216 27.1197 9.57216 26.5544 6.58973C26.4488 6.03297 26.4456 5.47189 26.4637 4.91054C26.4779 4.47405 26.8323 4.15297 27.2517 4.15919C27.6741 4.1654 28.0053 4.50297 28.0189 4.94919C28.0261 5.1827 28.0184 5.41675 28.0221 5.65054C28.0504 7.40865 29.316 8.87756 31.0299 9.14378C32.9965 9.44919 34.8365 8.02459 35.0699 6.0181C35.1117 5.65919 35.0859 5.29946 35.0949 4.94027C35.104 4.58108 35.3424 4.27054 35.6731 4.18594C36.0181 4.09783 36.3661 4.2427 36.5453 4.54756C36.6275 4.68756 36.6536 4.84081 36.6555 5C36.6573 5.16648 36.656 5.3327 36.656 5.49919V5.49946Z"
        fill="#F16523"
      />
    </g>
    <defs>
      <clipPath id="clip0_2259_23919">
        <rect width="44" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { Valu };
