import React, { useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { CommentIcon, OutlineStar } from "../../../../../src/assets/Icons";
import { Card } from "../../../../shared/globals/UiElements/Card";
import ProgressBar from "../ProgressBar";
import StyledStarsRating from "./StyledStarsRating";
import { useAuth } from "../../../../lib/Authentication";
import ReviewButton from "../../components/CustomerProfile/my-orders/ReviewButton";
import { ListCustomerReviewsQuery } from "../../../../generated/graphql";
import { PrimaryButton } from "../../../../components/Button";
import LoginPopup from "../Authentication/LoginPopup";
import { useStore } from "../../../../lib/storeData";
import { theme } from "../../../../shared/theme";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { H1, H3, H4 } from "../../../../shared/globals";

interface OverAllRatingProps {
  overAllRating: number;
  numberOfReviews: number;
  numberOfReviewsByStars: {
    5: number;
    4: number;
    3: number;
    2: number;
    1: number;
  };
  product: ListCustomerReviewsQuery["listCustomerReviews"][0]["product"];
}

const OverAllRating = ({
  overAllRating,
  numberOfReviews,
  numberOfReviewsByStars,
  product,
}: OverAllRatingProps) => {
  const { isLoggedIn } = useAuth();
  const { storeReviewSettings } = useStore();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <Card
      className="min-w-[380px] max-w-[345px] max-md:min-w-full"
      paddingSize="none"
    >
      <div className="p-sm border-b border-b-gray-200">
        <H3 fontWeight={600}>
          <FormattedMessage defaultMessage="Overall rating" />
        </H3>
      </div>
      <Flex fullWidth spacing="xxl" className="p-sm">
        <Flex column alignItems="flex-start" spacing="s">
          <H1 fontWeight={600} fontSize={"18px"}>
            <FormattedNumber value={parseFloat(overAllRating.toFixed(1))} />
          </H1>
          <StyledStarsRating
            isFractional
            isReadonly
            initialRatingValue={overAllRating}
          />
          <H4 color="secondary" fontWeight={400} fontSize={"14px"}>
            <FormattedMessage
              defaultMessage="{reviewsCount, plural, =0 {0 reviews} one {1 review} two {# reviews} few {# reviews} many {# reviews} other {# reviews}}"
              values={{
                reviewsCount: numberOfReviews,
              }}
            />
          </H4>
        </Flex>
        <Flex column fullWidth spacing="none">
          {["5", "4", "3", "2", "1"].map((star) => (
            <StarsProgressBar
              key={star}
              numberOfStars={star}
              numberOfReviews={numberOfReviews}
              numberOfStarReviews={numberOfReviewsByStars[star]}
              fillColor={
                storeReviewSettings?.starsColor || theme.colors.primary
              }
            />
          ))}
        </Flex>
      </Flex>
      <hr color="#E5E9EB" />
      <Flex fullWidth alignItems="start" spacing="s" className="p-sm">
        {storeReviewSettings?.enableGuestReviews ? (
          isLoggedIn ? (
            <ReviewButton
              product={product}
              buttonComponent={
                <PrimaryButton
                  prefixIcon={<CommentIcon />}
                  fullWidth
                  style={{
                    borderRadius: "20px",
                  }}
                >
                  <FormattedMessage defaultMessage="Write a review" />
                </PrimaryButton>
              }
            />
          ) : (
            <>
              <PrimaryButton
                prefixIcon={<CommentIcon />}
                fullWidth
                style={{
                  borderRadius: "20px",
                }}
                onClick={() => setIsPopupOpen(true)}
              >
                <FormattedMessage defaultMessage="Write a review" />
              </PrimaryButton>
              <LoginPopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
              />
            </>
          )
        ) : (
          <>
            <Flex column alignItems="flex-start">
              <OutlineStar />
            </Flex>
            <Flex column alignItems="flex-start" spacing="s">
              <H3 fontWeight="600">
                <FormattedMessage defaultMessage="How do i review this product?" />
              </H3>
              <H4 color="secondary" fontSize="12px" fontWeight={400}>
                <FormattedMessage defaultMessage="If you recently purchased this product, you can go to your Orders page and click on the Submit Review button" />
              </H4>
            </Flex>
          </>
        )}
      </Flex>
    </Card>
  );
};

const StarsProgressBar = ({
  numberOfStars,
  numberOfReviews,
  numberOfStarReviews,
  fillColor,
}) => {
  return (
    <Flex fullWidth spacing="m" alignItems="center">
      <Flex column className="min-w-[5px]" spacing="none">
        <H4>
          <FormattedNumber value={numberOfStars} />
        </H4>
      </Flex>
      <ProgressBar
        progress={(numberOfStarReviews / numberOfReviews) * 100}
        bgcolor={fillColor}
      />
      <Flex column className="min-w-[15px]" spacing="none" alignItems="center">
        <H4 color="secondary">
          <FormattedNumber value={numberOfStarReviews} />
        </H4>
      </Flex>
    </Flex>
  );
};

export default OverAllRating;
