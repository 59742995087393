import { createReducer } from "@reduxjs/toolkit";
import {
  setCartLading,
  setSideCartState,
  setCartPaymentPopUpState,
  updateCart,
  updateRecoveryCart,
  setShippingInformation,
  resetRecoveryCart,
  setCartEmptyPopUpState,
} from "./actionTypes";
import { CartContextType } from "../CartContext";

export const reducer = (initialState: CartContextType) =>
  createReducer(initialState, (builder) =>
    builder
      /**
       * CART REDUCERS
       */
      .addCase(updateCart, (state, { payload }) => {
        return { ...state, ...payload };
      })
      .addCase(updateRecoveryCart, (state, { payload }) => {
        return { ...state, recoveryCart: payload };
      })
      .addCase(resetRecoveryCart, (state) => {
        return { ...state, recoveryCart: null };
      })
      .addCase(setSideCartState, (state, { payload }) => {
        return { ...state, isSideCartOpen: payload };
      })
      .addCase(setCartLading, (state, { payload }) => {
        return { ...state, isLoading: payload };
      })
      .addCase(setCartPaymentPopUpState, (state, { payload }) => {
        return { ...state, isCartPaymentPopUpOpen: payload };
      })
      .addCase(setCartEmptyPopUpState, (state, { payload }) => {
        return { ...state, isCartEmptyPopUpOpen: payload };
      })
      .addCase(setShippingInformation, (state, { payload }) => {
        return { ...state, shippingInformation: payload };
      })
      .addDefaultCase((state) => state)
  );
