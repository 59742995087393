import { useRouter } from "../../../../../lib/i18n";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

interface ClearAllFiltersProps {
  loading: boolean;
  plain?: boolean;
  className?: string;
}

const ClearAllFilters = ({
  loading,
  plain,
  className,
}: ClearAllFiltersProps) => {
  const router = useRouter();
  return (
    <ClearFiltersButton
      className={className}
      disabled={
        loading ||
        Object.keys(router.query).length === 0 ||
        router.pathname.includes("collection")
      }
      plain={plain}
      onClick={() => {
        if (!router.pathname.includes("collection")) {
          if (router.pathname.includes("search")) {
            router.push({
              pathname: router.pathname.split("?")[0],
              query: {
                query: router.query.query ? router.query.query : undefined,
              },
            });
          } else {
            router.push({
              pathname: router.pathname.split("?")[0],
            });
          }
        }
      }}
    >
      <FormattedMessage defaultMessage="Clear all" />
    </ClearFiltersButton>
  );
};

const ClearFiltersButton = styled.button<{ plain?: boolean }>`
  height: ${({ plain }) => (plain ? "auto" : "40px")};
  background: ${({ plain }) => (plain ? "transparent" : "#fafbfc")};
  border: ${({ plain }) => (plain ? "none" : "1px solid #e5e5e5")};
  cursor: pointer;
  border-radius: 4px;
  color: #5f738c;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: ${({ plain }) => (plain ? 0 : 1)};
`;

export default ClearAllFilters;
