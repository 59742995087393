import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Information } from "../../../../../assets/Icons";
import { AddressInfoFragment } from "../../../../../generated/graphql";
import { FlexRow } from "../../../../../shared/globals";
import DeleteAddress from "./DeleteAddress";
import { EditAddress } from "./EditAddress";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import useBreakPoints from "../../../../../shared/utils/useBreakPoints";

interface AddressesPanelProps {
  address: AddressInfoFragment;
  userName: string;
  isDefault?: boolean;
  SetDefaultButton?: React.ReactNode;
  DefaultBadge?: React.ReactNode;
  CheckButton?: React.ReactNode;
  onSelectAddress?: React.MouseEventHandler<HTMLDivElement>;
  isValid: boolean;
}
const AddressesPanel: React.FC<AddressesPanelProps> = ({
  address,
  userName,
  isDefault,
  SetDefaultButton,
  DefaultBadge,
  CheckButton,
  isValid,
}) => {
  const { isTablet } = useBreakPoints();
  return (
    <AddressDetailsContainer>
      {!isValid && <InvalidAddressFlag />}
      <GridContainerHeader>
        <GridCell>
          <FormattedMessage defaultMessage="Name" />
        </GridCell>
        <GridCell>
          <FormattedMessage defaultMessage="Address" />
        </GridCell>
        <GridCell>
          <FormattedMessage defaultMessage="Country" />
        </GridCell>
        <GridCell>
          <FormattedMessage defaultMessage="Governorate" />
        </GridCell>
        <GridCell>
          <FormattedMessage defaultMessage="Postal code" />
        </GridCell>
        <GridCell>
          <FormattedMessage defaultMessage="Phone no" />
        </GridCell>
      </GridContainerHeader>
      <GridContainerBody rotateOn="TABLET">
        {CheckButton ? CheckButton : null}
        <GridCell bold>{userName}</GridCell>
        <GridCell column>
          <span>{address?.addressLine1}</span>
          <span>{address?.addressLine2}</span>
        </GridCell>
        <GridCell>{address?.country?.name}</GridCell>
        <GridCell>{address?.state?.name}</GridCell>

        {address?.postalCode ? (
          <GridCell>{address?.postalCode}</GridCell>
        ) : isTablet ? null : (
          " - "
        )}

        <GridCell column>
          <span
            style={{
              wordBreak: "break-all",
            }}
          ></span>
          <span
            style={{
              wordBreak: "break-all",
            }}
          >
            {address?.secondPhone}
          </span>
        </GridCell>
      </GridContainerBody>
      <GridContainerFooter alignItems="center" justifyContent="space-between">
        {isDefault && DefaultBadge ? DefaultBadge : null}
        {!isDefault && SetDefaultButton ? SetDefaultButton : null}
        <Flex alignItems="center">
          <EditAddress addressDetails={address} />
          <DeleteAddress addressId={address?.id} />
        </Flex>
      </GridContainerFooter>
    </AddressDetailsContainer>
  );
};

const AddressDetailsContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.bg.border};
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    margin-inline-start: -20px;
    border-radius: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
`;

const GridContainerHeader = styled(Flex)`
  display: grid;
  grid-template-columns: 15% 20% 15% 15% 15% 15%;
  padding: 20px;
  background-color: ${({ theme }) => theme.bg.reverse};
  border-bottom: 1px solid ${({ theme }) => theme.bg.border};
  @media (max-width: 768px) {
    display: none;
  }
`;

const GridContainerBody = styled(Flex)`
  display: grid;
  grid-template-columns: 15% 20% 15% 15% 15% calc(15% - 20px);
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.bg.border};
  @media (max-width: 768px) {
    display: flex;
    gap: 5px;
  }
`;

const GridContainerFooter = styled(Flex)`
  padding: 20px;
  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

const GridCell = styled(Flex)<{
  bold?: boolean;
}>`
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  @media (max-width: 768px) {
    color: #383838;
    font-size: 14px;
    font-weight: 400;
    gap: 5px;
  }
`;

const InvalidAddressFlag = () => {
  return (
    <InvalidAddressFlagContainer>
      <FlexRow justifyContent="start" alignItems="center" spacing="s">
        <Information />
        <FlexRow spacing="xs">
          <StyledErrorText isBolded>
            <FormattedMessage defaultMessage="Missing shipping address information," />
          </StyledErrorText>
          <StyledErrorText>
            <FormattedMessage defaultMessage="Please edit the address" />
          </StyledErrorText>
        </FlexRow>
      </FlexRow>
    </InvalidAddressFlagContainer>
  );
};

const InvalidAddressFlagContainer = styled.div`
  background-color: ${({ theme }) => theme.warn.wash};
  color: ${({ theme }) => theme.danger.default};
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
  border: solid 1px ${({ theme }) => theme.danger.alt};
  border-bottom: none;
`;

const StyledErrorText = styled.span<{ isBolded?: boolean }>`
  font-weight: ${({ isBolded }) => (isBolded ? "600" : "normal")};
  font-size: 14px;
`;
export default AddressesPanel;
