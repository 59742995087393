import styled, { css } from "styled-components";
import { PrimaryButton } from "../../../../components/Button";
import {
  HeavyH4,
  LightH4,
  LightH4ForDiscount,
} from "../../../../components/Checkout/CheckoutCart/styled";
import {
  FlexCol,
  H5,
  H3,
  FlexRow,
  H2,
  H4,
  Span,
} from "../../../../shared/globals";
import { themeColor, rtl } from "../../../../shared/styles-utils";

export const Container = styled(FlexCol)`
  background-color: ${themeColor("backgroundReverse")};
  padding: 30px 0;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const CartHolder = styled(FlexCol)`
  flex: 1 1 35%;
  margin-bottom: 60px;
`;

export const StyledTable = styled.table`
  display: none;
  width: 100%;
  @media (min-width: 768px) {
    display: table;
    height: fit-content;
    flex: 1 1 75%;
    border-collapse: collapse;
    ${rtl("margin-left", "margin-right")}: 3%;
  }
  & > thead > tr {
    border-bottom: solid 2px ${({ theme }) => theme.bg.wash}1a;
  }
`;

export const StyledTH = styled.th`
  text-align: start;
  padding-bottom: 15px;
`;

export const StyledTD = styled.td`
  text-align: start;
  padding: 15px 6px;
  padding: 15px 6px;
  :first-of-type {
    padding-inline-start: 0px;
  }
  :last-of-type {
    padding-inline-end: 0px;
    text-align: end;
  }
`;

export const StyledH5 = styled(H5)`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: ${({ theme: { text } }) => text.inkLight};
  text-transform: uppercase;
  margin-top: 6px;
`;

export const StyledH3 = styled(H3)`
  max-width: 250px;
  font-size: 12px;
  font-weight: 500;
`;

export const StyledH3WithMargin = styled(StyledH3)`
  font-size: 16px;
  font-weight: 700;
  ${rtl("margin-right", "margin-left")}: 4px;
`;

export const ItemInfo = styled(FlexCol)`
  width: 100%;
  ${rtl("margin-right", "margin-left")}: 10px;
`;

export const IconToButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: inherit;
  outline: none;
  color: #b7b8b9;
`;

export const CartPanel = styled(FlexCol)`
  background-color: ${themeColor("white")};
  width: 100%;
  max-width: 346px;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: solid 2px ${({ theme }) => theme.bg.wash}1a;
`;

export const BorderBottom = styled.div`
  width: 100%;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.bg.wash}1a;
    margin-bottom: 20px;
    padding-bottom: 14px;
  }
`;

export const FullWidth = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
`;

export const StyledH2 = styled(H2)`
  color: ${themeColor("primary")};
`;

export const CheckoutButton = styled(PrimaryButton)`
  width: 100%;
  & div {
    display: flex;
    transform: scaleX(${rtl("-1", "1")});
    padding-left: 8px;
  }
`;

export const PriceWrapper = styled(FlexRow)<{
  isNotActive: boolean;
  isBig?: boolean;
}>`
  opacity: ${({ isNotActive }) => isNotActive && 0.5};
  span {
    align-self: flex-start;
    font-size: ${({ isBig }) => (isBig ? "16px" : "13px")};
    font-weight: ${({ isBig }) => (isBig ? 700 : 600)};
    line-height: 1.5;
  }

  @media (min-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledH4 = styled(H4)`
  opacity: 0.5;
  font-weight: 700;
  ${rtl("margin-left", "margin-right")}: 10px;
  &:last-child {
    opacity: 1;
    font-weight: 500;
    margin-right: 0;
    color: ${themeColor("primary")};
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

export const FullWidthCol = styled(FlexCol)`
  width: 100%;
  ${rtl("padding-right", "padding-left")}: 82px;
`;

export const FlexRowWithMarginBottom = styled(FlexRow)`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const ItemHolder = styled(FullWidthCol)`
  ${rtl("padding-right", "padding-left")}: 0;
  &:not(:last-child) {
    margin-bottom: 14px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.bg.wash}1a;
  }
`;

export const StyledNumber = styled.h5`
  font-size: 12px;
  font-weight: normal;
  color: ${({ theme: { text } }) => text.inkLight};
  margin: 0 4px;
`;

export const StyledNonDiscountedVariants = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${rtl("0 +78px 0 -66px", "0 -66px 0 78px")};
  border-top: solid 1px ${({ theme }) => theme.bg.wash}1a;

  @media (max-width: 768px) {
    margin: 0;
    justify-content: flex-start;
    padding: 6px 0;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 0;
      justify-content: flex-start;
      padding: 6px 0;
    `}
`;

export const HR = styled.div`
  width: 100%;
  border-bottom: solid 1px ${({ theme }) => theme.bg.wash}1a;
`;

const SelectedOptions = styled(Span)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme: { text } }) => text.inkLight};
  text-transform: capitalize;
`;

export { SelectedOptions, HeavyH4, LightH4, LightH4ForDiscount };
