import React from "react";
import styled from "styled-components";
import { Photo } from "../../../shared/globals/UiElements/Photo";
import { Link } from "../../../lib/i18n";
import useBreakPoints from "../../../shared/utils/useBreakPoints";
import { LinkType } from "../../../generated/graphql";
import { BannerCardProps } from "../../types";
import { getLink } from "../../../shared/utils/getLink";

const BannerCard: React.FC<BannerCardProps> = ({ banner }) => {
  const { isTablet } = useBreakPoints();
  if (banner?.bannerLink?.type === LinkType.None)
    return <BannerImage banner={banner} isTablet={isTablet} />;

  return (
    <Link
      fullHeight
      fullWidth
      href={getLink(
        banner?.bannerLink?.type,
        banner?.bannerLink?.resource?.handle
      )}
      absoluteHref={
        banner?.bannerLink?.type === LinkType.ExternalLink
          ? banner?.bannerLink?.url!
          : ""
      }
      target={
        banner?.bannerLink?.type === LinkType.ExternalLink ? "_blank" : "_self"
      }
    >
      <BannerImage banner={banner} isTablet={isTablet} />
    </Link>
  );
};

export default BannerCard;

interface BannerImageProps {
  banner: BannerCardProps["banner"];
  isTablet: boolean;
}

const BannerImage = ({ banner, isTablet }: BannerImageProps) => (
  <PhotoContainer isLinkNone={banner?.bannerLink?.type === LinkType.None}>
    <Photo
      src={
        (isTablet
          ? banner?.mobileImage?.src || banner?.image?.src
          : banner?.image?.src || banner?.mobileImage?.src) ||
        "/default-placeholder-image.png"
      }
    />
  </PhotoContainer>
);

/**
 *
 * Styles
 *
 */

const PhotoContainer = styled.div<{
  isLinkNone: boolean;
}>`
  position: relative;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  cursor: ${({ isLinkNone }) => (isLinkNone ? "default" : "pointer")};
`;
