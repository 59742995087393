const Checked = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.6664 36.6473V40.0207C76.6618 47.9275 74.1015 55.6211 69.3672 61.954C64.633 68.2869 57.9784 72.9197 50.396 75.1616C42.8136 77.4035 34.7096 77.1343 27.2927 74.3941C19.8758 71.654 13.5434 66.5897 9.23986 59.9566C4.93632 53.3235 2.89224 45.4769 3.41249 37.5871C3.93274 29.6974 6.98943 22.1872 12.1267 16.1766C17.264 10.166 24.2066 5.97706 31.919 4.23453C39.6315 2.492 47.7007 3.28923 54.923 6.50733M76.6663 10.6663L39.9997 47.3697L28.9997 36.3697"
        stroke="#EF6820"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { Checked };
