import * as React from "react";
import { FormattedMessage } from "react-intl";
import { isEqual as _isEqual } from "lodash";
import {
  ProductDetailsQuery,
  ProductOptionValue,
  VariantInfoFragment,
} from "../generated/graphql";
import { useRouter } from "../lib/i18n";
import type { SimpleProductProps } from "../templates/types";
import type { RendererComponent } from "./types";
import { stringifySearchQuery } from "../shared/utils/resolveSearchQuery";
import { formatAvailableQuantity } from "../contexts/CartContext/reducer/utils";
import { useCartData } from "../lib/cartData/useCartData";
import { getCartItem } from "../lib/cartData/utils";

interface SimpleProductRendererProps
  extends RendererComponent<SimpleProductProps> {}

export enum CurrentTab {
  Overview = "Overview",
  Additional_Info = "Additional Info.",
}

const ProductInfoRenderer: React.FC<SimpleProductRendererProps> = ({
  product,
  Component,
}) => {
  const router = useRouter();
  const {
    cart,
    addSimpleItem: { addSimpleItem, loading: addingSimpleItem },
    updateSimpleItem: { updateSimpleItem, loading: updatingSimpleItem },
    setSideCartState,
  } = useCartData();
  const [quantity, setQuantity] = React.useState(1);
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const tabs = [
    {
      label: <FormattedMessage key={1} defaultMessage="Overview" />,
      value: CurrentTab.Overview,
    },
    {
      label: <FormattedMessage key={2} defaultMessage="Additional Info." />,
      value: CurrentTab.Additional_Info,
    },
  ];

  const selectedVariant =
    product?.variants?.nodes?.find(
      (variant) => variant.id === router?.query?.selections
    ) || product?.variants?.nodes?.[0];

  const availableQuantity = formatAvailableQuantity(selectedVariant);

  const itemInCart = getCartItem(cart, selectedVariant?.id!);

  const handleAddToCart = async (onCompletedFunction: (data: any) => void) => {
    if (itemInCart)
      await updateSimpleItem(
        {
          item: {
            quantity: itemInCart?.quantity + quantity,
            variantId: selectedVariant?.id!,
          },
        },
        onCompletedFunction
      );
    else
      await addSimpleItem(
        {
          item: {
            quantity,
            variantId: selectedVariant?.id!,
          },
        },
        onCompletedFunction
      );

    setSideCartState(true);
  };

  const handleSelectVariant =
    (value: ProductOptionValue, optionIndex: number) => () => {
      setIsAlertOpen(false);
      setQuantity(1);
      const variantId = getAvailableVariant(
        selectedVariant,
        optionIndex,
        product,
        value
      )?.id!;
      router.push(
        {
          pathname: router.asPath?.split("?")[0],
          query: stringifySearchQuery({ selections: variantId }),
        },
        undefined,
        { scroll: false }
      );
    };

  return (
    <Component
      selectedVariant={selectedVariant}
      product={product}
      availableQuantity={availableQuantity}
      isAlertOpen={isAlertOpen}
      quantity={quantity}
      handleSelect={handleSelectVariant}
      setQuantity={setQuantity}
      handleAddToCart={handleAddToCart}
      setIsAlertOpen={setIsAlertOpen}
      isValueAvailableForOtherSelectedValues={
        isValueAvailableForOtherSelectedValues
      }
      isCartLoading={addingSimpleItem || updatingSimpleItem}
      tabs={tabs.filter(
        (tab) =>
          tab.value !== CurrentTab.Additional_Info ||
          product?.attributes?.length! > 0
      )}
      isOutOfStock={
        selectedVariant?.quantity === 0 && selectedVariant?.trackQuantity
      }
    />
  );
};

export default ProductInfoRenderer;

/**
 *
 *
 * Functions
 *
 *
 */

function getEachValueAndItsAvailableValues(
  product: ProductDetailsQuery["product"]
) {
  const variantsValues = product?.variants?.nodes?.map((variant) =>
    variant.selectedOptions.map((option) => option?.value?.id)
  );
  const optionsValues = product?.options?.map((option) =>
    option?.values?.map((value) => value.id)
  );

  let temp: { [key: string]: string[][] } = {};
  optionsValues?.forEach((optionValues) => {
    optionValues?.forEach((value) => {
      const availableValues = variantsValues?.filter((variantValues) =>
        variantValues.includes(value)
      );
      temp = {
        ...temp,
        [value]: availableValues!,
      };
    });
  });

  return temp;
}

function getAvailableValuesForSpecificValue(
  value: string,
  product: ProductDetailsQuery["product"]
) {
  const eachValueAndItsAvailableValues =
    getEachValueAndItsAvailableValues(product);
  return eachValueAndItsAvailableValues[value].map((values) => values);
}

function isValueAvailableForOtherSelectedValues(
  selectedVariant: VariantInfoFragment | null | undefined,
  optionIndex: number,
  product: ProductDetailsQuery["product"],
  value: ProductOptionValue
) {
  if (!selectedVariant?.selectedOptions) return true;
  const otherSelectedOptions = [...selectedVariant.selectedOptions];
  otherSelectedOptions.splice(optionIndex, 1);
  const otherSelectedValues = otherSelectedOptions.map(
    (option) => option?.value?.id
  );

  const testVariant = [...otherSelectedValues];
  testVariant.splice(optionIndex, 0, value.id);

  const availableValues = getAvailableValuesForSpecificValue(value.id, product);

  const temp = availableValues.find((values) => _isEqual(values, testVariant));
  return !!temp;
}

function getAvailableVariant(
  selectedVariant: VariantInfoFragment | null | undefined,
  optionIndex: number,
  product: ProductDetailsQuery["product"],
  value: ProductOptionValue
) {
  const allVariantsValuesIDs = product?.variants?.nodes?.map((variant) =>
    variant?.selectedOptions?.map((option) => option.value.id)
  );

  const preSelectedVariantValuesIDs = selectedVariant?.selectedOptions?.map(
    (option) => option.value.id
  );

  const newSelectedVariantValuesIDs = [...preSelectedVariantValuesIDs!];
  newSelectedVariantValuesIDs.splice(optionIndex, 1, value.id);

  const variantIndex = allVariantsValuesIDs?.findIndex((variantValuesIDs) =>
    _isEqual(variantValuesIDs, newSelectedVariantValuesIDs)
  );

  if (variantIndex !== -1) {
    return product?.variants?.nodes?.[variantIndex!];
  } else {
    const temp = getAvailableValuesForSpecificValue(value.id, product);
    const newVariantIndex = allVariantsValuesIDs?.findIndex((variantValues) =>
      _isEqual(variantValues, temp[0])
    );
    return product?.variants?.nodes?.[newVariantIndex!];
  }
}
