import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import {
  DeleteButton,
  StyledNonDiscountedVariants,
  StyledNumber,
  StyledDiscount,
} from "./styled";
import { TrashIcon } from "../../../../assets/Icons";
import { DefaultTextPrice } from "../../../../components/Price";
import { QuantityInput } from "../QuantityInput";
import {
  CartCustomItem,
  CartSimpleItem,
  CheckoutServiceErrorCodes as ErrorCodes,
} from "../../../../generated/graphql";
import { FlexCol, FlexRow, H4, P } from "../../../../shared/globals";
import Alert from "../../../../shared/globals/UiElements/Alert";
import { CartItemChildrenProps } from "../../../default/components/Cart/types";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import { useCartData } from "../../../../lib/cartData/useCartData";
import { CartItemInfo } from "./CartItemInfo";
import { isProductNotAvailableError } from "../../../../shared/utils/error-utils";

const CartItemMobile = ({
  item,
  isCustomDiscountApplied,
  isAutomaticDiscountApplied,
  discountedSimpleItemsCount,
  nonDiscountedSimpleItemsCount,
  isAllItemsDiscounted,
  itemErrors,
  value,
  setValue,
  isAlertOpen,
  setIsAlertOpen,
  onQuantityChange,
  onRemoveCartItem,
}: CartItemChildrenProps) => {
  const {
    cart: { isLoading },
  } = useCartData();

  const isProductNotAvailableErrorFound =
    isProductNotAvailableError(itemErrors);

  const typedSimpleItem = item as CartSimpleItem;
  const typedCustomItem = item as CartCustomItem;

  return (
    <ItemHolder fullWidth spacing="l" alignItems="flex-start">
      <FlexRow spacing="m">
        <ItemImg
          imgDetails={
            typedSimpleItem?.variant?.image ||
            typedCustomItem?.product?.images[0]
          }
        />
        <FlexCol fullWidth alignItems="flex-start" spacing="s">
          <CartItemInfo
            item={item}
            isCustomDiscountApplied={isCustomDiscountApplied}
            isAutomaticDiscountApplied={isAutomaticDiscountApplied}
            discountedSimpleItemsCount={discountedSimpleItemsCount}
            isAllItemsDiscounted={isAllItemsDiscounted}
          />
          <PriceWrapper
            fullWidth
            spacing="xs"
            isNotActive={isProductNotAvailableErrorFound}
          >
            {(isAutomaticDiscountApplied || isCustomDiscountApplied) && (
              <StyledDiscount money={item?.subtotal} />
            )}
            {isLoading ? (
              <P fontWeight="600">
                <FormattedMessage defaultMessage="Calculating..." />
              </P>
            ) : (
              <DefaultTextPrice money={item?.totalPrice} />
            )}
          </PriceWrapper>
          {isAutomaticDiscountApplied && !isAllItemsDiscounted && (
            <StyledNonDiscountedVariants spacing="xs">
              <FlexRow spacing="xs">
                <StyledNumber>{nonDiscountedSimpleItemsCount}x</StyledNumber>
                <H4>
                  {(typedSimpleItem?.variant?.selectedOptions || [])
                    ?.map((option) => option.value.name)
                    .join(" / ")}
                </H4>
              </FlexRow>
              <PriceWrapper isNotActive={isProductNotAvailableErrorFound}>
                <DefaultTextPrice money={item?.price} />
              </PriceWrapper>
            </StyledNonDiscountedVariants>
          )}
          <FlexRow spacing="m">
            <QuantityInput
              max={
                typedSimpleItem.variant?.maxPerCart || Number.POSITIVE_INFINITY
              }
              value={item.quantity}
              onChange={(value) => {
                setValue(value);
                onQuantityChange(value);
              }}
              disabled={isProductNotAvailableErrorFound}
              loading={isLoading}
            />

            <DeleteButton
              type="button"
              onClick={() =>
                onRemoveCartItem(
                  typedSimpleItem?.variant?.id || typedCustomItem?.product?.id
                )
              }
              disabled={isLoading}
            >
              <TrashIcon />
            </DeleteButton>
          </FlexRow>
        </FlexCol>
      </FlexRow>

      {isAlertOpen && (
        <Alert
          message={
            value >
            (typedSimpleItem?.variant?.maxPerCart ||
              Number?.POSITIVE_INFINITY) ? (
              <FormattedMessage defaultMessage="Quantity exceeded" />
            ) : (
              <FormattedMessage
                defaultMessage="Minimum quantity per order {min}. You can add up to {max} per order"
                values={{
                  min:
                    typedSimpleItem?.variant?.minPerCart ||
                    Number.POSITIVE_INFINITY,
                  max:
                    typedSimpleItem?.variant?.maxPerCart ||
                    Number.POSITIVE_INFINITY,
                }}
              />
            )
          }
          closeAction={() => setIsAlertOpen(false)}
        />
      )}

      {itemErrors?.map((itemError, i) => (
        <Alert
          key={i}
          type={itemError.code === ErrorCodes.PriceChanged ? "warn" : undefined}
          message={itemError.message}
        />
      ))}
    </ItemHolder>
  );
};

export default CartItemMobile;

// Styles

export const ItemHolder = styled(FlexCol)`
  padding: 12px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.bg.wash}1a;
  }
`;

const PriceWrapper = styled(FlexRow)<{ isNotActive: boolean }>`
  opacity: ${({ isNotActive }) => isNotActive && 0.5};
  span {
    font-size: 14px;
    font-weight: 600;
  }
`;
