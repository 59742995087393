import type { ModifiedTemplate } from "../default";
import Shop from "./pages/Shop";
import Search from "./pages/Search";
import Collection from "./pages/Collection";
import Cart from "./pages/Cart";
import Hero from "./elements/Hero";
import GridGallerySection from "./elements/GridGallerySection";
import HomeGallerySection from "./elements/HomeGallerySection";
import SectionCardWrapper from "./elements/SectionCardWrapper";
import SectionHeading from "./elements/SectionHeading";
import CollectionCard from "./components/HomeGallerySection/cards/CollectionCard";
import ProductCard from "./components/HomeGallerySection/cards/ProductCard";
import AttributeValueCard from "./components/HomeGallerySection/cards/AttributeValueCard";
import BannerCard from "./components/HomeGallerySection/cards/BannerCard";
import ProductsList from "./elements/ProductsList";
import FilterAndSort from "./elements/FilterAndSort";
import SimpleProduct from "./elements/SimpleProduct";
import ProductGallery from "./elements/ProductGallery";
import ProductDetails from "./elements/ProductDetails";
import ContactElement from "./elements/Contact";
import PageHeader from "./elements/PageHeader";
import SideCart from "./elements/SideCart";
import CartTable from "./elements/CartTable";
import CartItem from "./elements/CartItem";
import ProfileLayout from "./elements/CustomerProfile/ProfileLayout";
import UserAccountElement from "./elements/CustomerProfile/UserAccount";
import UserAddressesElement from "./elements/CustomerProfile/UserAddresses";
import UserOrdersElement from "./elements/CustomerProfile/OrderHistory";
import UserOrderElement from "./elements/CustomerProfile/OrderDetails";
import UserReviewsElement from "./elements/CustomerProfile/UserReviews";
import Coupon from "./elements/Coupon";
import MobileNavigation from "./elements/MobileNavigation";
import PageNotFoundError from "./elements/Error/PageNotFound";
import UnknownError from "./elements/Error/UnknownError";
import SectionContent from "./elements/SectionContent";
import DiscountsBreakdown from "./elements/DiscountsBreakdown";
import LoginForm from "./elements/Authentication/LoginForm";
import RegistrationForm from "./elements/Authentication/RegistrationForm";
import ResetPasswordForm from "./elements/Authentication/ResetPasswordForm";
import ForgotPasswordForm from "./elements/Authentication/ForgotPasswordForm";
import NavBar from "./elements/NavBar";
import DesktopMenu from "./elements/DesktopMenu";

const blackAndWhite: ModifiedTemplate = {
  pages: {
    Shop,
    Search,
    Collection,
    Cart,
  },
  elements: {
    // HOME
    Hero,
    SectionCardWrapper,
    SectionHeading,
    HomeGallerySection,
    GridGallerySection,
    // SHARED
    SectionContent,
    CollectionCard,
    ProductCard,
    BannerCard,
    ProductsList,
    AttributeValueCard,
    FilterAndSort,
    Coupon,
    MobileNavigation,
    DiscountsBreakdown,
    DesktopMenu,
    // NAV
    NavBar,
    // CART
    CartTable,
    CartItem,
    SideCart,
    // SIMPLE PRODUCT
    SimpleProduct,
    ProductGallery,
    ProductDetails,
    ContactElement,
    PageHeader,
    // CUSTOMER PROFILE
    ProfileLayout,
    UserAccountElement,
    UserAddressesElement,
    UserOrderElement,
    UserOrdersElement,
    UserReviewsElement,
    // ERROR
    PageNotFoundError,
    UnknownError,
    // AUTHENTICATION
    LoginForm,
    RegistrationForm,
    ResetPasswordForm,
    ForgotPasswordForm,
  },
};

export default blackAndWhite;
