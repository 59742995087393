import styled from "styled-components";
import { H1 } from "../../../../shared/globals";
import HTMLRender from "../../../../shared/globals/UiElements/HTMLRender";
import { ProductDetailsProps } from "../../../types";
import { FormattedMessage } from "react-intl";

export const ProductOverview: React.FC<
  Omit<ProductDetailsProps, "attributes" | "tabs">
> = ({ title, description }) => {
  return (
    <ItemDetails>
      {description ? (
        <ItemHeading>
          <FormattedMessage defaultMessage="Description" />
        </ItemHeading>
      ) : (
        <ItemHeading>{title}</ItemHeading>
      )}
      <DetailsText html={description} />
    </ItemDetails>
  );
};

const ItemDetails = styled.div`
  padding: 40px 0;
`;

const ItemHeading = styled(H1)`
  font-size: 20px;
  color: #252a31;
  font-weight: 700;
`;

const DetailsText = styled(HTMLRender)`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${({ theme: { text } }) => text.inkLight};
`;
