import React from "react";

export const BillingAddressIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16 21.0004H18C19.6569 21.0004 21 19.6572 21 18.0004V7.90971C21 7.11406 20.6839 6.351 20.1213 5.78839L17.612 3.27908C17.0494 2.71647 16.2863 2.4004 15.4907 2.40039H8C6.34315 2.40039 5 3.74354 5 5.40039V9.00039"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 8.00039H17C15.8954 8.00039 15 7.10496 15 6.00039V2.40039"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="3"
        y="12"
        width="10"
        height="9"
        rx="2.22222"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.5 12V14.5C9.5 14.7761 9.27614 15 9 15H7C6.72386 15 6.5 14.7761 6.5 14.5V12"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
