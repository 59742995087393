import { Section } from "../../../shared/layout";
import { StoreTemplate } from "../../TemplateLoader";
import type { LoginPageProps, TemplateElement } from "../../types";
import { Card } from "../../../shared/globals/UiElements/Card";

const Login: TemplateElement<LoginPageProps> = () => {
  const Template = StoreTemplate.get();
  return (
    <div className="py-12">
      <Section>
        <Card paddingSize="xl" className="mx-auto md:max-w-[500px]">
          {/* @ts-expect-error: should expect LoginFormProps to be required but handled in its renderer */}
          <Template.elements.LoginForm />
        </Card>
      </Section>
    </div>
  );
};

export default Login;
