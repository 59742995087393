const Paypal = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="14"
      fill="none"
      viewBox="0 0 57 14"
    >
      <path
        fill="#009EE3"
        fillRule="evenodd"
        d="M4.947 3.233H8.82c2.08 0 2.863 1.053 2.742 2.6C11.363 8.387 9.819 9.8 7.77 9.8H6.737c-.281 0-.47.186-.546.69l-.44 2.93c-.028.19-.128.3-.278.315H3.039c-.23 0-.31-.175-.25-.554l1.484-9.393c.058-.376.264-.555.674-.555z"
        clipRule="evenodd"
      />
      <path
        fill="#113984"
        fillRule="evenodd"
        d="M21.766 3.059c1.307 0 2.513.709 2.348 2.476-.2 2.1-1.325 3.262-3.1 3.267h-1.55c-.224 0-.332.182-.39.555l-.3 1.907c-.045.288-.193.43-.41.43H16.92c-.23 0-.31-.147-.259-.476l1.191-7.643c.06-.376.2-.516.457-.516H21.766zm-2.35 4.092h1.175c.735-.028 1.223-.537 1.272-1.455.03-.567-.353-.973-.962-.97l-1.106.005-.379 2.42zm8.621 3.958c.132-.12.266-.182.247-.034l-.047.354c-.024.185.05.283.221.283h1.282c.216 0 .321-.087.374-.421l.79-4.958c.04-.249-.02-.371-.21-.371h-1.41c-.127 0-.189.071-.222.265l-.052.305c-.027.159-.1.187-.168.027-.239-.566-.849-.82-1.7-.8-1.977.041-3.31 1.542-3.453 3.466-.11 1.488.956 2.657 2.362 2.657 1.02 0 1.476-.3 1.99-.77l-.004-.003zm-1.074-.763c-.85 0-1.444-.679-1.32-1.511.122-.832.918-1.511 1.77-1.511.85 0 1.443.679 1.32 1.511-.123.832-.918 1.511-1.77 1.511zm6.45-4.4h-1.3c-.268 0-.377.2-.292.446l1.614 4.726-1.583 2.249c-.133.188-.03.359.157.359h1.461c.174.02.344-.063.434-.213l4.964-7.12c.153-.219.081-.449-.17-.449h-1.383c-.237 0-.332.094-.468.291l-2.07 3-.925-3.007c-.054-.182-.189-.282-.438-.282z"
        clipRule="evenodd"
      />
      <path
        fill="#009EE3"
        fillRule="evenodd"
        d="M44.032 3.059c1.307 0 2.513.709 2.348 2.476-.2 2.1-1.325 3.262-3.1 3.267h-1.55c-.223 0-.331.182-.39.555l-.3 1.907c-.044.288-.192.43-.41.43h-1.443c-.23 0-.31-.147-.26-.476l1.194-7.645c.059-.376.2-.516.457-.516h3.454v.002zm-2.35 4.092h1.175c.735-.028 1.223-.537 1.272-1.455.03-.567-.353-.973-.962-.97l-1.106.005-.38 2.42zm8.62 3.958c.133-.12.267-.182.248-.034l-.047.354c-.024.185.049.283.22.283h1.283c.216 0 .32-.087.374-.421l.79-4.958c.04-.249-.021-.371-.21-.371h-1.408c-.127 0-.19.071-.222.265l-.052.305c-.027.159-.1.187-.168.027-.24-.566-.85-.82-1.7-.8-1.977.041-3.31 1.542-3.453 3.466-.11 1.488.956 2.657 2.362 2.657 1.02 0 1.476-.3 1.99-.77l-.006-.003zm-1.072-.763c-.851 0-1.444-.679-1.321-1.511.123-.832.919-1.511 1.77-1.511.85 0 1.444.679 1.32 1.511-.122.832-.918 1.511-1.77 1.511zm5.913 1.374h-1.48c-.052.002-.103-.019-.138-.057-.035-.04-.05-.091-.043-.143l1.3-8.236c.025-.116.127-.199.246-.2h1.48c.052-.002.102.019.137.057.035.04.051.091.044.143l-1.3 8.236c-.025.117-.127.2-.246.202v-.002z"
        clipRule="evenodd"
      />
      <path
        fill="#113984"
        fillRule="evenodd"
        d="M2.524 0h3.878C7.494 0 8.79.035 9.656.8c.579.511.883 1.324.813 2.2-.238 2.961-2.01 4.62-4.385 4.62H4.172c-.326 0-.541.216-.633.8l-.534 3.4c-.035.22-.13.35-.3.366H.312c-.265 0-.36-.2-.29-.642L1.742.646C1.81.208 2.052 0 2.524 0z"
        clipRule="evenodd"
      />
      <path
        fill="#172C70"
        fillRule="evenodd"
        d="M3.595 8.075l.677-4.287c.059-.376.265-.556.675-.556H8.82c.64 0 1.16.1 1.566.285-.39 2.636-2.094 4.1-4.326 4.1H4.15c-.255.001-.443.129-.556.458z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export { Paypal };
