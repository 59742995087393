import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import useBreakPoints from "../../../shared/utils/useBreakPoints";
import { FlexCol, H4 } from "../../../shared/globals";
import { CartTableProps } from "../../types";

const CartTable = ({ renderCartItems }: CartTableProps) => {
  const { isTablet } = useBreakPoints();

  return (
    <>
      {isTablet ? (
        <MobileContainer fullWidth>{renderCartItems!()}</MobileContainer>
      ) : (
        <StyledTable>
          <thead>
            <tr>
              <StyledTH>
                <H4 fontWeight={600}>
                  <FormattedMessage defaultMessage="Products" />
                </H4>
              </StyledTH>
              <StyledTH>
                <H4 fontWeight={600}>
                  <FormattedMessage defaultMessage="Price" />
                </H4>
              </StyledTH>
              <StyledTH>
                <H4 fontWeight={600}>
                  <FormattedMessage defaultMessage="Quantity" />
                </H4>
              </StyledTH>
              <StyledTH>
                <H4 fontWeight={600}>
                  <FormattedMessage defaultMessage="Subtotal" />
                </H4>
              </StyledTH>
              <StyledTH></StyledTH>
            </tr>
          </thead>
          <tbody>{renderCartItems!()}</tbody>
        </StyledTable>
      )}
    </>
  );
};

export default CartTable;

// Styles

const MobileContainer = styled(FlexCol)`
  border: 1px solid ${({ theme }) => theme.bg.wash}1a;
  border-radius: 8px;
`;

const StyledTable = styled.table`
  display: none;
  width: 100%;
  @media (min-width: 768px) {
    display: table;
    flex: 1 1 75%;
    height: fit-content;
    border: 1px solid ${({ theme }) => theme.bg.border};
    border-radius: 8px;
    border-spacing: 0;
  }
  > thead {
    background-color: ${({ theme }) => theme.bg.reverse};
  }
`;

export const StyledTH = styled.th`
  text-align: start;
  padding: 20px 2px;
  :first-of-type {
    border-top-left-radius: 7px;
    padding-inline-start: 20px;
  }
  :last-of-type {
    border-top-right-radius: 7px;
    padding-inline-end: 20px;
  }
`;
