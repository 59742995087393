import * as React from "react";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { BarTracker } from "../../../components/ProgressTracker/BarTracker";
import { TrialMessage } from "../../../components/UtilityComponents/TrialNotice";
import {
  HideOnMobile,
  Container,
  Header,
} from "../../../components/Checkout/styled";
import { StoreTemplate } from "../../TemplateLoader";
import MobileSubHeader from "../../../components/Checkout/MobileSubHeader";
import { LargeScreensCheckoutCart } from "../../../components/Checkout/CheckoutCart";
import { FlexCol, FlexRow, H5 } from "../../../shared/globals";
import styled from "styled-components";
import { rtl } from "../../../shared/styles-utils";
import ClientComponent from "../../../shared/globals/UiElements/ClientComponent";
import { useStore } from "../../../lib/storeData";
import {
  CartStepEnum,
  OrderStatusEnum,
  useCartLazyQuery,
  useGetOrderByIdLazyQuery,
  useGetOrderByCartIdLazyQuery,
} from "../../../generated/graphql";
import { useRouter } from "../../../lib/i18n";
import { useCartData } from "../../../lib/cartData/useCartData";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import { resetRecoveryCart } from "../../../lib/cartData/reducer/actionTypes";
import {
  fireConfirmationEvent,
  firePaymentEvent,
  firePurchaseEvent,
  fireUseCouponEvent,
} from "../../../components/Checkout/Confirmation/components/utils";
import { useUpdateEffect } from "../../../hooks/useUpdateEffect";
import { defaultValue } from "../../../lib/cartData";
import { v4 as uuidv4 } from "uuid";

const TEMP_SESSION_ID = `Session_${uuidv4()}`;

const CheckoutLayout = (page: React.ReactNode) => {
  const Template = StoreTemplate.get();
  const { subscription, storeId, name: storeName } = useStore();
  const router = useRouter();
  const { query, asPath, pathname } = router;
  const { cart, updateCart, updateRecoveryCart, setCartEmptyPopUpState } =
    useCartData();

  const queryCartId = query?.cartId as string;

  const cartIdPrefix = queryCartId?.split("_")[0];

  const cartId = queryCartId?.split("_")[1];

  const cartData = cart.recoveryCart ?? cart;

  const { sessionId } = parseCookies();
  const [RecoveryCartQuery, { loading, error }] = useCartLazyQuery({
    variables: {
      storeId: storeId,
      cartId:
        cartIdPrefix === "recoverycart" ? "RecoveryCart_" + cartId : cart?.id,
    },
    context: {
      headers: { sessionId: sessionId || TEMP_SESSION_ID },
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCookie(
        null,
        "recoverySessionId",
        data?.customerQueries?.cart?.sessionId!,
        {
          maxAge: 30 * 24 * 60 * 60,
          path: "/",
        }
      );
      updateRecoveryCart(data?.customerQueries?.cart!);
      if (data?.customerQueries?.cart?.items?.length === 0) {
        setCartEmptyPopUpState(true);
      }
      if (data?.customerQueries?.cart?.status === "RECOVERED") {
        router.push(
          `/checkout/confirmation/${data?.customerQueries?.cart?.id}`
        );
      }
    },
    onError: () => {
      if (!query.paymentId) router.push("/");
    },
  });
  const [
    getOrderByOrderId,
    { data: orderDataByOrderId, loading: orderDataLoading },
  ] = useGetOrderByIdLazyQuery({
    variables: { storeId, orderId: queryCartId as string },
    fetchPolicy: "no-cache",
    onError: () => {
      if (!query.paymentId) router.push("/");
    },
  });

  const [getCartData, { data: cartQueryData, loading: cartDataLoading }] =
    useCartLazyQuery({
      variables: {
        storeId: storeId,
        cartId:
          cartIdPrefix === "recoverycart"
            ? "RecoveryCart_" + cartId
            : "Cart_" + cartId,
      },
      context: {
        headers: { sessionId },
      },
      fetchPolicy: "no-cache",
    });

  const [
    getOrderByCartIdPolling,
    {
      data: orderDataByCartId,
      loading: orderDataByCartIdPollingLoading,
      stopPolling,
    },
  ] = useGetOrderByCartIdLazyQuery({
    context: {
      headers: { sessionId },
    },
    fetchPolicy: "no-cache",
    pollInterval: 1000,
    onCompleted: (data) => {
      if (!data.orderByCart) {
        getCartData();
      }

      if (data.orderByCart?.status === OrderStatusEnum.Successful) {
        stopPolling();
      }
    },
    onError: () => {
      stopPolling();
    },
  });

  const [getOrderByCartId, { loading: orderDataByCartIdLoading }] =
    useGetOrderByCartIdLazyQuery({
      context: {
        headers: { sessionId },
      },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data.orderByCart?.status === OrderStatusEnum.Successful) {
          router.push(`/checkout/confirmation/${data?.orderByCart?.id}`);
        }
      },
    });
  useEffect(() => {
    if (pathname.includes("/confirmation/") && cartIdPrefix === "Cart") {
      getOrderByCartId({
        variables: { storeId, cartId: queryCartId },
      });
      getOrderByCartIdPolling({
        variables: { storeId, cartId: queryCartId },
      });
    }
    if (cartIdPrefix === "RecoveryCart") {
      router.replace(asPath.toLowerCase());
      return;
    }
    if (cartIdPrefix !== "recoverycart") resetRecoveryCart();

    if (cartIdPrefix === "Order") {
      getOrderByOrderId();
    }
    const queryPaymentId = query?.paymentId as string;
    const paymentPrefix = queryPaymentId && queryPaymentId.split("_")[0]!;
    if (
      cartIdPrefix !== "recoverycart" &&
      cartIdPrefix !== "Cart" &&
      cartIdPrefix !== "Order" &&
      paymentPrefix !== "PaymentIntent"
    ) {
      router.push("/");
    }

    if (cartIdPrefix === "recoverycart") {
      RecoveryCartQuery();
    } else {
      destroyCookie(null, "recoverySessionId", {
        maxAge: -1,
        path: "/",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCartId, pathname]);

  useUpdateEffect(() => {
    const orderData =
      orderDataByCartId?.orderByCart ?? orderDataByOrderId?.order;

    if (
      !orderData ||
      new Date().getTime() - new Date(orderData?.createdAt).getTime() > 10000 ||
      orderData?.status !== OrderStatusEnum.Successful ||
      cartData.lastStep !== CartStepEnum.Completed
    ) {
      return;
    }
    fireConfirmationEvent();
    firePaymentEvent(orderData);
    firePurchaseEvent(orderData, storeName);
    if (orderData?.promoCodeSnapshot?.code) {
      fireUseCouponEvent(orderData);
    }
    updateCart(defaultValue);
  }, [orderDataByCartId, orderDataByOrderId]);

  return (
    !loading &&
    !error && (
      <ClientComponent>
        <Container>
          <Header>
            <Template.elements.Logo />

            <HideOnMobile>
              <BarTracker />
            </HideOnMobile>
          </Header>
          <TrialMessage />
          <MobileSubHeader
            cartConfirmationData={cartQueryData?.customerQueries?.cart!}
            orderDetails={orderDataByOrderId}
          />
          <BodyContent>
            <BodyLayout>
              {React.cloneElement(page as React.ReactElement, {
                orderDetails: {
                  orderDataByOrderId: orderDataByOrderId,
                  orderDataByCartId: orderDataByCartId,
                  orderDataLoading: orderDataLoading,
                  cartConfirmationData: cartQueryData?.customerQueries?.cart,
                  cartConfirmationDataLoading: cartDataLoading,
                  orderDataByCartIdLoading: orderDataByCartIdLoading,
                  orderDataByCartIdPollingLoading:
                    orderDataByCartIdPollingLoading,
                },
              })}
              {!subscription && (
                <StyledH5>
                  <FormattedMessage
                    defaultMessage="© {year} All rights reserved — Powered by Wuilt"
                    values={{
                      year: new Date().getFullYear(),
                    }}
                  />
                </StyledH5>
              )}
            </BodyLayout>
            <LargeScreensCheckoutCart
              cartConfirmationData={cartQueryData?.customerQueries?.cart!}
              orderDetails={orderDataByOrderId}
            />
          </BodyContent>
        </Container>
      </ClientComponent>
    )
  );
};

export default CheckoutLayout;

/**
 * STYLES
 */

const BodyContent = styled(FlexRow)`
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;
`;

const BodyLayout = styled(FlexCol)`
  background-color: ${({ theme }) => theme.bg.reverse};
  width: 100%;
  padding: 30px 20px;

  @media (min-width: 768px) {
    width: 56%;
    padding: ${rtl("40px 165px 40px 45px", "40px 45px 40px 165px")};
    min-height: 100vh;
  }
`;

const StyledH5 = styled(H5)`
  font-weight: bold;
  color: ${({ theme }) => theme.text.secondary};
  width: 100%;
  margin-top: 40px;
  padding-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.bg.wash}1a;
`;
