import { FC } from "react";
import { useIntl } from "react-intl";
import { SectionHeadingProps } from "../../types";
import { Heading } from "../../../components/UtilityComponents/Heading";
import { getLocaleInfo } from "../../../lib/i18n/locales-data";

const SectionHeading: FC<SectionHeadingProps> = ({ section }) => {
  const { locale } = useIntl();
  const activeLocale = getLocaleInfo(locale).code;
  const translations = section?.translations?.find(
    (translation) => translation.locale === activeLocale
  );

  return section?.showSectionHeader ? (
    <Heading>{translations?.header || section?.header}</Heading>
  ) : null;
};

export default SectionHeading;
