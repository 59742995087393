const UserFilledIcon = () => {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.25" width="36" height="36" rx="18" fill="#F2F4F7" />
      <path
        d="M24.9166 24.6667V24.0417C24.9166 22.0857 23.3309 20.5 21.3749 20.5H15.1249C13.1689 20.5 11.5833 22.0857 11.5833 24.0417V24.6667"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="18.2501"
        cy="13.8333"
        rx="3.33333"
        ry="3.33333"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { UserFilledIcon };
