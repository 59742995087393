import Cart from "./pages/Cart";
import InfoBar from "./elements/InfoBar";
import NavBar from "./elements/NavBar";
import DesktopMenu from "./elements/DesktopMenu";
import Footer from "./elements/Footer";
import ProductCard from "./components/HomeGallerySection/cards/ProductCard";
import AttributeValueCard from "./components/HomeGallerySection/cards/AttributeValueCard";
import BannerCard from "./components/HomeGallerySection/cards/BannerCard";
import ProductsList from "./elements/ProductsList";
import Logo from "./elements/Logo";
import Hero from "./elements/Hero";
import HomeAbout from "./elements/HomeAbout";
import HomeContentSection from "./elements/HomeContentSection";
import HomeGallerySection from "./elements/HomeGallerySection";
import SimpleProduct from "./elements/SimpleProduct";
import ProductGallery from "./elements/ProductGallery";
import ProductDetails from "./elements/ProductDetails";
import CustomProduct from "./elements/CustomProduct";
import MobileCustomProduct from "./elements/MobileCustomProduct";
import DesktopCustomProduct from "./elements/DesktopCustomProduct";
import CategoriesSection from "./elements/CategoriesSection";
import VariantsSection from "./elements/VariantsSection";
import RatingAndReviews from "./elements/RatingAndReviews";
import SectionCardWrapper from "./elements/SectionCardWrapper";
import SectionHeading from "./elements/SectionHeading";
import GridGallerySection from "./elements/GridGallerySection";
import CollectionCard from "./components/HomeGallerySection/cards/CollectionCard";
import FilterAndSort from "./elements/FilterAndSort";
import ContactElement from "./elements/Contact";
import PageHeader from "./elements/PageHeader";
import SideCart from "./elements/SideCart";
import CartTable from "./elements/CartTable";
import CartItem from "./elements/CartItem";
import ProfileLayout from "./elements/CustomerProfile/ProfileLayout";
import UserAccountElement from "./elements/CustomerProfile/UserAccount";
import UserAddressesElement from "./elements/CustomerProfile/UserAddresses";
import UserOrdersElement from "./elements/CustomerProfile/OrderHistory";
import UserOrderElement from "./elements/CustomerProfile/OrderDetails";
import UserReviewsElement from "./elements/CustomerProfile/UserReviews";
import Coupon from "./elements/Coupon";
import MobileNavigation from "./elements/MobileNavigation";
import PageNotFoundError from "./elements/Error/PageNotFound";
import UnknownError from "./elements/Error/UnknownError";
import SectionContent from "./elements/SectionContent";
import DiscountsBreakdown from "./elements/DiscountsBreakdown";
import LoginForm from "./elements/Authentication/LoginForm";
import RegistrationForm from "./elements/Authentication/RegistrationForm";
import ResetPasswordForm from "./elements/Authentication/ResetPasswordForm";
import ForgotPasswordForm from "./elements/Authentication/ForgotPasswordForm";
import { ModifiedTemplate } from "../default";

const modernTemplate: ModifiedTemplate = {
  pages: {
    Cart,
  } as const,
  elements: {
    // SHARED
    SectionContent,
    InfoBar,
    NavBar,
    DesktopMenu,
    Footer,
    ProductCard,
    CollectionCard,
    BannerCard,
    AttributeValueCard,
    ProductsList,
    Logo,
    FilterAndSort,
    PageHeader,
    RatingAndReviews,
    Coupon,
    MobileNavigation,
    DiscountsBreakdown,
    // HOME
    Hero,
    HomeAbout,
    HomeContentSection,
    HomeGallerySection,
    SectionHeading,
    SectionCardWrapper,
    GridGallerySection,
    // CART
    CartTable,
    CartItem,
    SideCart,

    // SIMPLE PRODUCT
    SimpleProduct,
    ProductGallery,
    ProductDetails,
    // CUSTOM PRODUCT
    CustomProduct,
    DesktopCustomProduct,
    MobileCustomProduct,
    CategoriesSection,
    VariantsSection,
    // PAGE ELEMENTS
    ContactElement,
    // CUSTOMER PROFILE
    ProfileLayout,
    UserAccountElement,
    UserAddressesElement,
    UserOrderElement,
    UserOrdersElement,
    UserReviewsElement,
    // ERROR
    PageNotFoundError,
    UnknownError,
    // Authentication
    LoginForm,
    RegistrationForm,
    ResetPasswordForm,
    ForgotPasswordForm,
  } as const,
} as const;

export default modernTemplate;
