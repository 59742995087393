import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { HelpIcon, ShoppingCartPromotionIcon } from "../../../../assets/Icons";
import { StyledShippingDiscountBadge } from "../../../../components/Checkout/CheckoutCart/styled";
import { DefaultTextPrice } from "../../../../components/Price";
import { FlexRow, FlexCol, H4 } from "../../../../shared/globals";
import { StoreTemplate } from "../../../TemplateLoader";
import Tooltip from "../../../../shared/globals/UiElements/Tooltip";
import { useCartData } from "../../../../lib/cartData/useCartData";

export const CartCardSummary = () => {
  const intl = useIntl();
  const Template = StoreTemplate.get();

  const { cart } = useCartData();

  const { isLoading } = cart;

  const areDiscountsApplied = !!cart.receipt?.automaticDiscount?.amount;

  const isCouponDiscountApplied =
    !!cart?.promoCode?.percentageOff || !!cart?.promoCode?.fixedAmount;

  const isShippingDiscountApplies = cart?.shippingDiscount
    ? cart?.receipt?.subtotal?.amount >=
      cart?.shippingDiscount?.customerBuys?.value?.amount!
    : false;

  return (
    <FlexCol fullWidth alignItems="flex-start" spacing="l">
      <H4 fontWeight={600}>
        <FormattedMessage defaultMessage="Summary" />
      </H4>

      <FlexRow fullWidth justifyContent="space-between">
        <H4 color="secondary" fontWeight={400}>
          <FormattedMessage defaultMessage="Subtotal" />
        </H4>
        <H4 fontWeight={600}>
          {isLoading ? (
            <FormattedMessage defaultMessage="Calculating..." />
          ) : (
            <DefaultTextPrice money={cart?.receipt?.subtotal} />
          )}
        </H4>
      </FlexRow>

      {areDiscountsApplied && <Template.elements.DiscountsBreakdown />}

      {isCouponDiscountApplied && (
        <FlexRow fullWidth justifyContent="space-between">
          <H4 color="secondary" fontWeight={400}>
            <FormattedMessage defaultMessage="Coupon" />
          </H4>
          {cart?.promoCode?.percentageOff ? (
            <DiscountedPrice>
              -<DefaultTextPrice money={cart?.receipt?.discount} /> (-
              {cart?.promoCode?.percentageOff}%)
            </DiscountedPrice>
          ) : (
            <DiscountedPrice>
              -<DefaultTextPrice money={cart?.receipt?.discount} />
            </DiscountedPrice>
          )}
        </FlexRow>
      )}

      {!!cart?.receipt?.tax?.amount && (
        <FlexRow fullWidth justifyContent="space-between">
          <FlexRow spacing="xs">
            <H4 color="secondary" fontWeight={400}>
              <FormattedMessage defaultMessage="Estimated Taxes" />
            </H4>
            <Tooltip
              icon={<HelpIcon />}
              tooltipMessage={intl.formatMessage({
                defaultMessage:
                  "The final tax and total will be confirmed by email or text after you place your order.",
              })}
            />
          </FlexRow>
          <H4 fontWeight={600}>
            + <DefaultTextPrice money={cart?.receipt?.tax} />
          </H4>
        </FlexRow>
      )}

      <FlexRow alignItems="start" fullWidth justifyContent="space-between">
        <H4 color="secondary" fontWeight={400}>
          <FormattedMessage defaultMessage="Shipping" />
        </H4>
        {cart?.shippingRate?.cost ? (
          isShippingDiscountApplies ? (
            <FlexCol spacing="m" alignItems="end">
              <H4 fontWeight={600}>
                <DefaultTextPrice money={cart?.shippingRate?.cost} />
              </H4>
              <StyledShippingDiscountBadge>
                <ShoppingCartPromotionIcon />
                {cart?.shippingDiscount?.title}
              </StyledShippingDiscountBadge>
            </FlexCol>
          ) : (
            <H4 fontWeight={600}>
              <DefaultTextPrice money={cart?.shippingRate?.cost} />
            </H4>
          )
        ) : (
          <H4 fontWeight={600}>
            <FormattedMessage defaultMessage="To be calculated" />
          </H4>
        )}
      </FlexRow>
    </FlexCol>
  );
};

const DiscountedPrice = styled(H4)`
  color: ${({ theme }) => theme.success.medium};
  * {
    color: ${({ theme }) => theme.success.medium};
  }
`;
