import { useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { DownArrow } from "../../../../../assets/Icons";
import {
  ListCustomerReviewsQuery,
  ReviewStatus,
} from "../../../../../generated/graphql";
import { useStore } from "../../../../../lib/storeData";
import { FlexCol, FlexRow, H4, H5 } from "../../../../../shared/globals";
import { Card } from "../../../../../shared/globals/UiElements/Card";
import { Badge } from "../../../../../shared/globals/UiElements/Badge";
import StyledStarsRating from "../../../components/ReviewsAndRatings/StyledStarsRating";
import ReviewsMoreActions from "./ReviewsMoreActions";
import ItemImg from "../../../../../shared/globals/UiElements/ItemImg";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import { useAuth } from "../../../../../lib/Authentication";

const MAX_REVIEWS_PER_PAGE = 4;
const SHOW_MORE_REVIEW_LENGTH = 370;

interface ReviewsListProps {
  reviews: ListCustomerReviewsQuery["listCustomerReviews"];
}

const ReviewsList = ({ reviews }: ReviewsListProps) => {
  const { name: storeName } = useStore();
  const [extendedReviews, setExtendedReviews] = useState<boolean[]>(
    new Array(MAX_REVIEWS_PER_PAGE).fill(false)
  );
  const auth = useAuth();
  return (
    <FlexCol spacing="m" style={{ marginTop: 11 }}>
      {reviews?.map((review, index) => (
        <Card key={review.id}>
          <FlexCol alignItems="flex-start" spacing="xs">
            <Flex
              fullWidth
              justifyContent="space-between"
              spacing="m"
              alignItems="center"
            >
              <H4 fontSize="14px" fontWeight={600} margin={0}>
                {review.showName ? auth.user?.name : `${storeName} Shopper`}
              </H4>
              <Flex justifyContent="space-between">
                {review.status === ReviewStatus.Unpublished && (
                  <Badge
                    type="warn"
                    label={
                      <FormattedMessage defaultMessage="• Pending Approval" />
                    }
                    className="!bg-warning-50 !px-xs !border-warning-200 !text-warning-700 !rounded-full !text-xs"
                  />
                )}
                <ReviewsMoreActions review={review} />
              </Flex>
            </Flex>
            <div>
              <H5 color="secondary">
                <FormattedMessage defaultMessage="Reviewed on" />{" "}
                <FormattedDate
                  value={review.createdAt}
                  day="numeric"
                  month="short"
                  year="numeric"
                />
              </H5>
            </div>
            <div>
              <StyledStarsRating
                initialRatingValue={review.rating}
                isReadonly
                size={15}
              />
            </div>
            <H4 fontWeight="bold" margin="12px 0 0 0">
              {review.headline}
            </H4>
            <ExtendableText isExtended={extendedReviews[index]}>
              {review.content}
            </ExtendableText>
            {review.content?.length! > SHOW_MORE_REVIEW_LENGTH && (
              <ShowMoreSection
                spacing="none"
                isExtended={extendedReviews[index]}
              >
                <ShowMoreButton
                  onClick={() =>
                    setExtendedReviews(
                      extendedReviews.map((item, i) =>
                        i === index ? !item : item
                      )
                    )
                  }
                >
                  {extendedReviews[index] ? (
                    <FormattedMessage defaultMessage="Show less" />
                  ) : (
                    <FormattedMessage defaultMessage="Show more" />
                  )}
                </ShowMoreButton>
                <DownArrow />
              </ShowMoreSection>
            )}
            <FlexRow spacing="s" margin="12px 0 0 0">
              <ItemImg imgDetails={review?.product?.images[0]} size={40} />
              <H4 fontWeight={600} fontSize="12px">
                {review?.product?.title}
              </H4>
            </FlexRow>
          </FlexCol>
        </Card>
      ))}
    </FlexCol>
  );
};

export default ReviewsList;

const ExtendableText = styled.p<{ isExtended: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: ${({ isExtended }) => (isExtended ? "block" : "-webkit-box")};
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.secondary};
  margin: 0;
  font-size: 14px;
`;

const ShowMoreSection = styled(FlexRow)<{ isExtended: boolean }>`
  width: 100%;
  svg {
    transform: ${({ isExtended }) => (isExtended ? "rotate(180deg)" : "")};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ShowMoreButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
