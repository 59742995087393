import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { ApolloError } from "@apollo/client";
import { AvailableFilterChoices } from "./AvailableFilterChoices";
import { FilterByPrice } from "./FilterByPrice";
import { HyphenIcon, AddIcon } from "../../../../../assets/Icons";
import { useStore } from "../../../../../lib/storeData";
import { FlexCol, FlexRow } from "../../../../../shared/globals";
import { rtl, themeColor } from "../../../../../shared/styles-utils";
import { DefaultFilters } from "../../../../default/components/Filter/consts";
import { FiltersDictionary } from "../../../../../shared/utils/filterHelperFunctions";

interface FiltersSectionProps {
  availableFilters: FiltersDictionary;
  selectedFiltersIds: string[];
  selectedFilters: Map<string, boolean>;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<Map<string, boolean>>
  >;
  loading: boolean;
  error: ApolloError | undefined;
}

const FiltersSection = ({
  availableFilters,
  selectedFiltersIds,
  selectedFilters,
  setSelectedFilters,
  loading,
  error,
}: FiltersSectionProps) => {
  const { currency } = useStore();
  return (
    <FiltersContainer alignItems="start" fullWidth>
      {Object.keys(availableFilters || {}).map((key) => (
        <FlexCol key={key} alignItems="start" fullWidth>
          <StyledFilterRow
            onClick={() =>
              setSelectedFilters(
                (prev) => new Map(prev.set(key, !prev.get(key)))
              )
            }
          >
            {availableFilters![key][0]?.propertyName}
            <span style={{ flexShrink: 0 }}>
              {selectedFilters.get(key) ? <HyphenIcon /> : <AddIcon />}
            </span>
          </StyledFilterRow>

          {selectedFilters.get(key) && (
            <ScrollableSection>
              <AvailableFilterChoices
                availableFilters={availableFilters[key]}
                loading={loading}
                error={error!}
                selectedFilterIds={selectedFiltersIds}
              />
            </ScrollableSection>
          )}
        </FlexCol>
      ))}

      <FlexCol fullWidth>
        <StyledFilterRow
          onClick={() =>
            setSelectedFilters(
              (prev) =>
                new Map(
                  prev.set(
                    DefaultFilters.Price,
                    !prev.get(DefaultFilters.Price)
                  )
                )
            )
          }
        >
          <FlexRow>
            <FormattedMessage defaultMessage="Price" />{" "}
            <Currency>({currency})</Currency>
          </FlexRow>
          {selectedFilters.get(DefaultFilters.Price) ? (
            <HyphenIcon />
          ) : (
            <AddIcon />
          )}
        </StyledFilterRow>

        {selectedFilters.get(DefaultFilters.Price) && <FilterByPrice />}
      </FlexCol>
    </FiltersContainer>
  );
};

export default FiltersSection;

// Styles

const FiltersContainer = styled(FlexCol)`
  height: 100%;
  overflow-y: auto;
  margin-top: 20px;
  > * {
    margin-bottom: 26px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    background-color: ${({ theme }) => theme.bg.reverse};
    padding: 16px 0;
    margin-top: 0;
    > * {
      margin-bottom: 2px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const StyledFilterRow = styled(FlexRow)`
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  svg {
    ${rtl("transform: rotate(180deg)", "")}
  }
  @media (max-width: 768px) {
    background-color: ${themeColor("backgroundReverse")};
    padding: 16px 20px;
  }
`;

const ScrollableSection = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 150px;
  @media (max-width: 768px) {
    width: auto;
    max-height: unset;
    margin: 0 20px;
  }

  /* Set the width and height of the scroll bar track */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  /* Set the color of the scroll bar track */
  /* ::-webkit-scrollbar-track {
    background-color: #f2f2f2;
  } */
  /* Set the color and style of the scroll bar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
  /* Set the color and style of the scroll bar thumb when hovered */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

const Currency = styled.span`
  color: ${themeColor("secondary")};
  margin-inline-start: 4px;
`;
