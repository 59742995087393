import React from "react";
import { Photo } from "../../../../../shared/globals/UiElements/Photo";
import { Link } from "../../../../../lib/i18n";
import { AttributeValueCardProps } from "../../../../types";
import MotionElementWrapper from "../../../../../shared/globals/MotionElementWrapper";
import SectionCardTitle from "./SectionCardTitle";

const AttributeValueCard: React.FC<AttributeValueCardProps> = ({
  attributeValue,
  hideTitle,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <Link
      fullHeight
      fullWidth
      href={`/shop`}
      query={{
        page: 1,
        filters: `a:${attributeValue?.id}|${attributeValue?.attribute?.id}`,
      }}
    >
      <div
        className="w-full h-full"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <MotionElementWrapper
          fullWidth
          fullHeight
          animate={isHovered ? { scale: 1.1 } : { scale: 1 }}
          duration={0.5}
        >
          <Photo
            src={attributeValue?.image?.src || "/default-placeholder-image.png"}
            alt={attributeValue?.name!}
            objectFit="cover"
          />
        </MotionElementWrapper>
      </div>
      {!hideTitle && (
        <SectionCardTitle
          isHovered={isHovered}
          setIsHovered={setIsHovered}
          title={attributeValue?.name!}
        />
      )}
    </Link>
  );
};

export default AttributeValueCard;
