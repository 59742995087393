import React from "react";
import { BannerCardProps } from "../../../../types";
import { LinkType } from "../../../../../generated/graphql";
import { Photo } from "../../../../../shared/globals/UiElements/Photo";
import styled from "styled-components";
import useBreakPoints from "../../../../../shared/utils/useBreakPoints";
import { Link } from "../../../../../lib/i18n";
import { getLink } from "../../../../../shared/utils/getLink";

const BannerCard: React.FC<BannerCardProps> = ({ banner }) => {
  const { isTablet } = useBreakPoints();
  if (banner?.bannerLink?.type === LinkType.None)
    return <BannerImage banner={banner} isTablet={isTablet} />;

  return (
    <StyledLink
      fullHeight
      fullWidth
      href={getLink(
        banner?.bannerLink?.type,
        banner?.bannerLink?.resource?.handle
      )}
      absoluteHref={
        banner?.bannerLink?.type === LinkType.ExternalLink
          ? banner?.bannerLink?.url!
          : ""
      }
      target={
        banner?.bannerLink?.type === LinkType.ExternalLink ? "_blank" : "_self"
      }
    >
      <Photo
        src={
          (isTablet
            ? banner?.mobileImage?.src || banner?.image?.src
            : banner?.image?.src || banner?.mobileImage?.src) ||
          "/default-placeholder-image.png"
        }
        objectFit="contain"
      />
    </StyledLink>
  );
};

interface BannerImageProps {
  banner: BannerCardProps["banner"];
  isTablet: boolean;
}

const BannerImage = ({ banner, isTablet }: BannerImageProps) => (
  <Photo
    src={
      (isTablet
        ? banner?.mobileImage?.src || banner?.image?.src
        : banner?.image?.src || banner?.mobileImage?.src) ||
      "/default-placeholder-image.png"
    }
    objectFit="contain"
  />
);

export default BannerCard;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;
