import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import styled from "styled-components";
import { CorrectMarkIcon } from "../../../assets/Icons";
import { Badge } from "../../../components/Badge/Badge";
import { PrimaryButton } from "../../../components/Button";
import { DiscountPrice } from "../../../components/Price";
import { QuantityInput } from "../components/QuantityInput";
import { P } from "../../../shared/globals";
import { StoreTemplate } from "../../TemplateLoader";
import type { SimpleProductProps, TemplateElement } from "../../types";
import Alert from "../../../shared/globals/UiElements/Alert";
import { useCart } from "../../../contexts/CartContext";
import { useStore } from "../../../lib/storeData";
import { BuyNowButtonDisplay } from "../../../generated/graphql";
import BuyNowButton from "../../../components/BuyNowButton/BuyNowButton";
import ProductRatingSummary from "../../default/components/ReviewsAndRatings/ProductRatingSummary";
import { getSalePercentage } from "../components/ProductCardPrice";
import { PriceBase } from "../../../components/Price/Price";
import AlsoLike from "../components/AlsoLike";

const SimpleProduct: TemplateElement<SimpleProductProps> = ({
  selectedVariant,
  product,
  availableQuantity,
  isAlertOpen,
  quantity,
  handleSelect,
  setQuantity,
  handleAddToCart,
  setIsAlertOpen,
  tabs,
  isCartLoading,
  isOutOfStock,
}) => {
  const Template = StoreTemplate.get();
  const { cart } = useCart();
  const {
    areReviewsActivated,
    appearance,
    currency: currencyCode,
  } = useStore();

  const item = cart.items?.find(
    (item) => item?.variantId === selectedVariant?.id
  );

  const disableButton = () => {
    if (isOutOfStock) return true;
    if (availableQuantity?.type === "cart") {
      if (item) {
        return item?.quantity! < availableQuantity?.min!;
      }
      return quantity! < availableQuantity?.min!;
    }
    return false;
  };

  return (
    <>
      <ProductWrapper>
        <Template.elements.ProductGallery
          selectedId={selectedVariant?.image?.id}
          images={product?.images!}
        />
        <StyledProduct>
          <StyledBadge
            outOfStock={
              availableQuantity?.type === "stock" &&
              availableQuantity?.max === 0
            }
            onSale={
              !!selectedVariant?.compareAtPrice &&
              selectedVariant?.price?.amount !== 0
            }
          />

          <ProductHead>
            {product?.title}
            {areReviewsActivated && (
              <ProductRatingSummary
                reviewsStatistics={product?.reviewsStatistics}
              />
            )}
          </ProductHead>

          <PricingWrapper>
            {selectedVariant?.price?.amount === 0 ? (
              <FreeText>
                <FormattedMessage defaultMessage="Free" />
              </FreeText>
            ) : selectedVariant?.compareAtPrice ? (
              <SalePriceWrapper>
                <SaveText>
                  <FormattedMessage defaultMessage="Save" />{" "}
                  <PriceBase
                    money={{
                      amount:
                        selectedVariant?.compareAtPrice.amount! -
                        selectedVariant.price.amount!,
                      currencyCode,
                    }}
                  />
                  {" ("}
                  <FormattedNumber
                    value={getSalePercentage(
                      selectedVariant?.compareAtPrice.amount,
                      selectedVariant.price.amount
                    )}
                    minimumFractionDigits={1}
                    style="percent"
                  />
                  {")"}
                </SaveText>
                <BeforeAndAfterPriceWrapper>
                  <StyledPrice money={selectedVariant?.price} />
                  <StyledDiscountPrice
                    money={selectedVariant?.compareAtPrice}
                    fontSize="18px"
                  />
                </BeforeAndAfterPriceWrapper>
              </SalePriceWrapper>
            ) : (
              <StyledPrice money={selectedVariant?.price} />
            )}
          </PricingWrapper>

          {product?.options?.map((option, optionIndex) => (
            <div key={option?.name! + optionIndex}>
              <OptionHeader>
                {option?.name} :{" "}
                {selectedVariant?.selectedOptions?.[optionIndex]?.value.name}
              </OptionHeader>
              <OptionValuesWrapper>
                {option?.values?.map((value) => (
                  <OptionValueContainer key={value.id}>
                    <OptionCircle onClick={handleSelect?.(value, optionIndex)}>
                      {selectedVariant?.selectedOptions?.[optionIndex]?.value
                        ?.id === value.id && <CorrectMarkIcon />}
                    </OptionCircle>
                    <OptionValue>{value.name}</OptionValue>
                  </OptionValueContainer>
                ))}
              </OptionValuesWrapper>
            </div>
          ))}
          {selectedVariant?.sku ? (
            <P style={{ fontStyle: "italic" }}>
              <FormattedMessage
                defaultMessage="SKU: {sku}"
                values={{ sku: selectedVariant?.sku }}
              />
            </P>
          ) : null}

          {availableQuantity?.type === "cart" &&
            availableQuantity?.min! > 1 && (
              <Alert
                message={
                  <FormattedMessage
                    defaultMessage="Minimum quantity per order {min}. You can add up to {max} per order"
                    values={{
                      min: availableQuantity?.min,
                      max: availableQuantity?.max,
                    }}
                  />
                }
                style={{ marginBottom: 6 }}
              />
            )}
          <AddToCartWrapper
            className={
              availableQuantity?.type === "stock" &&
              availableQuantity?.max === 0
                ? "dimmed"
                : ""
            }
            isBuyNowButtonVisible={
              !!appearance?.productDisplay?.buyNowButton?.display &&
              appearance?.productDisplay?.buyNowButton?.display !==
                BuyNowButtonDisplay.None
            }
          >
            <QuantityInput
              max={availableQuantity?.max}
              value={quantity!}
              onChange={(value) => setQuantity?.(value)}
              disabled={isCartLoading}
            />
            <StyledPrimaryButton
              fullWidth
              onClick={() => handleAddToCart?.()}
              disabled={disableButton() || isCartLoading}
              isLoading={isCartLoading}
            >
              <FormattedMessage defaultMessage="Add to cart" />
            </StyledPrimaryButton>
            {appearance?.productDisplay?.buyNowButton?.display &&
              appearance?.productDisplay?.buyNowButton?.display !==
                BuyNowButtonDisplay.None && (
                <StyledBuyNowButton
                  isDisabled={
                    disableButton() ||
                    quantity! < availableQuantity?.min! ||
                    isCartLoading
                  }
                  isAlwaysVisible
                  product={product!}
                  quantity={quantity}
                />
              )}
          </AddToCartWrapper>
          {isAlertOpen && (
            <Alert
              message={
                availableQuantity?.type === "stock" ? (
                  <FormattedMessage defaultMessage="Quantity exceeded" />
                ) : (
                  <FormattedMessage
                    defaultMessage="You can only add up to {max} of this product to cart"
                    values={{ max: availableQuantity?.max }}
                  />
                )
              }
              closeAction={() => setIsAlertOpen!(false)}
            />
          )}
        </StyledProduct>
      </ProductWrapper>
      <Template.elements.ProductDetails
        options={product?.options!}
        title={product?.title!}
        description={product?.descriptionHtml}
        attributes={product?.attributes}
        tabs={tabs!}
      />
      {areReviewsActivated && (
        <Template.elements.RatingAndReviews
          product={{
            id: product?.id!,
            title: product?.title!,
            images: product?.images!,
          }}
        />
      )}
      <AlsoLike
        isRandom={product?.moreProducts.isRandom!}
        products={product?.moreProducts.products!}
        numberOfProducts={4}
      />
    </>
  );
};

export default SimpleProduct;

/**
 *
 *
 * Styles
 *
 */

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const StyledProduct = styled.div`
  flex: 1 1 50%;
  margin-bottom: 40px;
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const ProductHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const FreeText = styled.span`
  font-size: 24px;
  font-weight: 600;
`;

const SalePriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

const SaveText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #cc0905;
  margin-bottom: 8px;
`;

const BeforeAndAfterPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 18px;
`;

const StyledPrice = styled(PriceBase)`
  font-size: 18px;
  font-weight: 600;
  color: #000;
`;

const StyledDiscountPrice = styled(DiscountPrice)`
  font-size: 18px;
  font-weight: 400;
  color: #a5a5a5;
`;

const OptionHeader = styled(P)`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.default};
  margin-bottom: 8px;
  text-transform: capitalize;
`;

const OptionValuesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  gap: 10px;
`;

const OptionValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
`;

const OptionValue = styled.span`
  font-size: 14px;
  letter-spacing: -0.2px;
  font-weight: 400;
  color: #555;
  color: ${({ theme }) => theme.text.default};
`;

const OptionCircle = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  background: #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #555;
`;

const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const AddToCartWrapper = styled.div<{
  isBuyNowButtonVisible: boolean;
}>`
  display: flex;
  flex-direction: ${({ isBuyNowButtonVisible }) =>
    isBuyNowButtonVisible ? "column" : "row"};
  gap: 10px;
  justify-content: flex-start;
  max-width: ${({ isBuyNowButtonVisible }) =>
    isBuyNowButtonVisible ? "100%" : "430px"};

  &.dimmed {
    opacity: 0.5;
    pointer-events: none;
  }

  @media (min-width: 480px) {
    flex-direction: row;
  }
`;

const StyledBadge = styled(Badge)`
  background-color: #cc0905;
  color: white;
  svg {
    fill: white;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  max-width: 100%;
  @media (min-width: 480px) {
    max-width: 200px;
  }
`;

const StyledBuyNowButton = styled(BuyNowButton)`
  max-width: 100%;
  margin: 0;
  @media (min-width: 480px) {
    max-width: 200px;
  }
`;
