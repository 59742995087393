const CheckCircle = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM15.707 10.707C15.8892 10.5184 15.99 10.2658 15.9877 10.0036C15.9854 9.7414 15.8802 9.49059 15.6948 9.30518C15.5094 9.11977 15.2586 9.0146 14.9964 9.01233C14.7342 9.01005 14.4816 9.11084 14.293 9.293L11 12.586L9.707 11.293C9.5184 11.1108 9.2658 11.01 9.0036 11.0123C8.7414 11.0146 8.49059 11.1198 8.30518 11.3052C8.11977 11.4906 8.0146 11.7414 8.01233 12.0036C8.01005 12.2658 8.11084 12.5184 8.293 12.707L10.293 14.707C10.4805 14.8945 10.7348 14.9998 11 14.9998C11.2652 14.9998 11.5195 14.8945 11.707 14.707L15.707 10.707Z"
      fill="#42AB44"
    />
  </svg>
);

export { CheckCircle };
