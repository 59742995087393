import React from "react";

export const PaymentStatusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.3033 4.6967C18.2322 7.62563 18.2322 12.3744 15.3033 15.3033C12.3743 18.2322 7.62562 18.2322 4.6967 15.3033C1.76777 12.3743 1.76777 7.62562 4.6967 4.6967C7.62563 1.76777 12.3744 1.76777 15.3033 4.6967"
        stroke="black"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.2497 12.0833C9.63884 12.0833 8.33301 10.7775 8.33301 9.16667C8.33301 7.55583 9.63884 6.25 11.2497 6.25C12.8613 6.25 14.1663 7.55583 14.1663 9.16667C14.1663 10.7775 12.8613 12.0833 11.2497 12.0833"
        stroke="black"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.60801 7.93164C7.06467 8.00664 5.83301 9.27164 5.83301 10.8333C5.83301 12.4441 7.13884 13.75 8.74967 13.75C9.91384 13.75 10.9105 13.0633 11.3788 12.0766"
        stroke="black"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
