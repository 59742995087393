import React from "react";
import type { ModifiedTemplate } from "../templates/default";
import type { RendererComponent } from "./types";
import Footer from "./FooterRenderer";
import ProductCard from "./ProductCardRenderer";
import NavBar from "./NavBarRenderer";
import SimpleProduct from "./SimpleProductRenderer";
import CustomProduct from "./CustomProductRenderer";
import VariantsSection from "./VariantsSectionRenderer";
import Hero from "./HeroRenderer";
import CartTable from "./CartTableRenderer";
import Coupon from "./CouponRenderer";
import DiscountsBreakdown from "./DiscountsBreakdownRenderer";
import LoginForm from "./LoginFormRenderer";
import RegistrationForm from "./RegistrationFormRenderer";
import ResetPasswordForm from "./ResetPasswordFormRenderer";
import ForgotPasswordForm from "./ForgotPasswordFormRenderer";

export type Renderers = {
  [Property in keyof ModifiedTemplate["elements"]]: React.ComponentType<
    RendererComponent<any>
  >;
};

const renderers: Renderers = {
  Footer,
  ProductCard,
  NavBar,
  SimpleProduct,
  CustomProduct,
  VariantsSection,
  Hero,
  CartTable,
  Coupon,
  DiscountsBreakdown,
  LoginForm,
  RegistrationForm,
  ResetPasswordForm,
  ForgotPasswordForm,
};

export default renderers;
