import { FormattedMessage } from "react-intl";
import Flex from "../../../shared/globals/UiElements/Flex";
import { CouponIcon } from "../../../assets/Icons";
import { DefaultTextPrice } from "../../../components/Price";
import { Badge } from "../../../shared/globals/UiElements/Badge";
import { DiscountBreakdownProps, TemplateElement } from "../../types";
import { HeavyH4, LightH4ForDiscount } from "../components/Cart/styled";

const DiscountsBreakdown: TemplateElement<DiscountBreakdownProps> = ({
  totalDiscounts,
  aggregateDiscount,
}) => (
  <Flex column fullWidth justifyContent="space-between" alignItems="flex-start">
    <Flex fullWidth justifyContent="space-between">
      <HeavyH4>
        <FormattedMessage defaultMessage="Discount" />
      </HeavyH4>
      <LightH4ForDiscount fontWeight={600}>
        -
        <DefaultTextPrice money={aggregateDiscount} />
      </LightH4ForDiscount>
    </Flex>
    <Flex fullWidth column justifyContent="space-between" spacing="s">
      {totalDiscounts &&
        Array.from(totalDiscounts.keys()).map((discount) => {
          if (totalDiscounts.get(discount)!.amount > 0)
            return (
              <Flex
                spacing="s"
                fullWidth
                justifyContent="space-between"
                alignItems="center"
              >
                <Badge
                  className="ml-xl"
                  type="ink"
                  label={
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      spacing="xs"
                    >
                      <CouponIcon />
                      {discount}
                    </Flex>
                  }
                />
                <LightH4ForDiscount>
                  -
                  <DefaultTextPrice money={totalDiscounts.get(discount)} />
                </LightH4ForDiscount>
              </Flex>
            );
          return null;
        })}
    </Flex>
  </Flex>
);

export default DiscountsBreakdown;
