import React from "react";
import { FormattedMessage } from "react-intl";
import Alert from "../../../../shared/globals/UiElements/Alert";
import { QuantityInput } from "../QuantityInput";
import { useCartData } from "../../../../lib/cartData/useCartData";

interface SideCartItemQtyProps {
  isDisabled?: boolean;
  initialQuantity: number;
  maxQuantityPerCart?: number | null;
  minQuantityPerCart?: number | null;
  handleQuantityChange: (value: number) => void;
}
const SideCartItemQty: React.FC<SideCartItemQtyProps> = ({
  isDisabled = false,
  initialQuantity,
  maxQuantityPerCart,
  minQuantityPerCart,
  handleQuantityChange,
}) => {
  const {
    cart: { isLoading },
  } = useCartData();
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);

  return (
    <>
      <QuantityInput
        value={initialQuantity}
        max={maxQuantityPerCart ?? Number.POSITIVE_INFINITY}
        onChange={(value) => {
          handleQuantityChange(value);
        }}
        disabled={isDisabled || isLoading}
      />
      {isAlertOpen && (
        <>
          <br />
          <Alert
            message={
              initialQuantity > maxQuantityPerCart! ? (
                <FormattedMessage defaultMessage="Quantity exceeded" />
              ) : (
                <FormattedMessage
                  defaultMessage="Minimum quantity per order {min}. You can add up to {max} per order"
                  values={{
                    min: minQuantityPerCart,
                    max: maxQuantityPerCart ?? Number.POSITIVE_INFINITY,
                  }}
                />
              )
            }
            closeAction={() => setIsAlertOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default SideCartItemQty;
