const TruckIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3337 13.3333V5.16667C13.3337 4.23325 13.3337 3.76654 13.152 3.41002C12.9922 3.09641 12.7372 2.84144 12.4236 2.68166C12.0671 2.5 11.6004 2.5 10.667 2.5H4.33366C3.40024 2.5 2.93353 2.5 2.57701 2.68166C2.2634 2.84144 2.00844 3.09641 1.84865 3.41002C1.66699 3.76654 1.66699 4.23325 1.66699 5.16667V10.6667C1.66699 11.6001 1.66699 12.0668 1.84865 12.4233C2.00844 12.7369 2.2634 12.9919 2.57701 13.1517C2.93353 13.3333 3.40024 13.3333 4.33366 13.3333H13.3337ZM13.3337 13.3333H17.0003C17.467 13.3333 17.7004 13.3333 17.8787 13.2425C18.0355 13.1626 18.1629 13.0351 18.2428 12.8783C18.3337 12.7001 18.3337 12.4667 18.3337 12V9.71895C18.3337 9.51513 18.3337 9.41321 18.3106 9.31731C18.2902 9.23228 18.2566 9.15099 18.2109 9.07643C18.1593 8.99233 18.0873 8.92027 17.9431 8.77614L16.2242 7.05719C16.0801 6.91306 16.008 6.841 15.9239 6.78947C15.8493 6.74378 15.768 6.71011 15.683 6.68969C15.5871 6.66667 15.4852 6.66667 15.2814 6.66667H13.3337M7.50033 15.4167C7.50033 16.5673 6.56759 17.5 5.41699 17.5C4.2664 17.5 3.33366 16.5673 3.33366 15.4167C3.33366 14.2661 4.2664 13.3333 5.41699 13.3333C6.56759 13.3333 7.50033 14.2661 7.50033 15.4167ZM16.667 15.4167C16.667 16.5673 15.7343 17.5 14.5837 17.5C13.4331 17.5 12.5003 16.5673 12.5003 15.4167C12.5003 14.2661 13.4331 13.3333 14.5837 13.3333C15.7343 13.3333 16.667 14.2661 16.667 15.4167Z"
        stroke="white"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { TruckIcon };
