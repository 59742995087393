import React from "react";
import { FormattedMessage } from "react-intl";
import { C500, RightArrow } from "../../../../assets/Icons";
import { Section } from "../../../../shared/layout";
import { StyledHead, StyledParagraph, SvgWrapper } from "./PageNotFound";
import { PrimaryButton } from "../../../../components/Button";
import Flex from "../../../../shared/globals/UiElements/Flex";

function UnknownError() {
  return (
    <Section>
      <Flex column justifyContent="center" alignItems="center" height="100vh">
        <SvgWrapper>
          <C500 />
        </SvgWrapper>
        <StyledHead>
          <FormattedMessage defaultMessage="Something went wrong!" />
        </StyledHead>
        <StyledParagraph>
          <FormattedMessage defaultMessage="Sorry we’re unable to find the page you’re looking for." />
        </StyledParagraph>
        <PrimaryButton href="/shop" suffixIcon={<RightArrow />}>
          <FormattedMessage defaultMessage=" Go back to Shop" />
        </PrimaryButton>
      </Flex>
    </Section>
  );
}

export default UnknownError;
