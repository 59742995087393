import { FormattedMessage } from "react-intl";
import { ShoppingCart } from "../../../../assets/Icons";
import { Price } from "../../../../components/Price";
import { H4 } from "../../../../shared/globals";
import {
  ImageFragment,
  Money,
  SelectedOptionsFragment,
} from "../../../../generated/graphql";
import SectionContent from "../../elements/SectionContent";
import Flex from "../../../../shared/globals/UiElements/Flex";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import Button from "../Button/Button";
import MotionElementWrapper from "../../../../shared/globals/MotionElementWrapper";

interface ProductBottomDetailsProps {
  title: string;
  price: Money;
  image: ImageFragment;
  options: SelectedOptionsFragment[];
  handleAddToCart: () => void;
  visible: boolean;
  disableButton: () => boolean | undefined | null;
}

export const ProductBottomDetails = (props: ProductBottomDetailsProps) => {
  const hasOptions = props.options.length;
  const handleAddToCart = () => {
    if (hasOptions) {
      const element = document.getElementById("product-head-id");

      if (element) {
        return element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
    return props.handleAddToCart();
  };
  return (
    <MotionElementWrapper
      initial={{ opacity: 0, y: "100%" }}
      animate={{
        opacity: props.visible ? 1 : 0,
        y: props.visible ? 0 : "100%",
      }}
      className="fixed bottom-0  py-md w-full z-DROPDOWN bg-white right-0 justify-between items-center shadow-[0px_-2px_4px_-2px_rgba(16,24,40,0.06),0px_-4px_8px_-2px_rgba(16,24,40,0.10)]  hidden md:flex opacity-0"
    >
      <SectionContent className="w-full">
        <Flex fullWidth alignItems="center" justifyContent="space-between">
          <Flex spacing="s">
            <ItemImg imgDetails={props.image} size={60} />
            <Flex column justifyContent="center" spacing="xxs">
              <H4>{props.title}</H4>
              <Flex spacing="xs">
                {props.options.map((option: any, idx) => (
                  <div key={option.id} className="text-sm text-gray-500">
                    {option.value.name}
                    {props.options.length - 1 === idx ? "" : "/"}
                  </div>
                ))}
              </Flex>

              <H4>
                <Price money={props.price} />
              </H4>
            </Flex>
          </Flex>
          <div className="w-[200px]">
            <Button
              prefixIcon={!hasOptions && <ShoppingCart />}
              fullWidth
              onClick={handleAddToCart}
              type={hasOptions ? "secondary" : "primary"}
              isDisabled={hasOptions ? false : !!props.disableButton()}
            >
              {hasOptions ? (
                <FormattedMessage defaultMessage="Select options" />
              ) : (
                <FormattedMessage defaultMessage="Add to cart" />
              )}
            </Button>
          </div>
        </Flex>
      </SectionContent>
    </MotionElementWrapper>
  );
};
