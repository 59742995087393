import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import EditUserInfo from "../../components/CustomerProfile/my-account/EditUserInfo";
import { useAuth } from "../../../../lib/Authentication";
import { H3, P, FlexRow, H4 } from "../../../../shared/globals";
import { Card } from "../../../../shared/globals/UiElements/Card";
import { Modes } from "../../components/CustomerProfile/my-account/types";
import type { SuccessStateType } from "../../components/CustomerProfile/my-account/types";
import { CheckCircle } from "../../../../assets/Icons";
import { rtl } from "../../../../shared/styles-utils";
import DeleteAccount from "../../components/CustomerProfile/my-account/DeleteAccount";
import Flex from "../../../../shared/globals/UiElements/Flex";

const UserAccount = () => {
  const { user } = useAuth();
  const [isSuccess, setIsSuccess] = React.useState<SuccessStateType>({
    mode: null,
    isSuccess: false,
  });

  const handleSuccess = ({ mode, isSuccess }: SuccessStateType) => {
    setIsSuccess({ mode, isSuccess });
    setTimeout(() => setIsSuccess({ mode: null, isSuccess: false }), 5000);
  };

  return (
    <Flex column fullWidth spacing="m" alignItems="flex-start">
      <H3 fontSize="1.25rem" fontWeight="600" className="!text-gray-800">
        <FormattedMessage defaultMessage="My Account" />
      </H3>
      <Card paddingSize="none">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className="p-md border-b border-solid border-gray-200"
        >
          <div>
            <P fontSize="0.875rem" fontWeight="500" className="!text-gray-500">
              <FormattedMessage defaultMessage="Name" />
            </P>
            <H3
              fontSize="0.875rem"
              fontWeight="500"
              className="!text-gray-800 "
            >
              {user?.name}
            </H3>
          </div>
          <ActionWrapper>
            {isSuccess.mode === Modes["NAME"] && !!isSuccess.isSuccess && (
              <SuccessMessage />
            )}
            <EditUserInfo mode={Modes["NAME"]} onSuccess={handleSuccess} />
          </ActionWrapper>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className="p-md border-b border-solid border-gray-200"
        >
          <div>
            <P fontSize="0.875rem" fontWeight="500" className="!text-gray-500">
              <FormattedMessage defaultMessage="E-mail address" />
            </P>
            <H3
              fontSize="0.875rem"
              fontWeight="500"
              className="!text-gray-800 break-all"
            >
              {user?.email}
            </H3>
          </div>
          <ActionWrapper>
            {isSuccess.mode === Modes["EMAIL"] && !!isSuccess.isSuccess && (
              <SuccessMessage />
            )}
            <EditUserInfo mode={Modes["EMAIL"]} onSuccess={handleSuccess} />
          </ActionWrapper>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className="p-md border-b border-solid border-gray-200"
        >
          <div>
            <P fontSize="0.875rem" fontWeight="500" className="!text-gray-500 ">
              <FormattedMessage defaultMessage="Phone number" />
            </P>
            <H3 fontSize="0.875rem" fontWeight="500" className="!text-gray-800">
              {user?.phone}
            </H3>
          </div>
          <ActionWrapper>
            {isSuccess.mode === Modes["PHONE"] && !!isSuccess.isSuccess && (
              <SuccessMessage />
            )}
            <EditUserInfo mode={Modes["PHONE"]} onSuccess={handleSuccess} />
          </ActionWrapper>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className="p-md "
        >
          <div>
            <P fontSize="0.875rem" fontWeight="500" className="!text-gray-500">
              <FormattedMessage defaultMessage="Password" />
            </P>
            <H3 fontSize="0.875rem" fontWeight="500" className="!text-gray-800">
              *********
            </H3>
          </div>
          <ActionWrapper>
            {isSuccess.mode === Modes["PASSWORD"] && !!isSuccess.isSuccess && (
              <SuccessMessage />
            )}
            <EditUserInfo mode={Modes["PASSWORD"]} onSuccess={handleSuccess} />
          </ActionWrapper>
        </Flex>
      </Card>

      <DeleteAccount />
    </Flex>
  );
};

export default UserAccount;

const SuccessMessage = () => (
  <SuccessMessageContainer>
    <CheckCircle />
    <H4 fontWeight={600}>
      <FormattedMessage defaultMessage="Saved" />
    </H4>
  </SuccessMessageContainer>
);

const ActionWrapper = styled(FlexRow)`
  @media (max-width: 768px) {
    margin-top: 10px;
    flex-direction: row-reverse;
  }
`;

const SuccessMessageContainer = styled(FlexRow)`
  background: #f0fdfa;
  padding: 10px 20px;
  border: 1px solid #ccfbf1;
  border-radius: 6px;
  margin: 0 20px;

  > h4 {
    color: #115e59;
  }

  > svg {
    ${rtl("margin-left", "margin-right")}: 10px;
  }
`;
