import React from "react";

export const ShoppingBasket = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Shopping, Ecommerce/Basket, Shopping Cart">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Path"
              d="M8.00062 3L5.89062 6.777"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Path_2"
              d="M15 3L17.3 6.777"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Path_3"
              d="M16 21C13.239 21 11 18.761 11 16C11 13.239 13.239 11 16 11C18.762 11 21 13.239 21 16C21 18.761 18.762 21 16 21"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Path_4"
              d="M16 14.25V17.75"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Path_5"
              d="M17.75 16H14.25"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Path_6"
              d="M19.4415 12.3753L20.1445 9.12934C20.4065 7.92034 19.4875 6.77734 18.2485 6.77734H4.94047C3.70447 6.77734 2.78347 7.92034 3.04447 9.12934L4.51547 15.9213C4.70747 16.8143 5.49747 17.4503 6.41147 17.4503H11.2155"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
