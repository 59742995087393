import React from "react";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import MotionElementWrapper from "../../../../../shared/globals/MotionElementWrapper";
import { theme } from "../../../../../../tailwind.config";
import { useStore } from "../../../../../lib/storeData";
import { ArrowRight } from "../../../../../assets/Icons";
import useBreakPoints from "../../../../../shared/utils/useBreakPoints";
import { useTheme } from "styled-components";

interface SectionCardTitleProps {
  isHovered: boolean;
  setIsHovered: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  secondLine?: React.ReactNode;
}

const SectionCardTitle = ({
  isHovered,
  setIsHovered,
  title,
  secondLine,
}: SectionCardTitleProps) => {
  const { appearance } = useStore();
  const { isTablet } = useBreakPoints();
  const { isRTL } = useTheme();
  return (
    <Flex
      className={`absolute bottom-2 left-2 right-2 bg-gray-25 rounded-lg px-md py-xs min-h-[64px] ${
        isTablet || !secondLine ? "box-border" : "box-content"
      } overflow-hidden`}
      justifyContent="space-between"
      alignItems="center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Flex column spacing="none">
        <MotionElementWrapper
          initial={{ y: 0, color: theme.extend.colors.gray[800] }}
          animate={
            isHovered
              ? {
                  y: isTablet || !secondLine ? 0 : 3,
                  color: appearance?.colors?.primary!,
                }
              : { y: 0, color: theme.extend.colors.gray[800] }
          }
          duration={0.5}
        >
          <span
            className="text-xl font-semibold"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </span>
        </MotionElementWrapper>
        {!isTablet && (
          <MotionElementWrapper
            initial={{ y: 0, opacity: 0, height: 0 }}
            animate={
              isHovered
                ? { y: -2, opacity: 1, height: "fit-content" }
                : { y: 0, opacity: 0, height: 0 }
            }
            duration={0.5}
          >
            {secondLine}
          </MotionElementWrapper>
        )}
      </Flex>
      <div>
        <ArrowRight rotate={isRTL} />
      </div>
    </Flex>
  );
};

export default SectionCardTitle;
