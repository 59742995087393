import * as React from "react";
import Modal from "../../../../../shared/globals/UiElements/Modal";
import { Form, Field } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";
import createDecorator from "final-form-focus";
import Asterisk from "../../../../../shared/globals/UiElements/Asterisk";
import { H2, Label, FlexRow } from "../../../../../shared/globals";
import { StyledForm, RelativeDiv } from "./styled";
import type { FormValues } from "./types";
import { useStore } from "../../../../../lib/storeData";
import {
  CheckoutFieldOptions,
  CustomerIdentifier,
} from "../../../../../generated/graphql";
import { CheckoutFields } from "../../../../../contexts/CartContext/types";
import {
  PhoneField,
  RequiredSpan,
} from "../../../../../components/Checkout/Information/styled";
import ShippingDestinationSelect, {
  ShippingDestination,
} from "../../../../../components/UtilityComponents/ShippingDestinationSelect";
import { validate } from "../../../../../components/Checkout/Information/validate";
import { Delete } from "../../../../../assets/Icons";
import InputPhone, { PhoneInput } from "../../Input/inputPhone";
import { Input } from "../../Input";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import Button from "../../Button/Button";
import useBreakPoints from "../../../../../shared/utils/useBreakPoints";

const focusOnError = createDecorator();

interface AddressModalProps {
  isOpen: boolean;
  defaultValues?: FormValues;
  mode: "ADD" | "EDIT";
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
}

const AddressModal: React.FC<AddressModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  mode,
  defaultValues,
}) => {
  const { customCheckoutSetting } = useStore();
  const intl = useIntl();
  const { isTablet } = useBreakPoints();

  const ignoredFields = () => {
    return customCheckoutSetting?.secondaryPhone ===
      CheckoutFieldOptions.Mandatory
      ? [
          CheckoutFields.Phone,
          CheckoutFields.Name,
          CheckoutFields.Email,
          CheckoutFields.Notes,
        ]
      : [
          CheckoutFields.Phone,
          CheckoutFields.Name,
          CheckoutFields.Email,
          CheckoutFields.Notes,
          CheckoutFields.SecondPhone,
        ];
  };
  return (
    <Modal
      closeIcon={
        <div className="text-gray-500 p-xs">
          <Delete />
        </div>
      }
      className="max-md:!w-[95%] !w-[60%] !rounded-xl"
      show={isOpen}
      onClose={onClose}
      overLayColor="rgba(16, 24, 40, .7)"
    >
      <Form
        onSubmit={onSubmit}
        validate={(values) =>
          validate(values, customCheckoutSetting, ignoredFields())
        }
        decorators={[focusOnError]}
        render={({ handleSubmit, submitting }) => (
          <StyledForm onSubmit={handleSubmit}>
            <H2
              className="!text-gray-900 !p-xl border-b border-gray-200"
              fontSize="18px"
            >
              {mode === "ADD" ? (
                <FormattedMessage defaultMessage="Add New Address" />
              ) : (
                <FormattedMessage defaultMessage="Edit this address" />
              )}
            </H2>
            <Modal.Body>
              <Flex spacing="xxl" column>
                <Flex rotateOn="TABLET" spacing="xl">
                  <RelativeDiv className="flex-1">
                    <Label className="!mt-none">
                      <FlexRow className="text-gray-700">
                        <FormattedMessage defaultMessage="Address" />
                        <Asterisk />
                      </FlexRow>
                    </Label>
                    <Field name="address" defaultValue={defaultValues?.address}>
                      {({ input, meta: { error, touched } }) => (
                        <>
                          <Input
                            {...input}
                            className={error && touched ? "invalid" : ""}
                            data-test="type-address"
                            placeholder={intl.formatMessage({
                              defaultMessage: "Enter your address",
                            })}
                          />
                          {error && touched && (
                            <RequiredSpan>{error}</RequiredSpan>
                          )}
                        </>
                      )}
                    </Field>
                  </RelativeDiv>
                  <RelativeDiv className="flex-1">
                    <Label className="!mt-none">
                      <FlexRow className="text-gray-700">
                        <FormattedMessage defaultMessage="Apartment, suite, Unit etc." />
                        <Asterisk />
                      </FlexRow>
                    </Label>
                    <Field
                      style={{ position: "relative" }}
                      name="apartment"
                      defaultValue={defaultValues?.apartment}
                    >
                      {({ input, meta: { error, touched } }) => (
                        <>
                          <Input
                            {...input}
                            className={error && touched ? "invalid" : ""}
                            data-test="type-apartment"
                            placeholder={intl.formatMessage({
                              defaultMessage: "Enter your apartment number",
                            })}
                          />
                          {error && touched && (
                            <RequiredSpan>{error}</RequiredSpan>
                          )}
                        </>
                      )}
                    </Field>
                  </RelativeDiv>
                </Flex>
                <Flex column spacing="xl">
                  <Field<ShippingDestination>
                    name="shippingDestination"
                    defaultValue={defaultValues?.shippingDestination}
                  >
                    {({ input, meta: { error, touched } }) => (
                      <ShippingDestinationSelect
                        {...input}
                        startQuery
                        error={error && touched}
                      />
                    )}
                  </Field>
                  {customCheckoutSetting?.postalCode !==
                    CheckoutFieldOptions.Inactive && (
                    <Flex spacing="none" className="!flex-1" column>
                      <Label className="!mt-none">
                        <FlexRow className="text-gray-700">
                          <FormattedMessage defaultMessage="Postal Code" />
                          {customCheckoutSetting?.postalCode ===
                            CheckoutFieldOptions.Mandatory && <Asterisk />}
                        </FlexRow>
                      </Label>
                      <Field
                        name="postalCode"
                        defaultValue={defaultValues?.postalCode}
                      >
                        {({ input, meta: { error, touched } }) => (
                          <>
                            <Input
                              {...input}
                              data-test="type-postalcode"
                              type="text"
                              min="0"
                              placeholder={intl.formatMessage({
                                defaultMessage: "Enter postal code",
                              })}
                            />
                            {error && touched && (
                              <RequiredSpan>{error}</RequiredSpan>
                            )}
                          </>
                        )}
                      </Field>
                    </Flex>
                  )}
                </Flex>
                {customCheckoutSetting?.identifier !==
                  CustomerIdentifier.Email && (
                  <Flex rotateOn="TABLET" spacing="xl" fullWidth>
                    {customCheckoutSetting?.secondaryPhone !==
                      CheckoutFieldOptions.Inactive && (
                      <PhoneField fullWidth>
                        <Label className="!mt-none">
                          <FlexRow className="text-gray-700">
                            <FormattedMessage defaultMessage="Secondary Phone no. " />

                            {customCheckoutSetting?.secondaryPhone ===
                            CheckoutFieldOptions.Mandatory ? (
                              <Asterisk />
                            ) : (
                              <span className="!m-0 text-gray-400">
                                <FormattedMessage defaultMessage="(optional)" />
                              </span>
                            )}
                          </FlexRow>
                        </Label>
                        <Field<PhoneInput>
                          name="secondPhone"
                          defaultValue={defaultValues?.secondPhone}
                        >
                          {(fieldProps) => <InputPhone {...fieldProps} />}
                        </Field>
                      </PhoneField>
                    )}
                  </Flex>
                )}
              </Flex>
            </Modal.Body>

            <Flex
              className="p-xl pt-sm"
              justifyContent={isTablet ? "space-between" : "flex-end"}
            >
              <Button px={18} type="secondary" onClick={onClose}>
                <FormattedMessage defaultMessage="Cancel" />
              </Button>
              <Button
                px={64}
                data-test="button-save-changes"
                isLoading={submitting}
                htmlType="submit"
              >
                <FormattedMessage defaultMessage="Save Changes" />
              </Button>
            </Flex>
          </StyledForm>
        )}
      />
    </Modal>
  );
};

export default AddressModal;
