import React from "react";
import { FormattedMessage } from "react-intl";
import EmptyState from "../../components/CustomerProfile/my-orders/OrdersEmptyState";
import OrderHistoryPanel from "../../components/CustomerProfile/my-orders/OrderHistoryPanel";
import {
  OrderSortByField,
  SortOrder,
  useListCustomerOrdersQuery,
} from "../../../../generated/graphql";
import { H3 } from "../../../../shared/globals";
import { Card } from "../../../../shared/globals/UiElements/Card";
import { Pagination } from "../../../../components/Pagination/Pagination";
import LoadingSpinner from "../../../../shared/globals/UiElements/LoadingSpinner";
import { useAuth } from "../../../../lib/Authentication";
import { useRouter } from "../../../../lib/i18n";
import Flex from "../../../../shared/globals/UiElements/Flex";
import useBreakPoints from "../../../../shared/utils/useBreakPoints";

const ITEM_PER_PAGE = 10;

const OrderHistory = () => {
  const router = useRouter();
  const { user } = useAuth();
  const { isTablet } = useBreakPoints();
  const { page: urlPage = 1 } = router.query;
  const page = Number(urlPage);
  const offset = (page - 1) * ITEM_PER_PAGE;

  const { data, loading } = useListCustomerOrdersQuery({
    variables: {
      id: user?.id!,
      connection: {
        sortBy: OrderSortByField.CreatedAt,
        sortOrder: SortOrder.Desc,
        first: ITEM_PER_PAGE,
        offset,
      },
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Flex column spacing="m">
      <H3 fontWeight="bold">
        <FormattedMessage defaultMessage="My Orders" />
      </H3>
      {data?.customerOrders?.orders?.nodes?.length ? (
        <>
          <Flex
            column
            spacing={isTablet ? "xl" : "none"}
            className="overflow-auto rounded-lg max-md:shadow-none shadow-md !bg-transparent"
          >
            <OrderHistoryPanel
              orders={data?.customerOrders?.orders?.nodes}
              loading={loading}
            />
          </Flex>
          <Pagination
            offset={offset}
            totalCount={data?.customerOrders?.orders?.totalCount}
            itemPerPage={ITEM_PER_PAGE}
            style={{ paddingBottom: 0 }}
          />
        </>
      ) : (
        <Card paddingSize="3xl" boxShadow="sm">
          <EmptyState />
        </Card>
      )}
    </Flex>
  );
};

export default OrderHistory;
