const MyReviewsIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.25284 5.88132C9.39321 5.59683 9.68292 5.41672 10.0002 5.41672C10.3174 5.41672 10.6071 5.59683 10.7475 5.88132L11.1715 6.74084C11.2928 6.98684 11.5275 7.15736 11.7989 7.19681L12.7474 7.33466C13.0612 7.38031 13.3219 7.60012 13.4199 7.90171C13.518 8.2033 13.4363 8.53439 13.2093 8.75584L12.5227 9.42552C12.3265 9.61696 12.2369 9.8927 12.2833 10.1629L12.4453 11.1073C12.4989 11.4199 12.3704 11.7358 12.1138 11.9223C11.8572 12.1087 11.5171 12.1334 11.2363 11.9858L10.3878 11.5399C10.1451 11.4123 9.85519 11.4123 9.61248 11.5399L8.76398 11.9858C8.48322 12.1334 8.14306 12.1087 7.88648 11.9223C7.62991 11.7358 7.50141 11.4199 7.555 11.1073L7.717 10.1629C7.76336 9.8927 7.67382 9.61696 7.47755 9.42552L6.79099 8.75584C6.56399 8.53439 6.48236 8.2033 6.5804 7.90171C6.67844 7.60012 6.93915 7.38032 7.25297 7.33468L8.20141 7.19683C8.47287 7.15738 8.70752 6.98686 8.82887 6.74085L9.25284 5.88132Z"
      stroke="#667085"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 2.5H5C3.61929 2.5 2.5 3.61929 2.5 5V12.5C2.5 13.8807 3.61929 15 5 15H6.6725V17.0357C6.6725 17.2139 6.77461 17.3763 6.93517 17.4534C7.09573 17.5306 7.2863 17.5089 7.42541 17.3976L10.4225 15H15C16.3807 15 17.5 13.8807 17.5 12.5V5C17.5 3.61929 16.3807 2.5 15 2.5Z"
      stroke="#667085"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export { MyReviewsIcon };
