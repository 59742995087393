import { useEffect, useState } from "react";
import styled from "styled-components";
import { ProductPlaceHolder, ArrowRight } from "../../../assets/Icons";
import { PrimaryButton } from "../../../components/Button";
import { Photo } from "../../../shared/globals/UiElements/Photo";
import { rtl } from "../../../shared/styles-utils";
import type { ProductGalleryProps } from "../../types";
import HoverToZoomClickToLightBox from "../../../components/HoverToZoom/HoverToZoom";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import { lightBoxStyles } from "../../../components/HoverToZoom/styled";
import tailwindConfig from "../../../../tailwind.config";

const ProductGallery = ({ images, selectedId }: ProductGalleryProps) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [showLightBox, setShowLightBox] = useState(false);

  useEffect(() => {
    const index = images.findIndex((image) => {
      return image?.id === selectedId;
    });
    if (index !== -1) {
      setActiveImageIndex(index);
    }
  }, [images, selectedId]);

  const total = images.length;
  const hasNext = activeImageIndex < total - 1;
  const hasPrevious = activeImageIndex !== 0;

  const handleNext = () => {
    if (images.length > 4 && activeImageIndex > 2) {
      if (activeImageIndex <= 3) setActiveImageIndex(activeImageIndex + 1);
      return setShowLightBox(true);
    }
    setActiveImageIndex(activeImageIndex + 1);
  };

  const handlePrevious = () => {
    setActiveImageIndex(activeImageIndex - 1);
  };

  const lightboxImages = images.map((image) => {
    return { src: image?.src! };
  });
  return (
    <GalleryContainer>
      <ActiveImgContainer key={images[activeImageIndex]?.src}>
        {images[activeImageIndex] ? (
          <HoverToZoomClickToLightBox
            src={images[activeImageIndex]?.src!}
            images={lightboxImages}
            objectFit="cover"
          />
        ) : (
          <ProductPlaceHolder />
        )}
        {hasNext && (
          <ImagesButton suffixIcon={<ArrowRight />} onClick={handleNext} next />
        )}
        {hasPrevious && (
          <ImagesButton
            suffixIcon={<ArrowRight />}
            onClick={handlePrevious}
            next={false}
          />
        )}
      </ActiveImgContainer>

      <PassiveImgContainer>
        {images.map((thumb, index) => {
          if (!thumb || index > 4) return null;
          if (index === 4)
            return (
              <ThumbImgWrapper
                key={`gl-${index}`}
                onClick={() => {
                  setActiveImageIndex(index);
                  setShowLightBox(true);
                }}
                active={index === activeImageIndex}
              >
                <div className="absolute right-0 bg-black/50 z-DEFAULT w-full h-full top-0"></div>
                {images.length > 4 && (
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-DEFAULT text-3xl font-semibold cursor-default">
                    +{images.length - 4}
                  </div>
                )}
                <Photo
                  image={thumb}
                  alt={thumb?.altText! || ""}
                  objectFit="contain"
                />
              </ThumbImgWrapper>
            );
          return (
            <ThumbImgWrapper
              key={`gl-${index}`}
              onClick={() => setActiveImageIndex(index)}
              active={index === activeImageIndex}
              className="cursor-pointer"
            >
              <Photo
                image={thumb}
                alt={thumb?.altText! || ""}
                objectFit="contain"
                className={
                  index === activeImageIndex ? "opacity-100" : "opacity-70"
                }
              />
            </ThumbImgWrapper>
          );
        })}
      </PassiveImgContainer>
      <Lightbox
        open={showLightBox}
        plugins={[Zoom, Thumbnails]}
        carousel={{
          preload: 3,
          finite: true,
        }}
        index={
          lightboxImages.indexOf(
            lightboxImages?.find(
              (image) => image.src === lightboxImages[activeImageIndex]?.src
            )!
          ) || 0
        }
        styles={lightBoxStyles}
        animation={{
          zoom: 400,
          navigation: 400,
        }}
        zoom={{
          maxZoomPixelRatio: 3,
          scrollToZoom: true,
        }}
        close={() => setShowLightBox(false)}
        slides={lightboxImages}
      />
    </GalleryContainer>
  );
};

export default ProductGallery;

/**
 *
 * Styles
 *
 */

const GalleryContainer = styled.div`
  margin: 0 0 25px;
  display: flex;
  flex-direction: column;
  overflow-anchor: none;
  justify-content: start;
  align-items: center;

  @media (min-width: 768px) {
    width: 100%;
    max-width: 520px;
  }
`;

const ActiveImgContainer = styled.div`
  position: relative;

  height: 520px;
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  @media (min-width: 768px) {
    max-width: 520px;
  }
`;

const PassiveImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 -10px;
  @media (min-width: 768px) {
    width: 512px;
  }
`;

const ThumbImgWrapper = styled.div<{ active: boolean }>`
  width: 100%;
  position: relative;
  aspect-ratio: 1 / 1;
  border: 2px solid
    ${({ active, theme }) =>
      active
        ? theme.colors.primary
        : tailwindConfig.theme.extend.colors.gray[100]};
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  margin: 10px 5px 0 0;
  flex: 0 0 calc(25% - 5px);
  display: flex;
  justify-content: center;
  flex: 0 0 calc(20% - 5px);
`;

const ImagesButton = styled(PrimaryButton)<{ next: boolean }>`
  font-weight: 600;
  font-size: 12px;
  padding: 13px 15px;
  border-radius: 50%;
  background-color: #ffffff;
  color: ${tailwindConfig.theme.extend.colors.gray[700]};
  border: solid 1px #bac7d5;
  position: absolute;
  top: 50%;
  opacity: 0.7;
  left: ${({ next }) => (next ? rtl("", "83%") : rtl("83%", ""))};
  right: ${({ next }) => (next ? rtl("83%", "") : rtl("", "83%"))};
  div {
    padding: 0;
    svg {
      ${({ next }) =>
        next
          ? rtl("transform: rotate(180deg)", "")
          : rtl("", "transform: rotate(180deg)")};
    }
  }
  transition: 200ms ease;
  &:hover {
    box-shadow: none;
    opacity: 1;
  }
  @media (min-width: 768px) {
    left: ${({ next }) => (next ? rtl("", "90%") : rtl("90%", ""))};
    right: ${({ next }) => (next ? rtl("90%", "") : rtl("", "90%"))};
  }
`;
