import styled from "styled-components";
import { SectionContentProps, TemplateElement } from "../../types";
import { Breakpoints } from "../../../shared/globals/types";

const SectionContent: TemplateElement<SectionContentProps> = ({
  children,
  className,
}) => {
  return <Content className={className}>{children}</Content>;
};

export default SectionContent;

const Content = styled.div`
  max-width: 100%;
  padding: 0 20px;
  margin: auto;

  @media (min-width: ${Breakpoints.TABLET}px) {
    max-width: 85%;
  }
`;
