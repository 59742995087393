import { IntlShape } from "react-intl";
import {
  CartCustomItem,
  CartItem,
  CartSimpleItem,
  CurrencyCode,
  Money,
} from "../../generated/graphql";
import { getTranslatedDiscountTitle } from "../../shared/utils/getTranslatedDiscountTitle";
import { ZERO_MONEY, addMoney } from "../../shared/utils/money";

export function getTotalDiscounts(
  items: CartItem[],
  currentLocale: string,
  intl: IntlShape,
  currencyCode: CurrencyCode
): Map<string, Money> {
  const discountsMap = new Map<string, Money>();
  (items as CartItem[])?.forEach((item) => {
    const typedSimpleItem = item as CartSimpleItem;
    if (typedSimpleItem?.discounts) {
      typedSimpleItem.discounts?.forEach((discount) => {
        const translatedTitle = getTranslatedDiscountTitle({
          defaultTitle: discount?.data?.title,
          translations: discount?.data?.translations,
          currentLocale,
        })!;

        discountsMap.set(
          translatedTitle,
          addMoney(
            discountsMap.get(translatedTitle) || ZERO_MONEY(currencyCode),
            discount?.total!
          )
        );
      });
    } else if (
      !!item?.discount?.info?.fixed?.amount ||
      !!item?.discount?.info?.percentage
    ) {
      const typedCustomItem = item as CartCustomItem;
      discountsMap.set(
        intl.formatMessage({ defaultMessage: "Custom Discount" }),
        typedCustomItem.discount?.total || ZERO_MONEY(currencyCode)
      );
    }
  });

  return discountsMap;
}
