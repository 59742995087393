import React from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import styled from "styled-components";
import {
  UserOrderInfoFragment,
  OrderPaymentStatusEnum,
  FulfillStatusEnum,
} from "../../../../../generated/graphql";
import { Link } from "../../../../../lib/i18n";
import { H5, Spinner } from "../../../../../shared/globals";
import { themeColor } from "../../../../../shared/styles-utils";
import { DefaultTextPrice } from "../../../../../components/Price";
import { Badge } from "../../../../../shared/globals/UiElements/Badge";
import useBreakPoints from "../../../../../shared/utils/useBreakPoints";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import { Card } from "../../../../../shared/globals/UiElements/Card";

interface OrderHistoryPanelProps {
  orders: (UserOrderInfoFragment | null)[] | null;
  loading: boolean;
}

const formattedOrderStatus = {
  [OrderPaymentStatusEnum.Paid]: <FormattedMessage defaultMessage="Paid" />,
  [OrderPaymentStatusEnum.Pending]: (
    <FormattedMessage defaultMessage="Pending" />
  ),
  [OrderPaymentStatusEnum.Refunded]: (
    <FormattedMessage defaultMessage="Refunded" />
  ),
  unknown: <FormattedMessage defaultMessage="Unknown" />,
};

const formattedFulfillmentOrderStatus = {
  [FulfillStatusEnum.Fulfilled]: (
    <FormattedMessage defaultMessage="Fulfilled" />
  ),
  [FulfillStatusEnum.PartiallyFulfilled]: (
    <FormattedMessage defaultMessage="Partially Fulfilled" />
  ),
  [FulfillStatusEnum.Unfulfilled]: (
    <FormattedMessage defaultMessage="Unfulfilled" />
  ),
  unknown: <FormattedMessage defaultMessage="Unknown" />,
};

const OrderHistoryPanel = ({ orders, loading }: OrderHistoryPanelProps) => {
  const { isTablet } = useBreakPoints();
  if (loading) {
    return <Spinner size={64} inline />;
  }

  return (
    <>
      <GridContainerHeader className="max-md:!hidden !bg-white">
        <GridCell>
          <FormattedMessage defaultMessage="Order" />
        </GridCell>
        <GridCell>
          <FormattedMessage defaultMessage="Date" />
        </GridCell>
        <GridCell>
          <FormattedMessage defaultMessage="Payment" />
        </GridCell>
        <GridCell>
          <FormattedMessage defaultMessage="Fulfillment" />
        </GridCell>
        <GridCell>
          <FormattedMessage defaultMessage="Total" />
        </GridCell>
      </GridContainerHeader>
      {orders?.map((order) =>
        !isTablet ? (
          <Link key={order?.id} href={`/me/my-orders/${order?.id}`}>
            <GridContainerBody className="bg-white max-md:rounded-lg">
              <GridCell id="order-serial" fontWeight="bold">
                #
                <FormattedNumber
                  value={+order?.orderSerial!}
                  minimumIntegerDigits={4}
                  notation={
                    +order?.orderSerial! < 1000 ? "compact" : "standard"
                  }
                />
              </GridCell>
              <GridCell className="!text-sm  !text-gray-800 !font-normal">
                <FormattedDate
                  value={order?.createdAt}
                  day="2-digit"
                  month="long"
                  year="numeric"
                />
              </GridCell>
              <GridCell>
                <Badge
                  label={
                    formattedOrderStatus[order?.paymentStatus ?? "unknown"] ??
                    order?.paymentStatus
                  }
                  className={
                    order?.paymentStatus == OrderPaymentStatusEnum.Paid
                      ? "!bg-[#ECFDF3] !px-xs !border-success-200 !text-success-700 !rounded-full !text-xs"
                      : "!bg-warning-50 !px-xs !border-warning-200 !text-warning-700 !rounded-full !text-xs"
                  }
                />
              </GridCell>
              <GridCell className="!text-sm !text-gray-800 !font-normal ">
                {formattedFulfillmentOrderStatus[
                  order?.fulfillmentStatus ?? "unknown"
                ] ?? order?.fulfillmentStatus}
              </GridCell>
              <DefaultTextPrice
                className="text-sm text-gray-800 font-normal"
                money={order?.totalPrice}
              />
            </GridContainerBody>
          </Link>
        ) : (
          <Link key={order?.id} href={`/me/my-orders/${order?.id}`}>
            <Card boxShadow="sm" paddingSize="none">
              <Flex
                justifyContent="space-between "
                className="border-b border-gray-200 !py-sm !px-md "
              >
                <div className="flex-1">
                  <H5
                    className="!text-gray-600"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    Order
                  </H5>
                  <GridCell id="order-serial" fontWeight="bold">
                    #
                    <FormattedNumber
                      value={+order?.orderSerial!}
                      minimumIntegerDigits={4}
                      notation={
                        +order?.orderSerial! < 1000 ? "compact" : "standard"
                      }
                    />
                  </GridCell>
                </div>
                <div className="text-sm flex-[2]  text-gray-800 font-normal">
                  <H5
                    className="!text-gray-600"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    Date
                  </H5>
                  <FormattedDate
                    value={order?.createdAt}
                    day="2-digit"
                    month="long"
                    year="numeric"
                  />
                </div>
                <div className="text-sm flex-1 text-gray-800 font-normal ">
                  <H5
                    className="!text-gray-600"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    Fulfillment
                  </H5>
                  <H5
                    className="!text-gray-800"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    {formattedFulfillmentOrderStatus[
                      order?.fulfillmentStatus ?? "unknown"
                    ] ?? order?.fulfillmentStatus}
                  </H5>
                </div>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                className="py-sm px-md"
              >
                <div className="flex-[3.4]">
                  <H5
                    className="!text-gray-600"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    Total
                  </H5>
                  <DefaultTextPrice
                    className="text-sm text-gray-800 font-normal "
                    money={order?.totalPrice}
                  />
                </div>
                <div className="flex-1">
                  <Badge
                    label={
                      formattedOrderStatus[order?.paymentStatus ?? "unknown"] ??
                      order?.paymentStatus
                    }
                    className={
                      order?.paymentStatus == OrderPaymentStatusEnum.Paid
                        ? "!bg-[#ECFDF3] !py-3xs !px-xs !border-success-200 !text-success-700 !rounded-full !text-xs"
                        : "!bg-warning-50   !py-3xs !px-xs !border-warning-200 !text-warning-700 !rounded-full !text-xs"
                    }
                  />
                </div>
              </Flex>
            </Card>
          </Link>
        )
      )}
    </>
  );
};

export default OrderHistoryPanel;

const GridContainerBody = styled.div`
  display: grid;
  grid-template-columns: 16% 36% 16% 16% 16%;
  padding: 20px;
  cursor: pointer;

  border-bottom: solid 1px #dfe4e8;

  &:hover {
    background-color: ${({ theme }) => theme.text.default}10;
  }

  @media (max-width: 768px) {
    grid-template-columns: 30% 40% 30%;
    grid-gap: 10px;
  }
`;

const GridContainerHeader = styled(GridContainerBody)`
  & > h5 {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    color: #475467;
  }
  &:hover {
    background-color: unset;
  }
  cursor: unset;
`;

const GridCell = styled(H5)`
  min-width: 100px;
  /* border-bottom: 1px solid #eaecf0; */
  &#order-serial {
    color: ${themeColor("primary")};
    font-size: 14px;
    font-weight: 500;
  }
`;
