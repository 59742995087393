import * as React from "react";
import Modal from "../../../../../shared/globals/UiElements/Modal";
import { Form, Field } from "react-final-form";
import { FormattedMessage } from "react-intl";
import createDecorator from "final-form-focus";
import Asterisk from "../../../../../shared/globals/UiElements/Asterisk";
import { H2, Label, Input, FlexRow } from "../../../../../shared/globals";
import { StyledForm } from "./styled";
import InputPhone, {
  PhoneInput,
} from "../../../../../shared/globals/UiElements/InputPhone";
import type { FormValues } from "./types";
import { useStore } from "../../../../../lib/storeData";
import {
  CheckoutFieldOptions,
  CustomerIdentifier,
} from "../../../../../generated/graphql";
import { CheckoutFields } from "../../../../../contexts/CartContext/types";
import {
  PhoneField,
  RequiredSpan,
} from "../../../../../components/Checkout/Information/styled";
import ShippingDestinationSelect, {
  ShippingDestination,
} from "../../../../../components/UtilityComponents/ShippingDestinationSelect";
import { validate } from "../../../../../components/Checkout/Information/validate";
import { Delete } from "../../../../../assets/Icons";
import useBreakPoints from "../../../../../shared/utils/useBreakPoints";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import { PrimaryButton } from "../../../../../components/Button";

const focusOnError = createDecorator();

interface AddressModalProps {
  isOpen: boolean;
  defaultValues?: FormValues;
  mode: "ADD" | "EDIT";
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
}

const AddressModal: React.FC<AddressModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  mode,
  defaultValues,
}) => {
  const { customCheckoutSetting } = useStore();
  const { isTablet } = useBreakPoints();
  const ignoredFields = () => {
    return customCheckoutSetting?.secondaryPhone ===
      CheckoutFieldOptions.Mandatory
      ? [
          CheckoutFields.Phone,
          CheckoutFields.Name,
          CheckoutFields.Email,
          CheckoutFields.Notes,
        ]
      : [
          CheckoutFields.Phone,
          CheckoutFields.Name,
          CheckoutFields.Email,
          CheckoutFields.Notes,
          CheckoutFields.SecondPhone,
        ];
  };
  return (
    <Modal
      show={isOpen}
      onClose={onClose}
      closeIcon={<Delete small />}
      closeButtonPosition={{
        rightOrLeft: isTablet ? "5px" : "25px",
        top: isTablet ? "20px" : "25px",
      }}
    >
      <Modal.Body padding={isTablet ? "20px" : "40px"}>
        <Flex column spacing="none">
          <H2>
            {mode === "ADD" ? (
              <FormattedMessage defaultMessage="Add New Address" />
            ) : (
              <FormattedMessage defaultMessage="Edit this address" />
            )}
          </H2>
          <Form
            onSubmit={onSubmit}
            validate={(values) =>
              validate(values, customCheckoutSetting, ignoredFields())
            }
            decorators={[focusOnError]}
            render={({ handleSubmit, submitting }) => (
              <StyledForm onSubmit={handleSubmit}>
                <Flex column fullWidth spacing="xl">
                  <Flex column spacing="none">
                    <Flex column spacing="none">
                      <Field
                        name="address"
                        defaultValue={defaultValues?.address}
                      >
                        {({ input, meta: { error, touched } }) => (
                          <Flex column spacing="xs">
                            <Label>
                              <FlexRow>
                                <FormattedMessage defaultMessage="Address" />
                                <Asterisk />
                              </FlexRow>
                            </Label>
                            <Input
                              {...input}
                              className={error && touched ? "invalid" : ""}
                              data-test="type-address"
                            />
                            {error && touched && (
                              <RequiredSpan>{error}</RequiredSpan>
                            )}
                          </Flex>
                        )}
                      </Field>

                      <Field
                        style={{ position: "relative" }}
                        name="apartment"
                        defaultValue={defaultValues?.apartment}
                      >
                        {({ input, meta: { error, touched } }) => (
                          <Flex column spacing="xs">
                            <Label>
                              <FlexRow>
                                <FormattedMessage defaultMessage="Apartment, suite, Unit etc." />
                                <Asterisk />
                              </FlexRow>
                            </Label>
                            <Input
                              {...input}
                              className={error && touched ? "invalid" : ""}
                              data-test="type-apartment"
                            />
                            {error && touched && (
                              <RequiredSpan>{error}</RequiredSpan>
                            )}
                          </Flex>
                        )}
                      </Field>
                      <Field<ShippingDestination>
                        name="shippingDestination"
                        defaultValue={defaultValues?.shippingDestination}
                      >
                        {({ input, meta: { error, touched } }) => (
                          <ShippingDestinationSelect
                            {...input}
                            startQuery
                            error={error && touched}
                          />
                        )}
                      </Field>
                      {customCheckoutSetting?.postalCode !==
                        CheckoutFieldOptions.Inactive && (
                        <Field
                          name="postalCode"
                          defaultValue={defaultValues?.postalCode}
                        >
                          {({ input, meta: { error, touched } }) => (
                            <Flex column spacing="xs">
                              <Label>
                                <FlexRow>
                                  <FormattedMessage defaultMessage="Postal Code" />
                                  {customCheckoutSetting?.postalCode ===
                                    CheckoutFieldOptions.Mandatory && (
                                    <Asterisk />
                                  )}
                                </FlexRow>
                              </Label>
                              <Input
                                {...input}
                                data-test="type-postalcode"
                                type="text"
                                min="0"
                              />
                              {error && touched && (
                                <RequiredSpan>{error}</RequiredSpan>
                              )}
                            </Flex>
                          )}
                        </Field>
                      )}
                      {customCheckoutSetting?.identifier !==
                        CustomerIdentifier.Email &&
                        customCheckoutSetting?.secondaryPhone !==
                          CheckoutFieldOptions.Inactive && (
                          <PhoneField fullWidth>
                            <Label>
                              <FlexRow>
                                <FormattedMessage defaultMessage="Secondary Phone no." />
                                {customCheckoutSetting?.secondaryPhone ===
                                  CheckoutFieldOptions.Mandatory && (
                                  <Asterisk />
                                )}
                              </FlexRow>
                            </Label>
                            <Field<PhoneInput>
                              name="secondPhone"
                              defaultValue={defaultValues?.secondPhone}
                            >
                              {(fieldProps) => <InputPhone {...fieldProps} />}
                            </Field>
                          </PhoneField>
                        )}
                    </Flex>
                  </Flex>
                  <Flex justifyContent="flex-end" flexWrap="wrap-reverse">
                    <PrimaryButton type="button" onClick={onClose} outlined>
                      <FormattedMessage defaultMessage="Cancel" />
                    </PrimaryButton>
                    <PrimaryButton
                      data-test="button-save-changes"
                      type="submit"
                      isLoading={submitting}
                    >
                      <FormattedMessage defaultMessage="Save Changes" />
                    </PrimaryButton>
                  </Flex>
                </Flex>
              </StyledForm>
            )}
          />
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default AddressModal;
