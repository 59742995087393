import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import { Sorting } from "../components/Filter/Sorting";
import { Filter as FilterIcon } from "../../../assets/Icons";
import { Section } from "../../../shared/layout";
import FilterTags from "../components/Filter/components/FilterTags";
import Filter from "../components/Filter/Filter";
import { FilterAndSortProps } from "../../types";
import Button from "../components/Button/Button";
import Flex from "../../../shared/globals/UiElements/Flex";

const DesktopFilterSort: FC<FilterAndSortProps> = () => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Section>
      <Filter isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Flex className="max-md:hidden pt-10" column>
        <Flex justifyContent="space-between" alignItems="center" fullWidth>
          <Button prefixIcon={<FilterIcon />} onClick={() => setIsOpen(true)}>
            <FormattedMessage defaultMessage="Filter" />
          </Button>
          {router.query.query && (
            <>
              <FormattedMessage defaultMessage="Searched for " />{" "}
              {'"' + router.query.query + '"'}
            </>
          )}
          <Sorting />
        </Flex>
        <Flex>
          <FilterTags />
        </Flex>
      </Flex>
    </Section>
  );
};

export default DesktopFilterSort;

// Styles
