import React, { FC } from "react";
import styled from "styled-components";
import { FlexCol } from "../../../shared/globals";
import { SectionCardWrapperProps } from "../../types";
import { SectionBackgroundTypeEnum } from "../../../generated/graphql";

const SectionCardWrapper: FC<SectionCardWrapperProps> = ({
  children,
  isSlider,
  background,
}) => {
  return (
    <CardWrapper isSlider={isSlider} background={background}>
      {children}
    </CardWrapper>
  );
};

export default SectionCardWrapper;

const CardWrapper = styled(FlexCol)<{
  isSlider?: boolean;
  background?: {
    color?: string;
    type: SectionBackgroundTypeEnum;
  };
}>`
  height: ${({ isSlider }) => (isSlider ? "calc(100% - 40px)" : "auto")};
  position: relative;
  background-color: ${({ background }) =>
    background?.type === SectionBackgroundTypeEnum.Color
      ? background?.color
      : "transparent"};
  padding: 10px;
  border-radius: 4px;
  margin-bottom: ${({ isSlider }) => (isSlider ? "20px" : "0")};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;
`;
