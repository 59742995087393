import React from "react";
import styled from "styled-components";
import { FlexCol, H1 } from "../../../../shared/globals";
import type { CustomProductInfoProps } from "../../../types";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import useBreakPoints from "../../../../shared/utils/useBreakPoints";
import HTMLRender from "../../../../shared/globals/UiElements/HTMLRender";

interface HeadingSectionProps {
  product: CustomProductInfoProps["product"];
}

const HeadingSection = ({ product }: HeadingSectionProps) => {
  const { isTablet } = useBreakPoints();
  return (
    <Container>
      {product?.images[0]?.src && (
        <ItemImg
          imgDetails={product?.images?.[0]}
          onlyImg
          size={isTablet ? 200 : 120}
        />
      )}
      <FlexCol alignItems="flex-start">
        <ProductTitle>{product.title}</ProductTitle>
        <HTMLRender html={product?.descriptionHtml} />
      </FlexCol>
    </Container>
  );
};

export default HeadingSection;

/**
 *
 *
 * Styles
 *
 *
 */

const Container = styled(FlexCol)`
  margin-bottom: 20px;
  padding: 20px 0;

  > div {
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    > div {
      justify-content: flex-start;
      margin-inline-end: 12px;
    }
  }
`;

const ProductTitle = styled(H1)`
  margin-bottom: 6px;
  font-weight: 500;
`;
