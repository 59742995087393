const EcommerceReceipt = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="57"
    viewBox="0 0 42 57"
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <path
            fill="#D8D8D8"
            d="M37.618 1.884L36.4.68c-.226-.227-.533-.355-.854-.355-.32 0-.628.128-.854.355l-1.204 1.204c-.226.227-.533.355-.854.355-.32 0-.628-.128-.854-.355L30.576.68c-.226-.227-.533-.355-.854-.355-.32 0-.628.128-.854.355l-1.204 1.204c-.226.227-.533.355-.854.355-.32 0-.628-.128-.854-.355L24.752.68c-.226-.227-.533-.355-.854-.355-.32 0-.628.128-.854.355L21.84 1.884c-.226.227-.533.355-.854.355-.32 0-.628-.128-.854-.355L18.928.68c-.226-.227-.533-.355-.854-.355-.32 0-.628.128-.854.355l-1.204 1.204c-.226.227-.533.355-.854.355-.32 0-.628-.128-.854-.355L13.104.68c-.226-.227-.533-.355-.854-.355-.32 0-.628.128-.854.355l-1.204 1.204c-.226.227-.533.355-.854.355-.32 0-.628-.128-.854-.355L7.28.68C7.054.453 6.747.325 6.426.325c-.32 0-.628.128-.854.355L4.368 1.884c-.226.227-.533.355-.854.355-.32 0-.628-.128-.854-.355-.344-.347-.864-.45-1.315-.263-.452.187-.745.628-.743 1.117v51.324c-.002.489.291.93.743 1.117.45.188.97.084 1.315-.263.226-.227.533-.355.854-.355.32 0 .628.128.854.355l1.204 1.204c.226.227.533.355.854.355.32 0 .628-.128.854-.355l1.204-1.204c.226-.227.533-.355.854-.355.32 0 .628.128.854.355l1.204 1.204c.226.227.533.355.854.355.32 0 .628-.128.854-.355l1.204-1.204c.226-.227.533-.355.854-.355.32 0 .628.128.854.355l1.204 1.204c.226.227.533.355.854.355.32 0 .628-.128.854-.355l1.204-1.204c.226-.227.533-.355.854-.355.32 0 .628.128.854.355l1.204 1.204c.226.227.533.355.854.355.32 0 .628-.128.854-.355l1.204-1.204c.226-.227.533-.355.854-.355.32 0 .628.128.854.355l1.204 1.204c.226.227.533.355.854.355.32 0 .628-.128.854-.355l1.204-1.204c.226-.227.533-.355.854-.355.32 0 .628.128.854.355l1.204 1.204c.226.227.533.355.854.355.32 0 .628-.128.854-.355l1.204-1.204c.226-.227.533-.355.854-.355.32 0 .628.128.854.355.344.347.864.45 1.315.263.452-.187.745-.628.743-1.117V2.738c.002-.489-.291-.93-.743-1.117-.45-.188-.97-.084-1.315.263-.47.464-1.225.464-1.694 0z"
            transform="translate(-842 -306) translate(842 306)"
          />
          <path
            fill="#969696"
            d="M19.068 16.752H8.652c-1.067 0-1.932-.865-1.932-1.932s.865-1.932 1.932-1.932h10.416c1.067 0 1.932.865 1.932 1.932s-.865 1.932-1.932 1.932z"
            transform="translate(-842 -306) translate(842 306)"
          />
          <path
            fill="#FFF"
            d="M34.23 24.074H7.77c-.58 0-1.05-.47-1.05-1.05-.004-.28.105-.552.303-.752.197-.2.466-.312.747-.312h26.46c.28 0 .55.113.747.312.198.2.307.471.303.752 0 .58-.47 1.05-1.05 1.05zM34.23 29.016H7.77c-.28 0-.55-.113-.747-.312-.198-.2-.307-.471-.303-.752 0-.58.47-1.05 1.05-1.05h26.46c.58 0 1.05.47 1.05 1.05.004.28-.105.552-.303.752-.197.2-.466.312-.747.312zM34.23 34H7.77c-.28.004-.549-.106-.747-.303-.197-.198-.307-.467-.303-.747 0-.58.47-1.05 1.05-1.05h26.46c.58 0 1.05.47 1.05 1.05.004.28-.106.549-.303.747-.198.197-.467.307-.747.303zM17.878 38.886H7.77c-.58 0-1.05-.47-1.05-1.05-.004-.28.106-.549.303-.747.198-.197.467-.307.747-.303h10.108c.28-.004.549.106.747.303.197.198.307.467.303.747 0 .58-.47 1.05-1.05 1.05zM26.432 38.886h-3.318c-.582 0-1.056-.468-1.064-1.05 0-.28.113-.55.312-.747.2-.198.471-.307.752-.303h3.318c.58 0 1.05.47 1.05 1.05-.008.577-.473 1.042-1.05 1.05z"
            transform="translate(-842 -306) translate(842 306)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export { EcommerceReceipt };
