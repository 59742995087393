import styled from "styled-components";
import { ProductDetailsProps } from "../../../types";
import { FormattedMessage } from "react-intl";

export const ProductAttributes: React.FC<
  Pick<ProductDetailsProps, "attributes">
> = ({ attributes }) => {
  return (
    <TabContainer>
      <TabHeader>
        <FormattedMessage defaultMessage="Attributes" />
      </TabHeader>
      <AttributesContainer>
        {attributes?.map((attribute, index) => (
          <AttributeBox key={index}>
            <AttributeName>{attribute?.name}</AttributeName>
            <AttributeValuesContainer key={index}>
              {attribute?.values?.map((value, index) => (
                <>
                  <AttributeValue>{value?.name}</AttributeValue>
                  {index !== attribute?.values?.length - 1 && (
                    <AttributeValue>{","}&nbsp;</AttributeValue>
                  )}
                </>
              ))}
            </AttributeValuesContainer>
          </AttributeBox>
        ))}
      </AttributesContainer>
    </TabContainer>
  );
};

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  gap: 24px;
`;

const TabHeader = styled.div`
  color: #252a31;
  font-size: 20px;
  font-weight: 700;
`;

const AttributesContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px 24px;
  flex-wrap: wrap;
`;

const AttributeBox = styled.div`
  display: flex;
  flex: 0 0 calc(50% - 24px);
  padding: 12px 22px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: #fafafa;
  gap: 16px;
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
`;

const AttributeName = styled.div`
  color: #000;
  font-weight: 600;
  letter-spacing: -0.2px;
`;

const AttributeValuesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const AttributeValue = styled.div`
  color: #636363;
  font-weight: 500;
  letter-spacing: -0.1px;
`;
