import { FC } from "react";
import styled from "styled-components";
import { StoreTemplate } from "../../../TemplateLoader";
import { ShoppingBag } from "../../../../assets/Icons";
import { LanguageSwitcher } from "../../components/NavBar/LanguageSwitcher";
import { Price } from "../../../../components/Price";
import Flex from "../../../../shared/globals/UiElements/Flex";
import SearchBar from "./SearchBar/SearchBar";
import { Section } from "../../../../shared/layout";
import { themeColor } from "../../../../shared/styles-utils";
import { useStore } from "../../../../lib/storeData";
import { NavBarProps } from "../../../types";
import AccountButton from "./DesktopAccountButton";
import { isAHWStore } from "../../../../shared/utils/isAHWStore";

const DesktopNavbar: FC<NavBarProps> = ({
  itemsCount,
  menu,
  itemsTotal,
  toggleSideCart,
}) => {
  const Template = StoreTemplate.get();
  const { storeId } = useStore();
  const isAHW = isAHWStore(storeId);

  return (
    <ShowAboveMobile>
      <Section boxShadow background="white">
        <NavTop justifyContent="space-between" spacing="m">
          <Flex spacing="xxl" alignItems="center">
            <Template.elements.Logo />
            <SearchBar />
          </Flex>

          <Flex spacing="m" alignItems="center">
            {!isAHW && <AccountButton />}
            <CartButton data-test="button-side-cart" onClick={toggleSideCart}>
              <Price money={itemsTotal} />
              <ShoppingBagWrapper>
                <ShoppingBagCount>{itemsCount}</ShoppingBagCount>
                <ShoppingBag />
              </ShoppingBagWrapper>
            </CartButton>
            <LanguageSwitcher />
          </Flex>
        </NavTop>

        <Template.elements.DesktopMenu items={menu!} />
      </Section>
    </ShowAboveMobile>
  );
};

export default DesktopNavbar;

/**
 *
 * Styles
 *
 */

const ShowAboveMobile = styled.div`
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
`;

const NavTop = styled(Flex)`
  padding: 20px 0;
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.special.border};
`;

const CartButton = styled.button`
  color: ${({ theme }) => theme.brand.default};
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background: none;
  padding: 0;
  border: none;
  margin: 0;
  line-height: 0;
  cursor: pointer;
  svg {
    margin-inline-end: 10px;
  }
`;

const ShoppingBagWrapper = styled.span`
  position: relative;
`;

const ShoppingBagCount = styled.span`
  background: ${themeColor("primary")}33;
  color: ${themeColor("primary")};
  font-weight: bold;
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 100%;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -10px;
  top: 0px;
  transform: translate(12px, -12px);
`;
