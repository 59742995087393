import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { PrimaryButton } from "../../../../../components/Button";
import { FlexRow, H3, FlexCol, P } from "../../../../../shared/globals";
import ClearAllFilters from "../../../../default/components/Filter/components/ClearAllFilters";
import { themeColor } from "../../../../../shared/styles-utils";

interface ApplyFilterSectionProps {
  onClose: () => void;
  totalCount: number;
  loading: boolean;
}

export const ApplyFilterSection = ({
  onClose,
  totalCount,
  loading,
}: ApplyFilterSectionProps) => {
  return (
    <ApplyFilterContainer fullWidth spacing="xl">
      <FlexRow fullWidth justifyContent="space-between">
        <H3 fontWeight={700}>
          <FormattedMessage defaultMessage="Items" />
        </H3>
        {!loading && (
          <P fontSize="12px">
            <FormattedMessage
              defaultMessage="{count, plural, =0 {no items} one {1 item} two {# items} few {# items} many {# items} other {# items}} found"
              values={{ count: totalCount }}
            />
          </P>
        )}
      </FlexRow>
      <FlexCol fullWidth spacing="m" alignItems="stretch">
        <PrimaryButton onClick={onClose}>
          <FormattedMessage defaultMessage="Apply Filter" />
        </PrimaryButton>
        <StyledClearAllFilters loading={loading} />
      </FlexCol>
    </ApplyFilterContainer>
  );
};

// Styles

const ApplyFilterContainer = styled(FlexCol)`
  box-shadow: 0px -4px 4px 0px rgba(226, 226, 226, 0.5);
  padding: 20px;
`;

const StyledClearAllFilters = styled(ClearAllFilters)`
  background-color: transparent;
  color: ${themeColor("primary")};
  border-color: ${themeColor("primary")};
`;
