import { useEffect, useState } from "react";
import styled from "styled-components";
import { ProductPlaceHolder, ArrowRight } from "../../../assets/Icons";
import { Photo } from "../../../shared/globals/UiElements/Photo";
import type { ProductGalleryProps } from "../../types";
import HoverToZoomClickToLightBox from "../../../components/HoverToZoom/HoverToZoom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import SwiperClass from "swiper";

const ProductGallery = ({ images, selectedId }: ProductGalleryProps) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [prevEl, setPrevEl] = useState<HTMLSpanElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLSpanElement | null>(null);
  const [mobileSwiper, setMobileSwiper] = useState<SwiperClass | null>(null);

  useEffect(() => {
    const index = images.findIndex((image) => {
      return image?.id === selectedId;
    });
    if (index !== -1) {
      mobileSwiper?.slideTo(index, 0);
      setActiveImageIndex(index);
    }
  }, [images, selectedId, mobileSwiper]);

  const total = images.length;

  return (
    <GalleryContainer>
      <DesktopGalleryContainer>
        {total > 1 && (
          <DesktopSwiperContainer>
            <SlideButtons ref={(node) => setPrevEl(node)}>
              <ArrowRight />
            </SlideButtons>
            <Swiper
              direction="vertical"
              slidesPerView="auto"
              slidesPerGroup={4}
              spaceBetween={10}
              navigation={{ prevEl, nextEl }}
              modules={[Navigation]}
              style={{
                height: "100%",
                position: "relative",
              }}
              watchSlidesProgress
            >
              {(images || []).map((thumb, index) => (
                <SwiperSlide
                  style={{
                    height: "calc(25% - 10px)",
                    width: "calc(100% - 2px)",
                    borderRadius: "10px",
                    border:
                      index === activeImageIndex
                        ? "1px solid #000"
                        : "1px solid #e5e5e5",
                    overflow: "hidden",
                  }}
                  key={index}
                  onClick={() => setActiveImageIndex(index)}
                >
                  <Photo
                    src={thumb?.src!}
                    alt={thumb?.altText! || ""}
                    objectFit="contain"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <SlideButtons ref={(node) => setNextEl(node)} bottom>
              <ArrowRight />
            </SlideButtons>
          </DesktopSwiperContainer>
        )}

        <ActiveImgContainer key={images[activeImageIndex]?.src}>
          {images[activeImageIndex] ? (
            <HoverToZoomClickToLightBox
              src={images[activeImageIndex]?.src!}
              images={images.map((image) => {
                return { src: image?.src! };
              })}
            />
          ) : (
            <ProductPlaceHolder />
          )}
        </ActiveImgContainer>
      </DesktopGalleryContainer>
      <MobileSwiperContainer>
        {total >= 1 && activeImageIndex !== -1 ? (
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            style={{
              height: "100%",
              position: "relative",
            }}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
              renderBullet: function (index, className) {
                const backgroundColor =
                  index + 1 === activeImageIndex ? "#E2E2E2" : "#606060";
                return `<div class="${className}" style="
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  margin: 0;
                  background-color: ${backgroundColor};"
                ></div>`;
              },
            }}
            onInit={(swiper) => {
              setMobileSwiper(swiper);
            }}
            modules={[Pagination]}
          >
            {(images || []).map((thumb, index) => (
              <SwiperSlide
                key={index}
                style={{
                  height: "100%",
                  maxHeight: "512px",
                }}
              >
                <HoverToZoomClickToLightBox
                  src={images[index]?.src!}
                  images={images.map((image) => {
                    return { src: image?.src! };
                  })}
                  badgeBottomPosition="20px"
                />
              </SwiperSlide>
            ))}
            <div
              className="swiper-pagination"
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#fff",
                width: "auto",
                padding: "7px 10px",
                borderRadius: "20px",
                display: "flex",
                gap: "5px",
              }}
            />
          </Swiper>
        ) : (
          <ProductPlaceHolder />
        )}
      </MobileSwiperContainer>
    </GalleryContainer>
  );
};

export default ProductGallery;

/**
 *
 * Styles
 *
 */

const GalleryContainer = styled.div`
  display: flex;
  overflow-anchor: none;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    width: 100%;
    max-width: 512px;
  }
`;

const ActiveImgContainer = styled.div`
  position: relative;
  max-width: 520px;
  height: 520px;
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  background: #fff;
  border-radius: 4px;
`;

const DesktopGalleryContainer = styled.div`
  display: none;
  @media (min-width: 768px) {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: row;
  }
`;

const DesktopSwiperContainer = styled.div`
  width: 120px;
  height: 520px;
  max-height: 520px;
  position: relative;
`;

const MobileSwiperContainer = styled.div`
  width: 100%;
  max-width: 512px;
  aspect-ratio: 1;
  @media (min-width: 768px) {
    display: none;
  }
`;

const SlideButtons = styled.button<{ bottom?: boolean }>`
  display: flex;
  align-items: ${({ bottom }) => (bottom ? "flex-end" : "flex-start")};
  justify-content: center;
  width: 100%;
  height: 20px;
  z-index: 10;
  cursor: pointer;
  top: ${({ bottom }) => (bottom ? "unset" : "0")};
  bottom: ${({ bottom }) => (bottom ? "0" : "unset")};
  background: ${({ bottom }) =>
    bottom
      ? "linear-gradient(rgba(255, 255, 255, 0) 0%, white  70%)"
      : "linear-gradient(white 30%, rgba(255, 255, 255, 0) 100%)"};
  :disabled {
    visibility: hidden;
  }
  border: none;

  position: absolute;

  svg {
    transform: ${({ bottom }) => (bottom ? "rotate(90deg)" : "rotate(-90deg)")};
    color: #000;
    z-index: 11;
    width: 32px;
    height: 32px;
    padding: 10px;
    border-radius: 50%;
    background: #fff;
  }
`;
