import React from "react";
import { Section } from "../../../shared/layout";
import type { UserAccountPageProps } from "../../types";
import { StoreTemplate } from "../../TemplateLoader";

const UserAccountPage: React.FC<UserAccountPageProps> = () => {
  const Template = StoreTemplate.get();

  return (
    <Section background={"backgroundReverse"}>
      <Template.elements.ProfileLayout>
        <Template.elements.UserAccountElement />
      </Template.elements.ProfileLayout>
    </Section>
  );
};

export default UserAccountPage;
