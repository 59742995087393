export const EmptyCartIllustration = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="85"
      height="106"
      viewBox="0 0 85 106"
      fill="none"
    >
      <path
        d="M56.9785 20.1263H77.1088C78.9365 20.1263 80.6893 20.8522 81.9816 22.1443C83.274 23.4364 84 25.1888 84 27.0161V98.1101C84 99.9374 83.274 101.69 81.9816 102.982C80.6893 104.274 78.9365 105 77.1088 105H56.9785V20.2349V20.1263Z"
        stroke="black"
        stroke-width="0.6"
      />
      <path
        d="M57 20H60.7249C61.063 20 61.3874 20.727 61.6265 22.021C61.8657 23.315 62 25.0701 62 26.9001V98.0999C62 99.9299 61.8657 101.685 61.6265 102.979C61.3874 104.273 61.063 105 60.7249 105H57V20.1088V20Z"
        fill="black"
      />
      <path
        d="M7.89124 20.1263H61.6517V101.044C61.6517 102.084 61.2358 103.08 60.4966 103.811C59.7574 104.542 58.7564 104.946 57.7169 104.935H7.89124C4.11064 104.935 1.03578 101.89 1 98.1101V26.9944C1.01198 23.1977 4.09379 20.1262 7.89124 20.1263Z"
        stroke="black"
        stroke-width="0.6"
      />
      <path
        d="M29.1743 49.0551C20.8971 47.7965 14.8184 40.6241 14.9353 32.2542V31.8195C14.9353 30.3431 16.1324 29.1462 17.6091 29.1462C19.0859 29.1462 20.283 30.3431 20.283 31.8195V32.3194C20.1925 38.1103 24.4712 43.0434 30.2177 43.7736C33.3013 44.0854 36.3738 43.0847 38.682 41.0168C40.9903 38.9489 42.321 36.0049 42.3481 32.9062V31.8195C42.3599 30.348 43.5501 29.158 45.022 29.1462C46.5023 29.1461 47.7056 30.3396 47.7176 31.8195V32.9062C47.6826 37.6084 45.6318 42.0696 42.0853 45.1581C38.5389 48.2466 33.8376 49.6656 29.1743 49.0551Z"
        fill="white"
        stroke="black"
        stroke-width="0.8"
      />
      <path
        d="M45.3033 0C54.346 0.0238862 61.6705 7.34703 61.6944 16.3879V20.0828H56.3249V16.3879C56.0628 10.4863 51.201 5.83647 45.2924 5.83647C39.3838 5.83647 34.522 10.4863 34.2599 16.3879V20.0828H28.9121V16.3879C28.9241 7.34208 36.2556 0.0119733 45.3033 0Z"
        fill="black"
      />
    </svg>
  );
};
