import { RendererComponent } from "./types";
import { DiscountBreakdownProps, TemplateElement } from "../templates/types";
import { getTotalDiscounts } from "./utils/getTotalDiscounts";
import { useIntl } from "react-intl";
import { getLocaleInfo } from "../lib/i18n/locales-data";
import { ZERO_MONEY, addMoney } from "../shared/utils/money";
import { useCartData } from "../lib/cartData/useCartData";
import { useStore } from "../lib/storeData";
import { CartItem } from "../generated/graphql";

interface DiscountsBreakdownRendererProps
  extends RendererComponent<DiscountBreakdownProps> {}

const DiscountsBreakdownRenderer: TemplateElement<
  DiscountsBreakdownRendererProps
> = ({ Component, orderItems, applyDefaultTemplateStyles }) => {
  const { cart } = useCartData();

  const intl = useIntl();
  const { currency: currencyCode } = useStore();
  const activeLocale = getLocaleInfo(intl.locale).code;

  const items = (orderItems || cart?.items) as CartItem[];

  const totalDiscounts = getTotalDiscounts(
    items,
    activeLocale,
    intl,
    currencyCode
  );

  const aggregateDiscount = Array.from(totalDiscounts?.values())?.reduce(
    (acc, curr) => addMoney(acc, curr),
    ZERO_MONEY(currencyCode)
  );

  return (
    <Component
      totalDiscounts={totalDiscounts}
      aggregateDiscount={aggregateDiscount}
      applyDefaultTemplateStyles={applyDefaultTemplateStyles}
    />
  );
};

export default DiscountsBreakdownRenderer;
