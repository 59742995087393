import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useShippingDestinationsQuery } from "../../generated/graphql";
import { useStore } from "../../lib/storeData";
import { Label } from "../../shared/globals";
import Asterisk from "../../shared/globals/UiElements/Asterisk";
import SelectWithIcon from "../../shared/globals/UiElements/SelectWithIcon";
import Flex from "../../shared/globals/UiElements/Flex";
import { RequiredSpan } from "./styled";
import {
  ShippingDestinationsMap,
  getShippingDestinations,
} from "../Checkout/Information/utils";

export interface ShippingDestination {
  country?: { value: string; label: string } | null;
  state?: { value: string; label: string } | null;
  city?: { value: string; label: string } | null;
  region?: { value: string; label: string } | null;
  isValid?: boolean;
}

interface ShippingDestinationSelectProps {
  startQuery: boolean;
  value?: ShippingDestination;
  error: boolean;
  onChange?: (selections: ShippingDestination) => void;
}

const ShippingDestinationSelect: React.FC<ShippingDestinationSelectProps> = ({
  startQuery,
  value: selections,
  error,
  onChange,
}) => {
  const intl = useIntl();
  const { storeId } = useStore();

  const { data } = useShippingDestinationsQuery({
    variables: { storeId },
    skip: !startQuery,
  });

  const destinationsMap = useMemo(() => getShippingDestinations(data), [data]);

  return (
    <>
      <div className="!w-full !m-none">
        <Label className="!mt-none">
          <Flex spacing="none" className="text-gray-700">
            <FormattedMessage defaultMessage="Country " />
            <Asterisk />
          </Flex>
        </Label>
        <SelectWithIcon
          dataTest="select-country"
          keyField="value"
          options={getShippingDestinationOptions(destinationsMap)!}
          value={selections?.country}
          className={`!py-[10px] !px-[14px] ${
            error && !destinationsMap.get(selections?.country?.value || "")
              ? "invalid"
              : ""
          }`}
          renderOption={(value: ShippingDestination["country"]) =>
            value?.label!
          }
          placeholder={intl.formatMessage({
            defaultMessage: "Select Country",
          })}
          onChange={(option: any) => {
            onChange?.({
              country: option,
              state: null,
              city: null,
              region: null,
              isValid: false,
            });
          }}
        />
        {error &&
          (!selections?.country ||
            !destinationsMap.get(selections?.country?.value || "")) && (
            <RequiredSpan>
              <FormattedMessage defaultMessage="Select a country" />
            </RequiredSpan>
          )}
      </div>

      <Flex rotateOn="TABLET" flexWrap="wrap">
        <div className="!flex-1">
          <Label className="!mt-none">
            <Flex spacing="none">
              <FormattedMessage defaultMessage="State" />
              <Asterisk />
            </Flex>
          </Label>
          <SelectWithIcon
            dataTest="select-state"
            keyField="value"
            options={
              getShippingDestinationOptions(
                destinationsMap,
                selections?.country?.value
              )!
            }
            value={selections?.state}
            disabled={!selections?.country?.value}
            className={`!py-[10px] !px-[14px] ${
              error && !destinationsMap.get(selections?.state?.value || "")
                ? "invalid"
                : ""
            }`}
            renderOption={(value: ShippingDestination["state"]) =>
              value?.label!
            }
            placeholder={intl.formatMessage({
              defaultMessage: "Select State",
            })}
            onChange={(option: any) => {
              onChange?.({
                country: selections?.country,
                state: option,
                city: null,
                region: null,
                isValid:
                  option.value &&
                  !destinationsMap.get(option?.value || "")?.hasChildren,
              });
            }}
          />
          {error &&
            (!selections?.state ||
              !destinationsMap.get(selections?.state?.value || "")) && (
              <RequiredSpan className="text-gray-700">
                <FormattedMessage defaultMessage="Select a governorate" />
              </RequiredSpan>
            )}
        </div>

        {destinationsMap.get(selections?.state?.value || "")?.hasChildren && (
          <div className="!flex-1">
            <Label className="!mt-none">
              <Flex spacing="none" className="text-gray-700">
                <FormattedMessage defaultMessage="City" />
                <Asterisk />
              </Flex>
            </Label>
            <SelectWithIcon
              dataTest="select-city"
              keyField="value"
              options={
                getShippingDestinationOptions(
                  destinationsMap,
                  selections?.state?.value
                )!
              }
              value={selections?.city}
              disabled={!selections?.state?.value}
              className={`!py-[10px] !px-[14px] ${
                error && !destinationsMap.get(selections?.city?.value || "")
                  ? "invalid"
                  : ""
              }`}
              renderOption={(value: ShippingDestination["city"]) =>
                value?.label!
              }
              placeholder={intl.formatMessage({
                defaultMessage: "Select City",
              })}
              onChange={(option: any) => {
                onChange?.({
                  country: selections?.country,
                  state: selections?.state,
                  city: option,
                  region: null,
                  isValid:
                    option.value &&
                    !destinationsMap.get(option?.value || "")?.hasChildren,
                });
              }}
            />
            {error &&
              (!selections?.city ||
                !destinationsMap.get(selections?.city?.value || "")) && (
                <RequiredSpan>
                  <FormattedMessage defaultMessage="Select a city" />
                </RequiredSpan>
              )}
          </div>
        )}

        {destinationsMap.get(selections?.city?.value || "")?.hasChildren && (
          <div className="!flex-1">
            <Label className="!mt-none">
              <Flex spacing="none" className="text-gray-700">
                <FormattedMessage defaultMessage="Region" />
                <Asterisk />
              </Flex>
            </Label>
            <SelectWithIcon
              dataTest="select-region"
              keyField="value"
              options={
                getShippingDestinationOptions(
                  destinationsMap,
                  selections?.city?.value
                )!
              }
              value={selections?.region}
              disabled={!selections?.city?.value}
              className={`!py-[10px] !px-[14px] ${
                error && !destinationsMap.get(selections?.region?.value || "")
                  ? "invalid"
                  : ""
              }`}
              renderOption={(value: ShippingDestination["region"]) =>
                value?.label!
              }
              placeholder={intl.formatMessage({
                defaultMessage: "Select Region",
              })}
              onChange={(option: any) => {
                onChange?.({
                  country: selections?.country,
                  state: selections?.state,
                  city: selections?.city,
                  region: option,
                  isValid: !!option.value,
                });
              }}
            />
            {error &&
              (!selections?.region ||
                !destinationsMap.get(selections?.region?.value || "")) && (
                <RequiredSpan>
                  <FormattedMessage defaultMessage="Select a region" />
                </RequiredSpan>
              )}
          </div>
        )}
      </Flex>
    </>
  );
};

export default ShippingDestinationSelect;

export function getShippingDestinationOptions(
  shippingDestinationsMap: ShippingDestinationsMap,
  parentId?: string
) {
  if (parentId) {
    return shippingDestinationsMap.get(parentId)?.children?.map((id) => ({
      value: id!,
      label: shippingDestinationsMap.get(id)?.name!,
    }));
  }

  const options: ShippingDestination["country"][] = [];
  shippingDestinationsMap.forEach((item) => {
    if (!item?.parentId) {
      options.push({ label: item?.name!, value: item?.id! });
    }
  });
  return options;
}
