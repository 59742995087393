import ar from "../../../lang/ar.json";
import en from "../../../lang/en.json";
import fr from "../../../lang/fr.json";
import tr from "../../../lang/tr.json";
import de from "../../../lang/de.json";

export function loadMessages() {
  return {
    ar,
    en,
    fr,
    tr,
    de,
  };
}
