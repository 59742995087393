/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import styled, { css } from "styled-components";
import { DetailedHTMLProps, ButtonHTMLAttributes, ReactNode } from "react";
import { themeColor } from "../../shared/styles-utils";
import useRouteChange from "../../hooks/useRouteChange";
import ButtonSpinner from "./ButtonSpinner";
import { useStore } from "../../lib/storeData";

type SizeType = "small" | "medium";

export interface PrimaryButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  prefixIcon?: ReactNode;
  suffixIcon?: ReactNode;
  fullWidth?: boolean;
  target?: string;
  href?: string;
  to?: string;
  size?: SizeType;
  className?: string;
  isActive?: boolean;
  outlined?: boolean;
  reversed?: boolean;
  compact?: boolean;
  transparent?: boolean;
  isLoading?: boolean;
  loadOnRouteChange?: boolean;
  children?: React.ReactNode;
  backgroundColor?: string | null;
  borderColor?: string | null;
  rounded?: boolean;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = React.forwardRef(
  (props, ref) => {
    const {
      href,
      target,
      type = "button",
      children,
      fullWidth,
      prefixIcon,
      suffixIcon,
      isActive,
      outlined,
      reversed,
      compact,
      transparent,
      to,
      isLoading: loadingProp,
      loadOnRouteChange,
      backgroundColor,
      borderColor,
      ...rest
    } = props;

    const { isLoading: pageLoading, url } = useRouteChange();
    const { appearance } = useStore();
    const isLoading =
      loadingProp ||
      (pageLoading && loadOnRouteChange && !!to && url?.includes(to));
    const Spinner = (
      <ButtonSpinner
        color={
          transparent || reversed || outlined
            ? appearance?.colors?.primary!
            : "white"
        }
      />
    );

    let Component: any = "button";
    const buttonProps: any = {
      type,
      ...rest,
    };
    if (href) {
      Component = "a";
      delete buttonProps.type;
      buttonProps.target = target;
      buttonProps.href = href;
    }

    const prefixIconContent = prefixIcon ? (
      <StyledIconContainer>{prefixIcon}</StyledIconContainer>
    ) : null;
    const suffixIconContent = suffixIcon ? (
      <StyledIconContainer>{suffixIcon}</StyledIconContainer>
    ) : null;
    return (
      <StyledButton
        {...props}
        Component={Component}
        buttonProps={buttonProps}
        isLoading={isLoading}
      >
        {prefixIconContent}
        {children}
        {suffixIconContent}
        {isLoading && Spinner}
      </StyledButton>
    );
  }
);

PrimaryButton.displayName = "PrimaryButton";

const StyledButton = styled((props) => {
  const { Component, buttonProps, children, className } = props;
  return (
    <Component {...buttonProps} className={className}>
      {children}
    </Component>
  );
})<{
  reversed?: boolean;
  outlined?: boolean;
  transparent?: boolean;
  isLoading?: boolean;
  size?: SizeType;
  backgroundColor?: string;
  borderColor?: string;
  rounded?: boolean;
}>`
  font-size: 14px;
  background-color: ${({ transparent, backgroundColor }) =>
    transparent
      ? "transparent"
      : backgroundColor
      ? backgroundColor
      : themeColor("primary")};
  font-weight: 600;
  color: ${themeColor("white")};
  border-radius: ${({ rounded }) => (rounded ? "50px" : "4px")};
  border: 1px solid
    ${({ borderColor }) => (borderColor ? borderColor : themeColor("primary"))};
  margin: 0;
  padding: ${({ size }) => (size === "medium" ? "14px 46px" : "10px 16px")};
  cursor: pointer;
  display: flex;
  flex: ${({ fullWidth }) => (fullWidth ? "1 1 auto" : "0 0 auto")};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  line-height: 1.25;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  user-select: none;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  &:focus {
    outline: none;
  }

  &.dimmed {
    opacity: 0.5;
    pointer-events: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `}

  ${({ reversed }) =>
    reversed &&
    css<{ transparent }>`
      background-color: ${({ transparent }) =>
        transparent ? "transparent" : "#fff"};
      border: none;
      color: ${themeColor("primary")};

      &:hover {
        box-shadow: none;
      }
    `}

  ${({ outlined }) =>
    outlined &&
    css`
      background-color: ${themeColor("backgroundReverse")};
      border: 1px solid ${themeColor("primary")};
      color: ${themeColor("primary")};
    `}
      
  ${({ compact }) =>
    compact &&
    css`
      padding: 0;
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
      color: transparent;
    `}
  a {
    color: ${themeColor("primary")};
  }
`;

const StyledIconContainer = styled.div`
  padding: 0 8px;
  line-height: 0;
`;
