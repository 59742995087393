import { useState } from "react";
import styled from "styled-components";
import { Cancel } from "../../../assets/Icons";
import { useStore } from "../../../lib/storeData";
import HTMLRender from "../../../shared/globals/UiElements/HTMLRender";
import { Section } from "../../../shared/layout";
import { rtl } from "../../../shared/styles-utils";
import { ZIndex } from "../../../shared/globals/types";
import { InfoBarProps, TemplateElement } from "../../types";

const InfoBar: TemplateElement<InfoBarProps> = () => {
  const { appearance } = useStore();
  const [isDismissed, setIsDismissed] = useState(false);
  const infoBar = appearance?.infoBar;
  if (!infoBar?.isActive || isDismissed) return null;

  return (
    <StyledBanner backgroundColor={infoBar?.background}>
      <Section>
        <HTMLText html={infoBar?.text} />
      </Section>
      {infoBar?.isDismissible ? (
        <StyledIcon
          color={infoBar?.background}
          onClick={() => setIsDismissed(true)}
        >
          <Cancel />
        </StyledIcon>
      ) : null}
    </StyledBanner>
  );
};

export default InfoBar;

const StyledBanner = styled.div<{ backgroundColor?: string }>`
  position: sticky;
  top: 0;
  z-index: ${ZIndex["OVERLAY"]};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.brand.wash};

  @media (max-width: 768px) {
    position: unset;
  }
`;

const HTMLText = styled(HTMLRender)`
  max-width: calc(100vw - 65px);
`;

const StyledIcon = styled.div<{ color?: string }>`
  position: absolute;
  top: 15px;
  ${rtl("left", "right")}: 10px;
  color: ${({ color }) => color && color};
  cursor: pointer;
  filter: invert(1);
  mix-blend-mode: luminosity;
`;
