import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { PhoneAHW, UserIconAHW } from "../../../assets/Icons";
import { useStore } from "../../../lib/storeData";
import { FlexRow, P } from "../../../shared/globals";
import { Section } from "../../../shared/layout";
import { rtl } from "../../../shared/styles-utils";
import { InfoBarProps, TemplateElement } from "../../types";
import { isAHWStore } from "../../../shared/utils/isAHWStore";
import DefaultInfoBar from "../../default/elements/InfoBar";
import AccountButton from "../components/NavBar/DesktopAccountButton";

const InfoBar: TemplateElement<InfoBarProps> = () => {
  const { contactInfo, storeId } = useStore();

  if (isAHWStore(storeId))
    return (
      <StyledInfoWrapper>
        <Section>
          <FlexRow justifyContent="space-between">
            <P fontSize="14px" color="white">
              <FormattedMessage defaultMessage="SUN-THU 10AM to 6PM" />
            </P>
            <br />

            <FlexRow spacing="m">
              <StyledContactUs>
                <FlexRow>
                  <PhoneAHW />
                  <a href={`tel:${contactInfo?.phone}`}>
                    <FormattedMessage defaultMessage="Contact us" />
                  </a>
                </FlexRow>
              </StyledContactUs>
              <AccountButton color="white" icon={<UserIconAHW />} />
            </FlexRow>
          </FlexRow>
        </Section>
      </StyledInfoWrapper>
    );

  return <DefaultInfoBar />;
};

export default InfoBar;

/**
 *
 * Styles
 *
 */

const StyledInfoWrapper = styled.div`
  background-color: #333;
  padding: 12px 0;
`;

const StyledContactUs = styled.div`
  ${rtl("border-left", "border-right")}: 2px solid #d8d8d8;
  padding-inline-end: 12px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;

  a {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }

  svg {
    margin: 0 10px;
  }
`;
