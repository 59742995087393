import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useStore } from "../../../../lib/storeData";
import StyledStarsRating from "./StyledStarsRating";
import Flex from "../../../../shared/globals/UiElements/Flex";

interface ProductCardReviewsProps {
  totalReviews: number;
  averageRating: number;
  noMarginTop?: boolean;
}

const ProductCardReviews = ({
  totalReviews,
  averageRating,
  noMarginTop,
}: ProductCardReviewsProps) => {
  const { storeReviewSettings: reviewsSettings } = useStore();
  return (
    <Flex
      spacing="xs"
      data-test="product-card-reviews-wrapper"
      className={noMarginTop ? "mt-0" : "mt-xl"}
      flexWrap="wrap"
    >
      {reviewsSettings?.showStars && (
        <StyledStarsRating
          isReadonly
          isFractional
          initialRatingValue={averageRating || 0}
          size={15}
          data-test="product-card-reviews-stars-rating"
        />
      )}
      {reviewsSettings?.showOverAllRating && (
        <span
          className="text-gray-800 text-xs font-medium"
          data-test="product-card-reviews-average"
        >
          <FormattedNumber value={parseFloat(averageRating.toFixed(1)) || 0} />
          {"/"}
          <FormattedNumber value={5} />
        </span>
      )}
      {reviewsSettings?.showReviewsCount && (
        <span
          className="text-gray-500 text-xs font-medium"
          data-test="product-card-reviews-count"
        >
          <FormattedMessage
            defaultMessage="{reviewsCount, plural, =0 {0 reviews} one {1 review} two {# reviews} few {# reviews} many {# reviews} other {# reviews}}"
            values={{
              reviewsCount: totalReviews || 0,
            }}
          />
        </span>
      )}
    </Flex>
  );
};

export default ProductCardReviews;
