import React from "react";
import styled from "styled-components";
import { useAuth } from "../../../../lib/Authentication/hooks/useAuth";
import { Link } from "../../../../lib/i18n";
import {
  filterMePageItems,
  mePageItemsEnum,
} from "../../elements/CustomerProfile/consts";
import { useStore } from "../../../../lib/storeData";
import Flex from "../../../../shared/globals/UiElements/Flex";
import tailwindConfig from "../../../../../tailwind.config";

const AccountButton = () => {
  const { isLoggedIn, logout } = useAuth();
  const { areReviewsActivated } = useStore();
  return (
    <>
      {isLoggedIn && (
        <StyledContainer className="text-gray-700">
          {filterMePageItems(areReviewsActivated).map((item) =>
            item.id !== mePageItemsEnum.LogOut ? (
              <Link key={item.id} href={`/me/${item.slug}/`}>
                <StyledButton>
                  {item.icon}
                  {item.title}
                </StyledButton>
              </Link>
            ) : (
              <div className="border-t border-gray-200" key={item.id}>
                <StyledButton onClick={logout}>
                  <Flex
                    className="rounded-full shadow-xs border border-gray-300 py-xs px-md"
                    justifyContent="center"
                    alignItems="center "
                    fullWidth
                  >
                    {item.icon}
                    {item.title}
                  </Flex>
                </StyledButton>
              </div>
            )
          )}
        </StyledContainer>
      )}
    </>
  );
};

export default AccountButton;

const StyledContainer = styled.div`
  width: 100%;
`;

const StyledButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  font-weight: 500;
  font-size: 16px;
  color: ${tailwindConfig.theme.extend.colors.gray[700]};
  svg {
    margin-inline-end: 5px;
  }
`;
