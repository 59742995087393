import React from "react";
import styled from "styled-components";
import { FlexRow } from "../../../shared/globals";
import { themeColor } from "../../../shared/styles-utils";
import { HeroBtnLink } from "../../../components/Hero/HeroBtnLink";
import type { HeroProps } from "../../types";
import { Photo } from "../../../shared/globals/UiElements/Photo";
import {
  HeroBox,
  HeroContainer,
  Heading,
  ButtonsWrapper,
  HeroBtn,
  SecondButton,
  Text,
  NavigationContainer,
  NavigationCircle,
} from "../../../components/Hero";
import { Section } from "../../../shared/layout";

const Hero: React.FC<HeroProps> = ({
  heroSettings,
  activeSlide,
  isButton1Exist,
  isButton2Exist,
  image,
  setActiveSlide,
  hasOverlay,
}) => {
  return (
    <StyledSection>
      <HeroBox align={activeSlide?.align!}>
        {image && (
          <Photo src={image || ""} objectFit="cover" priority absolute />
        )}
        <StyledHeroContainer hasOverlay={hasOverlay!}>
          <Heading
            headingColor={activeSlide?.subHeadingColor!}
            align={activeSlide?.align!}
          >
            {activeSlide?.headingText}
          </Heading>
          <Text
            subHeadingColor={activeSlide?.subHeadingColor!}
            subHeadingSize={String(activeSlide?.subHeadingSize!)}
            align={activeSlide?.align!}
          >
            {activeSlide?.subHeadingText}
          </Text>
          <ButtonsWrapper align={activeSlide?.align!}>
            {!isButton1Exist ? null : (
              <HeroBtnLink {...activeSlide?.buttons?.[0]?.link}>
                <HeroBtn>{activeSlide?.buttons?.[0]?.text}</HeroBtn>
              </HeroBtnLink>
            )}
            {!isButton2Exist ? null : (
              <HeroBtnLink {...activeSlide?.buttons?.[1]?.link}>
                <SecondButton>{activeSlide?.buttons?.[1]?.text}</SecondButton>
              </HeroBtnLink>
            )}
          </ButtonsWrapper>
        </StyledHeroContainer>
        {heroSettings?.length! > 1 && (
          <StyledNavigationContainer>
            <FlexRow>
              {heroSettings?.map((hero, i) => {
                return (
                  <StyledNavigationCircle
                    key={hero?.id}
                    className={hero?.id === activeSlide?.id ? "active" : ""}
                    onClick={() => setActiveSlide?.(heroSettings?.[i])}
                  />
                );
              })}
            </FlexRow>
          </StyledNavigationContainer>
        )}
      </HeroBox>
    </StyledSection>
  );
};

export default Hero;

/**
 *
 * Styles
 *
 */

const StyledSection = styled(Section)`
  && {
    margin: 30px auto 0;
    img {
      border-radius: 8px;
    }
    @media (max-width: 768px) {
      padding: 0;
      img {
        border-radius: 0;
      }
    }
  }
`;

const StyledHeroContainer = styled(HeroContainer)`
  border-radius: 8px;
  @media (max-width: 768px) {
    border-radius: 0;
  }
`;

const StyledNavigationContainer = styled(NavigationContainer)`
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 20px;
  padding: 10px 20px;
`;

const StyledNavigationCircle = styled(NavigationCircle)`
  background-color: #e2e2e2;

  &.active {
    background-color: ${themeColor("primary")};
    border: none;
  }
`;
