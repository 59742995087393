import { FormattedMessage } from "react-intl";
import {
  PriceWrapper,
  StyledH3,
  StyledNonDiscountedVariants,
  StyledNumber,
  StyledTD,
} from "./styled";
import { CartItemChildrenProps } from "./types";
import { TwoDashTrashIcon } from "../../../../assets/Icons";
import { DiscountPrice, Price } from "../../../../components/Price";
import { QuantityInput } from "../QuantityInput";
import {
  CartCustomItem,
  CartSimpleItem,
  ProductType,
  CheckoutServiceErrorCodes as ErrorCodes,
} from "../../../../generated/graphql";
import { FlexCol, FlexRow, P, Span } from "../../../../shared/globals";
import Alert from "../../../../shared/globals/UiElements/Alert";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import CartItemInfo from "./CartItemInfo";
import {
  DefaultTextPrice,
  PriceProps,
} from "../../../../components/Price/Price";
import { useCartData } from "../../../../lib/cartData/useCartData";
import { CustomButton } from "../Button/CustomButton";
import { theme } from "../../../../../tailwind.config";
import { subtractMoney } from "../../../../shared/utils/money";
import { isProductNotAvailableError } from "../../../../shared/utils/error-utils";

const CartItemDesktop = ({
  item,
  isCustomDiscountApplied,
  isAutomaticDiscountApplied,
  discountedSimpleItemsCount,
  nonDiscountedSimpleItemsCount,
  isAllItemsDiscounted,
  itemErrors,
  value,
  setValue,
  isAlertOpen,
  setIsAlertOpen,
  onQuantityChange,
  onRemoveCartItem,
}: CartItemChildrenProps) => {
  const isProductNotAvailableErrorFound =
    isProductNotAvailableError(itemErrors);

  const isItemSimple = item?.product?.type === ProductType.Simple;

  const typedSimpleItem = item as CartSimpleItem;
  const typedCustomItem = item as CartCustomItem;

  const totalPricePerSimpleItem: PriceProps["money"] = subtractMoney(
    typedSimpleItem.price!,
    typedSimpleItem.discount?.perItem
  );

  const totalPricePerCustomItem: PriceProps["money"] = subtractMoney(
    typedCustomItem.price!,
    typedCustomItem.discount?.perItem
  );

  const {
    cart: { isLoading },
  } = useCartData();

  return (
    <>
      <tr style={{ borderBottom: itemErrors?.length ? "0" : "" }}>
        <StyledTD>
          <FlexRow alignItems="start">
            <ItemImg
              imgDetails={
                typedSimpleItem?.variant?.image ||
                typedCustomItem?.product?.images[0]
              }
            />

            <CartItemInfo
              item={item}
              isCustomDiscountApplied={isCustomDiscountApplied}
              isAutomaticDiscountApplied={isAutomaticDiscountApplied}
              discountedSimpleItemsCount={discountedSimpleItemsCount}
              isAllItemsDiscounted={isAllItemsDiscounted}
            />
          </FlexRow>
          {isAutomaticDiscountApplied && !isAllItemsDiscounted && (
            <StyledNonDiscountedVariants>
              <FlexRow>
                <StyledNumber>{nonDiscountedSimpleItemsCount}x</StyledNumber>
                <StyledH3>
                  {typedSimpleItem.variant.selectedOptions
                    ?.map((option) => option.value.name)
                    .join(" / ")}
                </StyledH3>
              </FlexRow>
              <PriceWrapper isNotActive={isProductNotAvailableErrorFound}>
                <DefaultTextPrice money={typedSimpleItem.price} />
              </PriceWrapper>
            </StyledNonDiscountedVariants>
          )}
        </StyledTD>
        <StyledTD>
          <PriceWrapper isNotActive={isProductNotAvailableErrorFound}>
            {(isAutomaticDiscountApplied || isCustomDiscountApplied) && (
              <DiscountPrice style={{ fontWeight: 600 }} money={item.price} />
            )}
            <Price
              className="text-gray-600"
              money={
                isItemSimple ? totalPricePerSimpleItem : totalPricePerCustomItem
              }
            />
          </PriceWrapper>
        </StyledTD>
        <StyledTD>
          <QuantityInput
            max={
              typedSimpleItem?.variant?.maxPerCart || Number.POSITIVE_INFINITY
            }
            value={item.quantity}
            onChange={(value) => {
              setValue(value);
              onQuantityChange?.(value);
            }}
            disabled={isProductNotAvailableErrorFound}
          />
          {isAlertOpen && (
            <Alert
              message={
                value >
                (typedSimpleItem?.variant?.maxPerCart ||
                  Number.POSITIVE_INFINITY) ? (
                  <FormattedMessage defaultMessage="Quantity exceeded" />
                ) : (
                  <FormattedMessage
                    defaultMessage="Minimum quantity per order {min}. You can add up to {max} per order"
                    values={{
                      min:
                        typedSimpleItem?.variant?.minPerCart ||
                        Number.POSITIVE_INFINITY,
                      max:
                        typedSimpleItem?.variant?.maxPerCart ||
                        Number.POSITIVE_INFINITY,
                    }}
                  />
                )
              }
              style={{ position: "absolute" }}
              closeAction={() => setIsAlertOpen(false)}
            />
          )}
        </StyledTD>
        <StyledTD>
          <PriceWrapper isNotActive={isProductNotAvailableErrorFound}>
            {isLoading ? (
              <Span className="!text-[#475467]">
                <P fontWeight="600" fontSize="14px">
                  <FormattedMessage defaultMessage="Calculating..." />
                </P>
              </Span>
            ) : (
              <>
                {(isAutomaticDiscountApplied || isCustomDiscountApplied) && (
                  <DiscountPrice
                    style={{ fontWeight: 600 }}
                    money={item?.subtotal}
                  />
                )}
                <Price className="!text-[#475467]" money={item?.totalPrice} />
              </>
            )}
          </PriceWrapper>
        </StyledTD>
        <StyledTD>
          <CustomButton
            data-test="button-remove-item"
            onClick={() =>
              onRemoveCartItem(
                typedSimpleItem?.variant?.id || typedCustomItem?.product?.id
              )
            }
            isDisabled={isLoading}
            compact
            customDefault={{
              backgroundColor: "transparent",
              color: theme.extend.colors.primary[500],
              borderColor: "transparent",
            }}
            onlyIcon
            size="xs"
          >
            <TwoDashTrashIcon />
          </CustomButton>
        </StyledTD>
      </tr>
      {!!itemErrors?.length && (
        <tr className="!border-t-0">
          <td colSpan={5} style={{ paddingTop: 0, paddingBottom: 20 }}>
            <FlexCol spacing="s">
              {itemErrors?.map((itemError, i) => (
                <Alert
                  key={i}
                  type={
                    itemError.code === ErrorCodes.PriceChanged
                      ? "warn"
                      : undefined
                  }
                  message={itemError.message}
                />
              ))}
            </FlexCol>
          </td>
        </tr>
      )}
    </>
  );
};

export default CartItemDesktop;
