import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import createDecorator from "final-form-focus";
import { useIntl } from "react-intl";
import type {
  ForgotPasswordFormProps,
  ForgotPasswordFormValues,
  TemplateElement,
} from "../templates/types";
import { RendererComponent } from "./types";
import {
  ForgotPasswordDocument,
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables,
} from "../generated/graphql";
import { useStore } from "../lib/storeData";
import { getLocaleInfo } from "../lib/i18n/locales-data";

const focusOnError = createDecorator<ForgotPasswordFormValues>();

interface ForgotPasswordFormRendererProps
  extends RendererComponent<ForgotPasswordFormProps> {}

const ForgotPasswordFormRenderer: TemplateElement<
  ForgotPasswordFormRendererProps
> = ({ Component }) => {
  const { storeId } = useStore();
  const { mutate } = useApolloClient();
  const { locale } = useIntl();
  const activeLocale = getLocaleInfo(locale).code;

  const [isInvalid, setIsInvalid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmitForm = async (values: ForgotPasswordFormValues) => {
    setIsInvalid(false);
    try {
      await mutate<ForgotPasswordMutation, ForgotPasswordMutationVariables>({
        mutation: ForgotPasswordDocument,
        variables: {
          storeId,
          storeUrl: document.location.origin,
          email: values.email,
          locale: activeLocale,
        },
      });
      setIsSubmitted(true);
    } catch (error) {
      setIsInvalid(true);
    }
  };

  return (
    <Component
      isInvalid={isInvalid}
      isSubmitted={isSubmitted}
      onSubmit={handleSubmitForm}
      focusOnError={focusOnError}
    />
  );
};

export default ForgotPasswordFormRenderer;
