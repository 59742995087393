import React from "react";
import { useIntl } from "react-intl";
import {
  StepsHolder,
  StyledStepItem,
  StepBar,
  StepLabel,
  StepMarker,
} from "./styled";
import { Ok } from "../../assets/Icons";
import useCheckout from "../../contexts/CartContext/useCheckout";
import { getStepsMessages } from "../../contexts/CartContext/checkoutUtils";
import { useRouter } from "../../lib/i18n";

interface StepItemProps {
  active: boolean;
  done: boolean;
  step: string;
  stepNumber: number;
}

const StepItem = ({ active, done, step, stepNumber }: StepItemProps) => {
  return (
    <StyledStepItem>
      <StepBar done={done} />
      <StepMarker justifyContent="center" done={done} active={active}>
        {done ? <Ok /> : stepNumber}
      </StepMarker>
      <StepLabel active={active || done}>{step}</StepLabel>
    </StyledStepItem>
  );
};

const BarTracker = () => {
  const intl = useIntl();
  const { route } = useRouter();
  const { checkout } = useCheckout();
  const stepsMessages = getStepsMessages(intl, route);
  const currentStep = checkout?.meta?.activeStepInfo;
  const isConfirmationPage = currentStep?.name === "Confirmation";

  if (isConfirmationPage) return null;

  return (
    <StepsHolder>
      {stepsMessages.map((step, index) => {
        return (
          <StepItem
            active={currentStep.number === index + 1}
            done={currentStep.number > index + 1}
            step={step}
            stepNumber={index + 1}
            key={`step${index}`}
          />
        );
      })}
    </StepsHolder>
  );
};

export { BarTracker };
