import { FC } from "react";
import useBreakPoints from "../../../shared/utils/useBreakPoints";
import { FilterAndSortProps } from "../../types";
import { Section } from "../../../shared/layout";
import LoadingSpinner from "../../../shared/globals/UiElements/LoadingSpinner";
import DesktopFilterSort from "../components/Filter/DesktopFilterSort";
import MobileFilterSort from "../components/Filter/MobileFilterSort";

const FilterAndSort: FC<FilterAndSortProps> = ({
  products,
  itemPerPage,
  productsTotalCount,
  offset,
  loading,
}) => {
  const { isTablet, isLoading } = useBreakPoints();
  if (isLoading) return <LoadingSpinner />;
  return isTablet ? (
    <MobileFilterSort
      products={products}
      itemPerPage={itemPerPage}
      productsTotalCount={productsTotalCount}
      offset={offset}
      loading={loading}
    />
  ) : (
    <Section background="backgroundReverse">
      <DesktopFilterSort
        products={products}
        itemPerPage={itemPerPage}
        productsTotalCount={productsTotalCount}
        offset={offset}
        loading={loading}
      />
    </Section>
  );
};

export default FilterAndSort;
