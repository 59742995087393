import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "../Button";
import {
  BuyNowButtonDisplay,
  ProductBasicInfoFragment,
  ProductInfoFragment,
} from "../../generated/graphql";
import { useStore } from "../../lib/storeData";
import { BuildStateCategory } from "../../templates/types";
import { useState } from "react";
import HandleBuyNow from "../../shared/utils/handleBuyNow";
import { useCartData } from "../../lib/cartData/useCartData";
import BuyNowConfirmModal from "../../shared/globals/UiElements/BuyNowConfirmModal";

interface BuyNowButtonProps {
  product:
    | ProductBasicInfoFragment
    | (Omit<ProductInfoFragment, "categories"> & {
        categories: BuildStateCategory[];
      });
  isDisabled?: boolean;
  isSlider?: boolean;
  isHovered?: boolean;
  isAlwaysVisible?: boolean;
  isCard?: boolean;
  quantity?: number;
  buildState?: BuildStateCategory[] | undefined;
  className?: string;
  rounded?: boolean;
}

const BuyNowButton = ({
  product,
  isDisabled,
  isSlider,
  isHovered,
  isAlwaysVisible,
  isCard,
  quantity = 1,
  buildState,
  className,
  rounded,
}: BuyNowButtonProps) => {
  const { appearance } = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { cart } = useCartData();

  const handleBuyNow = HandleBuyNow({
    buildState,
    product,
    quantity,
  });

  return (
    <>
      <BuyNowButtonContainer
        disabled={isDisabled}
        isSlider={isSlider}
        className={className}
      >
        <BuyNowButtonComponent
          data-test="button-buy-now"
          fullWidth
          disabled={isDisabled}
          backgroundColor={
            appearance?.productDisplay?.buyNowButton?.backgroundColor || "#fff"
          }
          fontColor={appearance?.productDisplay?.buyNowButton?.fontColor}
          borderColor={appearance?.productDisplay?.buyNowButton?.borderColor}
          visibility={appearance?.productDisplay?.buyNowButton?.display}
          isHovered={isHovered}
          isAlwaysVisible={isAlwaysVisible}
          isCard={isCard}
          onClick={() =>
            cart?.items?.length ? setIsModalOpen(true) : handleBuyNow()
          }
          rounded={rounded}
        >
          <FormattedMessage defaultMessage="Buy Now" />
        </BuyNowButtonComponent>
      </BuyNowButtonContainer>
      <BuyNowConfirmModal
        onClose={() => setIsModalOpen(false)}
        productName={product.title}
        show={isModalOpen}
        handleBuyNow={handleBuyNow}
        loading={cart?.isLoading}
      />
    </>
  );
};

const BuyNowButtonContainer = styled.div<{
  disabled?: boolean;
  isSlider?: boolean;
}>`
  width: 100%;
  height: auto;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  margin-inline-start: ${({ isSlider }) => (isSlider ? "-10px" : "auto")};
  @media (min-width: 768px) {
    margin-inline-start: auto;
  }
`;

const BuyNowButtonComponent = styled(PrimaryButton)<{
  fontColor?: React.CSSProperties["color"] | undefined | null;
  visibility?: BuyNowButtonDisplay | undefined | null;
  isHovered?: boolean;
  isAlwaysVisible?: boolean;
  isCard?: boolean;
}>`
  font-size: ${({ isCard }) => (isCard ? "12px" : "14px")};
  padding: ${({ isCard }) => isCard && "5px"};
  height: ${({ isCard }) => (isCard ? "auto" : "100%")};
  color: ${({ theme, fontColor }) => fontColor || theme.colors.primary};
  visibility: ${({ visibility }) =>
    visibility !== BuyNowButtonDisplay.None ? "visible" : "hidden"};
  min-height: ${({ isCard }) => isCard && "40px"};

  @media (min-width: 768px) {
    padding: ${({ isCard }) => isCard && "6px 0"};
    font-size: 14px;
    visibility: ${({ visibility, isHovered, isAlwaysVisible }) =>
      isAlwaysVisible
        ? "visible"
        : visibility === BuyNowButtonDisplay.None ||
          (visibility === BuyNowButtonDisplay.OnHover && !isHovered)
        ? "hidden"
        : "visible"};
  }
`;

export default BuyNowButton;
