import React from "react";
import { Card } from "../../../shared/globals/UiElements/Card";
import { Section } from "../../../shared/layout";
import type { ResetPasswordPageProps } from "../../types";
import { StoreTemplate } from "../../TemplateLoader";

const ResetPassword: React.FC<ResetPasswordPageProps> = ({ token }) => {
  const Template = StoreTemplate.get();
  return (
    <div className="py-12">
      <Section>
        <Card paddingSize="2xl" className="mx-auto md:max-w-[500px]">
          {token ? (
            // @ts-expect-error: should expect ResetPasswordFormProps to be required but handled in its renderer
            <Template.elements.ResetPasswordForm token={token} />
          ) : (
            // @ts-expect-error: should expect ForgotPasswordFormProps to be required but handled in its renderer
            <Template.elements.ForgotPasswordForm />
          )}
        </Card>
      </Section>
    </div>
  );
};

export default ResetPassword;
