import styled from "styled-components";
import { FlexRow, H3 } from "../../../../shared/globals";
import { rtl, themeColor } from "../../../../shared/styles-utils";
import { RequiredSpan } from "../../../../components/Checkout/Information/styled";

export const FeedbackMessageContainer = styled(FlexRow)<{ isSuccess: boolean }>`
  background: ${({ isSuccess }) => (isSuccess ? " #f0fdfa" : "#FFEDF6")};
  padding: 10px 20px;
  border: 1px solid #ccfbf1;
  border-radius: 6px;
  margin: 0 20px;

  > h4 {
    color: ${({ isSuccess }) => (isSuccess ? " #115e59" : "#d21c1c")};
  }

  > svg {
    ${rtl("margin-left", "margin-right")}: 10px;
  }
`;

export const StyledH3 = styled(H3)`
  color: ${themeColor("primary")};
`;

export { RequiredSpan };
