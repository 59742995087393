import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { TemplateElement } from "../../../types";
import { AlsoLikeProps } from "./AlsoLikeProps";
import { StoreTemplate } from "../../../TemplateLoader";
import { useStore } from "../../../../lib/storeData";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { Link } from "../../../../lib/i18n";
import { P } from "../../../../shared/globals";
import { themeColor } from "../../../../shared/styles-utils";

const AlsoLike: TemplateElement<AlsoLikeProps> = ({
  products,
  isRandom,
  numberOfProducts,
  showSeeMore,
}) => {
  const Template = StoreTemplate.get();
  const { appearance } = useStore();
  return (
    <Flex column spacing="s">
      <Flex fullWidth justifyContent="space-between">
        <MayLike>
          {isRandom ? (
            <FormattedMessage defaultMessage="You May Also Like" />
          ) : (
            <FormattedMessage defaultMessage="Similar products" />
          )}
        </MayLike>
        {showSeeMore && (
          <SeeProducts>
            <Link href="/shop">
              <FormattedMessage defaultMessage="See all products" />
            </Link>
          </SeeProducts>
        )}
      </Flex>
      <Template.elements.ProductsList
        products={products?.slice(0, numberOfProducts)}
        showTwoProductsPerRowOn={
          appearance?.template?.handle === "black-and-white"
            ? "TABLET"
            : undefined
        }
      />
    </Flex>
  );
};

export default AlsoLike;

/**
 *
 * Styles
 *
 */

const MayLike = styled(P)`
  font-size: 16px;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const SeeProducts = styled(P)`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;

  a {
    text-decoration: none;
    color: ${themeColor("primary")};

    &:hover {
      color: ${({ theme }) => theme.brand.default};
    }
  }
`;
