const ApplePay = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="currentColor"
        d="M16.789 13.911c0 .956-.583 1.506-1.611 1.506h-1.35v-3.011h1.356c1.022 0 1.605.544 1.605 1.505zm2.639 3.478c0 .461.4.761 1.028.761c.8 0 1.4-.505 1.4-1.217v-.428l-1.306.083c-.739.05-1.122.322-1.122.8zM32 6.167v19.556a2.668 2.668 0 0 1-2.667 2.667H2.666a2.668 2.668 0 0 1-2.667-2.667V6.167A2.668 2.668 0 0 1 2.666 3.5h26.667A2.668 2.668 0 0 1 32 6.167zM7.1 12.733c.467.039.933-.233 1.228-.578c.289-.356.478-.833.428-1.317c-.411.017-.922.272-1.217.628c-.267.306-.494.8-.439 1.267zm3.367 4.139c-.011-.011-1.089-.422-1.1-1.667c-.011-1.039.85-1.539.889-1.567c-.489-.722-1.245-.8-1.506-.817c-.678-.039-1.256.383-1.578.383c-.328 0-.817-.367-1.35-.355a1.993 1.993 0 0 0-1.694 1.033c-.728 1.256-.189 3.111.517 4.133c.344.505.761 1.061 1.306 1.039c.516-.022.722-.333 1.344-.333c.628 0 .806.333 1.35.328c.567-.011.917-.505 1.267-1.011c.383-.578.544-1.133.556-1.167zm7.522-2.966c0-1.478-1.028-2.489-2.495-2.489H12.65v7.578h1.178v-2.589h1.628c1.489 0 2.533-1.022 2.533-2.5zm5 1.316c0-1.094-.878-1.8-2.222-1.8c-1.25 0-2.172.717-2.206 1.694h1.061c.089-.467.522-.772 1.111-.772c.722 0 1.122.333 1.122.956v.417l-1.467.089c-1.367.083-2.105.645-2.105 1.617c0 .983.761 1.633 1.855 1.633c.739 0 1.422-.372 1.733-.967h.022V19h1.089v-3.778zm5.678-1.728h-1.194l-1.383 4.478h-.022l-1.383-4.478h-1.239l1.994 5.517l-.105.333c-.178.567-.472.789-.994.789a5.61 5.61 0 0 1-.345-.017v.911c.067.022.361.028.45.028c1.15 0 1.689-.439 2.161-1.767z"
      />
    </svg>
  );
};

export { ApplePay };
