import { useQuery } from "@apollo/client";
import { AuthState, USER } from "../../apollo/client-graphql";
import { logout } from "../utils";
import { updateUserVar } from "../../apollo/apollo-client";

export type RegisterType = "login" | "signup";

export type User = {
  id?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
};

interface AuthHook {
  isLoggedIn: boolean;
  user?: User;
  logout: () => void;
  updateUser: (newUserData: User) => void;
}

const useAuth = (): AuthHook => {
  const { data } = useQuery<{ user: AuthState }>(USER);

  const updateUser = (newUserData: User) => {
    const updatedUser: AuthState = {
      ...data?.user,
      ...newUserData,
    };
    updateUserVar(updatedUser);
  };

  return {
    isLoggedIn: !!data?.user?.id,
    user: data?.user,
    logout,
    updateUser,
  };
};

export { useAuth };
