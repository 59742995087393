import React from "react";

const EmptyPageIllustration = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.5156 79.1719C58.0547 79.1719 57.5983 79.0808 57.1726 78.904C56.747 78.7271 56.3604 78.4679 56.0352 78.1412C55.71 77.8146 55.4525 77.4269 55.2775 77.0004C55.1026 76.574 55.0136 76.1172 55.0156 75.6563V70.5781H44.9844V75.6563C44.9864 76.1172 44.8974 76.574 44.7225 77.0004C44.5475 77.4269 44.29 77.8146 43.9648 78.1412C43.6396 78.4679 43.253 78.7271 42.8274 78.904C42.4017 79.0808 41.9453 79.1719 41.4844 79.1719C40.0133 79.1719 38.6024 79.7563 37.5621 80.7965C36.5219 81.8368 35.9375 83.2476 35.9375 84.7188C35.9416 85.4578 36.2381 86.1652 36.7622 86.6864C37.2863 87.2075 37.9953 87.5 38.7344 87.5H61.2656C62.0047 87.5 62.7137 87.2075 63.2378 86.6864C63.7619 86.1652 64.0584 85.4578 64.0625 84.7188C64.0625 83.2476 63.4781 81.8368 62.4379 80.7965C61.3976 79.7563 59.9867 79.1719 58.5156 79.1719Z"
        fill="#1D2939"
      />
      <path
        d="M58.5156 79.1719H41.4844C40.0133 79.1719 38.6024 79.7563 37.5621 80.7965C36.5219 81.8368 35.9375 83.2476 35.9375 84.7188C35.9416 85.4578 36.2381 86.1652 36.7622 86.6864C37.2863 87.2075 37.9953 87.5 38.7344 87.5H61.2656C62.0047 87.5 62.7137 87.2075 63.2378 86.6864C63.7619 86.1652 64.0584 85.4578 64.0625 84.7188C64.0625 83.2476 63.4781 81.8368 62.4379 80.7965C61.3976 79.7563 59.9867 79.1719 58.5156 79.1719Z"
        fill="#667085"
      />
      <path
        d="M81.1875 20.5781H18.8125C15.3262 20.5781 12.5 23.4043 12.5 26.8906V67.3906C12.5 70.8769 15.3262 73.7031 18.8125 73.7031H81.1875C84.6738 73.7031 87.5 70.8769 87.5 67.3906V26.8906C87.5 23.4043 84.6738 20.5781 81.1875 20.5781Z"
        fill="#DFE8F4"
      />
      <path
        d="M12.5 64.6562V67.3906C12.5 69.0482 13.1585 70.6379 14.3306 71.81C15.5027 72.9821 17.0924 73.6406 18.75 73.6406H81.25C82.9076 73.6406 84.4973 72.9821 85.6694 71.81C86.8415 70.6379 87.5 69.0482 87.5 67.3906V64.6562H12.5Z"
        fill="#667085"
      />
      <path d="M87.5 61.4219H12.5V64.6406H87.5V61.4219Z" fill="#CCD8E5" />
      <path
        d="M50 70.8906C50.9492 70.8906 51.7187 70.1211 51.7187 69.1719C51.7187 68.2226 50.9492 67.4531 50 67.4531C49.0508 67.4531 48.2812 68.2226 48.2812 69.1719C48.2812 70.1211 49.0508 70.8906 50 70.8906Z"
        fill="#1D2939"
      />
      <path
        d="M78.2344 38.125H57.3125V39.9844H78.2344V38.125Z"
        fill="#CCD8E5"
      />
      <path
        d="M78.2344 42.6562H57.3125V44.5156H78.2344V42.6562Z"
        fill="#CCD8E5"
      />
      <path
        d="M67.7656 47.2031H57.3125V49.0625H67.7656V47.2031Z"
        fill="#CCD8E5"
      />
      <path d="M55.125 38.125H53.0781V39.9844H55.125V38.125Z" fill="#CCD8E5" />
      <path
        d="M55.125 42.6562H53.0781V44.5156H55.125V42.6562Z"
        fill="#CCD8E5"
      />
      <path
        d="M55.125 47.2031H53.0781V49.0625H55.125V47.2031Z"
        fill="#CCD8E5"
      />
      <path
        d="M43.2815 48.6408H30.4065C30.1008 48.6366 29.8051 48.5317 29.565 48.3425C29.3249 48.1532 29.1539 47.8902 29.0784 47.594L26.7659 38.3283C26.7156 38.1239 26.7129 37.9106 26.7581 37.705C26.8033 37.4993 26.8951 37.3068 27.0264 37.1422C27.1578 36.9777 27.3251 36.8455 27.5157 36.7559C27.7062 36.6662 27.9147 36.6215 28.1252 36.6252H45.6096C45.8121 36.6354 46.0099 36.6903 46.1888 36.7859C46.3676 36.8816 46.5231 37.0156 46.644 37.1783C46.765 37.3411 46.8484 37.5286 46.8884 37.7274C46.9284 37.9263 46.9239 38.1315 46.8752 38.3283L44.5471 47.594C44.4769 47.8814 44.3158 48.1385 44.0878 48.3271C43.8598 48.5157 43.577 48.6258 43.2815 48.6408Z"
        fill="white"
      />
      <path
        d="M33.25 53.125C33.2498 53.5355 33.1271 53.9367 32.8976 54.2771C32.6681 54.6175 32.3422 54.8816 31.9617 55.0357C31.5811 55.1898 31.1633 55.2268 30.7616 55.1421C30.3599 55.0573 29.9926 54.8546 29.7068 54.5598C29.421 54.2651 29.2297 53.8918 29.1573 53.4877C29.0849 53.0835 29.1348 52.667 29.3005 52.2914C29.4663 51.9158 29.7403 51.5982 30.0876 51.3793C30.4349 51.1603 30.8396 51.05 31.25 51.0625C31.7861 51.0787 32.2948 51.3032 32.6682 51.6882C33.0415 52.0733 33.2502 52.5886 33.25 53.125Z"
        fill="white"
      />
      <path
        d="M44.5624 53.125C44.5655 53.5336 44.4472 53.9339 44.2225 54.2751C43.9977 54.6163 43.6767 54.8831 43.3001 55.0416C42.9235 55.2001 42.5083 55.2432 42.1072 55.1654C41.7061 55.0876 41.3372 54.8924 41.0471 54.6046C40.7571 54.3168 40.5591 53.9493 40.4783 53.5488C40.3974 53.1483 40.4374 52.7328 40.593 52.355C40.7486 51.9772 41.013 51.6542 41.3525 51.4268C41.692 51.1995 42.0914 51.0781 42.4999 51.0781C43.0443 51.0781 43.5665 51.2933 43.9529 51.6767C44.3392 52.0601 44.5583 52.5807 44.5624 53.125Z"
        fill="white"
      />
      <path
        d="M30.9064 42.2656L28.5939 33C28.5243 32.7162 28.3621 32.4638 28.1328 32.2826C27.9036 32.1015 27.6205 32.002 27.3283 32H23.1408C22.9532 31.9797 22.7635 31.9991 22.5839 32.057C22.4043 32.1148 22.2389 32.2099 22.0985 32.3359C21.9581 32.4619 21.8458 32.6161 21.7689 32.7884C21.6921 32.9607 21.6523 33.1473 21.6523 33.3359C21.6523 33.5246 21.6921 33.7112 21.7689 33.8835C21.8458 34.0558 21.9581 34.2099 22.0985 34.336C22.2389 34.462 22.4043 34.557 22.5839 34.6149C22.7635 34.6728 22.9532 34.6922 23.1408 34.6719H25.0939C25.3103 34.6697 25.5211 34.7412 25.6915 34.8746C25.8619 35.0081 25.9817 35.1956 26.0314 35.4063L27.7502 42.2656C27.8262 42.5602 27.9979 42.8213 28.2382 43.0079C28.4786 43.1945 28.774 43.2962 29.0783 43.2969H32.2033C31.9044 43.2894 31.6161 43.1846 31.3821 42.9985C31.148 42.8124 30.9811 42.5551 30.9064 42.2656Z"
        fill="white"
      />
      <g clip-path="url(#clip0_1167_46207)">
        <path
          d="M98.8902 43.1198C98.6109 43.404 98.2779 43.6298 97.9104 43.7839C97.543 43.938 97.1486 44.0173 96.7502 44.0173C96.3517 44.0173 95.9573 43.938 95.5899 43.7839C95.2224 43.6298 94.8894 43.404 94.6102 43.1198L85.4601 33.9998C84.8926 33.4336 84.5732 32.6651 84.5723 31.8633C84.5713 31.0616 84.8889 30.2924 85.4552 29.7248C86.0214 29.1572 86.7899 28.8379 87.5916 28.8369C88.3933 28.836 89.1626 29.1536 89.7302 29.7198L98.8902 38.8798C99.452 39.4423 99.7675 40.2048 99.7675 40.9998C99.7675 41.7948 99.452 42.5573 98.8902 43.1198Z"
          fill="#DFE8F4"
        />
        <path
          d="M69.7498 30.39C67.811 28.9014 66.387 26.843 65.6779 24.5037C64.9688 22.1645 65.0102 19.6619 65.7962 17.3473C66.5823 15.0328 68.0735 13.0226 70.0605 11.5989C72.0474 10.1752 74.4304 9.40967 76.8748 9.40967C79.3192 9.40967 81.7022 10.1752 83.6892 11.5989C85.6761 13.0226 87.1674 15.0328 87.9534 17.3473C88.7394 19.6619 88.7808 22.1645 88.0717 24.5037C87.3626 26.843 85.9387 28.9014 83.9998 30.39C81.9547 31.9559 79.4506 32.8045 76.8748 32.8045C74.299 32.8045 71.795 31.9559 69.7498 30.39Z"
          fill="#667085"
        />
        <path
          d="M98.83 38.7798L94.1 34.0598C93.217 33.1921 92.6038 32.0876 92.3341 30.8793C92.0644 29.6711 92.1498 28.4106 92.58 27.2498C93.9933 23.6404 94.1213 19.6545 92.9424 15.9618C91.7636 12.2691 89.3497 9.0947 86.1064 6.97182C82.8631 4.84895 78.988 3.907 75.1322 4.30422C71.2763 4.70145 67.6746 6.41365 64.9322 9.15316C62.1899 11.8927 60.4739 15.4926 60.0726 19.348C59.6714 23.2035 60.6093 27.0796 62.7288 30.3251C64.8483 33.5706 68.0202 35.9877 71.7116 37.1704C75.4031 38.3532 79.3891 38.2293 83 36.8198C84.1725 36.387 85.4452 36.3031 86.6644 36.5782C87.8835 36.8532 88.9969 37.4755 89.87 38.3698L94.61 43.1198C94.927 43.4432 95.3131 43.6906 95.7393 43.8435C96.1655 43.9964 96.6208 44.0509 97.071 44.0028C97.5213 43.9547 97.9548 43.8052 98.3391 43.5657C98.7234 43.3262 99.0485 43.0029 99.29 42.6198C99.6653 42.0241 99.8244 41.3174 99.7407 40.6183C99.6569 39.9192 99.3354 39.2701 98.83 38.7798ZM69.75 30.3898C67.8112 28.9013 66.3872 26.8428 65.6781 24.5036C64.969 22.1643 65.0104 19.6617 65.7964 17.3472C66.5825 15.0326 68.0737 13.0224 70.0607 11.5987C72.0476 10.1751 74.4306 9.4095 76.875 9.4095C79.3194 9.4095 81.7024 10.1751 83.6894 11.5987C85.6763 13.0224 87.1676 15.0326 87.9536 17.3472C88.7396 19.6617 88.781 22.1643 88.0719 24.5036C87.3628 26.8428 85.9389 28.9013 84 30.3898C81.9549 31.9557 79.4508 32.8043 76.875 32.8043C74.2992 32.8043 71.7952 31.9557 69.75 30.3898Z"
          fill="#CCD8E5"
        />
        <path
          d="M96 36L91.73 40.27L94.6 43.15C95.1675 43.7176 95.9373 44.0364 96.74 44.0364C97.5426 44.0364 98.3124 43.7176 98.88 43.15C99.4475 42.5824 99.7664 41.8127 99.7664 41.01C99.7664 40.2073 99.4475 39.4376 98.88 38.87L96 36Z"
          fill="#1D2939"
        />
        <path
          d="M96.0068 35.9634L91.7358 40.2344L93.0298 41.5284L97.3008 37.2575L96.0068 35.9634Z"
          fill="#667085"
        />
        <path
          d="M78.0198 14.8701H75.7398C75.4968 14.8701 75.2998 15.0671 75.2998 15.3101V22.8301C75.2998 23.0731 75.4968 23.2701 75.7398 23.2701H78.0198C78.2628 23.2701 78.4598 23.0731 78.4598 22.8301V15.3101C78.4598 15.0671 78.2628 14.8701 78.0198 14.8701Z"
          fill="white"
        />
        <path
          d="M78.0198 25.1201H75.7398C75.4968 25.1201 75.2998 25.3171 75.2998 25.5601V27.8401C75.2998 28.0831 75.4968 28.2801 75.7398 28.2801H78.0198C78.2628 28.2801 78.4598 28.0831 78.4598 27.8401V25.5601C78.4598 25.3171 78.2628 25.1201 78.0198 25.1201Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1167_46207">
          <rect
            width="41"
            height="41"
            fill="white"
            transform="translate(59 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { EmptyPageIllustration };
