import { FormattedMessage } from "react-intl";
import { Section } from "../../../shared/layout";
import type { ContactPageProps } from "../../types";
import { StoreTemplate } from "../../TemplateLoader";

const ContactPage: React.FC<ContactPageProps> = () => {
  const Template = StoreTemplate.get();

  return (
    <>
      <Template.elements.PageHeader
        pageTitle={<FormattedMessage defaultMessage="Contact Us" />}
      />
      <Section background="backgroundReverse">
        <Template.elements.ContactElement />
      </Section>
    </>
  );
};

export default ContactPage;
