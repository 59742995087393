import { FormattedMessage } from "react-intl";
import {
  BorderBottom,
  CartHolder,
  CartPanel,
  FullWidth,
  LightH4,
  LightH4ForDiscount,
  StyledH2,
  StyledH3WithMargin,
  StyledH4,
  StyledH5,
} from "./styled";
import { ShoppingBagOutlined } from "../../../../assets/Icons";
import { FlexCol, FlexRow } from "../../../../shared/globals";
import { Link } from "../../../../lib/i18n";
import { StoreTemplate } from "../../../TemplateLoader";
import Flex from "../../../../shared/globals/UiElements/Flex";
import useBreakPoints from "../../../../shared/utils/useBreakPoints";
import styled from "styled-components";
import SummaryCard from "../SummaryCard/SummaryCard";
import { useCartData } from "../../../../lib/cartData/useCartData";
import Button from "../Button/Button";
import { Price } from "../../../../components/Price";
import { isCartDirty } from "../../../../shared/utils/error-utils";

const CartCard = () => {
  const Template = StoreTemplate.get();
  const { isMobile } = useBreakPoints();

  const {
    cart: { receipt, shippingRate, isLoading, cartErrors, id },
  } = useCartData();

  const canNotProceedToCheckout = isCartDirty(cartErrors);

  const isFreeShippingApplied = receipt?.shipping?.amount === 0;

  return (
    <CartHolder>
      <CartPanel>
        <BorderBottom className="!mb-0">
          <FlexRow fullWidth justifyContent="center">
            <ShoppingBagOutlined />
            <StyledH3WithMargin className="!text-center ">
              <FormattedMessage defaultMessage="Cart Totals" />
            </StyledH3WithMargin>
          </FlexRow>
        </BorderBottom>
        <BorderBottom className="!p-5  !mb-0 !w-full">
          <Template.elements.Coupon />
        </BorderBottom>
        <SummaryCard />
        <BorderBottom className="!px-5">
          <FullWidth style={{ alignItems: "flex-start" }}>
            <StyledH5 className="!font-bold !text-base">
              <FormattedMessage defaultMessage="Total" />
            </StyledH5>
            <FlexCol alignItems="flex-end">
              <StyledH2 className="!text-xl">
                {isLoading ? (
                  <FormattedMessage defaultMessage="Calculating" />
                ) : (
                  <Price money={receipt?.total!} />
                )}
              </StyledH2>
              {shippingRate ? (
                shippingRate?.cost?.amount && !isFreeShippingApplied ? (
                  <LightH4>
                    <FormattedMessage defaultMessage="+ Shipping" />
                  </LightH4>
                ) : (
                  <LightH4ForDiscount>
                    <FormattedMessage defaultMessage="Free Shipping" />
                  </LightH4ForDiscount>
                )
              ) : (
                <LightH4>
                  <FormattedMessage defaultMessage="To be calculated" />
                </LightH4>
              )}
            </FlexCol>
          </FullWidth>
        </BorderBottom>
        {!isMobile && (
          <Flex className="!px-5" fullWidth>
            <Button
              to={`/checkout/${id}`}
              fullWidth
              data-test="button-checkout"
              isDisabled={isLoading || canNotProceedToCheckout}
            >
              <FormattedMessage defaultMessage="Checkout" />
            </Button>
          </Flex>
        )}
      </CartPanel>
      <Link href="/shop">
        <StyledH4 style={{ cursor: "pointer" }}>
          <FormattedMessage defaultMessage="Continue Shopping" />
        </StyledH4>
      </Link>
      {isMobile && (
        <MobileFixedCheckout>
          <Button
            to={`/checkout/${id}`}
            fullWidth
            data-test="button-checkout"
            isDisabled={isLoading || canNotProceedToCheckout}
            className="!rounded-none !h-full !text-[18px]"
            borderRadius={0}
          >
            <FormattedMessage defaultMessage="Checkout" />
          </Button>
        </MobileFixedCheckout>
      )}
    </CartHolder>
  );
};

export default CartCard;

const MobileFixedCheckout = styled(Flex)`
  position: fixed;
  bottom: 60px;
  width: 100%;
  height: 60px;
  box-shadow: 0 -1px 5px 0 rgba(190, 190, 190, 0.3);
`;
