import React from "react";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { EmptyState } from "../../../../components/UtilityComponents/EmptyState";
import { H2, P, Spinner } from "../../../../shared/globals";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import { EmptySearchResults } from "../../../../assets/Icons";
import styled from "styled-components";
import { StoreTemplate } from "../../../TemplateLoader";
import { useStoreProductsQuery } from "../../../../generated/graphql";
import { useStore } from "../../../../lib/storeData";
import { Section } from "../../../../shared/layout";

const SearchEmptyState = () => {
  const router = useRouter();
  const Template = StoreTemplate.get();
  const { storeId } = useStore();
  const { data, loading } = useStoreProductsQuery({
    variables: {
      storeId,
      first: 4,
    },
  });

  return (
    <StyledSection>
      <Flex column justifyContent="center" alignItems="center" spacing="xxxxl">
        <Flex alignItems="center" justifyContent="center" spacing="s">
          <H2>
            <FormattedMessage
              defaultMessage={`Search Results for "{SearchValue}"`}
              values={{ SearchValue: router.query.query }}
            />
          </H2>
          <P
            fontSize="18px"
            style={{
              opacity: "0.5",
            }}
          >
            <FormattedMessage defaultMessage="[0 Results]" />
          </P>
        </Flex>
        <EmptyState
          title={
            <Flex spacing="none">
              <P fontSize="18px" fontWeight="400">
                <FormattedMessage
                  defaultMessage="Sorry we Couldn’t find any matches for <b>{SearchValue}!</b>"
                  values={{
                    SearchValue: router.query.query,
                    b: (msg) => <b>{msg}</b>,
                  }}
                />
              </P>
            </Flex>
          }
          padding="0px"
          image={
            <SVGContainer>
              <EmptySearchResults />
            </SVGContainer>
          }
          description={
            <P
              style={{
                whiteSpace: "nowrap",
                opacity: "0.5",
              }}
            >
              <FormattedMessage defaultMessage="Please try searching with another term." />
            </P>
          }
        />
        {loading ? (
          <Spinner inline size={40} />
        ) : (
          <Flex column>
            <P fontSize="20px" fontWeight="600">
              <FormattedMessage defaultMessage="We Recommended" />
            </P>
            <Template.elements.ProductsList
              products={data?.products?.nodes}
              showTwoProductsPerRowOn="TABLET"
            />
          </Flex>
        )}
      </Flex>
    </StyledSection>
  );
};

const SVGContainer = styled.div`
  svg {
    width: 160px;
    height: auto;
  }
`;

const StyledSection = styled(Section)`
  && {
    padding-top: 100px;
  }
`;

export default SearchEmptyState;
