import * as React from "react";
import styled, { css } from "styled-components";
import { MinusIconOutline, PlusIconOutline } from "../../../../assets/Icons";
import { FlexRow } from "../../../../shared/globals";
import { themeColor } from "../../../../shared/styles-utils";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { useCartData } from "../../../../lib/cartData/useCartData";

interface QuantityInputProps {
  value: number;
  className?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  max?: number;
  disabled?: boolean;
  onChange: (value: number) => void;
  size?: "small" | "large";
  min?: number;
}

const QuantityInput = ({
  value,
  className,
  style,
  fullWidth,
  max = Number.POSITIVE_INFINITY,
  disabled,
  onChange,
  size = "small",
  min = 0,
}: QuantityInputProps) => {
  const {
    cart: { isLoading },
  } = useCartData();
  return (
    <Container
      style={style}
      fullWidth={fullWidth}
      className={className}
      disabled={disabled}
      onClick={(e) => e.stopPropagation()}
      loading={isLoading}
    >
      <Flex spacing="none" fullHeight>
        <PrefixSuffix
          data-test="button-decrease-quantity"
          type="button"
          onClick={() => {
            if (onChange) {
              onChange(Number(value - 1));
            }
          }}
          className={value <= 1 && max !== 0 ? "dimmed" : ""}
          size={size}
        >
          <MinusIconOutline />
        </PrefixSuffix>
        <StyledInput
          type="number"
          fullWidth={fullWidth}
          min={min}
          max={max}
          value={max === 0 ? 0 : value}
          onChange={({ target: { value: targetValue } }) => {
            if (Number(targetValue) > max) return onChange(max);
            if (onChange && +targetValue > 0) onChange(Number(targetValue));
          }}
          width={size}
        />
        <PrefixSuffix
          data-test="button-increase-quantity"
          type="button"
          onClick={() => {
            if (value >= max) return;
            if (onChange) onChange(Number(value + 1));
          }}
          className={value >= max ? "dimmed" : ""}
          size={size}
        >
          <PlusIconOutline />
        </PrefixSuffix>
      </Flex>
    </Container>
  );
};

export { QuantityInput };

const Container = styled(FlexRow)<{
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
}>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "fit-content")};
  border-radius: 999px;
  border: solid 1px ${({ theme }) => theme.bg.inactive};
  background-color: ${themeColor("white")};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${({ loading }) =>
    loading &&
    css`
      opacity: 0.25;
      pointer-events: none;
    `}
`;

const PrefixSuffix = styled.button<{
  size: "small" | "large";
}>`
  padding: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  width: ${({ size }) => (size === "large" ? "40px" : "auto")};
  &.dimmed {
    opacity: 0.5;
    pointer-events: none;
  }
  svg {
    margin: auto;
  }
`;

const StyledInput = styled.input<{
  fullWidth?: boolean;
  width: "small" | "large";
}>`
  background-color: transparent;
  color: ${({ theme }) => theme.text.default};
  width: ${({ fullWidth, width }) =>
    fullWidth ? "100%" : width === "large" ? "40px" : "35px"};
  font-weight: 600;
  text-align: center;
  border: 1px solid var(--Gray-300, #d0d5dd);
  border-top: 0;
  border-bottom: 0;
  outline: none;
  appearance: textfield;
  font-size: 16px;
  /* Chrome, Safari, Edge, Opera, Firefox */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -moz-appearance: textfield;
    -webkit-appearance: none;
  }
  @media (min-width: 768px) {
    font-size: 14px;
  }
`;
