interface Props {
  className?: string;
}

const ExplanationIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#000000"
      className={className}
    >
      <path
        d="M12,2C6.477,2,2,6.477,2,12c0,5.523,4.477,10,10,10s10-4.477,10-10C22,6.477,17.523,2,12,2z M12.5,17h-1 c-0.276,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5v1C13,16.776,12.776,17,12.5,17z M12,13 L12,13c-0.552,0-1-0.448-1-1V8c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v4C13,12.552,12.552,13,12,13z"
        fill="#000000"
      />
    </svg>
  );
};

export { ExplanationIcon };
