import React from "react";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { FormattedMessage, useIntl } from "react-intl";
import { BorderBottom, FullWidth, HeavyH4, LightH4 } from "../Cart/styled";
import { DefaultTextPrice } from "../../../../components/Price";
import Tooltip from "../../../../shared/globals/UiElements/Tooltip";
import { FlexCol, FlexRow } from "../../../../shared/globals";
import { HelpIcon, ShoppingCartPromotionIcon } from "../../../../assets/Icons";
import { useCartData } from "../../../../lib/cartData/useCartData";
import { StoreTemplate } from "../../../TemplateLoader";
import {
  LightH4ForDiscount,
  StyledShippingDiscountBadge,
} from "../../../../components/Checkout/CheckoutCart/styled";

export const CartCardSummary = () => {
  const intl = useIntl();
  const Template = StoreTemplate.get();
  const { cart } = useCartData();
  const areDiscountsApplied = !!cart.receipt?.automaticDiscount?.amount;

  const isCouponDiscountApplied =
    !!cart?.promoCode?.percentageOff || !!cart?.promoCode?.fixedAmount;

  const isShippingDiscountApplies = cart?.shippingDiscount
    ? cart?.receipt?.subtotal?.amount >=
      cart?.shippingDiscount?.customerBuys?.value?.amount!
    : false;

  return (
    <BorderBottom>
      <Flex column>
        <FullWidth>
          <HeavyH4>
            <FormattedMessage defaultMessage="Subtotal" />
          </HeavyH4>
          <LightH4>
            {cart.isLoading ? (
              <FormattedMessage defaultMessage="Calculating..." />
            ) : (
              <DefaultTextPrice money={cart?.receipt?.subtotal} />
            )}
          </LightH4>
        </FullWidth>
        {areDiscountsApplied && <Template.elements.DiscountsBreakdown />}
        {isCouponDiscountApplied && (
          <FullWidth>
            <HeavyH4>
              <FormattedMessage defaultMessage="Coupon" />
            </HeavyH4>
            {cart?.promoCode?.percentageOff ? (
              <LightH4ForDiscount>
                -<DefaultTextPrice money={cart?.receipt?.discount} /> (-
                {cart?.promoCode?.percentageOff}%)
              </LightH4ForDiscount>
            ) : (
              <LightH4ForDiscount>
                -<DefaultTextPrice money={cart?.receipt?.discount} />
              </LightH4ForDiscount>
            )}
          </FullWidth>
        )}
        {!!cart?.receipt?.tax?.amount && (
          <FullWidth>
            <FlexRow spacing="xs">
              <HeavyH4>
                <FormattedMessage defaultMessage="Estimated Taxes" />
              </HeavyH4>

              <Tooltip
                icon={<HelpIcon />}
                tooltipMessage={intl.formatMessage({
                  defaultMessage:
                    "The final tax and total will be confirmed by email or text after you place your order.",
                })}
              />
            </FlexRow>
            <LightH4>
              + <DefaultTextPrice money={cart?.receipt?.tax} />
            </LightH4>
          </FullWidth>
        )}
        <FullWidth alignItems="start">
          <HeavyH4>
            <FormattedMessage defaultMessage="Shipping" />
          </HeavyH4>
          {cart?.shippingRate?.cost ? (
            isShippingDiscountApplies ? (
              <FlexCol spacing="m" alignItems="end">
                <LightH4>
                  <DefaultTextPrice money={cart?.shippingRate?.cost} />
                </LightH4>
                <StyledShippingDiscountBadge>
                  <ShoppingCartPromotionIcon />
                  {cart?.shippingDiscount?.title}
                </StyledShippingDiscountBadge>
              </FlexCol>
            ) : (
              <LightH4>
                <DefaultTextPrice money={cart?.shippingRate?.cost} />
              </LightH4>
            )
          ) : (
            <LightH4>
              <FormattedMessage defaultMessage="To be calculated" />
            </LightH4>
          )}
        </FullWidth>
      </Flex>
    </BorderBottom>
  );
};
