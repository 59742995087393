import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { SalePriceTagIcon } from "../../assets/Icons";
import {
  CustomItemDiscountDetails,
  CustomProductDiscount,
} from "../../generated/graphql";
import { rtl, themeColor } from "../../shared/styles-utils";
import { DefaultTextPrice } from "../Price/Price";
import { Breakpoints, ZIndex } from "../../shared/globals/types";

interface BadgeProps {
  className?: string;
  style?: React.CSSProperties;
  outOfStock?: boolean;
  onSale?: boolean;
  simpleInStock?: boolean;
  customInStock?: boolean;
  customItemDiscount?:
    | CustomProductDiscount
    | CustomItemDiscountDetails["info"]
    | null;
  automaticDiscountTitle?: string | null;
  dotBackground?: string;
  badgeColor?: string;
  saleMessage?: ReactNode;
}

export const Badge = ({
  className,
  style,
  outOfStock,
  onSale,
  simpleInStock,
  customInStock,
  customItemDiscount,
  automaticDiscountTitle,
  dotBackground,
  badgeColor,
  saleMessage,
}: BadgeProps) => {
  const dataTest = outOfStock
    ? "badge-out-of-stock"
    : onSale
    ? "badge-on-sale"
    : customItemDiscount
    ? "badge-discount"
    : automaticDiscountTitle
    ? "buy-x-get-y-discount"
    : "badge-in-stock";

  if (
    !outOfStock &&
    !onSale &&
    !simpleInStock &&
    !customInStock &&
    !customItemDiscount &&
    !automaticDiscountTitle
  ) {
    return null;
  }
  const typedCustomItemDiscountDetails =
    customItemDiscount as CustomItemDiscountDetails["info"];
  const typedCustomProductDiscountDetails =
    customItemDiscount as CustomProductDiscount;

  return (
    <StyledBadge
      data-test={dataTest}
      style={style}
      className={className}
      isSale={!!onSale}
      outOfStock={!!outOfStock}
      simpleInStock={!!simpleInStock}
      customInStock={!!customInStock}
      customItemDiscount={!!customItemDiscount}
      automaticDiscountTitle={!!automaticDiscountTitle}
      title={automaticDiscountTitle || undefined}
    >
      {outOfStock ? (
        <>
          <Dot dotBackgroundColor={dotBackground} outOfStock />
          <FormattedMessage defaultMessage="Out of stock" />
        </>
      ) : onSale ? (
        saleMessage || <FormattedMessage defaultMessage="SALE" />
      ) : simpleInStock || customInStock ? (
        <>
          <Dot dotBackgroundColor={dotBackground} />
          <FormattedMessage defaultMessage="IN STOCK" />
        </>
      ) : typedCustomItemDiscountDetails?.percentage ||
        typedCustomProductDiscountDetails?.percent ? (
        saleMessage || (
          <FormattedMessage
            defaultMessage="SAVE {percent}%"
            values={{
              percent:
                typedCustomItemDiscountDetails?.percentage ||
                typedCustomProductDiscountDetails?.percent,
            }}
          />
        )
      ) : customItemDiscount?.fixed ? (
        <FormattedMessage
          defaultMessage="SAVE {fixed}"
          values={{
            fixed: <DefaultTextPrice money={customItemDiscount.fixed} />,
          }}
        />
      ) : automaticDiscountTitle ? (
        <>
          <SalePriceTagIcon fill={badgeColor} />
          <p>{automaticDiscountTitle || ""}</p>
        </>
      ) : null}
    </StyledBadge>
  );
};

/**
 * Styles
 */

const StyledBadge = styled.div<{
  outOfStock: boolean;
  isSale: boolean;
  simpleInStock: boolean;
  customInStock: boolean;
  customItemDiscount: boolean;
  automaticDiscountTitle: boolean;
}>`
  width: fit-content;
  font-weight: 700;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  max-width: -webkit-fill-available;
  gap: 2px;
  p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    flex-shrink: 0;
  }

  @media (max-width: ${Breakpoints["TABLET"]}px) {
    font-size: 10px;
  }

  ${rtl("margin-left", "margin-right")}: 6px;

  ${({ customItemDiscount }) =>
    customItemDiscount &&
    css`
      background-color: #bbf7d0;
      color: #166534;
      > span {
        color: #166534;
      }
    `};

  ${({ outOfStock }) =>
    outOfStock &&
    css`
      background-color: #fef2f2;
      color: #991b1b;
    `};

  ${({ isSale, outOfStock }) =>
    isSale &&
    !outOfStock &&
    css`
      background-color: ${themeColor("primary")};
      color: white;
    `};

  ${({ simpleInStock, customInStock, outOfStock, isSale }) =>
    (simpleInStock || customInStock) &&
    !outOfStock &&
    !isSale &&
    css`
      background-color: #f0fdf4;
      color: #166534;
    `};

  ${({ automaticDiscountTitle }) =>
    automaticDiscountTitle &&
    css`
      background-color: #f0fdf4;
      color: #166534;
    `};
`;

export const BadgeContainer = styled.div`
  position: absolute;
  max-width: 100%;
  z-index: ${ZIndex["DROPDOWN"]};
  top: 12px;
  left: ${({ theme }) => (theme.isRTL ? "" : "12px")};
  right: ${({ theme }) => (theme.isRTL ? "12px" : "")};
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Dot = styled.div<{ outOfStock?: boolean; dotBackgroundColor?: string }>`
  width: 7px;
  height: 7px;
  background: ${({ dotBackgroundColor, outOfStock }) =>
    dotBackgroundColor
      ? dotBackgroundColor
      : outOfStock
      ? "#991b1b"
      : "#166534"};
  border: 1.5px solid
    ${({ dotBackgroundColor, outOfStock }) =>
      dotBackgroundColor
        ? dotBackgroundColor
        : outOfStock
        ? "#f87171"
        : "#166534"};
  border-radius: 30px;
  ${rtl("margin-left", "margin-right")}: 4px;
`;
