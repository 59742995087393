import { useEffect } from "react";
import useBreakPoints from "../shared/utils/useBreakPoints";
import { Breakpoints, ZIndex } from "../shared/globals/types";

const useWidgetsStyles = () => {
  const { isTablet } = useBreakPoints();

  useEffect(() => {
    const startTime = Date.now();

    let GBWidget = document.querySelector("[id^=gb-widget]") as HTMLDivElement;
    let ContactWidget = document.getElementById("contact") as HTMLDivElement;
    let HubspotWidget = document.getElementById(
      "hubspot-messages-iframe-container"
    ) as HTMLDivElement;
    let GTMChatWidget = document.getElementsByClassName(
      "widget-visible"
    )[0] as HTMLDivElement;
    let trengoWidget = document.getElementsByClassName(
      "TrengoWidgetLauncher__iframe"
    )[0] as HTMLDivElement;

    // set interval for 10 seconds to check if widgets are loaded because they take some time to load depending on the internet speed
    const interval = setInterval(function () {
      GBWidget = document.querySelector("[id^=gb-widget]") as HTMLDivElement;
      ContactWidget = document.getElementById("contact") as HTMLDivElement;
      HubspotWidget = document.getElementById(
        "hubspot-messages-iframe-container"
      ) as HTMLDivElement;
      GTMChatWidget = document.getElementsByClassName(
        "widget-visible"
      )[0] as HTMLDivElement;
      trengoWidget = document.getElementsByClassName(
        "TrengoWidgetLauncher__iframe"
      )[0] as HTMLDivElement;

      // if the interval is not cleared after 60 seconds, clear it
      if (Date.now() - startTime >= 60000) clearInterval(interval);

      if (window.innerWidth < Breakpoints["SMALLSCREEN"]) {
        if (GBWidget) {
          GBWidget.style.bottom = "100px";
          GBWidget.style.zIndex = `${ZIndex["DROPDOWN"]}`;
        }
        if (ContactWidget) {
          ContactWidget.style.bottom = "110px";
          ContactWidget.style.zIndex = `${ZIndex["DROPDOWN"]}`;
        }
        if (HubspotWidget) {
          HubspotWidget.style.setProperty("bottom", "100px", "important");
          HubspotWidget.style.setProperty(
            "z-index",
            ZIndex["DROPDOWN"].toString(),
            "important"
          );
        }
        if (GTMChatWidget) {
          (
            GTMChatWidget.querySelectorAll(
              "iframe"
            ) as NodeListOf<HTMLIFrameElement>
          ).forEach(function (iframe) {
            iframe.style.setProperty("bottom", "94px", "important");
            iframe.style.setProperty(
              "z-index",
              ZIndex["DROPDOWN"].toString(),
              "important"
            );
          });
        }
        if (trengoWidget) {
          trengoWidget.style.bottom = "110px";
          trengoWidget.style.zIndex = `${ZIndex["DROPDOWN"]}`;
        }
      } else {
        if (GBWidget) {
          GBWidget.style.bottom = "14px";
          GBWidget.style.zIndex = `${ZIndex["DROPDOWN"]}`;
        }
        if (ContactWidget) {
          ContactWidget.style.bottom = "20px";
          ContactWidget.style.zIndex = `${ZIndex["DROPDOWN"]}`;
        }
        if (HubspotWidget) {
          HubspotWidget.style.setProperty("bottom", "14px", "important");
          HubspotWidget.style.setProperty(
            "z-index",
            ZIndex["DROPDOWN"].toString(),
            "important"
          );
        }
        if (GTMChatWidget) {
          (
            GTMChatWidget.querySelectorAll(
              "iframe"
            ) as NodeListOf<HTMLIFrameElement>
          ).forEach(function (iframe) {
            iframe.style.setProperty("bottom", "0px", "important");
            iframe.style.setProperty(
              "z-index",
              ZIndex["DROPDOWN"].toString(),
              "important"
            );
          });
        }
        if (trengoWidget) {
          trengoWidget.style.bottom = "20px";
          trengoWidget.style.zIndex = `${ZIndex["DROPDOWN"]}`;
        }
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [isTablet]);
};

export default useWidgetsStyles;
