import React from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-focus";
import Modal from "../../../../../shared/globals/UiElements/Modal";
import { H2, P, Label, Input } from "../../../../../shared/globals";
import {
  ButtonsContainer,
  SaveButton,
  RequiredSpan,
  CancelButton,
} from "./styled";
import type { FormValues } from "./types";
import { Delete } from "../../../../../assets/Icons";

const focusOnError = createDecorator();

interface EditPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
  error?: React.ReactNode;
  children?: React.ReactNode;
}

const EditPasswordModal: React.FC<EditPasswordModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  error,
}) => {
  return (
    <Modal
      show={isOpen}
      onClose={onClose}
      modalWidth="small"
      closeIcon={<Delete small />}
    >
      <Modal.Body padding="32px">
        <Form
          onSubmit={onSubmit}
          validate={validate}
          decorators={[focusOnError]}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <H2 margin="0px 0px 10px 0px">
                <FormattedMessage defaultMessage="Change your password" />
              </H2>
              <P color="secondary">
                <FormattedMessage defaultMessage="Use the form below to change the password for your account." />
              </P>

              {/* The input is only used for accessibility and password managers */}
              <input autoComplete="username" style={{ display: "none" }} />

              <Label>
                <FormattedMessage defaultMessage="Current Password" />
              </Label>
              <Field name="currentPassword" type="password">
                {({ input, meta: { error, touched } }) => (
                  <>
                    <Input
                      {...input}
                      autoComplete="current-password"
                      className={error && touched ? "invalid" : ""}
                      data-test="type-name"
                      placeholder="Enter your current password"
                    />
                    {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                  </>
                )}
              </Field>

              <Label>
                <FormattedMessage defaultMessage="New password" />
              </Label>
              <Field name="password" type="password">
                {({ input, meta: { error, touched } }) => (
                  <>
                    <Input
                      {...input}
                      autoComplete="new-password"
                      className={error && touched ? "invalid" : ""}
                      data-test="type-name"
                      placeholder="Enter your new password"
                    />
                    {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                  </>
                )}
              </Field>

              <Label>
                <FormattedMessage defaultMessage="Re-enter new password" />
              </Label>
              <Field name="confirmedPassword" type="password">
                {({ input, meta: { error, touched } }) => (
                  <>
                    <Input
                      {...input}
                      autoComplete="new-password"
                      className={error && touched ? "invalid" : ""}
                      data-test="type-name"
                      placeholder="Re-enter your new password"
                    />
                    {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                  </>
                )}
              </Field>
              {error && <RequiredSpan>{error}</RequiredSpan>}
              <ButtonsContainer>
                <CancelButton type="button" onClick={onClose}>
                  <FormattedMessage defaultMessage="Cancel" />
                </CancelButton>
                <SaveButton
                  data-test="button-save-changes"
                  type="submit"
                  isLoading={submitting}
                >
                  <FormattedMessage defaultMessage="Save Changes" />
                </SaveButton>
              </ButtonsContainer>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EditPasswordModal;

const validate = (values: FormValues) => {
  const errors: Partial<{ [k in keyof FormValues]: React.ReactElement }> = {};
  if (!values?.currentPassword) {
    errors.currentPassword = (
      <FormattedMessage defaultMessage="Enter your current password" />
    );
  }
  if (!values?.password) {
    errors.password = (
      <FormattedMessage defaultMessage="Enter your new password" />
    );
  }
  if (values?.password === values?.currentPassword) {
    errors.password = (
      <FormattedMessage defaultMessage="The new password must be different from the current one" />
    );
  }
  if (values?.password !== values?.confirmedPassword) {
    errors.confirmedPassword = (
      <FormattedMessage defaultMessage="These passwords not matched" />
    );
  }
  return errors;
};
