import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { TrashIcon } from "../../../../../assets/Icons";
import { useAuth } from "../../../../../lib/Authentication";
import {
  ListCustomerAddressesDocument,
  useDeleteCustomerAddressMutation,
} from "../../../../../generated/graphql";
import { ModalConfirm } from "../../../../../shared/globals/UiElements/ModalConfirm";
import { useStore } from "../../../../../lib/storeData";

interface DeleteAddressProps {
  addressId: string;
  children?: React.ReactNode;
}

const DeleteAddress: React.FC<DeleteAddressProps> = ({ addressId }) => {
  const { storeId } = useStore();
  const { user } = useAuth();
  const [deleteCustomerAddress, { loading }] = useDeleteCustomerAddressMutation(
    {
      refetchQueries: [
        {
          query: ListCustomerAddressesDocument,
          variables: { customerId: user?.id, storeId },
        },
      ],
    }
  );

  const onDelete = async (userId: string, addressId: string) => {
    await deleteCustomerAddress({
      variables: { customerId: userId, addressId: addressId, storeId },
    });
  };
  return (
    <ModalConfirm
      modalBody={
        <FormattedMessage defaultMessage="Are you sure you want to delete this address" />
      }
      modalHeader={<FormattedMessage defaultMessage="Delete Address" />}
      loading={loading}
      onConfirm={() => onDelete(user?.id!, addressId)}
    >
      <DeleteButton data-test="button-remove-address">
        <TrashIcon />
      </DeleteButton>
    </ModalConfirm>
  );
};

const DeleteButton = styled.button`
  background-color: #cc09050d;
  border-radius: 4px;
  padding: 0.5rem;
  border: none;
  cursor: pointer;
`;
export default DeleteAddress;
