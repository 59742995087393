import { NextRouter } from "next/router";
import { StoreMenuItemFragment } from "../../generated/graphql";

type itemType = StoreMenuItemFragment & { children?: StoreMenuItemFragment[] };

export const isCurrentRoute = (router: NextRouter, item: itemType): boolean => {
  const { pathname, query } = router;
  return (
    (pathname === "/contact-us" && item.link?.staticPageType === "Contact") ||
    (pathname === "/" && item.link?.staticPageType === "Home") ||
    (pathname === "/shop" && item.link?.staticPageType === "Shop") ||
    (query.handle &&
      item?.link?.resource?.__typename !== "StoreStaticPage" &&
      query.handle === item?.link?.resource?.handle) ||
    (query.collection &&
      item?.link?.resource?.__typename !== "StoreStaticPage" &&
      query.collection === item?.link?.resource?.handle) ||
    false
  );
};
