import {
  ApolloClient,
  ApolloQueryResult,
  NormalizedCacheObject,
} from "@apollo/client";
import {
  StoreBasicInfoByDomainDocument,
  StoreBasicInfoByDomainQuery,
  StoreBasicInfoByDomainQueryVariables,
  StoreBasicInfoByHandleDocument,
  StoreBasicInfoByHandleQuery,
  StoreBasicInfoByHandleQueryVariables,
} from "../../generated/graphql";
import { LanguageType, supportedLanguages } from "../../lib/i18n/locales-data";
import { isSupported } from "../../lib/i18n/language-detect";
import { checkSubscriptionActive } from "./check-subscription-active";
import { getCookie } from "./cookie";
import { IncomingMessage, ServerResponse } from "http";
import accepts from "accepts";
import NodeCache from "node-cache";

const BASE_WUILT_STORE_DOMAIN = "wuiltstore.com";

export async function checkUrlAndRedirect({
  hostname,
  pathname = "/",
  apolloClient,
  request,
  res,
  cache,
}: {
  hostname: string;
  pathname?: string;
  apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
  request?: IncomingMessage;
  res?: ServerResponse<IncomingMessage>;
  cache: NodeCache;
}) {
  if (!hostname) {
    throw new Error("getStoreData: url is required in server");
  }
  if (!apolloClient) {
    throw new Error("getStoreData: apolloClient is required");
  }
  const isSubDomain = hostname.indexOf(BASE_WUILT_STORE_DOMAIN) !== -1;

  let query: ApolloQueryResult<StoreBasicInfoByDomainQuery>;

  let cachedStoreBasicData: StoreBasicInfoByDomainQuery | undefined = cache.get(
    `${hostname}/basicInfo`
  );

  if (!cachedStoreBasicData) {
    if (isSubDomain) {
      const handle = hostname.split(".")[0];
      query = await apolloClient?.query<
        StoreBasicInfoByHandleQuery,
        StoreBasicInfoByHandleQueryVariables
      >({
        query: StoreBasicInfoByHandleDocument,
        errorPolicy: "ignore",
        variables: { storeHandle: handle },
      });
    } else {
      query = await apolloClient?.query<
        StoreBasicInfoByDomainQuery,
        StoreBasicInfoByDomainQueryVariables
      >({
        query: StoreBasicInfoByDomainDocument,
        errorPolicy: "ignore",
        variables: { storeDomain: hostname },
      });
    }
    const { data, error } = query;
    if (error) {
      throw error;
    }
    cachedStoreBasicData = data;
    cache.set(`${hostname}/basicInfo`, data);
  }

  if (!cachedStoreBasicData.store) {
    const error: any = new Error("Store not found from store basic info");
    error.code = 9004;
    throw error;
  }
  if (
    !checkSubscriptionActive(cachedStoreBasicData.store.subscription) &&
    cachedStoreBasicData.store.isExpired
  ) {
    const error: any = new Error("Store is expired");
    error.code = 9009;
    throw error;
  }
  // swap the comments here to reach the local host
  const mainHostName =
    cachedStoreBasicData?.store?.domain?.domainName ?? hostname;
  // const mainHostName = hostname;
  const protocol = request?.headers?.["x-forwarded-proto"] || "http";
  const oldMainUrl = `${protocol}://${hostname}${pathname}`;
  let mainUrl = `${protocol}://${mainHostName}${pathname}`;
  const isBrowserLanguageSupported =
    cachedStoreBasicData?.store?.supportedLocales?.includes(
      accepts(request!).language(supportedLanguages) as LanguageType
    );

  // use the language from the cookie if it exists, otherwise use the browser language if it is supported from the store supported languages, otherwise use the default locale
  const defaultLocale =
    (getCookie("lang", request) as LanguageType) ||
    (isBrowserLanguageSupported
      ? (accepts(request!).language(supportedLanguages) as LanguageType)
      : cachedStoreBasicData?.store?.defaultLocale);
  const languageFromPathname = pathname
    ?.split?.("/")?.[1]
    .split("?")?.[0] as LanguageType;
  const isPathnameHasValidLanguage = isSupported(
    languageFromPathname,
    supportedLanguages
  );

  if (pathname === "/" || !isPathnameHasValidLanguage) {
    mainUrl = `${protocol}://${mainHostName}/${defaultLocale}${pathname}`;
  } else if (isPathnameHasValidLanguage) {
    const isLanguageSupported =
      cachedStoreBasicData?.store?.supportedLocales.includes(
        languageFromPathname
      );
    if (!isLanguageSupported) {
      mainUrl = mainUrl.replace(
        `/${languageFromPathname}`,
        `/${defaultLocale}`
      );
    }
  }

  if (oldMainUrl !== mainUrl) {
    res?.writeHead(301, {
      Location: mainUrl,
    });
    res?.end();
    return true;
  }
  return false;
}
