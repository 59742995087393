import { useApolloClient } from "@apollo/client";
import createDecorator from "final-form-focus";
import type {
  ResetPasswordFormProps,
  ResetPasswordFormValues,
  TemplateElement,
} from "../templates/types";
import { RendererComponent } from "./types";
import { useRouter } from "../lib/i18n";
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  ResetPasswordDocument,
  MeQuery,
  MeQueryVariables,
  MeDocument,
} from "../generated/graphql";
import { registerUser } from "../lib/Authentication";
import { useStore } from "../lib/storeData";
import { useCartData } from "../lib/cartData/useCartData";
import { parseCookies } from "nookies";

const focusOnError = createDecorator<ResetPasswordFormValues>();

interface ResetPasswordFormRendererProps
  extends RendererComponent<ResetPasswordFormProps> {}

const ResetPasswordFormRenderer: TemplateElement<
  ResetPasswordFormRendererProps
> = ({ Component, token }) => {
  const Router = useRouter();
  const { storeId } = useStore();
  const { mutate, query } = useApolloClient();
  const {
    cart,
    updateContactInfo: { updateContactInfo },
  } = useCartData();
  const { sessionId } = parseCookies();

  const handleSubmitForm = async (values: ResetPasswordFormValues) => {
    try {
      const { data: resetData } = await mutate<
        ResetPasswordMutation,
        ResetPasswordMutationVariables
      >({
        mutation: ResetPasswordDocument,
        variables: {
          storeId,
          token,
          newPassword: values.newPassword,
        },
      });

      const loginToken = resetData?.resetCustomerPassword;
      const { data: meData } = await query<MeQuery, MeQueryVariables>({
        query: MeDocument,
        context: { headers: { authorization: `Bearer ${loginToken}` } },
      });

      registerUser("login", meData?.me!, loginToken!);
      if (
        (meData?.me?.phone !== cart?.contactInfo?.phone ||
          meData?.me?.email !== cart?.contactInfo?.email ||
          meData?.me?.name !== cart?.contactInfo?.name) &&
        sessionId
      )
        await updateContactInfo({
          info: {
            name: meData?.me?.name!,
            email: meData?.me?.email,
            phone: meData?.me?.phone,
          },
        });

      Router.replace("/");
    } catch (error) {}
  };

  return <Component onSubmit={handleSubmitForm} focusOnError={focusOnError} />;
};

export default ResetPasswordFormRenderer;
