import React, { Fragment } from "react";
import ReactPlayer from "react-player";
import { SwiperSlide } from "swiper/react";
import { SectionTypeEnum } from "../../../../generated/graphql";
import { FormattedDataType } from "../../../types";
import { StoreTemplate } from "../../../TemplateLoader";

const SectionGenerator = (
  section: Partial<FormattedDataType>,
  isSliderSection?: boolean
) => {
  const Template = StoreTemplate.get();

  if (section.type === SectionTypeEnum.Video) {
    const renderCard = (link: FormattedDataType["links"][0]) => (
      <Template.elements.SectionCardWrapper
        isSlider={isSliderSection}
        data-test="link-card"
        background={section?.background}
      >
        <ReactPlayer url={link} width="100%" height="350px" />
      </Template.elements.SectionCardWrapper>
    );

    return section?.links?.map((link, index) =>
      isSliderSection ? (
        <SwiperSlide
          key={index}
          style={{
            height: "auto",
          }}
        >
          {renderCard(link)}
        </SwiperSlide>
      ) : (
        <Fragment key={index}>{renderCard(link)}</Fragment>
      )
    );
  }

  if (section.type === SectionTypeEnum.Banner) {
    const renderCard = (banner: FormattedDataType["banners"][0]) => (
      <Template.elements.SectionCardWrapper
        isSlider={isSliderSection}
        data-test="banner-card"
        background={section?.background}
      >
        <Template.elements.BannerCard banner={banner} />
      </Template.elements.SectionCardWrapper>
    );

    return section?.banners?.map((banner) =>
      isSliderSection ? (
        <SwiperSlide
          key={banner?.id}
          style={{
            display: "flex",
            height: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {renderCard(banner)}
        </SwiperSlide>
      ) : (
        <Fragment key={banner?.id}>{renderCard(banner)}</Fragment>
      )
    );
  }

  if (section.type === SectionTypeEnum.AttributesRow) {
    const renderCard = (
      attributeValue: FormattedDataType["attributeValues"][0]
    ) => (
      <Template.elements.SectionCardWrapper
        isSlider={isSliderSection}
        data-test="attributeValue-card"
        background={section?.background}
      >
        <Template.elements.AttributeValueCard
          attributeValue={attributeValue}
          hideTitle={!section?.showItemName}
        />
      </Template.elements.SectionCardWrapper>
    );

    return section?.attributeValues?.map((attributeValue) =>
      isSliderSection ? (
        <SwiperSlide
          key={attributeValue?.id}
          style={{
            display: "flex",
            height: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {renderCard(attributeValue)}
        </SwiperSlide>
      ) : (
        <Fragment key={attributeValue?.id}>
          {renderCard(attributeValue)}
        </Fragment>
      )
    );
  }

  if (section.type === SectionTypeEnum.CollectionsRow) {
    const renderCard = (collection: FormattedDataType["collections"][0]) => (
      <Template.elements.SectionCardWrapper
        isSlider={isSliderSection}
        isCollection
        data-test="collection-card"
        background={section?.background}
      >
        <Template.elements.CollectionCard
          collection={collection}
          hideTitle={!section?.showItemName}
        />
      </Template.elements.SectionCardWrapper>
    );

    return section?.collections?.map((collection) =>
      isSliderSection ? (
        <SwiperSlide
          style={{
            display: "flex",
            height: "auto",
            justifyContent: "center",
          }}
          key={collection?.id}
        >
          {renderCard(collection)}
        </SwiperSlide>
      ) : (
        <Fragment key={collection?.id}>{renderCard(collection)}</Fragment>
      )
    );
  }

  if (section.type === SectionTypeEnum.ProductsRow) {
    const renderCard = (
      product: FormattedDataType["products"][0],
      index: number
    ) => (
      <Template.elements.SectionCardWrapper
        isSlider={isSliderSection}
        isProduct
        data-test="collection-card"
        background={section?.background}
      >
        <Template.elements.ProductCard
          product={product}
          isSlider={isSliderSection}
          productPerRow={section.itemsPerRow}
          index={index}
          productsCount={section?.products?.length}
        />
      </Template.elements.SectionCardWrapper>
    );

    return section?.products?.map((product, index) =>
      isSliderSection ? (
        <SwiperSlide
          key={product?.id}
          style={{
            height: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {renderCard(product, index)}
        </SwiperSlide>
      ) : (
        <Fragment key={product?.id}>{renderCard(product, index)}</Fragment>
      )
    );
  }

  return undefined;
};

export default SectionGenerator;
