import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { DefaultTextPrice } from "../../components/Price";
import {
  CartError,
  ProductType,
  CurrencyCode,
  CartSimpleItem,
  CartCustomItem,
  CheckoutServiceErrorCodes as ErrorCodes,
  CartFragment,
} from "../../generated/graphql";

export type FormattedError = CartError & {
  message: ReactElement;
};

export function generateItemRelatedErrors(
  item: CartFragment["items"][0],
  currencyCode: CurrencyCode,
  errors?: CartError[] | null
): FormattedError[] {
  if (!errors?.length) return [];
  const isItemSimple = item.product?.type === ProductType.Simple;
  const { itemRelatedErrors, customItemVariantsTitles } = findItemRelatedErrors(
    item,
    isItemSimple,
    errors
  );

  const formattedErrors = itemRelatedErrors.map((error) => ({
    code: error.code,
    params: error.params,
    message: generateErrorMessage(
      error,
      currencyCode,
      customItemVariantsTitles.get(error.params?.resourceId)
    ),
  }));

  return formattedErrors;
}

function findItemRelatedErrors(
  item: CartFragment["items"][0],
  isItemSimple: boolean,
  errors?: CartError[] | null
) {
  let itemId = "";
  const customItemVariantsTitles = new Map();
  const itemRelatedErrors = (errors || []).filter((error) => {
    const resourceId = error.params?.resourceId;
    if (isItemSimple) {
      const typedSimpleItem = item as CartSimpleItem;
      const productId = typedSimpleItem.product?.id.split("_")[1];
      const variantId = typedSimpleItem.variant?.id.split("_")[1];
      itemId =
        resourceId === productId
          ? productId
          : resourceId === variantId
          ? variantId
          : "";
    } else {
      const typedCustomItem = item as CartCustomItem;
      typedCustomItem.categories.map(({ selectedVariants }) => {
        selectedVariants.map(
          ({
            variant: {
              id,
              product: { title },
            },
          }) => {
            if (id.split("_")[1] === resourceId) {
              itemId = id.split("_")[1];
              customItemVariantsTitles.set(resourceId, title);
            }
          }
        );
      });
    }
    return itemId === resourceId;
  });
  return { itemRelatedErrors, customItemVariantsTitles };
}

export const isProductNotAvailableError = (
  errors?: CartError[] | FormattedError[] | null
): boolean => {
  if (!errors?.length) return false;
  return !!errors?.find(
    (error) =>
      error.code === ErrorCodes.ProductNotAvailable ||
      (error.code === ErrorCodes.InsufficientQuantity &&
        error?.params?.availableQuantity === 0)
  );
};

export const isCartDirty = (errors?: CartError[] | null): boolean => {
  if (!errors?.length) return false;
  const cartItemsRelatedErrors = errors?.filter(
    (error) =>
      error.code === ErrorCodes.ProductNotAvailable ||
      error.code === ErrorCodes.MaxQuantityExceeded ||
      error.code === ErrorCodes.MinQuantityNotMet ||
      error.code === ErrorCodes.InsufficientQuantity
  );
  return !!cartItemsRelatedErrors?.length;
};

export const isContactInformationError = (
  errors?: CartError[] | null
): boolean => {
  if (!errors?.length) return false;
  const contactInformationRelatedErrors = errors?.filter(
    (error) =>
      error.code === ErrorCodes.InvalidCustomerName ||
      error.code === ErrorCodes.InvalidCustomerIdentifier
  );
  return !!contactInformationRelatedErrors?.length;
};

export const isShippingInformationError = (
  errors?: CartError[] | null
): boolean => {
  if (!errors?.length) return false;
  const shippingInformationRelatedErrors = errors?.filter(
    (error) =>
      error.code === ErrorCodes.InvalidSecondaryPhone ||
      error.code === ErrorCodes.InvalidCheckoutNotes ||
      error.code === ErrorCodes.InvalidCheckoutPostcode
  );
  return !!shippingInformationRelatedErrors?.length;
};

export const isDeliveryError = (errors?: CartError[] | null): boolean => {
  if (!errors?.length) return false;
  const deliveryRelatedErrors = !!errors?.find(
    (error) => error.code === ErrorCodes.InvalidShippingRate
  );
  return deliveryRelatedErrors;
};
export const isCouponError = (errors?: CartError[] | null): boolean => {
  if (!errors?.length) return false;
  const couponRelatedErrors = !!errors?.find(
    (error) => error.code === ErrorCodes.PromoCodeNotValid
  );
  return couponRelatedErrors;
};

function generateErrorMessage(
  error: CartError,
  currencyCode: CurrencyCode,
  customItemVariantTitle?: string
): ReactElement {
  if (error.code === ErrorCodes.ProductNotAvailable) {
    return (
      <FormattedMessage
        defaultMessage="This product is no longer available, <b>please remove it from your cart</b>"
        values={{ b: (...chunks) => <b>{chunks}</b> }}
      />
    );
  }
  if (error.code === ErrorCodes.MaxQuantityExceeded) {
    return (
      <FormattedMessage
        defaultMessage="Please edit the quantity of this product, you can only add up to <b>{availableQuantity} per order</b>"
        values={{
          availableQuantity: error.params?.availableQuantity,
          b: (...chunks) => <b>{chunks}</b>,
        }}
      />
    );
  }
  if (error.code === ErrorCodes.MinQuantityNotMet) {
    return (
      <FormattedMessage
        defaultMessage="Please edit the quantity of this product, the minimum quantity <b>{availableQuantity} per order</b>"
        values={{
          availableQuantity: error.params?.availableQuantity,
          b: (...chunks) => <b>{chunks}</b>,
        }}
      />
    );
  }
  if (error.code === ErrorCodes.InsufficientQuantity) {
    if (customItemVariantTitle)
      return (
        <FormattedMessage
          defaultMessage="Please edit the quantity of {variantTitle} selected variant, <b>available stock: {availableQuantity}</b>"
          values={{
            availableQuantity: error.params?.availableQuantity,
            b: (...chunks) => <b>{chunks}</b>,
            variantTitle: customItemVariantTitle,
          }}
        />
      );
    return (
      <FormattedMessage
        defaultMessage="Please edit the quantity of this product, <b>available stock: {availableQuantity}</b>"
        values={{
          availableQuantity: error.params?.availableQuantity,
          b: (...chunks) => <b>{chunks}</b>,
        }}
      />
    );
  }
  if (error.code === ErrorCodes.PriceChanged) {
    if (customItemVariantTitle)
      return (
        <FormattedMessage
          defaultMessage="Please note that the price of {variantTitle} selected variant has changed from <b>{requestedPrice} to {availablePrice}</b> since you placed it in your cart"
          values={{
            requestedPrice: (
              <DefaultTextPrice
                money={{ amount: error.params?.requestedPrice!, currencyCode }}
              />
            ),
            availablePrice: (
              <DefaultTextPrice
                money={{ amount: error.params?.availablePrice!, currencyCode }}
              />
            ),
            b: (...chunks) => <b>{chunks}</b>,
            variantTitle: customItemVariantTitle,
          }}
        />
      );
    return (
      <FormattedMessage
        defaultMessage="Please note that the price of this product has changed from <b>{requestedPrice} to {availablePrice}</b> since you placed it in your cart"
        values={{
          requestedPrice: (
            <DefaultTextPrice
              money={{ amount: error.params?.requestedPrice!, currencyCode }}
            />
          ),
          availablePrice: (
            <DefaultTextPrice
              money={{ amount: error.params?.availablePrice!, currencyCode }}
            />
          ),
          b: (...chunks) => <b>{chunks}</b>,
        }}
      />
    );
  }
  return (
    <FormattedMessage defaultMessage="Oops, An Error occurred. please try again" />
  );
}
