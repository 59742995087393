import React, { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { Breakpoints, SpacingValues } from "../types";

interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  column?: boolean;
  rotateOn?: keyof typeof Breakpoints;
  flexWrap?: CSSProperties["flexWrap"];
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  fullWidth?: boolean;
  fullHeight?: boolean;
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  spacing?: keyof typeof SpacingValues;
  horizontalSpacing?: keyof typeof SpacingValues;
  verticalSpacing?: keyof typeof SpacingValues;
}

const Flex = (props: FlexProps) => {
  return <FlexContainer {...props}>{props.children}</FlexContainer>;
};

export default Flex;

const FlexContainer = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  flex-wrap: ${(props) => props.flexWrap || "nowrap"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "stretch"};
  width: ${(props) => (props.fullWidth ? "100%" : props.width || "auto")};
  height: ${(props) => (props.fullHeight ? "100%" : props.height || "auto")};

  gap: ${(props) =>
    props.spacing
      ? SpacingValues[props.spacing]
      : `${
          props.verticalSpacing
            ? SpacingValues[props?.verticalSpacing]
            : SpacingValues.m
        } ${
          props.horizontalSpacing
            ? SpacingValues[props?.horizontalSpacing]
            : SpacingValues.m
        } `};

  ${(props) =>
    props.rotateOn &&
    css`
      @media (max-width: ${Breakpoints[props.rotateOn]}px) {
        flex-direction: ${props.column ? "row" : "column"};
      }
    `}
`;
