import { useCallback, useContext, useEffect, useState } from "react";
import { useRouter } from "../../lib/i18n";
import { useStore } from "../../lib/storeData";
import { getActiveStepInfo, getInitialState } from "./checkoutUtils";
import { Context } from "./context";
import {
  addCustomItemToCart as addCustomItemToCartAction,
  toggleSideCart as toggleSideCartAction,
  closeSideCart as closeSideCartAction,
  resetCart as resetCartAction,
  overwriteCartItems as overwriteCartItemsAction,
  setCartUpdating as setCartUpdatingAction,
} from "./reducer/actionTypes";
import {
  CartItemType,
  CartItemInput,
  ResetCartStatus,
  CheckoutStatusEnum,
} from "./types";

export function useCart() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useCart should be in CartProvider");
  }
  const { currency } = useStore();
  const initialState = getInitialState(currency);
  const [cart, dispatch] = context;

  const addCustomItemToCart = useCallback(
    (payload: { newItem: CartItemInput; status?: CheckoutStatusEnum }) => {
      dispatch(
        addCustomItemToCartAction({
          ...payload,
          status: payload?.status ?? CheckoutStatusEnum.Active,
        })
      );
    },
    [dispatch]
  );

  const toggleSideCart = useCallback(() => {
    dispatch(toggleSideCartAction());
  }, [dispatch]);

  const setCartUpdating = useCallback(
    (payload: boolean) => {
      dispatch(setCartUpdatingAction(payload));
    },
    [dispatch]
  );

  const closeSideCart = useCallback(() => {
    dispatch(closeSideCartAction());
  }, [dispatch]);

  const resetCart = useCallback(
    (status: ResetCartStatus = CheckoutStatusEnum.Done) => {
      dispatch(resetCartAction(status));
    },
    [dispatch]
  );

  const overwriteCartItems = useCallback(
    (payload: { items: CartItemType[]; status: CheckoutStatusEnum }) => {
      dispatch(overwriteCartItemsAction(payload));
    },
    [dispatch]
  );

  const router = useRouter();
  useEffect(() => {
    if (
      (cart?.checkout?.status === CheckoutStatusEnum.Done ||
        cart?.checkout?.status === CheckoutStatusEnum.BuyNowDone) &&
      getActiveStepInfo(router?.pathname).name === "Other"
    ) {
      resetCart(cart.checkout.status);
    }
  }, [cart?.checkout?.status, dispatch, resetCart, router?.pathname]);

  const [isCSR, setIsCSR] = useState(false);
  useEffect(() => {
    setIsCSR(true);
  }, []);

  return {
    cart: isCSR ? cart : initialState,
    isReady: isCSR,
    addCustomItemToCart,
    resetCart,
    toggleSideCart,
    closeSideCart,
    overwriteCartItems,
    setCartUpdating,
  };
}
