import React from "react";
import styled from "styled-components";
import { useAuth } from "../../../../lib/Authentication";
import { H3, H5 } from "../../../../shared/globals";
import { themeColor } from "../../../../shared/styles-utils";
import { Link, useRouter } from "../../../../lib/i18n";
import { filterMePageItems, mePageItemsEnum } from "./consts";
import { useStore } from "../../../../lib/storeData";
import { ProfileLayoutProps } from "../../../types";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { OutlineLogoutIcon } from "../../../../assets/Icons";
import { Card } from "../../../../shared/globals/UiElements/Card";

const ProfileLayout: React.FC<ProfileLayoutProps> = ({ children }) => {
  const router = useRouter();
  const { user, logout } = useAuth();
  const { areReviewsActivated } = useStore();

  return (
    <Flex
      alignItems="flex-start"
      rotateOn="TABLET"
      className="py-3xl "
      spacing="xxl"
    >
      <Card className="max-md:w-full  flex-[1] gap-sm flex flex-col">
        <Flex className="border-b border-solid " justifyContent="space-between">
          <div className="p-sm">
            <H3 fontWeight="bold">{user?.name}</H3>
            <H5 className="text-gray-600">{user?.email}</H5>
          </div>
          <StyledLogOut className="flex items-center" onClick={logout}>
            <OutlineLogoutIcon />
          </StyledLogOut>
        </Flex>
        {filterMePageItems(areReviewsActivated).map(
          (item) =>
            item.id !== mePageItemsEnum.LogOut && (
              <Link key={item.id} href={`/me/${item.slug}/`}>
                <StyledItem
                  className={
                    router.route.includes(item.slug!) ? "active" : undefined
                  }
                >
                  {item.icon}
                  {item.title}
                </StyledItem>
              </Link>
            )
        )}
      </Card>

      <div className="max-md:w-full flex-[3]">{children}</div>
    </Flex>
  );
};

export default ProfileLayout;

/**
 *
 * Styles
 *
 */

const StyledItem = styled.div`
  padding: 10px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  svg {
    margin-inline-end: 5px;
  }
  &:hover {
    background-color: ${themeColor("primary")}20;
  }

  &.active {
    color: ${({ theme }) => theme.text.reverse};
    background-color: ${themeColor("primary")};

    svg > path {
      fill: ${({ theme }) => theme.text.reverse};
    }
  }
`;

const StyledLogOut = styled.div`
  svg {
    cursor: pointer;
  }
`;
