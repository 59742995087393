import { createReducer } from "@reduxjs/toolkit";
import { CheckoutStatusEnum, CartState } from "../types";
import {
  toggleSideCart,
  addCustomItemToCart,
  resetCart,
  closeSideCart,
  updateCouponAction,
  updateInformationAction,
  updateShippingAction,
  updateItemsAction,
  updateReceiptAction,
  updateCheckoutStatusAction,
  updateBuyNowItemAction,
  overwriteCartItems,
  setCartUpdating,
} from "./actionTypes";
import { addNewItem, calculateItemsTotal, isAvailableToAdd } from "./utils";

export const reducer = (initialState: CartState) =>
  createReducer(initialState, (builder) =>
    builder
      /**
       * CART REDUCERS
       */
      .addCase(setCartUpdating, (state, { payload }) => {
        return {
          ...state,
          isCartUpdating: payload,
        };
      })
      .addCase(toggleSideCart, (state) => {
        return {
          ...state,
          isSideCart: !state.isSideCart,
        };
      })
      .addCase(closeSideCart, (state) => {
        return {
          ...state,
          isSideCart: false,
        };
      })
      .addCase(
        addCustomItemToCart,
        (state, { payload: { newItem, status } }) => {
          const { isAvailable } = isAvailableToAdd(
            state.items,
            newItem,
            newItem.buildData
          );
          if (!isAvailable) return state;

          const items = addNewItem(state.items, newItem);

          if (status === CheckoutStatusEnum.BuyNowActive) {
            return {
              ...state,
              buyNowItem: [newItem],
              itemsTotal: calculateItemsTotal([newItem]),
            };
          }
          return {
            ...state,
            items,
            itemsTotal: calculateItemsTotal(items),
          };
        }
      )
      .addCase(overwriteCartItems, (state, { payload: { items, status } }) => {
        const itemsTotal = calculateItemsTotal(items);
        if (status === CheckoutStatusEnum.BuyNowActive) {
          return {
            ...state,
            buyNowItem: items,
            itemsTotal,
          };
        }
        return {
          ...state,
          items,
          itemsTotal,
        };
      })
      .addCase(resetCart, (state, { payload: status }) => {
        if (status === CheckoutStatusEnum.BuyNowDone)
          return {
            ...state,
            buyNowItem: null,
            checkout: {
              ...state.checkout,
              status: CheckoutStatusEnum.Active,
            },
            itemsTotal: state.items.length
              ? calculateItemsTotal(state.items)
              : {
                  ...state.itemsTotal,
                  amount: 0,
                },
          };

        return initialState;
      })
      /**
       * CHECKOUT REDUCERS
       */
      .addCase(updateInformationAction, (state, { payload }) => {
        const { information } = payload;
        return {
          ...state,
          checkout: {
            ...state?.checkout,
            information: {
              ...state?.checkout?.information,
              ...information,
            },
          },
        };
      })
      .addCase(updateShippingAction, (state, { payload }) => {
        const { shippingInfo } = payload;
        return {
          ...state,
          checkout: {
            ...state?.checkout,
            shippingInfo,
          },
        };
      })
      .addCase(updateCouponAction, (state, { payload }) => {
        const { coupon } = payload;
        return {
          ...state,
          checkout: {
            ...state?.checkout,
            coupon,
          },
        };
      })
      .addCase(updateItemsAction, (state, { payload }) => {
        const { items } = payload;
        return {
          ...state,
          items,
        };
      })
      .addCase(updateBuyNowItemAction, (state, { payload }) => {
        const { items } = payload;
        return {
          ...state,
          buyNowItem: items,
        };
      })
      .addCase(updateReceiptAction, (state, { payload }) => {
        const { receipt } = payload;
        return {
          ...state,
          checkout: {
            ...state?.checkout,
            receipt: {
              ...state?.checkout?.receipt,
              ...receipt,
            },
          },
        };
      })
      .addCase(updateCheckoutStatusAction, (state, { payload: status }) => {
        return {
          ...state,
          checkout: { ...state.checkout, status },
        };
      })
      .addDefaultCase((state) => state)
  );
