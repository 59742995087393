import styled, { css, keyframes } from "styled-components";
import { themeColor } from "../styles-utils";
import type {
  Align,
  FontWeight,
  Grow,
  Justify,
  Margin,
  Color,
  Flex,
  Spacing,
  FlexWrap,
  FlexBasis,
  FontSize,
} from "./types";
import { SpacingValues, ZIndex } from "./types";

export const FlexRow = styled.div<
  Justify &
    Align &
    Grow &
    Margin &
    Flex &
    Spacing &
    FlexWrap &
    FlexBasis & { fullWidth?: boolean }
>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  flex-grow: ${({ flexGrow }) => (flexGrow ? flexGrow : "0")};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  flex: ${({ flex }) => flex};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex-basis: ${({ flexBasis }) => flexBasis};
  ${({ spacing, theme: { isRTL } }) =>
    isRTL
      ? spacing &&
        css`
          > * {
            margin-left: ${SpacingValues[spacing]} !important;
            &:last-child {
              margin-left: 0 !important;
            }
          }
        `
      : spacing &&
        css`
          > * {
            margin-right: ${SpacingValues[spacing]} !important;
            &:last-child {
              margin-right: 0 !important;
            }
          }
        `}
`;

export const FlexCol = styled.div<
  Justify &
    Align &
    Grow &
    Margin &
    Flex &
    Spacing &
    FlexWrap &
    FlexBasis & { fullWidth?: boolean }
>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  flex-grow: ${({ flexGrow }) => (flexGrow ? flexGrow : "0")};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  flex: ${({ flex }) => flex};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex-basis: ${({ flexBasis }) => flexBasis};
  ${({ spacing }) =>
    spacing &&
    css`
      > * {
        margin-bottom: ${SpacingValues[spacing]} !important;
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    `}
`;

export const H1 = styled.h1<FontSize & FontWeight & Color & Margin>`
  color: ${({ theme, color }) => theme.colors[color!] || theme.text.default};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 900)};
  font-size: ${({ fontSize }) => fontSize || "1.5rem"};
  line-height: 1.3;
  margin: ${({ margin }) => (margin ? margin : 0)};
  padding: 0;
`;

export const H2 = styled.h2<FontSize & FontWeight & Margin>`
  color: ${({ theme }) => theme.text.default};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 700)};
  font-size: ${({ fontSize }) => fontSize || "1.25rem"};
  line-height: 1.3;
  margin: ${({ margin }) => (margin ? margin : 0)};
  padding: 0;
`;

export const H3 = styled.h3<FontSize & FontWeight & Color & Margin>`
  color: ${({ theme, color }) => theme.colors[color!] || theme.text.default};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 500)};
  font-size: ${({ fontSize }) => fontSize || "1rem"};
  line-height: 1.5;
  margin: ${({ margin }) => (margin ? margin : 0)};
  padding: 0;
`;

export const H4 = styled.h4<FontSize & FontWeight & Color & Margin>`
  color: ${({ theme, color }) => theme.colors[color!] || theme.text.default};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 500)};
  font-size: ${({ fontSize }) => fontSize || "0.875rem"};
  line-height: 1.4;
  margin: ${({ margin }) => (margin ? margin : 0)};
  padding: 0;
`;

export const H5 = styled.h5<FontSize & FontWeight & Color & Margin>`
  color: ${({ theme, color }) => theme.colors[color!] || theme.text.default};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 500)};
  font-size: ${({ fontSize }) => fontSize || "0.75rem"};
  line-height: 1.4;
  margin: ${({ margin }) => (margin ? margin : 0)};
  padding: 0;
`;

export const H6 = styled.h6<FontSize & FontWeight & Color & Margin>`
  color: ${({ theme, color }) => theme.colors[color!] || theme.text.default};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 500)};
  font-size: ${({ fontSize }) => fontSize || "0.675rem"};
  line-height: 1.5;
  margin: ${({ margin }) => (margin ? margin : 0)};
  padding: 0;
`;

export const P = styled.p<FontSize & Color & Margin & FontWeight>`
  color: ${({ theme, color }) => theme.colors[color!] || theme.text.default};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  font-size: ${({ fontSize }) => fontSize || "0.875rem"};
  line-height: 1.4;
  margin: ${({ margin }) => margin || 0};
  padding: 0;
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.text.default};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4;
  margin: 0;
  padding: 0;
`;

export const spin = keyframes`
  to {transform: rotate(360deg);}
`;

export const Spinner = styled.span<{ size?: number; inline?: boolean }>`
  width: ${({ size = 32 }) => `${size}px`};
  height: ${({ size = 32 }) => `${size}px`};
  &:before {
    content: "";
    box-sizing: border-box;
    display: inline-block;
    position: ${({ inline }) => (inline ? "relative" : "absolute")};
    top: ${({ inline }) => (inline ? "0" : "50%")};
    left: ${({ inline }) => (inline ? "0" : "50%")};
    width: ${({ size = 16 }) => `${size}px`};
    height: ${({ size = 16 }) => `${size}px`};
    margin-top: ${({ size = 16, inline }) => (inline ? "0" : `-${size / 2}px`)};
    margin-left: ${({ size = 16, inline }) =>
      inline ? "0" : `-${size / 2}px`};
    border-radius: 50%;
    border: 2px solid
      ${(props) =>
        props.color
          ? eval(`props.theme.${props.color}`)
          : props.theme.colors.primary};
    border-top-color: transparent;
    border-right-color: ${(props) =>
      props.color
        ? eval(`props.theme.${props.color}`)
        : props.theme.colors.primary};
    border-bottom-color: transparent;
    animation: ${spin} 2s linear infinite;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 500;
  margin-top: 20px;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.text.default};
  a {
    text-decoration: underline;
  }
`;

export const PrefixLabel = styled.label`
  display: flex;
  width: 100%;
  margin-top: 0.25rem;
  padding-left: 0.875rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text.placeholder};
  > input {
    margin-left: 2px;
  }
`;

export const Input = styled.input`
  flex: 1 0 auto;
  background: ${themeColor("white")};
  width: 100%;
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.bg.inactive};
  border-radius: 0.25rem;
  outline: none;
  padding: 0.5rem 0.75rem;
  margin-top: 0.375rem;
  box-shadow: none;
  font-weight: 600;
  &.invalid,
  &.invalid:focus {
    border: 1px solid #d21c1c;
  }
  ${(props) =>
    props.type === "checkbox" &&
    css`
      flex: initial;
      width: initial;
      margin: 0 0.5rem 0.125rem 0;
    `}
  &::placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
    font-weight: normal;
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
  }
  &:-moz-placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
  }
  &:focus {
    border: 1px solid ${themeColor("primary")};
  }
  /* Chrome, Safari, Edge, Opera, Firefox */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  -moz-appearance: textfield;
  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

export const TextArea = styled.textarea`
  flex: 1 0 auto;
  width: 100%;
  min-height: 66px;
  background: ${themeColor("white")};
  font-weight: 600;
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.bg.inactive};
  border-radius: 0.25rem;
  outline: none;
  padding: 0.75rem;
  margin-top: 0.438rem;
  box-shadow: none;
  resize: vertical;
  &.invalid {
    border: 1px solid #d21c1c;
  }
  &::placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
  }
  &:-moz-placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.text.default};
    opacity: 0.5;
  }
  &:focus {
    border: 1px solid ${themeColor("primary")};
  }
  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

export const UnderlineInput = styled.input`
  font-size: inherit;
  font-weight: inherit;
  color: ${({ theme }) => theme.text.default};
  border-bottom: 0.125rem solid ${({ theme }) => theme.bg.inactive};
  &:focus {
    border-color: ${({ theme }) => theme.brand.default};
  }
`;

export const Overlay = styled.div<{ isAbsolute?: boolean }>`
  position: ${({ isAbsolute }) => (isAbsolute ? "absolute" : "fixed")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  background-color: ${({ theme }) => theme.bg.wash};
  z-index: ${ZIndex["OVERLAY"]};
`;

export const Shadow = {
  low: "0 2px 8px",
  mid: "0 4px 12px",
  high: "0 8px 16px",
};

export const Transition = {
  hover: {
    on: "all 0.2s ease-in",
    off: "all 0.2s ease-out",
  },
  reaction: {
    on: "all 0.15s ease-in",
    off: "all 0.1s ease-out",
  },
  dropdown: {
    off: "all 0.35s ease-out",
  },
};
