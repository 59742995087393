import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, Form } from "react-final-form";
import createDecorator from "final-form-focus";
import Asterisk from "../../../../shared/globals/UiElements/Asterisk";
import { useStore } from "../../../../lib/storeData";
import { PrimaryButton } from "../../../../components/Button";
import {
  CheckCircle,
  Envelope,
  Marker,
  Mobile,
} from "../../../../assets/Icons";
import {
  FlexRow,
  H3,
  H4,
  H5,
  Input,
  Label,
  TextArea,
} from "../../../../shared/globals";
import {
  Container,
  StyledH4,
  StyledSection,
  ContactForm,
  StyledForm,
  RequiredSpan,
  ContactInfo,
  AddressBook,
  InfoHolder,
  Icon,
  Info,
  StyledH3,
  HorizontalLine,
  TextFollow,
  FeedbackMessageContainer,
} from "./styled";
import { SocialLinks } from "../../../../components/UtilityComponents/SocialLinks";
import { useSubmitStoreContactFormMutation } from "../../../../generated/graphql";
import InputPhone, {
  PhoneInput,
} from "../../../../shared/globals/UiElements/InputPhone";
import { Card } from "../../../../shared/globals/UiElements/Card";
import { fireSubmitContactFormEvent } from "./utils";

export type ContactFormValues = {
  name: string;
  email: string;
  phone: PhoneInput;
  subjectTitle: string;
  subjectBody: string;
};

const SuccessMessage = () => (
  <FeedbackMessageContainer isSuccess={true}>
    <CheckCircle />
    <H4 fontWeight={600}>
      <FormattedMessage defaultMessage="We have received your message and will reach you as soon as possible." />
    </H4>
  </FeedbackMessageContainer>
);

const FailureMessage = () => (
  <FeedbackMessageContainer isSuccess={false}>
    <H4 fontWeight={600}>
      <FormattedMessage defaultMessage="Something went wrong, please try again." />
    </H4>
  </FeedbackMessageContainer>
);

const focusOnError = createDecorator<ContactFormValues>();

const Contact = () => {
  const intl = useIntl();
  const { storeId, socialMedia, contactInfo, location } = useStore();
  const [isSuccess, setIsSuccess] = React.useState<boolean | undefined>(
    undefined
  );

  const [SubmitStoreContactForm] = useSubmitStoreContactFormMutation();

  const email = contactInfo?.email;
  const phone = contactInfo?.phone;
  const address = location?.address;

  const handleSubmitContact = async (input: ContactFormValues) => {
    try {
      const { data } = await SubmitStoreContactForm({
        variables: {
          input: {
            storeId,
            name: input.name,
            fromEmail: input.email,
            phone: input?.phone?.value!,
            messageSubject: input.subjectTitle,
            messageBody: input.subjectBody,
          },
        },
      });

      if (data?.submitStoreContactForm?.statusCode! < 400) {
        handleFeedback(true);
        fireSubmitContactFormEvent(input);
      } else {
        handleFeedback(false);
      }
    } catch (error) {
      handleFeedback(false);
    }
  };

  const handleFeedback = (value: boolean) => {
    setIsSuccess(value);
    setTimeout(() => setIsSuccess(undefined), 5000);
  };

  const isContactInfoExist = !!(email || phone || address);
  const socialLinkContent = socialMedia?.length ? (
    <>
      <HorizontalLine />
      <TextFollow>
        <FormattedMessage defaultMessage="You also follow & contact us through" />
      </TextFollow>
      <SocialLinks socialSettings={socialMedia} />
    </>
  ) : null;

  return (
    <Container alignItems="stretch">
      <Card paddingSize="xl">
        <StyledH4>
          <FormattedMessage
            defaultMessage="You can send your questions by filling out the form below & We will get back to you as
            soon as possible."
          />
        </StyledH4>
        <StyledSection alignItems="flex-start" isColumn={!isContactInfoExist}>
          <ContactForm>
            <Form<ContactFormValues>
              onSubmit={handleSubmitContact}
              validate={validate}
              initialValues={{}}
              decorators={[focusOnError]}
              render={({ handleSubmit, submitting }) => {
                return (
                  <StyledForm onSubmit={handleSubmit}>
                    <Label>
                      <FlexRow>
                        <FormattedMessage defaultMessage="Name" />
                        <Asterisk />
                      </FlexRow>
                      <Field name="name">
                        {({ input, meta: { error, touched } }) => (
                          <>
                            <Input
                              {...input}
                              className={error && touched ? "invalid" : ""}
                            />
                            {error && touched && (
                              <RequiredSpan>{error}</RequiredSpan>
                            )}
                          </>
                        )}
                      </Field>
                    </Label>
                    <Label>
                      <FlexRow>
                        <FormattedMessage defaultMessage="Your e-mail" />
                        <Asterisk />
                      </FlexRow>
                      <Field name="email">
                        {({ input, meta: { error, touched } }) => (
                          <>
                            <Input
                              {...input}
                              className={error && touched ? "invalid" : ""}
                              type="email"
                            />
                            {error && touched && (
                              <RequiredSpan>{error}</RequiredSpan>
                            )}
                          </>
                        )}
                      </Field>
                    </Label>
                    <Label>
                      <FlexRow>
                        <FormattedMessage defaultMessage="Phone no." />
                        <Asterisk />
                      </FlexRow>
                      <Field<PhoneInput> name="phone">
                        {(fieldProps) => (
                          <InputPhone
                            {...fieldProps}
                            dataTest="type-primary-phone"
                          />
                        )}
                      </Field>
                    </Label>
                    <Label>
                      <FlexRow>
                        <FormattedMessage defaultMessage="Subject Title" />
                        <Asterisk />
                      </FlexRow>
                      <Field name="subjectTitle">
                        {({ input, meta: { error, touched } }) => (
                          <>
                            <Input
                              {...input}
                              className={error && touched ? "invalid" : ""}
                            />
                            {error && touched && (
                              <RequiredSpan>{error}</RequiredSpan>
                            )}
                          </>
                        )}
                      </Field>
                    </Label>
                    <Label>
                      <FlexRow>
                        <FormattedMessage defaultMessage="Subject Body" />
                        <Asterisk />
                      </FlexRow>
                      <Field name="subjectBody">
                        {({ input, meta: { error, touched } }) => (
                          <>
                            <TextArea
                              {...input}
                              className={error && touched ? "invalid" : ""}
                              placeholder={intl.formatMessage({
                                defaultMessage: "Your message here...",
                              })}
                            />
                            {error && touched && (
                              <RequiredSpan>{error}</RequiredSpan>
                            )}
                          </>
                        )}
                      </Field>
                    </Label>
                    <PrimaryButton
                      type="submit"
                      style={{ marginTop: "1.5rem" }}
                      fullWidth
                      isLoading={submitting}
                    >
                      <FormattedMessage defaultMessage="Send" />
                    </PrimaryButton>
                    {isSuccess === true ? (
                      <SuccessMessage />
                    ) : isSuccess === false ? (
                      <FailureMessage />
                    ) : null}
                  </StyledForm>
                );
              }}
            />
          </ContactForm>
          {isContactInfoExist ? (
            <ContactInfo>
              <Card paddingSize="xl">
                <AddressBook alignItems="flex-start">
                  {email && (
                    <InfoHolder>
                      <Icon>
                        <Envelope />
                      </Icon>
                      <Info alignItems="flex-start">
                        <H5>
                          <FormattedMessage defaultMessage="Email" />
                        </H5>
                        <StyledH3>{email}</StyledH3>
                      </Info>
                    </InfoHolder>
                  )}
                  {phone && (
                    <InfoHolder>
                      <Icon>
                        <Mobile />
                      </Icon>
                      <Info alignItems="flex-start">
                        <H5>
                          <FormattedMessage defaultMessage="Phone" />
                        </H5>
                        <H3>{phone}</H3>
                      </Info>
                    </InfoHolder>
                  )}
                  {address && (
                    <InfoHolder>
                      <Icon>
                        <Marker />
                      </Icon>
                      <Info alignItems="flex-start">
                        <H5>
                          <FormattedMessage defaultMessage="Address" />
                        </H5>
                        <H3>{address}</H3>
                      </Info>
                    </InfoHolder>
                  )}
                  {socialLinkContent}
                </AddressBook>
              </Card>
            </ContactInfo>
          ) : (
            socialLinkContent
          )}
        </StyledSection>
      </Card>
    </Container>
  );
};

const validate = (values: ContactFormValues) => {
  const errors: Record<string, unknown> = {};

  if (!values.name?.trim()) {
    errors.name = <FormattedMessage defaultMessage="Enter your full name" />;
  }

  if (
    !values?.email ||
    !values?.email?.match(
      /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    )
  ) {
    errors.email = (
      <FormattedMessage defaultMessage="Enter a valid email address" />
    );
  }

  if (!values.phone?.isValid) {
    errors.phone = (
      <FormattedMessage defaultMessage="Enter a valid phone number" />
    );
  }

  if (!values.subjectTitle?.trim()) {
    errors.subjectTitle = (
      <FormattedMessage defaultMessage="Enter subject title" />
    );
  }

  if (!values.subjectBody?.trim()) {
    errors.subjectBody = (
      <FormattedMessage defaultMessage="Enter subject content" />
    );
  }
  return errors;
};

export default Contact;
