import React from "react";
import { Section } from "../../../shared/layout";
import { StoreTemplate } from "../../TemplateLoader";

const UserOrderDetails = () => {
  const Template = StoreTemplate.get();

  return (
    <Section background={"backgroundReverse"}>
      <Template.elements.ProfileLayout>
        <Template.elements.UserOrderElement />
      </Template.elements.ProfileLayout>
    </Section>
  );
};

export default UserOrderDetails;
