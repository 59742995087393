import * as React from "react";
import { useAuth } from "../../../lib/Authentication";
import GuestInformation from "../../../components/Checkout/Information/GuestInformation";
import CustomerInformation from "./CustomerInformation";
import { useCartData } from "../../../lib/cartData/useCartData";
import { isCartDirty } from "../../../shared/utils/error-utils";
import { useRouter } from "next/router";

const Information = () => {
  const { isLoggedIn } = useAuth();
  const { cart } = useCartData();
  const router = useRouter();

  const queryCartId = router?.query?.cartId as string;

  const cartIdPrefix = queryCartId.split("_")[0];

  const cartData = cart.recoveryCart ?? cart;

  const cartItemsErrorsFound = isCartDirty(cartData?.cartErrors);

  React.useEffect(() => {
    if (
      cartIdPrefix !== "recoverycart" &&
      cartIdPrefix !== "RecoveryCart" &&
      (!cartData?.items?.length || cartItemsErrorsFound)
    )
      router.replace("/cart");
    else if (cartItemsErrorsFound && cartIdPrefix === "recoverycart") {
      router.replace(`/checkout/invalid-order/${queryCartId}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData]);

  return isLoggedIn && cartIdPrefix !== "recoverycart" ? (
    <CustomerInformation />
  ) : (
    <GuestInformation />
  );
};

export default Information;
