import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";
import { Overlay } from "../../../../shared/globals";
import {
  Filter as FilterIcon,
  Delete as Delete,
  LeftArrow,
} from "../../../../assets/Icons";
import { useEscapeAndStopScrollingEffect } from "../../../../hooks/useEscapeAndStopScrollingEffect";
import {
  CloseIconDesktop,
  CloseIconMobile,
  Container,
  FilterSection,
  FlipSvg,
  ScrollableSection,
  StyledFlexRow,
  StyledH3WithMargin,
} from "./styled";
import { LongTailRightArrow } from "../../../../assets/Icons/LongTailRightArrow";
import { useStore } from "../../../../lib/storeData";
import {
  ProductAttributeValue,
  ProductCollection,
  ProductOptionValue,
  useFullTextSearchAndFilterQuery,
  useGetFiltersQuery,
} from "../../../../generated/graphql";
import { ApplyFilterSection } from "./components/ApplyFilterSection";
import { AvailableFilterChoices } from "./components/AvailableFilterChoices";
import { FiltersSection } from "./components/FiltersSection";
import { FilterByPrice } from "./components/FilterByPrice";
import { DefaultFilters } from "./consts";
import {
  getAvailableFilters,
  getFiltersSelectors,
} from "../../../../shared/utils/filterHelperFunctions";

interface SelectedFiltersProps {
  id: string | null | undefined;
  name: string | null | undefined;
}

interface FilterProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const Filter = ({ isOpen, onClose }: FilterProps) => {
  const router = useRouter();
  const [selectedFilter, setSelectedFilter] =
    React.useState<SelectedFiltersProps>({
      id: "",
      name: "",
    });
  const { storeId } = useStore();
  const selectedFiltersIds =
    typeof router.query.filters === "string"
      ? [router.query.filters]
      : router.query.filters || [];

  const intl = useIntl();
  const { attributeValueSelectors, collectionIds, optionValueSelectors } =
    getFiltersSelectors(selectedFiltersIds || []);
  const { data, error, loading } = useGetFiltersQuery({
    variables: {
      storeId,
    },
  });

  const { data: products } = useFullTextSearchAndFilterQuery({
    variables: {
      storeId,
      connectionSettings: {
        offset: 0,
        first: 24,
      },
      filters: {
        search: router.query.query as string,
        collectionIds,
        attributeValueSelectors,
        optionValueSelectors,
        priceRange: {
          min: parseFloat(router.query.minPrice as string) || undefined,
          max: parseFloat(router.query.maxPrice as string) || undefined,
        },
      },
    },
  });

  useEscapeAndStopScrollingEffect({ isOpen: isOpen!, onClose });

  const { filtersDictionary } = getAvailableFilters({
    attributesProductsDistribution: data?.getApplicableFilters
      ?.attributeValues! as ProductAttributeValue[],
    collectionsProductsDistribution: data?.getApplicableFilters
      ?.collections as ProductCollection[],
    optionsProductsDistribution: data?.getApplicableFilters
      ?.optionValues as ProductOptionValue[],
    intl,
  });

  return (
    <>
      {isOpen && <Overlay onClick={onClose} />}
      <Container isOpen={isOpen!}>
        <FilterSection>
          <StyledFlexRow>
            {selectedFilter.name === "" ? (
              <FilterIcon />
            ) : (
              <FlipSvg
                onClick={() =>
                  setSelectedFilter({
                    id: "",
                    name: "",
                  })
                }
              >
                <LongTailRightArrow />
              </FlipSvg>
            )}
            <StyledH3WithMargin>
              {selectedFilter.name === "" ? (
                <FormattedMessage defaultMessage="Filter" />
              ) : selectedFilter.name === DefaultFilters.Collections ? (
                <FormattedMessage defaultMessage="Product Collections" />
              ) : selectedFilter.name === DefaultFilters.Price ? (
                <FormattedMessage defaultMessage="Price" />
              ) : (
                selectedFilter.name
              )}
            </StyledH3WithMargin>
          </StyledFlexRow>
          <CloseIconDesktop onClick={onClose}>
            <Delete />
          </CloseIconDesktop>
          <CloseIconMobile onClick={onClose}>
            <LeftArrow />
          </CloseIconMobile>
        </FilterSection>
        {selectedFilter.name === "" && (
          <FiltersSection
            selectFilter={setSelectedFilter}
            availableFilters={filtersDictionary}
            loading={loading}
            selectedFiltersIds={selectedFiltersIds}
          />
        )}
        {selectedFilter.id && (
          <ScrollableSection>
            <AvailableFilterChoices
              availableFilters={filtersDictionary[selectedFilter.id]}
              loading={loading}
              error={error!}
              selectedFilterIds={selectedFiltersIds}
            />
          </ScrollableSection>
        )}
        {selectedFilter.name === "Price" && <FilterByPrice />}
        <ApplyFilterSection
          onClose={onClose!}
          totalCount={products?.fullTextSearchAndFilter?.totalCount || 0}
          loading={loading}
        />
      </Container>
    </>
  );
};

export default Filter;
