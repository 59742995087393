import React from "react";
export const OutlinedShoppingBasket = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.1737 11.8723L4.89453 5.83398H15.4162C15.9587 5.83398 16.3562 6.34315 16.2245 6.86982L15.1012 11.3632C14.9304 12.0457 14.3504 12.5473 13.6504 12.6173L7.97036 13.1857C7.1237 13.2698 6.34953 12.704 6.1737 11.8723Z"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.89533 5.83398L4.35366 3.33398H2.91699"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.2578 16.055C14.0894 16.055 13.9528 16.1917 13.9544 16.36C13.9544 16.5284 14.0911 16.665 14.2594 16.665C14.4278 16.665 14.5644 16.5284 14.5644 16.36C14.5636 16.1917 14.4269 16.055 14.2578 16.055"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.24746 16.0566C7.07912 16.0566 6.94246 16.1932 6.94412 16.3616C6.94246 16.5307 7.07996 16.6674 7.24829 16.6674C7.41662 16.6674 7.55329 16.5307 7.55329 16.3624C7.55329 16.1932 7.41662 16.0566 7.24746 16.0566"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
