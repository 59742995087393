import React, { ReactNode } from "react";
import styled from "styled-components";

type TooltipProps = {
  tooltipMessage: string;
  icon?: ReactNode;
};

const Tooltip: React.FC<TooltipProps> = ({ tooltipMessage, icon }) => {
  return (
    <StyledTooltip data-tooltip={tooltipMessage}>
      <span>{icon}</span>
    </StyledTooltip>
  );
};

export default Tooltip;

const StyledTooltip = styled.span`
  position: relative;
  display: inline-block;
  cursor: help;
  &:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: ${({ theme }) => theme["bg"].wash};
    max-width: 320px;
    width: max-content;
    color: white;
    border-radius: 5px;
    padding: 12px;
    font-size: 12px;
  }
`;
