import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { ReviewsQuery, useReviewsQuery } from "../../../generated/graphql";
import { useStore } from "../../../lib/storeData";
import { H2 } from "../../../shared/globals";
import { RatingAndReviewsProps } from "../../types";
import OverAllRating from "../components/ReviewsAndRatings/OverAllRating";
import Reviews from "../components/ReviewsAndRatings/Reviews";
import Flex from "../../../shared/globals/UiElements/Flex";

const ITEM_PER_PAGE = 4;

const RatingAndReviews = ({ product }: RatingAndReviewsProps) => {
  const { storeId } = useStore();
  const [page, setPage] = React.useState(1);
  const offset = (page - 1) * ITEM_PER_PAGE;
  const [renderedData, setRenderedData] = React.useState<
    ReviewsQuery | undefined
  >();

  const { data, loading } = useReviewsQuery({
    variables: {
      productId: product?.id,
      storeId,
      connection: {
        first: ITEM_PER_PAGE,
        offset,
      },
    },
  });

  useEffect(() => {
    if (data) setRenderedData(data);
  }, [data]);

  if (!renderedData) return null;

  return (
    <Flex
      column
      alignItems="flex-start"
      justifyContent="flex-start"
      className="pb-2xl"
      spacing="xxxl"
    >
      <div className="border-b border-b-gray-200 w-full pb-sm">
        <H2 fontWeight="600">
          <FormattedMessage defaultMessage="Customer ratings & reviews" />
        </H2>
      </div>
      <Flex
        rotateOn="TABLET"
        justifyContent="space-between"
        spacing="xxl"
        alignItems="flex-start"
        fullWidth
      >
        <OverAllRating
          numberOfReviews={renderedData?.product?.reviewsStatistics.total || 0}
          overAllRating={renderedData?.product?.reviewsStatistics.average || 0}
          numberOfReviewsByStars={{
            5: renderedData?.product?.reviewsStatistics.starsCount.five || 0,
            4: renderedData?.product?.reviewsStatistics.starsCount.four || 0,
            3: renderedData?.product?.reviewsStatistics.starsCount.three || 0,
            2: renderedData?.product?.reviewsStatistics.starsCount.two || 0,
            1: renderedData?.product?.reviewsStatistics.starsCount.one || 0,
          }}
          product={product}
        />
        <Reviews
          reviews={renderedData?.product}
          numberOfReviews={renderedData?.product?.reviewsStatistics.total || 0}
          page={page}
          setPage={setPage}
          loading={loading}
        />
      </Flex>
    </Flex>
  );
};

export default RatingAndReviews;
