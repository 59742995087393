import React from "react";
import styled, { css } from "styled-components";
import { StoreTemplate } from "../../TemplateLoader";
import type { TemplateElement, ProductsListProps } from "../../types";
import { Breakpoints } from "../../../shared/globals/types";

const ProductsList: TemplateElement<ProductsListProps> = ({
  products,
  showTwoProductsPerRowOn,
}) => {
  const Template = StoreTemplate.get();

  return (
    <StyledContainer showTwoProductsPerRowOn={showTwoProductsPerRowOn}>
      {products?.map((product) => (
        <Template.elements.ProductCard key={product?.id} product={product!} />
      ))}
    </StyledContainer>
  );
};

export default ProductsList;

/**
 *
 * Styles
 *
 */

const StyledContainer = styled.div<{
  showTwoProductsPerRowOn?: keyof typeof Breakpoints;
}>`
  padding: 40px 0;
  display: grid;
  /* Only using 1f breaks for small screens for long contents because 1fr = minmax(auto,1fr)
   * and item size can't be smaller than auto which is the grid item size.
   * Using minmax(0, 1fr) makes the grid element resizes min to 0 and max to 1fr and all
   * items resize proportionally
   */
  grid-template-columns: repeat(4, minmax(0, 1fr));
  justify-content: center;
  grid-gap: 24px;
  ${(props) => css`
    @media (max-width: ${Breakpoints[props?.showTwoProductsPerRowOn!] ||
      Breakpoints["WIDESCREEN"]}px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  `}
`;
