import createDecorator from "final-form-focus";
import React, { useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { FormattedMessage } from "react-intl";
import { useAuth } from "../../../lib/Authentication";
import { FlexRow, H2, H3, Label, TextArea } from "../../../shared/globals";
import Asterisk from "../../../shared/globals/UiElements/Asterisk";
import AddressesEmptyState from "../components/CustomerProfile/my-addresses/AddressesEmptyState";
import AddressesPanel from "../components/CustomerProfile/my-addresses/AddressesPanel";
import {
  ContinueButton,
  GoBackButton,
  RadioButtonLabel,
  RouteSection,
  StyledDivForLabel,
} from "../../../components/Checkout/Information/styled";
import {
  StyledForm,
  CheckboxInput1,
  Checkmark1,
  RequiredSpan,
  ShippingSection,
} from "../../../components/Checkout/Information/styled";
import {
  AddressInfoFragment,
  CartStepEnum,
  CheckoutFieldOptions,
  CustomCheckoutSetting,
  CustomerIdentifier,
  useListCustomerAddressesQuery,
  useShippingDestinationsQuery,
} from "../../../generated/graphql";
import AddAddress from "../components/CustomerProfile/my-addresses/AddAddress";
import { LeftArrow, RightArrow } from "../../../assets/Icons";
import { Link } from "../../../lib/i18n";
import LoadingSpinner from "../../../shared/globals/UiElements/LoadingSpinner";
import { useStore } from "../../../lib/storeData";
import { Card } from "../../../shared/globals/UiElements/Card";
import { useCartData } from "../../../lib/cartData/useCartData";
import Flex from "../../../shared/globals/UiElements/Flex";
import { useRouter } from "../../../lib/i18n";
import _, { isEqual } from "lodash";
import {
  CheckoutInformation,
  fireBeginCheckoutEvent,
  fireCheckoutInformationEvent,
  getShippingDestinations,
  isShippingDestinationAvailable,
} from "../../../components/Checkout/Information/utils";

const CustomerInformation: React.FC = () => {
  const focusOnError = React.useMemo(() => createDecorator(), []);
  const { id: storeId, customCheckoutSetting } = useStore();
  const { user: loggedInUser } = useAuth();
  const [selectedAddress, setSelectedAddress] = useState<
    AddressInfoFragment & { isValid?: boolean }
  >();
  const [error, setError] = useState<React.ReactNode>(null);
  const { data, loading } = useListCustomerAddressesQuery({
    variables: { customerId: loggedInUser?.id!, storeId },
    skip: !loggedInUser?.id,
  });

  const {
    updateContactInfo: { updateContactInfo },
    setCartShippingDetails: {
      setCartShippingDetails,
      loading: CartShippingDetailsLoading,
    },
    cart,
  } = useCartData();

  const { data: shippingData } = useShippingDestinationsQuery({
    variables: { storeId },
  });

  const shippingDestinationsMap = useMemo(
    () => getShippingDestinations(shippingData),
    [shippingData]
  );

  const shippingDestinationAvailable = isShippingDestinationAvailable(
    shippingDestinationsMap,
    cart?.shippingDetails?.area
  );

  const router = useRouter();
  const {
    query: { cartId },
  } = useRouter();

  useEffect(() => {
    if (cart?.shippingDetails?.id) {
      setSelectedAddress(
        data?.customerAddresses?.find(
          (item) => item?.id === cart?.shippingDetails?.id
        )
      );
    } else {
      setSelectedAddress(
        data?.customerAddresses?.find((item) => item?.isDefault)
      );
    }
  }, [data, cart?.shippingDetails?.id]);
  useEffect(() => {
    if (
      loggedInUser &&
      (!cart.contactInfo ||
        !_.isEqual(
          {
            email: loggedInUser?.email,
            name: loggedInUser?.name,
            phone: loggedInUser?.phone,
          },
          {
            email: cart.contactInfo?.email,
            name: cart.contactInfo?.name,
            phone: cart.contactInfo?.phone,
          }
        ))
    )
      updateContactInfo({
        info: {
          name: loggedInUser?.name!,
          email: loggedInUser?.email,
          phone: loggedInUser?.phone!,
          id: cart?.customer?.id!,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);
  const handleSelect = (address: AddressInfoFragment) => {
    setError(null);
    setSelectedAddress(address);
  };
  const setShippingDetails = async (selectedAddress, notes) => {
    const shippingDestination = createShippingDestination(
      selectedAddress,
      shippingDestinationAvailable
    );
    const variables = {
      addressLine1: selectedAddress?.addressLine1!,
      addressLine2: selectedAddress?.addressLine2!,
      id: selectedAddress?.id!,
      area: {
        cityId: shippingDestination?.city?.value!,
        countryId: shippingDestination?.country?.value!,
        regionId: shippingDestination?.region?.value!,
        stateId: shippingDestination?.state?.value!,
      },
      notes: notes,
      postalCode: selectedAddress?.postalCode,
      secondPhone: selectedAddress?.secondPhone,
    };
    const preShippingDetails = {
      addressLine1: cart?.shippingDetails?.addressLine1,
      addressLine2: cart?.shippingDetails?.addressLine2,
      id: cart?.shippingDetails?.id,
      area: {
        cityId:
          cart?.shippingDetails?.area?.cityId === null
            ? undefined
            : cart?.shippingDetails?.area?.cityId,
        countryId:
          cart?.shippingDetails?.area?.countryId === null
            ? undefined
            : cart?.shippingDetails?.area?.countryId,
        regionId:
          cart?.shippingDetails?.area?.regionId === null
            ? undefined
            : cart?.shippingDetails?.area?.regionId,
        stateId:
          cart?.shippingDetails?.area?.stateId === null
            ? undefined
            : cart?.shippingDetails?.area?.stateId,
      },
      notes: cart?.shippingDetails?.notes,
      postalCode: cart?.shippingDetails?.postalCode,
      secondPhone: cart?.shippingDetails?.secondPhone,
    };
    if (!isEqual(variables, preShippingDetails)) {
      await setCartShippingDetails({
        shippingDetails: variables!,
      });
    }
  };
  const handleSubmit = ({ notes }) => {
    if (!selectedAddress) {
      setError(
        <FormattedMessage defaultMessage="Select your shipping address" />
      );
      return;
    }
    const informationEventParametersValues = {
      address:
        cart?.shippingDetails?.addressLine1 ||
        cart.shippingInformation?.address,
      apartment:
        cart?.shippingDetails?.addressLine2 ||
        cart.shippingInformation?.apartment,
      email: cart?.contactInfo?.email,
      name: cart?.contactInfo?.name,
      postalCode:
        cart?.shippingDetails?.postalCode ||
        cart.shippingInformation?.postalCode,
      phone: { value: cart?.contactInfo?.phone, isValid: true },
      secondPhone: {
        value:
          cart?.shippingDetails?.secondPhone ||
          cart?.shippingInformation?.secondPhone?.value,
        isValid: cart?.shippingInformation?.secondPhone?.isValid || true,
      },
      notes: cart?.shippingDetails?.notes || cart.shippingInformation?.notes,
      isSubscribed: false,
      shippingDestination: {
        country: {
          label:
            cart?.shippingDetails?.area?.country?.name ||
            cart.shippingInformation?.shippingDestination?.country?.label,
          value:
            cart?.shippingDetails?.area?.countryId ||
            cart.shippingInformation?.shippingDestination?.country?.value,
        },
        state: {
          label:
            cart?.shippingDetails?.area?.state?.name ||
            cart.shippingInformation?.shippingDestination?.state?.label,
          value:
            cart?.shippingDetails?.area?.stateId ||
            cart.shippingInformation?.shippingDestination?.state?.value,
        },
        city: {
          label:
            cart?.shippingDetails?.area?.city?.name ||
            cart.shippingInformation?.shippingDestination?.city?.label,
          value:
            cart?.shippingDetails?.area?.cityId ||
            cart.shippingInformation?.shippingDestination?.city?.value,
        },
        region: {
          label:
            cart?.shippingDetails?.area?.region?.name ||
            cart.shippingInformation?.shippingDestination?.region?.label,
          value:
            cart?.shippingDetails?.area?.regionId ||
            cart.shippingInformation?.shippingDestination?.region?.value,
        },
        isValid: cart.shippingInformation?.shippingDestination?.isValid,
      },
    } as CheckoutInformation;
    setShippingDetails(selectedAddress, notes);
    if (
      cart.lastStep === CartStepEnum.Shopping ||
      cart.lastStep === CartStepEnum.Information
    ) {
      fireBeginCheckoutEvent(cart);
      fireCheckoutInformationEvent(informationEventParametersValues);
    }
    router.push(`/checkout/delivery/${cartId}`);
  };

  const validate = (values: { notes: string }) => {
    const errors: Record<string, unknown> = {};
    if (
      !values.notes?.trim() &&
      customCheckoutSetting?.notesToSeller === CheckoutFieldOptions.Mandatory
    ) {
      errors.notes = <FormattedMessage defaultMessage="Required field" />;
    }

    return errors;
  };

  if (!loggedInUser?.id || loading) {
    return <LoadingSpinner minHeight="400px" />;
  }

  const checkedAddresses = checkAddressesValidity(
    data?.customerAddresses,
    customCheckoutSetting!
  );

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      decorators={[focusOnError]}
      initialValues={{
        notes: cart?.shippingDetails?.notes,
      }}
      render={({ handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Flex column className="mb-2xl">
            <H2>
              <FormattedMessage defaultMessage="Contact information" />
            </H2>
            <Card paddingSize="none">
              <Flex spacing="none" column>
                <Flex className="border-b p-md" fullWidth>
                  <div className="text-gray-600">Name</div>
                  <div className="text-gray-800">{loggedInUser?.name}</div>
                </Flex>
                {customCheckoutSetting?.identifier !==
                  CustomerIdentifier.Phone && (
                  <Flex className="border-b p-md" fullWidth>
                    <div className="text-gray-600">Email</div>
                    <div className="text-gray-800">{loggedInUser?.email}</div>
                  </Flex>
                )}
                {customCheckoutSetting?.identifier !==
                  CustomerIdentifier.Email && (
                  <Flex className="border-b p-md" fullWidth>
                    <div className="text-gray-600">Phone</div>
                    <div className="text-gray-800">{loggedInUser?.phone}</div>
                  </Flex>
                )}
              </Flex>
            </Card>
          </Flex>

          <ShippingSection>
            <H2>
              <FormattedMessage defaultMessage="Shipping information" />
            </H2>
            <br />
            <H3
              style={{
                marginBottom: "1rem",
              }}
            >
              <FormattedMessage defaultMessage="Select a shipping address" />
            </H3>

            {data?.customerAddresses?.length! > 0 ? (
              <>
                {checkedAddresses?.map((address) => (
                  <AddressesPanel
                    key={address?.id}
                    onSelectAddress={() => handleSelect(address)}
                    userName={loggedInUser.name!}
                    address={address}
                    isValid={address?.isValid}
                    CheckButton={
                      <StyledDivForLabel
                        className={
                          address.id === selectedAddress?.id ? "active" : ""
                        }
                        onClick={() => handleSelect(address)}
                      >
                        <RadioButtonLabel>
                          <CheckboxInput1 type="radio" name="radio" />
                          <Checkmark1 />
                        </RadioButtonLabel>
                      </StyledDivForLabel>
                    }
                  />
                ))}
                <AddAddress fullWidth />
              </>
            ) : (
              <Card paddingSize="xl" className="mt-sm">
                <AddressesEmptyState />
              </Card>
            )}
            {customCheckoutSetting?.notesToSeller !==
              CheckoutFieldOptions.Inactive && (
              <Label>
                <FlexRow>
                  <FormattedMessage defaultMessage="Notes to seller" />
                  {customCheckoutSetting?.notesToSeller ===
                    CheckoutFieldOptions.Mandatory && <Asterisk />}
                </FlexRow>
                <Field name="notes">
                  {({ input, meta: { error, touched } }) => (
                    <>
                      <TextArea data-test="type-notes" {...input} />
                      {error && touched && (
                        <RequiredSpan style={{ height: "auto" }}>
                          {error}
                        </RequiredSpan>
                      )}
                    </>
                  )}
                </Field>
              </Label>
            )}
          </ShippingSection>

          <RouteSection>
            <Link href="/cart">
              <GoBackButton type="button" data-test="button-go-back">
                <div>
                  <LeftArrow />
                </div>
                <FormattedMessage defaultMessage="Return to cart" />
              </GoBackButton>
            </Link>
            <div>
              <ContinueButton
                data-test="button-delivery"
                type="submit"
                suffixIcon={<RightArrow />}
                loadOnRouteChange
                isLoading={CartShippingDetailsLoading}
                disabled={
                  !checkedAddresses?.find(
                    (address) => address.id === selectedAddress?.id
                  )?.isValid
                }
                fullWidth
              >
                <FormattedMessage defaultMessage="Continue to Delivery" />
              </ContinueButton>
              {error && (
                <RequiredSpan style={{ height: "auto" }}>{error}</RequiredSpan>
              )}
            </div>
          </RouteSection>
        </StyledForm>
      )}
    />
  );
};

export default CustomerInformation;

const checkAddressesValidity = (
  addresses: AddressInfoFragment[] | undefined,
  customCheckoutSettings:
    | CustomCheckoutSetting
    | undefined
    | Record<string, never>
) => {
  return addresses?.map((address) => {
    if (customCheckoutSettings?.identifier !== CustomerIdentifier.Email) {
      if (
        customCheckoutSettings?.secondaryPhone ===
          CheckoutFieldOptions.Mandatory &&
        !address?.secondPhone?.trim()
      ) {
        return { ...address, isValid: false };
      }
    }

    if (
      customCheckoutSettings?.postalCode === CheckoutFieldOptions.Mandatory &&
      !address?.postalCode?.trim()
    ) {
      return { ...address, isValid: false };
    }

    return { ...address, isValid: true };
  });
};

const createShippingDestination = (
  selectedAddress: AddressInfoFragment,
  shippingDestinationAvailable: boolean
) => {
  return {
    isValid: shippingDestinationAvailable,
    country: selectedAddress?.country?.id
      ? {
          value: selectedAddress?.country?.id!,
          label: selectedAddress?.country?.name!,
        }
      : null,
    state: selectedAddress?.state?.id
      ? {
          value: selectedAddress?.state?.id!,
          label: selectedAddress?.state?.name!,
        }
      : null,
    city: selectedAddress?.city?.id
      ? {
          value: selectedAddress?.city?.id!,
          label: selectedAddress?.city?.name!,
        }
      : null,
    region: selectedAddress?.region?.id
      ? {
          value: selectedAddress?.region?.id!,
          label: selectedAddress?.region?.name!,
        }
      : null,
  };
};
