import React, { Dispatch, SetStateAction } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Mail, Phone } from "../../../../assets/Icons";
import { LoginMethod } from "../../../types";

interface LoginSwitcherProps {
  activeTab: LoginMethod;
  setActiveTab: Dispatch<SetStateAction<LoginMethod>>;
}

const tabs = [
  {
    name: <FormattedMessage defaultMessage="Email" />,
    value: LoginMethod.EMAIL,
    icon: <Mail />,
  },
  {
    name: <FormattedMessage defaultMessage="Phone" />,
    value: LoginMethod.PHONE,
    icon: <Phone />,
  },
];

const LoginSwitcher = ({ activeTab, setActiveTab }: LoginSwitcherProps) => {
  return (
    <div className="flex bg-gray-50 p-1 border border-solid border-gray-200 rounded-[50px]">
      {tabs.map((tab) => (
        <WithPrimaryColor
          key={tab.value}
          isActive={activeTab === tab.value}
          className={`w-full flex justify-center items-center py-2 px-3 gap-2 text-sm font-semibold rounded-[50px] cursor-pointer ${
            activeTab === tab.value
              ? "bg-white text-gray-800 shadow-sm" // should add [&>svg]:text-primary after dynamic theming
              : "text-gray-500 [&>svg]:text-gray-500"
          }`}
          onClick={() => setActiveTab(tab.value)}
        >
          {tab.icon}
          {tab.name}
        </WithPrimaryColor>
      ))}
    </div>
  );
};

export default LoginSwitcher;

const WithPrimaryColor = styled.div<{ isActive: boolean }>`
  > svg {
    color: ${({ theme, isActive }) => isActive && theme.colors.primary};
  }
`;
