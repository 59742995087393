import { FC, useState } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import { Sorting } from "./Sorting";
import { PrimaryButton } from "../../../../components/Button";
import { Filter as FilterIcon } from "../../../../assets/Icons";
import { Section } from "../../../../shared/layout";
import FilterTags from "./components/FilterTags";
import Filter from "./Filter";
import { FilterAndSortProps } from "../../../types";

const DesktopFilterSort: FC<FilterAndSortProps> = () => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Section background="backgroundReverse">
      <Filter isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <div>
        <ItemsWrapper>
          <RowWrapper>
            <FilterBox>
              <PrimaryButton
                prefixIcon={<FilterIcon />}
                onClick={() => setIsOpen(true)}
              >
                <FormattedMessage defaultMessage="Filter" />
              </PrimaryButton>
              {/* <ProductCounter>Showing 6 products of 40</ProductCounter> */}
            </FilterBox>
            {router.query.query && (
              <>
                <FormattedMessage defaultMessage="Searched for " />{" "}
                {'"' + router.query.query + '"'}
              </>
            )}
            <Sorting />
          </RowWrapper>
          <RowWrapper>
            <FilterTags />
          </RowWrapper>
        </ItemsWrapper>
      </div>
    </Section>
  );
};

export default DesktopFilterSort;

// Styles

const ItemsWrapper = styled.div`
  display: none;
  align-items: center;
  place-content: center;
  padding: 30px 0 0;
  justify-content: space-between;
  margin: auto;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const RowWrapper = styled.div<{
  border?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: ${({ border }) => (border ? ` 1px solid #d8d8d8;` : "unset")};
  @media (min-width: 768px) {
    justify-content: space-between;
    display: flex;
  }
`;

const FilterBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

// TODO: showing number of products
// const ProductCounter = styled(Span)`
//   font-size: 12px;
//   font-weight: bold;
//   line-height: 1.5;
//   color: #969696;
//   ${rtl("margin: 0 16px 0 0 ", "margin: 0 0 0 16px;")};
// `;
