const EnvelopeOutlined = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        d="M27.5 17.917L21.3113 20.5649C20.4738 20.9232 19.5262 20.9232 18.6887 20.5649L12.5 17.917"
        stroke="#475467"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="12.5"
        y="14.167"
        width="15"
        height="12.5"
        rx="4"
        stroke="#475467"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { EnvelopeOutlined };
