import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";
import { Overlay } from "../../../../shared/globals";
import { FilterOutlined, Delete } from "../../../../assets/Icons";
import { useEscapeAndStopScrollingEffect } from "../../../../hooks/useEscapeAndStopScrollingEffect";
import { useStore } from "../../../../lib/storeData";
import {
  ProductAttributeValue,
  ProductCollection,
  ProductOptionValue,
  useFullTextSearchAndFilterQuery,
  useGetFiltersQuery,
} from "../../../../generated/graphql";
import { ApplyFilterSection } from "./components/ApplyFilterSection";
import FiltersSection from "./components/FiltersSection";
import {
  getAvailableFilters,
  getFiltersSelectors,
} from "../../../../shared/utils/filterHelperFunctions";
import { theme } from "../../../../shared/theme";
import Flex from "../../../../shared/globals/UiElements/Flex";
import MotionElementWrapper from "../../../../shared/globals/MotionElementWrapper";
import { useTheme } from "styled-components";

interface FilterProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const Filter = ({ isOpen, onClose }: FilterProps) => {
  const router = useRouter();
  const { storeId, appearance } = useStore();
  const { isRTL } = useTheme();
  const selectedFiltersIds =
    typeof router.query.filters === "string"
      ? [router.query.filters]
      : router.query.filters || [];

  const intl = useIntl();
  const { attributeValueSelectors, collectionIds, optionValueSelectors } =
    getFiltersSelectors(selectedFiltersIds || []);
  const { data, error, loading } = useGetFiltersQuery({
    variables: {
      storeId,
    },
  });

  const [selectedFilters, setSelectedFilters] = useState<Map<string, boolean>>(
    new Map()
  );

  const { data: products } = useFullTextSearchAndFilterQuery({
    variables: {
      storeId,
      connectionSettings: {
        offset: 0,
        first: 24,
      },
      filters: {
        search: router.query.query as string,
        collectionIds,
        attributeValueSelectors,
        optionValueSelectors,
        priceRange: {
          min: parseFloat(router.query.minPrice as string) || undefined,
          max: parseFloat(router.query.maxPrice as string) || undefined,
        },
      },
    },
  });

  useEscapeAndStopScrollingEffect({ isOpen: isOpen!, onClose });

  const { filtersDictionary } = getAvailableFilters({
    attributesProductsDistribution: data?.getApplicableFilters
      ?.attributeValues! as ProductAttributeValue[],
    collectionsProductsDistribution: data?.getApplicableFilters
      ?.collections as ProductCollection[],
    optionsProductsDistribution: data?.getApplicableFilters
      ?.optionValues as ProductOptionValue[],
    intl,
  });

  return (
    <>
      {isOpen && <Overlay onClick={onClose} />}
      <MotionElementWrapper
        className={`max-w-[360px] w-[90vw] fixed flex flex-col top-0 shadow-lg bg-white z-OVERLAY ${
          isRTL ? "right-0" : "left-0"
        }`}
        initial={{ opacity: 0, x: isRTL ? "120%" : "-120%" }}
        animate={
          isOpen
            ? { opacity: 1, x: 0 }
            : { opacity: 0, x: isRTL ? "120%" : "-120%" }
        }
        duration={0.2}
        fullHeight
      >
        <Flex
          justifyContent="space-between"
          className="py-md px-lg border-b border-gray-200"
          fullWidth
          alignItems="center"
        >
          <Flex spacing="s">
            <FilterOutlined />
            <span className="font-semibold text-gray-800">
              <FormattedMessage defaultMessage="Filter" />
            </span>
          </Flex>
          <div onClick={onClose} className="cursor-pointer ">
            <Delete
              style={{
                color: appearance?.colors?.primary || theme.colors.primary,
              }}
            />
          </div>
        </Flex>
        <FiltersSection
          availableFilters={filtersDictionary}
          selectedFiltersIds={selectedFiltersIds}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          loading={loading!}
          error={error}
        />

        <ApplyFilterSection
          onClose={onClose!}
          totalCount={products?.fullTextSearchAndFilter?.totalCount || 0}
          loading={loading}
        />
      </MotionElementWrapper>
    </>
  );
};

export default Filter;
