export const FilterOutlined = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6.76776 4.06524C7.74407 5.04155 7.74407 6.62446 6.76776 7.60077C5.79146 8.57708 4.20854 8.57708 3.23223 7.60077C2.25592 6.62446 2.25592 5.04155 3.23223 4.06524C4.20854 3.08893 5.79146 3.08893 6.76776 4.06524"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.6667 5.83268H7.5"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.7678 12.3982C17.7441 13.3746 17.7441 14.9575 16.7678 15.9338C15.7915 16.9101 14.2085 16.9101 13.2322 15.9338C12.2559 14.9575 12.2559 13.3746 13.2322 12.3982C14.2085 11.4219 15.7915 11.4219 16.7678 12.3982"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.33398 14.1667H12.5007"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
