import React from "react";
import { FormattedMessage } from "react-intl";
import { StoreTemplate } from "../../TemplateLoader";
import type { HomePageProps } from "../../types";
import { SectionTypeEnum } from "../../../generated/graphql";
import { EmptyCartIcon } from "../../../assets/Icons";
import { EmptyState } from "../../../components/UtilityComponents/EmptyState";
import ProductsSectionFallback from "../components/ProductsSectionFallback";
import { formatSections } from "../../../shared/utils/homeHelpers";
import WhileInViewAnimation from "../../../shared/globals/WhileInViewAnimation";

const HomePage: React.FC<HomePageProps> = ({
  appearance,
  homepageSections,
  products,
}) => {
  const Template = StoreTemplate.get();

  const formattedSections = formatSections(homepageSections);

  return (
    <>
      <Template.elements.Hero heroSettings={appearance?.heroSlider} />
      <Template.elements.HomeAbout aboutSettings={appearance?.about} />
      {formattedSections?.length ? (
        formattedSections.map((section, index) => {
          if (
            section.type === SectionTypeEnum.Text ||
            section.type === SectionTypeEnum.Embed
          ) {
            return (
              <WhileInViewAnimation key={section.id}>
                <Template.elements.HomeContentSection section={section} />
              </WhileInViewAnimation>
            );
          }

          return (
            <Template.elements.HomeGallerySection
              key={section.id}
              section={section}
              index={index}
            />
          );
        })
      ) : products?.length ? (
        <ProductsSectionFallback products={products} />
      ) : (
        <EmptyState
          title={
            <FormattedMessage defaultMessage="There are no products added yet" />
          }
          image={<EmptyCartIcon />}
        />
      )}
    </>
  );
};

export default HomePage;
