import { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, Form } from "react-final-form";
import { PrimaryButton } from "../../../../components/Button";
import { Link } from "../../../../lib/i18n";
import InputPhone, {
  PhoneInput,
} from "../../../../shared/globals/UiElements/InputPhone";
import LoginSwitcher from "../../components/Authentication/LoginSwitcher";
import {
  LoginFormProps,
  LoginFormValues,
  LoginMethod,
  TemplateElement,
} from "../../../types";
import Flex from "../../../../shared/globals/UiElements/Flex";
import InputWithIcon from "../../../../shared/globals/UiElements/InputWithIcon";
import { AddUser } from "../../../../assets/Icons";
import { RequiredSpan } from "../../../black-and-white/elements/Contact/styled";

const LoginForm: TemplateElement<LoginFormProps> = ({
  activeTab,
  setActiveTab,
  onSubmit,
  isError,
  setIsError,
  focusOnError,
}) => {
  const intl = useIntl();
  return (
    <Form<LoginFormValues>
      onSubmit={onSubmit}
      validate={(values) => validate(values, activeTab)}
      decorators={[focusOnError]}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Flex column spacing="xl">
            <p className="m-0 text-2xl text-gray-900 font-semibold text-center">
              <FormattedMessage defaultMessage="Sign-in to your account" />
            </p>
            <LoginSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
            <Flex column alignItems="start" fullWidth>
              {activeTab === LoginMethod.EMAIL && (
                <label className=" text-sm font-medium w-full">
                  <FormattedMessage defaultMessage="Email" />
                  <Field name="email">
                    {({ input, meta: { error, touched } }) => (
                      <div className="mt-1.5">
                        <InputWithIcon
                          {...input}
                          type="email"
                          autoComplete="email"
                          className={
                            (error && touched) || isError ? "invalid" : ""
                          }
                          onChange={(event) => {
                            setIsError(false);
                            input.onChange(event);
                          }}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Enter your email",
                          })}
                        />
                        {error && touched && (
                          <RequiredSpan>{error}</RequiredSpan>
                        )}
                      </div>
                    )}
                  </Field>
                </label>
              )}
              {activeTab === LoginMethod.PHONE && (
                <label className=" text-sm font-medium w-full">
                  <FormattedMessage defaultMessage="Phone no." />
                  <Field<PhoneInput> name="phone">
                    {(fieldProps) => (
                      <InputPhone
                        {...fieldProps}
                        className={isError ? "invalid" : ""}
                        onChange={(event: PhoneInput) => {
                          setIsError(false);
                          fieldProps.input.onChange(event);
                        }}
                      />
                    )}
                  </Field>
                </label>
              )}
              <Flex column alignItems="flex-end" fullWidth>
                <label className="w-full  text-sm font-medium">
                  <FormattedMessage defaultMessage="Password" />
                  <Field name="password">
                    {({ input, meta: { error, touched } }) => (
                      <div className="mt-1.5 w-full">
                        <InputWithIcon
                          {...input}
                          width="100%"
                          type="password"
                          autoComplete="current-password"
                          className={
                            (error && touched) || isError ? "invalid" : ""
                          }
                          onChange={(event) => {
                            setIsError(false);
                            input.onChange(event);
                          }}
                          placeholder="••••••••"
                        />
                        {error && touched && (
                          <RequiredSpan>{error}</RequiredSpan>
                        )}
                        {isError && (
                          <RequiredSpan>
                            <FormattedMessage defaultMessage="Invalid credentials" />
                          </RequiredSpan>
                        )}
                      </div>
                    )}
                  </Field>
                </label>
              </Flex>
              <PrimaryButton compact reversed className="!font-normal">
                <Link href="/reset-password">
                  <FormattedMessage defaultMessage="Forgot password?" />
                </Link>
              </PrimaryButton>
            </Flex>

            <PrimaryButton type="submit" fullWidth isLoading={submitting}>
              <FormattedMessage defaultMessage="Login" />
            </PrimaryButton>

            <Flex alignItems="center">
              <Flex
                width="30px"
                height="30px"
                className="bg-black/20 rounded-full"
                alignItems="center"
                justifyContent="center"
              >
                <AddUser />
              </Flex>
              <Flex column spacing="none">
                <span>
                  <FormattedMessage defaultMessage="Don't Have an Account?" />
                </span>
                <PrimaryButton
                  type="button"
                  style={{ justifyContent: "start" }}
                  compact
                  reversed
                >
                  <Link href="/registration">
                    <span className="font-bold">
                      <FormattedMessage defaultMessage="Sign Up with E-mail" />
                    </span>
                  </Link>
                </PrimaryButton>
              </Flex>
            </Flex>
          </Flex>
        </form>
      )}
    />
  );
};

export default LoginForm;

/**
 *
 * Functions
 *
 */

const validate = (values: LoginFormValues, activeTab: LoginMethod) => {
  const errors: Partial<{ [key in keyof LoginFormValues]: ReactElement }> = {};

  if (
    activeTab === LoginMethod.EMAIL &&
    (!values?.email?.trim() ||
      !values?.email?.match(
        /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
      ))
  ) {
    errors.email = (
      <FormattedMessage defaultMessage="Please enter a valid email address" />
    );
  }

  if (activeTab === LoginMethod.PHONE && !values?.phone?.isValid) {
    errors.phone = (
      <FormattedMessage defaultMessage="Please enter a valid phone number" />
    );
  }

  if (!values?.password) {
    errors.password = (
      <FormattedMessage defaultMessage="Please enter your password" />
    );
  }

  return errors;
};
