import React from "react";
import styled from "styled-components";
import {
  ButtonColorsForStatusType,
  getButtonColorsOnType,
  ButtonSizes,
  ButtonTypes,
  ButtonRequiredColorsType,
  OnlyIconButtonSizes,
} from "./ButtonPalette";
import { useStore } from "../../../../lib/storeData";
import { theme } from "../../../../shared/theme";
import { Link } from "../../../../lib/i18n";
import { LinkTarget } from "../../../../lib/i18n/Link";
import Flex from "../../../../shared/globals/UiElements/Flex";
import ButtonSpinner from "../../../../components/Button/ButtonSpinner";
import { theme as tailwindTheme } from "../../../../../tailwind.config";

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  type?: ButtonTypes;
  size?: keyof typeof ButtonSizes;
  px?: number;
  py?: number;
  customDefault?: ButtonRequiredColorsType;
  customHover?: ButtonRequiredColorsType;
  customDisabled?: ButtonRequiredColorsType;
  fontSize?: number;
  to?: string | null;
  target?: LinkTarget;
  borderRadius?: number;
  isDisabled?: boolean;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  onlyIcon?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  htmlType?: "button" | "submit" | "reset";
  loadingSpinnerColor?: string;
  compact?: boolean;
}

const ButtonLayout = ({
  children,
  type = "primary",
  size = "md",
  ...rest
}: ButtonProps) => {
  const { appearance } = useStore();
  const ButtonColorsOnType = getButtonColorsOnType({
    storePrimaryColor: appearance?.colors?.primary || theme.colors.primary,
  });
  return (
    <StyledButton
      size={size}
      buttonTypeColors={ButtonColorsOnType[type]}
      type={rest.htmlType || "button"}
      disabled={rest.isDisabled || rest.isLoading}
      {...rest}
    >
      {rest.isDisabled && type !== "secondary" && (
        <div
          style={{
            position: "absolute",
            top: -1,
            left: -1,
            right: -1,
            bottom: -1,
            borderRadius: rest.borderRadius ?? 9999,
            backgroundColor: "rgba(255,255,255,0.5)",
          }}
        />
      )}
      {rest.isLoading && (
        <ButtonSpinner isCentralized color={rest.loadingSpinnerColor} />
      )}
      <Flex
        spacing={size === "xl" ? "m" : "s"}
        alignItems="center"
        justifyContent="center"
        className={`${rest.isLoading ? "invisible" : ""}`}
      >
        {rest.prefixIcon && rest.prefixIcon}
        {children}
        {rest.suffixIcon && rest.suffixIcon}
      </Flex>
    </StyledButton>
  );
};

const Button = ({
  children,
  type = "primary",
  size = "md",
  ...rest
}: ButtonProps) => {
  if (rest?.to) {
    return (
      <StyledLink
        href={rest.to}
        absoluteHref={rest.to?.includes("http") ? rest.to : undefined}
        target={rest.target || "_self"}
        borderRadius={rest.borderRadius}
        fullWidth={rest.fullWidth}
      >
        <ButtonLayout size={size} type={type} {...rest}>
          {children}
        </ButtonLayout>
      </StyledLink>
    );
  }
  return (
    <ButtonLayout size={size} type={type} {...rest}>
      {children}
    </ButtonLayout>
  );
};

export default Button;

const StyledButton = styled.button<{
  buttonTypeColors: ButtonColorsForStatusType;
  customDefault?: ButtonRequiredColorsType;
  customHover?: ButtonRequiredColorsType;
  customDisabled?: ButtonRequiredColorsType;
  fontSize?: number;
  size: keyof typeof ButtonSizes;
  px?: number;
  py?: number;
  borderRadius?: number;
  onlyIcon?: boolean;
  fullWidth?: boolean;
  compact?: boolean;
}>`
  border: 1px solid;

  background-color: ${({ buttonTypeColors, customDefault }) =>
    customDefault?.backgroundColor || buttonTypeColors.default.backgroundColor};
  color: ${({ buttonTypeColors, customDefault }) =>
    customDefault?.color || buttonTypeColors.default.color};
  border-color: ${({ buttonTypeColors, customDefault }) =>
    customDefault?.borderColor || buttonTypeColors.default.borderColor};
  &:hover {
    background-color: ${({ buttonTypeColors, customHover }) =>
      customHover?.backgroundColor || buttonTypeColors.hover.backgroundColor};
    color: ${({ buttonTypeColors, customHover }) =>
      customHover?.color || buttonTypeColors.hover.color};
    border-color: ${({ buttonTypeColors, customHover }) =>
      customHover?.borderColor || buttonTypeColors.hover.borderColor};
  }
  &:disabled {
    background-color: ${({ buttonTypeColors, customDisabled }) =>
      customDisabled?.backgroundColor ||
      buttonTypeColors.disabled.backgroundColor};
    color: ${({ buttonTypeColors, customDisabled }) =>
      customDisabled?.color || buttonTypeColors.disabled.color};
    border-color: ${({ buttonTypeColors, customDisabled }) =>
      customDisabled?.borderColor || buttonTypeColors.disabled.borderColor};
    cursor: not-allowed;
  }

  padding: ${({ size, py, onlyIcon }) =>
      py
        ? py
        : onlyIcon
        ? OnlyIconButtonSizes[size].py
        : ButtonSizes[size].py}px
    ${({ size, px, onlyIcon }) =>
      px
        ? px
        : onlyIcon
        ? OnlyIconButtonSizes[size].px
        : ButtonSizes[size].px}px;

  padding: ${({ compact }) => (compact ? "0" : "")};
  border-radius: ${({ borderRadius }) =>
    borderRadius !== undefined ? borderRadius : 9999}px;

  width: ${({ fullWidth }) => (fullWidth ? "100%" : "fit-content")};

  font-weight: 600;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 16)}px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  box-shadow: ${tailwindTheme.extend.boxShadow.xs};
  position: relative;
`;

const StyledLink = styled(Link)<{
  borderRadius?: number;
  fullWidth?: boolean;
}>`
  border-radius: ${({ borderRadius }) => borderRadius || 9999}px;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "fit-content")};
`;
