import {
  ApolloClient,
  ApolloQueryResult,
  NormalizedCacheObject,
} from "@apollo/client";
import {
  CurrencyCode,
  StoreDetailsByDomainDocument,
  StoreDetailsByDomainQuery,
  StoreDetailsByDomainQueryVariables,
  StoreDetailsByHandleDocument,
  StoreDetailsByHandleQuery,
  StoreDetailsByHandleQueryVariables,
} from "../../generated/graphql";
import { AddBugsnagStoreMetaData } from "../bugsnag";
import { LanguageType } from "../i18n/locales-data";
import { reduceStoreRedirectsArray } from "../i18n/storeRedirectsFunctions";
import { StoreContextType } from "./StoreContext";
import { checkSubscriptionActive } from "../../shared/utils/check-subscription-active";
import NodeCache from "node-cache";
export * from "./StoreContext";

const BASE_WUILT_STORE_DOMAIN = "wuiltstore.com";
export function getHostnameFromRequest(req) {
  if (!req) return null;
  return req.headers["X-Forwarded-Host"] || req.headers.host;
}

const isServer = typeof window === "undefined";

// @ts-ignore
const windowStoreData = !isServer && window.__NEXT_DATA__.storeData;

export async function getStoreData(
  cache?: NodeCache,
  apolloClient: ApolloClient<NormalizedCacheObject> | undefined = undefined,
  hostname: string | undefined = undefined,
  language: LanguageType | undefined = undefined
): Promise<StoreContextType> {
  if (windowStoreData) {
    AddBugsnagStoreMetaData(windowStoreData);
    return windowStoreData;
  }
  if (!hostname) {
    throw new Error("getStoreData: hostname is required in server");
  }
  if (!apolloClient) {
    throw new Error("getStoreData: apolloClient is required");
  }

  const cachedStoreData = cache?.get(
    `${hostname}/${language}`
  ) as StoreContextType;
  if (cachedStoreData) {
    AddBugsnagStoreMetaData(cachedStoreData);
    return cachedStoreData;
  }

  const isSubDomain = hostname.indexOf(BASE_WUILT_STORE_DOMAIN) !== -1;
  let query: ApolloQueryResult<StoreDetailsByDomainQuery>;
  if (isSubDomain) {
    const handle = hostname.split(".")[0];
    query = await apolloClient.query<
      StoreDetailsByHandleQuery,
      StoreDetailsByHandleQueryVariables
    >({
      query: StoreDetailsByHandleDocument,
      errorPolicy: "ignore",
      variables: { storeHandle: handle },
    });
  } else {
    query = await apolloClient.query<
      StoreDetailsByDomainQuery,
      StoreDetailsByDomainQueryVariables
    >({
      query: StoreDetailsByDomainDocument,
      errorPolicy: "ignore",
      variables: { storeDomain: hostname },
    });
  }

  const { data, error } = query;
  if (error) {
    throw error;
  }

  if (!data.store) {
    const error: any = new Error("Store not found from store data");
    error.code = 9004;
    throw error;
  }
  const reducedRedirects = reduceStoreRedirectsArray(data.store.redirects)!;

  if (
    !checkSubscriptionActive(data.store.subscription) &&
    data.store.isExpired
  ) {
    const error: any = new Error("Store is expired");
    error.code = 9009;
    throw error;
  }

  const storeContextValue = {
    ...data.store,
    currency: data.store.currency as CurrencyCode,
    storeId: data.store.id,
    locales: data.store.supportedLocales as LanguageType[],
    defaultLocale: data.store?.fallbackLocale as LanguageType,
    hostname,
    storeUrl: "https://" + hostname + "/" + data.store?.fallbackLocale,
    template: data?.store?.appearance?.template?.handle || "default",
    automaticDiscounts: data?.store?.automaticDiscounts || [],
    redirects: reducedRedirects,
    customCheckoutSetting: data?.store?.customCheckoutSetting || {},
  };

  cache?.set(`${hostname}/${language}`, storeContextValue);
  AddBugsnagStoreMetaData(storeContextValue);
  return storeContextValue;
}
