import React from "react";
import styled from "styled-components";
import { H1, P } from "../../../../shared/globals";
import { Section } from "../../../../shared/layout";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "../../../../components/Button";
import { C404V2 } from "../../../../assets/Icons";
import { EmptyState } from "../../../../components/UtilityComponents/EmptyState";

export const SvgWrapper = styled.div<{
  svgWidth?: string;
}>`
  svg {
    width: 280px;
    height: auto;
  }
  @media (min-width: 768px) {
    svg {
      width: ${(props) => props.svgWidth || "280px"};
    }
  }
`;

export const StyledHead = styled(H1)`
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
`;

export default function PageNotFoundError() {
  return (
    <Section>
      <EmptyState
        descriptionFullWidth
        image={
          <SvgWrapper>
            <C404V2 />
          </SvgWrapper>
        }
        title={<FormattedMessage defaultMessage="Page not found!" />}
        description={
          <P
            style={{
              opacity: "0.5",
            }}
          >
            <FormattedMessage defaultMessage="Sorry we’re unable to find the page you’re looking for." />
          </P>
        }
        button={
          <PrimaryButton
            href="/shop"
            style={{
              minWidth: "300px",
            }}
          >
            <FormattedMessage defaultMessage="Go back to Shop" />
          </PrimaryButton>
        }
      />
    </Section>
  );
}
