import { createAction } from "@reduxjs/toolkit";
import type {
  CheckoutCoupon,
  CheckoutInformation,
  CheckoutShipping,
  CheckoutReceipt,
  CartItemType,
  CartItemInput,
  CheckoutStatusEnum,
  ResetCartStatus,
} from "../types";

/**
 * CART ACTIONS
 */

export const toggleSideCart = createAction<void, "TOGGLE_SIDE_CART">(
  "TOGGLE_SIDE_CART"
);

export const setCartUpdating = createAction<boolean, "SET_CART_UPDATING">(
  "SET_CART_UPDATING"
);

export const closeSideCart = createAction<void, "CLOSE_SIDE_CART">(
  "CLOSE_SIDE_CART"
);

export const addCustomItemToCart = createAction<
  { newItem: CartItemInput; status: CheckoutStatusEnum },
  "ADD_CUSTOM_ITEM_TO_CART"
>("ADD_CUSTOM_ITEM_TO_CART");

export const resetCart = createAction<ResetCartStatus, "RESET_STORE_STATES">(
  "RESET_STORE_STATES"
);

export const overwriteCartItems = createAction<
  { items: CartItemType[]; status: CheckoutStatusEnum },
  "OVERWRITE_CART_ITEMS"
>("OVERWRITE_CART_ITEMS");

/**
 * CHECKOUT ACTIONS
 */

export const updateInformationAction = createAction<
  { information: CheckoutInformation },
  "UPDATE_INFORMATION"
>("UPDATE_INFORMATION");

export const updateShippingAction = createAction<
  { shippingInfo: CheckoutShipping },
  "UPDATE_SHIPPING"
>("UPDATE_SHIPPING");

export const updateCouponAction = createAction<
  { coupon: CheckoutCoupon },
  "UPDATE_COUPON"
>("UPDATE_COUPON");

export const updateItemsAction = createAction<
  { items: CartItemType[] },
  "UPDATE_ITEMS"
>("UPDATE_ITEMS");

export const updateBuyNowItemAction = createAction<
  { items: CartItemType[] },
  "UPDATE_BUY_NOW_ITEM"
>("UPDATE_BUY_NOW_ITEM");

export const updateReceiptAction = createAction<
  { receipt: Partial<CheckoutReceipt> },
  "UPDATE_RECEIPT"
>("UPDATE_RECEIPT");

export const updateCheckoutStatusAction = createAction<
  CheckoutStatusEnum,
  "CHANGE_CHECKOUT_STATUS"
>("CHANGE_CHECKOUT_STATUS");
