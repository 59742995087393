import styled from "styled-components";
import { FlexCol, FlexRow } from "../../shared/globals";
import { rtl } from "../../shared/styles-utils";
import { ZIndex } from "../../shared/globals/types";

export const HideOnMobile = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: inherit;
  }
`;

export const Container = styled(FlexCol)`
  min-height: 100vh;
`;

export const Header = styled(FlexRow)`
  width: 100%;
  border: solid 1px #eaeaea;
  z-index: ${ZIndex["DROPDOWN"]};
  padding: 10px ${rtl("20px", "0")} 10px ${rtl("0", "20px")};
  box-shadow: inset 0 -1px 0 0 #eeeeee;

  @media (min-width: 768px) {
    box-shadow: 0 2px 4px 0 rgba(175, 175, 175, 0.2);
    padding: 10px ${rtl("165px", "0")} 10px ${rtl("0", "165px")};
  }
`;
