import React, { FC } from "react";
import { PageHeaderProps } from "../../types";

const PageHeader: FC<PageHeaderProps> = ({ pageTitle }) => {
  return (
    <div className="w-full py-2xl text-center bg-gray-100 text-2xl text-gray-800 font-semibold">
      {pageTitle}
    </div>
  );
};

export default PageHeader;
