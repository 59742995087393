import styled from "styled-components";
import { useIntl } from "react-intl";
import { useStore } from "../../lib/storeData";
import { Money, CurrencyCode } from "../../generated/graphql";
import { roundNumber } from "../../contexts/CartContext/checkoutUtils";

export interface PriceProps {
  money: Money | null | undefined;
  className?: string;
  style?: React.CSSProperties;
}

const noOfDigits = {
  [CurrencyCode.Kwd]: 3, // Kuwaiti dinar
  [CurrencyCode.Jod]: 3, // Jordanian dinar
  [CurrencyCode.Bhd]: 3, // Bahraini dinar
  [CurrencyCode.Iqd]: 3, // Iraqi dinar
  [CurrencyCode.Omr]: 3, // Omani rial
};

export const PriceBase = ({
  money: inputMoney,
  className = "",
  style,
}: PriceProps) => {
  const { currency: currencyCode } = useStore();

  const money = inputMoney || { amount: 0, currencyCode };
  const hasDecimal = money.amount % 1 !== 0;

  const intl = useIntl();

  // handle currencies with 3 digits
  const requiredDecimalPlaces = noOfDigits[currencyCode] || 2;

  const roundedPrice = roundNumber(money.amount, requiredDecimalPlaces);

  const price = intl.formatNumber(roundedPrice, {
    style: "currency",
    currency: money.currencyCode,
    minimumFractionDigits: hasDecimal ? requiredDecimalPlaces : 0,
    maximumFractionDigits: hasDecimal ? requiredDecimalPlaces : 0,
  });

  const revertedPrice = price
    .split(/\s|&nbsp;/g)
    .reverse()
    .join("\u00a0");

  return (
    <span
      data-test="text-price"
      style={{ ...style }}
      className={`${className}`}
    >
      {intl.locale === "ar-EG" || intl.locale === "fr-FR" // Check the intl formatter to display the price in Arabic or French
        ? price
        : revertedPrice}
    </span>
  );
};

export const Price = styled(PriceBase)<{
  color?: string;
  fontWeight?: number;
  fontSize?: string;
  alignSelf?: string;
}>`
  font-size: ${({ fontSize }) => fontSize ?? "16px"};
  font-weight: ${({ fontWeight }) => fontWeight ?? 600};
  color: ${({ theme, color }) => color ?? theme.text.default};
  align-self: flex-start;
  @media (min-width: 768px) {
    align-self: ${({ alignSelf }) => alignSelf ?? "center"};
  }
`;

export const DefaultTextPrice = styled(PriceBase)<{
  color?: string;
}>`
  color: ${({ theme, color }) => color ?? theme.text.default};
`;
