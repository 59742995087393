import { FC, useEffect, useState } from "react";
import { RendererComponent } from "./types";
import { CouponProps, FormValues } from "../templates/types";
import { useCartData } from "../lib/cartData/useCartData";

import { isCouponError } from "../shared/utils/error-utils";

interface CouponRendererProps extends RendererComponent<CouponProps> {}

export enum CouponCodeErrors {
  InvalidCouponError = "INVALID_PROMO_CODE",
  MinimumRequirementIsNotMet = "MINIMUM_REQUIREMENT_IS_NOT_MET",
}
const CouponRenderer: FC<CouponRendererProps> = ({ Component }) => {
  const {
    applyPromoCode: { applyPromoCode, loading, error: applyPromoCodeError },
    removePromoCode: { removePromoCode, loading: removePromoCodeLoading },
    cart,
  } = useCartData();
  const cartData = cart.recoveryCart ?? cart;
  const [error, setError] = useState<any>();
  const couponErrorFound = isCouponError(cartData.cartErrors);
  const handelRemovePromoCode = async () => {
    await removePromoCode();
  };
  useEffect(() => {
    if (couponErrorFound) {
      handelRemovePromoCode();
    }
    if (applyPromoCodeError) {
      setError(applyPromoCodeError);
    }
  }, [applyPromoCodeError]);
  const onSubmit = async (values: FormValues) => {
    if (!values.coupon) return;
    try {
      await applyPromoCode(
        {
          code: values.coupon,
        },

        (data) => {
          if (
            cartData.receipt?.subtotal?.amount! >
            data?.customerActions?.cart?.applyPromoCode?.promoCode
              ?.minimumOrderAmountToApply!
          ) {
            setError(undefined);
          } else {
            setError({
              message: "MINIMUM_REQUIREMENT_IS_NOT_MET",
              minimumAmount:
                data?.customerActions?.cart?.applyPromoCode?.promoCode
                  ?.minimumOrderAmountToApply!,
            });
          }
        }
      );
    } catch (error) {
      setError(error);
    }
  };

  const onRemovePromoCode = async () => {
    await removePromoCode(() => {
      setError(undefined);
    });
  };

  return (
    <Component
      loading={loading}
      error={error}
      removePromoCodeLoading={removePromoCodeLoading}
      onSubmit={onSubmit}
      onRemovePromoCode={onRemovePromoCode}
    />
  );
};

export default CouponRenderer;
