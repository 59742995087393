import { gql } from "@apollo/client";

export type AuthState = {
  token?: string | null;
  id?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
};

export const typeDefs = gql`
  extend type User {
    isLoggedIn: Boolean!
    token: String
    id: String
    name: String
    email: String!
    phone: String
  }

  extend type Query {
    user: User!
  }
`;

export const USER = gql`
  query User {
    user @client
  }
`;
