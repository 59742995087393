import { useRouter } from "../../../../../lib/i18n";
import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../Button/Button";

interface ClearAllFiltersProps {
  loading: boolean;
  className?: string;
}

const ClearAllFilters = ({ loading, className }: ClearAllFiltersProps) => {
  const router = useRouter();
  return (
    <Button
      className={className}
      fullWidth
      isDisabled={
        loading ||
        Object.keys(router.query).length === 0 ||
        router.pathname.includes("collection")
      }
      onClick={() => {
        if (!router.pathname.includes("collection")) {
          if (router.pathname.includes("search")) {
            router.push({
              pathname: router.pathname.split("?")[0],
              query: {
                query: router.query.query ? router.query.query : undefined,
              },
            });
          } else {
            router.push({
              pathname: router.pathname.split("?")[0],
            });
          }
        }
      }}
      type="secondary"
    >
      <FormattedMessage defaultMessage="Clear all" />
    </Button>
  );
};

export default ClearAllFilters;
