import React from "react";

export const ReviewButtonIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M18.9997 10.0003V5.00033C18.9997 3.15938 17.5073 1.66699 15.6663 1.66699H5.66634C3.82539 1.66699 2.33301 3.15938 2.33301 5.00033V10.8337C2.33301 12.6746 3.82539 14.167 5.66634 14.167H7.33301"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.7365 17.5L18.9863 15.5004C19.0974 14.6111 18.4853 13.7931 17.6007 13.6488L15.666 13.3333V11.25C15.666 10.5596 15.1063 10 14.416 10V10C13.7256 10 13.166 10.5596 13.166 11.25V14.9998L12.6781 14.5119C12.457 14.2908 12.1573 14.1667 11.8447 14.1667C11.5321 14.1667 11.2323 14.2908 11.0112 14.5119V14.5119C10.614 14.9091 10.5523 15.5316 10.8639 15.999L11.8645 17.5"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.92883 5.40652C8.05166 5.1576 8.30516 5 8.58273 5C8.86031 5 9.11381 5.1576 9.23663 5.40652L9.60762 6.15859C9.71381 6.37384 9.91913 6.52305 10.1566 6.55757L10.9865 6.6782C11.2611 6.71814 11.4893 6.91046 11.575 7.17435C11.6608 7.43824 11.5894 7.72794 11.3908 7.92171L10.79 8.50767C10.6183 8.67519 10.5399 8.91646 10.5805 9.15292L10.7222 9.97927C10.7691 10.2528 10.6567 10.5292 10.4322 10.6924C10.2077 10.8555 9.91005 10.877 9.66439 10.748L8.92195 10.3577C8.70958 10.2461 8.45589 10.2461 8.24352 10.3577L7.50107 10.748C7.25541 10.877 6.95777 10.8555 6.73327 10.6924C6.50877 10.5292 6.39633 10.2528 6.44322 9.97927L6.58498 9.15292C6.62554 8.91646 6.54719 8.67519 6.37545 8.50767L5.77469 7.92171C5.57607 7.72794 5.50465 7.43824 5.59043 7.17435C5.67621 6.91046 5.90433 6.71814 6.17892 6.6782L7.00882 6.55757C7.24634 6.52305 7.45166 6.37384 7.55784 6.15859L7.92883 5.40652Z"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
