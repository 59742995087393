import { FormattedMessage } from "react-intl";
import {
  MyOrdersIcon,
  MyReviewsFilledIcon,
  MyAccountIcon,
  MyAddressesIcon,
  LogoutIcon,
} from "../../../../../assets/Icons";

export enum mePageItemsEnum {
  MyOrders = "my-orders",
  MyReviews = "my-reviews",
  MyAccount = "my-account",
  MyAddresses = "my-addresses",
  LogOut = "log-out",
}

export const mePageItems = [
  {
    id: mePageItemsEnum.MyOrders,
    icon: <MyOrdersIcon />,
    title: <FormattedMessage defaultMessage="My Orders" />,
    slug: mePageItemsEnum.MyOrders,
  },
  {
    id: mePageItemsEnum.MyReviews,
    icon: <MyReviewsFilledIcon />,
    title: <FormattedMessage defaultMessage="My Reviews" />,
    slug: mePageItemsEnum.MyReviews,
  },
  {
    id: mePageItemsEnum.MyAccount,
    icon: <MyAccountIcon />,
    title: <FormattedMessage defaultMessage="My Account" />,
    slug: mePageItemsEnum.MyAccount,
  },
  {
    id: mePageItemsEnum.MyAddresses,
    icon: <MyAddressesIcon />,
    title: <FormattedMessage defaultMessage="My Addresses" />,
    slug: mePageItemsEnum.MyAddresses,
  },
  {
    id: mePageItemsEnum.LogOut,
    icon: <LogoutIcon />,
    title: <FormattedMessage defaultMessage="Log Out" />,
  },
];

export const filterMePageItems = (areReviewsActivated: boolean) =>
  mePageItems.filter((item) =>
    item.id === mePageItemsEnum.MyReviews ? areReviewsActivated : true
  );
