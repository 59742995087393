import * as React from "react";
import { Link } from "../../lib/i18n";

interface PageLinkProps {
  handle: string;
  children?: React.ReactNode;
}

export const PageLink: React.FC<PageLinkProps> = ({ handle, children }) => {
  return <Link href={`/page/${handle}`}>{children}</Link>;
};
