import React from "react";
import { FormattedMessage } from "react-intl";
import { TwoDashTrashIcon } from "../../../../../assets/Icons";
import { useAuth } from "../../../../../lib/Authentication";
import {
  ListCustomerAddressesDocument,
  useDeleteCustomerAddressMutation,
} from "../../../../../generated/graphql";
import { ModalConfirm } from "../../../../../shared/globals/UiElements/ModalConfirm";
import { useStore } from "../../../../../lib/storeData";
import Button from "../../Button/Button";

interface DeleteAddressProps {
  addressId: string;
  children?: React.ReactNode;
}

const DeleteAddress: React.FC<DeleteAddressProps> = ({ addressId }) => {
  const { storeId } = useStore();
  const { user } = useAuth();
  const [deleteCustomerAddress, { loading }] = useDeleteCustomerAddressMutation(
    {
      refetchQueries: [
        {
          query: ListCustomerAddressesDocument,
          variables: { customerId: user?.id, storeId },
        },
      ],
    }
  );

  const onDelete = async (userId: string, addressId: string) => {
    await deleteCustomerAddress({
      variables: { customerId: userId, addressId: addressId, storeId },
    });
  };
  return (
    <ModalConfirm
      modalBody={
        <FormattedMessage defaultMessage="Are you sure you want to delete this address" />
      }
      modalHeader={<FormattedMessage defaultMessage="Delete Address" />}
      loading={loading}
      onConfirm={() => onDelete(user?.id!, addressId)}
    >
      <Button
        px={8}
        py={8}
        className="h-fit"
        onlyIcon
        type="secondary"
        prefixIcon={<TwoDashTrashIcon />}
      />
    </ModalConfirm>
  );
};

export default DeleteAddress;
