import { FormattedMessage } from "react-intl";
import { H1, H2 } from "../../../shared/globals";
import Modal from "../../../shared/globals/UiElements/Modal";
import Flex from "../../../shared/globals/UiElements/Flex";
import { Delete, ShoppingIcon } from "../../../assets/Icons";
import { useRouter } from "next/router";
import { useCartData } from "../../../lib/cartData/useCartData";

const CartEmptyPopup = () => {
  const router = useRouter();
  const { cart, setCartEmptyPopUpState } = useCartData();

  const handleShopping = async () => {
    router.push("/");
    setCartEmptyPopUpState(false);
  };

  return (
    <Modal
      modalWidth="small"
      closeIcon={
        <div className="text-gray-500 p-xs">
          <Delete />
        </div>
      }
      show={cart.isCartEmptyPopUpOpen || false}
      className="!rounded-xl"
      onClose={() => handleShopping()}
    >
      <Modal.Header boxShadow="none">
        <ShoppingIcon />
      </Modal.Header>
      <Modal.Body padding="0 24px 32px">
        <Flex column spacing="s">
          <H1 className="!text-lg !font-semibold">
            <FormattedMessage defaultMessage="Looks like your cart is empty!" />
          </H1>
          <H2 className="!text-sm !font-normal">
            <FormattedMessage
              defaultMessage="The recovery link you used might be for a cart that's already
            empty. Don't worry! You can head back to the home page and
            start filling a new cart."
            />

            <span
              onClick={handleShopping}
              className="!font-semibold block !text-sm text-warning-600 cursor-pointer"
            >
              <FormattedMessage defaultMessage="Click here" />
            </span>
          </H2>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default CartEmptyPopup;
