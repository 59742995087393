import styled from "styled-components";
import { H2, H4 } from "../../shared/globals/";
import { ReactNode } from "react";
import Flex from "../../shared/globals/UiElements/Flex";

interface EmptyStateProps {
  title?: string | ReactNode;
  description?: string | ReactNode;
  descriptionFullWidth?: boolean;
  image?: ReactNode;
  button?: ReactNode;
  padding?: string;
  maxWidth?: string;
}

const StyledCard = styled(Flex)<{
  padding?: string;
}>`
  text-align: center;
  padding: ${({ padding }) => padding || "60px 0 100px"};
`;

export const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  description,
  descriptionFullWidth,
  image,
  button,
  padding,
  maxWidth = "60%",
}) => {
  return (
    <StyledCard
      column
      padding={padding}
      alignItems="center"
      justifyContent="center"
      spacing="xxl"
    >
      {image}
      <Flex spacing="xs" column justifyContent="center" alignItems="center">
        <H2>{title}</H2>
        <H4
          color="secondary"
          style={{ maxWidth: descriptionFullWidth ? "100%" : maxWidth }}
        >
          {description}
        </H4>
      </Flex>
      {button}
    </StyledCard>
  );
};
