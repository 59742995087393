import { FormattedMessage } from "react-intl";
import { StyledH5, StyledTable, StyledTH } from "../components/Cart/styled";
import useBreakPoints from "../../../shared/utils/useBreakPoints";
import { FlexCol } from "../../../shared/globals";
import { CartTableProps } from "../../types";

const CartTable = ({ renderCartItems }: CartTableProps) => {
  const { isTablet } = useBreakPoints();

  return (
    <>
      {isTablet ? (
        <FlexCol fullWidth>{renderCartItems!()}</FlexCol>
      ) : (
        <StyledTable>
          <thead>
            <tr>
              <StyledTH>
                <StyledH5>
                  <FormattedMessage defaultMessage="Products" />
                </StyledH5>
              </StyledTH>
              <StyledTH>
                <StyledH5>
                  <FormattedMessage defaultMessage="Price" />
                </StyledH5>
              </StyledTH>
              <StyledTH>
                <StyledH5>
                  <FormattedMessage defaultMessage="Quantity" />
                </StyledH5>
              </StyledTH>
              <StyledTH>
                <StyledH5>
                  <FormattedMessage defaultMessage="Subtotal" />
                </StyledH5>
              </StyledTH>
              <StyledTH></StyledTH>
            </tr>
          </thead>
          <tbody>{renderCartItems!()}</tbody>
        </StyledTable>
      )}
    </>
  );
};

export default CartTable;
