const DebitCard = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.375 18.9583C24.375 20.1543 23.4049 21.125 22.2083 21.125H3.79167C2.59512 21.125 1.625 20.1543 1.625 18.9583V7.04167C1.625 5.84458 2.59512 4.875 3.79167 4.875H22.2083C23.4049 4.875 24.375 5.84458 24.375 7.04167V18.9583Z"
      fill="#BAC7D5"
    />
    <path
      d="M8.66667 11.375C8.66667 11.6746 8.42454 11.9167 8.125 11.9167H5.41667C5.11713 11.9167 4.875 11.6746 4.875 11.375V9.20835C4.875 8.90881 5.11713 8.66669 5.41667 8.66669H8.125C8.42454 8.66669 8.66667 8.90881 8.66667 9.20835V11.375ZM8.125 14.0834H4.875V15.1667H8.125V14.0834ZM12.4583 16.7917H4.875V17.875H12.4583V16.7917ZM12.4583 14.0834H9.20833V15.1667H12.4583V14.0834ZM16.7917 14.0834H13.5417V15.1667H16.7917V14.0834ZM21.125 14.0834H17.875V15.1667H21.125V14.0834Z"
      fill="white"
    />
    <path d="M10.833 8.66669H21.1247V10.8334H10.833V8.66669Z" fill="white" />
  </svg>
);

export { DebitCard };
