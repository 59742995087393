import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { EmptyCartIcon } from "../../../assets/Icons";
import { Pagination } from "../../../components/Pagination/Pagination";
import { EmptyState } from "../../../components/UtilityComponents/EmptyState";
import { Section } from "../../../shared/layout";
import { StoreTemplate } from "../../TemplateLoader";
import type { CollectionPageProps } from "../../types";
import LoadingSpinner from "../../../shared/globals/UiElements/LoadingSpinner";

const Collection: React.FC<CollectionPageProps> = ({
  collection,
  itemsPerPage,
  offset,
  loading,
}) => {
  const Template = StoreTemplate.get();
  const router = useRouter();
  router.query.filters = "c:" + collection?.id;
  return (
    <>
      <Template.elements.PageHeader pageTitle={collection?.title} />
      <Template.elements.FilterAndSort />
      <Section background="backgroundReverse">
        {loading ? (
          <LoadingSpinner />
        ) : !collection?.products?.nodes?.length ? (
          <EmptyState
            title={
              <FormattedMessage defaultMessage="There are no products added yet" />
            }
            image={<EmptyCartIcon />}
          />
        ) : (
          <>
            <Template.elements.ProductsList
              products={collection?.products?.nodes}
            />
            <Pagination
              offset={offset}
              itemPerPage={itemsPerPage}
              totalCount={collection?.products?.totalCount!}
              rounded
            />
          </>
        )}
      </Section>
    </>
  );
};

export default Collection;
