import styled from "styled-components";
import { H3, FlexRow, Span, H5, FlexCol } from "../../../../shared/globals";
import { rtl } from "../../../../shared/styles-utils";
import { DiscountPrice } from "../../../../components/Price";

export const DeleteButton = styled.button<{ disabled?: boolean }>`
  background-color: #cc09050d;
  padding: 4px;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

export const StyledDiscount = styled(DiscountPrice)`
  align-self: flex-start;
  font-weight: 400 !important;
  padding: 0;
  opacity: 0.5;
`;

export const Title = styled(H3)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  word-break: break-word;
`;

export const StyledNumber = styled(H5)`
  color: ${({ theme }) => theme.text.secondary}b3;
  line-height: unset;
`;

export const StyledNonDiscountedVariants = styled(FlexRow)`
  margin: ${rtl("0 84px 0 -70px", "0 -70px 0 84px")};
  border-top: solid 1px ${({ theme }) => theme.bg.wash}1a;

  @media (max-width: 768px) {
    margin: 0;
    padding-top: 6px;
    min-width: 165px;
  }
`;

export const SelectedOptions = styled(Span)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme: { text } }) => text.inkLight};
  text-transform: capitalize;
`;

export const ItemInfo = styled(FlexCol)`
  width: 100%;
  ${rtl("margin-right", "margin-left")}: 10px;
`;
