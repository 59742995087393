import React from "react";
import styled from "styled-components";
import { useAuth } from "../../../../lib/Authentication";
import { FlexRow, H3, H5 } from "../../../../shared/globals";
import { themeColor } from "../../../../shared/styles-utils";
import { Link, useRouter } from "../../../../lib/i18n";
import { filterMePageItems, mePageItemsEnum } from "./consts";
import { useStore } from "../../../../lib/storeData";
import { ProfileLayoutProps } from "../../../types";
import { LogoutSimpleIcon } from "../../../../assets/Icons";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "../../../../components/Button";

const ProfileLayout: React.FC<ProfileLayoutProps> = ({ children }) => {
  const router = useRouter();
  const { user, logout } = useAuth();
  const { areReviewsActivated } = useStore();

  return (
    <Container alignItems="flex-start">
      <NavigateList>
        <FlexRow justifyContent="space-between" spacing="m">
          <div>
            <H3 fontWeight="bold">{user?.name}</H3>
            <StyledH5>{user?.email}</StyledH5>
          </div>
          <LogoutButton onClick={logout}>
            <LogoutSimpleIcon />
            <FormattedMessage defaultMessage="Log Out" />
          </LogoutButton>
        </FlexRow>
        <TabsContainer>
          {filterMePageItems(areReviewsActivated).map(
            (item) =>
              item.id !== mePageItemsEnum.LogOut && (
                <TabButton
                  key={item.id}
                  href={`/me/${item.slug}/`}
                  isActive={router.route.includes(item.slug!)}
                >
                  {item.title}
                </TabButton>
              )
          )}
        </TabsContainer>
      </NavigateList>
      <Content>{children}</Content>
    </Container>
  );
};

export default ProfileLayout;

/**
 *
 * Styles
 *
 */

const NavigateList = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  width: 100%;
`;

const Container = styled(FlexRow)`
  padding-top: 30px;
  flex-direction: column;
  gap: 30px;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  border-bottom: 2px solid #e5e9eb;
  padding-top: 40px;
  width: 100%;
`;

const TabButton = styled(Link)<{ isActive: boolean }>`
  border: none;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  background: transparent;
  font-weight: ${({ isActive }) => (isActive ? "700" : "400")};
  border-bottom: 3px solid
    ${({ isActive }) => (isActive ? themeColor("primary") : "transparent")};
  color: ${({ isActive, theme }) =>
    isActive ? themeColor("primary") : theme.bg.gray};
  padding: 0 20px 20px;
  margin-bottom: -1.5px;
`;

const LogoutButton = styled(PrimaryButton)`
  border: none;
  letter-spacing: -0.1px;
  color: ${({ theme }) => theme.danger.default};
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const StyledH5 = styled(H5)`
  color: ${({ theme }) => theme.text.secondary};
`;
