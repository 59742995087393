export const MyReviewsOutlinedIcon = () => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2473 2.75C13.6303 2.75 13.0633 3.10298 12.7903 3.65625L12.345 4.55859C12.3264 4.59615 12.2927 4.62092 12.2512 4.62695L11.2551 4.77148C10.6448 4.86025 10.1333 5.29027 9.94263 5.87695C9.75204 6.46346 9.91136 7.11226 10.3528 7.54297H10.3548L11.0755 8.24609C11.1054 8.27525 11.1177 8.31608 11.1106 8.35742L10.9407 9.34766C10.8364 9.95564 11.088 10.5749 11.5872 10.9375C12.0863 11.3002 12.7522 11.3474 13.2981 11.0605L14.1888 10.5938C14.226 10.5742 14.2689 10.5743 14.306 10.5938L15.1966 11.0605C15.7425 11.3474 16.4085 11.3002 16.9075 10.9375C17.4067 10.5749 17.6581 9.95554 17.554 9.34766L17.3841 8.35742C17.377 8.31621 17.3894 8.27525 17.4192 8.24609L18.1399 7.54297C18.5815 7.11227 18.7428 6.46353 18.552 5.87695C18.3614 5.29027 17.8498 4.86025 17.2395 4.77148L16.2434 4.62695C16.2018 4.6209 16.1684 4.59648 16.1497 4.55859L15.7043 3.65625C15.4314 3.10281 14.8643 2.75 14.2473 2.75ZM14.2473 4.25C14.2965 4.25 14.337 4.27634 14.3586 4.32031L14.804 5.22266C15.0402 5.70136 15.5 6.03451 16.0286 6.11133L17.0247 6.25586C17.0733 6.26294 17.1091 6.29516 17.1243 6.3418C17.1395 6.38854 17.1283 6.4344 17.093 6.46875L16.3723 7.17188C15.9905 7.54424 15.816 8.0838 15.9055 8.60938L16.0754 9.60156C16.0837 9.65008 16.0662 9.69585 16.0266 9.72461H16.0246C15.9848 9.75352 15.9374 9.75733 15.8937 9.73441L15.0031 9.26566C14.5306 9.01734 13.9639 9.01744 13.4914 9.26566L12.6008 9.73441C12.5572 9.75733 12.5077 9.7535 12.468 9.72461C12.4284 9.69585 12.4108 9.64998 12.4191 9.60156L12.5891 8.60938C12.6792 8.08332 12.5046 7.5447 12.1223 7.17188L11.4016 6.46875C11.3664 6.43442 11.3551 6.38861 11.3704 6.3418C11.3855 6.29516 11.4213 6.26294 11.47 6.25586L12.4661 6.11133C12.9946 6.0345 13.4544 5.7017 13.6907 5.22266L14.136 4.32031C14.1578 4.27616 14.1981 4.25 14.2473 4.25Z"
        fill="#667085"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2473 0C8.18513 0 6.49731 1.68782 6.49731 3.75V10.75C6.49731 12.8122 8.18515 14.5 10.2473 14.5H14.8489L16.9583 16.2188C17.331 16.5222 17.8501 16.5835 18.2844 16.377C18.7179 16.1703 18.9973 15.7303 18.9973 15.25V14.3477C20.6908 13.9878 21.9973 12.5465 21.9973 10.75V3.75C21.9973 1.68783 20.3095 0 18.2473 0H10.2473ZM10.2473 1.5H18.2473C19.4989 1.5 20.4973 2.49847 20.4973 3.75V10.75C20.4973 12.0016 19.4989 13 18.2473 13C18.0484 13 17.8577 13.079 17.717 13.2197C17.5764 13.3603 17.4973 13.5511 17.4973 13.75V14.7227L15.5891 13.168C15.455 13.059 15.2873 12.9996 15.1145 13H10.2473C8.99578 13 7.99731 12.0016 7.99731 10.75V3.75C7.99731 2.49848 8.9958 1.5 10.2473 1.5Z"
        fill="#667085"
      />
      <path
        d="M4.24731 7C2.18515 7 0.497314 8.68778 0.497314 10.75V14.75C0.497314 16.5465 1.80385 17.9878 3.49731 18.3477V19.25C3.49731 19.7302 3.77671 20.1703 4.21021 20.377C4.64451 20.5835 5.16367 20.5221 5.53638 20.2188L7.64771 18.5H10.2473C12.3095 18.5 13.9973 16.8122 13.9973 14.75V13.75C13.9973 13.5511 13.9183 13.3603 13.7776 13.2197C13.637 13.079 13.4462 13 13.2473 13C13.0484 13 12.8576 13.079 12.717 13.2197C12.5763 13.3603 12.4973 13.5511 12.4973 13.75V14.75C12.4973 16.0016 11.4989 17 10.2473 17H7.38013C7.2073 16.9996 7.03964 17.059 6.90552 17.168L4.99731 18.7227V17.75C4.99729 17.5511 4.91827 17.3603 4.77762 17.2197C4.63697 17.079 4.44622 17 4.24731 17C2.99578 17 1.99731 16.0016 1.99731 14.75V10.75C1.99731 9.49842 2.99578 8.5 4.24731 8.5H7.24731C7.44623 8.5 7.63699 8.42098 7.77764 8.28033C7.9183 8.13968 7.99731 7.94891 7.99731 7.75C7.99731 7.55109 7.9183 7.36032 7.77764 7.21967C7.63699 7.07902 7.44623 7 7.24731 7H4.24731Z"
        fill="#667085"
      />
    </svg>
  );
};
