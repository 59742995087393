import { FC } from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { DefaultTextPrice, DiscountPrice } from "../../../../components/Price";
import {
  FlexCol,
  FlexRow,
  H3,
  P,
  Span,
  Spinner,
} from "../../../../shared/globals";
import { CouponIcon, TrashIcon } from "../../../../assets/Icons";
import Alert from "../../../../shared/globals/UiElements/Alert";
import { Link } from "../../../../lib/i18n";
import { themeColor } from "../../../../shared/styles-utils";
import useBreakPoints from "../../../../shared/utils/useBreakPoints";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { Badge } from "../../../../shared/globals/UiElements/Badge";
import { getLocaleInfo } from "../../../../lib/i18n/locales-data";
import { getTranslatedDiscountTitle } from "../../../../shared/utils/getTranslatedDiscountTitle";
import { useCartData } from "../../../../lib/cartData/useCartData";
import {
  CartCustomItem,
  CartError,
  CartItem,
  CartSimpleItem,
  ProductType,
  CheckoutServiceErrorCodes as ErrorCodes,
  CartFragment,
} from "../../../../generated/graphql";
import SideCartItemQty from "./SideCartItemQty";
import {
  FormattedError,
  generateItemRelatedErrors,
  isProductNotAvailableError,
} from "../../../../shared/utils/error-utils";
import { useStore } from "../../../../lib/storeData";

interface SideCartSingleItemProps {
  item: CartFragment["items"][0];
  errors?: CartError[] | null;
}

interface SideCartItemProps {
  item: CartFragment["items"][0];
  itemErrors?: FormattedError[] | null;
  isItemNotAvailable: boolean;
}

const SideCartSingleItem: React.FC<SideCartSingleItemProps> = ({
  item,
  errors,
}) => {
  const isSimpleItem = item?.product?.type === ProductType.Simple;

  const { currency: currencyCode } = useStore();

  const itemRelatedErrors = generateItemRelatedErrors(
    item as CartItem,
    currencyCode,
    errors
  );

  const isProductNotAvailableErrorFound =
    isProductNotAvailableError(itemRelatedErrors);

  if (isSimpleItem)
    return (
      <SideCartSimpleItem
        item={item}
        itemErrors={itemRelatedErrors}
        isItemNotAvailable={isProductNotAvailableErrorFound}
      />
    );
  else
    return (
      <SideCartCustomItem
        item={item}
        itemErrors={itemRelatedErrors}
        isItemNotAvailable={isProductNotAvailableErrorFound}
      />
    );
};

const SideCartSimpleItem: FC<SideCartItemProps> = ({
  item,
  itemErrors,
  isItemNotAvailable,
}) => {
  const { isTablet } = useBreakPoints();
  const { locale } = useIntl();
  const activeLocale = getLocaleInfo(locale).code;

  const {
    cart: { isLoading },
    setSideCartState,
    updateSimpleItem: { updateSimpleItem },
    removeSimpleItem: { removeSimpleItem },
  } = useCartData();

  const typedSimpleItem = item as CartSimpleItem;

  const isAutomaticDiscountApplied = !!typedSimpleItem?.discounts?.length;

  const handleRemoveCartItem = async () => {
    await removeSimpleItem(typedSimpleItem?.variant?.id);
  };

  const handleQuantityChange = async (value: number) => {
    await updateSimpleItem({
      item: {
        quantity: value,
        variantId: typedSimpleItem?.variant?.id,
      },
    });
  };
  const FlexRotator = ({ children }) =>
    isTablet ? (
      <FlexCol fullWidth spacing="m" alignItems="flex-start">
        {children}
      </FlexCol>
    ) : (
      <FlexRow fullWidth spacing="m" justifyContent="space-between">
        {children}
      </FlexRow>
    );

  return (
    <FlexCol
      fullWidth
      spacing="m"
      style={{ backgroundColor: "white", padding: "20px", borderRadius: "8px" }}
    >
      <ItemHolder
        data-test="cart-item"
        fullWidth
        spacing="m"
        alignItems="start"
        justifyContent="space-between"
      >
        <ItemImg
          imgDetails={
            typedSimpleItem?.variant?.image ||
            typedSimpleItem?.product?.images[0]
          }
        />

        <Flex column fullWidth spacing="xxs">
          {isAutomaticDiscountApplied && (
            <Flex flexWrap="wrap" spacing="s">
              {typedSimpleItem?.discounts?.map((discount, idx) => (
                <Badge
                  key={idx}
                  type="ink"
                  label={
                    <Flex alignItems="center" spacing="xs">
                      <CouponIcon />
                      {getTranslatedDiscountTitle({
                        defaultTitle: discount?.data?.title,
                        currentLocale: activeLocale,
                        translations: discount?.data?.translations,
                      })}
                    </Flex>
                  }
                />
              ))}
            </Flex>
          )}

          <FlexRotator>
            <FlexCol alignItems="flex-start" spacing="xs">
              <Title
                data-test="text-item-title"
                onClick={() => setSideCartState(false)}
              >
                <Link
                  title={typedSimpleItem?.product?.title}
                  href={`/product/all/${typedSimpleItem?.product?.handle}`}
                >
                  {typedSimpleItem?.product?.title}
                </Link>
              </Title>
              {!!typedSimpleItem?.variant?.selectedOptions.length && (
                <SelectedOptions>
                  {(typedSimpleItem?.variant?.selectedOptions || [])
                    ?.map((option) => option.value?.name)
                    .join(" / ")}
                </SelectedOptions>
              )}
              {!!typedSimpleItem?.variant?.sku && (
                <P fontSize="12px" style={{ fontStyle: "italic" }}>
                  <FormattedMessage
                    defaultMessage="SKU: {sku}"
                    values={{ sku: typedSimpleItem?.variant?.sku }}
                  />
                </P>
              )}
              <FlexRow data-test="item-price" spacing="xs">
                {isLoading ? (
                  <FlexRow spacing="s" alignItems="center">
                    <P>
                      <FormattedMessage defaultMessage="Calculating..." />
                    </P>
                    <Spinner inline={true} size={14} />
                  </FlexRow>
                ) : (
                  <>
                    {isAutomaticDiscountApplied && (
                      <DiscountPrice
                        style={{ padding: 0 }}
                        money={typedSimpleItem?.subtotal}
                      />
                    )}
                    <DefaultTextPrice
                      style={{ fontSize: 14, fontWeight: 600 }}
                      money={typedSimpleItem?.totalPrice}
                    />
                  </>
                )}
              </FlexRow>
            </FlexCol>

            <FlexRow spacing="m">
              <SideCartItemQty
                isDisabled={isItemNotAvailable}
                initialQuantity={typedSimpleItem?.quantity}
                maxQuantityPerCart={typedSimpleItem?.variant?.maxPerCart}
                handleQuantityChange={handleQuantityChange}
              />
              <DeleteButton
                data-test="button-remove-item"
                type="button"
                onClick={handleRemoveCartItem}
                disabled={isLoading}
              >
                <TrashIcon />
              </DeleteButton>
            </FlexRow>
          </FlexRotator>
        </Flex>
      </ItemHolder>
      {itemErrors?.map((itemError, i) => (
        <Alert
          key={i}
          type={itemError.code === ErrorCodes.PriceChanged ? "warn" : undefined}
          message={itemError.message}
        />
      ))}
    </FlexCol>
  );
};

const SideCartCustomItem: FC<SideCartItemProps> = ({
  item,
  itemErrors,
  isItemNotAvailable,
}) => {
  const { isTablet } = useBreakPoints();

  const {
    cart: { isLoading },
    setSideCartState,
    updateCustomItem: { updateCustomItem },
    removeCustomItem: { removeCustomItem },
  } = useCartData();

  const typedCustomItem = item as CartCustomItem;

  const isCustomDiscountApplied =
    !!typedCustomItem?.discount?.info?.fixed?.amount ||
    !!typedCustomItem?.discount?.info?.percentage;

  const handleRemoveCartItem = async () => {
    await removeCustomItem({
      item: {
        productId: typedCustomItem.product.id,
      },
    });
  };

  const handleQuantityChange = async (value: number) => {
    await updateCustomItem({
      item: {
        productId: typedCustomItem.product.id,
        quantity: value,
      },
    });
  };
  const FlexRotator = ({ children }) =>
    isTablet ? (
      <FlexCol fullWidth spacing="m" alignItems="flex-start">
        {children}
      </FlexCol>
    ) : (
      <FlexRow fullWidth spacing="m" justifyContent="space-between">
        {children}
      </FlexRow>
    );

  return (
    <FlexCol
      fullWidth
      spacing="m"
      style={{ backgroundColor: "white", padding: "20px", borderRadius: "8px" }}
    >
      <ItemHolder
        fullWidth
        spacing="m"
        alignItems="start"
        data-test="cart-item"
        justifyContent="space-between"
      >
        <ItemImg imgDetails={typedCustomItem?.product?.images[0]} />

        <Flex column fullWidth spacing="xxs">
          {isCustomDiscountApplied && (
            <Badge
              type="ink"
              label={
                <Flex alignItems="center" spacing="xs">
                  <CouponIcon />
                  {typedCustomItem?.discount?.info?.percentage ? (
                    <FormattedMessage
                      defaultMessage="SAVE {percent}%"
                      values={{
                        percent: typedCustomItem?.discount?.info?.percentage,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="SAVE {fixed}"
                      values={{
                        fixed: (
                          <DefaultTextPrice
                            money={typedCustomItem?.discount?.info?.fixed}
                          />
                        ),
                      }}
                    />
                  )}
                </Flex>
              }
            />
          )}
          <FlexRotator>
            <FlexCol alignItems="flex-start" spacing="xs">
              <Title
                data-test="text-item-title"
                onClick={() => setSideCartState(false)}
              >
                <Link
                  title={typedCustomItem?.product?.title}
                  href={`/product/all/${typedCustomItem?.product?.handle}`}
                >
                  {typedCustomItem?.product?.title}
                </Link>
              </Title>
              <FlexRow data-test="item-price" spacing="xs">
                {isLoading ? (
                  <FlexRow spacing="s" alignItems="center">
                    <P>
                      <FormattedMessage defaultMessage="Calculating..." />
                    </P>
                    <Spinner inline={true} size={14} />
                  </FlexRow>
                ) : (
                  <>
                    {isCustomDiscountApplied && (
                      <DiscountPrice
                        style={{ padding: 0 }}
                        money={typedCustomItem?.subtotal}
                      />
                    )}
                    <DefaultTextPrice
                      style={{ fontSize: 14, fontWeight: 600 }}
                      money={typedCustomItem?.totalPrice}
                    />
                  </>
                )}
              </FlexRow>
            </FlexCol>

            <FlexRow spacing="m">
              <SideCartItemQty
                isDisabled={isItemNotAvailable}
                initialQuantity={typedCustomItem?.quantity}
                handleQuantityChange={handleQuantityChange}
              />
              <DeleteButton
                data-test="button-remove-item"
                type="button"
                onClick={handleRemoveCartItem}
                disabled={isLoading}
              >
                <TrashIcon />
              </DeleteButton>
            </FlexRow>
          </FlexRotator>
        </Flex>
      </ItemHolder>
      {itemErrors?.map((itemError, i) => (
        <Alert
          key={i}
          type={itemError.code === ErrorCodes.PriceChanged ? "warn" : undefined}
          message={itemError.message}
        />
      ))}
    </FlexCol>
  );
};

export default SideCartSingleItem;

const ItemHolder = styled(FlexRow)`
  background-color: ${themeColor("white")};
  border-radius: 8px;
  word-break: break-word;
`;

const Title = styled(H3)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;

const SelectedOptions = styled(Span)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme: { text } }) => text.inkLight};
  text-transform: capitalize;
`;

const DeleteButton = styled.button<{ disabled?: boolean }>`
  background-color: #cc09050d;
  padding: 4px;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;
