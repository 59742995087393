import { ReactElement } from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";
import { Field, Form } from "react-final-form";
import createDecorator from "final-form-focus";
import { PrimaryButton } from "../../../../../components/Button";
import { FlexRow, FlexCol, P } from "../../../../../shared/globals";
import { rtl } from "../../../../../shared/styles-utils";

type FormValues = {
  minPrice: number;
  maxPrice: number;
};

export const FilterByPrice = () => {
  const router = useRouter();
  const focusOnError = createDecorator<FormValues>();

  const handleSubmit = (values: FormValues) => {
    router.push(
      {
        pathname: router.asPath?.split("?")[0],
        query: {
          ...router.query,
          page: 1,
          minPrice: values.minPrice,
          maxPrice: values.maxPrice,
        },
      },
      undefined,
      { scroll: false }
    );
  };

  return (
    <Container>
      <Form<FormValues>
        onSubmit={handleSubmit}
        validate={validate}
        decorators={[focusOnError]}
        initialValues={{
          minPrice: +router.query.minPrice! || undefined,
          maxPrice: +router.query.maxPrice! || undefined,
        }}
        render={({ handleSubmit, submitting, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <FlexRow
              fullWidth
              spacing="l"
              justifyContent="space-between"
              margin="16px 0 0"
            >
              <Field name="minPrice">
                {({ input }) => (
                  <StyledInput
                    {...input}
                    data-test="type-min-price"
                    min="0"
                    placeholder="-"
                  />
                )}
              </Field>

              <P color="secondary">
                <FormattedMessage defaultMessage="To" />
              </P>

              <Field name="maxPrice">
                {({ input }) => (
                  <StyledInput
                    {...input}
                    data-test="type-max-price"
                    min="0"
                    placeholder="-"
                  />
                )}
              </Field>

              <SaveButton
                data-test="button-save-price"
                type="submit"
                isLoading={submitting}
              >
                <FormattedMessage defaultMessage="GO" />
              </SaveButton>
            </FlexRow>

            {errors && touched && (
              <RequiredSpan>{errors.minPrice}</RequiredSpan>
            )}
          </form>
        )}
      />
    </Container>
  );
};

const validate = (values: FormValues) => {
  const errors: Partial<Record<keyof FormValues, ReactElement>> = {};
  if (values?.minPrice >= values?.maxPrice) {
    errors.minPrice = (
      <FormattedMessage defaultMessage="Please make sure that min price is less than the max price" />
    );
  }
  return errors;
};

// Styles

const Container = styled(FlexCol)`
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const StyledInput = styled.input`
  display: flex;
  align-items: center;
  position: relative;
  border: solid 1px #dfe3e8;
  border-radius: 4px;
  flex-direction: ${rtl("row-reverse", "row")};

  padding: 9px;
  outline: none;
  background-color: transparent;
  width: 100%;
  height: 40px;
  font-weight: 600;
  appearance: none;
  /* Chrome, Safari, Edge, Opera, Firefox */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::placeholder {
    font-weight: 400;
    color: black;
    text-align: center;
  }
  -moz-appearance: textfield;
`;

const SaveButton = styled(PrimaryButton)`
  color: white;
  background-color: black;
  height: 40px;
  border: none;
  &:hover,
  &:focus {
    opacity: 0.85;
  }
`;

const RequiredSpan = styled.span`
  font-size: 14px;
  color: #d21c1c;
  font-weight: 400;
`;
