import React from "react";

export const ContainedStar = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.9506 16.9818L4.69135 15.0265L3.49409 13.46C2.8353 12.5973 2.8353 11.4004 3.49409 10.5377L4.69135 8.97311L4.9524 7.01774C5.09629 5.94143 5.94295 5.0947 7.01924 4.95074L8.97266 4.69147L10.539 3.49412C11.4016 2.83529 12.5984 2.83529 13.461 3.49412L15.0273 4.69147L16.9826 4.95255C18.0586 5.09709 18.9049 5.94353 19.0494 7.01955L19.3086 8.97312L20.5059 10.5378C21.1647 11.4005 21.1647 12.5973 20.5059 13.46L19.3086 15.0265L19.0476 16.9818C18.9039 18.0586 18.0573 18.9059 16.9808 19.0506L15.0273 19.3081L13.461 20.5072C12.5978 21.1642 11.4022 21.1642 10.539 20.5072L8.97266 19.3081L7.01744 19.047C5.94116 18.9044 5.09408 18.058 4.9506 16.9818Z"
        stroke="#FE9931"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1031 8.08572C11.2716 7.74434 11.6192 7.5282 11.9999 7.5282C12.3806 7.5282 12.7282 7.74434 12.8967 8.08572L13.4055 9.11713C13.5511 9.41234 13.8327 9.61697 14.1584 9.66431L15.2965 9.82973C15.6731 9.88451 15.986 10.1483 16.1036 10.5102C16.2213 10.8721 16.1233 11.2694 15.8509 11.5351L15.027 12.3387C14.7915 12.5685 14.684 12.8994 14.7396 13.2236L14.934 14.3569C14.9984 14.732 14.8442 15.1111 14.5363 15.3349C14.2284 15.5586 13.8202 15.5882 13.4833 15.4111L12.4651 14.876C12.1738 14.7229 11.8259 14.7229 11.5347 14.876L10.5165 15.4111C10.1795 15.5882 9.77135 15.5586 9.46346 15.3349C9.15557 15.1111 9.00137 14.732 9.06568 14.3569L9.26008 13.2236C9.3157 12.8994 9.20826 12.5685 8.97273 12.3387L8.14889 11.5351C7.8765 11.2694 7.77854 10.8721 7.89618 10.5102C8.01383 10.1483 8.32669 9.8845 8.70327 9.82973L9.8414 9.66431C10.1672 9.61697 10.4487 9.41234 10.5944 9.11713L11.1031 8.08572Z"
        stroke="#FE9931"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
