import { FormattedMessage } from "react-intl";
import {
  BorderBottom,
  CartHolder,
  CartPanel,
  CheckoutButton,
  FullWidth,
  LightH4,
  LightH4ForDiscount,
  StyledH2,
  StyledH3WithMargin,
  StyledH4,
  StyledH5,
} from "./styled";
import { ShoppingBag, RightArrow } from "../../../../assets/Icons";
import { FlexCol, FlexRow } from "../../../../shared/globals";
import { Link } from "../../../../lib/i18n";
import { StoreTemplate } from "../../../TemplateLoader";
import { useCartData } from "../../../../lib/cartData/useCartData";
import { isCartDirty } from "../../../../shared/utils/error-utils";
import { CartCardSummary } from "../CartCardSummary/CartCardSummary";
import { Price } from "../../../../components/Price";

const CartCard = () => {
  const Template = StoreTemplate.get();
  const {
    cart: { receipt, shippingRate, isLoading, cartErrors, id },
  } = useCartData();

  const canNotProceedToCheckout = isCartDirty(cartErrors);

  const isFreeShippingApplied = receipt?.shipping?.amount === 0;

  return (
    <CartHolder>
      <CartPanel>
        <BorderBottom>
          <FlexRow>
            <ShoppingBag />
            <StyledH3WithMargin>
              <FormattedMessage defaultMessage="Cart Totals" />
            </StyledH3WithMargin>
          </FlexRow>
        </BorderBottom>
        <BorderBottom>
          <Template.elements.Coupon />
        </BorderBottom>
        <CartCardSummary />
        <BorderBottom>
          <FullWidth style={{ alignItems: "flex-start" }}>
            <StyledH5 style={{ lineHeight: 2 }}>
              <FormattedMessage defaultMessage="Total" />
            </StyledH5>
            <FlexCol alignItems="flex-end">
              <StyledH2>
                {isLoading ? (
                  <FormattedMessage defaultMessage="Calculating..." />
                ) : (
                  <Price money={receipt?.total} />
                )}
              </StyledH2>
              {shippingRate ? (
                shippingRate?.cost?.amount && !isFreeShippingApplied ? (
                  <LightH4>
                    <FormattedMessage defaultMessage="+ Shipping" />
                  </LightH4>
                ) : (
                  <LightH4ForDiscount>
                    <FormattedMessage defaultMessage="Free Shipping" />
                  </LightH4ForDiscount>
                )
              ) : (
                <LightH4>
                  <FormattedMessage defaultMessage="To be calculated" />
                </LightH4>
              )}
            </FlexCol>
          </FullWidth>
        </BorderBottom>
        <Link
          href={`/checkout/${id}`}
          fullWidth
          disabled={isLoading || canNotProceedToCheckout}
        >
          <CheckoutButton
            suffixIcon={<RightArrow />}
            loadOnRouteChange
            to={`/checkout/${id}`}
            disabled={isLoading || canNotProceedToCheckout}
          >
            <FormattedMessage defaultMessage="Checkout" />
          </CheckoutButton>
        </Link>
      </CartPanel>
      <Link href="/shop">
        <StyledH4 style={{ cursor: "pointer" }}>
          <FormattedMessage defaultMessage="Continue Shopping" />
        </StyledH4>
      </Link>
    </CartHolder>
  );
};

export default CartCard;
