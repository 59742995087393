import { FormattedMessage } from "react-intl";
import { ApolloError } from "@apollo/client";
import { AvailableFilterChoices } from "./AvailableFilterChoices";
import { FilterByPrice } from "./FilterByPrice";
import { HyphenIcon, AddIcon } from "../../../../../assets/Icons";
import { DefaultFilters } from "../../../../default/components/Filter/consts";
import { FiltersDictionary } from "../../../../../shared/utils/filterHelperFunctions";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import MotionElementWrapper from "../../../../../shared/globals/MotionElementWrapper";

interface FiltersSectionProps {
  availableFilters: FiltersDictionary;
  selectedFiltersIds: string[];
  selectedFilters: Map<string, boolean>;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<Map<string, boolean>>
  >;
  loading: boolean;
  error: ApolloError | undefined;
}

const FiltersSection = ({
  availableFilters,
  selectedFiltersIds,
  selectedFilters,
  setSelectedFilters,
  loading,
  error,
}: FiltersSectionProps) => {
  return (
    <Flex
      column
      fullHeight
      spacing="none"
      fullWidth
      className="overflow-y-auto overflow-x-hidden"
    >
      {Object.keys(availableFilters || {}).map((key) => (
        <Flex column key={key} fullWidth spacing="none">
          <Flex
            onClick={() =>
              setSelectedFilters(
                (prev) => new Map(prev.set(key, !prev.get(key)))
              )
            }
            className="p-md cursor-pointer"
            fullWidth
            justifyContent="space-between"
          >
            <span className="text-gray-700 ">
              {availableFilters![key][0]?.propertyName}
            </span>
            <span className="shrink-0 text-gray-500">
              {selectedFilters.get(key) ? <HyphenIcon /> : <AddIcon />}
            </span>
          </Flex>

          <MotionElementWrapper
            animate={
              selectedFilters.get(key)
                ? { display: "block", opacity: 1, height: "auto" }
                : { opacity: 0, height: 0, transitionEnd: { display: "none" } }
            }
            duration={0.2}
          >
            <AvailableFilterChoices
              availableFilters={availableFilters[key]}
              loading={loading}
              error={error!}
              selectedFilterIds={selectedFiltersIds}
            />
          </MotionElementWrapper>
        </Flex>
      ))}

      <Flex column fullWidth spacing="none" className="cursor-pointer">
        <Flex
          fullWidth
          justifyContent="space-between"
          onClick={() =>
            setSelectedFilters(
              (prev) =>
                new Map(
                  prev.set(
                    DefaultFilters.Price,
                    !prev.get(DefaultFilters.Price)
                  )
                )
            )
          }
          className="p-md"
        >
          <span className="text-gray-700 ">
            <FormattedMessage defaultMessage="Price" />{" "}
          </span>
          {selectedFilters.get(DefaultFilters.Price) ? (
            <span className=" text-gray-500">
              <HyphenIcon />
            </span>
          ) : (
            <span className=" text-gray-500">
              <AddIcon />
            </span>
          )}
        </Flex>
        <MotionElementWrapper
          animate={
            selectedFilters.get(DefaultFilters.Price)
              ? { opacity: 1 }
              : { opacity: 0 }
          }
          duration={0.2}
        >
          <FilterByPrice />
        </MotionElementWrapper>
      </Flex>
    </Flex>
  );
};

export default FiltersSection;
