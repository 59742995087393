import React, { CSSProperties } from "react";
import styled from "styled-components";

interface HTMLRenderProps {
  html: string | null | undefined;
  className?: string;
  style?: CSSProperties;
}

const HTMLRender: React.FC<HTMLRenderProps> = ({ html, className, style }) => {
  if (!html) return null;
  return (
    <Wrapper
      className={className}
      dangerouslySetInnerHTML={{ __html: html }}
      style={style}
    />
  );
};

export default HTMLRender;

const Wrapper = styled.div`
  overflow: auto;
`;
