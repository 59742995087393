import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  FooterFollow,
  TextFollow,
  CloseMenu,
  MobileWrapper,
  MobileNavScrollContainer,
  MobileNavHeader,
  StyledShopButton,
  StyledMyAccountButton,
  StyledLoginButton,
} from "../components/NavBar/styled";
import { Overlay } from "../../../shared/globals";
import AccountButton from "../components/NavBar/MobileAccountButton";
import { MobileLanguageSwitcher } from "../components/NavBar/MobileLanguageSwitcher";
import { SocialLinks } from "../../../components/UtilityComponents/SocialLinks";
import { CustomerIcon, LeftArrow } from "../../../assets/Icons";
import MobileMenu from "../components/NavBar/menu/MobileMenu";
import { useAuth } from "../../../lib/Authentication";
import { Link, useRouter } from "../../../lib/i18n";
import { MobileNavigationProps } from "../../types";

const MobileNavigation = ({
  open,
  onClose,
  socialSettings,
  menus,
}: MobileNavigationProps) => {
  const auth = useAuth();
  const Router = useRouter();
  const [isActive, setIsActive] = React.useState(true);
  const { locale } = useIntl();
  React.useEffect(() => {
    if (!auth.isLoggedIn) {
      setIsActive(true);
    }
  }, [auth.isLoggedIn]);

  return (
    <>
      {open && <Overlay onClick={onClose} />}
      <MobileWrapper open={open}>
        <MobileNavScrollContainer>
          <MobileNavHeader
            isFullWidth={
              Router.pathname === "/login" ||
              Router.pathname === "/registration"
            }
          >
            <StyledShopButton
              onClick={() => setIsActive(true)}
              className={isActive ? "active" : ""}
              isFullWidth={
                Router.pathname === "/login" ||
                Router.pathname === "/registration"
              }
            >
              <FormattedMessage defaultMessage="Shop" />
            </StyledShopButton>
            {auth.isLoggedIn ? (
              <StyledMyAccountButton
                onClick={() => setIsActive(false)}
                className={!isActive ? "active" : ""}
              >
                <CustomerIcon />
                <FormattedMessage defaultMessage="My Account" />
              </StyledMyAccountButton>
            ) : Router.pathname === "/login" ||
              Router.pathname === "/registration" ? null : (
              <StyledLoginButton>
                <Link href="/login">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CustomerIcon />
                    <FormattedMessage defaultMessage="Login" />
                  </div>
                </Link>
              </StyledLoginButton>
            )}
          </MobileNavHeader>
          {isActive ? (
            <>
              <MobileMenu items={menus} />
              <FooterFollow>
                {!socialSettings?.length ? null : (
                  <TextFollow>
                    <FormattedMessage defaultMessage="Follow us on" />
                  </TextFollow>
                )}
                <SocialLinks socialSettings={socialSettings} padding={10} />
              </FooterFollow>
            </>
          ) : (
            <AccountButton />
          )}
          <CloseMenu onClick={onClose}>
            <LeftArrow rotate={locale === "ar-EG"} />
          </CloseMenu>
        </MobileNavScrollContainer>
        <MobileLanguageSwitcher />
      </MobileWrapper>
    </>
  );
};

export default MobileNavigation;
