const ShoppingIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_6165_16738)">
        <rect x="2" y="1" width="48" height="48" rx="10" fill="white" />
        <rect
          x="2.5"
          y="1.5"
          width="47"
          height="47"
          rx="9.5"
          stroke="#EAECF0"
        />
        <path
          d="M22 30.5L19 16.5H17"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.25 30.5H32.25"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle
          cx="32.25"
          cy="32.25"
          r="1.75"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle
          cx="22.25"
          cy="32.25"
          r="1.75"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.9998 19.5H33.9992C34.3099 19.5 34.6029 19.6444 34.7922 19.8907C34.9815 20.1371 35.0455 20.4574 34.9655 20.7576L33.366 26.7576C33.2493 27.1954 32.8528 27.5 32.3998 27.5H21.3569"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.0002 19.4999L19.6431 19.5001"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.6353 18.0859L28.4637 20.9144"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.6353 20.9144L28.4637 18.0859"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6165_16738"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6165_16738"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6165_16738"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export { ShoppingIcon };
