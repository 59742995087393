import { FC } from "react";
import { useIntl } from "react-intl";
import { SectionHeadingProps } from "../../types";
import { getLocaleInfo } from "../../../lib/i18n/locales-data";
import styled from "styled-components";

const SectionHeading: FC<SectionHeadingProps> = ({ section }) => {
  const { locale } = useIntl();
  const activeLocale = getLocaleInfo(locale).code;
  const translations = section?.translations?.find(
    (translation) => translation.locale === activeLocale
  );

  return section?.showSectionHeader ? (
    <SectionHeader>{translations?.header || section?.header}</SectionHeader>
  ) : null;
};

const SectionHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-inline-start: 10px;
`;

export default SectionHeading;
