import Flex from "../../../../shared/globals/UiElements/Flex";
import { useStore } from "../../../../lib/storeData";
import SwiperClass from "swiper";
import MotionElementWrapper from "../../../../shared/globals/MotionElementWrapper";

interface SwiperPaginationProps {
  swiper: SwiperClass;
  timeLeft: number;
  autoPlayDelay: number;
  realActiveIndex: number;
  setRealActiveIndex: (index: number) => void;
}

const SwiperPagination: React.FC<SwiperPaginationProps> = ({
  swiper,
  timeLeft,
  autoPlayDelay,
  realActiveIndex,
  setRealActiveIndex,
}) => {
  const numberOfSlider = swiper?.slides?.length;

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className="absolute bottom-5 left-[50%] translate-x-[-50%] rounded-full z-TOOLTIP bg-white px-sm py-xs"
      spacing="s"
    >
      {Array.from(Array(numberOfSlider || []).keys()).map((i, index) => (
        <PaginationBullet
          key={index}
          isActive={index === realActiveIndex}
          onClick={() => {
            swiper?.slideToLoop(index);
            setRealActiveIndex(index);
          }}
          timeLeft={timeLeft}
          autoPlayDelay={autoPlayDelay}
        />
      ))}
    </Flex>
  );
};

interface PaginationBulletProps {
  isActive: boolean;
  onClick: () => void;
  timeLeft: number;
  autoPlayDelay: number;
}

const PaginationBullet = ({
  isActive,
  onClick,
  timeLeft,
  autoPlayDelay,
}: PaginationBulletProps) => {
  const progress = 100 - (timeLeft / autoPlayDelay) * 100;
  const { appearance } = useStore();
  const primaryColor = appearance?.colors?.primary;

  return (
    <div onClick={onClick}>
      <MotionElementWrapper
        initial={{ width: 6, height: 6 }}
        animate={isActive ? { width: 28, height: 6 } : { width: 6, height: 6 }}
        className={`rounded-full bg-gray-300 ${
          isActive ? "" : "hover:bg-gray-500 cursor-pointer"
        } transition-all duration-200 relative overflow-hidden`}
      >
        {isActive && (
          <div
            className={`absolute top-0 bottom-0 left-0 h-full rounded-full`}
            style={{
              backgroundColor: `${primaryColor}`,
              width: `${progress}%`,
            }}
          />
        )}
      </MotionElementWrapper>
    </div>
  );
};

export default SwiperPagination;
