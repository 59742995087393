import React from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import styled, { css } from "styled-components";
import {
  CouponIcon,
  LeftSmallArrow,
  MyReviewsIcon,
  TruckIcon,
} from "../../../../assets/Icons";
import {
  CustomItem,
  DiscountAppliedOnType,
  useGetOrderByIdQuery,
  OrderInfoFragment,
  OrderPaymentStatusEnum,
  FulfillStatusEnum,
  PromoCodeTypeEnum,
} from "../../../../generated/graphql";
import { Link } from "../../../../lib/i18n";
import { useStore } from "../../../../lib/storeData";
import { FlexCol, FlexRow, H4, H5, H6 } from "../../../../shared/globals";
import { Badge } from "../../../../shared/globals/UiElements/Badge";
import LoadingSpinner from "../../../../shared/globals/UiElements/LoadingSpinner";
import { rtl, themeColor } from "../../../../shared/styles-utils";
import {
  multiplyMoneyByNumber,
  subtractMoney,
} from "../../../../shared/utils/money";
import { PrimaryButton } from "../../../../components/Button";
import {
  DefaultTextPrice,
  Price,
  DiscountPrice,
} from "../../../../components/Price";
import ReviewButton from "../../components/CustomerProfile/my-orders/ReviewButton";
import { StyledButton } from "../../components/CustomerProfile/my-orders/ReviewStyles";
import { useRouter } from "next/router";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import { StoreTemplate } from "../../../TemplateLoader";
import { transformOrderItems } from "../../../../components/Checkout/utils/transformOrderItems";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { getTranslatedDiscountTitle } from "../../../../shared/utils/getTranslatedDiscountTitle";
import { getLocaleInfo } from "../../../../lib/i18n/locales-data";
import { Card } from "../../../../shared/globals/UiElements/Card";
import CartSummaryPanel from "../../components/customComponents/CartSummaryPanel";

interface ItemPriceAndQtyProps {
  item?: OrderInfoFragment["items"][0] | null;
}

const ItemPriceAndQty: React.FC<ItemPriceAndQtyProps> = ({ item }) => {
  const isBuyXGetYDiscountApplied = !!item?.discount?.perItem?.amount!;
  const itemDiscount = item?.discount?.perItem;
  const priceBeforeDiscount = item?.price;
  const priceAfterDiscount = subtractMoney(item?.price!, itemDiscount);
  const discountedItemsCount = item?.discount?.quantity || 0;
  const nonDiscountedItemsCount = item?.quantity! - discountedItemsCount;
  const isAllItemsDiscounted = item?.quantity! === discountedItemsCount;

  return (
    <>
      <GridCell>
        <FlexCol alignItems="start">
          {isBuyXGetYDiscountApplied && (
            <div>
              <DiscountPrice money={priceBeforeDiscount} />
              <PriceCell money={priceAfterDiscount} />
            </div>
          )}

          {!isAllItemsDiscounted && <PriceCell money={priceBeforeDiscount} />}
        </FlexCol>
      </GridCell>
      <GridCell>
        <GridCell>
          <FlexCol alignItems="start">
            {isBuyXGetYDiscountApplied && (
              <span> x {discountedItemsCount}</span>
            )}
            {!isAllItemsDiscounted && <span> x {nonDiscountedItemsCount}</span>}
          </FlexCol>
        </GridCell>
      </GridCell>
    </>
  );
};
const OrderDetails = () => {
  const Template = StoreTemplate.get();
  const { query } = useRouter();
  const orderId = query.orderId as string;
  const { storeId, areReviewsActivated } = useStore();
  const { data, loading } = useGetOrderByIdQuery({
    variables: { storeId, orderId },
  });
  const { locale } = useIntl();
  const currentLocale = getLocaleInfo(locale).code;
  const formattedOrderStatus = {
    [OrderPaymentStatusEnum.Paid]: <FormattedMessage defaultMessage="Paid" />,
    [OrderPaymentStatusEnum.Pending]: (
      <FormattedMessage defaultMessage="Pending" />
    ),
    [OrderPaymentStatusEnum.Refunded]: (
      <FormattedMessage defaultMessage="Refunded" />
    ),
    unknown: <FormattedMessage defaultMessage="Unknown" />,
  };

  const formattedFulfillmentOrderStatus = {
    [FulfillStatusEnum.Fulfilled]: (
      <FormattedMessage defaultMessage="Fulfilled" />
    ),
    [FulfillStatusEnum.PartiallyFulfilled]: (
      <FormattedMessage defaultMessage="Partially Fulfilled" />
    ),
    [FulfillStatusEnum.Unfulfilled]: (
      <FormattedMessage defaultMessage="Unfulfilled" />
    ),
    unknown: <FormattedMessage defaultMessage="Unknown" />,
  };
  const order = data?.order;

  const isShippingDiscount = !!order?.discounts?.find(
    (discount) => discount?.appliedOn === DiscountAppliedOnType.Shipping
  );

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <HeaderContainer>
        <div>
          <StyledLink>
            <Link href={`/me/my-orders/`}>
              <NavigateBack>
                <FlipOnRtl>
                  <LeftSmallArrow />
                </FlipOnRtl>
                <H5>
                  <FormattedMessage defaultMessage="Order History" />
                </H5>
              </NavigateBack>
            </Link>
          </StyledLink>
          <OrderHeader>
            <FormattedMessage defaultMessage="Order" /> #
            <FormattedNumber
              value={+order?.orderSerial!}
              minimumIntegerDigits={4}
              notation={+order?.orderSerial! < 1000 ? "compact" : "standard"}
            />
          </OrderHeader>
        </div>
        {order?.shipmentDetails?.trackingURL && (
          <PrimaryButton
            href={order?.shipmentDetails?.trackingURL}
            target="_blank"
          >
            <TruckIcon />
            <FormattedMessage defaultMessage="Track Shipment" />
          </PrimaryButton>
        )}
      </HeaderContainer>
      <Card paddingSize="none">
        <div style={{ overflowX: "auto" }}>
          <GridContainerHeader>
            <GridCell>
              <FormattedMessage defaultMessage="Product" />
            </GridCell>
            <GridCell>
              <FormattedMessage defaultMessage="Price" />
            </GridCell>
            <GridCell>
              <FormattedMessage defaultMessage="Qty." />
            </GridCell>
            <GridCell>
              <FormattedMessage defaultMessage="Total" />
            </GridCell>
          </GridContainerHeader>
          {order?.items?.map((item) => {
            if (!item) return null;
            const reviewsArrayByProductIds = order?.reviews?.map(
              (review) => review?.productId
            );
            const isItemReviewed = reviewsArrayByProductIds.includes(
              item?.productSnapshot?.id!
            );
            const customItem = item?.__typename === "CustomItem" ? item : null;

            return (
              <GridContainerBody key={item?.id}>
                <FlexRow spacing="s">
                  <ItemImg imgDetails={item?.productSnapshot?.images?.[0]} />
                  <ItemCell alignItems="flex-start">
                    {!!customItem?.discount?.perItem?.amount && (
                      <Badge
                        type="ink"
                        label={
                          <Flex alignItems="center" spacing="xs">
                            <CouponIcon />
                            <FormattedMessage
                              defaultMessage="SAVE {fixed}"
                              values={{
                                fixed: (
                                  <DefaultTextPrice
                                    money={customItem.discount.perItem}
                                  />
                                ),
                              }}
                            />
                          </Flex>
                        }
                      />
                    )}

                    {!!item?.discounts?.length && (
                      <Flex flexWrap="wrap" spacing="xs">
                        {item?.discounts?.map((discount, idx) => (
                          <Badge
                            key={idx}
                            type="ink"
                            label={
                              <Flex alignItems="center" spacing="xs">
                                <CouponIcon />
                                {getTranslatedDiscountTitle({
                                  defaultTitle: discount?.data?.title,
                                  translations: discount?.data?.translations,
                                  currentLocale,
                                })}
                              </Flex>
                            }
                          />
                        ))}
                      </Flex>
                    )}

                    <ItemTitle fontWeight="bold">{item?.title}</ItemTitle>
                    {item?.__typename === "SimpleItem" ? (
                      <>
                        <ItemVariants>
                          {item?.selectedOptions
                            ?.map((option) => option?.value)
                            .join(" / ")}
                        </ItemVariants>
                        {item?.variantSnapshot?.sku ? (
                          <H6 color="secondary">
                            <FormattedMessage
                              defaultMessage="SKU: {sku}"
                              values={{ sku: item?.variantSnapshot?.sku }}
                            />
                          </H6>
                        ) : null}
                      </>
                    ) : (
                      <ItemVariants>
                        <CartSummaryPanel
                          hideButtons
                          customItem={item as CustomItem}
                          customItemDiscount={item?.discount?.info}
                          isCustomDiscountApplied={
                            !!item?.discount?.info?.fixed?.amount ||
                            !!item?.discount?.info?.percentage
                          }
                        />
                      </ItemVariants>
                    )}
                  </ItemCell>
                </FlexRow>
                <ItemPriceAndQty item={item} />
                <TotalPrice
                  money={subtractMoney(
                    item?.subtotal!,
                    multiplyMoneyByNumber(
                      item?.discount?.perItem!,
                      item?.discount?.quantity
                    )
                  )}
                />
                {item?.__typename === "SimpleItem" && areReviewsActivated && (
                  <>
                    {isItemReviewed ? (
                      <Badge
                        label={<FormattedMessage defaultMessage="✔ Reviewed" />}
                      />
                    ) : (
                      <ReviewButton
                        orderId={order?.id}
                        product={{
                          id: item?.productSnapshot?.id!,
                          title: item?.title!,
                          images: item?.productSnapshot?.images!,
                        }}
                        buttonComponent={
                          <StyledButton prefixIcon={<MyReviewsIcon />} outlined>
                            <FormattedMessage defaultMessage="Review" />
                          </StyledButton>
                        }
                      />
                    )}
                  </>
                )}
              </GridContainerBody>
            );
          })}
        </div>
        <GridContainerRestInfo>
          <RestInfo>
            <RestInfoRow>
              <H4 fontWeight="bold">
                <FormattedMessage defaultMessage="Subtotal" />
              </H4>
              <PriceCell
                money={order?.receipt?.subtotal}
                style={{ fontSize: 14, opacity: 0.8 }}
              />
            </RestInfoRow>

            {!!order?.receipt?.automaticDiscount?.amount && (
              <RestInfoRow id="discount">
                <Template.elements.DiscountsBreakdown
                  orderItems={transformOrderItems(order)}
                />
              </RestInfoRow>
            )}

            {order?.receipt?.discount?.amount ? (
              <RestInfoRow>
                <H4 fontWeight="bold">
                  {order.promoCodeSnapshot?.type ===
                  PromoCodeTypeEnum.Percentage ? (
                    <>
                      <FormattedMessage defaultMessage="Coupon" />{" "}
                      <small style={{ fontWeight: "normal" }}>
                        ({order?.promoCodeSnapshot?.percentageOff}%)
                      </small>
                    </>
                  ) : (
                    <>
                      <FormattedMessage defaultMessage="Coupon" />{" "}
                      <small style={{ fontWeight: "normal" }}>
                        ({order?.promoCodeSnapshot?.fixedAmount}%)
                      </small>
                    </>
                  )}
                </H4>
                <DiscountedPrice>
                  -
                  <PriceCell
                    money={order?.receipt?.discount}
                    style={{ fontSize: 14 }}
                  />
                </DiscountedPrice>
              </RestInfoRow>
            ) : null}
            {order?.receipt?.tax?.amount ? (
              <RestInfoRow>
                <H4 fontWeight="bold">
                  <FormattedMessage defaultMessage="Estimated Taxes" />
                </H4>
                <PriceCell
                  money={order?.receipt?.tax}
                  style={{ fontSize: 14, opacity: 0.8 }}
                />
              </RestInfoRow>
            ) : null}
            <RestInfoRow style={{ marginBottom: 0 }}>
              <H4 fontWeight="bold">
                <FormattedMessage defaultMessage="Shipping" />{" "}
                <small style={{ fontWeight: "normal" }}>
                  ({order?.shippingRateName})
                </small>
              </H4>
              <ShippingNotes isShippingDiscount={isShippingDiscount}>
                {!order?.shippingRateCost?.amount ? (
                  <FormattedMessage defaultMessage="Free Shipping" />
                ) : (
                  <PriceCell
                    money={order?.shippingRateCost}
                    style={{ fontSize: 14 }}
                  />
                )}
              </ShippingNotes>
            </RestInfoRow>
            {isShippingDiscount && (
              <RestInfoRow id="appliedDiscount">
                <H4 />
                <DiscountedPrice>
                  {!order?.receipt?.shipping?.amount ? (
                    <div>
                      <FormattedMessage defaultMessage="Free Shipping" />
                    </div>
                  ) : (
                    <PriceCell
                      money={order?.receipt?.shipping}
                      style={{ fontSize: 14 }}
                    />
                  )}
                </DiscountedPrice>
              </RestInfoRow>
            )}
            <RestInfoRow>
              <H4 fontWeight="bold">
                <FormattedMessage defaultMessage="TOTAL" />
              </H4>
              <Price money={order?.receipt?.total} style={{ fontSize: 20 }} />
            </RestInfoRow>
          </RestInfo>
        </GridContainerRestInfo>
      </Card>

      <PaymentShippingInfo>
        <StyledFlexRow alignItems="flex-start">
          <FlexCol alignItems="flex-start" flexGrow={1} margin="0 0 10px 0">
            <H4Title>
              <FormattedMessage defaultMessage="Payment Status" />
            </H4Title>
            <H4>
              {formattedOrderStatus[order?.paymentStatus ?? "unknown"] ??
                order?.paymentStatus}
            </H4>
          </FlexCol>
          <FlexCol alignItems="flex-start" flexGrow={1} margin="0 0 10px 0">
            <H4Title>
              <FormattedMessage defaultMessage="Fulfillment Status" />
            </H4Title>
            <H4>
              {formattedFulfillmentOrderStatus[
                order?.fulfillmentStatus ?? "unknown"
              ] ?? order?.fulfillmentStatus}
            </H4>
          </FlexCol>
        </StyledFlexRow>

        <StyledFlexRow alignItems="flex-start">
          <FlexCol alignItems="flex-start" flexGrow={1} margin="0 0 10px 0">
            <H4Title>
              <FormattedMessage defaultMessage="Shipping address" />
            </H4Title>
            <H4>{order?.customer?.name}</H4>
            <H4>{order?.shippingAddress?.addressLine1}</H4>
            <H4>{order?.shippingAddress?.addressLine2}</H4>
            <H4>{order?.shippingAddress?.postalCode}</H4>
            <H4>{order?.shippingAddress?.areaSnapshot?.regionName}</H4>
            <H4>{order?.shippingAddress?.areaSnapshot?.cityName}</H4>
            <H4>{order?.shippingAddress?.areaSnapshot?.stateName}</H4>
            <H4>{order?.shippingAddress?.areaSnapshot?.countryName}</H4>
            <H4>{order?.customer?.phone}</H4>
          </FlexCol>

          <FlexCol alignItems="flex-start" flexGrow={1} margin="0 0 10px 0">
            <H4Title>
              <FormattedMessage defaultMessage="Billing address" />
            </H4Title>
            <H4>{order?.customer?.name}</H4>
            <H4>{order?.billingAddress?.addressLine1}</H4>
            <H4>{order?.billingAddress?.addressLine2}</H4>
            <H4>{order?.billingAddress.postalCode}</H4>
            <H4>{order?.shippingAddress?.areaSnapshot?.regionName}</H4>
            <H4>{order?.shippingAddress?.areaSnapshot?.cityName}</H4>
            <H4>{order?.shippingAddress?.areaSnapshot?.stateName}</H4>
            <H4>{order?.shippingAddress?.areaSnapshot?.countryName}</H4>
            <H4>{order?.customer?.phone}</H4>
          </FlexCol>
        </StyledFlexRow>
      </PaymentShippingInfo>
    </>
  );
};

export default OrderDetails;

const StyledLink = styled.div`
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const NavigateBack = styled(FlexRow)`
  color: ${themeColor("primary")};
  cursor: pointer;
  & > h5 {
    margin-inline-start: 5px;
    color: ${themeColor("primary")};
  }
`;
const FlipOnRtl = styled.div`
  transform: ${rtl(`rotate(180deg);`, "")};
`;

const OrderHeader = styled(FlexRow)`
  margin-top: 11px;
  font-weight: bold;
`;

const GridContainerBody = styled.div`
  display: grid;
  grid-template-columns: 35% 15% 15% 15% auto;
  padding: 20px;
  gap: 10px;
  border-bottom: solid 1px #dfe4e8;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 40% 20% 10% 20% auto;
  }
`;

const GridContainerHeader = styled(GridContainerBody)`
  & > h5 {
    color: ${({ theme }) => theme.text.default}80;
  }
`;

const GridCell = styled(H5)`
  margin: auto 0;
`;

const ItemCell = styled(FlexCol)`
  margin: auto 0;
`;

const ItemTitle = styled(H4)`
  color: ${themeColor("primary")};
`;

const ItemVariants = styled(H5)`
  opacity: 0.5;
`;

const PriceCell = styled(DefaultTextPrice)`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
  margin: auto 0;
`;

const TotalPrice = styled(PriceCell)`
  font-weight: bold;
`;

const GridContainerRestInfo = styled.div`
  padding: 20px;
  background-color: ${themeColor("backgroundReverse")};
  border-radius: 3px;
`;

const RestInfo = styled(FlexCol)``;

const RestInfoRow = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;

  /* discount title */
  &#discount > * > h4 {
    color: ${({ theme }) => theme.text.default};
    font-weight: bold;
  }

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }

  &:nth-last-child(2) {
    padding-bottom: 20px;
    border-bottom: 1px solid #dfe4e8;
  }
`;

const DiscountedPrice = styled(H4)`
  opacity: 0.8;
  color: ${({ theme }) => theme.success.medium};
  * {
    color: ${({ theme }) => theme.success.medium};
  }
`;

const ShippingNotes = styled(H4)<{ isShippingDiscount: boolean }>`
  opacity: 0.8;
  ${({ isShippingDiscount }) =>
    isShippingDiscount &&
    css`
      text-decoration: line-through;
    `}
`;

const StyledFlexRow = styled(FlexRow)`
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PaymentShippingInfo = styled.div`
  padding: 20px 0;
`;

const H4Title = styled(H4)`
  opacity: 0.5;
  margin-bottom: 8px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
  svg {
    margin-inline-end: 0.5rem;
  }
`;
