const ReviewsEmptyStateIcon = () => (
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.8682 17.655H49.4175C49.8468 17.655 50.2719 17.7396 50.6686 17.9039C51.0652 18.0682 51.4256 18.309 51.7291 18.6125C52.0327 18.9161 52.2735 19.2765 52.4378 19.6731C52.602 20.0697 52.6866 20.4948 52.6866 20.9241V54.6563C52.6866 55.0856 52.602 55.5107 52.4378 55.9073C52.2735 56.3039 52.0327 56.6643 51.7291 56.9679C51.4256 57.2714 51.0652 57.5122 50.6686 57.6765C50.2719 57.8408 49.8468 57.9253 49.4175 57.9253H39.8682V17.7066V17.655Z"
      fill="#75869B"
    />
    <path
      d="M39.8682 53.9447V57.8944H49.4175C50.2792 57.8944 51.1061 57.5543 51.7182 56.9479C52.3304 56.3415 52.6784 55.5179 52.6866 54.6563V38.476C52.6877 37.273 52.338 36.0958 51.6805 35.0885C51.023 34.0811 50.0861 33.2873 48.9844 32.8041L42.0854 29.7722L39.8682 53.9447Z"
      fill="#5F738C"
    />
    <path
      d="M16.5825 17.655H42.0854V56.0485C42.0854 56.2926 42.0369 56.5344 41.9429 56.7597C41.8488 56.985 41.711 57.1894 41.5374 57.3611C41.3638 57.5328 41.1579 57.6683 40.9315 57.7599C40.7052 57.8514 40.4629 57.8971 40.2188 57.8944H16.5825C15.7209 57.8944 14.894 57.5543 14.2818 56.9479C13.6696 56.3415 13.3216 55.5179 13.3135 54.6563V20.9138C13.3162 20.0486 13.6618 19.2197 14.2746 18.6089C14.8874 17.998 15.7173 17.655 16.5825 17.655Z"
      fill="#DFE8F4"
    />
    <path
      d="M42.0753 29.7722L35.1865 22.8732L31.9381 29.401L23.6675 30.2879L42.0753 48.706V29.7722Z"
      fill="#CCD8E5"
    />
    <path
      d="M26.6788 31.3809C24.78 31.0922 23.0499 30.1258 21.8082 28.6605C20.5666 27.1951 19.8973 25.3299 19.9241 23.4094V23.2031C19.9241 22.8667 20.0578 22.5441 20.2956 22.3062C20.5335 22.0683 20.8561 21.9347 21.1926 21.9347C21.529 21.9347 21.8516 22.0683 22.0895 22.3062C22.3274 22.5441 22.461 22.8667 22.461 23.2031V23.4403C22.4403 24.7673 22.9095 26.0554 23.779 27.0581C24.6485 28.0608 25.8572 28.7077 27.1738 28.875C27.8972 28.9482 28.628 28.8696 29.3194 28.6444C30.0108 28.4192 30.6476 28.0522 31.1891 27.567C31.7306 27.0818 32.165 26.4889 32.4644 25.8263C32.7639 25.1637 32.9218 24.4459 32.9282 23.7188V23.2031C32.9282 22.8667 33.0618 22.5441 33.2997 22.3062C33.5376 22.0683 33.8602 21.9347 34.1966 21.9347C34.534 21.9347 34.8577 22.068 35.0972 22.3056C35.3367 22.5432 35.4726 22.8658 35.4754 23.2031V23.7188C35.4672 24.8217 35.2247 25.9103 34.7641 26.9124C34.3035 27.9146 33.6352 28.8075 32.8035 29.5319C31.9719 30.2563 30.9958 30.7958 29.9399 31.1146C28.884 31.4333 27.7724 31.5241 26.6788 31.3809Z"
      fill="white"
    />
    <path
      d="M34.3303 8.10559C36.3909 8.11103 38.3655 8.932 39.8225 10.389C41.2795 11.8461 42.1005 13.8207 42.1059 15.8812V17.6343H39.5588V15.8812C39.4989 14.5334 38.9214 13.2606 37.9464 12.328C36.9715 11.3954 35.6743 10.8748 34.3252 10.8748C32.976 10.8748 31.6788 11.3954 30.7039 12.328C29.729 13.2606 29.1514 14.5334 29.0916 15.8812V17.6343H26.5547V15.8812C26.5574 13.8198 27.3775 11.8437 28.8351 10.386C30.2928 8.92841 32.2689 8.10832 34.3303 8.10559Z"
      fill="#75869B"
    />
    <path
      d="M29.0916 16.2113H26.5547V17.6344H29.0916V16.2113Z"
      fill="#5F738C"
    />
    <path
      d="M42.0955 16.2113H39.5586V17.6344H42.0955V16.2113Z"
      fill="#5F738C"
    />
    <path
      d="M28.1534 38.7131L27.7202 39.1462L27.2768 38.7131C26.956 38.3919 26.575 38.1371 26.1556 37.9633C25.7362 37.7895 25.2867 37.7 24.8327 37.7C24.3788 37.7 23.9292 37.7895 23.5099 37.9633C23.0905 38.1371 22.7095 38.3919 22.3887 38.7131C22.0678 39.033 21.8133 39.4132 21.6396 39.8317C21.4659 40.2502 21.3765 40.6989 21.3765 41.152C21.3765 41.6051 21.4659 42.0538 21.6396 42.4723C21.8133 42.8908 22.0678 43.2709 22.3887 43.5909L22.8321 44.0343L27.2768 48.4687C27.3336 48.5276 27.4016 48.5745 27.4769 48.6066C27.5522 48.6386 27.6332 48.6551 27.7151 48.6551C27.7969 48.6551 27.8779 48.6386 27.9532 48.6066C28.0285 48.5745 28.0966 48.5276 28.1534 48.4687L32.598 44.024L33.0415 43.5806C33.3623 43.2606 33.6169 42.8805 33.7906 42.462C33.9643 42.0435 34.0537 41.5948 34.0537 41.1417C34.0537 40.6885 33.9643 40.2399 33.7906 39.8214C33.6169 39.4029 33.3623 39.0227 33.0415 38.7028C32.72 38.3823 32.3384 38.1283 31.9187 37.9553C31.499 37.7824 31.0492 37.6939 30.5953 37.6948C30.1413 37.6958 29.692 37.7862 29.273 37.9609C28.854 38.1356 28.4735 38.3912 28.1534 38.7131Z"
      fill="#75869B"
    />
  </svg>
);

export { ReviewsEmptyStateIcon };
