import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAuth } from "../../../../../lib/Authentication";
import { H3, Label, P } from "../../../../../shared/globals";
import { Field, Form } from "react-final-form";
import createDecorator from "final-form-focus";
import { RequiredSpan } from "./styled";
import { useDeleteAccountMutation } from "../../../../../generated/graphql";
import Modal from "../../../../../shared/globals/UiElements/Modal";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import { Input } from "../../Input";
import { Delete } from "../../../../../assets/Icons";
import useBreakPoints from "../../../../../shared/utils/useBreakPoints";
import Button from "../../Button/Button";

type FormValues = {
  password: string;
};

const DeleteAccount = () => {
  const { logout } = useAuth();
  const focusOnError = createDecorator<FormValues>();
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<ReactElement | null>(null);
  const [deleteAccount] = useDeleteAccountMutation();

  const handleDelete = async (values: FormValues) => {
    try {
      await deleteAccount({
        variables: { password: values.password },
        onCompleted: () => {
          // essential since JWT keeps token even after erasing the cookie
          window.location.reload();
          logout();
        },
      });
    } catch (e) {
      setError(<FormattedMessage defaultMessage="Something Went Wrong!!" />);
      if (e.message === "Wrong Password") {
        setError(<FormattedMessage defaultMessage="Wrong Password" />);
      }
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setError(null);
  };
  const intl = useIntl();
  const { isTablet } = useBreakPoints();
  return (
    <>
      <Button
        data-test="button-delete-account"
        onClick={() => setIsOpen(true)}
        type="danger"
        size="xs"
      >
        <FormattedMessage defaultMessage="Delete Account" />
      </Button>

      <Modal
        overLayColor="rgba(16, 24, 40, .7)"
        show={isOpen}
        onClose={handleClose}
        modalWidth={isTablet ? "large" : "small"}
        closeIcon={
          <div className="text-gray-500 p-xs">
            <Delete />
          </div>
        }
        className="!rounded-xl !p-3xs "
      >
        <Form<FormValues>
          onSubmit={handleDelete}
          validate={validate}
          decorators={[focusOnError]}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Flex column spacing="xs" alignItems="flex-start">
                  <H3
                    className="!text-gray-900"
                    fontSize="18px"
                    fontWeight="600"
                  >
                    <FormattedMessage defaultMessage="Delete account" />
                  </H3>
                  <P
                    className="!text-gray-600"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    <FormattedMessage defaultMessage="Are you sure you want to delete this account?" />
                  </P>

                  <Label>
                    <FormattedMessage defaultMessage="Current password" />
                  </Label>
                  <Field name="password">
                    {({ input, meta: { error: fieldError, touched } }) => (
                      <>
                        <Input
                          {...input}
                          type="password"
                          className={
                            (fieldError && touched) || error ? "invalid" : ""
                          }
                          onChange={(event) => {
                            if (error) {
                              setError(null);
                            }
                            input.onChange(event);
                          }}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Enter your current password",
                          })}
                        />
                        {fieldError && touched && (
                          <RequiredSpan>{fieldError}</RequiredSpan>
                        )}
                        {error && (
                          <RequiredSpan>
                            <FormattedMessage defaultMessage="Invalid credentials" />
                          </RequiredSpan>
                        )}
                      </>
                    )}
                  </Field>
                </Flex>
                <Flex justifyContent="space-between" className="mt-2xl">
                  <Button fullWidth type="secondary" onClick={handleClose}>
                    <FormattedMessage defaultMessage="Cancel" />
                  </Button>
                  <Button
                    fullWidth
                    data-test="button-save-changes"
                    type="danger"
                    isLoading={submitting}
                  >
                    <FormattedMessage defaultMessage="Delete" />
                  </Button>
                </Flex>
              </Modal.Body>
            </form>
          )}
        />
      </Modal>
    </>
  );
};

export default DeleteAccount;

// Functions

const validate = (values: FormValues) => {
  const errors: Partial<Record<keyof FormValues, ReactElement>> = {};
  if (!values?.password?.trim()) {
    errors.password = (
      <FormattedMessage defaultMessage="Please enter your password" />
    );
  }
  return errors;
};
