import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { EcommerceReceipt } from "../../../../../assets/Icons";
import { H3, H4 } from "../../../../../shared/globals";
import AddAddress from "./AddAddress";
import Flex from "../../../../../shared/globals/UiElements/Flex";

const AddressesEmptyState = () => {
  return (
    <Flex column alignItems="center">
      <EcommerceReceipt />
      <Flex column alignItems="center" justifyContent="center" spacing="none">
        <H3 fontWeight="bold">
          <FormattedMessage defaultMessage="There are no addresses yet!" />
        </H3>
        <StyledH4>
          <FormattedMessage defaultMessage="Add your addresses and we will save it for you in next purchases" />
        </StyledH4>
        <AddAddress />
      </Flex>
    </Flex>
  );
};

export default AddressesEmptyState;

const StyledH4 = styled(H4)`
  color: ${({ theme }) => theme.brand.default};
  opacity: 0.8;
  margin: 10px 0px 20px 0px;
`;
