import React, { FC } from "react";
import styled from "styled-components";
import { PageHeaderProps } from "../../types";

const PageHeader: FC<PageHeaderProps> = ({ pageTitle }) => {
  return <StyledHeader>{pageTitle}</StyledHeader>;
};

const StyledHeader = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.4px;
  padding: 30px 0 20px 0;
  text-align: center;
`;

export default PageHeader;
