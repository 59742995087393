import * as React from "react";
import { ThemeProvider } from "styled-components";
import { CartProvider as OldCartProvider } from "../contexts/CartContext";
import { IntlProvider } from "react-intl";
import type { MainAppContext, MainAppProps } from "../lib/types";
import { getFonts } from "../lib/StoreFonts";
import { createTheme } from "../shared/theme";
import { GlobalStyle } from "../shared/theme/globalStyle";
import { LanguageType, SUPPORTED_LOCALES } from "../lib/i18n/locales-data";
import { getMainLayout } from "../shared/utils/layout";
import {
  getHostnameFromRequest,
  getStoreData,
  StoreContextType,
  StoreProvider,
} from "../lib/storeData";
import { getApolloClient } from "../lib/apollo/apollo-client";
import { ApolloProvider } from "@apollo/client";
import AnalyticsAccounts from "../lib/analytics-accounts/AnalyticsAccounts";
import { StoreTemplate } from "../templates/TemplateLoader";
import { messages } from "../lib/i18n";
import { getLanguageFromUrl } from "../lib/i18n/language-detect";
import { getCookie } from "../shared/utils/cookie";
import { ErrorBoundary } from "../lib/bugsnag";
import { Error } from "../components/Error";
import LoadingBar from "../components/LoadingBar/LoadingBar";
import { tryLogin } from "../lib/Authentication";
import { AuthState } from "../lib/apollo/client-graphql";
import { checkAndDoRedirect } from "../lib/i18n/storeRedirectsFunctions";
import logger from "../logger";
import { IntegrationScriptControls } from "../generated/graphql";
import useWidgetsStyles from "../hooks/useWidgetsStyles";
import { checkMaintenancePageCondition } from "../lib/checkMaintenancePageCondition";
import "../global.css";
import { CartContextType, CartProvider, getCartData } from "../lib/cartData";
import nookies from "nookies";
import { useRouter } from "next/router";
import { useCartData } from "../lib/cartData/useCartData";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { checkUrlAndRedirect } from "../shared/utils/checkUrlAndRedirect";
import NodeCache from "node-cache";

const cache = new NodeCache({ stdTTL: 60 });
const DefaultError = () => {
  logger.error("DefaultError");
  return <Error statusCode={500} />;
};

const Layout: any = ({ Component, pageProps }: MainAppProps) => {
  const getLayout = Component?.useLayout ?? getMainLayout;
  return getLayout(<Component {...pageProps} />);
};

type AppContextProps = MainAppProps & {
  children: React.ReactNode;
};

const SetCartRefCode = ({
  children,
  cartRefCode,
}: {
  children: React.ReactNode;
  cartRefCode?: string | null;
}) => {
  const { query } = useRouter();
  const {
    setCartRefCode: { setCartRefCode },
  } = useCartData();
  React.useEffect(() => {
    if (query.ref && cartRefCode !== (query.ref as string)) {
      setCartRefCode({
        refCode: query.ref as string,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{children}</>;
};

const AppContext: React.FC<AppContextProps> = ({ children, ...props }) => {
  const { storeData } = props;
  StoreTemplate.load(storeData?.template);
  return (
    <React.Fragment>
      <OldCartProvider>{children}</OldCartProvider>
      <AnalyticsAccounts
        storeIntegrations={
          storeData?.integrationScriptControls as IntegrationScriptControls[]
        }
        analyticalAccounts={storeData?.analyticalAccounts}
      />
    </React.Fragment>
  );
};

const AppProviders: React.FC<AppContextProps> = ({ children, ...props }) => {
  const { language, storeData, cartData, user, serverApolloClient } = props;
  const apolloClient =
    serverApolloClient || getApolloClient(user, language).apolloClient;
  const locale = SUPPORTED_LOCALES[language] || SUPPORTED_LOCALES.en;
  const isRTL = locale.dir === "rtl";
  const storeFonts = getFonts(storeData);
  const colors = storeData?.appearance?.colors;

  return (
    <ApolloProvider client={apolloClient}>
      <StoreProvider value={storeData}>
        <CartProvider initialCartData={cartData}>
          <IntlProvider locale={locale.locale} messages={messages[locale.code]}>
            <ThemeProvider
              theme={createTheme(
                colors,
                isRTL,
                storeFonts,
                storeData?.template
              )}
            >
              <GlobalStyle />
              {children}
            </ThemeProvider>
          </IntlProvider>
        </CartProvider>
      </StoreProvider>
    </ApolloProvider>
  );
};

function App(props: MainAppProps) {
  const { error } = props;
  useWidgetsStyles();
  if (error) {
    return (
      <AppProviders {...props}>
        <Error statusCode={error.code} />
      </AppProviders>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={DefaultError}>
      <AppProviders {...props}>
        <AppContext {...props}>
          <SetCartRefCode cartRefCode={props?.cartData?.refCode}>
            <div id="modal" />
            <LoadingBar />
            <Layout {...props} />
          </SetCartRefCode>
        </AppContext>
      </AppProviders>
    </ErrorBoundary>
  );
}

App.getInitialProps = async (appContext: MainAppContext) => {
  const isServer = typeof window === "undefined";
  const { ctx } = appContext;
  const { res } = ctx;
  const request = ctx.req;
  const reqHostname = getHostnameFromRequest(request);
  const token = getCookie("auth-token", request!);
  let error = null;
  let user: AuthState | undefined = undefined;
  const language =
    getLanguageFromUrl(request?.url! || ctx.asPath!) ||
    (getCookie("lang") as LanguageType) ||
    ("en" as LanguageType);
  if (request?.url?.includes("?prevUrl")) {
    res!.statusCode = 404;
    res!.end("404 Not Found: Invalid URL");
    return;
  }

  let storeData: StoreContextType | null = null;
  let cartData: CartContextType | null = null;
  try {
    if (isServer) {
      user = await tryLogin(token);
      const { apolloClient } = getApolloClient(user, language, true);
      ctx.apolloClient = apolloClient;
      ctx.token = token;
      const redirected = await checkUrlAndRedirect({
        hostname: reqHostname,
        pathname: request?.url,
        request,
        apolloClient,
        res,
        cache,
      });

      if (redirected) {
        return;
      }
      storeData = await getStoreData(
        cache,
        apolloClient,
        reqHostname,
        language
      );
      let { sessionId } = nookies.get(ctx);
      if (!sessionId && appContext?.router?.query?.ref) {
        const sessionUuid = `Session_${uuidv4()}`;
        // for the first time, we set the session id to the cookie
        sessionId = sessionUuid;
        nookies.set(ctx, "sessionId", sessionUuid, {
          maxAge: 30 * 24 * 60 * 60,
        });
      }

      if (sessionId) {
        cartData = await getCartData({
          apolloClient,
          storeId: storeData.id,
          sessionId,
        });
      }
      ctx.storeData = storeData;
      const url = new URL(request?.url!, "http://placeholder");
      const queryParams = Object.fromEntries(url.searchParams.entries());
      let isMaintenanceTest = false;
      const { isTestUser } = nookies.get(ctx);
      if (isTestUser) {
        isMaintenanceTest = true;
      } else if (_.get(queryParams, "maintenance") === "false") {
        isMaintenanceTest = true;
        // for the first time, we set the session id to the cookie
        nookies.set(ctx, "isTestUser", "true", {
          maxAge: 30 * 24 * 60 * 60,
          path: "/",
        });
      }

      checkMaintenancePageCondition(
        storeData.maintenanceMode?.isEnabled,
        ctx.pathname,
        res,
        isMaintenanceTest
      );
      checkAndDoRedirect(storeData.redirects, request?.url!, res!);
    } else {
      storeData = await getStoreData();
    }
  } catch (err) {
    logger.error(err, reqHostname);
    error = err;
    ctx.error = err;
  }
  return {
    error,
    user,
    storeData,
    cartData,
    language,
  };
};

export default App;
