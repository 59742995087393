const StoreClosed = () => {
  return (
    <svg width="71" height="61" viewBox="0 0 71 61">
      <defs>
        <linearGradient
          id="zs0ms3a7wb"
          x1="37.332%"
          x2="62.668%"
          y1="27.71%"
          y2="72.29%"
        >
          <stop offset="0%" stopColor="#F44F5A" />
          <stop offset="44.3%" stopColor="#EE3D4A" />
          <stop offset="100%" stopColor="#E52030" />
        </linearGradient>
        <linearGradient
          id="kfj2s4utec"
          x1="65.35%"
          x2="34.65%"
          y1="97.575%"
          y2="2.425%"
        >
          <stop offset="0%" stopColor="#4C5963" />
          <stop offset="100%" stopColor="#889097" />
        </linearGradient>
        <radialGradient
          id="99rwh4gf5a"
          cx="49.881%"
          cy="7.188%"
          r="154.285%"
          fx="49.881%"
          fy="7.188%"
          gradientTransform="matrix(.44001 0 0 1 .28 0)"
        >
          <stop offset="1.6%" stopColor="#999" />
          <stop offset="6.6%" stopColor="#999" />
          <stop offset="10.1%" stopColor="#A7A7A7" />
          <stop offset="16.4%" stopColor="#B8B8B8" />
          <stop offset="24.1%" stopColor="#C3C3C3" />
          <stop offset="34.9%" stopColor="#CACACA" />
          <stop offset="65.1%" stopColor="#CCC" />
          <stop offset="68.6%" stopColor="#C3C3C3" />
          <stop offset="80.1%" stopColor="#ACACAC" />
          <stop offset="90.8%" stopColor="#9E9E9E" />
          <stop offset="100%" stopColor="#999" />
        </radialGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="url(#99rwh4gf5a)"
                fillRule="nonzero"
                d="M62.046 26.533L35.2 5.242 8.354 26.533 6.366 24.026 35.2 1.158 64.034 24.026z"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="url(#zs0ms3a7wb)"
                fillRule="nonzero"
                d="M3.2 20.8h64c1.768 0 3.2 1.432 3.2 3.2v33.6c0 1.768-1.432 3.2-3.2 3.2h-64c-1.768 0-3.2-1.432-3.2-3.2V24c0-1.768 1.432-3.2 3.2-3.2z"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <g fill="#FFF" opacity=".2">
                <g>
                  <path
                    d="M10.954.172c.993-.29 2.323-.181 3.035.093.325.124.523.283.656.467.198.276.27.642.218 1.098-.092.799-1.123 2.954-2.169 5.192-1.535 3.285-1.878 3.925-3.074 7.24-1.196 3.315-2.085 6.34-2.667 9.071-.584 2.733-.814 4.959-.69 6.677.057.794.55 2.282 1.564 2.578 1.021.299 2.474-.701 3.495-2.578l-.05.087 2.15 3.527c-1.674 2.36-4.24 3.634-6.56 3.245l-.231-.044c-1.867-.407-3.07-1.813-3.127-1.888-1.339-1.733-1.46-3.858-1.656-3.822-.143.026.035 1.145-.12 1.181-.23.054-1.265-2.289-1.606-5.072-.234-1.909-.087-3.553.07-5.226.267-2.845.79-4.959 1.242-6.63l.046-.167.051-.182c.208-.73.7-2.385 1.3-3.936C4.548 6.642 7.94 1.055 10.953.173z"
                    transform="translate(-685 -453) translate(606 400) translate(79 53) translate(2 22)"
                  />
                  <path
                    d="M14.56 23.247c0-1.044.107-1.98.323-2.81.214-.828.52-1.487.92-1.98.398-.49.842-.736 1.333-.736.49 0 .75.292.782.875.03.584-.092 1.321-.368 2.21-.276.89-.66 1.842-1.15 2.855-.491 1.013-.981 1.95-1.471 2.809-.246-1.105-.368-2.179-.368-3.223zm3.22-11.42c1.962 0 3.357.66 4.185 1.98.462.736 1.173 2.199.966 4.881-.293 3.798-2.137 6.603-3.137 8.087-1.231 1.828-2.524 3.151-3.486 4.023.12.348.436 1.098 1.196 1.704.974.776 2.048.794 2.407.795 1.508.007 2.585-.84 3.756-1.762 1.048-.825 1.687-1.605 2.437-2.533.68-.84 1.54-2.01 2.397-3.516l-.096-.084 4.03.952-.122.024c-.764 1.528-1.623 2.899-2.58 4.111l-.363.447c-1.104 1.32-2.254 2.427-3.45 3.316-.856.636-1.93 1.423-3.541 2.025-.912.342-1.886.695-3.22.692-.568-.002-1.947-.024-3.443-.85-1.323-.729-2.082-1.726-2.443-2.282l-.007.01-2.202-3.793c-.797-1.67-1.195-3.54-1.195-5.61 0-2.149.322-4.159.966-6.032.644-1.872 1.564-3.437 2.76-4.697 1.196-1.257 2.59-1.887 4.185-1.887z"
                    transform="translate(-685 -453) translate(606 400) translate(79 53) translate(2 22)"
                  />
                  <path
                    d="M31.046 18.81c-.43 1.382-.827 2.38-1.195 2.993-.86-.92-1.38-2.132-1.564-3.638-.184-1.503-.154-2.96.092-4.374.244-1.411.674-2.608 1.287-3.591.613-.982 1.319-1.381 2.116-1.198.429.124.644.723.644 1.796 0 1.075-.123 2.334-.368 3.776-.246 1.443-.583 2.855-1.012 4.236zm.507-17.22c1.255-.338 2.437-.323 3.54.045 2.084.676 3.128 2.855 3.128 6.539 0 3.684-1.35 8.534-4.047 14.55.796-.429 1.61-1.059 2.437-1.888.828-.829 1.594-1.733 2.3-2.716.617-.86 1.256-1.692 1.931-2.901.089-.159.45-.812.984-1.652.683-1.076.959-1.375 1.362-1.571.408-.199 1.153-.391 1.55-.05.185.159.434.566.013 2.03-.452 1.574-1.28 2.646-2.575 4.512-.982 1.413-2.023 2.686-3.127 3.822-1.104 1.136-2.224 2.072-3.357 2.809-1.136.736-2.254 1.196-3.358 1.38l-.038.077-3.8-.923.026-.045c-.745-.64-1.842-1.717-2.789-3.295l-.187-.322c-.17-.305-2.92-5.379-1.214-11.008.792-2.615 2.41-4.912 2.116-5.112-.164-.11-.778.616-.85.552-.097-.086.84-1.617 2.367-2.9.712-.598 1.861-1.47 3.588-1.934z"
                    transform="translate(-685 -453) translate(606 400) translate(79 53) translate(2 22)"
                  />
                </g>
                <path
                  d="M46.968 9.606c-.024.236-.084.525-.293.822-.094.132-.188.266-.38.381-.47.281-1.058.175-1.172.154-.101-.019-.487-.098-.803-.368-.1-.084-.3-.27-.319-.5-.024-.316.315-.548.758-.867 1.505-1.08 1.616-1.283 1.843-1.217.51.15.395 1.3.366 1.595"
                  transform="translate(-685 -453) translate(606 400) translate(79 53) translate(2 22)"
                />
              </g>
              <path
                fill="url(#kfj2s4utec)"
                fillRule="nonzero"
                d="M35.2 0c1.76 0 3.2 1.44 3.2 3.2 0 1.76-1.44 3.2-3.2 3.2-1.76 0-3.2-1.44-3.2-3.2C32 1.44 33.44 0 35.2 0z"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="#000"
                fillRule="nonzero"
                d="M60.547 33.034H45.216v.521l-.402-.153c-.52-.197-1.088-.346-1.69-.44-.577-.092-1.217-.135-1.956-.135-.778 0-1.528.087-2.232.253-.766.184-1.46.483-2.066.888-.65.44-1.172 1.006-1.547 1.68-.064.115-.091.251-.145.373-.224-.338-.46-.667-.743-.968-1.395-1.477-3.222-2.226-5.429-2.226-2.315 0-4.219.776-5.652 2.309-1.413 1.502-2.128 3.462-2.128 5.822 0 .765.112 1.47.273 2.148h-2.083V33.034h-5.974v.344c-.996-.364-2.204-.55-3.59-.55-2.375 0-4.353.777-5.88 2.311-1.516 1.533-2.287 3.527-2.287 5.922 0 2.275.699 4.145 2.075 5.557 1.392 1.428 3.28 2.153 5.61 2.153 1.612 0 2.976-.257 4.072-.753v.539h10.537v-1.458c1.32 1.088 2.928 1.674 4.829 1.674 2.254 0 4.128-.767 5.568-2.279.154-.161.266-.35.403-.523v1.491l.872.447c.583.299 1.263.52 2.018.662.7.133 1.448.2 2.227.2.771 0 1.515-.077 2.208-.225.784-.167 1.49-.448 2.098-.842.387-.251.716-.563 1.014-.907v1.758h15.331c2.407 0 4.384-.728 5.879-2.163 1.516-1.45 2.288-3.386 2.288-5.754-.002-2.838-1.063-7.604-8.167-7.604zm-48.459 9.57c-.66.383-1.368.57-2.166.57-.693 0-1.184-.177-1.548-.563-.385-.408-.566-.965-.566-1.75 0-.829.194-1.413.61-1.837.406-.416.932-.61 1.656-.61.766 0 1.42.167 1.996.512l1.372.821v2.063l-1.354.793zm17.811.124c-.259.317-.56.446-1.037.446-.444 0-.737-.136-1.004-.465-.338-.421-.508-1.058-.508-1.898 0-.83.175-1.473.516-1.91.272-.346.58-.487 1.065-.487.522 0 .763.175.963.437.319.418.488 1.11.488 2.01-.001.832-.168 1.478-.483 1.867zM62 42.522c-.34.358-.802.544-1.443.577v-4.6c.669.034 1.157.21 1.488.538.373.371.547.893.547 1.637-.002.836-.189 1.424-.592 1.848z"
                opacity=".05"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="#000"
                fillRule="nonzero"
                d="M9.371 47.971c-2.105 0-3.8-.641-5.035-1.91-1.227-1.258-1.848-2.941-1.848-4.999 0-2.177.693-3.98 2.056-5.36 1.37-1.377 3.157-2.075 5.309-2.075 1.294 0 2.41.168 3.317.501l.523.194v4.643l-1.211-.725c-.704-.422-1.493-.627-2.408-.627-.94 0-1.67.278-2.23.85-.564.575-.836 1.36-.836 2.396 0 .99.256 1.743.784 2.301.517.547 1.214.813 2.128.813.942 0 1.782-.224 2.568-.682l1.203-.7v4.428l-.451.22c-1.006.486-2.307.732-3.869.732z"
                opacity=".07"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M12.894 46.52c-.9.434-2.072.653-3.521.653-1.891 0-3.378-.557-4.463-1.669-1.081-1.11-1.622-2.592-1.622-4.442 0-1.97.61-3.568 1.826-4.795 1.219-1.227 2.796-1.838 4.74-1.838 1.204 0 2.216.152 3.04.454v2.674c-.825-.493-1.763-.74-2.819-.74-1.157 0-2.091.365-2.8 1.092-.712.728-1.065 1.713-1.065 2.957 0 1.192.334 2.142 1.003 2.85.672.706 1.574 1.06 2.709 1.06 1.081 0 2.072-.264 2.97-.79l.002 2.534z"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="#000"
                fillRule="nonzero"
                d="M14.243 47.757L14.243 33.834 18.619 33.834 18.619 43.906 23.181 43.906 23.181 47.757z"
                opacity=".07"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M22.38 46.957h-7.337V34.634h2.775v10.072h4.563v2.25z"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="#000"
                fillRule="nonzero"
                d="M28.81 47.971c-1.981 0-3.628-.66-4.888-1.966-1.256-1.3-1.895-2.997-1.895-5.047 0-2.148.642-3.923 1.91-5.275 1.279-1.365 2.985-2.056 5.071-2.056 1.976 0 3.608.664 4.846 1.975 1.228 1.304 1.847 3.022 1.847 5.105 0 2.138-.642 3.9-1.906 5.234-1.281 1.35-2.958 2.03-4.985 2.03zm.121-10.355c-.731 0-1.269.251-1.694.79-.456.584-.688 1.394-.688 2.407 0 1.025.23 1.832.683 2.397.424.521.942.766 1.63.766.719 0 1.245-.235 1.658-.742.437-.543.66-1.34.66-2.37 0-1.09-.218-1.93-.65-2.496-.392-.514-.9-.752-1.599-.752z"
                opacity=".07"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M28.81 47.173c-1.764 0-3.204-.575-4.312-1.723-1.112-1.15-1.67-2.645-1.67-4.492 0-1.948.567-3.523 1.695-4.726 1.128-1.203 2.626-1.805 4.488-1.805 1.757 0 3.178.576 4.266 1.727 1.085 1.153 1.629 2.67 1.629 4.556 0 1.936-.564 3.498-1.688 4.684-1.13 1.185-2.596 1.779-4.408 1.779zm.121-10.357c-.974 0-1.747.366-2.321 1.096-.572.731-.86 1.7-.86 2.9 0 1.22.288 2.185.86 2.897.574.71 1.324 1.065 2.252 1.065.957 0 1.716-.345 2.279-1.035.56-.691.84-1.648.84-2.875 0-1.277-.272-2.27-.816-2.98-.544-.711-1.29-1.068-2.234-1.068z"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="#000"
                fillRule="nonzero"
                d="M39.898 47.971c-.728 0-1.428-.062-2.077-.185-.683-.128-1.29-.327-1.803-.59l-.436-.223v-4.954l1.314 1.101c.43.362.882.622 1.38.802.494.176.998.265 1.502.265.284 0 .528-.024.724-.072.172-.04.31-.094.408-.158.07-.042.112-.083.136-.123.02-.165.004-.216-.035-.27-.08-.108-.201-.22-.36-.326-.211-.144-.456-.273-.737-.403-.312-.14-.655-.291-1.028-.443-1.083-.45-1.912-1.022-2.467-1.7-.589-.72-.89-1.594-.89-2.604 0-.78.167-1.47.492-2.046.312-.564.75-1.039 1.299-1.41.523-.35 1.13-.61 1.805-.771.638-.154 1.326-.232 2.043-.232.696 0 1.294.04 1.832.125.55.088 1.064.222 1.531.398l.515.197v4.653l-1.257-.877c-.167-.115-.349-.216-.546-.304-.206-.093-.419-.167-.638-.229-.223-.062-.445-.107-.663-.138-.217-.028-.422-.044-.617-.044-.25 0-.479.024-.679.07-.217.05-.344.109-.41.152-.08.048-.132.098-.166.147l.012.19c.054.084.145.17.267.258.171.127.376.242.614.36.27.133.578.272.92.411.538.226 1.005.458 1.434.712.448.266.843.573 1.174.916.359.372.63.798.815 1.267.184.462.28 1.003.28 1.601 0 .823-.167 1.535-.493 2.12-.327.584-.773 1.061-1.328 1.42-.523.339-1.14.585-1.829.73-.635.137-1.322.207-2.038.207z"
                opacity=".07"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M36.38 46.485v-2.75c.502.417 1.04.73 1.626.94.586.21 1.175.312 1.772.312.347 0 .652-.032.912-.094.262-.063.478-.15.652-.263.175-.11.306-.243.394-.395.085-.152.128-.315.128-.494 0-.24-.069-.455-.206-.645-.138-.187-.325-.363-.564-.523-.238-.16-.521-.315-.846-.464-.328-.149-.678-.301-1.056-.456-.963-.4-1.682-.891-2.154-1.469-.475-.578-.708-1.278-.708-2.098 0-.641.128-1.193.387-1.654.256-.46.606-.84 1.05-1.14.443-.297.96-.514 1.543-.655.584-.141 1.204-.211 1.856-.211.64 0 1.21.038 1.706.115.494.078.954.197 1.371.358v2.568c-.206-.144-.432-.269-.675-.377-.243-.11-.494-.199-.754-.27-.256-.073-.515-.126-.768-.16-.256-.034-.497-.052-.724-.052-.316 0-.604.03-.86.091-.259.06-.478.144-.652.253-.178.109-.316.238-.413.39-.101.152-.147.322-.147.512 0 .207.052.39.163.554.109.165.262.318.465.464.2.147.444.29.728.43.288.141.613.287.972.434.494.206.934.426 1.328.658.39.232.728.494 1.01.785.28.291.497.627.642 1.002.15.374.226.811.226 1.31 0 .688-.131 1.264-.39 1.731-.263.468-.616.845-1.063 1.135-.446.29-.966.497-1.56.622-.593.127-1.219.19-1.878.19-.675 0-1.319-.057-1.928-.17-.608-.114-1.136-.285-1.584-.514z"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="#000"
                fillRule="nonzero"
                d="M46.016 47.757L46.016 33.834 54.722 33.834 54.722 37.694 50.39 37.694 50.39 38.834 54.422 38.834 54.422 42.688 50.39 42.688 50.39 43.906 55.006 43.906 55.006 47.757z"
                opacity=".07"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M54.206 46.957h-7.39V34.634h7.106v2.26H49.59v2.741h4.032v2.251H49.59v2.82h4.616v2.25z"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="#000"
                fillRule="nonzero"
                d="M55.38 47.757V33.835h5.167c6.088 0 7.367 3.701 7.367 6.807 0 2.139-.687 3.881-2.04 5.174-1.341 1.288-3.133 1.94-5.325 1.94H55.38zm4.951-3.851c.981 0 1.719-.274 2.25-.834.545-.574.81-1.358.81-2.397 0-.96-.257-1.68-.783-2.205-.526-.521-1.277-.776-2.293-.776h-.56v6.213l.576-.001z"
                opacity=".07"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M56.18 46.957V34.634h4.367c4.375 0 6.567 2.001 6.567 6.008 0 1.918-.597 3.45-1.794 4.596-1.194 1.146-2.784 1.719-4.771 1.719H56.18zm2.777-10.063v7.812h1.374c1.203 0 2.147-.362 2.832-1.084.685-.721 1.029-1.704 1.029-2.947 0-1.174-.34-2.099-1.02-2.771-.678-.672-1.631-1.01-2.855-1.01h-1.36z"
                transform="translate(-685 -453) translate(606 400) translate(79 53)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export { StoreClosed };
