const HelpIcon = ({
  width = 16,
  height = 16,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 16 17"
  >
    <path
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="M6.06 6.5a2 2 0 0 1 3.886.667c0 1.333-2 2-2 2M8 11.833h.006m6.66-3.333a6.667 6.667 0 1 1-13.333 0 6.667 6.667 0 0 1 13.333 0Z"
    />
  </svg>
);

export { HelpIcon };
