import React from "react";
import Modal from "../../../../../shared/globals/UiElements/Modal";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-focus";
import { H2, P, Label } from "../../../../../shared/globals";
import { FormattedMessage, useIntl } from "react-intl";
import { RequiredSpan } from "./styled";
import type { FormValues } from "./types";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import { Input } from "../../Input";
import { Delete } from "../../../../../assets/Icons";
import useBreakPoints from "../../../../../shared/utils/useBreakPoints";
import Button from "../../Button/Button";

const focusOnError = createDecorator();

interface EditNameModalProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  onSubmit: (values: FormValues) => void;
  error?: React.ReactNode;
  children?: React.ReactNode;
}
const EditEmailModal: React.FC<EditNameModalProps> = ({
  isOpen,
  onClose,
  email,
  onSubmit,
  error,
}) => {
  const intl = useIntl();
  const { isTablet } = useBreakPoints();
  return (
    <Modal
      overLayColor="rgba(16, 24, 40, .7)"
      show={isOpen}
      onClose={onClose}
      modalWidth={isTablet ? "large" : "small"}
      closeIcon={
        <div className="text-gray-500 p-xs">
          <Delete />
        </div>
      }
      className="!rounded-xl !p-3xs"
    >
      <Modal.Body>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          decorators={[focusOnError]}
          initialValues={{ email }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Flex column spacing="xs">
                <H2 className="!text-gray-900" fontSize="18px" fontWeight="600">
                  <FormattedMessage defaultMessage="Change your e-mail address" />
                </H2>
                <P className="!text-gray-600" fontSize="14px" fontWeight="400">
                  <FormattedMessage defaultMessage="Update your email address." />
                </P>
              </Flex>
              <Label className="text-gray-700 text-sm font-medium">
                <FormattedMessage defaultMessage="New e-mail address" />
              </Label>
              <Field name="email" type="email">
                {({ input, meta: { error, touched } }) => (
                  <>
                    <Input
                      {...input}
                      className={error && touched ? "invalid" : ""}
                      data-test="type-email"
                      placeholder={intl.formatMessage({
                        defaultMessage: "Enter your new email",
                      })}
                    />
                    {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                  </>
                )}
              </Field>
              <Label className="text-gray-700 text-sm font-medium">
                <FormattedMessage defaultMessage="Current password" />
              </Label>
              <Field name="currentPassword">
                {({ input, meta: { error, touched } }) => (
                  <>
                    <Input
                      {...input}
                      className={error && touched ? "invalid" : ""}
                      data-test="type-password"
                      type="password"
                      placeholder={intl.formatMessage({
                        defaultMessage: "Enter your current password",
                      })}
                    />
                    {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                  </>
                )}
              </Field>
              {error && <RequiredSpan>{error}</RequiredSpan>}
              <Flex justifyContent="space-between" className="mt-2xl">
                <Button fullWidth type="secondary" onClick={onClose}>
                  <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button
                  fullWidth
                  data-test="button-save-changes"
                  isLoading={submitting}
                  htmlType="submit"
                >
                  <FormattedMessage defaultMessage="Save Changes" />
                </Button>
              </Flex>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EditEmailModal;

const validate = (values: FormValues) => {
  const errors: Partial<{ [k in keyof FormValues]: React.ReactElement }> = {};
  if (!values?.email) {
    errors.email = <FormattedMessage defaultMessage="Enter a valid email" />;
  }
  if (!values?.currentPassword) {
    errors.currentPassword = (
      <FormattedMessage defaultMessage="Enter your password" />
    );
  }
  return errors;
};
