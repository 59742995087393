import type { NavBarProps, TemplateElement } from "../../types";
import DesktopNavbar from "../components/NavBar/DesktopNavbar";
import MobileNavbar from "../components/NavBar/MobileNavbar";
import { StoreTemplate } from "../../TemplateLoader";

const NavBar: TemplateElement<NavBarProps> = ({
  menu,
  socialMedia,
  itemsTotal,
  itemsCount,
  toggleSideCart,
}) => {
  const Template = StoreTemplate.get();

  return (
    <>
      <Template.elements.SideCart />
      <Template.elements.CartPopup />
      <MobileNavbar menu={menu} socialMedia={socialMedia} />
      <DesktopNavbar
        menu={menu}
        itemsTotal={itemsTotal}
        itemsCount={itemsCount}
        toggleSideCart={toggleSideCart}
      />
    </>
  );
};

export default NavBar;
