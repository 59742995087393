import React from "react";

export const TrashIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M20.6669 7.92087H21.778"
        stroke="#CC0905"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6668 12.2989V19.0322"
        stroke="#CC0905"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0007 11.0098V20.1987"
        stroke="#CC0905"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.3338 12.2989V19.0322"
        stroke="#CC0905"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2172 23.4133H9.78386C8.43053 23.4133 7.33386 22.3167 7.33386 20.9633V7.92H20.6672V20.9633C20.6672 22.3167 19.5705 23.4133 18.2172 23.4133Z"
        stroke="#CC0905"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.4444 7.91935L17.6244 5.35824C17.4767 4.89824 17.0489 4.58601 16.5667 4.58601H11.4333C10.95 4.58601 10.5222 4.89824 10.3755 5.35824L9.55554 7.91935"
        stroke="#CC0905"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.22253 7.92087H7.33365"
        stroke="#CC0905"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
