import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { Delete } from "../../../../../assets/Icons";
import { useRouter } from "../../../../../lib/i18n";
import { rtl } from "../../../../../shared/styles-utils";
import { useEffect, useState } from "react";
import { useStore } from "../../../../../lib/storeData";
import {
  ProductAttributeValue,
  ProductCollection,
  useGetFiltersQuery,
  ProductOptionValue,
} from "../../../../../generated/graphql";
import { getLocaleInfo } from "../../../../../lib/i18n/locales-data";
import ClearAllFilters from "./ClearAllFilters";
import {
  FiltersDictionary,
  getAvailableFilters,
} from "../../../../../shared/utils/filterHelperFunctions";

const FilterTags = () => {
  const [filtersDictionary, setFiltersDictionary] =
    useState<FiltersDictionary>();
  const router = useRouter();
  const { storeId } = useStore();
  const intl = useIntl();
  const { locale } = useIntl();
  const selectedFiltersIds =
    typeof router.query.filters === "string"
      ? [router.query.filters]
      : router.query.filters || [];
  const activeLocale = getLocaleInfo(locale).code;

  const { data, loading } = useGetFiltersQuery({
    variables: {
      storeId,
    },
  });

  useEffect(() => {
    if (data) {
      const { filtersDictionary: temp } = getAvailableFilters({
        attributesProductsDistribution: data?.getApplicableFilters
          ?.attributeValues! as ProductAttributeValue[],
        collectionsProductsDistribution: data?.getApplicableFilters
          ?.collections as ProductCollection[],
        optionsProductsDistribution: data?.getApplicableFilters
          ?.optionValues as ProductOptionValue[],
        intl,
      });
      setFiltersDictionary(temp);
    }
  }, [data, intl]);

  if (
    !(router.query.filters || router.query.minPrice || router.query.maxPrice) ||
    router.route.includes("collection")
  ) {
    return null;
  }
  const removeFilter = (filter: string) => {
    const newFilters = { ...router.query };
    if (typeof newFilters.filters === "string") {
      newFilters.filters = [newFilters.filters];
    }

    if (filter === "minPrice" || filter === "maxPrice") {
      newFilters[filter] = undefined;
    } else {
      newFilters.filters = (newFilters.filters as string[])?.filter(
        (f: string) => f !== filter
      ) as string[];
    }
    if (
      !newFilters?.minPrice?.length &&
      !newFilters?.maxPrice?.length &&
      !newFilters?.filters?.length &&
      !router.query.query
    ) {
      router.push({ pathname: `/${activeLocale}/shop`, query: null });
      return;
    }
    router.push(
      {
        pathname: router.asPath?.split("?")[0],
        query: newFilters,
      },
      undefined,
      { scroll: false }
    );
  };

  const getSelectedFiltersNames = () => {
    const selectedFiltersNames: {
      filterName: string;
      filterValue: string;
    }[] = [];

    if (!filtersDictionary) return [];

    for (const [, value] of Object.entries(filtersDictionary)) {
      value.forEach((filter) => {
        if (
          selectedFiltersIds.includes(filter.valueId) ||
          selectedFiltersIds?.includes(
            filter?.valueId! + "|" + filter?.propertyId!
          )
        )
          selectedFiltersNames.push({
            filterName: filter.valueName,
            filterValue: filter.valueId.startsWith("c:")
              ? filter.valueId
              : filter.valueId + "|" + filter.propertyId,
          });
      });
    }
    return selectedFiltersNames;
  };

  return (
    <TagsContainer>
      {router.query.minPrice && (
        <FilterTag>
          <FormattedMessage defaultMessage="Min Price: " />{" "}
          {router.query.minPrice}
          <Div onClick={() => removeFilter("minPrice")}>
            <Delete />
          </Div>
        </FilterTag>
      )}
      {router.query.maxPrice && (
        <FilterTag>
          <FormattedMessage defaultMessage="Max Price" />{" "}
          {router.query.maxPrice}
          <Div onClick={() => removeFilter("maxPrice")}>
            <Delete />
          </Div>
        </FilterTag>
      )}
      {getSelectedFiltersNames().map((item) => (
        <FilterTag title={item.filterName} key={item.filterValue}>
          <FilterName>{item.filterName}</FilterName>
          <Div onClick={() => removeFilter(item.filterValue)}>
            <Delete />
          </Div>
        </FilterTag>
      ))}
      <ClearAllFilters loading={loading} plain />
    </TagsContainer>
  );
};
export default FilterTags;

const TagsContainer = styled.div`
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  width: 100vw;
  gap: 10px;
  margin-left: 20px;
  white-space: nowrap;
`;

const FilterTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafbfc;
  border: 1px solid #dfe3e8;
  border-radius: 4px;
  height: calc(100% - 2px);
  padding: 0 10px;
  color: #5f738c;
  font-size: 14px;
  text-overflow: ellipsis;
  padding: 10px;
  font-weight: 600;
  flex-direction: ${() => rtl("row-reverse", "row")};
  svg {
    margin-left: 5px;
    color: #252a31;
    stroke: #252a31;
    width: 10px;
  }
`;

const FilterName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
`;

const Div = styled.div`
  cursor: pointer;
`;
