import Select, { StylesConfig } from "react-select";
import { FormattedMessage } from "react-intl";
import { NextRouter } from "next/router";
import { useRouter } from "../../../../lib/i18n";
import { SortOrder } from "../../../../generated/graphql";
import { FlexRow, P } from "../../../../shared/globals";
import { sortOptions } from "../../../default/components/Filter/consts";
import { ZIndex } from "../../../../shared/globals/types";

export const Sorting = () => {
  const router = useRouter();

  const handleChange = (selectedOption: {
    value: { sortBy: string; sortOrder: SortOrder };
  }) => {
    updateQuerySorts(router, {
      ...selectedOption.value,
    });
  };

  const sort = {
    sortBy: router.query.sortBy,
    sortOrder: router.query.sortOrder,
  };

  return (
    <FlexRow margin="20px 0 0">
      <P fontSize="16px" fontWeight={600}>
        <FormattedMessage defaultMessage="Sort by:" />
      </P>
      <Select
        instanceId="select-sorting"
        options={sortOptions}
        onChange={handleChange}
        styles={customStyles}
        defaultValue={sortOptions.find(
          (option) => option.key === `${sort.sortBy}-${sort.sortOrder}`
        )}
        isSearchable={false}
        placeholder={<FormattedMessage defaultMessage="Default Sorting" />}
        components={{ IndicatorSeparator: () => null }}
      />
    </FlexRow>
  );
};

// Functions

function updateQuerySorts(
  router: NextRouter,
  newFilters: { sortBy: string; sortOrder: SortOrder }
) {
  router.push({
    pathname: router.asPath.split("?")[0],
    query: {
      ...router.query,
      ...newFilters,
    },
  });
}
const secondaryColor = "#525252"; // theme.colors.secondary
const customStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    border: "none",
    fontWeight: "500",
    cursor: "pointer",
    boxShadow: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: secondaryColor,
    fontWeight: 400,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: secondaryColor,
    fontWeight: 400,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "4px",
    boxShadow: "0px 4px 10px 0px rgba(182, 182, 182, 0.25)",
    backgroundColor: "white",
    zIndex: ZIndex["DROPDOWN"],
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "1.43",
    color: secondaryColor,
    backgroundColor: "white",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#F5F5F5",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: secondaryColor,
  }),
};
