import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { PrimaryButton } from "../../../../../components/Button";
import { useAuth } from "../../../../../lib/Authentication";
import {
  FlexCol,
  FlexRow,
  H3,
  Input,
  Label,
  P,
} from "../../../../../shared/globals";
import { Field, Form } from "react-final-form";
import createDecorator from "final-form-focus";
import { CancelButton, RequiredSpan, SaveButton } from "./styled";
import { useDeleteAccountMutation } from "../../../../../generated/graphql";
import Modal from "../../../../../shared/globals/UiElements/Modal";
import { Delete } from "../../../../../assets/Icons";

type FormValues = {
  password: string;
};

const DeleteAccount = () => {
  const { logout } = useAuth();
  const focusOnError = createDecorator<FormValues>();
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<ReactElement | null>(null);
  const [deleteAccount] = useDeleteAccountMutation();

  const handleDelete = async (values: FormValues) => {
    try {
      await deleteAccount({
        variables: { password: values.password },
        onCompleted: () => {
          // essential since JWT keeps token even after erasing the cookie
          window.location.reload();
          logout();
        },
      });
    } catch (e) {
      setError(<FormattedMessage defaultMessage="Something Went Wrong!!" />);
      if (e.message === "Wrong Password") {
        setError(<FormattedMessage defaultMessage="Wrong Password" />);
      }
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setError(null);
  };

  return (
    <>
      <DeleteAccountButton
        data-test="button-delete-account"
        onClick={() => setIsOpen(true)}
      >
        <FormattedMessage defaultMessage="Delete account" />
      </DeleteAccountButton>

      <Modal
        show={isOpen}
        onClose={handleClose}
        modalWidth="small"
        closeIcon={<Delete />}
      >
        <Modal.Header>
          <H3 fontSize="18px" fontWeight={700}>
            <FormattedMessage defaultMessage="Delete account" />
          </H3>
        </Modal.Header>

        <Form<FormValues>
          onSubmit={handleDelete}
          validate={validate}
          decorators={[focusOnError]}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <FlexCol alignItems="flex-start">
                  <P fontSize="16px" fontWeight={500}>
                    <FormattedMessage defaultMessage="Are you sure you want to delete this account?" />
                  </P>

                  <Label>
                    <FormattedMessage defaultMessage="Current password" />
                  </Label>
                  <Field name="password">
                    {({ input, meta: { error: fieldError, touched } }) => (
                      <>
                        <Input
                          {...input}
                          type="password"
                          className={
                            (fieldError && touched) || error ? "invalid" : ""
                          }
                          onChange={(event) => {
                            if (error) {
                              setError(null);
                            }
                            input.onChange(event);
                          }}
                        />
                        {fieldError && touched && (
                          <RequiredSpan>{fieldError}</RequiredSpan>
                        )}
                        {error && (
                          <RequiredSpan>
                            <FormattedMessage defaultMessage="Invalid credentials" />
                          </RequiredSpan>
                        )}
                      </>
                    )}
                  </Field>
                </FlexCol>
              </Modal.Body>

              <Modal.Footer>
                <ButtonsContainer>
                  <CancelButton type="button" onClick={handleClose}>
                    <FormattedMessage defaultMessage="Cancel" />
                  </CancelButton>
                  <DeleteButton
                    data-test="button-save-changes"
                    type="submit"
                    isLoading={submitting}
                  >
                    <FormattedMessage defaultMessage="Delete" />
                  </DeleteButton>
                </ButtonsContainer>
              </Modal.Footer>
            </form>
          )}
        />
      </Modal>
    </>
  );
};

export default DeleteAccount;

// Functions

const validate = (values: FormValues) => {
  const errors: Partial<Record<keyof FormValues, ReactElement>> = {};
  if (!values?.password?.trim()) {
    errors.password = (
      <FormattedMessage defaultMessage="Please enter your password" />
    );
  }
  return errors;
};

// Styles

const DeleteAccountButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.danger.default};
  border-color: ${({ theme }) => theme.danger.default};
`;

const ButtonsContainer = styled(FlexRow)`
  gap: 12px;
  justify-content: flex-end;
  align-items: stretch;
`;

const DeleteButton = styled(SaveButton)`
  background-color: ${({ theme }) => theme.danger.default};
  border-color: ${({ theme }) => theme.danger.default};
`;
