const MarkerOutlined = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 20.833V20.833C18.6192 20.833 17.5 19.7138 17.5 18.333V18.333C17.5 16.9522 18.6192 15.833 20 15.833V15.833C21.3808 15.833 22.5 16.9522 22.5 18.333V18.333C22.5 19.7138 21.3808 20.833 20 20.833Z"
        stroke="#475467"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.0001 27.5C20.0001 27.5 14.1667 22.7083 14.1667 18.3333C14.1667 15.1117 16.7784 12.5 20.0001 12.5C23.2217 12.5 25.8334 15.1117 25.8334 18.3333C25.8334 22.7083 20.0001 27.5 20.0001 27.5Z"
        stroke="#475467"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { MarkerOutlined };
