import { Section } from "../../../shared/layout";
import type { CartPageProps } from "../../types";
import Cart from "../components/Cart";

const CartPage: React.FC<CartPageProps> = () => {
  return (
    <Section background={"backgroundReverse"}>
      <Cart />
    </Section>
  );
};

export default CartPage;
