const CreditCards = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="20"
      fill="none"
      viewBox="0 0 61 20"
    >
      <path
        fill="#1565C0"
        d="M28 17.333C28 18.806 26.806 20 25.333 20H2.667C1.194 20 0 18.806 0 17.333V2.667C0 1.194 1.194 0 2.667 0h22.666C26.806 0 28 1.194 28 2.667v14.666z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M16.863 8.1c-.952 0-1.284.3-1.284.704 0 .317.426.528.937.78.765.379 1.72.85 1.72 1.914 0 1.475-1.904 2.501-3.277 2.501-1.37 0-2.078-.404-2.078-.404l.33-1.477s.731.406 1.84.406c1.104 0 1.258-.449 1.258-.643 0-.472-.462-.695-1.001-.954-.752-.362-1.652-.796-1.652-2.08 0-1.218 1.038-2.181 3.051-2.181.889 0 1.794.343 1.794.343l-.31 1.54s-.71-.45-1.328-.45zm-8.74-1.433l-1.75 5.221s-.445-2.209-.489-2.486c-.996-2.274-2.467-2.147-2.467-2.147L5.15 14v-.001h2.108l2.914-7.332H8.124zM9.794 14h1.913l1.158-7.333h-1.938L9.792 14zm11.531-7.333h2.014L24.821 14h-1.742l-.204-1.047h-2.397L20.086 14h-1.902l3.14-7.333zm-.316 4.885l1.042-2.771.546 2.771h-1.588z"
        clipRule="evenodd"
      />
      <path
        fill="#FFC107"
        d="M6.141 10.63l-.644-3.165s-.291-.686-1.049-.686h-2.96s3.774 1.114 4.653 3.851z"
      />
      <path
        fill="#3F51B5"
        d="M61 17.333C61 18.806 59.806 20 58.333 20H35.667C34.194 20 33 18.806 33 17.333V2.667C33 1.194 34.194 0 35.667 0h22.666C59.806 0 61 1.194 61 2.667v14.666z"
      />
      <path
        fill="#FFC107"
        d="M51 3.333c-3.682 0-6.667 2.985-6.667 6.667s2.985 6.667 6.667 6.667 6.666-2.985 6.666-6.667S54.682 3.333 51 3.333z"
      />
      <path
        fill="#FF3D00"
        d="M45.676 14c-.309-.411-.575-.856-.784-1.333h3.55c.186-.424.331-.87.425-1.334h-4.399c-.088-.43-.134-.876-.134-1.333H49c0-.457-.046-.903-.134-1.333h-4.398c.094-.464.239-.91.424-1.334h3.55c-.208-.477-.474-.922-.784-1.333h-1.982c.292-.387.62-.748.988-1.063-1.166-.998-2.676-1.604-4.33-1.604-3.682 0-6.667 2.985-6.667 6.667s2.985 6.667 6.667 6.667c2.179 0 4.108-1.05 5.324-2.667h-1.982z"
      />
    </svg>
  );
};

export { CreditCards };
