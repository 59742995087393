import styled from "styled-components";
import { PrimaryButton } from "../../Button";
import { themeColor, rtl } from "../../../shared/styles-utils";
import { Label, Input, FlexCol } from "../../../shared/globals";
import { Card } from "../../../shared/globals/UiElements/Card";

export const StyledForm = styled.form`
  width: 100%;
`;

export const ContactSection = styled.div`
  width: 100%;
  margin-bottom: 35px;
`;

export const ShippingSection = styled.div`
  margin-bottom: 30px;
`;

export const RouteSection = styled(FlexCol)<{ isRecoveryCart?: boolean }>`
  width: 100%;
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: ${({ isRecoveryCart }) =>
      isRecoveryCart ? "end" : "space-between"};
  }
`;

export const GoBackButton = styled.button`
  display: flex;
  background-color: inherit;
  align-items: center;
  justify-content: center;
  color: ${themeColor("primary")};
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;

  div {
    display: flex;
    transform: scaleX(${rtl("-1", "1")});
    padding-right: 8px;
  }
`;

export const ContinueButton = styled(PrimaryButton)`
  margin-bottom: 40px;
  padding: 12px 34px;
  width: 100%;
  @media (min-width: 768px) {
    margin-bottom: 0;
    width: auto;
    flex: 0 0 auto;
  }
  div {
    ${rtl("padding-right", "padding-left")}: 8px;
    display: flex;
    transform: scaleX(${rtl("-1", "1")});
  }
`;

export const CheckboxLabel = styled(Label)`
  margin-top: 0.5rem;
  width: fit-content;
  position: relative;
  ${rtl(
    "padding-right: calc(16px + 0.5rem)",
    "padding-left: calc(16px + 0.5rem)"
  )};
  cursor: pointer;
  user-select: none;

  span:after {
    top: 1px;
    left: 5px;
    width: 5px;
    height: 10px;
    border: 5px solid ${themeColor("primary")};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

export const CheckboxInput = styled(Input)`
  display: none;
  margin: 0;
  &:checked ~ span:after {
    display: block;
  }
`;

// the styled input
export const Checkmark = styled.span`
  background-color: ${themeColor("white")};
  height: 17px;
  width: 17px;
  border: 1px solid ${themeColor("primary")};
  border-radius: 4px;
  position: absolute;
  top: 0;
  ${rtl("right", "left")}: 0;
  /* the tick mark */
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export const DesktopRES = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

export const MobileRES = styled.div`
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const RequiredSpan = styled.span`
  font-size: 12px;
  color: #d21c1c;
  font-weight: 400;
  height: 0;
  margin-top: 4px;
`;

export const PhoneField = styled(FlexCol)<{ fullWidth?: boolean }>`
  align-items: flex-start;
  width: 100%;

  @media (min-width: 768px) {
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "50%")};
  }
`;

//////////////////////////////////////////////

export const StyledDiv = styled.div`
  padding: 9px 12px;
  /* border: solid 1px ${({ theme }) => theme.bg.wash}1a; */
  border-bottom: 0;
  align-items: flex-start;
  justify-content: space-between;
  &:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    border-bottom: solid 1px ${({ theme }) => theme.bg.wash}1a;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const StyledDivForLabel = styled(StyledDiv)`
  padding: 15px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: auto;
  border: none;
  padding: 0;
  &:hover input ~ span,
  &.active input ~ span {
    background-color: ${themeColor("primary")};
  }
  &:hover,
  &.active {
    background-color: ${themeColor("white")};
  }
`;

export const RadioButtonLabel = styled(Label)`
  margin-top: 0;
  font-weight: 700;
  width: fit-content;
  position: relative;
  ${rtl("padding-right", "padding-left")}: 30px;
  cursor: pointer;
`;

export const CheckboxInput1 = styled(Input)`
  display: none;
  margin: 0;
`;

// the styled input
export const Checkmark1 = styled.span`
  height: 20px;
  width: 20px;
  background-color: ${({ theme }) => theme.bg.wash}1a;
  border-radius: 50%;
  position: absolute;
  ${rtl("right", "left")}: 0;
  /* the dot circle */
  &:after {
    content: "";
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${themeColor("white")};
    display: block;
    position: absolute;
  }
`;
