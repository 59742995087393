import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ProgressCircle from "./ProgressCircle";
import { FlexCol } from "../../../shared/globals";
import { Stepper, StyledH2, StyledH4 } from "../styled";
import useCheckout from "../../../contexts/CartContext/useCheckout";
import { useRouter } from "../../../lib/i18n";
import { getStepsMessages } from "../../../contexts/CartContext/checkoutUtils";

const CircleTracker = () => {
  const intl = useIntl();
  const { route } = useRouter();
  const { checkout } = useCheckout();
  const stepsMessages = getStepsMessages(intl, route);
  const currentStep = checkout?.meta?.activeStepInfo;
  const isConfirmationPage = currentStep?.name === "Confirmation";

  if (isConfirmationPage) return null;

  return (
    <Stepper>
      <ProgressCircle value={currentStep.number} total={stepsMessages.length} />
      <FlexCol alignItems="flex-start">
        <StyledH2>{stepsMessages[currentStep.number - 1]}</StyledH2>
        {!!stepsMessages[currentStep.number] && (
          <StyledH4>
            <FormattedMessage defaultMessage="Next" />:{" "}
            {stepsMessages[currentStep.number]}
          </StyledH4>
        )}
      </FlexCol>
    </Stepper>
  );
};
export { CircleTracker };
