import { FormattedMessage } from "react-intl";
import ClearAllFilters from "./ClearAllFilters";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import Button from "../../Button/Button";

interface ApplyFilterSectionProps {
  onClose: () => void;
  totalCount: number;
  loading: boolean;
}

const getItemsFound = (itemsCount: number) => (
  <FormattedMessage
    defaultMessage="{count, plural, =0 {no items} one {1 item} two {# items} few {# items} many {# items} other {# items}} found"
    values={{ count: itemsCount }}
  />
);

export const ApplyFilterSection = ({
  onClose,
  totalCount,
  loading,
}: ApplyFilterSectionProps) => {
  return (
    <Flex
      fullWidth
      height={180}
      className="shrink-0 border-t border-gray-200 p-lg "
      column
    >
      <Flex fullWidth justifyContent="space-between">
        <span className="text-gray-800 font-semibold ">
          <FormattedMessage defaultMessage="Items" />
        </span>
        {!loading && (
          <span className="text-gray-800 text-sm font-normal">
            {getItemsFound(totalCount)}{" "}
          </span>
        )}
      </Flex>
      <Flex fullWidth>
        <Button
          fullWidth
          style={{
            flexGrow: 1,
          }}
          onClick={onClose}
        >
          <FormattedMessage defaultMessage="Done" />
        </Button>
        <ClearAllFilters loading={loading} />
      </Flex>
    </Flex>
  );
};
