import styled from "styled-components";
import { ProductDetailsProps } from "../../../types";

export const ProductAttributes: React.FC<
  Pick<ProductDetailsProps, "attributes">
> = ({ attributes }) => {
  return (
    <StyledTable>
      {attributes?.map((attribute) => (
        <StyledTR key={attribute?.name}>
          <StyledTdAttributeName>{attribute?.name}</StyledTdAttributeName>
          <td>
            {attribute?.values?.map(
              (value, index) =>
                value?.name +
                `${index === attribute?.values?.length - 1 ? "" : ", "}`
            )}
          </td>
        </StyledTR>
      ))}
    </StyledTable>
  );
};

const StyledTable = styled.table`
  display: table;
  height: fit-content;
  border-collapse: collapse;
  & > thead > tr {
    border-bottom: solid 2px ${({ theme }) => theme.bg.wash}1a;
  }
`;

const StyledTR = styled.tr`
  border-bottom: solid 1px ${({ theme }) => theme.bg.wash}1a;
  :nth-child(even) {
    background-color: #f9fafb;
  }
  td {
    padding: 15px;
    word-break: break-word;
  }
`;

const StyledTdAttributeName = styled.td`
  width: 150px;
`;
