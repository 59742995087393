import { FormattedMessage } from "react-intl";
import Cart from "../components/Cart";
import { Section } from "../../../shared/layout";
import type { CartPageProps } from "../../types";
import { StoreTemplate } from "../../TemplateLoader";
import React from "react";

const CartPage: React.FC<CartPageProps> = () => {
  const Template = StoreTemplate.get();
  return (
    <>
      <Template.elements.PageHeader
        pageTitle={<FormattedMessage defaultMessage="Cart" />}
      />
      <Section background={"backgroundReverse"}>
        <Cart />
      </Section>
    </>
  );
};

export default CartPage;
