import React from "react";
import { Section } from "../../../shared/layout";
import type { UserOrdersPageProps } from "../../types";
import { StoreTemplate } from "../../TemplateLoader";

const UserOrdersPage: React.FC<UserOrdersPageProps> = () => {
  const Template = StoreTemplate.get();

  return (
    <Section background={"backgroundReverse"}>
      <Template.elements.ProfileLayout>
        <Template.elements.UserOrdersElement />
      </Template.elements.ProfileLayout>
    </Section>
  );
};

export default UserOrdersPage;
