import React from "react";

export const CorrectMarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
    >
      <path
        d="M10 1L4.37627 7L1 3.40054"
        stroke="black"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
