import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { DefaultTextPrice } from "../../../../components/Price";
import { FlexCol, FlexRow, H3 } from "../../../../shared/globals";
import { PrimaryButton } from "../../../../components/Button";
import { themeColor } from "../../../../shared/styles-utils";
import { Link } from "../../../../lib/i18n";
import { useCartData } from "../../../../lib/cartData/useCartData";
import { isCartDirty } from "../../../../shared/utils/error-utils";
import { CartError } from "../../../../generated/graphql";

export interface SideCartFooter {
  updateCartItemsLoading?: boolean;
}

const SideCartFooter: React.FC<SideCartFooter> = ({
  updateCartItemsLoading,
}) => {
  const {
    cart: { id: cartId, isLoading, receipt, items, cartErrors },
    setSideCartState,
  } = useCartData();

  const canNotProceedToCheckout = isCartDirty(cartErrors as CartError[]);

  const itemsCount = items?.reduce((acc, curr) => acc + curr.quantity, 0);

  return (
    <Container fullWidth spacing="l">
      <Subtotal fullWidth justifyContent="space-between">
        <FlexRow spacing="xs">
          <H3>
            <FormattedMessage
              defaultMessage="Subtotal"
              values={{ itemsCount }}
            />
          </H3>
          <H3 fontWeight={400}>
            <FormattedMessage
              defaultMessage="({itemsCount, plural, =0 {0 item} one {1 item} two {# items} few {# items} many {# items} other {# items}})"
              values={{ itemsCount }}
            />
          </H3>
        </FlexRow>
        <H3 fontWeight={700} data-test="text-sidecart-subtotal">
          {updateCartItemsLoading ? (
            <FormattedMessage defaultMessage="Calculating..." />
          ) : (
            <DefaultTextPrice money={receipt?.total} />
          )}
        </H3>
      </Subtotal>

      <Footer fullWidth justifyContent="space-between">
        <Link
          fullWidth
          href={`/checkout/${cartId}`}
          disabled={isLoading || canNotProceedToCheckout}
        >
          <CheckoutButton
            data-test="button-checkout"
            fullWidth
            onClick={() => setSideCartState(false)}
            loadOnRouteChange
            to={`/checkout/${cartId}`}
            disabled={isLoading || canNotProceedToCheckout}
          >
            <FormattedMessage defaultMessage="Checkout" />
          </CheckoutButton>
        </Link>
        <Link fullWidth href="/cart">
          <CartButton
            data-test="button-view-cart"
            fullWidth
            onClick={() => setSideCartState(false)}
            loadOnRouteChange
            to="cart"
            outlined
          >
            <FormattedMessage defaultMessage="View Cart" />
          </CartButton>
        </Link>
      </Footer>
    </Container>
  );
};

export default SideCartFooter;

const Container = styled(FlexCol)`
  padding: 20px;
  box-shadow: 0px -4px 4px 0px rgba(226, 226, 226, 0.5);
  background-color: ${themeColor("white")};
`;

const Subtotal = styled(FlexRow)`
  background-color: rgba(0, 159, 41, 0.1);
  border-radius: 4px;
  padding: 16px;
`;

const Footer = styled(FlexRow)`
  gap: 16px;
`;

const CheckoutButton = styled(PrimaryButton)`
  padding-block: 12px;
  background-color: ${themeColor("primary")};
  color: ${themeColor("white")};
  border-color: ${themeColor("primary")};
`;

const CartButton = styled(PrimaryButton)`
  padding-block: 12px;
  border-color: ${themeColor("primary")};
  color: ${themeColor("primary")};
`;
