import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import { EmptyCartIcon } from "../../../../assets/Icons";
import { EmptyState } from "../../../../components/UtilityComponents/EmptyState";
import { FlexRow, FlexCol, P } from "../../../../shared/globals";
import LoadingSpinner from "../../../../shared/globals/UiElements/LoadingSpinner";
import { StoreTemplate } from "../../../TemplateLoader";
import FilterTags from "../../../default/components/Filter/components/FilterTags";
import { FilterAndSortProps } from "../../../types";
import Filter from "./Filter";
import { Sorting } from "./Sorting";
import { Pagination } from "../../../../components/Pagination/Pagination";

const DesktopFilterSort = ({
  products,
  itemPerPage,
  productsTotalCount,
  offset,
  loading,
}: FilterAndSortProps) => {
  const router = useRouter();
  const Template = StoreTemplate.get();
  const hasFilters =
    (router.query.filters &&
      [...(router.query.filters as string[])].some(
        (filter) => filter.length
      )) ||
    router.query.minPrice ||
    router.query.maxPrice;

  return (
    <FlexRow alignItems="start" margin="40px 0 0">
      <Filter loading={loading} />

      <StyledFlexCol>
        {(router.query.query || hasFilters) && (
          <ItemsWrapper alignItems="start" spacing="xl">
            {router.query.query && (
              <SearchText>
                <FormattedMessage defaultMessage="Search Results For" />{" "}
                {'"' + router.query.query + '"'}{" "}
                <span style={{ opacity: 0.4 }}>
                  <FormattedMessage
                    defaultMessage="[{resultsCount} Results]"
                    values={{ resultsCount: productsTotalCount }}
                  />
                </span>
              </SearchText>
            )}
            {hasFilters && (
              <FilterTagsWrapper>
                <FilterTags />
              </FilterTagsWrapper>
            )}
          </ItemsWrapper>
        )}
        <Sorting />
        {loading ? (
          <LoadingSpinner />
        ) : products?.length === 0 ? (
          <EmptyState
            title={
              router.query.filters ||
              router.query.minPrice ||
              router.query.maxPrice ? (
                <FormattedMessage defaultMessage="There are no products match your search criteria" />
              ) : (
                <FormattedMessage defaultMessage="There are no products added yet" />
              )
            }
            image={<EmptyCartIcon />}
          />
        ) : (
          <>
            <Template.elements.ProductsList products={products} />
            <Pagination
              offset={offset!}
              itemPerPage={itemPerPage!}
              totalCount={productsTotalCount!}
            />
          </>
        )}
      </StyledFlexCol>
    </FlexRow>
  );
};

export default DesktopFilterSort;

// Styles

const SearchText = styled(P)`
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  word-break: break-word;
`;

const FilterTagsWrapper = styled.div`
  > div {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
`;

const ItemsWrapper = styled(FlexCol)`
  padding: 20px 0;
  box-shadow: inset 0px -1px 0px 0px #eeeeee;
`;

const StyledFlexCol = styled.div`
  width: 100%;
`;
