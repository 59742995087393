import { ShippingDestination } from "../../components/UtilityComponents/ShippingDestinationSelect";
import {
  Money,
  OrderSelectedOptionInput,
  AutomaticDiscountFragment,
  Image,
  CalculatedDiscountBaseItem,
} from "../../generated/graphql";
import { PhoneInput } from "../../shared/globals/UiElements/InputPhone";
import type { CartBuildData, SimpleProductProps } from "../../templates/types";

/**
 *  CART TYPES
 */

export interface UpdateCartItemQuantityInput {
  itemId: string;
  newQuantity: number;
}

export type ItemCustomDiscountType = {
  title: string;
  quantity: number;
  percentage: number | null | undefined;
  fixed: Money | null | undefined;
  itemDiscount: Money;
  totalDiscount: Money;
};

export type CartItemInput = {
  variantId: string;
  productId: string;
  handle: string;
  title: string;
  price: Money;
  compareAtPrice?: Money;
  quantity: number;
  availableQuantity: SimpleProductProps["availableQuantity"];
  img: Image | null | undefined;
  sku: string | null | undefined;
  selectedOptions: OrderSelectedOptionInput[];
  collectionIds: string[];
  collectionNames: string[];
  source: string;
  buildData: CartBuildData | null | undefined;
  customDiscount: ItemCustomDiscountType | null;
  customerId?: string;
  subtotal?: Money; // = price * quantity
  total?: Money; // = (price * quantity) - discount
  taxable?: boolean | null | undefined;
  productTax?: number | null | undefined;
};

export type CartItemType = CartItemInput & CalculatedDiscountBaseItem;

/**
 * CHECKOUT TYPES
 */

export interface CheckoutInformation {
  address: string;
  apartment: string;
  email?: string;
  name: string;
  postalCode?: string | null;
  phone?: PhoneInput;
  secondPhone?: PhoneInput;
  notes?: string;
  isSubscribed?: boolean;
  selectedAddressId?: string;
  shippingDestination: ShippingDestination;
}

export enum CheckoutFields {
  Address = "address",
  Apartment = "apartment",
  Email = "email",
  Name = "name",
  Phone = "phone",
  PostalCode = "postalCode",
  SecondPhone = "secondPhone",
  Notes = "notes",
  IsSubscribed = "isSubscribed",
  SelectedAddressId = "selectedAddressId",
  ShippingDestination = "shippingDestination",
}

export interface CheckoutShipping {
  id: string;
  name: string;
  cost: Money;
}

export interface CheckoutCoupon {
  code: string;
  percentageOff?: number;
  fixedAmountOff?: number;
  minimumOrderAmountToApply?: number;
  type?: string;
}

export interface CheckoutReceipt {
  subtotal: Money;
  automaticDiscount: Money;
  couponDiscount: Money;
  shippingDiscount: Money;
  tax?: Money;
  shipping: Money;
  total?: Money;
}

export interface CheckoutStepInfo {
  number: number;
  name:
    | "Cart"
    | "Information"
    | "Delivery"
    | "Invalid Order"
    | "Payment"
    | "Confirmation"
    | "Other";
}

export type ResetCartStatus =
  | CheckoutStatusEnum.Done
  | CheckoutStatusEnum.BuyNowDone;

export enum CheckoutStatusEnum {
  Active = "Active",
  Done = "Done",
  BuyNowActive = "Buy-now-active",
  BuyNowDone = "Buy-now-done",
}

export interface Checkout {
  information?: CheckoutInformation;
  shippingInfo?: CheckoutShipping;
  coupon: CheckoutCoupon;
  receipt: CheckoutReceipt;
  status: CheckoutStatusEnum;
}

export interface CheckoutMeta {
  isFreeShippingDiscount: boolean;
  isShippingDiscount: boolean;
  isAutomaticDiscount: boolean;
  isCustomDiscount: boolean;
  isUsingCoupon: boolean;
  isInformationDone: boolean;
  isShippingDone: boolean;
  activeStepInfo: CheckoutStepInfo;
}

export interface CheckoutContext extends Checkout {
  shippingDiscount: AutomaticDiscountFragment | null | undefined;
  tax: number | null | undefined;
  receipt: CheckoutReceipt;
  items: CartItemType[];
  buyNowItem: CartItemType[] | null;
  meta: CheckoutMeta;
  orderId?: string;
}

/**
 * CONTEXT TYPES
 */

export interface CartState {
  items: CartItemType[];
  buyNowItem: CartItemType[] | null;
  checkout: Checkout;
  itemsTotal: Money;
  isSideCart?: boolean;
  isCartUpdating?: boolean;
}
