import { FormattedMessage, useIntl } from "react-intl";
import { Delete } from "../../../../../assets/Icons";
import { useRouter } from "../../../../../lib/i18n";
import { ReactNode, useEffect, useState } from "react";
import { useStore } from "../../../../../lib/storeData";
import {
  ProductAttributeValue,
  ProductCollection,
  useGetFiltersQuery,
  ProductOptionValue,
} from "../../../../../generated/graphql";
import { getLocaleInfo } from "../../../../../lib/i18n/locales-data";
import {
  FiltersDictionary,
  getAvailableFilters,
} from "../../../../../shared/utils/filterHelperFunctions";
import Flex from "../../../../../shared/globals/UiElements/Flex";

const FilterTags = () => {
  const [filtersDictionary, setFiltersDictionary] =
    useState<FiltersDictionary>();
  const router = useRouter();
  const { storeId } = useStore();
  const intl = useIntl();
  const { locale } = useIntl();
  const selectedFiltersIds =
    typeof router.query.filters === "string"
      ? [router.query.filters]
      : router.query.filters || [];
  const activeLocale = getLocaleInfo(locale).code;

  const { data } = useGetFiltersQuery({
    variables: {
      storeId,
    },
  });

  useEffect(() => {
    if (data) {
      const { filtersDictionary: temp } = getAvailableFilters({
        attributesProductsDistribution: data?.getApplicableFilters
          ?.attributeValues! as ProductAttributeValue[],
        collectionsProductsDistribution: data?.getApplicableFilters
          ?.collections as ProductCollection[],
        optionsProductsDistribution: data?.getApplicableFilters
          ?.optionValues as ProductOptionValue[],
        intl,
      });
      setFiltersDictionary(temp);
    }
  }, [data, intl]);

  if (
    !(router.query.filters || router.query.minPrice || router.query.maxPrice) ||
    router.route.includes("collection")
  ) {
    return null;
  }
  const removeFilter = (filter: string) => {
    const newFilters = { ...router.query };
    if (typeof newFilters.filters === "string") {
      newFilters.filters = [newFilters.filters];
    }

    if (filter === "minPrice" || filter === "maxPrice") {
      newFilters[filter] = undefined;
    } else {
      newFilters.filters = (newFilters.filters as string[])?.filter(
        (f: string) => f !== filter
      ) as string[];
    }
    if (
      !newFilters?.minPrice?.length &&
      !newFilters?.maxPrice?.length &&
      !newFilters?.filters?.length &&
      !router.query.query
    ) {
      router.push({ pathname: `/${activeLocale}/shop`, query: null });
      return;
    }
    router.push(
      {
        pathname: router.asPath?.split("?")[0],
        query: newFilters,
      },
      undefined,
      { scroll: false }
    );
  };

  const getSelectedFiltersNames = () => {
    const selectedFiltersNames: {
      filterName: string;
      filterValue: string;
    }[] = [];

    if (!filtersDictionary) return [];

    for (const [, value] of Object.entries(filtersDictionary)) {
      value.forEach((filter) => {
        if (
          selectedFiltersIds.includes(filter.valueId) ||
          selectedFiltersIds?.includes(
            filter?.valueId! + "|" + filter?.propertyId!
          )
        )
          selectedFiltersNames.push({
            filterName: filter.valueName,
            filterValue: filter.valueId.startsWith("c:")
              ? filter.valueId
              : filter.valueId + "|" + filter.propertyId,
          });
      });
    }
    return selectedFiltersNames;
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      <span className="text-gray-500 font-semibold max-md:hidden">
        <FormattedMessage defaultMessage="Selected filters" />
      </span>
      <Flex flexWrap="wrap" spacing="s">
        {router.query.minPrice && (
          <FilterTag onClick={() => removeFilter("minPrice")}>
            <FormattedMessage defaultMessage="Min Price: " />{" "}
            {router.query.minPrice}
          </FilterTag>
        )}
        {router.query.maxPrice && (
          <FilterTag onClick={() => removeFilter("maxPrice")}>
            <FormattedMessage defaultMessage="Max Price" />{" "}
            {router.query.maxPrice}
          </FilterTag>
        )}
        {getSelectedFiltersNames().map((item) => (
          <FilterTag
            title={item.filterName}
            key={item.filterValue}
            onClick={() => removeFilter(item.filterValue)}
          >
            {item.filterName}
          </FilterTag>
        ))}
      </Flex>
    </Flex>
  );
};

export default FilterTags;

interface FilterTagsProps {
  title?: string;
  children?: ReactNode;
  key?: string;
  onClick: () => void;
}

const FilterTag = ({ children, title, key, onClick }: FilterTagsProps) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    className="bg-white border border-gray-300 rounded-full shadow-sm px-sm py-xs text-gray-500 hover:bg-gray-100 h-full text-sm font-semibold"
    title={title}
    key={key}
  >
    <span className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[250px]">
      {children}
    </span>
    <span className="cursor-pointer " onClick={onClick}>
      <Delete />
    </span>
  </Flex>
);
