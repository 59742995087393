const ShoppingCartPromotionIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.51172 1.66357L1.66992 1.67822L1.67806 3.34489L3.40332 3.33675L6.14909 9.92367L5.15137 11.5203C4.81803 12.0537 4.80079 12.7274 5.10579 13.2765C5.41079 13.8257 5.98951 14.1668 6.61784 14.1668H16.6667V12.5002H6.61784L6.56413 12.4025L7.54395 10.8335H13.7679C14.3737 10.8335 14.9313 10.5057 15.2246 9.97738L18.6523 3.8055L17.1956 2.99658L13.7679 9.16683H7.63835L4.51172 1.66357ZM9.16667 1.66683C8.70667 1.66683 8.33333 2.04016 8.33333 2.50016C8.33333 2.96016 8.70667 3.3335 9.16667 3.3335C9.62667 3.3335 10 2.96016 10 2.50016C10 2.04016 9.62667 1.66683 9.16667 1.66683ZM12.3258 1.66683L7.94759 7.49691L7.95085 7.50016H9.50846L13.8883 1.66683H12.3258ZM12.5 5.8335C12.04 5.8335 11.6667 6.20683 11.6667 6.66683C11.6667 7.12683 12.04 7.50016 12.5 7.50016C12.96 7.50016 13.3333 7.12683 13.3333 6.66683C13.3333 6.20683 12.96 5.8335 12.5 5.8335ZM6.66667 15.0002C6.22464 15.0002 5.80072 15.1758 5.48816 15.4883C5.17559 15.8009 5 16.2248 5 16.6668C5 17.1089 5.17559 17.5328 5.48816 17.8453C5.80072 18.1579 6.22464 18.3335 6.66667 18.3335C7.10869 18.3335 7.53262 18.1579 7.84518 17.8453C8.15774 17.5328 8.33333 17.1089 8.33333 16.6668C8.33333 16.2248 8.15774 15.8009 7.84518 15.4883C7.53262 15.1758 7.10869 15.0002 6.66667 15.0002ZM15 15.0002C14.558 15.0002 14.134 15.1758 13.8215 15.4883C13.5089 15.8009 13.3333 16.2248 13.3333 16.6668C13.3333 17.1089 13.5089 17.5328 13.8215 17.8453C14.134 18.1579 14.558 18.3335 15 18.3335C15.442 18.3335 15.8659 18.1579 16.1785 17.8453C16.4911 17.5328 16.6667 17.1089 16.6667 16.6668C16.6667 16.2248 16.4911 15.8009 16.1785 15.4883C15.8659 15.1758 15.442 15.0002 15 15.0002Z"
        fill="#42AB44"
      />
    </svg>
  );
};

export { ShoppingCartPromotionIcon };
