import React from "react";
import { formatAvailableQuantity } from "../contexts/CartContext/reducer/utils";
import {
  AutomaticDiscountFragment,
  DiscountAppliedOnType,
  ProductType,
} from "../generated/graphql";
import { useRouter } from "../lib/i18n";
import type { ProductCardProps } from "../templates/types";
import { computeBuildTotal } from "./CustomProductRenderer";
import type { RendererComponent } from "./types";
import { useStore } from "../lib/storeData";
import { useCartData } from "../lib/cartData/useCartData";
import { getCartItem } from "../lib/cartData/utils";

interface ProductCardRendererProps
  extends RendererComponent<ProductCardProps> {}

const ProductCardRenderer: React.FC<ProductCardRendererProps> = ({
  Component,
  product,
  onlyCardBody,
  isSlider,
  productPerRow,
  index,
  productsCount,
  background,
  isProductList,
}) => {
  const { query } = useRouter();
  const collection = query.collection as string;
  const {
    cart,
    addSimpleItem: { addSimpleItem, loading: addingSimpleItem },
    updateSimpleItem: { updateSimpleItem, loading: updatingSimpleItem },
    setSideCartState,
  } = useCartData();
  const { automaticDiscounts } = useStore();

  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const firstVariant = product?.variants?.nodes?.[0];
  const availableQuantity = formatAvailableQuantity(firstVariant);

  const isDiscount = !!(product?.discount?.percent || product?.discount?.fixed);
  const discountedCustomProductPrice =
    computeBuildTotal(product) < 0 ? 0 : computeBuildTotal(product);
  const collectionIds = (product?.collections?.nodes || []).map((i) => i?.id!);

  const appliedAutomaticDiscounts =
    product.type === ProductType.Custom
      ? []
      : getAppliedAutomaticDiscounts(
          automaticDiscounts,
          firstVariant?.id,
          collectionIds
        );

  const itemInCart = getCartItem(cart, firstVariant?.id!);

  const handleAddToCart = async () => {
    if (itemInCart)
      await updateSimpleItem({
        item: {
          quantity: itemInCart?.quantity + 1,
          variantId: firstVariant?.id!,
        },
      });
    else
      await addSimpleItem({
        item: {
          quantity: 1,
          variantId: firstVariant?.id!,
        },
      });

    setSideCartState(true);
  };

  return (
    <Component
      product={product}
      handleAddToCart={handleAddToCart}
      collection={collection}
      isAlertOpen={isAlertOpen}
      setIsAlertOpen={setIsAlertOpen}
      isDiscount={isDiscount}
      discountedCustomProductPrice={discountedCustomProductPrice}
      availableQuantity={availableQuantity}
      onlyCardBody={onlyCardBody}
      isSlider={isSlider}
      productPerRow={productPerRow}
      index={index}
      productsCount={productsCount}
      background={background}
      isCartUpdating={cart.isLoading}
      isAddingSimpleItem={addingSimpleItem || updatingSimpleItem}
      isProductList={isProductList}
      appliedAutomaticDiscounts={appliedAutomaticDiscounts}
    />
  );
};

export default ProductCardRenderer;

function getAppliedAutomaticDiscounts(
  automaticDiscounts: (AutomaticDiscountFragment | null)[],
  selectedVariantId: string | null | undefined,
  productCollectionIds: string[] = []
): (AutomaticDiscountFragment | null)[] {
  const validDiscounts = {
    [DiscountAppliedOnType.AmountOff]: "amountOff",
    [DiscountAppliedOnType.BuyXGetY]: "BuyXGetYDiscount",
  };

  const filteredDiscounts = automaticDiscounts?.filter((discount) =>
    Object.keys(validDiscounts)?.includes(discount?.appliedOn!)
  );

  if (!filteredDiscounts?.length) return [];

  return filteredDiscounts.filter((discount) => {
    const discountField = validDiscounts[discount?.appliedOn!];

    const discountVariantIds =
      discount?.[discountField]?.customerBuys?.items?.productVariantIds;

    const isProductFirstVariantIncluded = discountVariantIds?.includes(
      selectedVariantId!
    );
    const discountCollectionIds =
      discount?.[discountField]?.customerBuys?.items?.collectionIds;

    const isProductCollectionIncluded = discountCollectionIds?.some?.((i) =>
      productCollectionIds?.includes?.(i)
    );

    return isProductFirstVariantIncluded || isProductCollectionIncluded;
  });
}
