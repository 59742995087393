import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, Form } from "react-final-form";
import { PrimaryButton } from "../../../components/Button";
import { rtl, themeColor } from "../../../shared/styles-utils";
import {
  BoxImportant,
  CheckCircle,
  DangerDashedIcon,
  PercentDashedIcon,
} from "../../../assets/Icons";
import {
  FlexCol,
  FlexRow,
  H4,
  H5,
  Input,
  Spinner,
} from "../../../shared/globals";
import { CouponProps, FormValues, TemplateElement } from "../../types";
import { useStore } from "../../../lib/storeData";
import { PriceBase } from "../../../components/Price/Price";
import { useCartData } from "../../../lib/cartData/useCartData";
import Flex from "../../../shared/globals/UiElements/Flex";
import { CheckoutServiceErrorCodes } from "../../../generated/graphql";

const Coupon: TemplateElement<CouponProps> = ({
  loading,
  error,
  removePromoCodeLoading,
  onSubmit,
  onRemovePromoCode,
  minimumPromoCodeLoading,
}) => {
  const intl = useIntl();
  const { cart } = useCartData();
  const couponData = cart.recoveryCart != null ? cart.recoveryCart : cart;
  const { currency } = useStore();
  const isInvalid = !loading && error;

  const isValid =
    !loading &&
    (!!couponData?.promoCode?.percentageOff ||
      !!couponData?.promoCode?.fixedAmount);

  const isDirty = isValid || isInvalid;
  return (
    <>
      <FlexCol fullWidth spacing="s" alignItems="flex-start">
        <H4 fontWeight={600}>
          <FormattedMessage defaultMessage="Coupon" />
        </H4>
        <H5 color="secondary" fontWeight={400} style={{ opacity: 0.8 }}>
          <FormattedMessage defaultMessage="Do you have a coupon code?" />
        </H5>
        <FlexRow fullWidth>
          <Form<FormValues>
            onSubmit={onSubmit!}
            initialValues={{
              coupon: couponData?.promoCode?.code || "",
            }}
            render={({ handleSubmit, values }) => (
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <FlexRow spacing="m" alignItems="stretch">
                  <Field
                    name="coupon"
                    initialValue={
                      isInvalid ? values.coupon : couponData?.promoCode?.code
                    }
                  >
                    {({ input }) => (
                      <InputContainer>
                        <StyledInput
                          {...input}
                          className={isInvalid ? "invalid" : ""}
                          valid={isValid}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Enter coupon code",
                          })}
                          disabled={cart?.isLoading || isDirty}
                        />

                        {isDirty && (
                          <IconHolder>
                            {isInvalid && <BoxImportant />}
                            {isValid && <CheckCircle />}
                          </IconHolder>
                        )}
                      </InputContainer>
                    )}
                  </Field>

                  {isDirty ? (
                    <InputButton
                      type="submit"
                      onClick={() => {
                        onRemovePromoCode!();
                        values.coupon = "";
                      }}
                    >
                      <FormattedMessage defaultMessage="Remove" />
                    </InputButton>
                  ) : (
                    <InputButton
                      type="submit"
                      className={
                        !values.coupon ||
                        values?.coupon === couponData?.promoCode?.code
                          ? "dimmed "
                          : undefined
                      }
                      isLoading={loading || minimumPromoCodeLoading}
                    >
                      <FormattedMessage defaultMessage="Apply" />
                    </InputButton>
                  )}
                </FlexRow>
              </form>
            )}
          />
        </FlexRow>

        {isValid &&
          error == undefined &&
          (!cart?.isLoading ? (
            <FeedbackMessage fullWidth spacing="xs">
              <PercentDashedIcon />
              <H5 fontWeight={600}>
                {couponData?.promoCode?.percentageOff ? (
                  <FormattedMessage
                    defaultMessage="{couponCode} - {couponPercentage}% OFF"
                    values={{
                      couponCode: couponData?.promoCode?.code,
                      couponPercentage: couponData?.promoCode?.percentageOff,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="{couponCode} - {couponFixedAmount} OFF"
                    values={{
                      couponCode: couponData?.promoCode?.code,
                      couponFixedAmount: (
                        <PriceBase
                          money={{
                            amount: couponData?.promoCode?.fixedAmount!,
                            currencyCode: currency,
                          }}
                        />
                      ),
                    }}
                  />
                )}{" "}
              </H5>
            </FeedbackMessage>
          ) : (
            <Flex spacing="s" className="mt-2" alignItems="center">
              <Spinner inline={true} size={20} />
              <div className="text-sm text-gray-600">
                <FormattedMessage defaultMessage="Processing" />
              </div>
            </Flex>
          ))}

        {isInvalid &&
          (error && !removePromoCodeLoading ? (
            <FeedbackMessage invalid fullWidth spacing="xs">
              <DangerDashedIcon />
              <H5 fontWeight={600}>
                {error.message ==
                CheckoutServiceErrorCodes.PromoCodeNotValid ? (
                  <FormattedMessage defaultMessage="Sorry, this coupon code is invalid" />
                ) : (
                  <FormattedMessage
                    defaultMessage="The order subtotal must be more than {minimum} to apply the coupon"
                    values={{
                      minimum: (
                        <PriceBase
                          money={{
                            amount: error?.minimumAmount,
                            currencyCode: currency,
                          }}
                        />
                      ),
                    }}
                  />
                )}
              </H5>
            </FeedbackMessage>
          ) : (
            <Flex spacing="s" className="mt-2" alignItems="center">
              <Spinner inline={true} size={20} />
              <div className="text-sm text-gray-600">
                <FormattedMessage defaultMessage="Processing" />
              </div>
            </Flex>
          ))}
      </FlexCol>
    </>
  );
};

export default Coupon;

// Styles

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

const StyledInput = styled(Input)<{ valid: boolean }>`
  padding-inline-end: 22px;
  margin-top: 0;
  background-color: ${({ theme }) => theme.bg.reverse};
  color: ${({ valid, theme }) => valid && theme.success.medium};
  border-color: ${({ valid, theme }) =>
    valid ? theme.success.medium : theme.bg.border};

  &::placeholder {
    font-size: 12px;
    font-weight: 600;
    opacity: 0.7;
  }

  &:focus {
    border-color: ${({ valid, theme }) =>
      valid ? theme.success.medium : themeColor("primary")};
  }

  &.invalid {
    color: ${({ theme }) => theme.danger.orange};
    border-color: ${({ theme }) => theme.danger.orange};
    :focus {
      border-color: ${({ theme }) => theme.danger.orange};
    }
  }
`;

const IconHolder = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.danger.orange};
  ${rtl("left", "right")}: 8px;
`;

const InputButton = styled(PrimaryButton)`
  padding: 8px 17px;
  font-size: 16px;
  background-color: ${themeColor("primary")};
  border: unset;

  &.dimmed {
    opacity: 0.2;
  }

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

const FeedbackMessage = styled(FlexRow)<{ invalid?: boolean }>`
  padding: 10px;
  border-radius: 4px;
  background-color: ${({ invalid, theme }) =>
    invalid ? `${theme.danger.orange}0d` : `${theme.success.medium}26`};

  > h5 {
    color: ${({ invalid, theme }) =>
      invalid ? theme.danger.orange : theme.success.medium};
  }
`;
