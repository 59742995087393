export const CreditCardWarningError = () => {
  return (
    <svg
      width="242"
      height="199"
      viewBox="0 0 242 199"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.68885 72.4593C10.2624 71.6233 11.6705 68.8603 10.8341 66.288C9.99763 63.7158 7.23329 62.3083 4.65977 63.1444C2.08625 63.9804 0.678081 66.7434 1.51454 69.3157C2.35099 71.8879 5.11532 73.2954 7.68885 72.4593Z"
        fill="#EAEEF9"
      />
      <path
        d="M19.9326 59.5802C21.6826 59.0117 22.6401 57.1328 22.0713 55.3837C21.5025 53.6346 19.6228 52.6775 17.8728 53.246C16.1228 53.8145 15.1652 55.6933 15.734 57.4425C16.3028 59.1916 18.1826 60.1487 19.9326 59.5802Z"
        fill="#EAEEF9"
      />
      <path
        d="M234.496 77.9316C237.533 75.1581 237.745 70.4495 234.97 67.4145C232.195 64.3796 227.484 64.1676 224.448 66.9411C221.411 69.7146 221.199 74.4232 223.974 77.4582C226.749 80.4931 231.46 80.7051 234.496 77.9316Z"
        fill="#EAEEF9"
      />
      <path
        d="M4.41406 196.09H240"
        stroke="#EAEEF9"
        stroke-width="4.0179"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M129.067 22.7266H112.211V33.6968H129.067V22.7266Z"
        fill="#B3BFCF"
      />
      <path
        d="M129.067 22.7266H112.211V27.4281H129.067V22.7266Z"
        fill="#9FADBF"
      />
      <path
        d="M65.1762 44.5785L56.0293 53.7207L62.6816 60.3696L71.8284 51.2274L65.1762 44.5785Z"
        fill="#B3BFCF"
      />
      <path
        d="M172.139 50.5531L181.286 59.6953L187.245 53.739L178.098 44.5968L172.139 50.5531Z"
        fill="#B3BFCF"
      />
      <path
        d="M126.911 196.877C171.724 196.877 208.053 160.567 208.053 115.775C208.053 70.9843 171.724 34.6738 126.911 34.6738C82.0975 34.6738 45.769 70.9843 45.769 115.775C45.769 160.567 82.0975 196.877 126.911 196.877Z"
        fill="#D3DAE6"
      />
      <path
        d="M122.011 193.938C166.824 193.938 203.153 157.627 203.153 112.836C203.153 68.0448 166.824 31.7344 122.011 31.7344C77.1976 31.7344 40.8691 68.0448 40.8691 112.836C40.8691 157.627 77.1976 193.938 122.011 193.938Z"
        fill="#DAE1ED"
        stroke="#D3DAE6"
        stroke-width="2.00895"
        stroke-miterlimit="10"
      />
      <path
        d="M50.4727 117.344C48.5128 117.344 46.9448 115.777 46.9448 113.818C46.9448 113.426 46.9448 113.035 46.9448 112.839C46.9448 75.0304 75.1681 43.0991 112.603 38.3975C114.563 38.2016 116.327 39.5729 116.523 41.5319C116.719 43.4909 115.347 45.2539 113.387 45.4498C79.48 49.7596 54.0006 78.7525 54.0006 112.839C54.0006 113.23 54.0006 113.426 54.0006 113.818C54.0006 115.777 52.4327 117.344 50.4727 117.344Z"
        fill="#EDF0FC"
      />
      <path
        d="M122.011 188.064C113.975 188.064 105.939 186.888 98.4916 184.342C96.5317 183.754 95.5517 181.795 96.1397 179.836C96.7277 177.877 98.6876 176.898 100.648 177.485C107.507 179.836 114.759 180.815 122.011 180.815C155.918 180.815 184.925 155.545 189.433 121.85C189.629 119.891 191.393 118.52 193.353 118.716C195.313 118.912 196.685 120.675 196.489 122.634C194.137 140.461 185.317 156.916 171.794 169.062C158.074 181.403 140.435 188.064 122.011 188.064Z"
        fill="#D0D5DD"
      />
      <path
        d="M185.318 112.836C185.318 113.619 185.318 114.207 185.318 114.99C184.142 148.881 156.311 176.11 122.012 176.11C87.7128 176.11 59.8815 148.881 58.7056 114.99C58.7056 114.207 58.7056 113.619 58.7056 112.836C58.7056 78.7493 85.7529 50.7359 119.66 49.5605C120.444 49.5605 121.228 49.5605 122.012 49.5605C156.703 49.5605 184.73 77.378 185.318 111.66C185.318 112.052 185.318 112.444 185.318 112.836Z"
        fill="#EDF0FC"
      />
      <path
        d="M185.318 114.994C184.142 148.885 156.311 176.114 122.012 176.114C87.7128 176.114 59.8815 148.885 58.7056 114.994C59.8815 81.1039 87.7128 53.8741 122.012 53.8741C156.311 53.6782 184.142 80.908 185.318 114.994Z"
        fill="white"
      />
      <path
        d="M138.866 153.194H102.607C100.843 153.194 99.4712 151.822 99.4712 150.059V132.037C99.4712 130.274 100.843 128.902 102.607 128.902H138.866C140.63 128.902 142.002 130.274 142.002 132.037V150.059C142.002 151.822 140.63 153.194 138.866 153.194Z"
        fill="#F2F5FC"
      />
      <path
        d="M176.889 115.972H166.502C164.738 115.972 163.366 114.601 163.366 112.837C163.366 111.074 164.738 109.703 166.502 109.703H176.889C178.653 109.703 180.025 111.074 180.025 112.837C180.025 114.601 178.457 115.972 176.889 115.972Z"
        fill="#DAE1ED"
      />
      <path
        d="M74.384 115.972H63.9963C62.2323 115.972 60.8604 114.601 60.8604 112.837C60.8604 111.074 62.2323 109.703 63.9963 109.703H74.384C76.1479 109.703 77.5199 111.074 77.5199 112.837C77.5199 114.601 75.952 115.972 74.384 115.972Z"
        fill="#DAE1ED"
      />
      <path
        d="M120.443 172.585C118.679 172.585 117.307 171.214 117.307 169.451V159.068C117.307 157.305 118.679 155.934 120.443 155.934C122.207 155.934 123.579 157.305 123.579 159.068V169.451C123.383 171.214 122.011 172.585 120.443 172.585Z"
        fill="#DAE1ED"
      />
      <path
        d="M120.443 70.1312C118.679 70.1312 117.307 68.7599 117.307 66.9968V56.8101C117.307 55.0471 118.679 53.6758 120.443 53.6758C122.207 53.6758 123.579 55.0471 123.579 56.8101V67.1927C123.383 68.7599 122.011 70.1312 120.443 70.1312Z"
        fill="#DAE1ED"
      />
      <path
        d="M121.227 121.458C126.531 121.458 130.831 117.16 130.831 111.859C130.831 106.557 126.531 102.26 121.227 102.26C115.923 102.26 111.624 106.557 111.624 111.859C111.624 117.16 115.923 121.458 121.227 121.458Z"
        fill="#F2F5FC"
      />
      <path
        d="M161.994 73.8538C160.818 72.8743 159.054 72.8743 158.074 73.8538L124.167 109.507C123.971 109.507 123.971 109.507 123.775 109.507C121.227 109.507 119.267 111.466 119.267 114.013C119.267 114.209 119.267 114.405 119.267 114.6L113.779 120.282C112.799 121.457 112.799 123.22 113.779 124.199C114.367 124.787 114.955 124.983 115.739 124.983C116.523 124.983 117.307 124.787 117.699 124.199L123.187 118.518C123.383 118.518 123.579 118.518 123.579 118.518C126.127 118.518 128.087 116.559 128.087 114.013C128.087 113.817 128.087 113.621 128.087 113.425L161.994 77.9676C163.17 76.5963 163.17 74.8332 161.994 73.8538Z"
        fill="#DAE1ED"
      />
      <path
        d="M159.446 71.6995C158.27 70.72 156.506 70.72 155.526 71.6995L121.619 107.353C121.423 107.353 121.423 107.353 121.227 107.353C118.679 107.353 116.719 109.312 116.719 111.858C116.719 112.054 116.719 112.25 116.719 112.446L111.232 118.127C110.252 119.303 110.252 121.066 111.232 122.045C111.82 122.633 112.408 122.829 113.192 122.829C113.975 122.829 114.759 122.633 115.151 122.045L120.639 116.364C120.835 116.364 121.031 116.364 121.031 116.364C123.579 116.364 125.539 114.405 125.539 111.858C125.539 111.663 125.539 111.467 125.539 111.271L159.446 75.8133C160.622 74.442 160.426 72.6789 159.446 71.6995Z"
        fill="#F79009"
      />
      <path
        d="M158.27 72.6772L112.212 120.672C111.82 120.672 111.82 120.672 111.428 120.672C111.036 120.084 111.232 119.301 111.624 118.713L155.918 72.2854C156.506 71.6977 157.486 71.6977 158.074 72.0895C158.466 72.0895 158.466 72.4813 158.27 72.6772Z"
        fill="#F79009"
      />
      <path
        d="M137.103 7.24822V17.8267C137.103 21.9405 133.771 25.0749 129.851 25.0749H111.82C107.704 25.0749 104.568 21.7447 104.568 17.8267V7.24822C104.568 3.13436 107.9 0 111.82 0H129.851C133.967 0 137.103 3.33026 137.103 7.24822Z"
        fill="#1C3754"
      />
      <path
        d="M137.103 7.24822V17.8267C137.103 21.9405 133.771 25.0749 129.851 25.0749H111.82C107.704 25.0749 104.568 21.7447 104.568 17.8267V15.8677C105.744 20.9611 110.252 20.9611 110.252 20.9611H128.871C131.419 20.9611 133.575 18.8062 133.575 16.2595V8.6195C133.575 3.33026 132.595 1.37128 129.851 0C133.967 0 137.103 3.33026 137.103 7.24822Z"
        fill="#172D45"
      />
      <path
        d="M107.312 19.0011C106.724 19.0011 106.332 18.6093 106.332 18.0216V5.87597C106.332 5.28828 106.724 4.89648 107.312 4.89648C107.9 4.89648 108.291 5.28828 108.291 5.87597V18.0216C108.095 18.6093 107.704 19.0011 107.312 19.0011Z"
        fill="#344054"
      />
      <path
        d="M111.82 19.0011C111.232 19.0011 110.84 18.6093 110.84 18.0216V5.87597C110.84 5.28828 111.232 4.89648 111.82 4.89648C112.408 4.89648 112.8 5.28828 112.8 5.87597V18.0216C112.8 18.6093 112.408 19.0011 111.82 19.0011Z"
        fill="#344054"
      />
      <path
        d="M116.523 19.0011C115.935 19.0011 115.543 18.6093 115.543 18.0216V5.87597C115.543 5.28828 115.935 4.89648 116.523 4.89648C117.111 4.89648 117.503 5.28828 117.503 5.87597V18.0216C117.307 18.6093 116.915 19.0011 116.523 19.0011Z"
        fill="#344054"
      />
      <path
        d="M121.031 19.0011C120.443 19.0011 120.051 18.6093 120.051 18.0216V5.87597C120.051 5.28828 120.443 4.89648 121.031 4.89648C121.619 4.89648 122.011 5.28828 122.011 5.87597V18.0216C122.011 18.6093 121.619 19.0011 121.031 19.0011Z"
        fill="#344054"
      />
      <path
        d="M125.735 19.0011C125.147 19.0011 124.755 18.6093 124.755 18.0216V5.87597C124.755 5.28828 125.147 4.89648 125.735 4.89648C126.323 4.89648 126.715 5.28828 126.715 5.87597V18.0216C126.519 18.6093 126.127 19.0011 125.735 19.0011Z"
        fill="#344054"
      />
      <path
        d="M130.243 19.0011C129.655 19.0011 129.263 18.6093 129.263 18.0216V5.87597C129.263 5.28828 129.655 4.89648 130.243 4.89648C130.831 4.89648 131.223 5.28828 131.223 5.87597V18.0216C131.223 18.6093 130.635 19.0011 130.243 19.0011Z"
        fill="#344054"
      />
      <path
        opacity="0.7"
        d="M134.75 19.0011C134.162 19.0011 133.77 18.6093 133.77 18.0216V5.87597C133.77 5.28828 134.162 4.89648 134.75 4.89648C135.338 4.89648 135.73 5.28828 135.73 5.87597V18.0216C135.73 18.6093 135.338 19.0011 134.75 19.0011Z"
        fill="#344054"
      />
      <path
        d="M66.9361 45.4481L57.1364 55.243C54.9805 57.3979 51.4526 57.3979 49.1006 55.243L43.4168 49.562C41.2608 47.4071 41.2608 43.881 43.4168 41.5302L53.2165 31.7353C55.3724 29.5804 58.9004 29.5804 61.2523 31.7353L66.9361 37.4163C69.0921 39.7671 69.0921 43.2933 66.9361 45.4481Z"
        fill="#1C3754"
      />
      <path
        d="M176.302 45.4481L186.102 55.243C188.258 57.3979 191.786 57.3979 194.138 55.243L199.821 49.562C201.977 47.4071 201.977 43.881 199.821 41.5302L190.022 31.7353C187.866 29.5804 184.338 29.5804 181.986 31.7353L176.302 37.4163C174.146 39.7671 174.146 43.2933 176.302 45.4481Z"
        fill="#1C3754"
      />
      <path
        d="M121.227 112.64C121.66 112.64 122.011 112.289 122.011 111.856C122.011 111.423 121.66 111.072 121.227 111.072C120.794 111.072 120.443 111.423 120.443 111.856C120.443 112.289 120.794 112.64 121.227 112.64Z"
        fill="#1C3754"
      />
      <path
        d="M222.165 158.282C222.165 161.025 221.773 163.571 221.185 166.118C220.793 167.685 220.401 169.056 219.813 170.428C216.481 179.047 209.621 186.1 200.997 189.626C200.801 189.822 200.605 189.822 200.409 189.822C199.821 190.018 199.233 190.213 198.449 190.409C195.117 191.585 191.59 191.977 188.062 191.977C169.246 191.977 153.958 176.696 153.958 157.89C153.958 139.084 169.246 124 188.062 124C206.877 124.196 222.165 139.476 222.165 158.282Z"
        fill="url(#paint0_linear_6047_59286)"
      />
      <path
        d="M187.767 150.669V158.705M187.767 166.741H187.787M207.856 158.705C207.856 169.8 198.862 178.794 187.767 178.794C176.672 178.794 167.677 169.8 167.677 158.705C167.677 147.61 176.672 138.615 187.767 138.615C198.862 138.615 207.856 147.61 207.856 158.705Z"
        stroke="url(#paint1_linear_6047_59286)"
        stroke-width="4.0179"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        d="M185.316 111.852H119.854V49.5566C120.638 49.5566 121.422 49.5566 122.206 49.5566C156.701 49.5566 184.728 77.3741 185.316 111.852Z"
        fill="url(#paint2_linear_6047_59286)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6047_59286"
          x1="169.812"
          y1="130.353"
          x2="207.252"
          y2="187.401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F2CE18" />
          <stop offset="1" stop-color="#CC6B0A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6047_59286"
          x1="198.291"
          y1="166.269"
          x2="176.701"
          y2="149.691"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0113208" stop-color="#FFE7C2" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6047_59286"
          x1="115.321"
          y1="62.002"
          x2="173.808"
          y2="120.518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F79009" />
          <stop offset="1" stop-color="#F79009" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
