import styled, { css } from "styled-components";
import { FlexRow, H3, FlexCol, H4, H2, Span } from "../../../shared/globals";
import { themeColor, rtl } from "../../../shared/styles-utils";

export const Container = styled(FlexCol)`
  padding: 10px 20px;
  width: 100%;
  @media (min-width: 768px) {
    width: 44%;
    padding: ${rtl("40px 45px 40px 165px", "40px 165px 40px 45px")};
  }
`;

export const FullWidth = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
`;

export const StyledH3WithMargin = styled(H3)`
  font-weight: 700;
  margin: 0 4px;
`;

export const StyledButton = styled.button`
  background-color: inherit;
  color: ${({ theme }) => theme.colors.primary};
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

export const BorderBottom = styled.div`
  width: 100%;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.bg.wash}1a;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  &:first-child {
    border-bottom-width: 2px;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const MarginBottom = styled(FullWidth)`
  &:not(:last-child) {
    margin-bottom: 23px;
  }
`;

export const ImgDiv = styled.div`
  position: relative;
`;

export const QuantitySpan = styled.span`
  min-width: 22px;
  height: 22px;
  background-color: ${themeColor("primary")};
  position: absolute;
  border-radius: 30px;
  text-align: center;
  color: #fff;
  top: -8px;
  ${rtl("left", "right")}: -8px;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 4px;
  line-height: 1.4;
`;

export const LightH4 = styled(H4)`
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;
`;

export const StyledShippingCost = styled(H4)<{ isShippingDiscount: boolean }>`
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;

  ${({ isShippingDiscount }) =>
    isShippingDiscount &&
    css`
      text-decoration: line-through;
    `}
`;

export const StyledShippingDiscount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
`;

export const StyledShippingDiscountBadge = styled.div`
  display: flex;
  padding: 6px 12px;
  color: rgba(66, 171, 68, 1);
  background: rgba(226, 246, 226, 1);
  width: fit-content;
  border-radius: 4px;
  > svg {
    ${rtl("margin-left:6px", "margin-right:6px")};
  }
`;

export const LightH4ForDiscount = styled(LightH4)`
  color: ${({ theme }) => theme.success.medium};
  * {
    color: ${({ theme }) => theme.success.medium};
  }
`;

export const HeavyH4 = styled(H4)`
  font-weight: 600;
  ${rtl("margin-left", "margin-right")}: 5px;
  color: ${({ theme: { text } }) => text.inkLight};
`;

export const StyledH2 = styled(H2)`
  color: ${themeColor("primary")};
`;

export const PriceWrapper = styled(FlexCol)`
  width: auto;
  margin-inline-start: 10px;
  span {
    align-self: flex-end;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.5;
  }
`;

export const HideOnMobile = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

export const HideOnDesktop = styled.div`
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const SelectedOptions = styled(Span)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme: { text } }) => text.inkLight};
  text-transform: capitalize;
  padding-bottom: 4px;
`;
