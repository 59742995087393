import React from "react";
import styled from "styled-components";
import { PriceBase } from "../../../components/Price/Price";
import { themeColor } from "../../../shared/styles-utils";
import {
  ProductBasicInfoFragment,
  ProductType,
} from "../../../generated/graphql";
import { useStore } from "../../../lib/storeData";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { H3 } from "../../../shared/globals";

interface ProductCardPriceProps {
  product: ProductBasicInfoFragment;
  isDiscount?: boolean;
  discountedCustomProductPrice?: number;
}

const ProductCardPrice = ({
  product,
  isDiscount,
  discountedCustomProductPrice,
}: ProductCardPriceProps) => {
  const { currency: currencyCode } = useStore();
  const firstVariant = product?.variants?.nodes[0];
  const customProduct = product.type === ProductType.Custom;

  if (!customProduct) {
    return (
      <>
        {firstVariant?.price?.amount === 0 ? (
          <PriceWrapper>
            <H3 color="primary" fontWeight={700}>
              <FormattedMessage defaultMessage="Free" />
            </H3>
          </PriceWrapper>
        ) : (
          <PriceWrapper>
            {firstVariant?.compareAtPrice && (
              <>
                <SaveText>
                  <FormattedMessage defaultMessage="Save" />{" "}
                  <PriceBase
                    money={{
                      amount:
                        firstVariant?.compareAtPrice?.amount! -
                        firstVariant?.price?.amount!,
                      currencyCode,
                    }}
                  />
                  {" ("}
                  <FormattedNumber
                    value={getSalePercentage(
                      firstVariant?.compareAtPrice?.amount,
                      firstVariant?.price?.amount
                    )}
                    minimumFractionDigits={1}
                    style="percent"
                  />
                  {")"}
                </SaveText>
                <StyledDiscountPrice money={firstVariant?.compareAtPrice} />
              </>
            )}
            <StyledPrice money={firstVariant?.price} />
          </PriceWrapper>
        )}
      </>
    );
  } else {
    return (
      <>
        {isDiscount ? (
          <PriceWrapper>
            <SaveText>
              <FormattedMessage defaultMessage="Save" />{" "}
              <PriceBase
                money={{
                  amount:
                    product?.initialPrice?.amount! -
                    discountedCustomProductPrice!,
                  currencyCode,
                }}
              />
              {" ("}
              <FormattedNumber
                value={getSalePercentage(
                  product?.initialPrice?.amount!,
                  discountedCustomProductPrice!
                )}
                minimumFractionDigits={1}
                style="percent"
              />
              {")"}
            </SaveText>
            <StyledDiscountPrice money={product.initialPrice} />
            <StyledPrice
              money={{
                amount: discountedCustomProductPrice!,
                currencyCode,
              }}
            />
          </PriceWrapper>
        ) : (
          <PriceWrapper>
            <StyledPrice money={product?.initialPrice} />
          </PriceWrapper>
        )}
      </>
    );
  }
};

export const getSalePercentage = (
  initialPrice: number,
  discountPrice: number
) => {
  return (100 - (discountPrice / initialPrice) * 100) / 100;
};

export default ProductCardPrice;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 14px 0 20px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledPrice = styled(PriceBase)`
  font-size: 14px;
  font-weight: 700;
  color: ${themeColor("primary")};
`;

const SaveText = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #cc0905;
  margin-bottom: 8px;
`;

const StyledDiscountPrice = styled(PriceBase)`
  font-size: 12px;
  font-weight: 400;
  color: #a5a5a5;
  margin-bottom: 8px;
  text-decoration: line-through;
`;
