import { useRouter } from "next/router";
import { StoreTemplate } from "../../TemplateLoader";
import type { CollectionPageProps } from "../../types";

const Collection: React.FC<CollectionPageProps> = ({
  collection,
  itemsPerPage,
  offset,
  loading,
}) => {
  const Template = StoreTemplate.get();
  const router = useRouter();
  router.query.filters = "c:" + collection?.id;
  router.query.query = collection?.title!;

  return (
    <Template.elements.FilterAndSort
      products={collection?.products?.nodes}
      itemPerPage={itemsPerPage}
      productsTotalCount={collection?.products?.totalCount}
      offset={offset}
      loading={loading}
    />
  );
};

export default Collection;
