import { ReactNode } from "react";
import type { BoxShadows, Spacings } from "../types";

interface CardProps {
  children?: ReactNode;
  paddingSize?: Spacings;
  boxShadow?: BoxShadows;
  className?: string;
}

export const Card: React.FC<CardProps> = ({
  children,
  paddingSize,
  boxShadow,
  className = "",
}) => {
  const padding = paddingSize ? `p-${paddingSize}` : "p-sm";
  const shadow = boxShadow ? `shadow-${boxShadow}` : "shadow-md";
  return (
    <div
      className={`${className} w-full bg-white ${padding} border border-solid border-gray-200 rounded-lg ${shadow}`}
    >
      {children}
    </div>
  );
};
