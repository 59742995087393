const GarbageIcon = () => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C10.5523 2 11 2.44772 11 3V4L14 4C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H13V11C13 12.6569 11.8807 14 10.5 14H5.5C4.11929 14 3 12.6569 3 11L3 6H2C1.44772 6 1 5.55228 1 5C1 4.44772 1.44772 4 2 4L5 4L5 3C5 2.44772 5.44772 2 6 2L10 2ZM6 6C5.48716 6 5.06449 6.38604 5.00673 6.88338L5 7L5 11C5 11.5523 5.44772 12 6 12C6.51284 12 6.93551 11.614 6.99327 11.1166L7 11L7 7C7 6.44772 6.55228 6 6 6ZM10 6C9.48716 6 9.06449 6.38604 9.00673 6.88338L9 7V11C9 11.5523 9.44771 12 10 12C10.5128 12 10.9355 11.614 10.9933 11.1166L11 11V7C11 6.44772 10.5523 6 10 6Z"
        fill="#5F738C"
      />
    </svg>
  );
};

export { GarbageIcon };
