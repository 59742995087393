import { Fragment, ReactNode, useState } from "react";
import { useApolloClient } from "@apollo/client";
import createDecorator from "final-form-focus";
import { FormattedMessage } from "react-intl";
import { useStore } from "../lib/storeData";
import {
  RegistrationFormProps,
  RegistrationFormValues,
  TemplateElement,
} from "../templates/types";
import type { RendererComponent } from "./types";
import {
  MeQuery,
  MeQueryVariables,
  MeDocument,
  SignUpDocument,
  SignUpMutation,
  SignUpMutationVariables,
  StoreLegalPage,
} from "../generated/graphql";
import { registerUser } from "../lib/Authentication";
import { Link } from "../lib/i18n";
import Flex from "../shared/globals/UiElements/Flex";
import { useCartData } from "../lib/cartData/useCartData";
import { parseCookies } from "nookies";

const focusOnError = createDecorator<RegistrationFormValues>();

interface RegistrationFormRendererProps
  extends RendererComponent<RegistrationFormProps> {}

const LoginFormRenderer: TemplateElement<RegistrationFormRendererProps> = ({
  Component,
}) => {
  const { id: storeId, legalPages } = useStore();
  const { mutate, query } = useApolloClient();
  const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
  const {
    updateContactInfo: { updateContactInfo },
  } = useCartData();

  const policies = getPolicies(legalPages);
  const { sessionId } = parseCookies();

  const handleSubmitForm = async (values: RegistrationFormValues) => {
    setErrorMessage(null);
    try {
      const { data: signUpData } = await mutate<
        SignUpMutation,
        SignUpMutationVariables
      >({
        mutation: SignUpDocument,
        variables: {
          storeId,
          input: {
            name: values.name,
            email: values.email,
            phone: values.phone?.value!,
            password: values.password,
          },
        },
      });
      const loginToken = signUpData?.signup!;
      const { data: meData } = await query<MeQuery, MeQueryVariables>({
        query: MeDocument,
        context: { headers: { authorization: `Bearer ${loginToken}` } },
      });

      registerUser("signup", meData?.me!, loginToken);
      if (sessionId) {
        await updateContactInfo({
          info: {
            name: meData?.me?.name!,
            email: meData?.me?.email,
            phone: meData?.me?.phone,
          },
        });
      }
    } catch (error) {
      if (error.message === "USER_EXIST") {
        setErrorMessage(
          <FormattedMessage defaultMessage="This user already exists" />
        );
        return;
      }
      setErrorMessage(
        <FormattedMessage defaultMessage="Something went wrong, please try again" />
      );
    }
  };

  return (
    <Component
      errorMessage={errorMessage}
      policies={policies}
      onSubmit={handleSubmitForm}
      focusOnError={focusOnError}
    />
  );
};

export default LoginFormRenderer;

function getPolicies(legalPages: (StoreLegalPage | null)[] | undefined | null) {
  const messages = {
    "privacy-policy": true,
    "terms-and-conditions": true,
  };

  const relatedPolicies = legalPages?.filter((page) => !!messages[page?.name!]);
  return (
    <Flex justifyContent="center" alignItems="center" spacing="xs">
      {relatedPolicies ? (
        relatedPolicies?.map((page, index) => (
          <Fragment key={page?.id}>
            <Link href={`/page/${page?.handle}`}>
              <span>{page?.title}</span>
            </Link>
            {index < relatedPolicies.length - 1 && <span>{`&`}</span>}
          </Fragment>
        ))
      ) : (
        <FormattedMessage defaultMessage="policies" />
      )}
    </Flex>
  );
}
