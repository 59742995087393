import React from "react";

export const FulfillmentStatusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6.3584 11.5556L9.40507 9.6981V5.82227"
        stroke="black"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.333 14.166V11.666C18.333 10.9749 16.933 10.416 15.208 10.416C13.483 10.416 12.0839 10.9758 12.083 11.666V16.666C12.0839 17.3562 13.4821 17.916 15.208 17.916C16.9339 17.916 18.3321 17.3562 18.333 16.666V11.666"
        stroke="black"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.084 11.666C12.084 12.3562 13.4831 12.916 15.209 12.916C16.9349 12.916 18.3331 12.3562 18.3331 11.666"
        stroke="black"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.083 14.166C12.083 14.8562 13.4821 15.416 15.208 15.416C16.9339 15.416 18.333 14.8562 18.333 14.166"
        stroke="black"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.474 7.49986C15.6006 3.67411 11.9251 1.16894 8.04487 1.75476C4.16466 2.34059 1.39252 5.8192 1.68752 9.73228C1.98251 13.6454 5.24484 16.6691 9.16902 16.6665"
        stroke="black"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
