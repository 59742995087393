import React from "react";
import type { ShopPageProps } from "../../types";
import { StoreTemplate } from "../../TemplateLoader";

const ShopPage: React.FC<ShopPageProps> = ({
  products,
  itemPerPage,
  productsTotalCount,
  offset,
  loading,
}) => {
  const Template = StoreTemplate.get();
  return (
    <Template.elements.FilterAndSort
      products={products}
      itemPerPage={itemPerPage}
      productsTotalCount={productsTotalCount}
      offset={offset}
      loading={loading}
    />
  );
};

export default ShopPage;
