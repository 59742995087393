export const FilterIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 7L16.5 7"
        stroke="#1F1F1F"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 9.5C4.61875 9.5 3.5 8.38125 3.5 7C3.5 5.61875 4.61875 4.5 6 4.5C7.38125 4.5 8.5 5.61875 8.5 7V7C8.5 8.38125 7.38125 9.5 6 9.5Z"
        stroke="#1F1F1F"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 16.5C10.6188 16.5 9.5 15.3813 9.5 14C9.5 12.6187 10.6187 11.5 12 11.5C13.3813 11.5 14.5 12.6187 14.5 14V14C14.5 15.3812 13.3813 16.5 12 16.5Z"
        stroke="#1F1F1F"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.5 14L9.5 14"
        stroke="#1F1F1F"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5 14L16.5 14"
        stroke="#1F1F1F"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
