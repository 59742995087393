import { FormattedMessage } from "react-intl";
import {
  ItemHolder,
  PriceWrapper,
  StyledH3,
  StyledH4,
  StyledNonDiscountedVariants,
  StyledNumber,
} from "./styled";
import { CartItemChildrenProps } from "./types";
import { TwoDashTrashIcon } from "../../../../assets/Icons";
import { DiscountPrice, Price } from "../../../../components/Price";
import { QuantityInput } from "../QuantityInput";
import {
  CartCustomItem,
  CartSimpleItem,
  CheckoutServiceErrorCodes as ErrorCodes,
} from "../../../../generated/graphql";
import { FlexRow, P } from "../../../../shared/globals";
import Alert from "../../../../shared/globals/UiElements/Alert";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import CartItemInfo from "./CartItemInfo";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { useCartData } from "../../../../lib/cartData/useCartData";
import { CustomButton } from "../Button/CustomButton";
import { theme } from "../../../../../tailwind.config";
import { isProductNotAvailableError } from "../../../../shared/utils/error-utils";

const CartItemMobile = ({
  item,
  isCustomDiscountApplied,
  isAutomaticDiscountApplied,
  discountedSimpleItemsCount,
  nonDiscountedSimpleItemsCount,
  isAllItemsDiscounted,
  itemErrors,
  value,
  setValue,
  isAlertOpen,
  setIsAlertOpen,
  onQuantityChange,
  onRemoveCartItem,
}: CartItemChildrenProps) => {
  const isProductNotAvailableErrorFound =
    isProductNotAvailableError(itemErrors);

  const typedSimpleItem = item as CartSimpleItem;
  const typedCustomItem = item as CartCustomItem;

  const {
    cart: { isLoading },
  } = useCartData();

  return (
    <ItemHolder className="">
      <Flex fullWidth alignItems="flex-start" justifyContent="space-between">
        <FlexRow alignItems="flex-start">
          <ItemImg
            imgDetails={
              typedSimpleItem?.variant?.image ||
              typedCustomItem?.product?.images[0]
            }
          />
          <CartItemInfo
            item={item}
            isCustomDiscountApplied={isCustomDiscountApplied}
            isAutomaticDiscountApplied={isAutomaticDiscountApplied}
            discountedSimpleItemsCount={discountedSimpleItemsCount}
            isAllItemsDiscounted={isAllItemsDiscounted}
          />
        </FlexRow>

        <Price className="!text-gray-900" money={item.price} />

        {isAutomaticDiscountApplied && !isAllItemsDiscounted && (
          <StyledNonDiscountedVariants>
            <FlexRow>
              <StyledNumber>{nonDiscountedSimpleItemsCount}x</StyledNumber>
              <StyledH3>
                {typedSimpleItem.variant.selectedOptions
                  ?.map((option) => option.value.name)
                  .join(" / ")}
              </StyledH3>
            </FlexRow>
            <PriceWrapper
              isNotActive={isProductNotAvailableErrorFound}
              style={{ padding: "0 6px" }}
            >
              <Price className="!text-gray-900" money={typedSimpleItem.price} />
            </PriceWrapper>
          </StyledNonDiscountedVariants>
        )}
      </Flex>
      <Flex
        fullWidth
        className="!p-3 mt"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Flex column spacing="none">
          <StyledH4>
            <FormattedMessage defaultMessage="Subtotal" />
          </StyledH4>
          <PriceWrapper isNotActive={isProductNotAvailableErrorFound} isBig>
            {isLoading ? (
              <P fontWeight="600">
                <FormattedMessage defaultMessage="Calculating..." />
              </P>
            ) : (
              <>
                {(isAutomaticDiscountApplied || isCustomDiscountApplied) && (
                  <DiscountPrice
                    style={{ fontWeight: 600 }}
                    money={item?.subtotal}
                  />
                )}
                <Price className="!text-gray-900" money={item?.totalPrice} />
              </>
            )}
          </PriceWrapper>
        </Flex>
        <Flex justifyContent="space-between">
          <QuantityInput
            max={
              typedSimpleItem.variant?.maxPerCart || Number.POSITIVE_INFINITY
            }
            value={item.quantity}
            onChange={(value) => {
              setValue(value);
              onQuantityChange?.(value);
            }}
            disabled={isProductNotAvailableErrorFound}
          />
          <CustomButton
            data-test="button-remove-item"
            onClick={() =>
              onRemoveCartItem(
                typedSimpleItem?.variant?.id || typedCustomItem?.product?.id
              )
            }
            isDisabled={isLoading}
            compact
            customDefault={{
              backgroundColor: "transparent",
              color: theme.extend.colors.primary[500],
              borderColor: "transparent",
            }}
            onlyIcon
            size="xs"
          >
            <TwoDashTrashIcon />
          </CustomButton>
        </Flex>

        {isAlertOpen && (
          <Alert
            message={
              value >
              (typedSimpleItem?.variant?.maxPerCart ||
                Number?.POSITIVE_INFINITY) ? (
                <FormattedMessage defaultMessage="Quantity exceeded" />
              ) : (
                <FormattedMessage
                  defaultMessage="Minimum quantity per order {min}. You can add up to {max} per order"
                  values={{
                    min:
                      typedSimpleItem?.variant?.minPerCart ||
                      Number.POSITIVE_INFINITY,
                    max:
                      typedSimpleItem?.variant?.maxPerCart ||
                      Number.POSITIVE_INFINITY,
                  }}
                />
              )
            }
            closeAction={() => setIsAlertOpen(false)}
          />
        )}
      </Flex>
      {itemErrors?.map((itemError, i) => (
        <Alert
          key={i}
          type={itemError.code === ErrorCodes.PriceChanged ? "warn" : undefined}
          message={itemError.message}
        />
      ))}
    </ItemHolder>
  );
};

export default CartItemMobile;
