import styled from "styled-components";
import { StoreTemplate } from "../../TemplateLoader";
import type { SiteLayoutProps, TemplateElement } from "../../types";
import { Breakpoints, ZIndex } from "../../../shared/globals/types";
import useBreakPoints from "../../../shared/utils/useBreakPoints";
import { useStore } from "../../../lib/storeData";
import { useEffect } from "react";

const excludedStoreIDs = [
  "Store_clwbygobi07ui01ixb7hg1hy9", // Jam jam store
  "Store_cloebqr7v096101jo1wjb04h9", // inpu
  "Store_clun3oqwj06jp01m42tmfc9du", // beauty-shades.com
  "Store_clrifiuqu0bfn01iufj211e48", // Mauritanian Store
  "Store_clwyyt6mp0ifl01ip19s08sqi", // lumir fashion
  "Store_clk6ln7ft0io501f7ga3xd32m", // Diar Mariam
  "Store_clxyhpy2301e401hf8p0h3ck4", // Toofe store
  "Store_cloqqdg0l00na01jkh0a216g2", // MOVE STORE
  "Store_clwhnzuzr0asd01ipc7u55aa0", // Star mix
  "Store_cljsnygr909pg01g445gjhw52", // ALL ZONE
  "Store_clx680pmp01m801i07qsc2gdj", // Clichei
  "Store_clxvpgabg001401hf1gp66uwl", // solovizion.com
];

const SiteLayout: TemplateElement<SiteLayoutProps> = ({ children }) => {
  const Template = StoreTemplate.get();
  const { storeId } = useStore();
  const { isSmallScreen } = useBreakPoints();

  const excludedStore = excludedStoreIDs.includes(storeId) && isSmallScreen;

  useEffect(() => {
    if (window.innerWidth > Breakpoints.TABLET) return;
    let prevScrollpos = window.scrollY;
    const navbar = document.getElementById("navbar") as HTMLElement;
    window.onscroll = function () {
      const currentScrollPos = window.scrollY;
      if (prevScrollpos > currentScrollPos) {
        navbar.style.top = "0";
      } else {
        navbar.style.top = -navbar.offsetHeight.toString() + "px";
      }
      prevScrollpos = currentScrollPos;
    };
  }, []);

  return (
    <>
      <Container id="navbar">
        <Template.elements.InfoBar />
        <Template.elements.NavBar />
      </Container>
      <Main applyTempStyles={excludedStore}>{children}</Main>
      {!excludedStore && <Template.elements.Footer />}
    </>
  );
};

export default SiteLayout;

/**
 * styles
 */

const Main = styled.main<{ applyTempStyles: boolean }>`
  min-height: 60vh;
  background-color: ${({ theme }) => theme.colors.backgroundReverse};
  padding-bottom: 60px;
  @media (max-width: 768px) {
    padding-bottom: ${({ applyTempStyles }) => applyTempStyles && "120px"};
  }
`;

// used for controlling the overlay inside SearchBar.tsx
const Container = styled.div`
  position: relative;
  z-index: ${ZIndex["OVERLAY"]};
  transition: top 0.3s ease-in-out;
  @media (max-width: ${Breakpoints.TABLET}px) {
    position: sticky;
    top: 0;
  }
`;
