import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Container } from "./styled";
import CartCard from "./CartCard";
import { EmptyCartIcon, RightArrow } from "../../../../assets/Icons";
import { PrimaryButton } from "../../../../components/Button";
import { EmptyState } from "../../../../components/UtilityComponents/EmptyState";
import { StoreTemplate } from "../../../TemplateLoader";
import { useCartData } from "../../../../lib/cartData/useCartData";

const Cart = () => {
  const Template = StoreTemplate.get();
  const {
    cart: { items },
  } = useCartData();

  return (
    <>
      {!items || items?.length === 0 ? (
        <EmptyState
          title={<FormattedMessage defaultMessage="Your cart is empty" />}
          description={
            <FormattedMessage defaultMessage="Looks like you haven’t added anything" />
          }
          image={<EmptyCartIcon />}
          button={
            <PrimaryButton href="/shop" suffixIcon={<RightArrow />}>
              <FormattedMessage defaultMessage="Start Shopping" />
            </PrimaryButton>
          }
        />
      ) : (
        <Container alignItems="stretch" spacing="xl">
          <Template.elements.CartTable />
          <CartCard />
        </Container>
      )}
    </>
  );
};

export default Cart;
