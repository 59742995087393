import styled from "styled-components";
import { H1, H2, FlexRow } from "../../shared/globals";
import { themeColor, rtl } from "../../shared/styles-utils";
import { PrimaryButton } from "../Button";
import { ZIndex } from "../../shared/globals/types";

export const AlignValues = {
  start: "flex-start",
  center: "center",
  end: "flex-end",
};

export const HeroBtn = styled(PrimaryButton)`
  border-radius: 4px;
  padding: 12px 32px;
  margin: 0 0 10px;
  background-color: ${themeColor("primary")};
  color: white;

  svg {
    ${rtl("transform: rotate(180deg)", "transform: rotate(0deg)")};
  }

  @media (min-width: 768px) {
    margin: 0 10px;
  }
`;

export const SecondButton = styled(PrimaryButton)`
  background-color: white;
  border: 1px solid ${themeColor("primary")};
  color: ${themeColor("primary")};
  padding: 12px 32px;
  div {
    padding: 0 16px;
  }
  svg {
    ${rtl("transform: rotate(180deg)", "transform: rotate(0deg)")}
  }
`;

export const ButtonsWrapper = styled.div<{ align: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => AlignValues[align]};

  @media (min-width: 768px) {
    justify-content: ${({ align }) => AlignValues[align]};
    flex-direction: row;
  }
`;

export const HeroBox = styled.section<{
  align: string;
}>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ align }) => AlignValues[align]};
  min-height: 300px;
  aspect-ratio: 2/3;
  @media (min-width: 768px) {
    aspect-ratio: 16/5;
  }
`;

export const HeroContainer = styled.div<{ hasOverlay: boolean }>`
  z-index: ${ZIndex["DEFAULT"]};
  ${({ hasOverlay }) =>
    hasOverlay && "box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);"};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
`;

export const Heading = styled(H1)<{
  headingColor: string;
  align?: string;
  noTextShadow?: boolean;
}>`
  text-shadow: ${({ noTextShadow }) =>
    noTextShadow ? "none" : "0 2px 4px rgba(0, 0, 0, 0.5);"};
  font-size: 40px;
  font-weight: bold;
  color: ${({ headingColor }) => headingColor};
  text-align: ${({ align }) => align};
  letter-spacing: -1.25px;

  @media (min-width: 768px) {
    font-size: 64px;
  }
`;

export const Text = styled(H2)<{
  subHeadingColor: string;
  subHeadingSize: string;
  align: string;
  noTextShadow?: boolean;
  noMargin?: boolean;
}>`
  text-shadow: ${({ noTextShadow }) =>
    noTextShadow ? "none" : "0 2px 4px rgba(0, 0, 0, 0.5);"};
  font-weight: 500;
  font-size: 18px;
  color: ${({ subHeadingColor }) => subHeadingColor};
  margin: ${({ noMargin }) => (noMargin ? "" : "10px 0 30px")};
  text-align: ${({ align }) => align};

  @media (min-width: 768px) {
    font-size: ${({ subHeadingSize }) => subHeadingSize}px;
  }
`;

export const NavigationContainer = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
  position: absolute;
  bottom: 20px;
  transform: ${rtl("rotateX(180deg)", "rotateX(0deg)")};
  z-index: ${ZIndex["DEFAULT"]};
`;

export const NavigationCircle = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 30px;
  background-color: ${themeColor("white")};
  cursor: pointer;

  :not(:last-of-type) {
    ${rtl("margin-left", "margin-right")}: 6px;
  }

  &.active {
    background-color: ${themeColor("primary")};
    border: 1px solid ${themeColor("white")};
  }
`;

export const ButtonHolder = styled(PrimaryButton)`
  padding: 12px 6px;
  border-radius: 30px;

  :first-of-type {
    ${rtl("margin-left", "margin-right")}: 10px;
  }

  > div {
    transform: ${rtl("rotateY(180deg)", "rotateY(0deg)")};
  }
`;
