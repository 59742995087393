const CardVerification = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.375 18.9583C24.375 20.1543 23.4049 21.125 22.2083 21.125H3.79167C2.59512 21.125 1.625 20.1543 1.625 18.9583V7.04167C1.625 5.84458 2.59512 4.875 3.79167 4.875H22.2083C23.4049 4.875 24.375 5.84458 24.375 7.04167V18.9583Z"
      fill="#BAC7D5"
    />
    <path d="M1.625 7.58331H24.375V10.8333H1.625V7.58331Z" fill="#94A8BE" />
    <path d="M2.70801 11.9167H23.2913V14.625H2.70801V11.9167Z" fill="#A6B6C8" />
    <path
      d="M13.2708 9.67633C12.3817 9.67633 11.5012 9.85146 10.6797 10.1917C9.85827 10.532 9.11186 11.0307 8.48313 11.6595C7.8544 12.2882 7.35567 13.0346 7.0154 13.8561C6.67513 14.6776 6.5 15.558 6.5 16.4472C6.5 17.3363 6.67513 18.2168 7.0154 19.0382C7.35567 19.8597 7.8544 20.6061 8.48313 21.2349C9.11186 21.8636 9.85827 22.3623 10.6797 22.7026C11.5012 23.0429 12.3817 23.218 13.2708 23.218C14.16 23.218 15.0404 23.0429 15.8619 22.7026C16.6834 22.3623 17.4298 21.8636 18.0585 21.2349C18.6873 20.6061 19.186 19.8597 19.5263 19.0382C19.8665 18.2168 20.0417 17.3363 20.0417 16.4472C20.0417 15.558 19.8665 14.6776 19.5263 13.8561C19.186 13.0346 18.6873 12.2882 18.0585 11.6595C17.4298 11.0307 16.6834 10.532 15.8619 10.1917C15.0404 9.85146 14.16 9.67633 13.2708 9.67633Z"
      fill="#BAC7D5"
    />
    <path
      d="M13.2705 23.8333C9.23834 23.8333 5.95801 20.553 5.95801 16.5208C5.95801 12.4886 9.23834 9.20831 13.2705 9.20831C17.3027 9.20831 20.583 12.4886 20.583 16.5208C20.583 20.553 17.3027 23.8333 13.2705 23.8333ZM13.2705 10.8333C10.1348 10.8333 7.58301 13.3846 7.58301 16.5208C7.58301 19.6571 10.1348 22.2083 13.2705 22.2083C16.4062 22.2083 18.958 19.6571 18.958 16.5208C18.958 13.3846 16.4062 10.8333 13.2705 10.8333Z"
      fill="#5F738C"
    />
    <path
      d="M7.58301 16.5208C7.58301 17.8506 8.04505 19.0721 8.81259 20.0416H17.7284C18.496 19.0721 18.958 17.8506 18.958 16.5208C18.958 14.9299 18.2993 13.4913 17.2431 12.4583H9.29792C8.24167 13.4913 7.58301 14.9299 7.58301 16.5208Z"
      fill="white"
    />
    <path
      d="M10.0813 17.2001C10.2335 17.2001 10.3575 17.1578 10.4529 17.0728C10.5482 16.9877 10.5975 16.8751 10.6013 16.7337H11.3753C11.3732 16.946 11.3163 17.1405 11.2042 17.3176C11.092 17.4942 10.9382 17.6312 10.7427 17.7287C10.5477 17.8262 10.3315 17.875 10.0954 17.875C9.65337 17.875 9.30508 17.7325 9.04995 17.4476C8.79483 17.1627 8.66699 16.7695 8.66699 16.2673V16.2121C8.66699 15.7295 8.79374 15.3443 9.0467 15.0562C9.29966 14.768 9.64795 14.625 10.09 14.625C10.4767 14.625 10.7865 14.7366 11.02 14.9598C11.2535 15.1829 11.3721 15.4797 11.3759 15.8502H10.6018C10.598 15.6872 10.5487 15.555 10.4534 15.4527C10.3581 15.3508 10.2324 15.2999 10.0764 15.2999C9.88412 15.2999 9.73895 15.3709 9.64091 15.5128C9.54287 15.6547 9.49358 15.886 9.49358 16.205V16.2917C9.49358 16.6145 9.54233 16.8469 9.63928 16.9883C9.7357 17.1297 9.88303 17.2001 10.0813 17.2001ZM13.4277 16.815L14.0317 14.625H14.939L13.8426 17.875H13.0133L11.917 14.625H12.8243L13.4277 16.815ZM16.6777 16.815L17.2817 14.625H18.189L17.0926 17.875H16.2633L15.167 14.625H16.0743L16.6777 16.815Z"
      fill="#37474F"
    />
  </svg>
);

export { CardVerification };
