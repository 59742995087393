import * as React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import CartCard from "./CartCard";
import { EmptyCartIllustration, RightArrow } from "../../../../assets/Icons";
import { PrimaryButton } from "../../../../components/Button";
import { EmptyState } from "../../../../components/UtilityComponents/EmptyState";
import { FlexCol } from "../../../../shared/globals";
import { themeColor } from "../../../../shared/styles-utils";
import { StoreTemplate } from "../../../TemplateLoader";
import { useCartData } from "../../../../lib/cartData/useCartData";

const Cart = () => {
  const Template = StoreTemplate.get();
  const {
    cart: { items },
  } = useCartData();

  return !items || items?.length === 0 ? (
    <EmptyState
      title={<FormattedMessage defaultMessage="Your cart is empty" />}
      description={
        <FormattedMessage defaultMessage="Looks like you haven’t added anything to your cart yet" />
      }
      image={<EmptyCartIllustration />}
      button={
        <StyledPrimaryButton href="/shop" suffixIcon={<RightArrow />}>
          <FormattedMessage defaultMessage="Start Shopping" />
        </StyledPrimaryButton>
      }
    />
  ) : (
    <Container spacing="xl">
      <Template.elements.CartTable />
      <CartCard />
    </Container>
  );
};

export default Cart;

// Styles

const Container = styled(FlexCol)`
  background-color: ${themeColor("backgroundReverse")};
  padding: 30px 0;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    > * {
      :not(:last-child) {
        margin-bottom: 0 !important;
        margin-inline-end: 20px;
      }
    }
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  background-color: ${themeColor("primary")};
  border-color: ${themeColor("primary")};
`;
