import { FC } from "react";
import styled from "styled-components";
import { NavBarProps } from "../../../types";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { Section } from "../../../../shared/layout";
import { StoreTemplate } from "../../../TemplateLoader";
import BurgerMenu from "./BurgerMenu";
import SearchOverlay from "./SearchBar/SearchOverlay";
import { BurgerIcon2 } from "../../../../assets/Icons";

const MobileNavbar: FC<NavBarProps> = ({ menu, socialMedia }) => {
  const Template = StoreTemplate.get();

  return (
    <ShowBelowMobile>
      <Section boxShadow background="white">
        <StyledFlex alignItems="center" justifyContent="space-between">
          <BurgerMenu
            menus={menu!}
            socialSettings={socialMedia!}
            icon={<BurgerIcon2 />}
          />
          <Template.elements.Logo />
          <SearchOverlay />
        </StyledFlex>
      </Section>
      <Template.elements.FixedNav menus={menu!} socialSettings={socialMedia!} />
    </ShowBelowMobile>
  );
};

export default MobileNavbar;

/**
 *
 * Styles
 *
 */

const ShowBelowMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const StyledFlex = styled(Flex)`
  padding-block: 10px;
`;
