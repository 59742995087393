const PhoneOutlined = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.585 25.8018L26.3005 25.0863C26.7886 24.5982 26.7886 23.8067 26.3005 23.3186L24.7027 21.7208C24.3901 21.4082 23.9662 21.2326 23.5242 21.2326C23.0821 21.2326 22.6582 21.4082 22.3457 21.7208L21.0931 22.9734C19.2118 22.2718 17.7278 20.7878 17.0262 18.9065L18.2788 17.6539C18.9296 17.003 18.9296 15.9478 18.2788 15.2969L16.681 13.6991C16.4466 13.4647 16.1286 13.333 15.7971 13.333C15.4656 13.333 15.1477 13.4647 14.9132 13.6991L14.1978 14.4146C13.6449 14.9675 13.3339 15.7172 13.3333 16.4991V16.4991C13.3309 19.1963 14.4013 21.7838 16.3086 23.691C18.2158 25.5982 20.8032 26.6687 23.5004 26.6663V26.6663C24.2824 26.6656 25.0321 26.3547 25.585 25.8018V25.8018Z"
        stroke="#475467"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { PhoneOutlined };
