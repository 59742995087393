export const VerifiedIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_4646_4208)">
        <path
          d="M10.8106 2.4798C10.9435 2.80138 11.1988 3.05699 11.5201 3.19044L12.647 3.65722C12.9686 3.79043 13.2242 4.04594 13.3574 4.36754C13.4906 4.68913 13.4906 5.05048 13.3574 5.37208L12.8909 6.49817C12.7577 6.81991 12.7575 7.18163 12.8913 7.50321L13.357 8.62897C13.423 8.78826 13.457 8.95899 13.457 9.13142C13.4571 9.30385 13.4231 9.4746 13.3571 9.6339C13.2912 9.79321 13.1944 9.93795 13.0725 10.0599C12.9505 10.1818 12.8058 10.2784 12.6464 10.3444L11.5203 10.8108C11.1988 10.9438 10.9432 11.199 10.8097 11.5204L10.3429 12.6473C10.2097 12.9689 9.95421 13.2244 9.63261 13.3576C9.31101 13.4908 8.94966 13.4908 8.62806 13.3576L7.50197 12.8912C7.18036 12.7583 6.81915 12.7586 6.49774 12.8919L5.37084 13.358C5.04942 13.4909 4.68839 13.4908 4.36705 13.3577C4.04571 13.2246 3.79034 12.9694 3.65704 12.6482L3.19012 11.5209C3.05716 11.1993 2.80193 10.9437 2.48056 10.8103L1.35366 10.3435C1.0322 10.2104 0.776764 9.95501 0.643504 9.63359C0.510244 9.31218 0.510063 8.951 0.643002 8.62945L1.10944 7.50336C1.24233 7.18175 1.24206 6.82054 1.10869 6.49913L0.642917 5.37142C0.576879 5.21213 0.542875 5.0414 0.542848 4.86897C0.54282 4.69654 0.576769 4.52579 0.642755 4.36649C0.708742 4.20718 0.805472 4.06244 0.927419 3.94053C1.04936 3.81862 1.19414 3.72194 1.35346 3.65601L2.47956 3.18956C2.80085 3.05671 3.05631 2.80181 3.18986 2.4808L3.65663 1.3539C3.78984 1.0323 4.04535 0.776794 4.36695 0.643583C4.68855 0.510372 5.0499 0.510372 5.3715 0.643583L6.49759 1.11003C6.8192 1.24291 7.18041 1.24264 7.50182 1.10927L8.6292 0.644306C8.95075 0.51117 9.31202 0.511197 9.63356 0.644382C9.9551 0.777567 10.2106 1.033 10.3438 1.35452L10.8107 2.48176L10.8106 2.4798Z"
          fill="#079455"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.7416 5.16548C9.83513 5.01858 9.86646 4.84055 9.82872 4.67055C9.79098 4.50054 9.68725 4.3525 9.54035 4.25898C9.39346 4.16545 9.21543 4.13412 9.04542 4.17186C8.87542 4.2096 8.72738 4.31333 8.63385 4.46023L6.06398 8.49835L4.88798 7.02835C4.77926 6.89236 4.62097 6.80513 4.44793 6.78585C4.27489 6.76657 4.10128 6.81682 3.96529 6.92554C3.8293 7.03426 3.74207 7.19255 3.72279 7.36559C3.70351 7.53863 3.75376 7.71224 3.86248 7.84823L5.61248 10.0357C5.6777 10.1174 5.76146 10.1822 5.85681 10.225C5.95216 10.2677 6.05633 10.287 6.16065 10.2814C6.26498 10.2757 6.36646 10.2452 6.45663 10.1925C6.54681 10.1397 6.62308 10.0662 6.6791 9.97798L9.7416 5.16548Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4646_4208">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
