import { Dispatch, SetStateAction } from "react";
import { Decorator } from "final-form";
import { PhoneInput } from "../../shared/globals/UiElements/InputPhone";

export interface LoginFormProps {
  activeTab: LoginMethod;
  setActiveTab: Dispatch<SetStateAction<LoginMethod>>;
  onSubmit: (values: LoginFormValues) => Promise<void>;
  isError: boolean;
  setIsError: Dispatch<SetStateAction<boolean>>;
  focusOnError: Decorator<LoginFormValues, object>;
}

export type LoginFormValues = {
  email: string;
  phone: PhoneInput;
  password: string;
};

export enum LoginMethod {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
}
