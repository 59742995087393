import * as React from "react";
import styled from "styled-components";
import { NextRouter } from "next/router";
import { FormattedMessage } from "react-intl";
import Select, { ControlProps, StylesConfig, components } from "react-select";
import { rtl } from "../../../../shared/styles-utils";
import { FlexRow, P } from "../../../../shared/globals";
import { MobileSort, MobileFilter } from "../../../../assets/Icons";
import { PrimaryButton } from "../../../../components/Button";
import { SortOrder } from "../../../../generated/graphql";
import { useRouter } from "../../../../lib/i18n";
import FilterTags from "./components/FilterTags";
import { RowWrapper } from "./DesktopFilterSort";
import Filter from "./Filter";
import { sortOptions } from "./consts";
import { ZIndex } from "../../../../shared/globals/types";

const MobileFilterSort = () => {
  const router = useRouter();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleChange = (selectedOption: {
    value: { sortBy: string; sortOrder: SortOrder };
  }) => {
    updateQuerySorts(router, {
      ...selectedOption.value,
    });
  };

  const sort = {
    sortBy: router.query.sortBy,
    sortOrder: router.query.sortOrder,
  };

  return (
    <>
      <Filter isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <ItemsWrapper>
        <FlexRow fullWidth style={{ borderBottom: "1px solid #d8d8d8" }}>
          <FilterBtn onClick={() => setIsOpen(true)}>
            <MobileFilter />
            <MobileText>
              <FormattedMessage defaultMessage="Filter" />
            </MobileText>
          </FilterBtn>
          <Select
            instanceId="sort-products"
            options={sortOptions}
            onChange={handleChange}
            styles={customStyles}
            defaultValue={sortOptions.find(
              (option) => option.key === `${sort.sortBy}-${sort.sortOrder}`
            )}
            isSearchable={false}
            placeholder={<FormattedMessage defaultMessage="Sort" />}
            components={{
              Control,
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
          />
        </FlexRow>
        {router.query.query && (
          <RowWrapper>
            <SearchedFor>
              <FormattedMessage defaultMessage="Searched for " />{" "}
              {'"' + router.query.query + '"'}
            </SearchedFor>
          </RowWrapper>
        )}
        <RowWrapper>
          <FilterTags />
        </RowWrapper>
      </ItemsWrapper>
      {/* TODO: aplayed filter text */}
      {/* <MobileCounter>
        <ProductCounter>Showing 6 products of 40</ProductCounter>
      </MobileCounter> */}
    </>
  );
};

export default MobileFilterSort;

// Functions

function updateQuerySorts(
  router: NextRouter,
  newFilters: { sortBy: string; sortOrder: SortOrder }
) {
  router.push({
    pathname: router.asPath.split("?")[0],
    query: {
      ...router.query,
      ...newFilters,
    },
  });
}

const Control = (props: ControlProps) => {
  const { children, ...restProps } = props;
  return (
    components.Control && (
      <components.Control {...restProps}>
        <MobileSort />
        {children}
      </components.Control>
    )
  );
};

const customStyles: StylesConfig = {
  container: (provided) => ({
    ...provided,
    width: "50%",
  }),

  control: (provided) => ({
    ...provided,
    border: "none",
    backgroundColor: "transparent",
    boxShadow: "none",
    justifyContent: "center",
    color: "#9A9A9A",
  }),

  singleValue: (provided) => ({
    ...provided,
    color: "#9A9A9A",
  }),

  valueContainer: (provided) => ({
    ...provided,
    fontWeight: "bold",
    flex: "unset",
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: "4px",
    boxShadow: "0 2px 4px 0 rgba(82, 82, 82, 0.2)",
    border: "solid 1px #dfe3e8",
    backgroundColor: "#fafbfc",
    zIndex: ZIndex["DROPDOWN"],
  }),

  option: (provided) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "1.43",
    color: "#4f4335",
    backgroundColor: "#fafbfc",
    cursor: "pointer",
  }),
};

// Styles

const ItemsWrapper = styled.div`
  position: relative;
  width: 100%;
  align-items: center;
  place-content: center;
  background-color: ${({ theme }) => theme.bg.reverse};
  padding: 10px 0;
  justify-content: space-around;
  display: flex;
  padding: 15px 0;
  flex-direction: column;

  @media (min-width: 768px) {
    display: none;
    justify-content: space-between;
    box-shadow: unset;
  }
`;

const MobileText = styled(P)`
  font-size: 16px;
  font-weight: bold;
  color: #9a9a9a;
  ${rtl("margin-right: 10px;", "margin-left: 10px;")}
`;

const SearchedFor = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  margin-top: 30px;

  letter-spacing: -0.1px;

  color: #465567;
`;

const FilterBtn = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.bg.reverse};
  color: #9a9a9a;
  width: 50%;
  border: none;
  position: relative;
  padding: 10px 12px;

  &:hover {
    box-shadow: unset;
  }
  &:focus {
    outline: none;
  }

  ::after {
    content: "";
    position: absolute;
    height: 20%;
    width: 1px;
    top: 50%;
    ${rtl("left", "right")}: 0;
    background-color: #d8d8d8;
    transform: translateY(-50%);
  }
`;
