import styled from "styled-components";
import { SectionContentProps, TemplateElement } from "../../types";

const SectionContent: TemplateElement<SectionContentProps> = ({
  children,
  className,
}) => {
  return <Content className={className}>{children}</Content>;
};

export default SectionContent;

const Content = styled.div`
  max-width: 1200px;
  padding: 0 20px;
  margin: auto;
`;
